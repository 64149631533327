import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { Box, ListItem } from "@mui/material";
import TrackAction from "../../../stores/track/TrackAction";
import { connect } from "react-redux";
import ImageIcon from "@mui/icons-material/Image";
import Rettrive from "../../releases/Rettrive";
import { theme } from "../../App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import TopTrackAction from "../../../stores/topTrackReport/TopTrackAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TopTrackAction.REQUEST_TOPTRACK]),
  pagination: state.topTrack.topTrack || [],
  topTracks: state.topTrack.topTrack.hits || [],
});

function TopVideos(props) {
  const [page_limit] = useState(15);
  const navigate = useNavigate();
  const [initialStart, setInitialStart] = useState(true);
  const { isRequesting } = props;

  function fetchtoptrack(param) {
    props.dispatch(TopTrackAction.requestTopTrack(param)).then(() => {
      setInitialStart(false);
    });
  }

  useEffect(() => {
    const videoParams = {
      order_by: `[["views","desc"]]`,
      filters: `[["content_type.keyword","must","","match","video"]]`,
      current_page: "analytics",
    };
    fetchtoptrack(videoParams);
  }, []);

  const columns = [
    {
      field: "sl_no",
      headerName: "S No.",
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail_url",
    //   headerName: "",
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Box
    //           style={{
    //             width: 37,
    //             maxHeight: 37,
    //             padding: 5,
    //             // backgroundColor: "#d0e9eb",
    //             // display: "grid",
    //             // placeItems: "center",
    //           }}
    //         >
    //           {/* {params?.row?.thumbnail_url */}
    //           <Rettrive
    //             thumbnail_url={params?.row?.thumbnail_url}
    //             params={params}
    //             current_page={"analytics"}
    //           />
    //           {/* )} */}
    //         </Box>
    //       </div>
    //     );
    //   },
    // },
    {
      // width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        console.log("params", params);
        return (
          <ListItem className="p-0 m-0">
            <div
              style={
                {
                  // marginLeft: 5,
                  // width: "100%",
                  // display: "flex",
                }
              }
            >
              <div
              // style={{ width: "30%" }}
              >
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {}
                  {params &&
                  params.row &&
                  params.row.artists &&
                  params.row.artists.length !== 0
                    ? "By "
                    : ""}
                  {params.row &&
                    params.row.artists &&
                    params.row.artists.length !== 0 &&
                    params.row.artists.map((item, index) => (
                      <span key={index}>
                        {item.artist_id?.artist_name}
                        {index !== params?.row?.artists?.artists?.length - 1 &&
                          ", "}
                      </span>
                    ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },

    {
      field: "views",
      headerName: "Views",
      // width: 150,
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "genre",
      headerName: "Genre",
      // width: 250,
      flex: 1,
      hide: false,
    },
    {
      field: "isrc",
      headerName: "ISRC",
      // width: 250,
      flex: 1,
      hide: false,
    },
    {
      field: "created_on",
      headerName: "Created on",
      // width: 220,
      flex: 1,
      resizable: false,
    },
    {
      field: "production_year",
      headerName: "Production Year",
      // width: 150,
      flex: 1,
      resizable: true,
    },
    {
      field: "publisher",
      headerName: "Publisher",
      // width: 150,
      flex: 2,
      resizable: true,
    },
    {
      field: "release_id",
      headerName: "Release name",
      // width: 150,
      flex: 2,
      resizable: true,
    },
  ];

  let rows = [];

  if (props.topTracks.hits && !initialStart) {
    props.topTracks.hits.map((item, index) => {
      let primaryArtists = "";
      primaryArtists = item._source.primary_artists
        .map((artist) => artist.artist_id.artist_name)
        .join(", ");

      rows.push({
        id: item._id,
        sl_no: index + 1,
        thumbnail_url: item._source.release_id?.thumbnail_url,
        title: item._source.title,
        artists: item._source.primary_artists,
        isrc: item._source.isrc,
        author: item._source.author,
        created_on: new Date(item._source.created_on).toLocaleString(),
        genre: item._source.genre?.genre_name,
        title: item._source.title,
        "music/album": item._source["music/album"],
        published_by: item._source.published_by,
        content_type: item._source.content_type,
        director: item._source.director,
        arranger: item._source.arranger,
        primary_track_type: item._source.primary_track_type,
        views: item._source.views,
        parental_advisory: item._source.parental_advisory,
        producer: item._source.producer,
        producer_catalouge_number: item._source.producer_catalouge_number,
        secondary_track_type: item._source.secondary_track_type,
        track_title_language: item._source.track_title_language,
        production_year: item._source.production_year,
        publisher: item._source.publisher,
        release_id: item._source.release_id.title,
      });
    });
  }

  const handleRowClick = (event, row) => {
    // navigate(`/AnalyticsDetailed`, {
    //   state: {
    //     isrc: event.row.isrc,
    //     thumbnail_url: event.row.upload_cover.props.image,
    //     title: event.row.title,
    //     artists: event.row.artists,
    //   },
    // });
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];

    const params = {
      page_number: `${value}` - 1,
      order_by: `[["views","desc"]]`,
      page_limit: `${page_limit}`,
    };

    if (value - 1 !== props.pagination.current_page_no) {
      fetchtoptrack(params);
    }
  };

  return (
    <Box>
      <div style={{ height: 400, width: "100%" }}>
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting ? (
            <DataGridFunction
              columns={columns}
              rows={rows}
              rowHeight={60}
              isRequesting={isRequesting}
              onRowClick={handleRowClick}
              pagelength={props.pagination.total_page_no}
              page={props.pagination.current_page_no}
              handleChange={handleChange}
            />
          ) : (
            <Box>
              <LoadingBgImage />
            </Box>
          )}
        </LoadingIndicator>
      </div>
    </Box>
  );
}

export default connect(mapStateToProps)(TopVideos);
