import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import { Box, Typography } from "@mui/material";
import { theme } from "../../views/App";
import PageNotFound from "../PageNotFound";

const options = {
  // Customize your chart options here
  scales: {
    x: {
      grid: {
        display: false, // Hide the X-axis grid lines
      },
      display: false,
    },
    y: {
      grid: {
        display: false, // Hide the Y-axis grid lines
      },
      display: false,
    },
  },
};

function StreamsLine(props) {
  const xAxis = [];
  const yAxis = [];

  if (props.audioStreamsReport) {
    props.audioStreamsReport?.forEach((value) => {
      yAxis.push(value.count);
      xAxis.push(
        new Date(value.key).toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        })
      );
    });
  }

  const content = {
    labels: xAxis,

    datasets: [
      {
        label: "Audio Streams",
        data: yAxis,
        fill: false,
        borderColor: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.main,
        tension: 0.1,
      },
    ],
  };

  return (
    <Box height={"100%"} width="100%">
      {yAxis?.length ? (
        <Line data={content} options={options} height={"100%"} />
      ) : (
        <Box>
          <p
            style={{
              fontSize: 18,
            }}
          >
            No Streams Found
          </p>
        </Box>
      )}
    </Box>
  );
}

export default StreamsLine;
