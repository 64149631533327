import ActionUtility from "../../utilities/ActionUtility";
import TopComposerEffect from "./TopComposerEffect";

export default class TopComposerAction {
  // TopComposerAction action with an function declaration
  static REQUEST_TOP_COMPOSER = "TopComposerAction.REQUEST_TOP_COMPOSER";
  static REQUEST_TOP_COMPOSER_FINISHED =
    "TopComposerAction.REQUEST_TOP_COMPOSER_FINISHED";

  static REQUEST_TOP_COMPOSER_FILTER =
    "TopComposerAction.REQUEST_TOP_COMPOSER_FILTER";
  static REQUEST_TOP_COMPOSER_FILTER_FINISHED =
    "TopComposerAction.REQUEST_TOP_COMPOSER_FILTER_FINISHED";

  // METHODS
  static requestTopComposer(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopComposerAction.REQUEST_TOP_COMPOSER,
        TopComposerEffect.requestTopComposer,
        params
      );
    };
  }

  static requestTopComposerFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopComposerAction.REQUEST_TOP_COMPOSER_FILTER,
        TopComposerEffect.requestTopComposerFilter,
        params
      );
    };
  }
}
