import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import StatusBulkPutModel from "./model/StatusBulkPutModel";
import StatusModel from "./model/StatusModel";
import StatusPostModel from "./model/StatusPostModel";
import StatusPutModel from "./model/StatusPutModel";

// StatusEffect
export default class StatusEffect {
  // calling an API according to the status model
  // Get method for the
  static async requestStatus(params) {
    const endpoint = environment.api.status;
    return EffectUtility.getToModel(StatusModel, endpoint, params);
  }

  static async requestStatusClear() {
    return {};
  }

  static async requestStatusFilterClear() {
    return {};
  }

  // Get method for the status filter
  static async requestStatusFilter(params) {
    const endpoint = environment.api.status;
    return EffectUtility.getToModel(StatusModel, endpoint, params);
  }

  // put method for the status
  static async requestPutStatus(data, id) {
    const endpoint = environment.api.status + "/" + id;
    return EffectUtility.putToModel(StatusPutModel, endpoint, data);
  }

  // post method for the status
  static async requestPostStatus(data) {
    const endpoint = environment.api.status + "/add";
    return EffectUtility.postToModel(StatusPostModel, endpoint, data);
  }

  // post method for the status
  static async requestPutStatusBulkUpdate(data) {
    const endpoint = environment.api.statusUpdate;
    return EffectUtility.putToModel(StatusBulkPutModel, endpoint, data);
  }
}
