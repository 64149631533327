import { BaseModel } from "sjs-base-model";

export default class TrackwiseRevenueSummaryModel extends BaseModel {
  revenue_by_month = [];
  store_wise_revenue = [];
  store_wise_streaming = [];
  territory_wise_count = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
