import BaseReducer from "../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Release by id Reducer
export default class FinalReleaseByIdReducer extends BaseReducer {
  initialState = {
    finalReleaseById: [],
  };
  [ReleasesAction.REQUEST_FINAL_RELEASE_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      finalReleaseById: action.payload,
    };
  }
}
