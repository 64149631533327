import ActionUtility from "../../utilities/ActionUtility";
import UserProfileEffect from "./UserProfileEffect";

export default class UserProfileAction {
  static REQUEST_USERPROFILE = "UserProfileAction.REQUEST_USERPROFILE";
  static REQUEST_USERPROFILE_FINISHED =
    "UserProfileAction.REQUEST_USERPROFILE_FINISHED";

  static REQUEST_MAILSUBSCIPTION = "UserProfileAction.REQUEST_MAILSUBSCIPTION";
  static REQUEST_MAILSUBSCIPTION_FINISHED =
    "UserProfileAction.REQUEST_MAILSUBSCIPTION_FINISHED";

  static REQUEST_NOTIFICATION_SUBSCIPTION =
    "UserProfileAction.REQUEST_NOTIFICATION_SUBSCIPTION";
  static REQUEST_NOTIFICATION_SUBSCIPTION_FINISHED =
    "UserProfileAction.REQUEST_NOTIFICATION_SUBSCIPTION_FINISHED";

  static REQUEST_POST_NOTIFICATION_SUBSCIPTION =
    "UserProfileAction.REQUEST_POST_NOTIFICATION_SUBSCIPTION";
  static REQUEST_POST_NOTIFICATION_SUBSCIPTION_FINISHED =
    "UserProfileAction.REQUEST_POST_NOTIFICATION_SUBSCIPTION_FINISHED";

  static REQUEST_PUT_NOTIFICATION_SUBSCIPTION =
    "UserProfileAction.REQUEST_PUT_NOTIFICATION_SUBSCIPTION";
  static REQUEST_PUT_NOTIFICATION_SUBSCIPTION_FINISHED =
    "UserProfileAction.REQUEST_PUT_NOTIFICATION_SUBSCIPTION_FINISHED";

  static REQUEST_PUT_MAILSUBSCIPTION =
    "UserProfileAction.REQUEST_PUT_MAILSUBSCIPTION";
  static REQUEST_PUT_MAILSUBSCIPTION_FINISHED =
    "UserProfileAction.REQUEST_PUT_MAILSUBSCIPTION_FINISHED";

  static REQUEST_POST_MAILSUBSCIPTION =
    "UserProfileAction.REQUEST_POST_MAILSUBSCIPTION";
  static REQUEST_POST_MAILSUBSCIPTION_FINISHED =
    "UserProfileAction.REQUEST_POST_MAILSUBSCIPTION_FINISHED";

  static REQUEST_PUT_USERPROFILE = "UserProfileAction.REQUEST_PUT_USERPROFILE";
  static REQUEST_PUT_USERPROFILE_FINISHED =
    "UserProfileAction.REQUEST_PUT_USERPROFILE_FINISHED";

  static REQUEST_PUT_USERPROFILE_PIC =
    "UserProfileAction.REQUEST_PUT_USERPROFILE_PIC";
  static REQUEST_PUT_USERPROFILE_PIC_FINISHED =
    "UserProfileAction.REQUEST_PUT_USERPROFILE_PIC_FINISHED";

  // USER Profile Reset password
  static REQUEST_PUT_USER_RESET_PASSWORD =
    "UserProfileAction.REQUEST_PUT_USER_RESET_PASSWORD";
  static REQUEST_PUT_USER_RESET_PASSWORD_FINISHED =
    "UserProfileAction.REQUEST_PUT_USER_RESET_PASSWORD_FINISHED";

  // Admin Reset password
  static REQUEST_PUT_ADMIN_RESET_PASSWORD =
    "UserProfileAction.REQUEST_PUT_ADMIN_RESET_PASSWORD";
  static REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED =
    "UserProfileAction.REQUEST_PUT_ADMIN_RESET_PASSWORD_FINISHED";

  // Admin Bank Details
  static REQUEST_PUT_BANK_DETAILS =
    "UserProfileAction.REQUEST_PUT_BANK_DETAILS";
  static REQUEST_PUT_BANK_DETAILS_FINISHED =
    "UserProfileAction.REQUEST_PUT_BANK_DETAILS_FINISHED";

  // METHODS
  static requestUserprofile(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_USERPROFILE,
        UserProfileEffect.requestUserprofile,
        params
      );
    };
  }

  static requestMailSubsciption(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_MAILSUBSCIPTION,
        UserProfileEffect.requestMailSubsciption,
        params
      );
    };
  }

  static requestNotificationSubsciption(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_NOTIFICATION_SUBSCIPTION,
        UserProfileEffect.requestNotificationSubsciption,
        params
      );
    };
  }

  static requestPutNotificationSubsciption(data, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_NOTIFICATION_SUBSCIPTION,
        UserProfileEffect.requestPutNotificationSubsciption,
        data,
        params
      );
    };
  }

  static requestPostNotificationSubsciption(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_POST_NOTIFICATION_SUBSCIPTION,
        UserProfileEffect.requestPostNotificationSubsciption,
        data,
        params,
        callBack
      );
    };
  }

  static requestPutMailSubsciption(data, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_MAILSUBSCIPTION_FINISHED,
        UserProfileEffect.requestPutMailSubsciption,
        data,
        params
      );
    };
  }

  static requestPostMailSubsciption(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_POST_MAILSUBSCIPTION,
        UserProfileEffect.requestPostMailSubsciption,
        data,
        params,
        callBack
      );
    };
  }

  static requestPutUserprofile(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_USERPROFILE,
        UserProfileAction.requestPutUserprofile,
        data
      );
    };
  }

  static requestPutUserprofilePic(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_USERPROFILE_PIC,
        UserProfileEffect.requestPutUserprofilePic,
        data
      );
    };
  }

  static requestUserResetPassword(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_USER_RESET_PASSWORD,
        UserProfileEffect.requestUserResetPassword,
        data
      );
    };
  }

  static requestAdminResetPassword(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_ADMIN_RESET_PASSWORD,
        UserProfileEffect.requestAdminResetPassword,
        data
      );
    };
  }

  static updateBankDetails(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        UserProfileAction.REQUEST_PUT_BANK_DETAILS,
        UserProfileEffect.updateBankDetails,
        data
      );
    };
  }
}
