import { Box, Button, Grid, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import ReleasesShadowTableAction from "../../stores/releasesShadowTable/ReleasesShadowTableAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_BY_ID,
  ]),

  releaseById: state.releaseById.releaseById || {},
  releasesShadowTableById:
    state.releasesShadowTableById.releasesShadowTableById || {},
  pagination: state.releasesShadowTableById.releasesShadowTableById || [],
});

const ReleaseDetailsHistory = (props) => {
  const { isRequesting } = props;

  function fetchReleasesShadowTableById(params) {
    props.dispatch(
      ReleasesShadowTableAction.requestReleasesShadowTableById(params)
    );
  }

  useEffect(() => {
    let params = {
      page_limit: "none",
      current_page: "release_details",
      filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
    };
    fetchReleasesShadowTableById(params);
  }, []);

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  let columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },
    {
      width: 80,
      flex: 1,
      field: "type_of_release",
      headerName: "Type",
      renderCell: (params) => {
        return params.row.type_of_release ? params.row.type_of_release : "";
      },
    },

    {
      width: 130,
      flex: 1,
      field: "status",
      headerName: "Status",
      cellClassName: "status",
    },
    {
      width: 130,
      flex: 1,
      field: "thumbnail_url",
      headerName: "Thumbnail",
      hide: true,
    },

    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            <div
              style={{
                // marginLeft: 5,
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
              }}
              // className="bg-warning"
            >
              <div
                style={{ width: "60%" }}
                // className="bg-success"
              >
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.artists?.length ? "By " : ""}
                  {params?.row?.artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.artists?.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      width: 130,
      flex: 1,
      field: "artists",
      headerName: "Artists",
      hide: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.artists &&
              params.row.artists.length > 0 &&
              params.row.artists.map((artist) => (
                <>
                  <span>{artist.artist_id.artist_name}</span>
                  <br />
                </>
              ))}
          </div>
        );
      },
    },

    {
      width: 130,
      flex: 1,
      field: "label",
      headerName: "Label",
      renderCell: (params) => {
        return params.row.label && params.row.label.label_name
          ? params.row.label.label_name
          : "";
      },
    },
    {
      width: 200,
      flex: 1,
      field: "main_release_date",
      headerName: "Release date",
      renderCell: (params) => {
        return formatDate(params.row.main_release_date);
      },
    },
    {
      width: 130,
      flex: 1,
      field: "no_of_tracks",
      headerName: "No of Track",
    },
    { width: 130, flex: 1, field: "UPC_EAN", headerName: "UPC", hide: true },
  ];

  const rows = [];

  if (props.releasesShadowTableById && props.releasesShadowTableById.hits) {
    props.releasesShadowTableById.hits.hits &&
      props.releasesShadowTableById.hits.hits.length > 0 &&
      props.releasesShadowTableById.hits.hits.map((item, index) => {
        rows.push({
          //   sl_no: item._id
          //     ? props.pagination.current_page_no * page_limit + ++index
          //     : "",
          sl_no: index + 1,
          id: item._id,
          type_of_release: item._source.log.type_of_release,
          status: item._source.log.status?.status_name,
          thumbnail_url: item._source.log.thumbnail_url,
          title: item._source.log.title,
          artists: item._source.log.artists,
          label: item._source.log.label,
          digital_release_date: item._source.log.digital_release_date,
          no_of_tracks: item._source.log.tracks?.length,
          UPC_EAN: item._source.log.UPC_EAN,
          copyrighted_to: item._source.log.copyrighted_to,
          featuring_artists: item._source.log.featuring_artists,
          main_release_date: item._source.log.main_release_date,
          pre_order_release_date: item._source.log.pre_order_release_date,
          production_year: item._source.log.production_year,
          published_by: item._source.log.published_by,
          producer_catalogue_number: item._source.log.producer_catalogue_number,
          genre_type: item._source.log.genre_type,
          sub_genre_type: item._source.log.sub_genre_type,
          format: item._source.log.format,
          territory: item._source.log.territory,
        });
      });
  }

  return (
    <Box>
      <Grid item container alignItems={"center"} justifyContent="center">
        <Grid container item lg={12} md={12} sm={12}>
          <Typography component={"span"}>
            {formatName(`${rows?.length}`)} Results found
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <LoadingIndicator isActive={isRequesting}>
            <DataGridFunction
              columns={columns ? columns : []}
              rows={rows ? rows : []}
              height={500}
              rowHeight={60}
            />
          </LoadingIndicator>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(ReleaseDetailsHistory);
