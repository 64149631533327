import ActionUtility from "../../utilities/ActionUtility";
import YTRevenueReportEffect from "./YTRevenueReportEffect";

export default class YTRevenueReportAction {
  static REQUEST_YT_REVENUE_REPORT =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT";
  static REQUEST_YT_REVENUE_REPORT_FINISHED =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FINISHED";

  static REQUEST_YT_REVENUE_REPORT_EXPORT =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_EXPORT";
  static REQUEST_YT_REVENUE_REPORT_EXPORT_FINISHED =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_EXPORT_FINISHED";

  static REQUEST_YT_REVENUE_REPORT_FILTER =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FILTER";
  static REQUEST_YT_REVENUE_REPORT_FILTER_FINISHED =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FILTER_FINISHED";

  static REQUEST_YT_REVENUE_REPORT_CLEAR =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_CLEAR";
  static REQUEST_YT_REVENUE_REPORT_CLEAR_FINISHED =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_CLEAR_FINISHED";

  static REQUEST_YT_REVENUE_REPORT_FILTER_CLEAR =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FILTER_CLEAR";
  static REQUEST_YT_REVENUE_REPORT_FILTER_CLEAR_FINISHED =
    "YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FILTER_CLEAR_FINISHED";

  // METHODS
  static requestYTRevenueReport(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT,
        YTRevenueReportEffect.requestYTRevenueReport,
        params
      );
    };
  }

  static requestYTRevenueReportExport(params, callBackfunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_EXPORT,
        YTRevenueReportEffect.requestYTRevenueReportExport,
        params,
        callBackfunction
      );
    };
  }

  static requestYTRevenueReportClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_CLEAR,
        YTRevenueReportEffect.requestYTRevenueReportClear
      );
    };
  }

  static requestYTRevenueReportFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FILTER_CLEAR,
        YTRevenueReportEffect.requestYTRevenueReportFilterClear
      );
    };
  }

  static requestYTRevenueReportFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FILTER,
        YTRevenueReportEffect.requestYTRevenueReportFilter,
        params
      );
    };
  }
}
