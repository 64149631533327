import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
// import IngBannerListModel from "./model/IngBannerListModel";
import MigrateTracksModel from "./model/MigrateTracksModel";
import MigrateTracksPostModel from "./model/MigrateTracksPostModel";
import MigrateTracksPutModel from "./model/MigrateTracksPutModel";

// MigrateTracksEffect
export default class MigrateTracksEffect {
  static async requestPostSpotifyMigrateTracks(params, callback) {
    const endpoint = environment.api.spotifyMigrateTrack;
    let response = EffectUtility.postToModel(
      MigrateTracksPostModel,
      endpoint,
      null,
      params
    );

    if (response && callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  static async requestPostAppleMigrateTracks(params, callback) {
    const endpoint = environment.api.appleMusicMigrateTrack;
    let response = EffectUtility.postToModel(
      MigrateTracksPostModel,
      endpoint,
      null,
      params
    );

    if (response && callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }
}
