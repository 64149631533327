import RevenueReportAction from "./RevenueReportAction";
import BaseReducer from "../../utilities/BaseReducer";

export default class RevenueReportFilterReducer extends BaseReducer {
  initialState = {
    revenueReportFilter: [],
  };
  [RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER_FINISHED](state, action) {
    return {
      ...state,
      revenueReportFilter: action.payload,
    };
  }

  [RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      revenueReportFilter: action.payload,
    };
  }
}
