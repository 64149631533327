import { Box, Grid } from "@mui/material";
import React from "react";
import { theme } from "../../views/App";
import StreamsLine from "./StreamsLine";

const StreamsComponent = (props) => {
  let totalCount = 0;

  if (props.audioStreamsReport) {
    for (const item of props.audioStreamsReport) {
      totalCount += item.count;
    }
  }

  return (
    <Box
      width={"100%"}
      sx={{
        background: theme.palette.background.default,
        borderRadius: "12px",
      }}
    >
      <Box paddingY={2} paddingX={2} width="100%">
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          flexDirection={"column"}
          alignItems="flex-start"
          justifyContent={"center"}
        >
          <Grid
            item
            container
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            alignItems="center"
            justifyContent={"flex-start"}
            width={"100%"}
          >
            <Box>
              <h6
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                STREAMS
              </h6>
            </Box>
          </Grid>
          <Grid
            item
            container
            xl={4}
            lg={4}
            md={4}
            sm={4}
            xs={4}
            alignItems="center"
            justifyContent={"flex-start"}
          >
            <Box height={"100%"}>
              <StreamsLine audioStreamsReport={props.audioStreamsReport} />
            </Box>
          </Grid>{" "}
          <Grid
            item
            container
            xl={5}
            lg={5}
            md={5}
            sm={5}
            xs={5}
            alignItems="center"
            justifyContent={"flex-start"}
            mt={4}
          >
            <Box sx={{ lineHeight: 1.2 }}>
              <div
                style={{
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                CURRENT
              </div>
              <div
                style={{
                  fontSize: 32,
                }}
              >
                {totalCount ? totalCount : 0}
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default StreamsComponent;
