import { BaseModel } from "sjs-base-model";

export default class LanguageModel extends BaseModel {
  list_of_language_data = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
