import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Tooltip,
  CircularProgress,
  TextField,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import React, { useState, useEffect, useMemo, useRef } from "react";
import AudioStreamChart from "./charts/AudioStreamsChart";
import { theme } from "../App";
import AddIcon from "@mui/icons-material/Add";
import TopStoresChart from "./charts/TopStoresChart";
import TestChart from "./charts/AudioStreamsChart";
// import TopTracks from "./charts/TopTracks";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import TransactionHistory from "./components/TransactionHistory";
import MuiButton from "../../components/muiButton/MuiButton";
import TopTracks from "./charts/TopTracks";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import CloseIcon from "@mui/icons-material/Close";
import PageNotFound from "../../components/PageNotFound";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { connect } from "react-redux";
import LatestReleasesBox from "./components/LatestReleasesBox";
import ReleaseTable from "../releases/ReleaseTable";
import LatestDraftsBox from "./components/LatestDraftsBox";
import TopReleaseAction from "../../stores/topReleaseReport/TopReleaseAction";
import BlogsAction from "../../stores/blogs/BlogsAction";
import DateSelectionPopper from "./components/DateSelectionPopper";
import ReleaseStatusCountAction from "../../stores/releaseStatusCountReport/ReleaseStatusCountAction";
import ReleaseCountBox from "./components/ReleaseCountBox";
// import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import LabelAction from "../../stores/label/LabelAction";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import LoadingDashboardBgImage from "../../components/loadingBgImage/LoadingDashboardBgImage";
import Autocomplete from "@mui/material/Autocomplete";
import TrackAction from "../../stores/track/TrackAction";
import TrackCountBox from "./components/TrackCountBox";
import DynamicReport from "../dashboard1/DynamicReport";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    // ReleasesAction.REQUEST_DRAFT_RELEASES,
    ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES,
    // TopReleaseAction.REQUEST_TOPRELEASE,
  ]),
  draftReleaseRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_DRAFT_RELEASES,
  ]),
  releaseStatusCountRequesting: selectRequesting(state, [
    ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT,
  ]),
  labelIsRequesting: selectRequesting(state, [
    LabelAction.REQUEST_LABELS_FILTER,
  ]),
  trackStatusCountRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACKSTATUSCOUNT,
  ]),
  draftRelease: state.draftRelease.draftRelease || [],
  topRelease: state.topRelease.topRelease || [],
  top5Delivered: state.top5Delivered.top5Delivered || [],
  releaseStatusCount: state.releaseStatusCount.releaseStatusCount || [],
  trackStatusCount: state.trackStatusCount.trackStatusCount || [],
  blogs: state.blogs.blogs || [],
  labelFilter: state.labelFilter.labelFilter || [],
});

function Dashboard(props) {
  const [label, setLabel] = useState(null);
  const boxRef = useRef(null);
  const [cardOpen, setCardOpen] = useState(null);
  const [cardDetails, setCardDetails] = useState(null);
  function fetchReleases(params) {
    props.dispatch(ReleasesAction.requesDraftReleases(params));
  }

  function fetchBlogs(params) {
    props.dispatch(BlogsAction.requestBlogs(params));
  }
  function fetchTopStatuscount(params = {}) {
    params.current_page = "dashboard";
    props.dispatch(ReleaseStatusCountAction.requestReleaseStatusCount(params));
  }
  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }
  function fetchTrackStatusCount(params) {
    props.dispatch(TrackAction.requestTrackStatusCount(params));
  }

  useEffect(() => {
    const filterparam = {
      page_limit: 5,
      order_by: `[["modified_on","desc"]]`,
      current_page: "dashboard",
      filters: `[["status.keyword","must","","match","1"]]`,
    };

    !label && fetchReleases(filterparam);
    fetchTopReleases();
    fetchTopStatuscount();

    const filterBlogs = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "dashboard",
    };

    fetchBlogs(filterBlogs);

    const labelParam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "dashboard",
    };

    fetchLabelFilter(labelParam);

    let status_count_params = {
      current_page: "dashboard",
    };
    // fetchTrackStatusCount(status_count_params);
  }, []);

  useEffect(() => {
    let filter = [];
    label && filter.push(`["label.keyword","must","","match","${label.id}"]`);

    const topStatusParam = {};
    if (label) {
      topStatusParam.filters = `[${filter}]`;
    }

    fetchTopStatuscount(topStatusParam);
    fetchTopReleases();

    filter.push(`["status.keyword","must","","match","1"]`);

    const filterParam = {
      page_limit: 5,
      order_by: `[["modified_on","desc"]]`,
      current_page: "dashboard",
      filters: `[${filter}]`,
    };

    label && fetchReleases(filterParam);
  }, [label]);

  function fetchTopReleases() {
    let filter = [];
    filter.push(`["status.keyword","must","","match","8"]`);
    label && filter.push(`["label.keyword","must","","match","${label.id}"]`);

    let params = {
      current_page: "dashboard",
      filters: `[${filter}]`,
      order_by: `[["main_release_date","desc"]]`,
      page_limit: "6",
    };
    props.dispatch(ReleasesAction.requesTop5DeliveredReleases(params));
  }

  let latestReleaseList = [];
  if (props && props.top5Delivered && props.top5Delivered.hits) {
    props.top5Delivered.hits.hits.length !== 0 &&
      props.top5Delivered.hits.hits.map((item) => {
        latestReleaseList.push(item);
      });
  }

  let releasecount = [];
  let releaseCountModified = [];
  props?.releaseStatusCount?.release_status_count?.status.forEach((item) => {
    releasecount.push(item);
  });

  let trackcount = [];
  props?.trackStatusCount?.track_status_count?.forEach((item) => {
    trackcount.push(item);
  });

  const submittedIndex = releasecount.findIndex((item) => item.key_id == "2");
  const ingestedIndex = releasecount.findIndex((item) => item.key_id == "7");
  if (submittedIndex !== -1 && ingestedIndex !== -1) {
    releaseCountModified = releasecount
      .map((item, index) => {
        if (index === submittedIndex) {
          return {
            ...item,
            count: item.count + releasecount[ingestedIndex].count,
          };
        } else if (index !== ingestedIndex) {
          return item;
        }
      })
      .filter((item) => item && item.key_id !== "7");
  } else {
    releaseCountModified = releasecount;
  }

  let latestDraftsList = [];
  props.draftRelease?.hits?.hits
    ?.filter((item) => item?._source?.status?.id === "1")
    .map((item) => {
      latestDraftsList.push(item);
    });

  const latestReleases = useMemo(
    () =>
      latestReleaseList && latestReleaseList.length !== 0 ? (
        <LatestReleasesBox latestReleaseList={latestReleaseList} />
      ) : (
        <PageNotFound page="Reports" />
      ),
    [latestReleaseList]
  );

  const latestDraft = useMemo(
    () =>
      latestDraftsList && latestDraftsList.length !== 0 ? (
        <LatestDraftsBox latestDraftsList={latestDraftsList} />
      ) : (
        <PageNotFound page="Reports" />
      ),
    [latestDraftsList]
  );

  const releasecountlist = useMemo(
    () =>
      releaseCountModified && releaseCountModified.length !== 0 ? (
        <ReleaseCountBox
          releasecount={releaseCountModified}
          labelFilter={label}
        />
      ) : (
        <PageNotFound page="Reports" />
      ),
    [releaseCountModified]
  );

  const trackStatusCount = useMemo(
    () =>
      trackcount && trackcount.length !== 0 ? (
        <TrackCountBox trackcount={trackcount} />
      ) : (
        <PageNotFound page="Reports" />
      ),
    [trackcount]
  );

  let labelList = [];
  props?.labelFilter?.hits?.hits?.map((item) => {
    if (item?._source?.label_name)
      labelList.push({
        id: item?._id,
        label_name: item._source.label_name,
      });
  });
  labelList.sort((a, b) => a.label_name.localeCompare(b.label_name));
  const labelListHandleChange = (event, searchLabel) => {
    setLabel(searchLabel);
  };

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes blink-shadow {
            0% {
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
            }
            50% {
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.6);
            }
            100% {
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
            }
          }
          @keyframes zoom-in {
            0% {
              opacity: 0;
              transform: scale(0.5);
            }
            50% {
              transform: scale(1.05);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
      `,
        }}
      />
      <Box
        ref={boxRef}
        sx={{
          backgroundColor: "white",
        }}
      >
        {/* ****************************   DYNAMIC REPORT ********************************** */}
        <Box
          sx={{
            marginBottom: 2,
          }}
        >
          <DynamicReport />
        </Box>

        {/* Top Filter Section */}
        <Grid
          container
          lg={12}
          sm={12}
          md={12}
          display={"flex"}
          justifyContent="flex-end"
          py={2}
          px={4.5}
          // m={10}
          style={{
            position: "sticky",
            top: `${boxRef?.current?.offsetTop}px`,
            backgroundColor: "white",
            zIndex: 100,
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
            borderRadius: "8px",
          }}
        >
          {" "}
          <Grid item>
            {" "}
            {props.labelIsRequesting && (
              <HourglassTopIcon
                sx={{
                  marginRight: "15px",
                  marginTop: "7px",
                  animation: props.labelIsRequesting
                    ? "rotateAnimation 2s linear infinite"
                    : "",
                }}
              />
            )}
            {!props.isRequesting && label && (
              <Tooltip title="Clear Label" arrow placement="left">
                <CancelRoundedIcon
                  sx={{
                    marginRight: "15px",
                    marginTop: "7px",
                    transition: "transform 0.3s",
                    animation: `zoom-in 0.5s ease`,
                    "&:hover": {
                      transform: "scale(1.4)",
                    },
                  }}
                  onClick={() => {
                    setLabel(null);
                  }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid
            item
            xs={10}
            sm={2.5}
            sx={{
              animation:
                props.isRequesting && label
                  ? "blink-shadow 1.2s infinite"
                  : !label
                  ? "zoom-in 0.3s ease-in-out"
                  : "none",
            }}
          >
            <Autocomplete
              value={label}
              options={labelList}
              onChange={labelListHandleChange}
              // onOpen={() => setOpenDropdown(true)}
              // onClose={() => setOpenDropdown(false)}
              // open={openDropdown}
              isOptionEqualToValue={(option, value) =>
                option.id === value.label_name
              }
              getOptionLabel={(option) => option.label_name}
              renderOption={(props, item) => (
                <li {...props} key={item.id}>
                  {item.label_name}
                </li>
              )}
              size="small"
              width="100%"
              disabled={props.labelIsRequesting}
              disablePortal
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Label"
                  variant="outlined"
                />
              )}
              PaperComponent={({ children }) => (
                <Paper
                  style={{
                    border: `1px solid ${theme.palette.primary.main}`,
                  }}
                >
                  {children}
                </Paper>
              )}
            />
          </Grid>
        </Grid>

        {/************Track Status Count Data Display************************* */}
        {/*  <Grid
          container
          lg={11.95}
          md={11.9}
          sm={11.9}
          p={2}
          sx={{
            backgroundColor: "white",
            gap: 2,
            paddingTop: 4,
          }}
        >
          <Grid
            item
            lg={11.98}
            md={11.9}
            sm={11.9}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              borderRadius: 2,
              overflow: "hidden",
              paddingX: 2,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component={"div"}
                sx={{ fontSize: "16px", fontWeight: "bold", marginY: "8px" }}
              >
                {formatName("Track Status")}
              </Typography>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
            </Grid>

            <LoadingIndicator isActive={props.trackStatusCountRequesting}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                {props.trackStatusCountRequesting ? (
                  <Box>
                    <LoadingDashboardBgImage />
                  </Box>
                ) : (
                  trackStatusCount
                )}
              </Grid>
            </LoadingIndicator>
          </Grid>
        </Grid> */}
        {/*  **************** Audio Streams  and Balance  ****************************  */}
        {/* <Grid
          container
          lg={12}
          md={12}
          sm={12}
          px={2}
          sx={{
            backgroundColor: "white",
            gap: 2,
          }}
        >
          <Grid
            item
            xl={5.9}
            lg={11.9}
            md={11.9}
            sm={11.9}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              borderRadius: 2,
              overflow: "hidden",
              paddingX: 2,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <AudioStreamChart label={label} />
          </Grid>

          <Grid
            item
            xl={5.9}
            lg={11.9}
            md={11.9}
            sm={11.9}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              borderRadius: 2,
              overflow: "hidden",
              paddingX: 2,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <TopStoresChart label={label} />
          </Grid>

          
        </Grid> */}

        {/* <Grid
          item
          lg={5.9}
          md={11.9}
          sm={11.9}
          sx={{
            border: `1px solid ${theme.reports.gridBorder}`,
            borderRadius: 2,
            overflow: "hidden",
            padding: 2,
            boxSizing: "border-box",
            height: "auto",
          }}
        >
          <Grid item xs={12}>
            <Typography
              component={"div"}
              sx={{ fontSize: "24px", display: "flex", alignItems: "center" }}
            >
              <span style={{ fontWeight: "bold" }}>
                {formatName("Your Balance")} | &nbsp;
              </span>
              <span style={{ color: "grey" }}>
                <Typography component={"span"} sx={{ fontSize: 20 }}>
                  Last Month
                </Typography>
              </span>
            </Typography>
            <hr
              className="p-0 m-0"
              style={{ color: theme.palette.text.gray }}
            />
          </Grid>
          *Disclaimer: This month's requested payment will be released by 15th
          of next month*
          <PageNotFound page="Balance" />
        </Grid> */}

        {/*  **************** Top Stores ****************************  */}

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          p={2}
          sx={{
            backgroundColor: "white",
            gap: 2,
          }}
        >
          {/* ******************** Latest Release **************************** */}

          <Grid
            item
            lg={5.9}
            md={11.9}
            sm={11.9}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              borderRadius: 2,
              overflow: "hidden",
              paddingX: 2,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component={"div"}
                sx={{ fontSize: "16px", fontWeight: "bold", marginY: "8px" }}
              >
                {formatName("Latest Releases")}
              </Typography>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
            </Grid>
            <LoadingIndicator isActive={props.isRequesting}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                {!props.isRequesting ? (
                  latestReleases
                ) : (
                  <Box>
                    <LoadingDashboardBgImage />
                  </Box>
                )}
              </Grid>
            </LoadingIndicator>
          </Grid>

          {/*  **************** Latest Drafts container  ****************************  */}
          <Grid
            item
            lg={5.9}
            md={11.9}
            sm={11.9}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              borderRadius: 2,
              overflow: "hidden",
              paddingX: 2,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component={"div"}
                sx={{ fontSize: "16px", fontWeight: "bold", marginY: "8px" }}
              >
                {formatName("Latest Drafts")}
              </Typography>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
            </Grid>
            <LoadingIndicator isActive={props.draftReleaseRequesting}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  paddingY: !props.draftReleaseRequesting && 5,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                {!props.draftReleaseRequesting ? (
                  latestDraft
                ) : (
                  <Box>
                    <LoadingDashboardBgImage />
                  </Box>
                )}
              </Grid>
            </LoadingIndicator>
          </Grid>
        </Grid>

        {/*  **************** News Highlight  ****************************  */}

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          px={2}
          sx={{
            backgroundColor: "white",
            gap: 2,
          }}
        >
          <Grid
            item
            lg={5.9}
            md={11.9}
            sm={11.9}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              borderRadius: 2,
              overflow: "hidden",
              paddingX: 2,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component={"div"}
                sx={{ fontSize: "16px", fontWeight: "bold", marginY: "8px" }}
              >
                {"A3"} {formatName("News Highlight")}
              </Typography>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
            </Grid>
            {props?.blogs?.hits?.hits?.length !== 0 ? (
              <Grid
                container
                item
                xs={12}
                px={5}
                pt={5}
                paddingY={2}
                spacing={2} // Add spacing between grid items
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  overflow: "auto",
                  height: "620px",
                  marginTop: 0,
                }}
              >
                {props?.blogs?.hits?.hits?.map((item, index) => (
                  <Grid item xs={5} key={index}>
                    <Card
                      sx={{
                        height: 275,
                        cursor: "pointer",
                        boxShadow: " 0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                      }}
                      onClick={() => {
                        setCardOpen(true);
                        setCardDetails(item);
                      }}
                    >
                      <Box sx={{ height: "20%" }}>
                        <Avatar
                          src={item?._source?.thumbnail_url}
                          sx={{
                            width: 56,
                            height: 56,
                            margin: 1,
                          }}
                        />
                      </Box>
                      <Box sx={{ height: "40%" }}>
                        <CardContent
                          style={{
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingBottom: 0,

                            height: "100%",

                            // "& .MuiCardContent-root": {
                            //   paddingBottom: 0,
                            // },
                          }}
                        >
                          <Typography
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              wordWrap: "break-word",
                              WebkitLineClamp: 4,
                              WebkitBoxOrient: "vertical",
                              fontSize: "16px",
                              height: "100%",
                            }}
                          >
                            {item?._source?.blog_title}
                          </Typography>
                        </CardContent>
                      </Box>
                      <Box sx={{ height: "40%" }}>
                        <CardContent
                          sx={{ padding: 0, px: 1, pb: 0, height: "100%" }}
                        >
                          <Typography
                            sx={{
                              display: "-webkit-box",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              wordWrap: "break-word",
                              WebkitLineClamp: 5,
                              WebkitBoxOrient: "vertical",
                              fontSize: "12px",
                              height: "100%",
                            }}
                          >
                            {item?._source?.blog_description}
                          </Typography>
                        </CardContent>
                      </Box>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <PageNotFound page={"Reports"} />
            )}
          </Grid>

          <Grid
            item
            lg={5.9}
            md={11.9}
            sm={11.9}
            sx={{
              border: `1px solid ${theme.reports.gridBorder}`,
              borderRadius: 2,
              overflow: "hidden",
              paddingX: 2,
              boxSizing: "border-box",
              height: "auto",
            }}
          >
            <Grid item xs={12}>
              <Typography
                component={"div"}
                sx={{ fontSize: "16px", fontWeight: "bold", marginY: "8px" }}
              >
                {formatName("Release Status")}
              </Typography>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
            </Grid>

            <LoadingIndicator isActive={props.releaseStatusCountRequesting}>
              <Grid
                container
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-evenly",
                }}
              >
                {props.releaseStatusCountRequesting ? (
                  <Box>
                    <LoadingDashboardBgImage />
                  </Box>
                ) : (
                  releasecountlist
                )}
              </Grid>
            </LoadingIndicator>
          </Grid>
        </Grid>

        <br />
        <Dialog
          open={cardOpen}
          // onClose={handleClose}
          fullWidth
          maxWidth="md"
          sx={{
            "& .MuiDialog-paper": {
              width: "100%", // Set width to 80% of the screen
              height: "100%", // Set height to 80% of the screen
              borderRadius: "8px", // Optional: rounded corners
            },
          }}
        >
          <DialogTitle display={"flex"} justifyContent={"space-between"}>
            {" "}
            <Avatar
              src={cardDetails?._source?.thumbnail_url}
              sx={{
                width: 56,
                height: 56,
                margin: 1,
              }}
            />
            <CloseIcon
              sx={{ cursor: "pointer" }}
              onClick={() => setCardOpen(false)}
            />
          </DialogTitle>
          <DialogContent sx={{ overflowY: "scroll" }}>
            <Typography sx={{ fontSize: "25px", pb: "30px" }}>
              {cardDetails?._source?.blog_title}
            </Typography>
            <Typography sx={{ fontSize: "18px" }}>
              {cardDetails?._source?.blog_description}
            </Typography>
          </DialogContent>
        </Dialog>
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(Dashboard);
