import { BaseModel } from "sjs-base-model";

export default class TerritoriesModel extends BaseModel {
  territory_data = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
