import { Box, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import { theme } from "../../App";

const TrackCountBox = ({ trackcount, clientFilter, labelFilter }) => {
  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  trackcount = trackcount.sort((a, b) => b.track_count - a.track_count);

  return (
    <>
      {trackcount?.map(
        (item) =>
          item.key_id !== "6" && (
            <Card sx={{ width: "220px" }}>
              <CardContent>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      boxShadow: theme.shadows,
                      borderRadius: 1,
                    }}
                  >
                    <Box
                      sx={{
                        width: "40%",
                        alignItems: "center",
                        padding: 1,
                        overflow: "hidden",
                        marginLeft: 9,
                      }}
                    >
                      <Typography sx={{ fontSize: 20, fontWeight: "bold" }}>
                        {item.total_tracks_size}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      paddingY: 1,
                    }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      {convertToTitleCase(item.status_name)}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )
      )}
    </>
  );
};

export default TrackCountBox;
