import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import YTNetRevenueByMonthGetModel from "./models/YTNetRevenueReportByMonthGetModel";

// YTNetRevenueByMonthEffect
export default class YTNetRevenueByMonthEffect {
  // calling an API according to the SessionLog model
  static async requestNetRevenueByMonth(params) {
    const endpoint = environment.api.ytNetRevenueByMonth;
    return EffectUtility.getToModel(
      YTNetRevenueByMonthGetModel,
      endpoint,
      params
    );
  }
  static async requestNetRevenueByMonthFilter(params) {
    const endpoint = environment.api.ytNetRevenueByMonth;
    return EffectUtility.getToModel(
      YTNetRevenueByMonthGetModel,
      endpoint,
      params
    );
  }

  static async requestNetRevenueByMonthFilterClear() {
    return {};
  }
}
