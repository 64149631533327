import BaseReducer from "../../utilities/BaseReducer";
import TopTrackAction from "./TopTrackAction";

// TrackReduser
export default class TopTrackWithDateFilterReducer extends BaseReducer {
  initialState = {
    topTrack_with_date_filter: [],
  };
  [TopTrackAction.REQUEST_TOPTRACK_WITH_DATE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      topTrack_with_date_filter: action.payload,
    };
  }
}
