import BaseReducer from "../../utilities/BaseReducer";
import ArtistAction from "./ArtistAction";

// ArtistReduser
export default class ArtistReduser extends BaseReducer {
  initialState = {
    artist: [],
  };
  [ArtistAction.REQUEST_ARTIST_FINISHED](state, action) {
    return {
      ...state,
      artist: action.payload,
    };
  }
}
