import { BaseModel } from "sjs-base-model";
import { v4 } from "uuid";

// StatusDataModel
export default class StatusDataModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;
    // response getting from the api
    id = v4();
    created_by = {
        user_id: "",
        user_name: "",
    };
    created_on = "";
    modified_by = {
        user_id: "",
        user_name: "",
    };
    modified_on = "";
    status_name = "";
    initial_status = Boolean;

    constructor(data) {
        super();

        this.update(data);
    }
}
