import React, { useEffect } from "react";
import { IconButton, Stack } from "@mui/material";
import "./postPluButton.css";
import AddIcon from "@mui/icons-material/Add";
import { theme } from "../../views/App";
import ClearIcon from "@mui/icons-material/Clear";
import { useState } from "react";

// post plus button component
const PostPlusButton = (props) => {
  const [add, setAdd] = useState();

  useEffect(() => {
    if (!props.open) {
      setAdd(false);
    }
  }, [props.open]);

  return (
    <Stack>
      <IconButton
        title="Add"
        aria-label="add"
        sx={{
          backgroundColor: "transparent",
          height: theme.palette.addButton.height,
          width: theme.palette.addButton.width,
          borderRadius: theme.palette.addButton.borderRadius,
          border: "solid 2px #2a5e6c",
          "&:hover": { color: "#2a5e6c" },
        }}
        onClick={
          props.onClick
            ? props.onClick
            : () => {
                props.openCloseModal(!props.open);
                props.editMode && props.editMode(!props.edit);
                setAdd(true);
              }
        }
      >
        {add ? (
          <ClearIcon
            className="clearIcon"
            sx={{ color: theme.palette.primary.main }}
          />
        ) : (
          <AddIcon className="AddIcon" sx={{ color: "#2a5e6c" }} />
        )}
      </IconButton>
    </Stack>
  );
};

export default PostPlusButton;
