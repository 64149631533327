import React, { useEffect, useState, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import PostPlusButton from "../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import MuiButton from "../../components/muiButton/MuiButton";
import Grid from "@mui/material/Grid";
import {
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import {
  Typography,
  OutlinedInput,
  MenuItem,
  Select,
  useTheme,
  Chip,
  IconButton,
} from "@mui/material";
import ClientAction from "../../stores/client/ClientAction";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { useLocation, useNavigate } from "react-router";
import RevenueReportAction from "../../stores/revenueReport/RevenueReportAction";
import RevenueReportFileUploader from "./RevenueReportFileUploader";
import dayjs from "dayjs";
import { format } from "date-fns";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Tooltip from "@mui/material/Tooltip";
import RevenueMultiLevelFilter from "./RevenueMultiLevelFilter";
import LabelAction from "../../stores/label/LabelAction";
import { CSVLink } from "react-csv";
import StreamPlatformAction from "../../stores/streamPlatform/StreamPlatformAction";
import ExportDailoge from "./ExportDailoge";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faPenToSquare } from "@fortawesome/free-solid-svg-icons";

// mapstatetoprops function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    RevenueReportAction.REQUEST_REVENUE_REPORT,
    RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER,
    ClientAction.REQUEST_CLIENT,
    ClientAction.REQUEST_CLIENT_FILTER,
    ReleasesAction.REQUEST_RELEASES,
    LabelAction.REQUEST_LABELS_FILTER,
    StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER,
    RevenueReportAction.REQUEST_FILTER_REVENUE_REPORT,
  ]),
  release: state.release.release || [],
  revenueReport: state.revenueReport.revenueReport || [],
  revenueReportFilter: state.revenueReportFilter.revenueReportFilter || [],
  client: state.client.client || [],
  clientFilter: state.clientFilter.clientFilter || [],
  pagination: state.filterRevenueReport.filterRevenueReport || [],
  labelFilter: state.labelFilter.labelFilter || [],
  stream_platform_filter:
    state.stream_platform_filter.stream_platform_filter || [],
  filterRevenueReport: state.filterRevenueReport.filterRevenueReport || [],
});

function RevenueReport(props) {
  const navigate = useNavigate();

  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(20);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [searchClient, setsearchClient] = useState(null);
  let [errorMessage, setErrorMessage] = useState(null);

  const [csvData, setCsvData] = useState([]);
  const csvLink = useRef();

  const [type, setType] = useState("");
  const [client, setClient] = useState(null);
  const [retailer, setRetailer] = useState(null);
  const oneMonthBefore = dayjs().subtract(1, "month").startOf("month").toDate();
  const [monthFilter, setMonthFilter] = useState(oneMonthBefore);
  const [releaseFilter, setReleaseFilter] = useState(null);
  const [isrcFilter, setisrcFilter] = useState(null);
  const [upcFilter, setupcFilter] = useState(null);
  const [labelFilter, setLabelFilter] = useState(null);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [chipContent, setChipContent] = useState([]);
  const [filterContents, setFilterContents] = useState([]);
  const [multiLevelFilterDialog, setMultiLevelFilterDialog] = useState(false);
  const [exportDialog, setExportDialog] = useState(false);

  const { isRequesting } = props;

  // **************************************************  UseEffect

  function fetchRevenueReport(params) {
    props.dispatch(RevenueReportAction.requestRevenueReport(params));
  }
  function fetchClient(params) {
    props.dispatch(ClientAction.requestClient(params));
  }
  function fetchClientFilter() {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      filters: `[["parent_client_id.keyword","must","","match","*"]]`,
    };
    props.dispatch(ClientAction.requestClientFilter(param));
  }
  function fetchReleases(params) {
    props.dispatch(ReleasesAction.requesReleases(params));
  }
  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }
  function fetchPlatformFilter(param) {
    props.dispatch(StreamPlatformAction.requestPlatformFilter(param));
  }

  const fetchfilterRevenueReport = (params) => {
    props.dispatch(RevenueReportAction.requestFilterRevenueReport(params));
  };

  useEffect(() => {
    const filter = [];

    filter.push(
      monthFilterResponse(dayjs().subtract(1, "month").startOf("month"))
    );

    const param = {
      page_limit: page_limit,
      filters: `[${filter}]`,

      order_by: `[["created_on","desc"]]`,
    };
    const platformfilterparam = {
      page_limit: "none",
      order_by: `[["stream_platform_name.keyword","asc"]]`,
      current_page: "revenueReport",
    };
    const labelParam = {
      page_limit: "none",
      order_by: `[["label_name.keyword","asc"]]`,
      current_page: "revenueReport",
    };

    // fetchClient(param);
    // fetchRevenueReport(param);
    // fetchClientFilter();
    fetchLabelFilter(labelParam);
    fetchPlatformFilter(platformfilterparam);

    let revenueParam = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
      current_page: "revenueReport",
    };

    fetchfilterRevenueReport(revenueParam);
    // fetchReleases();
  }, []);

  let clientList = [];
  props?.client?.hits?.hits?.map((item) => {
    if (item?._source?.client_name)
      clientList.push({
        id: item?._id,
        client_name: item._source.client_name,
      });
  });

  let releasesList = [];
  props?.release?.hits?.hits?.map((item) => {
    if (item?._source?.title)
      releasesList.push({
        id: item?._id,
        name: item._source.title,
      });
  });

  let platforms_list = [];
  props.stream_platform_filter?.hits?.hits?.map((item) => {
    if (item?._source?.stream_platform_name) {
      platforms_list.push({
        id: item?._id,
        name: item?._source?.stream_platform_name,
      });
    }
  });

  let labelList = [];
  props?.labelFilter?.hits?.hits?.map((item) => {
    if (item?._source?.label_name)
      labelList.push({
        id: item?._id,
        label_name: item._source.label_name,
      });
  });
  const groupedRoles = releasesList.reduce((groups, role) => {
    const name = role.name;
    const existingGroup = groups.find((group) => group.name === name);
    if (existingGroup) {
      existingGroup.options.push(role);
    } else {
      groups.push({ name, options: [role] });
    }
    return groups;
  }, []);

  function monthFilterResponse(date) {
    const firstDayOfMonth = dayjs(date).startOf("month");
    const lastDayOfMonth = dayjs(date).endOf("month");
    let filterString = ``;

    filterString = `["sales_reported_date","must","gte","range","${format(
      firstDayOfMonth.$d,
      "yyyy-MM-dd"
    )}"],["sales_reported_date","must","lte","range","${format(
      lastDayOfMonth.$d,
      "yyyy-MM-dd"
    )}"]`;

    return filterString;
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);
  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // *******************************************************  handle Filter
  const applyFilter = () => {
    const filter = [];

    if (client) {
      filter.push(`["client_id.keyword","must","","term","${client.id}"]`);
    }
    if (retailer) {
      filter.push(
        `["retailer_name.keyword","must","","wildcard","*${retailer?.name}*"]`
      );
    }
    if (releaseFilter) {
      filter.push(
        `["release_title.keyword","must","","wildcard","*${releaseFilter}*"]`
      );
    }
    if (isrcFilter) {
      filter.push(`["isrc.keyword","must","","wildcard","*${isrcFilter}*"]`);
    }

    if (upcFilter) {
      filter.push(`["upc.keyword","must","","wildcard","*${upcFilter}*"]`);
    }

    if (monthFilter) {
      filter.push(monthFilterResponse(monthFilter));
    }
    if (labelFilter) {
      filter.push(`["label.keyword","must","","match","${labelFilter.id}"]`);
    }

    if (
      client !== null ||
      releaseFilter !== null ||
      isrcFilter !== null ||
      upcFilter !== null ||
      retailer !== null ||
      monthFilter !== null ||
      labelFilter !== null
    ) {
      const params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        current_page: "revenueReport",
      };

      fetchRevenueReport(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      setClearLoader(false);
      const filter = [];

      filter.push(
        monthFilterResponse(dayjs().subtract(1, "month").startOf("month"))
      );

      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,
        page_limit: page_limit,
        current_page: "revenueReport",
      };

      fetchRevenueReport(params);
    }
  };

  const ClearFilter = () => {
    if (
      client ||
      releaseFilter ||
      isrcFilter ||
      upcFilter ||
      retailer ||
      monthFilter ||
      labelFilter
    ) {
      setFilterLoader(false);
      setClient(null);
      setMonthFilter(oneMonthBefore);
      setRetailer(null);
      setReleaseFilter(null);
      setisrcFilter(null);
      setupcFilter(null);

      setLabelFilter(null);
      setClearLoader(true);
      setFilterClicked(false);
      setChipContent([]);
      setFilterContents([]);

      const filter = [];

      filter.push(
        monthFilterResponse(dayjs().subtract(1, "month").startOf("month"))
      );

      const params = {
        order_by: '[["created_on", "desc"]]',
        filters: `[${filter}]`,

        page_limit: page_limit,
      };
      FilterClicked && fetchRevenueReport(params);
    } else {
      setAlertPopUp(true);
    }
  };
  //********************************************************* pagination

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "client_name",
      headerName: "Client Name",
      width: 150,
    },
    {
      field: "period",
      headerName: "Period",
      width: 150,
    },

    {
      field: "retailer_name",
      headerName: "Retailer",
      width: 150,
    },

    {
      field: "retailer_reporting_period",
      headerName: "Retailer Reporting Period",
      width: 150,
    },
    {
      field: "sales_reported_date",
      headerName: "Sales Reported Period",
      width: 150,
    },

    {
      field: "label_name",
      headerName: "Label Name",
      width: 150,
    },

    {
      field: "artist",
      headerName: "Artist",
      width: 150,
    },
    {
      field: "release_title",
      headerName: "Release Title",
      width: 150,
    },

    {
      field: "upc",
      headerName: "UPC",
      width: 150,
      //resizable: false,
    },
    {
      field: "release_catalouge_number",
      headerName: "Product / Catalog #",
      width: 150,
    },
    {
      field: "track_title",
      headerName: "Song",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mix_(version)",
      headerName: "Mix (Version)",
      width: 150,
    },

    {
      field: "isrc",
      headerName: "ISRC",
      width: 150,
    },

    {
      field: "genre",
      headerName: "Genre",
      width: 150,
    },

    {
      field: "release_date",
      headerName: "Release Date",
      width: 150,
    },

    {
      field: "retailer_stmt_country_iso",
      headerName: "Retailer Stmt Country ISO",
      width: 150,
    },

    {
      field: "territory",
      headerName: "Territory",
      width: 150,
    },

    {
      field: "asset_type",
      headerName: "Asset Type",
      width: 150,
    },
    {
      field: "sales_classification",
      headerName: "Sales Classification",
      width: 200,
    },

    {
      field: "sales_description",
      headerName: "Sales Description",
      width: 250,
    },

    {
      field: "quantity_net",
      headerName: "Quantity Net",
      width: 150,
    },
    {
      field: "cv_amount",
      headerName: "Gross Net",
      width: 150,
      //resizable: false,
    },

    {
      field: "cv_share_percentage",
      headerName: "Client Share Percentage",
      //resizable: false,
      width: 150,
    },
    {
      field: "client_share_amount",
      headerName: "Client Share Amount",
      //resizable: false,
      width: 150,
    },
    {
      field: "cv_label_share",
      headerName: "Label Share Percentage",
      width: 150,
    },

    {
      field: "label_share_amount",
      headerName: "Label Revenue Amount",
      //resizable: false,
      width: 150,
    },
  ];

  const filterRevenueColumns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "month",
      headerName: "Month",
      // flex: 1,
    },

    {
      field: "total_amount",
      headerName: "Total Amount",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "report_type",
      headerName: "Report Type",
      flex: 1,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "Export",
      width: 160,
      hide: false,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <IconButton
            title={"Export"}
            onClick={(event) => {
              event.ignore = true;
              setExportDialog(true);
              setEditRowsModel(params.row);
            }}
            sx={{ border: "0" }}
          >
            <FontAwesomeIcon
              icon={faFileExport}
              style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
              title="Edit"
            ></FontAwesomeIcon>
          </IconButton>
        );
      },
    },

    ,
  ];

  if (JSON.parse(localStorage.getItem("page_permission")).includes("*")) {
    columns
      .push
      // {
      //   field: "cv_share_percentage",
      //   headerName: "Share Policy",
      //   //resizable: false,
      // },
      // {
      //   field: "client_share_amount",
      //   headerName: "Client Share Amount",
      //   //resizable: false,
      // }
      ();
  }

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setFilterLoader(false);
    setClearLoader(false);
    const filter = [];
    let params = {};

    if (client) {
      filter.push(`["client_id.keyword","must","","term","${client.id}"]`);
    }
    if (retailer) {
      filter.push(
        `["retailer_name.keyword","must","","wildcard","*${retailer?.name}*"]`
      );
    }
    if (releaseFilter) {
      filter.push(
        `["release_title.keyword","must","","wildcard","*${releaseFilter}*"]`
      );
    }

    if (isrcFilter) {
      filter.push(`["isrc.keyword","must","","wildcard","*${isrcFilter}*"]`);
    }

    if (upcFilter) {
      filter.push(`["upc.keyword","must","","wildcard","*${upcFilter}*"]`);
    }

    if (monthFilter) {
      filter.push(monthFilterResponse(monthFilter));
    }
    if (labelFilter) {
      filter.push(`["label.keyword","must","","match","${labelFilter.id}"]`);
    }

    if (
      client !== null ||
      releaseFilter !== null ||
      isrcFilter !== null ||
      upcFilter !== null ||
      retailer !== null ||
      monthFilter !== null ||
      labelFilter !== null
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetchRevenueReport(params);
    }
  };

  const filterRevenueHandleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setFilterLoader(false);
    setClearLoader(false);
    const filter = [];
    let params = {};

    if (
      client !== null ||
      releaseFilter !== null ||
      isrcFilter !== null ||
      upcFilter !== null ||
      retailer !== null ||
      monthFilter !== null ||
      labelFilter !== null
    ) {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
        current_page: "revenueReport",
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
        current_page: "revenueReport",
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetchfilterRevenueReport(params);
    }
  };

  function returnMonthName(monthString) {
    let dateObj = new Date(monthString);
    let options = { year: "numeric", month: "short" };
    let formattedDate = dateObj.toLocaleDateString("default", options);
    return formattedDate;
  }

  // ********************************************************* DataGrid  rows
  const rows = [];
  const filterRevenuerows = [];
  if (props.revenueReport && props.revenueReport.hits) {
    props.revenueReport.hits.hits &&
      props.revenueReport.hits.hits.length > 0 &&
      props.revenueReport.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          artist: item._source?.artist,
          asset_type: item._source?.asset_type,
          client_id: item._source?.client_id,
          client_name: item._source?.client_id?.client_name,
          client_share_amount: item._source?.client_share_amount,
          created_on: item._source.created_on,
          cv_amount: item._source?.cv_amount,
          cv_label_share: item._source?.cv_label_share,
          cv_share_percentage: item._source?.cv_share_percentage,
          genre: item._source?.genre,
          isrc: item._source?.isrc,
          label: item._source?.label,
          label_name: item._source?.label.label_name,
          label_share_amount: item._source?.label_share_amount,
          main_label: item._source?.main_label,
          "mix_(version)": item._source["mix_(version)"],
          period: item._source?.period,
          quantity_net: item._source?.quantity_net,
          release_catalouge_number: item._source?.release_catalouge_number,
          release_date: item._source?.release_date,
          release_id: item._source?.release_id,
          release_title: item._source?.release_title,
          retailer_name: item._source?.retailer_name,
          retailer_reporting_period: item._source?.retailer_reporting_period,
          sales_reported_date: item._source?.sales_reported_date,
          retailer_stmt_country_iso: item._source?.retailer_stmt_country_iso,
          sales_classification: item._source?.sales_classification,
          sales_description: item._source?.sales_description,
          territory: item._source?.territory,
          track_id: item._source?.track_id,
          track_title: item._source?.track_title,
          upc: item._source?.upc,
          watching_hours: item._source?.watching_hours,
        });
      });
  }

  if (props.filterRevenueReport && props.filterRevenueReport.hits) {
    props.filterRevenueReport.hits.hits &&
      props.filterRevenueReport.hits.hits.length > 0 &&
      props.filterRevenueReport.hits.hits.map((item, index) => {
        filterRevenuerows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          month: returnMonthName(item._source.to_month),
          monthDate: item._source.to_month,
          total_amount: item._source.total_amount.toFixed(2),
          report_type: item._source.report_type,
        });
      });
  }

  let client_list = [];
  props.client?.hits?.hits?.length > 0 &&
    props.client?.hits?.hits.map((client) => {
      client_list.push({
        client_id: client._id,
        client_name: client._source?.client_name,
        username: client._source?.username,
      });
    });

  function getChipContent() {
    const content = [];
    if (client) content.push({ id: "1", name: client.client_name });
    if (releaseFilter) content.push({ id: "2", name: releaseFilter });
    if (isrcFilter) content.push({ id: "6", name: isrcFilter });
    if (upcFilter) content.push({ id: "7", name: upcFilter });

    if (retailer) content.push({ id: "3", name: retailer?.name });
    if (monthFilter) {
      const date = new Date(monthFilter);
      let monthName = date.toLocaleString("default", { month: "long" });
      content.push({ id: "4", name: monthName });
    } else {
      const date = new Date(dayjs().subtract(1, "month").startOf("month"));
      let monthName = date.toLocaleString("default", { month: "long" });
      content.push({ id: "4", name: monthName });
    }
    if (labelFilter) content.push({ id: "5", name: labelFilter.label_name });

    setChipContent(content);
  }

  function updateFilterContents() {
    filterContents.map((filter) => {
      if (filter.id === "1") setClient(filter.data);
      if (filter.id == "2") setReleaseFilter(filter.data);
      if (filter.id == "6") setisrcFilter(filter.data);
      if (filter.id == "7") setupcFilter(filter.data);
      if (filter.id == "3") setRetailer(filter.data);
      if (filter.id == "4") setMonthFilter(filter.data);
      if (filter.id == "5") setLabelFilter(filter.data);
    });

    getChipContent();
  }

  function deleteChip(filterID) {
    if (filterID == "1") setClient(null);
    if (filterID == "2") setReleaseFilter(null);
    if (filterID == "6") setisrcFilter(null);
    if (filterID == "7") setupcFilter(null);
    if (filterID == "3") setRetailer(null);
    if (filterID == "4") {
      setMonthFilter(oneMonthBefore);
    }
    if (filterID == "5") setLabelFilter(null);

    let newChipContent = chipContent;
    newChipContent = newChipContent.filter((item) => item.id != filterID);
    let newFilterContents = filterContents;
    newFilterContents = newFilterContents.filter((item) => item.id != filterID);

    setChipContent(newChipContent);
    setFilterContents(newFilterContents);
  }

  useEffect(() => {
    getChipContent();
  }, [filterContents]);

  useEffect(() => {
    applyFilter();
  }, [
    client,
    releaseFilter,
    monthFilter,
    retailer,
    labelFilter,
    isrcFilter,
    upcFilter,
  ]);

  function statusText(chipID) {
    if (chipID == "1") return "Client: ";
    if (chipID == "2") return "Release: ";
    if (chipID == "3") return "Retailer: ";
    if (chipID == "4") return "Month: ";
    if (chipID == "5") return "Label: ";
    if (chipID == "6") return "ISRC: ";
    if (chipID == "7") return "UPC: ";
  }

  return (
    <React.Fragment>
      {/* {chipContent?.length > 0 && (
        <Box
          sx={{
            backgroundColor: "white",
            borderBottom: `1px solid ${theme.palette.common.grey}`,
          }}
        >
          <Grid
            container
            alignItems="center"
            sx={{ paddingY: "8px", paddingX: "10px" }}
          >
            <Grid item sx={{ fontWeight: "bold" }}>
              Filters Applied:
            </Grid>
            <Grid xs={11} sm={10} sx={{ paddingLeft: "10px" }}>
              <>
                {chipContent.map((item) => (
                  <>
                    {" "}
                    <Typography
                      sx={{
                        display: "inline-block",
                      }}
                    >
                      {statusText(item.id)}&nbsp;
                    </Typography>
                    <Chip
                      label={item.name}
                      onDelete={() => {
                        deleteChip(item.id);
                      }}
                      variant="outlined"
                      size="small"
                      sx={{ marginRight: "5px", marginBottom: "3px" }}
                    />
                  </>
                ))}
              </>
            </Grid>
          </Grid>
        </Box>
      )} */}
      <Box>
        <RevenueReportFileUploader
          open={open}
          loading={loading}
          errorMessage={errorMessage}
          onSubmit={(file) => {
            setLoading(true);
            let formData = new FormData();

            let callBack = (response) => {
              if (response.status === "success" && !response.message) {
                setLoading(false);
                setOpen(false);
              }
              if (
                response &&
                (response.status === 417 ||
                  (response.message && response.message.length !== 0))
              ) {
                setErrorMessage(response);
                setLoading(false);
              }
            };
            formData.append("file", file);
            props.dispatch(
              RevenueReportAction.requestPostRevenueReport(formData, callBack)
            );
          }}
          onClose={() => {
            setOpen(false);
            setErrorMessage(null);
          }}
          editData={editRowsModel}
          edit={edit}
        />

        {/* <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="space-between"
          pr={1}
          pb={1}
        >
          <Grid
            item
            container
            xl={9}
            lg={9}
            md={12}
            sm={12}
            xs={12}
            gap={2}
          ></Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={6}
            sm={7}
            mt={{ md: 1, lg: 0, sm: 1, xl: 0 }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={2}
              justifyContent="flex-end"
              alignItems="end"
            >
              <Grid item>
                <Tooltip title="Apply Filters" arrow placement="left">
                  <Box
                    sx={{
                      //display: "inline-block",
                      // marginBottom: "3px",
                      padding: "5px",
                      border: `1px solid ${theme.palette.common.grey}`,
                      borderRadius: "4px",
                    }}
                  >
                    <FilterAltIcon
                      onClick={() => {
                        setMultiLevelFilterDialog(true);
                      }}
                    />
                  </Box>
                </Tooltip>
              </Grid>

              <Grid item xl={4} lg={4} md={3} sm={4}>
                <MuiButton
                  width="100%"
                  height={"100%"}
                  loading={clearLoader && isRequesting}
                  name={"Clear"}
                  onClick={ClearFilter}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={3} sm={4}>
                <MuiButton
                  width="100%"
                  height={"100%"}
                  loading={exportLoading}
                  name={!exportLoading ? "Export" : "Downloading"}
                  onClick={() => {
                    setExportDialog(true);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid
                conatiner
                item
                lg={12}
                xl={12}
                md={12}
                sm={12}
                xs={12}
                pr={1}
              >
                {/* loading icon */}
                {/* <LoadingIndicator isActive={isRequesting}>
                  {isRequesting ? (
                    <LoadingBgImage />
                  ) : (
                    props.revenueReport?.hits?.hits && (
                      <DataGridFunction
                        rows={rows}
                        columns={columns}
                        rowHeight={80}
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={handleChange}
                      />
                    )
                  )}
                </LoadingIndicator> */}

                <LoadingIndicator isActive={isRequesting}>
                  {isRequesting ? (
                    <LoadingBgImage />
                  ) : (
                    props.filterRevenueReport?.hits?.hits && (
                      <DataGridFunction
                        rows={filterRevenuerows}
                        columns={filterRevenueColumns}
                        rowHeight={50}
                        pagelength={props.pagination.total_page_no}
                        page={props.pagination.current_page_no}
                        handleChange={filterRevenueHandleChange}
                      />
                    )
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
            <RevenueMultiLevelFilter
              clientList={clientList}
              releasesList={releasesList}
              platforms_list={platforms_list}
              groupedRoles={groupedRoles}
              labelList={labelList}
              open={multiLevelFilterDialog}
              onClose={() => {
                setMultiLevelFilterDialog(false);
              }}
              chipContent={chipContent}
              setChipContent={setChipContent}
              filterContents={filterContents}
              setFilterContents={setFilterContents}
              applyFilter={applyFilter}
              updateFilterContents={updateFilterContents}
              deleteChip={deleteChip}
            />

            <ExportDailoge
              clientList={clientList}
              releasesList={releasesList}
              platforms_list={platforms_list}
              groupedRoles={groupedRoles}
              labelList={labelList}
              open={exportDialog}
              onClose={() => {
                setExportDialog(false);
              }}
              chipContent={chipContent}
              setChipContent={setChipContent}
              filterContents={filterContents}
              setFilterContents={setFilterContents}
              applyFilter={applyFilter}
              updateFilterContents={updateFilterContents}
              deleteChip={deleteChip}
              editRowsModel={editRowsModel}
            />

            <CSVLink
              data={csvData}
              filename="RevenueReport.csv"
              // filename={`RevenueReport_${new Date().toISOString().slice(0, 10)}.csv`}
              className="hidden"
              ref={csvLink}
              target="_blank"
            />
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"No Filters Found !!"}
      />
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(RevenueReport);

const SelectField = ({
  options,
  value,
  size,
  placeholder,
  handleSelectChange,
}) => {
  return (
    <Grid container>
      <Select
        input={<OutlinedInput {...inputProps} />}
        MenuProps={menuProps}
        value={value}
        displayEmpty
        size={size}
        style={{ width: "100%" }}
        onChange={handleSelectChange}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
