import { BaseModel } from "sjs-base-model";

// StatusPutModel
export default class StatusPutModel extends BaseModel {
    /*
     * Client-Side properties (Not from API)
     */
    // noneApiProperties = null;
    // response getting from the api mentioned end point
    // message = "";
    status = "";

    constructor(data) {
        super();
        this.update(data);
    }
}
