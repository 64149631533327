import ActionUtility from "../../utilities/ActionUtility";
import YTNetRevenueByMonthEffect from "./YTNetRevenueByMonthEffect";

export default class YTNetRevenueByMonthAction {
  // YTNetRevenueByMonthAction action with an function declaration
  static REQUEST_YT_NET_REVENUE_BY_MONTH =
    "YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH";
  static REQUEST_YT_NET_REVENUE_BY_MONTH_FINISHED =
    "YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FINISHED";

  static REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER =
    "YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER";
  static REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_FINISHED =
    "YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_FINISHED";

  static REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_CLEAR =
    "YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_CLEAR";
  static REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_CLEAR_FINISHED =
    "YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_CLEAR_FINISHED";

  // METHODS
  static requestNetRevenueByMonth(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH,
        YTNetRevenueByMonthEffect.requestNetRevenueByMonth,
        params
      );
    };
  }

  static requestNetRevenueByMonthFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER,
        YTNetRevenueByMonthEffect.requestNetRevenueByMonthFilter,
        params
      );
    };
  }

  static requestNetRevenueByMonthFilterClear(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_CLEAR,
        YTNetRevenueByMonthEffect.requestNetRevenueByMonthFilterClear,
        params
      );
    };
  }
}
