import BaseReducer from "../../utilities/BaseReducer";
import TopTerritoriesAction from "./TopTerritoriesAction";

// TopTerritoriesReducer
export default class TopTerritoriesReducer extends BaseReducer {
  initialState = {
    top_territories_report: [],
  };
  [TopTerritoriesAction.REQUEST_TOP_TERRITORIES_FINISHED](state, action) {
    return {
      ...state,
      top_territories_report: action.payload,
    };
  }
}
