import React, { useEffect, useState, useRef } from "react";
import { Tabs, Tab, Box, Grid, IconButton, Typography } from "@mui/material";
import { connect } from "react-redux";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import DashboardCustomizeRoundedIcon from "@mui/icons-material/DashboardCustomizeRounded";
import LibraryMusicRoundedIcon from "@mui/icons-material/LibraryMusicRounded";
import TimerSharpIcon from "@mui/icons-material/TimerSharp";
import StoreIcon from "@mui/icons-material/Store";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListIcon from "@mui/icons-material/List";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import UpcomingIcon from "@mui/icons-material/Upcoming";
import MergeTypeIcon from "@mui/icons-material/MergeType";
import FilterCenterFocusIcon from "@mui/icons-material/FilterCenterFocus";
import DialpadIcon from "@mui/icons-material/Dialpad";
import YTNetRevenueByMonthAction from "../../stores/monthwiseRevenueYT/YTNetRevenueByMonthAction";
import YTMonthwiseRevenueGridView from "./YTMonthwiseRevenueGridView";
import YTMonthwiseRevenueChartView from "./YTMonthwiseRevenueChartView";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER,
  ]),
  ytNetRevenueByMonthFilter:
    state.ytNetRevenueByMonthFilter.ytNetRevenueByMonthFilter || [],
});

const subTabsUI = {
  fontWeight: "bold",
  textTransform: "none",
  fontSize: "16px",
  "&.Mui-selected": {
    boxShadow: "0px 0px 20px 4px rgba(0, 0, 0, 0.6)",
  },
  "&:hover": {
    "& .MuiSvgIcon-root": {
      transition: "transform 0.9s",
      transform: "scale(1.3)",
    },
  },
};

function YTMonthwiseRevenueTabView(props) {
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const [reportViewType, setReportViewType] = useState("grid");

  const boxRef = useRef(null);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  function fetchNetRevenueByMonth(param) {
    props.dispatch(
      YTNetRevenueByMonthAction.requestNetRevenueByMonthFilter(param)
    );
  }

  const prevActiveTab = useRef(activeTab);
  useEffect(() => {
    prevActiveTab.current = activeTab;

    const filters = [];

    props.monthSelected &&
      filters.push(
        `["day","must","lte","range","${props.monthSelected}-31"],["day","must","gte","range","${props.monthSelected}-01"]`
      );

    const reportType = tabs.filter((tab) => tab.id === activeTab)[0]?.tabValue;

    const params = {
      report_type: reportType,
      filters: `${filters}`,
    };

    fetchNetRevenueByMonth(params);
  }, [activeTab]);

  const tabs = [
    {
      id: 0,
      tablabel: "Retailer Based",
      tabIcon: (
        <SupportAgentIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "retailer_name",
    },
    {
      id: 1,
      tablabel: "Label Based",
      tabIcon: (
        <LabelImportantIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "label",
    },
    {
      id: 2,
      tablabel: "Track Based",
      tabIcon: (
        <LibraryMusicRoundedIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "track_title",
    },
    {
      id: 3,
      tablabel: "Territory Based",
      tabIcon: (
        <PublicTwoToneIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "territory",
    },
    {
      id: 4,
      tablabel: "Release Based",
      tabIcon: (
        <NewReleasesIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "release_title",
    },
    {
      id: 5,
      tablabel: "UPC Based",
      tabIcon: (
        <UpcomingIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "upc",
    },

    {
      id: 6,
      tablabel: "ISRC Based",
      tabIcon: (
        <FilterCenterFocusIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "isrc",
    },
    {
      id: 7,
      tablabel: "Artist Based",
      tabIcon: (
        <InterpreterModeIcon
          style={{
            fontSize: 30,
            transition: "transform 0.3s",
          }}
        />
      ),
      tabValue: "artist",
    },
  ];

  function returnMonthName(monthValue) {
    // expected the month value be like 2023-09
    const dateString = monthValue;
    const [year, month] = dateString.split("-");
    const formattedDate = new Date(`${year}-${month}-01`).toLocaleDateString(
      "en-US",
      { year: "numeric", month: "long" }
    );

    return formattedDate;
  }

  const data = props.ytNetRevenueByMonthFilter;

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes rotateAnimation {
            0% {
              transform: rotate(0deg); 
            }
            50% {
              transform: rotate(-360deg);
            }
          }
          @keyframes popIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            }
            50% {
              transform: scale(1.1);
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
    `,
        }}
      />
      <Box
        ref={boxRef}
        style={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent="flex-start"
          style={{
            position: "sticky",
            top: `${boxRef?.current?.offsetTop}px`,
            backgroundColor: theme.palette.background.default,
            zIndex: 10,
          }}
        >
          <Grid
            container
            item
            xs={12}
            px={2}
            py={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              onClick={() => {
                props.setMonthSelected(null);
              }}
              sx={{
                backgroundColor: theme.palette.background.paper,
                color: theme.palette.primary.main,
              }}
            >
              <ArrowBackIcon
                style={{
                  fontSize: 20,
                  transition: "transform 0.3s",
                }}
              />
            </IconButton>

            <Grid
              sx={{
                fontSize: "24px",
                display: "flex",
                alignItems: "center",
              }}
              gap={1}
            >
              <span style={{ fontWeight: "bold" }}>
                {formatName(`${returnMonthName(props.monthSelected)}`)}
              </span>

              {data["Net Revenue By Month"] && (
                <span>
                  : $ {data["Net Revenue By Month"][0]?.total_revenue}
                </span>
              )}
            </Grid>

            <Grid item>
              {["grid", "chart"].map((item) => (
                <IconButton
                  sx={{
                    marginX: 1,
                    backgroundColor:
                      item === reportViewType
                        ? theme.palette.background.paper
                        : "none",
                    borderRadius: item === reportViewType ? 2 : 0,
                    padding: item === reportViewType ? 1 : 0,
                    boxShadow: item === reportViewType ? theme.shadows : "none",
                    borderBottom:
                      item === reportViewType
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                  onClick={() => {
                    setReportViewType(item);
                  }}
                >
                  {item === "grid" ? (
                    <Typography sx={{ color: theme.palette.primary.main }}>
                      Tabel View
                    </Typography>
                  ) : (
                    <Typography sx={{ color: theme.palette.primary.main }}>
                      Chart View
                    </Typography>
                  )}
                </IconButton>
              ))}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Tabs
              variant="fullWidth"
              scrollButtons="auto"
              value={activeTab}
              allowScrollButtonsMobile
              centered
              style={{
                marginRight: "20px",
                marginTop: "5px",
                marginBottom: "10px",
                backgroundColor: theme.palette.background.default,
                zIndex: 10,
                "&.Mui-focusVisible": {
                  backgroundColor: "rgba(100, 95, 228, 0.32)",
                },
              }}
            >
              {tabs?.map((tab) => (
                <Tab
                  onClick={(e) => {
                    setActiveTab(tab.id);
                  }}
                  label={tab.tablabel}
                  icon={tab.tabIcon}
                  sx={subTabsUI}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        <>
          {tabs.map((tab) => {
            return (
              tab.id === activeTab &&
              (reportViewType === "grid" ? (
                <YTMonthwiseRevenueGridView report_type={tab.tabValue} />
              ) : (
                <YTMonthwiseRevenueChartView
                  report_type={tab.tabValue}
                  tab={tab}
                />
              ))
            );
          })}
        </>
      </Box>
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Please apply some filters !"}
      />
    </>
  );
}

export default connect(mapStateToProps)(YTMonthwiseRevenueTabView);
