import BaseReducer from "../../utilities/BaseReducer";
import NetRevenueByMonthAction from "./NetRevenueByMonthAction";

export default class ReleasewiseRevenueReduser extends BaseReducer {
  initialState = {
    releasewiseRevenue: [],
  };
  [NetRevenueByMonthAction.REQUEST_RELEASEWISE_REVENUE_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      releasewiseRevenue: action.payload,
    };
  }
}
