import React, { useEffect, useRef, useState } from "react";
import {
  Paper,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { makeStyles } from "@material-ui/core";
import { theme } from "../../App";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DatePicker from "../../../components/datePicker/DateRangePicker";
//import { ShadowContainer } from "../../../../components/componentLayout/ComponentLayout";
import { isValid } from "date-fns";
import { format } from "date-fns";
import MuiButton from "../../../components/muiButton/MuiButton";

const useStyles = makeStyles({
  gridContainer: {
    height: "auto", // set the height of the grid container
    overflow: "auto", // add scrollbars when necessary
    "&::-webkit-scrollbar": {
      display: "none", // hide the scrollbar for webkit-based browsers (e.g. Chrome, Safari)
    },
    "-ms-overflow-style": "none", // hide the scrollbar for Internet Explorer and Edge
    "scrollbar-width": "none", // hide the scrollbar for Firefox and other browsers that support the new scrollbar style
  },
});

function DateSelectionPopper(props) {
  const [open, setOpen] = React.useState(false);
  const [dateOpen, setDateOpen] = useState(false);
  const dateSelectionPopperRef = React.useRef(null);
  const scrollRef = useRef(null);
  const prevOpen = React.useRef(open);
  const [dateRange, setDateRange] = useState([null, null]);
  const [createdDatelistSelected, setCreatedDatelistSelected] = useState(
    props.createdDatelistSelected
  );
  const [customDateClicked, setCustomDateClicked] = useState(false);
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
  }, []);

  const classes = useStyles();

  let maxDate = new Date();

  const handlePopper = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };

  // const [dateSelection] = useState([
  //   // "Last 2 Days",
  //   // "Today",
  //   // "Previous Day",
  //   "Last 7 Days",
  //   "Last 30 Days",
  //   "Custom",
  // ]);

  //   function dateApplyFilter() {
  const today = new Date();
  if (props.callingComponent == "dashboardAudioStream")
    today.setDate(today.getDate() - 2);

  let date1 = new Date();
  let previousday = new Date(date1);
  previousday.setDate(previousday.getDate() - 1);

  //Last 2 days filter
  let last2days = [...Array(2).keys()].map((index) => {
    const date = new Date();
    date.setDate(date.getDate() - index);
    return date;
  });

  let last7Days = new Date(today);
  if (props.callingComponent == "dashboardAudioStream")
    last7Days.setDate(today.getDate() - 6);
  else last7Days.setDate(today.getDate() - 8);

  // last 30 days
  let date2 = new Date();
  let last30Days;
  if (props.callingComponent == "dashboardAudioStream")
    last30Days = new Date(new Date().setDate(date1.getDate() - 31));
  else last30Days = new Date(new Date().setDate(date2.getDate() - 30));

  let params = {};
  let filter;

  function TodayFilter() {
    return `["current_date","must","lte","range","${format(
      new Date(),
      "yyyy-MM-dd"
    )}"],["current_date","must","gte","range","${format(
      today,
      "yyyy-MM-dd"
    )}"]`;
  }

  function PreviousFilter() {
    return `["current_date","must","lte","range","${format(
      previousday,
      "yyyy-MM-dd"
    )}"],["current_date","must","gte","range","${format(
      previousday,
      "yyyy-MM-dd"
    )}"]`;
  }

  function Last2DaysFilter() {
    return `["current_date","must","lte","range","${format(
      last2days[0],
      "yyyy-MM-dd"
    )}"],["current_date","must","gte","range","${format(
      last2days[1],
      "yyyy-MM-dd"
    )}"]`;
  }

  function Last7DaysFilter() {
    return `["current_date","must","lte","range","${format(
      today,
      "yyyy-MM-dd"
    )}"],["current_date","must","gte","range","${format(
      last7Days,
      "yyyy-MM-dd"
    )}"]`;
  }

  function Last30DaysFilter() {
    return `["current_date","must","lte","range","${format(
      today,
      "yyyy-MM-dd"
    )}"],["current_date","must","gte","range","${format(
      last30Days,
      "yyyy-MM-dd"
    )}"]`;
  }

  function CustomDateFilter() {
    function lastTwoDaysChecker(outerRange) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      outerRange.setHours(0, 0, 0, 0);
      const date1_ms = today.getTime();
      const date2_ms = outerRange.getTime();
      const difference_ms = Math.abs(date1_ms - date2_ms);
      const difference_days = Math.ceil(difference_ms / (1000 * 60 * 60 * 24));

      if (difference_days == 0) return 2;
      else if (difference_days == 1) return 1;
      else return 0;
    }

    if (props.callingComponent == "dashboardAudioStream") {
      return `["current_date","must","lte","range","${format(
        new Date(dateRange[1]).setDate(
          new Date(dateRange[1]).getDate() -
            lastTwoDaysChecker(new Date(dateRange[1]))
        ),
        "yyyy-MM-dd"
      )}"],["current_date","must","gte","range","${format(
        new Date(dateRange[0]),
        "yyyy-MM-dd"
      )}"]`;
    } else
      return `["current_date","must","lte","range","${format(
        new Date(dateRange[1]),
        "yyyy-MM-dd"
      )}"],["current_date","must","gte","range","${format(
        new Date(dateRange[0]),
        "yyyy-MM-dd"
      )}"]`;
  }

  if (createdDatelistSelected === "Today") {
    filter = TodayFilter();
  }

  if (createdDatelistSelected === "Previous Day") {
    filter = PreviousFilter();
  }

  if (createdDatelistSelected === "Last 2 Days") {
    filter = Last2DaysFilter();
  }
  if (createdDatelistSelected === "Last 7 Days") {
    filter = Last7DaysFilter();
  }
  if (createdDatelistSelected === "Last 30 Days") {
    filter = Last30DaysFilter();
  }
  if (createdDatelistSelected === "Custom") {
    if (customDateClicked) {
      filter = CustomDateFilter();
    }
  }

  props.onDateSelect(filter);
  props.setCreatedDatelistSelected(createdDatelistSelected);

  const dateValueHandleChange = (e, v) => {
    setDateRange(e);
  };

  useEffect(() => {
    isDatePickerOpen && setCustomDateClicked(false);
    if (
      !isDatePickerOpen &&
      dateRange[0] &&
      dateRange[1] &&
      !customDateClicked &&
      !props.setCreatedDatelistSelected &&
      !props.createdDatelistSelected
    ) {
      setCreatedDatelistSelected("Last 30 Days");
    }
  }, [isDatePickerOpen]);

  return (
    <Grid container>
      <Typography
        component={"div"}
        ref={dateSelectionPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <span
          style={{
            fontWeight: createdDatelistSelected !== null ? "none" : "none",
            fontSize: props.fontSize || "12px",
            color: props.color,
          }}
        >
          Duration {createdDatelistSelected && `: ${createdDatelistSelected}`}
        </span>

        <Typography
          sx={{
            paddingLeft: 1,
            color: props.color,
          }}
        >
          {!open ? (
            <KeyboardArrowDownIcon
              sx={{
                fontSize: "16px",
                //color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          ) : (
            <KeyboardArrowUpIcon
              sx={{
                fontSize: "16px",
                //color: theme.palette.primary.main,
                cursor: "pointer",
              }}
            />
          )}
        </Typography>
      </Typography>

      <Popper
        open={open}
        anchorEl={dateSelectionPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        sx={{
          zIndex: 999,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            sx={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 150,

                height: "auto",
                //boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  // style={{ display: "flex" }}
                >
                  <Grid
                    item
                    sx={{ padding: 2 }}
                    gap={1}
                    className={classes.gridContainer}
                    ref={scrollRef}
                  >
                    {props.dateSelection.map((item) => (
                      <Typography
                        component={"div"}
                        sx={{
                          marginBottom: 2,
                          cursor: "pointer",
                          textAlign: "left",
                          color: props.color,
                          fontWeight:
                            createdDatelistSelected === item ? "bold" : "none",
                        }}
                        onClick={() => {
                          item != "Custom" && setCreatedDatelistSelected(item);
                          if (item == "Custom") {
                            setDatePickerOpen(true);
                          }
                        }}
                      >
                        {item}
                      </Typography>
                    ))}
                  </Grid>
                  {/* {dateOpen && (
                    <Grid item sx={{ padding: 2 }}>
                      <DatePicker
                        value={dateRange ? dateRange : [null, null]}
                        onChange={dateValueHandleChange}
                        maxDate={maxDate}
                      />
                    </Grid>
                  )} */}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Dialog
        open={isDatePickerOpen}
        onClose={() => {
          if (!dateRange[0] || !dateRange[1])
            setCreatedDatelistSelected("Last 30 Days");
          setDatePickerOpen(false);
        }}
        TransitionComponent={Grow}
        transitionDuration={400}
        fullWidth
      >
        <DialogTitle sx={{ paddingBottom: "25px" }}>
          Select a Date Range{" "}
        </DialogTitle>
        <DialogContent>
          <DatePicker
            value={dateRange ? dateRange : [null, null]}
            onChange={dateValueHandleChange}
            disableFuture
          />
        </DialogContent>
        <DialogActions style={{ padding: "16px", marginTop: "15px" }}>
          <MuiButton
            width="40%"
            name={"Apply"}
            onClick={() => {
              setCreatedDatelistSelected("Custom");
              setCustomDateClicked(true);
              setDatePickerOpen(false);
            }}
          />
          <MuiButton
            cancel
            width="40%"
            name={"Clear"}
            onClick={() => {
              setCustomDateClicked(false);
              setDateRange([null, null]);
            }}
          />
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default DateSelectionPopper;
