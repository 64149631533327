import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import OrmNotificationGetModel from "./models/OrmNotificationsGetModel";
import OrmNotificationGetByIdModel from "./models/OrmNotificationsByIdModel";
import OrmNotificationsBulkUpdateModel from "./models/OrmnotificationBulkUpdateModel";
import NotificationsBulkUpdateModel from "./models/OrmnotificationBulkUpdateModel";
import NotificationsPutModel from "./models/NotificationsPutModel";

export default class NotificationEffect {
  static async requestNotifications(params, callback) {
    const endpoint = environment.api.notificationsData;
    let response = EffectUtility.getToModel(
      OrmNotificationGetModel,
      endpoint,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestFilterNotifications(params, callback) {
    const endpoint = environment.api.notificationsData;
    let response = EffectUtility.getToModel(
      OrmNotificationGetModel,
      endpoint,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }

    return response;
  }

  static async requestNotificationsById(id, params) {
    const endpoint = environment.api.notificationsData + "/" + id;
    return EffectUtility.getToModel(
      OrmNotificationGetByIdModel,
      endpoint,
      params
    );
  }

  static async requestPutNotificationsBulkUpdate(data) {
    const endpoint = environment.api.notificationsDataBulkUpdate;
    return EffectUtility.putToModel(
      NotificationsBulkUpdateModel,
      endpoint,
      data
    );
  }

  static async requestPutNotifications(data, id) {
    const endpoint = environment.api.notificationsData + "/" + id;
    return EffectUtility.putToModel(
      NotificationsPutModel, // PUT model is also same as post model
      endpoint,
      data
    );
  }

  // static async requestPostOrmNotifications(data) {
  //   const endpoint = environment.api.ormNotificationsData;
  //   return EffectUtility.postToModel(, endpoint, data);
  // }

  // static async requestDeleteOrmNotifications(id) {
  //   const endpoint = environment.api.ormNotificationsData + "/" + id;

  //   return EffectUtility.deleteToModel(, endpoint);
  // }

  //get error endpoint
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    return response.data;
  }
}
