import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Typography,
  Chip,
  Tooltip,
  Box,
  Accordion,
  AccordionSummary,
} from "@mui/material";
import { Form, Formik, Field, getIn, ErrorMessage, FastField } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import ClientAction from "../../stores/client/ClientAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../App";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import DatePickerComponent from "../../components/datePicker/DatePickerComponent";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import CloseIcon from "@mui/icons-material/Close";
import environment from "environment";
import DeleteIcon from "@mui/icons-material/Delete";
import AvatarLogo from "./AvatarLogo";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const pinRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const validationSchema = yup.object({
  type_of_client: string()
    .required("Enter Type of Client ")
    .required("Type of Client Required"),
  username: string().required("Enter User Name ").required("Username Required"),
  main_email_address: string()
    .required("Enter Main Email Address")
    .trim()
    .min(1, "Main Email Address should be of minimum 1 characters length")
    .required("Main Email Address is required")
    .matches(emailRegExp, "Email is not valid"),
  royalties_email_address: string()
    .required("Enter Royalties Email Address")
    .trim()
    .min(1, "Royalties Email Address should be of minimum 1 characters length")
    .required("Royalties Email Address is required")
    .matches(emailRegExp, "Email is not valid"),
  first_name: string()
    .required("Enter First Name")
    .trim()
    .min(1, "First Name should be of minimum 1 characters length")
    .required("First Name is required"),
  last_name: string()
    .required("Enter Last Name")
    .trim()
    .min(1, "Last Name should be of minimum 1 characters length")
    .required("Last Name is required"),
  phone_number: string("enter a proper phone number")
    .matches(phoneRegExp, "Phone number is not valid")
    .length(10, "Phone number should be 10 digits"),
  postal_code: string("enter a proper Pincode")
    .matches(pinRegExp, "Pincode is not valid")
    .length(6, "Pincode should be 6 digits"),
  city: string()
    .required("Enter City")
    .trim()
    .min(1, "City should be of minimum 1 characters length")
    .required("City is required"),
  country: string()
    .required("Enter Country")
    .trim()
    .min(1, "country should be of minimum 1 characters length")
    .required("Country is required"),
  default_language: string()
    .required("Enter Default Language")
    .required("Default Language is required"),
  default_time_zone: string()
    .required("Enter Default Time Zone")
    .required("Default Time Zone is Required"),

  credentials: yup.array().of(
    yup.object().shape({
      value: yup.string().required("Password is Required"),
    })
  ),

  // confirm_password: yup.string().required("Password is Required"),
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ClientPost = (props) => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = React.useState(false);

  const buttonForLoginRef = React.useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setshowconfirmPassword(!showconfirmPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function returnPhoneCodeObject(dial_code) {
    let phone_code = null;
    props.phoneCodes?.length > 0 &&
      (phone_code = props.phoneCodes.filter(
        (code) => code.dial_code === dial_code
      )[0]);

    return phone_code;
  }

  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  page_permission = [...new Set(page_permission)];

  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      client_name: props.projectEditData.client_name,
      username: props.projectEditData.username,
      city: props.projectEditData.city,
      country: props.projectEditData.country,
      default_language: props.projectEditData.default_language,
      default_time_zone: props.projectEditData.default_time_zone,
      enabled: props.projectEditData.enabled === "Enabled" ? true : false,
      yt_content_id:
        props.projectEditData.yt_content_id === "Enabled" ? true : false,
      yt_original:
        props.projectEditData.yt_original === "Enabled" ? true : false,
      allow_mobile_login:
        props.projectEditData.allow_mobile_login === "Enabled" ? true : false,
      music_stores:
        props.projectEditData.music_stores === "Enabled" ? true : false,
      yt_music: props.projectEditData.yt_music === "Enabled" ? true : false,
      first_name: props.projectEditData.first_name,
      last_name: props.projectEditData.last_name,
      main_email_address: props.projectEditData.main_email_address,
      phone_code: props.projectEditData.phone_number
        ? props.projectEditData.phone_number.split(" ").length === 2
          ? returnPhoneCodeObject(
              props.projectEditData.phone_number.split(" ")[0]
            )
          : null
        : null,
      phone_number: props.projectEditData.phone_number
        ? props.projectEditData.phone_number.split(" ").length === 2
          ? props.projectEditData.phone_number.split(" ")[1]
          : props.projectEditData.phone_number.split(" ")[0]
        : props.projectEditData.phone_number,
      postal_address: props.projectEditData.postal_address,
      postal_code: props.projectEditData.postal_code,
      royalties_email_address: props.projectEditData.royalties_email_address,
      type_of_client: props.projectEditData.type_of_client,
      user_profile_url: props.projectEditData.user_profile_url,
      // revenue_sharing: props.projectEditData.revenue_sharing,
      account_holder_name: props.projectEditData.account_holder_name,
      account_number: props.projectEditData.account_number,
      bank_name: props.projectEditData.bank_name,
      ifsc: props.projectEditData.ifsc,
      bank_gst_no_checkbox: props.projectEditData.bank_gst_no_checkbox,
      bank_address: props.projectEditData.bank_address,
      branch_name: props.projectEditData.branch_name,
      gst_no: props.projectEditData.gst_no,
      pan_no: props.projectEditData.pan_no,
      boolval: props.projectEditData.user_profile_url ? true : false,
      owned_labels: props.projectEditData.owned_labels
        ? props.projectEditData.owned_labels
        : [],

      //     // account_manager: props.projectEditData.account_manager,

      //     // all_content_delivered: props.projectEditData.all_content_delivered,
      //     // business_unit: props.projectEditData.business_unit,
      //     // channel_manager: props.projectEditData.channel_manager,

      //     // client_classification: props.projectEditData.client_classification,
      //     // // contact: props.projectEditData.contact,
      //     // contract_end_date: props.projectEditData.contract_end_date,
      //     // contract_signature_date: props.projectEditData.contract_signature_date,

      //     // contracting_a3_entity: props.projectEditData.contracting_a3_entity,
      //     // cost_centre: props.projectEditData.cost_centre,
      //     // digital_marketing_manager:
      //     //   props.projectEditData.digital_marketing_manager,
      //     // first_account_signatory: props.projectEditData.first_account_signatory,

      //     // main_royalty_rate: props.projectEditData.main_royalty_rate,

      //     // neighboring_rates: props.projectEditData.neighboring_rates,
      //     // number_of_release: props.projectEditData.number_of_release,
      //     // project_manager: props.projectEditData.project_manager,

      //     // royalty_rate_2: props.projectEditData.royalty_rate_2,
      //     // royalty_rate_3: props.projectEditData.royalty_rate_3,
      //     // royalty_rate_4: props.projectEditData.royalty_rate_4,
      //     // specific_agent_royalty_rate:
      //     //   props.projectEditData.specific_agent_royalty_rate,
      //     // specific_store_royalty_rate:
      //     //   props.projectEditData.specific_store_royalty_rate,
      //     // specific_territory_royalty_rate:
      //     //   props.projectEditData.specific_territory_royalty_rate,

      //     // starting_event: props.projectEditData.starting_event,
      //     // turn_over_begin: props.projectEditData.turn_over_begin,
      //     // turn_over_last_quarter: props.projectEditData.turn_over_last_quarter,
      //     // last_created: props.projectEditData.last_created,
      //     // main_music_genre: props.projectEditData.main_music_genre,
      //     // mobile_number: props.projectEditData.mobile_number,
      //     // status: props.projectEditData.status,
    };
  } else {
    initial = {
      client_name: "",
      type_of_client: "",
      main_email_address: "",
      royalties_email_address: "",
      first_name: "",
      last_name: "",
      username: "",
      phone_code: null,
      phone_number: "",
      postal_address: "",
      postal_code: "",
      enabled: false,
      yt_content_id: true,
      yt_music: true,
      yt_original: true,
      music_stores: true,
      allow_mobile_login: true,
      city: "",
      country: "",
      default_language: "",
      default_time_zone: "",
      credentials: [
        {
          type: "password",
          value: "",
          temporary: false,
        },
      ],
      // revenue_sharing: "",
      confirm_password: "",
      user_profile_url: "",
      boolean: false,
      boolval: false,
      owned_labels: [],
      account_holder_name: "",
      account_number: "",
      bank_name: "",
      bank_gst_no_checkbox: true,
      bank_address: "",
      branch_name: "",
      ifsc: "",
      gst_no: "",
      pan_no: "",
    };
    // Formik
  }
  const [loading, setLoading] = useState(false);

  const handlePhoneKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  const handlFileUpload = async (value, setFieldValue, event) => {
    setFieldValue("boolean", true);

    // event.preventDefault();
    let formData = new FormData();
    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let user_id = localStorage.getItem("userId");

    try {
      const response = await fetch(
        `${environment.api.baseApi}/profile_picture_upload?user_id=${user_id}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + access_tocken,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        setFieldValue("user_profile_url", data?.url);
        setFieldValue("boolean", false);
      } else {
        setFieldValue("boolean", false);
        document.querySelector("input[type='file']").value = "";
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const zipcodeCityCountry = (postal_code, setFieldValue) => {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    fetch(
      `${environment.api.baseApi}/musiclabels_pincode?current_page=Client&zipcode=${postal_code}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response.status) {
          setFieldValue("city", "");
          setFieldValue("country", "");
          setFieldValue("postal_address", "");
          alert(response.message);
        } else {
          setFieldValue("city", response.city);
          setFieldValue("country", response.country);
          setFieldValue("postal_address", response.area);
        }

        // return response;
      });
  };

  useEffect(() => {
    setLoading(false);
  }, [props]);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        enableReinitialize
        initialValues={initial}
        validate={(values) => {
          let errors = {};

          if (!values.client_name) {
            errors.client_name = "Client name is Required";
          }
          if (!props.edit) {
            if (!values.username) {
              errors.username = "Username is Required";
            } else if (!/^[a-zA-Z0-9]+$/.test(values.username)) {
              errors.username =
                "Username should only contain letters and numbers";
            }
          }

          if (
            !props.edit &&
            values.confirm_password !== values.credentials[0].value
          ) {
            errors.confirm_password = "Passwords do not match";
          }

          if (!props.edit && !values.confirm_password) {
            errors.confirm_password = "Password is Required";
          }
          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let bank_details_object = {};

          Object.assign(
            bank_details_object,
            values.account_holder_name
              ? {
                  account_holder_name: values.account_holder_name,
                }
              : { account_holder_name: null },

            values.account_number
              ? {
                  account_number: values.account_number,
                }
              : { account_number: null },

            values.bank_name
              ? {
                  bank_name: values.bank_name,
                }
              : { bank_name: null },

            {
              bank_gst_no_checkbox: values.bank_gst_no_checkbox,
            },

            values.bank_address
              ? {
                  bank_address: values.bank_address,
                }
              : { bank_address: null },

            values.branch_name
              ? {
                  branch_name: values.branch_name,
                }
              : { branch_name: null },

            values.ifsc
              ? {
                  ifsc: values.ifsc,
                }
              : { ifsc: null },
            values.bank_gst_no_checkbox
              ? {
                  gst_no: values.gst_no,
                }
              : { gst_no: null },
            !values.bank_gst_no_checkbox
              ? {
                  pan_no: values.pan_no,
                }
              : { pan_no: null }
          );

          if (props.edit) {
            let Obj = {};
            Object.assign(
              Obj,
              // values.client_name && {
              //   client_name: values.client_name,
              // },
              values.city && {
                city: values.city,
              },
              { bank_details: bank_details_object },

              values.type_of_client && {
                type_of_client: values.type_of_client,
              },
              // values.all_content_delivered && {
              //   all_content_delivered: values.all_content_delivered,
              // },
              values.main_email_address && {
                main_email_address: values.main_email_address,
              },
              values.royalties_email_address && {
                royalties_email_address: values.royalties_email_address,
              },
              values.first_name && {
                first_name: values.first_name,
              },
              values.last_name && {
                last_name: values.last_name,
              },
              values.phone_number && {
                phone_number: values.phone_code
                  ? `${values.phone_code?.dial_code} ${values.phone_number}`
                  : values.phone_number,
              },
              values.postal_address && {
                postal_address: values.postal_address,
              },
              values.postal_code && {
                postal_code: Number(values.postal_code),
              },
              values.country && {
                country: values.country,
              },
              values.default_language && {
                default_language: values.default_language,
              },
              values.default_time_zone && {
                default_time_zone: values.default_time_zone,
              },
              { enabled: values.enabled },
              { yt_content_id: values.yt_content_id },
              { yt_original: values.yt_original },
              { yt_music: values.yt_music },
              { music_stores: values.music_stores },
              { allow_mobile_login: values.allow_mobile_login },
              {
                user_profile_url: values.user_profile_url,
              },
              {
                owned_labels: values.owned_labels.map(
                  (owned_label) => owned_label.id
                ),
              }
            );
            props
              .dispatch(
                ClientAction.requestPutClient(
                  JSON.stringify(Obj),
                  props.projectEditData.id
                )
              )
              .then(() => {
                const params = {
                  order_by: `[["modified_on","desc"]]`,
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["modified_on","desc"]]`,
                  page_limit: "none",
                };

                props.dispatch(ClientAction.requestClient(params));
                props.dispatch(ClientAction.requestClientFilter(param));
                props.onClose();

                setLoading(false);
              });
          } else {
            let Obj = {};
            Object.assign(
              Obj,
              { bank_details: bank_details_object },
              values.client_name && {
                client_name: values.client_name,
              },
              values.username && {
                username: values.username,
              },
              values.city && {
                city: values.city,
              },
              values.type_of_client && {
                type_of_client: values.type_of_client,
              },
              // values.all_content_delivered && {
              //   all_content_delivered: values.all_content_delivered,
              // },
              values.main_email_address && {
                main_email_address: values.main_email_address,
              },
              values.royalties_email_address && {
                royalties_email_address: values.royalties_email_address,
              },
              values.first_name && {
                first_name: values.first_name,
              },
              values.last_name && {
                last_name: values.last_name,
              },
              values.phone_number && {
                phone_number: values.phone_code
                  ? `${values.phone_code?.dial_code} ${values.phone_number}`
                  : values.phone_number,
              },
              values.postal_address && {
                postal_address: values.postal_address,
              },
              values.postal_code && {
                postal_code: Number(values.postal_code),
              },
              values.country && {
                country: values.country,
              },
              values.default_language && {
                default_language: values.default_language,
              },
              values.default_time_zone && {
                default_time_zone: values.default_time_zone,
              },
              values.credentials && { credentials: values.credentials },
              { enabled: values.enabled },
              { yt_content_id: values.yt_content_id },
              { yt_original: values.yt_original },
              { yt_music: values.yt_music },
              { music_stores: values.music_stores },
              { allow_mobile_login: values.allow_mobile_login },
              values.user_profile_url && {
                user_profile_url: values.user_profile_url,
              },
              // values.revenue_sharing && {
              //   revenue_sharing: values.revenue_sharing,
              // },
              {
                owned_labels: values.owned_labels.map(
                  (owned_label) => owned_label.id
                ),
              }
            );

            const callBack = (res) => {
              navigate("/revenueSharePolicy", {
                state: {
                  client_id: res.id,
                  client_name: res.data?.client_name,
                  username: res.data?.username,
                },
              });
              if (res.status == "success") {
                const params = {
                  order_by: `[["created_on","desc"]]`,

                  page_limit: 15,
                };
                const param = {
                  order_by: `[["created_on","desc"]]`,

                  page_limit: "none",
                };
                props.dispatch(ClientAction.requestClient(params));
                props.dispatch(ClientAction.requestClientFilter(param));
                props.onClose();
                setLoading(false);
              }
            };

            props
              .dispatch(ClientAction.requestPostClient(Obj, callBack))
              .then(() => {});
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          options,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%", marginBottom: 5 }}>
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"div"}
                    fontSize={30}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      justifySelf: "center",
                    }}
                  >
                    {props.edit
                      ? formatName("Edit Sub-Client Details")
                      : formatName("Add Sub-Client Details")}
                  </Typography>

                  <Typography
                    component={"span"}
                    sx={{
                      justifySelf: "end",
                      marginLeft: 3,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <HighlightOffIcon sx={{ fontSize: 20 }} />
                  </Typography>
                </Typography>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Client Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter Client Name"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Client Name"
                        disabled={props.edit ? true : false}
                        id="client_name"
                        size="small"
                        name="client_name"
                        error={
                          Boolean(errors.client_name) &&
                          Boolean(touched.client_name)
                        }
                        helperText={
                          Boolean(touched.client_name) && errors.client_name
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  alignItems={"center"}
                >
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Type of Client * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter Type of Client"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        name="type_of_client"
                        size="small"
                        value={values.type_of_client}
                        component={AutoCompleteMultiSelect}
                        options={
                          props.typeclientuniqueList
                            ? props.typeclientuniqueList
                            : []
                        }
                        // filterOptions={createFilterOptions({
                        //   matchFrom: "start",
                        //   stringify: (option) => option.typeclient,
                        // })}
                        // isOptionEqualToValue={(option, value) =>
                        //   //setting value property to custom value
                        //   option.type_of_client === value.type_of_client
                        // }
                        // getOptionLabel={(option) => option}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Type of client",
                        }}
                        error={
                          Boolean(errors.type_of_client) &&
                          Boolean(touched.type_of_client)
                        }
                        helperText={
                          Boolean(touched.type_of_client) &&
                          errors.type_of_client
                        }
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    User Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter User Name"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="User Name"
                        disabled={props.edit ? true : false}
                        id="username"
                        size="small"
                        name="username"
                        error={
                          Boolean(errors.username) && Boolean(touched.username)
                        }
                        helperText={
                          Boolean(touched.username) && errors.username
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Main Email Address * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter the main email address"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Main Email Address"
                        id="main_email_address"
                        size="small"
                        name="main_email_address"
                        error={
                          Boolean(errors.main_email_address) &&
                          Boolean(touched.main_email_address)
                        }
                        helperText={
                          Boolean(touched.main_email_address) &&
                          errors.main_email_address
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Royalties Email * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter the Royalities email address"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Royalties Email Address"
                        id="royalties_email_address"
                        size="small"
                        name="royalties_email_address"
                        error={
                          Boolean(errors.royalties_email_address) &&
                          Boolean(touched.royalties_email_address)
                        }
                        helperText={
                          Boolean(touched.royalties_email_address) &&
                          errors.royalties_email_address
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    First Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter First Name"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="First Name"
                        id="first_name"
                        size="small"
                        name="first_name"
                        error={
                          Boolean(errors.first_name) &&
                          Boolean(touched.first_name)
                        }
                        helperText={
                          Boolean(touched.first_name) && errors.first_name
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Last Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter Last Name"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Last Name"
                        id="last_name"
                        size="small"
                        name="last_name"
                        error={
                          Boolean(errors.last_name) &&
                          Boolean(touched.last_name)
                        }
                        helperText={
                          Boolean(touched.last_name) && errors.last_name
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Phone Number :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <AutoCompleteComponent
                      value={values.phone_code}
                      list={props.phoneCodes ? props.phoneCodes : []}
                      textFieldProps={{
                        placeholder: "Select Country Code",
                      }}
                      handleChange={(e, value) => {
                        // setPhoneCodeCountry(value);
                        setFieldValue("phone_code", value);
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.code === value.code
                      }
                      getOptionLabel={(option) =>
                        `${option.name} ( ${option.dial_code} )`
                      }
                      renderOption={(props, option) => {
                        return (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            <img
                              loading="lazy"
                              width="20"
                              src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                              srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                              alt=""
                            />
                            {option.name} ({option.code}) {option.dial_code}
                          </Box>
                        );
                      }}
                      size="small"
                      width="100%"
                    />
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Phone Number"
                      id="phone_number"
                      size="small"
                      name="phone_number"
                      maxLength={13}
                      //inputMode="numeric"
                      error={
                        Boolean(errors.phone_number) &&
                        Boolean(touched.phone_number)
                      }
                      helperText={
                        Boolean(touched.phone_number) && errors.phone_number
                      }
                      handleKeyPress={(e) => {
                        if (!/[0-9\b]/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      className="col-10 mt-1"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Postal Code * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter Postal Code"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Postal Code"
                        id="postal_code"
                        size="small"
                        name="postal_code"
                        inputMode="numeric"
                        maxLength={6}
                        onChange={(e) => {
                          e.preventDefault();
                          setFieldValue("postal_code", e.target.value);
                          if (e.target.value.length === 6) {
                            zipcodeCityCountry(e.target.value, setFieldValue);
                          } else {
                            setFieldValue("city", "");
                            setFieldValue("country", "");
                            setFieldValue("postal_address", "");
                          }
                        }}
                        handleKeyPress={handlePhoneKeyPress}
                        error={
                          Boolean(errors.postal_code) &&
                          Boolean(touched.postal_code)
                        }
                        helperText={
                          Boolean(touched.postal_code) && errors.postal_code
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Postal Address :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter Postal Address"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Postal Address"
                        id="postal_address"
                        size="small"
                        name="postal_address"
                        error={
                          Boolean(errors.postal_address) &&
                          Boolean(touched.postal_address)
                        }
                        helperText={
                          Boolean(touched.postal_address) &&
                          errors.postal_address
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    City * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter City Name"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="City"
                        id="city"
                        size="small"
                        name="city"
                        error={Boolean(errors.city) && Boolean(touched.city)}
                        helperText={Boolean(touched.city) && errors.city}
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Country * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter Country Name"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Country"
                        id="country"
                        size="small"
                        name="country"
                        error={
                          Boolean(errors.country) && Boolean(touched.country)
                        }
                        helperText={Boolean(touched.country) && errors.country}
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Revenue Sharing:
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter Revenue Sharing"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Revenue Sharing"
                        id="revenue_sharing"
                        size="small"
                        name="revenue_sharing"
                        error={
                          Boolean(errors.revenue_sharing) &&
                          Boolean(touched.revenue_sharing)
                        }
                        helperText={
                          Boolean(touched.revenue_sharing) &&
                          errors.revenue_sharing
                        }
                        className="col-10"
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid> */}
              <Grid container mt={1}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  alignItems={"center"}
                >
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Default Language * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter  Default Language"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        name="default_language"
                        size="small"
                        value={values.default_language}
                        component={AutoCompleteMultiSelect}
                        options={props.nameslist ? props.nameslist : []}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Default Language",
                        }}
                        error={
                          Boolean(errors.default_language) &&
                          Boolean(touched.default_language)
                        }
                        helperText={
                          Boolean(touched.default_language) &&
                          errors.default_language
                        }
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  alignItems={"center"}
                >
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Default Time Zone * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Tooltip
                      title="Enter  Default Time Zone"
                      arrow
                      placement="right-end"
                    >
                      <Field
                        name="default_time_zone"
                        size="small"
                        value={
                          values.default_time_zone
                            ? values.default_time_zone
                            : null
                        }
                        component={AutoCompleteMultiSelect}
                        options={props.timezonelist ? props.timezonelist : []}
                        textFieldProps={{
                          margin: "normal",
                          variant: "outlined",
                          placeholder: "Default Time Zone",
                        }}
                        error={
                          Boolean(errors.default_time_zone) &&
                          Boolean(touched.default_time_zone)
                        }
                        helperText={
                          Boolean(touched.default_time_zone) &&
                          errors.default_time_zone
                        }
                      />
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} sm={12} container my={1}>
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  ml={10}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Owned Labels :
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                  <AutoCompleteComponent
                    name="owned_labels"
                    width="100%"
                    value={values.owned_labels}
                    textFieldProps={{
                      placeholder:
                        values.owned_labels && values.owned_labels.length !== 0
                          ? null
                          : "Owned Labels",
                    }}
                    list={props.labelList ? props.labelList : []}
                    handleChange={(e, value) => {
                      const duplicate = [];
                      const uniqueIds = new Set(values.owned_labels);
                      value.map((item) => {
                        if (uniqueIds.has(item.id)) {
                          duplicate.push(item);
                          return false;
                        }
                        uniqueIds.add(item.id);
                        return true;
                      });
                      const updatedLabel = value.filter(
                        (item) =>
                          !duplicate.some(
                            (duplicateItem) => duplicateItem.id === item.id
                          )
                      );
                      setFieldValue("owned_labels", updatedLabel);
                    }}
                    multiple
                    disableCloseOnSelect={true}
                    getOptionLabel={(option) =>
                      option.label_name ? option.label_name : ""
                    }
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.label_name}
                          variant="outlined"
                          size="small"
                          className="mx-1 mt-1"
                          deleteIcon={<DeleteIcon />}
                          onDelete={() => {
                            setFieldValue(
                              "owned_labels",
                              value.filter((item) => item.id !== option.id)
                            );
                          }}
                        />
                      ))
                    }
                    renderOption={(props, option) => {
                      const isPresent = values.owned_labels.some((obj) => {
                        const result = obj.id === option.id;
                        return result;
                      });

                      return (
                        <li {...props} key={option.id}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={isPresent}
                          />
                          {option.label_name}
                        </li>
                      );
                    }}
                    size="small"
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    User Enable :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={<Checkbox checked={values.enabled} />}
                      label="Enable/Disable"
                      name="enabled"
                      value={values.enabled}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Youtube Original :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={<Checkbox checked={values.yt_original} />}
                      label="Enable/Disable"
                      name="yt_original"
                      value={values.yt_original}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Youtube Music :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={<Checkbox checked={values.yt_music} />}
                      label="Enable/Disable"
                      name="yt_music"
                      value={values.yt_music}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Music Stores :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={<Checkbox checked={values.music_stores} />}
                      label="Enable/Disable"
                      name="music_stores"
                      value={values.music_stores}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Youtube Content ID :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={<Checkbox checked={values.yt_content_id} />}
                      label="Enable/Disable"
                      name="yt_content_id"
                      value={values.yt_content_id}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Mobile Login :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <FormControlLabel
                      control={<Checkbox checked={values.allow_mobile_login} />}
                      label="Enable/Disable"
                      name="allow_mobile_login"
                      value={values.allow_mobile_login}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
              </Grid>

              {!props.edit && (
                <>
                  <Grid container mt={1}>
                    <Grid item lg={12} md={12} sm={12} container>
                      <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                        Password * :
                      </Grid>
                      <Grid item lg={6} md={6} sm={6}>
                        <Tooltip
                          title="Enter Password credentials"
                          arrow
                          placement="top-end"
                        >
                          <Field
                            as={MuiTextField}
                            variant="outlined"
                            placeholder="Credentials"
                            id="credentials"
                            type={showPassword ? "password" : "text"}
                            size="small"
                            name="credentials[0].value"
                            error={Boolean(
                              getIn(touched, "credentials") &&
                                getIn(errors, "credentials")
                            )}
                            className="col-10"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton size="small">
                                    <LockIcon transform="scale(0.8)" />
                                  </IconButton>
                                </InputAdornment>
                              ),

                              onKeyPress: (event) => {
                                const { key } = event;
                                if (key === "Enter") {
                                  buttonForLoginRef.current.click();
                                }
                              },

                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="small"
                                    sx={{ mr: 1 }}
                                  >
                                    {showPassword ? (
                                      <VisibilityOff transform="scale(0.8)" />
                                    ) : (
                                      <Visibility transform="scale(0.8)" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                        <Typography component="div" sx={{ color: "#d32f2f" }}>
                          <ErrorMessage name="credentials[0].value" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container mt={1}>
                    <Grid item lg={12} md={12} sm={12} container>
                      <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                        Confirm Password * :
                      </Grid>
                      <Grid item lg={6} md={6} sm={6}>
                        <Tooltip
                          title="Enter Confirm Password"
                          arrow
                          placement="top-end"
                        >
                          <Field
                            as={MuiTextField}
                            variant="outlined"
                            placeholder="Password"
                            id="confirm_password"
                            type={showconfirmPassword ? "password" : "text"}
                            size="small"
                            name="confirm_password"
                            error={Boolean(
                              getIn(touched, "confirm_password") &&
                                getIn(errors, "confirm_password")
                            )}
                            className="col-10"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton size="small">
                                    <LockIcon transform="scale(0.8)" />
                                  </IconButton>
                                </InputAdornment>
                              ),

                              onKeyPress: (event) => {
                                const { key } = event;
                                if (key === "Enter") {
                                  buttonForLoginRef.current.click();
                                }
                              },

                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="small"
                                    sx={{ mr: 1 }}
                                  >
                                    {showconfirmPassword ? (
                                      <VisibilityOff transform="scale(0.8)" />
                                    ) : (
                                      <Visibility transform="scale(0.8)" />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Tooltip>
                        <Typography component="div" sx={{ color: "#d32f2f" }}>
                          <ErrorMessage name="confirm_password" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}

              {
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  alignItems={"center"}
                  my={0.5}
                  // justifyContent="center"
                >
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    User Profile Picture :
                  </Grid>
                  <Grid item lg={5} md={6} sm={6}>
                    <Grid
                      item
                      // className="bg-success"
                      // xl={10}
                      textAlign="left"
                      sx={{
                        overflow: "hidden",
                      }}
                    >
                      {!values.boolval ? (
                        <input
                          disabled={values.boolean}
                          type="file"
                          name="file"
                          accept="image/*"
                          onChange={(e) => {
                            handlFileUpload(
                              e.target.files[0],
                              setFieldValue,
                              e
                            );
                          }}
                        />
                      ) : (
                        <Chip
                          avatar={<AvatarLogo url={values.user_profile_url} />}
                          onDelete={() => {
                            setFieldValue("boolval", false);
                            setFieldValue("user_profile_url", "");
                          }}
                          deleteIcon={
                            <IconButton
                              onClick={() => {
                                setFieldValue("boolval", false);
                                setFieldValue("user_profile_url", "");
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          }
                        />
                      )}

                      {values.boolean && (
                        <CircleLoader width={"20px"} height={"20px"} />
                      )}

                      {/* {values.boolean && ( */}

                      {/* )} */}

                      {/* {values.Attachment_url && (
                        <Grid
                          item
                          // className="bg-info"
                          xl={2}
                          textAlign="center"
                        >
                          <IconButton className="m-0 p-0" title="Remove">
                            <CloseIcon
                              style={{
                                width: "13px",
                              }}
                              onClick={() => {
                                // clearFile(setFieldValue);
                              }}
                            />
                          </IconButton>
                        </Grid>
                      )} */}
                    </Grid>
                  </Grid>
                </Grid>
              }

              {/* {page_permission &&
                page_permission.length !== 0 &&
                page_permission.length === 1 &&
                page_permission[0] === "*" && (
                  <Box className=" mt-2" width="100%">
                    <div>
                      <Accordion
                        className="m-0 p-1"
                        style={{
                          border: `1px solid ${theme.palette.borderColor.textField}`,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="m-0 p-1"
                          style={{
                            backgroundColor: ` ${theme.palette.background.tab}`,
                          }}
                        >
                          <Typography
                            style={{
                              fontWeight: "bold",
                              marginLeft: 8,
                            }}
                          >
                            Bank Details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          // className="m-0 p-0"
                          sx={{ padding: 1 }}
                        >
                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                Account Holder Name :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="Account Holder Name"
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="Account Holder Name"
                                    // disabled={props.edit ? true : false}
                                    id="account_holder_name"
                                    size="small"
                                    name="account_holder_name"
                                    error={
                                      Boolean(errors.account_holder_name) &&
                                      Boolean(touched.account_holder_name)
                                    }
                                    helperText={
                                      Boolean(touched.account_holder_name) &&
                                      errors.account_holder_name
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setFieldValue(
                                        "account_holder_name",
                                        newValue
                                      );
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={`${"space-between"}`}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                Account Number :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Field
                                  as={MuiTextField}
                                  variant="outlined"
                                  placeholder={"Account Number"}
                                  id={"account_number"}
                                  size="small"
                                  name={"account_number"}
                                  // error={
                                  //   Boolean(errors["phone_number"]) &&
                                  //   Boolean(touched["phone_number"])
                                  // }
                                  // helperText={
                                  //   Boolean(touched["phone_number"]) &&
                                  //   errors["phone_number"]
                                  // }
                                  inputMode={"numeric"}
                                  // maxLength={13}
                                  handleKeyPress={(e) => {
                                    if (!/[0-9]/.test(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                Bank Name :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="Bank Name"
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="Bank Name"
                                    // disabled={props.edit ? true : false}
                                    id="bank_name"
                                    size="small"
                                    name="bank_name"
                                    error={
                                      Boolean(errors.bank_name) &&
                                      Boolean(touched.bank_name)
                                    }
                                    helperText={
                                      Boolean(touched.bank_name) &&
                                      errors.bank_name
                                    }
                                    onChange={(e) => {
                                      const newValue = capitalizeWords(
                                        e.target.value
                                      );
                                      setFieldValue("bank_name", newValue);
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                Bank Address :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="Bank Address"
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="Bank Address"
                                    // disabled={props.edit ? true : false}
                                    id="bank_address"
                                    size="small"
                                    name="bank_address"
                                    error={
                                      Boolean(errors.bank_address) &&
                                      Boolean(touched.bank_address)
                                    }
                                    helperText={
                                      Boolean(touched.bank_address) &&
                                      errors.bank_address
                                    }
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      setFieldValue("bank_address", newValue);
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                Branch Name :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="Branch Name"
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="Branch Name"
                                    // disabled={props.edit ? true : false}
                                    id="branch_name"
                                    size="small"
                                    name="branch_name"
                                    error={
                                      Boolean(errors.branch_name) &&
                                      Boolean(touched.branch_name)
                                    }
                                    helperText={
                                      Boolean(touched.branch_name) &&
                                      errors.branch_name
                                    }
                                    onChange={(e) => {
                                      const newValue = capitalizeWords(
                                        e.target.value
                                      );
                                      setFieldValue("branch_name", newValue);
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                IFSC :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="IFSC"
                                  arrow
                                  placement="right-end"
                                >
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="IFSC"
                                    // disabled={props.edit ? true : false}
                                    id="ifsc"
                                    size="small"
                                    name="ifsc"
                                    error={
                                      Boolean(errors.ifsc) &&
                                      Boolean(touched.ifsc)
                                    }
                                    helperText={
                                      Boolean(touched.ifsc) && errors.ifsc
                                    }
                                    onChange={(e) => {
                                      const newValue = capitalizeWords(
                                        e.target.value
                                      );
                                      const isAllowed = /^[A-Z0-9]{0,11}$/.test(
                                        newValue
                                      );
                                      if (isAllowed) {
                                        setFieldValue("ifsc", newValue);
                                      }
                                    }}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container mt={1}>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              justifyContent={"space-between"}
                            >
                              <Grid item lg={4} md={4} sm={4} mt={1}>
                                GST / PAN :
                              </Grid>
                              <Grid item lg={8} md={8} sm={8}>
                                <Tooltip
                                  title="GST / PAN"
                                  arrow
                                  placement="right-end"
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.bank_gst_no_checkbox}
                                      />
                                    }
                                    name="bank_gst_no_checkbox"
                                    value={values.bank_gst_no_checkbox}
                                    onChange={(event, value) => {
                                      setFieldValue("gst_no", "");
                                      setFieldValue("pan_no", "");
                                      setFieldValue(
                                        "bank_gst_no_checkbox",
                                        value
                                      );
                                    }}
                                    // onChange={handleChange}
                                  />
                                </Tooltip>
                              </Grid>
                            </Grid>
                          </Grid>

                          {values.bank_gst_no_checkbox ? (
                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  GST :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="GST"
                                    // disabled={props.edit ? true : false}
                                    id="gst_no"
                                    size="small"
                                    name="gst_no"
                                    error={
                                      Boolean(errors.gst_no) &&
                                      Boolean(touched.gst_no)
                                    }
                                    helperText={
                                      Boolean(touched.gst_no) && errors.gst_no
                                    }
                                    onChange={(e) => {
                                      const newValue = capitalizeWords(
                                        e.target.value
                                      );
                                      const isAllowed = /^[A-Z0-9]{0,15}$/.test(
                                        newValue
                                      );
                                      if (isAllowed) {
                                        setFieldValue("gst_no", newValue);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid container mt={1}>
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                justifyContent={"space-between"}
                              >
                                <Grid item lg={4} md={4} sm={4} mt={1}>
                                  PAN :
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="PAN"
                                    // disabled={props.edit ? true : false}
                                    id="pan_no"
                                    size="small"
                                    name="pan_no"
                                    error={
                                      Boolean(errors.pan_no) &&
                                      Boolean(touched.pan_no)
                                    }
                                    helperText={
                                      Boolean(touched.pan_no) && errors.pan_no
                                    }
                                    onChange={(e) => {
                                      const newValue = capitalizeWords(
                                        e.target.value
                                      );
                                      const isAllowed = /^[A-Z0-9]{0,15}$/.test(
                                        newValue
                                      );
                                      if (isAllowed) {
                                        setFieldValue("pan_no", newValue);
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Box>
                )} */}

              {/* <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Account Manager * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Account Manager"
                      id="account_manager"
                      size="small"
                      name="account_manager"
                      error={
                        Boolean(errors.account_manager) &&
                        Boolean(touched.account_manager)
                      }
                      helperText={
                        Boolean(touched.account_manager) &&
                        errors.account_manager
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Content Delivered * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      size="small"
                      name="all_content_delivered"
                      value={
                        values.all_content_delivered
                          ? values.all_content_delivered
                          : null
                      }
                      component={AutoCompleteMultiSelect}
                      options={
                        props.contentDeliveredList
                          ? props.contentDeliveredList
                          : []
                      }
                      textFieldProps={{
                        margin: "normal",
                        variant: "outlined",
                        placeholder: "Content Delivered",
                      }}
                      error={
                        Boolean(errors.all_content_delivered) &&
                        Boolean(touched.all_content_delivered)
                      }
                      helperText={
                        Boolean(touched.all_content_delivered) &&
                        errors.all_content_delivered
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Business Unit * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Business Unit"
                      id="business_unit"
                      size="small"
                      name="business_unit"
                      error={
                        Boolean(errors.business_unit) &&
                        Boolean(touched.business_unit)
                      }
                      helperText={
                        Boolean(touched.business_unit) && errors.business_unit
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Channel Manager * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Channel Manager"
                      id="channel_manager"
                      size="small"
                      name="channel_manager"
                      error={
                        Boolean(errors.channel_manager) &&
                        Boolean(touched.channel_manager)
                      }
                      helperText={
                        Boolean(touched.channel_manager) &&
                        errors.channel_manager
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Client Classification * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Client Classification"
                      id="client_classification"
                      size="small"
                      name="client_classification"
                      error={
                        Boolean(errors.client_classification) &&
                        Boolean(touched.client_classification)
                      }
                      helperText={
                        Boolean(touched.client_classification) &&
                        errors.client_classification
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Contract End Date * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <FastField
                      component={DatePickerComponent}
                      size="small"
                      name={`contract_end_date`}
                      label={false}
                      textFieldProps={{
                        fullWidth: true,
                        margin: "normal",
                        variant: "outlined",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Contract Signature Date * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <FastField
                      component={DatePickerComponent}
                      size="small"
                      name={`contract_signature_date`}
                      label={false}
                      textFieldProps={{
                        fullWidth: true,
                        margin: "normal",
                        variant: "outlined",
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Contract A3 Entity * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Contract A3 Entity"
                      id="contracting_a3_entity"
                      size="small"
                      name="contracting_a3_entity"
                      error={
                        Boolean(errors.contracting_a3_entity) &&
                        Boolean(touched.contracting_a3_entity)
                      }
                      helperText={
                        Boolean(touched.contracting_a3_entity) &&
                        errors.contracting_a3_entity
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Cost Centre * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Cost Centre"
                      id="cost_centre"
                      size="small"
                      name="cost_centre"
                      error={
                        Boolean(errors.cost_centre) &&
                        Boolean(touched.cost_centre)
                      }
                      helperText={
                        Boolean(touched.cost_centre) && errors.cost_centre
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Digital Marketing Manager * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Digital Marketing Manager"
                      id="digital_marketing_manager"
                      size="small"
                      name="digital_marketing_manager"
                      error={
                        Boolean(errors.digital_marketing_manager) &&
                        Boolean(touched.digital_marketing_manager)
                      }
                      helperText={
                        Boolean(touched.digital_marketing_manager) &&
                        errors.digital_marketing_manager
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    First Account Signatory * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="First Account Signatory "
                      id="first_account_signatory"
                      size="small"
                      name="first_account_signatory"
                      error={
                        Boolean(errors.first_account_signatory) &&
                        Boolean(touched.first_account_signatory)
                      }
                      helperText={
                        Boolean(touched.first_account_signatory) &&
                        errors.first_account_signatory
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Main Royalty Rate * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Main Royalty Rate"
                      id="main_royalty_rate"
                      size="small"
                      name="main_royalty_rate"
                      error={
                        Boolean(errors.main_royalty_rate) &&
                        Boolean(touched.main_royalty_rate)
                      }
                      helperText={
                        Boolean(touched.main_royalty_rate) &&
                        errors.main_royalty_rate
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Neighbouring Rates * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Neighbouring Rates "
                      id="neighboring_rates"
                      size="small"
                      name="neighboring_rates"
                      error={
                        Boolean(errors.neighboring_rates) &&
                        Boolean(touched.neighboring_rates)
                      }
                      helperText={
                        Boolean(touched.neighboring_rates) &&
                        errors.neighboring_rates
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Number of Release * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Number of Release"
                      id="number_of_release"
                      size="small"
                      name="number_of_release"
                      error={
                        Boolean(errors.number_of_release) &&
                        Boolean(touched.number_of_release)
                      }
                      helperText={
                        Boolean(touched.number_of_release) &&
                        errors.number_of_release
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Project Manager * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Project Manager"
                      id="project_manager"
                      size="small"
                      name="project_manager"
                      error={
                        Boolean(errors.project_manager) &&
                        Boolean(touched.project_manager)
                      }
                      helperText={
                        Boolean(touched.project_manager) &&
                        errors.project_manager
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Royalty Rate 2 * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Royalty Rate 2"
                      id="royalty_rate_2"
                      size="small"
                      name="royalty_rate_2"
                      error={
                        Boolean(errors.royalty_rate_2) &&
                        Boolean(touched.royalty_rate_2)
                      }
                      helperText={
                        Boolean(touched.royalty_rate_2) && errors.royalty_rate_2
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Royalty Rate 3 * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Royalty Rate 3 "
                      id="royalty_rate_3"
                      size="small"
                      name="royalty_rate_3"
                      error={
                        Boolean(errors.royalty_rate_3) &&
                        Boolean(touched.royalty_rate_3)
                      }
                      helperText={
                        Boolean(touched.royalty_rate_3) && errors.royalty_rate_3
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Royalty Rate 4 * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Royalty Rate 4"
                      id="royalty_rate_4"
                      size="small"
                      name="royalty_rate_4"
                      error={
                        Boolean(errors.royalty_rate_4) &&
                        Boolean(touched.royalty_rate_4)
                      }
                      helperText={
                        Boolean(touched.royalty_rate_4) && errors.royalty_rate_4
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Specific agent Royalty Rate * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Specific agent Royalty Rate "
                      id="specific_agent_royalty_rate"
                      size="small"
                      name="specific_agent_royalty_rate"
                      error={
                        Boolean(errors.specific_agent_royalty_rate) &&
                        Boolean(touched.specific_agent_royalty_rate)
                      }
                      helperText={
                        Boolean(touched.specific_agent_royalty_rate) &&
                        errors.specific_agent_royalty_rate
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Specific store Royalty Rate * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Specific store Royalty Rate"
                      id="specific_store_royalty_rate"
                      size="small"
                      name="specific_store_royalty_rate"
                      error={
                        Boolean(errors.specific_store_royalty_rate) &&
                        Boolean(touched.specific_store_royalty_rate)
                      }
                      helperText={
                        Boolean(touched.specific_store_royalty_rate) &&
                        errors.specific_store_royalty_rate
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Specific territory Royalty Rate * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Specific territory Royalty Rate"
                      id="specific_territory_royalty_rate"
                      size="small"
                      name="specific_territory_royalty_rate"
                      error={
                        Boolean(errors.specific_territory_royalty_rate) &&
                        Boolean(touched.specific_territory_royalty_rate)
                      }
                      helperText={
                        Boolean(touched.specific_territory_royalty_rate) &&
                        errors.specific_territory_royalty_rate
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Starting Event * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Starting Event"
                      id="starting_event"
                      size="small"
                      name="starting_event"
                      error={
                        Boolean(errors.starting_event) &&
                        Boolean(touched.starting_event)
                      }
                      helperText={
                        Boolean(touched.starting_event) && errors.starting_event
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Turn Over Begin * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Turn Over Begin"
                      id="turn_over_begin"
                      size="small"
                      name="turn_over_begin"
                      error={
                        Boolean(errors.turn_over_begin) &&
                        Boolean(touched.turn_over_begin)
                      }
                      helperText={
                        Boolean(touched.turn_over_begin) &&
                        errors.turn_over_begin
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Turn over last Quarter * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Turn over last Quarter"
                      id="turn_over_last_quarter"
                      size="small"
                      name="turn_over_last_quarter"
                      error={
                        Boolean(errors.turn_over_last_quarter) &&
                        Boolean(touched.turn_over_last_quarter)
                      }
                      helperText={
                        Boolean(touched.turn_over_last_quarter) &&
                        errors.turn_over_last_quarter
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Last Created * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Last Created"
                      id="last_created"
                      size="small"
                      name="last_created"
                      error={
                        Boolean(errors.last_created) &&
                        Boolean(touched.last_created)
                      }
                      helperText={
                        Boolean(touched.last_created) && errors.last_created
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Main Music Genre * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Main Music Genre"
                      id="main_music_genre"
                      size="small"
                      name="main_music_genre"
                      error={
                        Boolean(errors.main_music_genre) &&
                        Boolean(touched.main_music_genre)
                      }
                      helperText={
                        Boolean(touched.main_music_genre) &&
                        errors.main_music_genre
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Mobile Number * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Mobile Number"
                      id="mobile_number"
                      size="small"
                      name="mobile_number"
                      error={
                        Boolean(errors.mobile_number) &&
                        Boolean(touched.mobile_number)
                      }
                      helperText={
                        Boolean(touched.mobile_number) && errors.mobile_number
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={1}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Status * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Status"
                      id="status"
                      size="small"
                      name="status"
                      error={Boolean(errors.status) && Boolean(touched.status)}
                      helperText={Boolean(touched.status) && errors.status}
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid> */}
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      disabled={values.boolean}
                      loading={loading}
                      type="submit"
                      name="Add Client"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Submit"
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={() => {
                        document.querySelector("input[type='file']").value = "";
                      }}
                      width="100%"
                      cancel
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      cancel
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

function capitalizeWords(input) {
  return input.toUpperCase();
}

export default connect(mapDispatchToProps)(ClientPost);
