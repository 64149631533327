import React from "react";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { demoText } from "../grammerlyPluginId/grammerlyDemo";
import GrammarlyCustomisedPlugin from "../grammerlyPluginId/GrammarlyCustomisedPlugin";

export default function MuiTextField(props) {
  const theme = useTheme();

  //theme overrides for mui textfield component
  theme.components = {
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            outline: `1px ${theme.palette.borderColor.textField}`,
            backgroundColor: theme.palette.background.paper,
            fontSize: "14px",
            borderRadius: theme.palette.buttonComponent.borderRadius.small,
            height: props.height ? props.height : "100%",
            padding: "0px",
            "&.Mui-focused fieldset": {
              border: `1.5px solid ${theme.palette.primary.main}`,
              "& legend": {
                display: "none",
              },
            },
          },
        },
      },
    },
  };

  return (
    <FormControl fullWidth>
      <GrammarlyCustomisedPlugin>
        <TextField
          type={props.type ? props.type : "text"}
          onPaste={props.onPaste}
          inputProps={{
            readOnly: props.readOnly ? props.readOnly : false,
            inputMode: props.inputMode ? props.inputMode : "text",
            onKeyPress: props.handleKeyPress,
            maxLength: props.maxLength && props.maxLength,
          }}
          {...props}
          autoComplete={"off"}
          id="outlined-basic"
          variant="outlined"
          // defaultValue={demoText.input}
          sx={{
            "& legend": { display: "none" },
            "& fieldset": { top: 0 },
            width: props.width ? props.width : "100%",
            svg: { margin: "10px" },
            height: props.fieldheight ? props.fieldheight : "100%",
          }}
        />
      </GrammarlyCustomisedPlugin>
    </FormControl>
  );
}
