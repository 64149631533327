import BaseReducer from "../../utilities/BaseReducer";
import TopDevicesAction from "./TopDevicesAction";

// TrackReduser
export default class TopDevicesReduser extends BaseReducer {
  initialState = {
    top_device_type_report: [],
  };
  [TopDevicesAction.REQUEST_TOPDEVICES_FINISHED](state, action) {
    return {
      ...state,
      top_device_type_report: action.payload,
    };
  }
}
