import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

export default function MuiButton({
  name,
  type,
  onClick,
  width,
  title,
  disabled,
  loading,
  noTextOnLoading,
  minWidth,
  fontSize,
  size,
  height,
  backgroundColor,
  cancel,
  login,
  save,
  ref,
  burronBackground,
}) {
  const theme = useTheme();

  return (
    // <Typography>
    <LoadingButton
      ref={ref}
      disabled={disabled}
      variant="contained"
      type={type}
      loading={loading}
      loadingPosition={noTextOnLoading ? "" : "end"}
      title={title}
      sx={{
        textTransform: "none",
        backgroundColor: burronBackground
          ? burronBackground
          : cancel
          ? theme.palette.buttonComponent.background.cancel
          : login
          ? theme.palette.background.main
          : save
          ? theme.palette.background.button
          : theme.palette.background.primary,
        border: "solid 1px #eaeaea",
        borderRadius: theme.palette.buttonComponent.borderRadius.small,
        color: backgroundColor
          ? theme.palette.primary.main
          : cancel || login
          ? "#fff"
          : save
          ? theme.palette.primary.main
          : "#fff",
        fontSize: fontSize ? fontSize : "14px",
        // marginRight: "10px",
        // width: 200,
        height: height ? height : theme.palette.field.height,
        width: width ? width : "auto",
        minWidth: minWidth && minWidth,
        "&:hover": {
          backgroundColor: theme.palette.buttonComponent.hover.primary,
          color: backgroundColor ? "white" : "#d6ebf0",
        },
      }}
      onClick={onClick}
    >
      {noTextOnLoading && loading ? "" : name}
    </LoadingButton>
    // </Typography>
  );
}
