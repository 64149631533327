import { Box, ListItem } from "@mui/material";
import React from "react";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import Rettrive from "../releases/Rettrive";
import ImageIcon from "@mui/icons-material/Image";
import { theme } from "../App";

const ReleasesShadowTabel = (props) => {
  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  let columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },
    {
      width: 80,
      flex: 1,
      field: "type_of_release",
      headerName: "Type",
      renderCell: (params) => {
        return params.row.type_of_release ? params.row.type_of_release : "";
      },
    },

    {
      width: 130,
      flex: 1,
      field: "status",
      headerName: "Status",
      cellClassName: "status",
    },
    {
      width: 130,
      flex: 1,
      field: "thumbnail_url",
      headerName: "Thumbnail",
      hide: true,
    },

    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            {/* <div>
              <Box
                style={{
                  width: 37,
                  maxHeight: 33,
                  padding: 5,

                  backgroundColor: "#d0e9eb",
                  display: "grid",
                  placeItems: "center",
                }}
              >
                {params?.row?.thumbnail_url ? (
                  <Rettrive
                    thumbnail_url={params?.row?.thumbnail_url}
                    params={params}
                    current_page={"releaseActivities"}
                  />
                ) : (
                  <ImageIcon
                    style={{
                      width: "100%",
                      height: "100%",
                      color: theme.palette.primary.light,
                    }}
                  />
                )}
              </Box>
            </div> */}

            <div
              style={{
                marginLeft: 0,
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
              }}
              // className="bg-warning"
            >
              <div
                style={{ width: "60%" }}
                // className="bg-success"
              >
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.artists?.length ? "By " : ""}
                  {params?.row?.artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.artists?.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      width: 130,
      flex: 1,
      field: "artists",
      headerName: "Artists",
      hide: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.artists &&
              params.row.artists.length > 0 &&
              params.row.artists.map((artist) => (
                <>
                  <span>{artist.artist_id.artist_name}</span>
                  <br />
                </>
              ))}
          </div>
        );
      },
    },

    {
      width: 130,
      flex: 1,
      field: "label",
      headerName: "Label",
      renderCell: (params) => {
        return params.row.label && params.row.label.label_name
          ? params.row.label.label_name
          : "";
      },
    },
    {
      width: 200,
      flex: 1,
      field: "created_on",
      headerName: "Created On",
      renderCell: (params) => {
        return formatDate(params.row.created_on);
      },
    },
    {
      width: 200,
      flex: 1,
      field: "main_release_date",
      headerName: "Release date",
      renderCell: (params) => {
        return formatDate(params.row.main_release_date);
      },
    },
    {
      width: 130,
      flex: 1,
      field: "no_of_tracks",
      headerName: "No-Track",
    },
    { width: 130, flex: 1, field: "UPC_EAN", headerName: "UPC" },

    // {
    //   field: "deliveredTerritories",
    //   headerName: "Delivered Territories & Stores",
    //   resizable: false,
    //   width: 200,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             width: "100%",
    //           }}
    //         >
    //           <Button
    //             variant="outlined"
    //             onClick={() => {
    //               setOpenTerritoryyDialog(true);
    //               setTerritorySelected(params.row.territory);
    //             }}
    //             style={{
    //               padding: 2,
    //               display: "flex",
    //               borderColor: theme.palette.common.grey,
    //               justifyContent: "space-between",
    //             }}
    //           >
    //             <PublicIcon
    //               size="small"
    //               sx={{ color: theme.palette.common.grey }}
    //             />
    //             <Typography>
    //               {params.row.territory ? params.row.territory.length : 0}{" "}
    //               terrs.
    //             </Typography>
    //           </Button>

    //           {/* <Button
    //             variant="outlined"
    //             onClick={handleClickOpen("body")}
    //             style={{
    //               height: "18px",
    //               position: "relative",
    //             }}
    //           >
    //             <IconButton
    //               title="Globe"
    //               sx={{
    //                 border: "0",
    //                 position: "absolute",
    //                 left: 0,
    //               }}
    //             >
    //               <PublicIcon
    //                 color="active"
    //                 style={{ height: "20px", width: "20px" }}
    //               />
    //             </IconButton>
    //             <span style={{ marginLeft: "25px" }}>240 terrs.</span>
    //           </Button> */}
    //           {/* <Dialog open={open} onClose={handleClose} scroll={scroll}>
    //             <DialogTitle id="scroll-dialog-title">Territories</DialogTitle>
    //             <DialogContent dividers={scroll === "body"}></DialogContent>
    //           </Dialog> */}
    //         </div>
    //       </>
    //     );
    //   },
    // },

    // {
    //   width: 50,
    //   field: "issues",
    //   type: "actions",
    //   headerName: (
    //     <div title="Add Task">
    //       <AddTaskIcon
    //         style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //       />
    //     </div>
    //   ),
    //   //
    //   // width: 160,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <RaiseTicketPopper
    //       issueTypes={issueTypes}
    //       onClick={(issueType) => {
    //         setOpenIssueRaiseDialog(true);
    //         setReleaseDataIssue({
    //           releaseData: params.row,
    //           type: issueType,
    //         });
    //       }}
    //     >
    //       <div title="Add Task">
    //         <AddTaskIcon
    //           style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //         />
    //       </div>
    //     </RaiseTicketPopper>
    //   ),
    // },
  ];

  const rows = [];

  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  if (
    props.props.releasesShadowTableById &&
    props.props.releasesShadowTableById.hits
  ) {
    props.props.releasesShadowTableById.hits.hits &&
      props.props.releasesShadowTableById.hits.hits.length > 0 &&
      props.props.releasesShadowTableById.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.props.pagination.current_page_no * props.page_limit +
              ++index
            : "",
          id: item._id,
          type_of_release:
            item._source.log.type_of_release && item._source.log.type_of_release
              ? item._source.log.type_of_release.charAt(0).toUpperCase() +
                item._source.log.type_of_release.slice(1)
              : "",
          status:
            item._source.log.status && item._source.log.status.status_name
              ? convertToTitleCase(item._source.log.status.status_name)
              : "",
          thumbnail_url: item._source.log.thumbnail_url,
          title: item._source.log.title,
          artists: item._source.log.artists,
          label: item._source.log.label,
          digital_release_date: item._source.log.digital_release_date,
          no_of_tracks: item._source.log.tracks?.length,
          UPC_EAN: item._source.log.UPC_EAN,
          copyrighted_to: item._source.log.copyrighted_to,
          featuring_artists: item._source.log.featuring_artists,
          main_release_date: item._source.log.main_release_date,
          pre_order_release_date: item._source.log.pre_order_release_date,
          production_year: item._source.log.production_year,
          published_by: item._source.log.published_by,
          producer_catalogue_number: item._source.log.producer_catalogue_number,
          genre_type: item._source.log.genre_type,
          sub_genre_type: item._source.log.sub_genre_type,
          format: item._source.log.format,
          territory: item._source.log.territory,
          created_on: item._source.created_on,
        });
      });
  }

  return (
    <DataGridFunction
      columns={columns ? columns : []}
      rows={rows ? rows : []}
      rowHeight={80}
      pagelength={props.props?.pagination?.total_page_no}
      isRequesting={props.isRequesting}
      page={props.props?.pagination?.current_page_no}
      handleChange={props.handleChange}
      height={"75vh"}
    />
  );
};

export default ReleasesShadowTabel;
