import HttpErrorResponseModel from "../models/HttpErrorResponseModel";
import HttpUtility from "./HttpUtility";

export default class EffectUtility {
  // Project Get method Model
  static async getToModel(Model, endpoint, params) {
    const response = await HttpUtility.get(endpoint, params);

    return EffectUtility._restModelCreator(Model, response);
  }

  // Project Post method Model
  static async postToModel(Model, endpoint, data, params) {
    const response = await HttpUtility.post(endpoint, data, params);

    return EffectUtility._restModelCreator(Model, response);
  }

  static async postWithIdModel(Model, endpoint, data) {
    const response = await HttpUtility.postWithId(endpoint, data);

    return EffectUtility._restModelCreator(Model, response);
  }

  // Project Put method Model
  static async putToModel(Model, endpoint, data, params) {
    const response = await HttpUtility.put(endpoint, data, params);

    return EffectUtility._restModelCreator(Model, response);
  }

  //DELETE to model
  static async deleteToModel(Model, endpoint, params) {
    const response = await HttpUtility.delete(endpoint, params);

    return EffectUtility._restModelCreator(Model, response);
  }

  static _restModelCreator(Model, response) {
    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return !Array.isArray(response.data)
      ? new Model(response.data)
      : response.data.map((json) => new Model(json));
  }
}
