import { Box, Grid, Typography, ListItem, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import TerritoryDailog from "../releases/TerritoryDailog";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { connect } from "react-redux";
import PublicIcon from "@mui/icons-material/Public";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import GenreAction from "../../stores/genre/GenreAction";
import ArtistAction from "../../stores/artist/ArtistAction";
import PromoteTracksDialog from "./PromoteTracksDialog";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_RELEASES,
    GenreAction.REQUEST_GENRE,
    ReleasesAction.REQUEST_RELEASE_FILTER,
    ArtistAction.REQUEST_ARTIST_FILTER,
  ]),
  release: state.release.release || [],
  genre: state.genre.genre || [],
  pagination: state.release.release || [],
  artistFilter: state.artistFilter.artistFilter || [],
});

function Promote(props) {
  const [openTerritoryDialog, setOpenTerritoryyDialog] = React.useState(false);
  const [territorySelected, setTerritorySelected] = React.useState(null);
  const [page_limit] = useState(15);
  const [openTracksDialog, setOpenTracksDialog] = React.useState(false);
  const [releaseData, setReleaseData] = useState(null);

  function fetchGenre() {
    const param = {
      page_limit: "none",
      order_by: `[["genre_name.keyword", "asc"]]`,
      current_page: "delivered",
    };
    props.dispatch(GenreAction.requestGenre(param));
  }

  function fetchArtistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function fetchReleases(params) {
    props.dispatch(ReleasesAction.requesReleases(params));
  }

  useEffect(() => {
    let params = {
      page_limit: page_limit,
      order_by: '[["created_on", "desc"]]',
      current_page: "promote",
      // filters: `[["status","must","","match","8"]]`,
    };
    const filterparam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "promote",
    };

    fetchReleases(params);

    fetchGenre();

    fetchArtistFilter(filterparam);
  }, []);

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },
    {
      width: 80,
      flex: 1,
      field: "type_of_release",
      headerName: "Type",
      renderCell: (params) => {
        return params.row.type_of_release ? params.row.type_of_release : "";
      },
    },

    {
      width: 130,
      flex: 1,
      field: "status",
      headerName: "Status",
      cellClassName: "status",
      // renderCell: () => {
      //   return (
      //     <IconButton title="Status" sx={{ border: "0" }}>
      //       <FontAwesomeIcon
      //         icon={faIndustry}
      //         style={{ height: "30px", width: "30px" }}
      //       ></FontAwesomeIcon>
      //     </IconButton>
      //   );
      // },
    },
    {
      width: 130,
      flex: 1,
      field: "thumbnail_url",
      headerName: "Thumbnail",
      hide: true,
    },

    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        // console.log("check==>", Rettrive(params?.row?.thumbnail_url, params));
        return (
          <ListItem className="p-0 m-0">
            {/* <div>
              <Box
                style={{
                  width: 37,
                  height: 33,
                  maxHeight: 33,
                  borderRadius: 5,
                  // boxShadow: theme.shadows,
                  // borderRadius: "5px",
                  backgroundColor: "#d0e9eb",
                  display: "grid",
                  placeItems: "center",
                  overflow: "hidden",
                }}
              >
                {!props..isRequesting && params?.row?.thumbnail_url ? (
                  <Rettrive
                    thumbnail_url={params?.row?.thumbnail_url}
                    params={params}
                  />
                ) : (
                  <Box sx={{ padding: 1 }}>
                    <ImageIcon
                      style={{
                        width: "100%",
                        height: "100%",
                        color: theme.palette.primary.light,
                      }}
                    />
                  </Box>
                )}
              </Box>
            </div> */}

            <div
              style={{
                // marginLeft: 5,
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
              }}
              // className="bg-warning"
            >
              <div
                style={{ width: "60%" }}
                // className="bg-success"
              >
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.artists?.length ? "By " : ""}
                  {params?.row?.artists?.map((item, index) => (
                    <span key={index}>
                      {item?.artist_id?.artist_name}
                      {index !== params?.row?.artists?.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      width: 130,
      flex: 1,
      field: "artists",
      headerName: "Artists",
      hide: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.artists &&
              params.row.artists.length > 0 &&
              params.row.artists.map((artist) => (
                <>
                  <span>{artist.artist_id.artist_name}</span>
                  <br />
                </>
              ))}
          </div>
        );
      },
    },

    {
      width: 130,
      flex: 1,
      field: "label",
      headerName: "Label",
      renderCell: (params) => {
        return params.row.label && params.row.label.label_name
          ? params.row.label.label_name
          : "";
      },
    },
    {
      width: 200,
      flex: 1,
      field: "main_release_date",
      headerName: "Release date",
      renderCell: (params) => {
        return formatDate(params.row.main_release_date);
      },
    },
    { width: 130, flex: 1, field: "no_of_tracks", headerName: "# Of Track" },
    { width: 130, flex: 1, field: "UPC_EAN", headerName: "UPC" },

    {
      field: "deliveredTerritories",
      headerName: "Delivered Territories & Stores",
      resizable: false,
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setOpenTerritoryyDialog(true);
                  setTerritorySelected(params.row.territory);
                }}
                style={{
                  padding: 2,
                  display: "flex",
                  borderColor: theme.palette.common.grey,
                  justifyContent: "space-between",
                }}
              >
                <PublicIcon
                  size="small"
                  sx={{ color: theme.palette.common.grey }}
                />
                <Typography>
                  {params.row.territory ? params.row.territory.length : 0}{" "}
                  terrs.
                </Typography>
              </Button>

              {/* <Button
                variant="outlined"
                onClick={handleClickOpen("body")}
                style={{
                  height: "18px",
                  position: "relative",
                }}
              >
                <IconButton
                  title="Globe"
                  sx={{
                    border: "0",
                    position: "absolute",
                    left: 0,
                  }}
                >
                  <PublicIcon
                    color="active"
                    style={{ height: "20px", width: "20px" }}
                  />
                </IconButton>
                <span style={{ marginLeft: "25px" }}>240 terrs.</span>
              </Button> */}
              {/* <Dialog open={open} onClose={handleClose} scroll={scroll}>
                <DialogTitle id="scroll-dialog-title">Territories</DialogTitle>
                <DialogContent dividers={scroll === "body"}></DialogContent>
              </Dialog> */}
            </div>
          </>
        );
      },
    },
  ];

  const rows = [];

  if (props.release && props.release.hits) {
    props.release.hits.hits &&
      props.release.hits.hits.length > 0 &&
      props.release.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          type_of_release: item._source.type_of_release,
          status: item._source.status?.status_name,
          thumbnail_url: item._source.thumbnail_url,
          title: item._source.title,
          artists: item._source.artists,
          label: item._source.label,
          digital_release_date: item._source.digital_release_date,
          no_of_tracks: item._source.tracks?.length,
          UPC_EAN: item._source.UPC_EAN,
          copyrighted_to: item._source.copyrighted_to,
          featuring_artists: item._source.featuring_artists,
          main_release_date: item._source.main_release_date,
          pre_order_release_date: item._source.pre_order_release_date,
          production_year: item._source.production_year,
          published_by: item._source.published_by,
          producer_catalogue_number: item._source.producer_catalogue_number,
          genre_type: item._source.genre_type,
          sub_genre_type: item._source.sub_genre_type,
          format: item._source.format,
          territory: item._source.territory,
          thumbnail_url: item._source.thumbnail_url,
        });
      });
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <TerritoryDailog
        list={territorySelected}
        open={openTerritoryDialog}
        onClose={() => {
          setOpenTerritoryyDialog(false);
        }}
      />
      <DataGridFunction
        curser="pointer"
        columns={columns}
        rows={rows}
        rowHeight={80}
        pagelength={props?.pagination?.total_page_no}
        page={props?.pagination?.current_page_no}
        handleChange={props.handleChange}
        onRowClick={(params) => {
          setReleaseData(params.row);
          setOpenTracksDialog(true);
        }}
      />
      <PromoteTracksDialog
        open={openTracksDialog}
        onClose={() => {
          setOpenTracksDialog(false);
        }}
        releaseData={releaseData}
      />
    </Box>
  );
}

export default connect(mapStateToProps)(Promote);
