import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TopTrackModel from "./model/TopTrackModel";
import TopTrackPutModel from "./model/TopTrackPutModel";
import TopTrackPostModel from "./model/TopTrackPostModel";
import TopTrackWithDateFilterModel from "./model/TopTrackWithDateFilterModel";

// TopTrackEffect
export default class TopTrackEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestTopTrack(params, callBack) {
    const endpoint = environment.api.topTracks;
    let response = EffectUtility.getToModel(TopTrackModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestTopTrackWithDateFilter(params, callBack) {
    const endpoint = environment.api.topTracksWithDateFilter;
    let response = EffectUtility.getToModel(
      TopTrackWithDateFilterModel,
      endpoint,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // Get method for the track filter
  static async requestTopTrackFilter(params) {
    const endpoint = environment.api.track;
    return EffectUtility.getToModel(TopTrackModel, endpoint, params);
  }

  // put method for the track
  static async requestPutTopTrack(data, id) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.putToModel(TopTrackPutModel, endpoint, data);
  }

  // post method for the track
  static async requestPostTopTrack(data) {
    const endpoint = environment.api.track + "/add";
    return EffectUtility.postToModel(TopTrackPostModel, endpoint, data);
  }

  static async requestDeleteTopTrack(id) {
    const endpoint = environment.api.track + "/" + id;
    return EffectUtility.deleteToModel(TopTrackPostModel, endpoint);
  }
}
