import { Box, Grid, MenuItem, OutlinedInput, Select } from "@mui/material";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import PlanAction from "../../stores/plan/PlanAction";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import {
  menuProps,
  SelectFieldObject,
} from "../releaseFormComponents/reusedComponents/ReusedComponents";
import MuiButton from "../muiButton/MuiButton";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [PlanAction.REQUEST_PLAN]),
  planData: state.plan.plan || {},
});

const Plan = (props) => {
  function fetchPlanData() {
    let params = {
      page_limit: "none",
      current_page: "plan",
    };
    props.dispatch(PlanAction.requestPlan(params));
  }

  useEffect(() => {
    fetchPlanData();
  }, []);

  let data_array = [];

  if (props.planData && props.planData.hits) {
    props.planData.hits.hits.length !== 0 &&
      props.planData.hits.hits.map((item) => {
        data_array.push({
          plan_id: item._id,
          plan_name: item._source.plan_name,
        });
      });
  }

  const returnPlanObject = (id) => {
    let filtered_plan =
      data_array &&
      data_array.length !== 0 &&
      data_array.filter((item) => {
        return item.plan_id === id;
      });

    return filtered_plan && filtered_plan.length !== 0
      ? filtered_plan[0].plan_id
      : "";
  };
  let initial = {
    plan_id:
      props.releseData &&
      props.releseData._source &&
      props.releseData._source.plan_id
        ? props.releseData && props.releseData._source.plan_id.id
        : "",
  };

  const inputProps = {
    sx: {
      "& legend": { display: "none" },
      "& fieldset": {
        top: 0,
      },
      backgroundColor: "white",
      outline: "none",
      borderRadius: "5px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderWidth: "1px",
      },
    },
  };

  function Required() {
    return (
      <>
        <span style={{ color: "red" }}>Required</span>{" "}
        <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  const [loading, setLoading] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (props.plantriggerSubmit) {
      formRef.current.submitForm();
    }
  }, [props.plantriggerSubmit]);

  return (
    <Box width={"100%"}>
      <Formik
        initialValues={initial}
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange
        validate={(values) => {
          var errors = {};

          if (values.plan_id === "") {
            errors.plan_id = <Required />;
          }

          return errors;
        }}
        onSubmit={(values) => {
          let obj = {};
          setLoading(true);

          Object.assign(
            obj,
            values.plan_id && {
              plan_id: values.plan_id,
            }
          );
          props
            .dispatch(
              ReleasesAction.requestPutRelease(
                obj,
                props.releseData && props.releseData._id
              )
            )
            .then(() => {
              setLoading(false);
              props.setPlantriggerSubmit(false);

              props.dispatch(
                ReleasesAction.requestReleaseById(props.releseData._id)
              );
            });
        }}
        innerRef={formRef}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
        }) => (
          <Form style={{ width: "100%" }}>
            <Box width={"100%"}>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-end"
              >
                <Grid item lg={1} md={1} sm={1}>
                  <MuiButton
                    type="submit"
                    name={"Save"}
                    width="100%"
                    loading={loading}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                justifyContent={"flex-start"}
                alignItems="center"
              >
                <Grid>Choose a main Plan tier : </Grid>

                <Grid item lg={6} md={6} sm={6} mx={1}>
                  <Field name={"plan_id"}>
                    {({ field, values }) => {
                      return (
                        <Select
                          input={<OutlinedInput {...inputProps} />}
                          MenuProps={menuProps}
                          // value={field.value ? field.value : ""}
                          onChange={(event) => {
                            const selectedOption = data_array.find(
                              (option) => option.plan_id === event.target.value
                            );
                            field.onChange(selectedOption); // Update the hidden field with the selected object
                          }}
                          {...field}
                          displayEmpty
                          size={"small"}
                          style={{ width: "100%" }}
                          // value={values.plan_id}
                        >
                          {
                            <MenuItem value="" disabled>
                              <span
                                style={{
                                  color: "#b7c4c2",
                                  backgroundColor: "white",
                                }}
                              >
                                {"Select Plan"}
                              </span>
                            </MenuItem>
                          }

                          {data_array &&
                            data_array.length !== 0 &&
                            data_array.map((option) => (
                              <MenuItem
                                key={option.plan_id}
                                value={option.plan_id}
                              >
                                {option.plan_name}
                              </MenuItem>
                            ))}
                        </Select>
                      );
                    }}
                  </Field>

                  <ErrorMessage name={"plan_id"} />
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default connect(mapStateToProps)(Plan);
