import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TopArtistModel from "./model/TopArtistModel";
import TopArtistPutModel from "./model/TopArtistPutModel";
import TopArtistPostModel from "./model/TopArtistPostModel";

// TopArtistEffect
export default class TopArtistEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestTopArtist(params, callBack) {
    const endpoint = environment.api.topArtist;
    let response = EffectUtility.getToModel(TopArtistModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // Get method for the topArtist filter
  static async requestTopArtistFilter(params) {
    const endpoint = environment.api.topArtist;
    return EffectUtility.getToModel(TopArtistModel, endpoint, params);
  }

  // put method for the topArtist
  static async requestPutTopArtist(data, id) {
    const endpoint = environment.api.topArtist + "/" + id;
    return EffectUtility.putToModel(TopArtistPutModel, endpoint, data);
  }

  // post method for the topArtist
  static async requestPostTopArtist(data) {
    const endpoint = environment.api.topArtist + "/add";
    return EffectUtility.postToModel(TopArtistPostModel, endpoint, data);
  }

  static async requestDeleteTopArtist(id) {
    const endpoint = environment.api.topArtist + "/" + id;
    return EffectUtility.deleteToModel(TopArtistPostModel, endpoint);
  }
}
