import { BaseModel } from "sjs-base-model";

// TrackBulkPutModel
export default class TrackBulkPutModel extends BaseModel {
  // noneApiProperties = null;
  // response getting from the api mentioned end point

  data = [];
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
