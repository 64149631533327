import BaseReducer from "../../utilities/BaseReducer";
import PlanAction from "./PlanAction";

// PlanReduser
export default class PlanReduser extends BaseReducer {
  initialState = {
    plan: [],
  };
  [PlanAction.REQUEST_PLAN_FINISHED](state, action) {
    return {
      ...state,
      plan: action.payload,
    };
  }

  //   [LabelAction.REQUEST_LABELS_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       paln: action.payload,
  //     };
  //   }
}
