import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TerritoriesModel from "./model/TerritoriesModel";

// TerritoriesEffect
export default class TerritoriesEffect {
  // calling an API according to the TerritoriesEffect model
  static async requestTerritories(params) {
    const endpoint = environment.api.territories;
    return EffectUtility.getToModel(TerritoriesModel, endpoint, params);
  }

  // Get method for the territories filter
  static async requestTerritoriesFilter(params) {
    const endpoint = environment.api.territories;
    return EffectUtility.getToModel(TerritoriesModel, endpoint, params);
  }
}
