import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TopLanguagesModel from "./model/TopLanguagesModel";

// TopLanguagesEffect
export default class TopLanguagesEffect {
  // calling an API according to the plan model
  static async requestTopLanguages(params) {
    const endpoint = environment.api.top_languages_report;
    return EffectUtility.getToModel(TopLanguagesModel, endpoint, params);
  }

  // Get method for the plan filter
  static async requestTopLanguagesFilter(params) {
    const endpoint = environment.api.top_languages_report;
    return EffectUtility.getToModel(TopLanguagesModel, endpoint, params);
  }
}
