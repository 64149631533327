import BaseReducer from "../../utilities/BaseReducer";
import YTRevenueReportAction from "./YTRevenueReportAction";

export default class YTRevenueReportReducer extends BaseReducer {
  initialState = {
    ytRevenueReport: [],
  };
  [YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_FINISHED](state, action) {
    return {
      ...state,
      ytRevenueReport: action.payload,
    };
  }

  [YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ytRevenueReport: action.payload,
    };
  }
}
