import React, { useState } from "react";
import {
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik, Field, FieldArray } from "formik";
import { connect } from "react-redux";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../../views/App";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import Grow from "@mui/material/Grow";
import ClientRevenueSharePolicyAction from "../../stores/clientRevenueSharingPolicy/ClientRevenueSharePolicyAction";
import { useNavigate } from "react-router";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const ClientRevenueSharePolicyPost = (props) => {
  const navigate = useNavigate();

  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      client_id: props.editData?.client_id,
      standard_policy: props.editData?.standard_policy
        ? props.editData.standard_policy
        : {
            share_percentage: "",
            service_charge: "",
          },

      per_store_share_policy:
        props.editData.per_store_share_policy?.length > 0
          ? props.editData.per_store_share_policy
          : [
              {
                store_id: null,
                share_percentage: "",
                service_charge: "",
              },
            ],
    };
  } else {
    initial = {
      client_id: null,
      standard_policy: {
        share_percentage: "",
        service_charge: "",
      },
      per_store_share_policy: [
        {
          store_id: null,
          share_percentage: "",
          service_charge: "",
        },
      ],
    };
  }

  // Formik
  const [loading, setLoading] = useState(false);

  function Required() {
    return (
      <>
        <span style={{ color: theme.palette.secondary.main }}>Required</span>{" "}
        <span style={{ color: theme.palette.secondary.main }}>*</span>
      </>
    );
  }

  const AddIconFunction = (values, push) => {
    return (
      values.per_store_share_policy &&
      values.per_store_share_policy[values.per_store_share_policy.length - 1] &&
      values.per_store_share_policy[values.per_store_share_policy.length - 1]
        .store_id && (
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="flex-end"
          mt={1}
        >
          {" "}
          <MuiButton
            onClick={() =>
              push({
                // store_name: "",
                store_id: null,
                share_percentage: "",
                service_charge: "",
              })
            }
            name={<AddCircleOutlineIcon />}
          />
        </Grid>
      )
    );
  };

  const handlePhoneKeyPress = (event) => {
    const keyCode = event.which;
    if (keyCode < 48 || keyCode > 57) {
      // Prevent input of non-numeric characters
      event.preventDefault();
    }
  };

  function returnPlatformName(platformID) {
    let filteredPlatform = props.stream_platform_list.filter(
      (item) => item.platform_id == platformID
    );
    return filteredPlatform?.length > 0 ? filteredPlatform[0] : null;
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth="lg"
      TransitionComponent={Grow}
      transitionDuration={400}
    >
      <Formik
        initialValues={initial}
        validate={(values) => {
          var errors = {};

          if (!values.client_id) {
            errors.client_id = <Required />;
          }

          if (!values.standard_policy?.share_percentage) {
            errors.standard_policy = <Required />;
          } else if (values.standard_policy?.share_percentage !== "") {
            if (parseFloat(values.standard_policy?.share_percentage) > 100) {
              errors.standard_policy = (
                <span style={{ color: theme.palette.secondary.main }}>
                  Percentage must be less than or equal to 100
                </span>
              );
            }
          }

          if (values.standard_policy?.service_charge) {
            if (parseFloat(values.standard_policy?.service_charge) > 100) {
              errors.service_charge = (
                <span style={{ color: theme.palette.secondary.main }}>
                  Percentage must be less than or equal to 100
                </span>
              );
            }

            if (
              parseFloat(values.standard_policy?.service_charge) >
              parseFloat(values.standard_policy?.share_percentage)
            ) {
              errors.service_charge = (
                <span style={{ color: theme.palette.secondary.main }}>
                  Service Charge must be less than Share Percentage
                </span>
              );
            }
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          const post_obj = {};

          let perStoreSharePolicy = values.per_store_share_policy
            .filter((platformData) => platformData.store_id !== null)
            .map((platformData) => {
              return {
                store_id: platformData.store_id && platformData.store_id,
                share_percentage: platformData.share_percentage
                  ? parseFloat(platformData.share_percentage)
                  : 0,
                service_charge: platformData.service_charge
                  ? parseFloat(platformData.service_charge)
                  : 0,
              };
            });

          if (
            perStoreSharePolicy.length === 1 &&
            perStoreSharePolicy[0].share_percentage === 0
          ) {
            perStoreSharePolicy = [];
          } else if (perStoreSharePolicy.length > 1) {
            perStoreSharePolicy = perStoreSharePolicy;
          }

          Object.assign(
            post_obj,
            values.client_id && {
              client_id: props.edit
                ? props.editData.client_id.client_id
                : values.client_id.client_id,
            },
            values.standard_policy && {
              standard_policy: {
                service_charge: values.standard_policy.service_charge
                  ? parseFloat(values.standard_policy.service_charge)
                  : 0,
                share_percentage: values.standard_policy.share_percentage
                  ? parseFloat(values.standard_policy.share_percentage)
                  : 0,
              },
            },
            {
              per_store_share_policy: perStoreSharePolicy,
            }
          );

          if (props.edit && !props.clientID) {
            props
              .dispatch(
                ClientRevenueSharePolicyAction.requestPutClientRevenueSharePolicy(
                  post_obj,
                  props.editData.id
                )
              )
              .then(() => {
                const params = {
                  order_by: `[["modified_on","desc"]]`,
                  page_limit: 15,
                  current_page: "revenueSharePolicy",
                };
                props.dispatch(
                  ClientRevenueSharePolicyAction.requestClientRevenueSharePolicy(
                    params
                  )
                );
                props.onClose();
                setLoading(false);
              });
          } else {
            let current_page = "?current_page=revenueSharePolicy";

            props
              .dispatch(
                ClientRevenueSharePolicyAction.requestPostClientRevenueSharePolicy(
                  post_obj,
                  current_page
                )
              )
              .then(() => {
                const params = {
                  order_by: `[["modified_on","desc"]]`,
                  page_limit: 15,
                };
                props.dispatch(
                  ClientRevenueSharePolicyAction.requestClientRevenueSharePolicy(
                    params
                  )
                );
                props.clientID && navigate("/subClient");
                props.onClose();
                setLoading(false);
              });
          }
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText
                sx={{
                  width: "100%",
                  marginBottom: 5,
                }}
              >
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"div"}
                    fontSize={30}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      justifySelf: "center",
                    }}
                  >
                    {props.edit ? "Edit" : "Add"} Sub Client Revenue Sharing
                    Policy
                  </Typography>

                  <Typography
                    component={"span"}
                    sx={{
                      justifySelf: "end",
                      marginLeft: 3,
                      cursor: "pointer",
                      padding: "4px",
                      borderRadius: "50%",
                      "&:hover": {
                        transform: "scale(1.4)",
                        transition: "transform 0.3s ease-in-out",
                        color: theme.palette.secondary.main,
                      },
                    }}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <HighlightOffIcon sx={{ fontSize: 20 }} />
                  </Typography>
                </Typography>
              </DialogContentText>
              {/* ****************************************** Tag Name */}
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                alignItems={"center"}
                justifyContent="center"
              >
                <Grid item lg={3} md={3} sm={3} fontWeight={"bold"}>
                  Sub Client * :
                </Grid>
                <Grid item lg={6} md={6} sm={6}>
                  <Field
                    name="client_id"
                    size="small"
                    value={values.client_id ? values.client_id : null}
                    component={AutoCompleteMultiSelect}
                    options={props.client_list ? props.client_list : []}
                    filterOptions={createFilterOptions({
                      matchFrom: "start",
                      stringify: (option) => option.client_name,
                    })}
                    disabled={props.edit ? true : false}
                    isOptionEqualToValue={(option, value) =>
                      //setting value property to custom value
                      option.client_id === value.client_id
                    }
                    getOptionLabel={(option) => {
                      return option.client_name !== ""
                        ? `${option.client_name} (${
                            option.username ? option.username : ""
                          })`
                        : "";
                    }}
                    textFieldProps={{
                      margin: "normal",
                      variant: "outlined",
                      placeholder: "Sub Client",
                    }}
                    error={
                      Boolean(errors.client_id) && Boolean(touched.client_id)
                    }
                    helperText={Boolean(touched.client_id) && errors.client_id}
                  />
                </Grid>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                alignItems={"center"}
                justifyContent="center"
              >
                <Grid item lg={3} md={3} sm={3} fontWeight={"bold"}>
                  Standard Policy :
                </Grid>
                <Grid
                  tem
                  lg={6}
                  md={6}
                  sm={6}
                  container
                  alignItems={"flex-start"}
                  justifyContent="space-between"
                >
                  <Grid container item xl={5} lg={5} md={5} sm={5}>
                    <Grid item lg={12} md={12} sm={12}>
                      Client Share Percentage * :
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder="Client Share Percentage"
                        id="share_percentage"
                        size="small"
                        name={`standard_policy.share_percentage`}
                        inputMode="numeric"
                        handleKeyPress={handlePhoneKeyPress}
                        error={
                          Boolean(errors.standard_policy) &&
                          Boolean(touched?.standard_policy)
                        }
                        helperText={
                          Boolean(touched?.standard_policy) &&
                          errors?.standard_policy
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                alignItems={"center"}
                justifyContent="center"
              >
                <Grid item lg={3} md={3} sm={3} fontWeight={"bold"}>
                  Per Stream Platform Share Policy :
                </Grid>
                <Grid item container lg={6} md={6} sm={6}>
                  <FieldArray name="per_store_share_policy">
                    {({ insert, remove, push }) => (
                      <Grid
                        container
                        lg={12}
                        md={12}
                        sm={12}
                        mt={1}
                        // px={1}
                        className="d-flex justify-content-center"
                      >
                        {values.per_store_share_policy &&
                          values.per_store_share_policy.length > 0 &&
                          values.per_store_share_policy.map((role, index) => (
                            <Grid
                              container
                              lg={12}
                              md={12}
                              sm={12}
                              justifyContent="space-between"
                              //alignItems="center"
                              mt={1}
                              key={index}
                            >
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                container
                                className="d-flex align-items-center"
                              >
                                <Grid item lg={12} md={12} sm={12}>
                                  Stream Platform :
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <Field
                                    name={`per_store_share_policy.${index}.store_name`}
                                    size="small"
                                    disableClearable={true}
                                    value={
                                      values.per_store_share_policy[index]
                                        .store_id &&
                                      returnPlatformName(
                                        values.per_store_share_policy[index]
                                          .store_id
                                      )
                                    }
                                    component={AutoCompleteMultiSelect}
                                    options={
                                      props.stream_platform_list
                                        ? props.stream_platform_list
                                        : []
                                    }
                                    onChange={(_, value) => {
                                      setFieldValue(
                                        `per_store_share_policy.${index}.store_id`,
                                        value.platform_id
                                      );
                                      setFieldValue(
                                        `per_store_share_policy.${index}.share_percentage
                                        `,
                                        value.share_percentage
                                      );
                                    }}
                                    isOptionEqualToValue={(option, value) =>
                                      option.platform_id === value.platform_id
                                    }
                                    getOptionLabel={(option) =>
                                      option.platform_name
                                    }
                                    textFieldProps={{
                                      variant: "outlined",
                                      placeholder: "Stream Platform",
                                    }}
                                  />
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={3}
                                sm={3}
                                container
                                className="d-flex align-items-center"
                              >
                                <Grid item lg={12} md={12} sm={12}>
                                  Client Share Percentage :
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                  <Field
                                    as={MuiTextField}
                                    variant="outlined"
                                    placeholder="Client Share Percentage"
                                    id="share_percentage"
                                    size="small"
                                    name={`per_store_share_policy.${index}.share_percentage`}
                                    inputMode="numeric"
                                    handleKeyPress={handlePhoneKeyPress}
                                  />
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                xl={1}
                                lg={1}
                                md={1}
                                sm={1}
                                container
                                // mt={4}
                                className="d-flex justify-content-end"
                                // alignItems="flex-end"
                                style={{ alignItems: "flex-end" }}
                              >
                                {values.per_store_share_policy.length !== 1 && (
                                  <MuiButton
                                    name={<DeleteIcon />}
                                    onClick={() => remove(index)}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          ))}

                        {AddIconFunction(values, push)}
                      </Grid>
                    )}
                  </FieldArray>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                gap={2}
                display="flex"
                justifyContent="flex-end"
                alignItems={"center"}
              >
                <Grid item md={3} sm={12}>
                  {!props.edit ? (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Add"
                      width="80%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Submit"
                      width="80%"
                    />
                  )}
                </Grid>
                <Grid item md={3} sm={12}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="80%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="80%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(ClientRevenueSharePolicyPost);
