// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/pie

import { ResponsiveBar } from "@nivo/bar";
import { theme } from "../../views/App";

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export const NivoBarChart = ({
  legendsDirection,
  legendHide,
  legendPosition,
  onClick,
  tooltip,
  ...props
}) => {
  // Data Format for reference :
  // [
  //  {
  //   "country": "AD",
  //   "hot dog": 147,
  //   "burger": 112,
  //  },
  // ]

  const layout = props.layout ? props.layout : "vertical"; // horizontal | vertical

  const customColors = props.colors
    ? props.colors
    : [
        `${theme.pieChart.color1}`,
        `${theme.pieChart.color2}`,
        `${theme.pieChart.color3}`,
        `${theme.pieChart.color4}`,
        `${theme.pieChart.color5}`,
        `${theme.pieChart.color6}`,
      ];

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";

    // Function to calculate the brightness of a color
    function calculateLuminance(hex) {
      const rgb = parseInt(hex.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;

      return 0.299 * r + 0.587 * g + 0.114 * b;
    }

    do {
      color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
    } while (calculateLuminance(color) < 50); // Adjust the brightness threshold

    return color;
  }

  const CustomTooltip = ({ id, value, color }) => (
    <div
      style={{ padding: 12, color, background: "#222222", cursor: "pointer" }}
    >
      <span>Look, I'm custom :)</span>
      <br />
      <strong>
        {id}: {value}
      </strong>
    </div>
  );

  return (
    <ResponsiveBar
      data={props.chartData}
      keys={props.keys}
      indexBy={props.indexedByAxis}
      layout={layout}
      otherAxis={props.otherAxis}
      groupMode={props.groupMode && props.groupMode} // 'stacked' | 'grouped'
      padding={props.barPadding ? props.barPadding : 0.45}
      colors={props.keys?.map((item, index) =>
        index < customColors.length ? customColors[index] : getRandomColor()
      )}
      enableLabel={props.enableLabel && props.enableLabel}
      enableGridX={props.enableGridX && props.enableGridX} // X Grid Lines
      enableGridY={props.enableGridY && props.enableGridY} // Y Grid Lines
      defs={props.def}
      fill={props.fill}
      // innerPadding={1.5} // Spacing
      // borderRadius={3}
      // borderWidth={1}
      margin={
        props.margin
          ? props.margin
          : { top: 50, right: 230, bottom: 50, left: 100 }
      }
      borderColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={
        props.axisBottom
          ? props.axisBottom
          : {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: props.axisBottomLegend && props.axisBottomLegend,
              legendPosition: "middle",
              legendOffset: 40,
              truncateTickAt: 0,
            }
      }
      axisLeft={
        props.axisLeft
          ? props.axisLeft
          : {
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: props.axisLeftLegend && props.axisLeftLegend,
              legendPosition: "middle",
              legendOffset: -65,
              truncateTickAt: 0,
            }
      }
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "black",
        modifiers: [["darker", 0.6]],
      }}
      legends={
        legendHide
          ? []
          : [
              {
                dataFrom: "keys",
                anchor: legendPosition ? legendPosition : "bottom-right", // center || left || right || top || bottom-right || top-left || bottom-left || top-right
                direction: legendsDirection ? legendsDirection : "column", // column || row
                justify: false,
                translateX: props.translateX ? props.translateX : 120,
                translateY: props.translateY && props.translateY,
                itemsSpacing: 25,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]
      }
      animate={true}
      motionConfig="gentle"
      onClick={onClick}
      onMouseEnter={props.onMouseEnter}
      // Custom made toolTip
      tooltip={
        tooltip
          ? tooltip
          : ({ id, value, color }) => (
              <div
                style={{
                  padding: "4px 10px 4px 10px",
                  color: theme.palette.primary.main,
                  background: "white",
                  display: "flex",
                  alignItems: "center",
                  border: `1px solid ${theme.palette.common.grey}`,
                  borderRadius: "4px",
                  boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.6)",
                }}
              >
                <div
                  style={{
                    width: 12,
                    height: 12,
                    marginRight: 8,
                    backgroundColor: color,
                  }}
                ></div>
                {id}:&nbsp;<strong>{value}</strong>
              </div>
            )
      }
    />
  );
};
