import ActionUtility from "../../utilities/ActionUtility";
import AssetsEffect from "./AssetsEffect";

export default class AssetsAction {
  // AssetsAction action with an function declaration
  static REQUEST_ASSETS = "AssetsAction.REQUEST_ASSETS";
  static REQUEST_ASSETS_FINISHED = "AssetsAction.REQUEST_ASSETS_FINISHED";

  static REQUEST_ASSETS_FILTER = "AssetsAction.REQUEST_ASSETS_FILTER";
  static REQUEST_ASSETS_FILTER_FINISHED =
    "AssetsAction.REQUEST_ASSETS_FILTER_FINISHED";

  static REQUEST_PUT_ASSETS = "AssetsAction.REQUEST_PUT_ASSETS";
  static REQUEST_PUT_ASSETS_FINISHED =
    "AssetsAction.REQUEST_PUT_ASSETS_FINISHED";

  static REQUEST_POST_ASSETS = "AssetsAction.REQUEST_POST_ASSETS";
  static REQUEST_POST_ASSETS_FINISHED =
    "AssetsAction.REQUEST_POST_ASSETS_FINISHED";

  static REQUEST_PUT_ASSETS_BULKUPDATE =
    "AssetsAction.REQUEST_PUT_ASSETS_BULKUPDATE";
  static REQUEST_PUT_ASSETS_BULKUPDATE_FINISHED =
    "AssetsAction.REQUEST_PUT_ASSETS_BULKUPDATE_FINISHED";

  static REQUEST_ASSETS_CLEAR = "AssetsAction.REQUEST_ASSETS_CLEAR";
  static REQUEST_ASSETS_CLEAR_FINISHED =
    "AssetsAction.REQUEST_ASSETS_CLEAR_FINISHED";

  static REQUEST_ASSETS_FILTER_CLEAR =
    "AssetsAction.REQUEST_ASSETS_FILTER_CLEAR";
  static REQUEST_ASSETS_FILTER_CLEAR_FINISHED =
    "AssetsAction.REQUEST_ASSETS_FILTER_CLEAR_FINISHED";

  static REQUEST_ASSETS_DELETE = "AssetsAction.REQUEST_ASSETS_DELETE";
  static REQUEST_ASSETS_DELETE_FINISHED =
    "AssetsAction.REQUEST_ASSETS_DELETE_FINISHED";

  // METHODS
  // Organization GET function
  static requestAssets(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_ASSETS,
        AssetsEffect.requestAssets,
        params
      );
    };
  }

  static requestAssetsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_ASSETS_CLEAR,
        AssetsEffect.requestAssetsClear
      );
    };
  }

  static requestAssetsFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_ASSETS_FILTER_CLEAR,
        AssetsEffect.requestAssetsFilterClear
      );
    };
  }

  static requestAssetsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_ASSETS_FILTER,
        AssetsEffect.requestAssetsFilter,
        params
      );
    };
  }
  static requestPutAssets(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_PUT_ASSETS,
        AssetsEffect.requestPutAssets,
        data,
        id,
        params
      );
    };
  }

  // Organizationfunction
  static requestPostAssets(data, callBack, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_POST_ASSETS,
        AssetsEffect.requestPostAssets,
        data,
        callBack,
        params
      );
    };
  }

  static requestPutAssetsBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_PUT_ASSETS_BULKUPDATE,
        AssetsEffect.requestPutAssetsBulkUpdate,
        data
      );
    };
  }

  static requestAssetsDelete(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        AssetsAction.REQUEST_ASSETS_DELETE,
        AssetsEffect.requestAssetsDelete,
        id,
        params,
        callBack
      );
    };
  }
}
