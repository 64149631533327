import { BaseModel } from "sjs-base-model";

export default class A3LabelRevenueReportGetModel extends BaseModel {
  label_wise_revenue = [];

  constructor(data1) {
    super();
    this.update(data1);
  }
}
