import { Box, Button, Grid, ListItem, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import ReleasesShadowTableAction from "../../stores/releasesShadowTable/ReleasesShadowTableAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const ReleaseSubmissionHistory = (props) => {
  const { isRequesting } = props;

  console.log("releaseData", props.releaseData);

  let columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },
    {
      width: 80,
      flex: 1,
      field: "message",
      headerName: "Message",
      width: 250,
    },

    {
      width: 130,
      flex: 1,
      hide: true,
      field: "messaged_by",
      headerName: "Message By",
      renderCell: (params) => {
        return <span>{params.row.messaged_by?.user_name}</span>;
      },
    },
    {
      width: 130,
      flex: 1,
      field: "messaged_on",
      headerName: "Messaged On",
    },

    {
      width: 130,
      flex: 1,
      field: "read",
      headerName: "Read Status",
      hide: true,
    },
  ];

  function returnLocalTime(time) {
    let timeConversion = new Date(time);
    timeConversion =
      timeConversion.toDateString() +
      ", " +
      timeConversion.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

    return timeConversion;
  }

  const rows = [];

  props.releaseData &&
    props.releaseData?._source?.submission_history?.length > 0 &&
    props.releaseData._source.submission_history.map((feedBack, index) => {
      rows.push({
        id: index,
        sl_no: ++index,
        message: feedBack.message,
        messaged_on: feedBack.messaged_on
          ? returnLocalTime(feedBack.messaged_on)
          : "",
        messaged_by: feedBack.messaged_by,
        read: feedBack.read,
        fields: feedBack.fields,
        page: feedBack.page,
      });
    });

  return (
    <Box>
      <Grid item container alignItems={"center"} justifyContent="center">
        <Grid container item lg={12} md={12} sm={12}>
          <Typography component={"span"}>
            {formatName(`${rows?.length}`)} Results found
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12}>
          <LoadingIndicator isActive={isRequesting}>
            <DataGridFunction
              columns={columns ? columns : []}
              rows={rows ? rows : []}
              height={500}
              rowHeight={60}
            />
          </LoadingIndicator>
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(ReleaseSubmissionHistory);
