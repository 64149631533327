import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  IconButton,
  Button,
  ListItem,
  RadioGroup,
  Radio,
  createFilterOptions,
  InputAdornment,
} from "@mui/material";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ErrorMessage,
  FastField,
  Field,
  FieldArray,
  Form,
  Formik,
  getIn,
  useFormik,
  useFormikContext,
} from "formik";
import { useLocation, useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import environment from "environment";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import {
  inputProps,
  menuProps,
  SelectFieldObject,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { theme } from "../App";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiButton from "../../components/muiButton/MuiButton";
import CoverPicUploadDialog from "../../components/releaseFormComponents/releaseInfo/CoverPicUploadDialog";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import GenreAction from "../../stores/genre/GenreAction";
import SubGenreAction from "../../stores/subgenre/SubGenreAction";
import ArtistAction from "../../stores/artist/ArtistAction";
import LabelAction from "../../stores/label/LabelAction";
import StreamPlatformAction from "../../stores/streamPlatform/StreamPlatformAction";
import StatusAction from "../../stores/status/StatusAction";
import LanguageAction from "../../stores/language/LanguageAction";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import BreadCrumbs from "./BreadCrumbs";
import PlanAction from "../../stores/plan/PlanAction";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import MuiDatePicker from "../../components/datePicker/MuiDatePicker";
import MuiDateField from "../../components/dateField/DateField";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CircularLoadingIndicator from "../../components/loading-indicator/CircularLoadingIndicator";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CircularProgress from "@mui/material/CircularProgress";
import InfoIcon from "@mui/icons-material/Info";
import UploadPopper from "../../components/poppers/UploadPopper";
import Tooltip from "@mui/material/Tooltip";

import {
  faApple,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import AutocompleteDialoge from "../../components/autoCompleteComponent/AutocompleteDialoge";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import LockIcon from "@mui/icons-material/Lock";
import Visibility from "@mui/icons-material/Visibility";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    GenreAction.REQUEST_GENRE_FILTER,
    LabelAction.REQUEST_LABELS_FILTER,
    SubGenreAction.REQUEST_SUBGENRE_FILTER,
    // ArtistAction.REQUEST_ARTIST_FILTER,
    StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
    LanguageAction.REQUEST_LANGUAGE,
    PlanAction.REQUEST_PLAN,
  ]),
  releaseById: state.releaseById.releaseById || {},
  genreFilter: state.genreFilter.genreFilter || [],
  labelFilter: state.labelFilter.labelFilter || [],
  subgenreFilter: state.subgenreFilter.subgenreFilter || [],
  artistFilter: state.artistFilter.artistFilter || [],
  stream_platform_filter:
    state.stream_platform_filter.stream_platform_filter || [],
  statusFilter: state.statusFilter.statusFilter || [],
  language: state.language.language || [],
  plan: state.plan.plan || [],
});

const ReleaseInformation = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const formRef = useRef(null);
  const boxRef = useRef(null);

  const [submissionRedirect, setSubmissionRedirect] = useState(false);

  const formik = useFormikContext();
  //const formik = useFormik();

  function fetchResource(resourceId) {
    const params = {
      current_page: "releaseInfo",
    };
    props.dispatch(ReleasesAction.requestReleaseById(resourceId, params));
  }

  const filter = createFilterOptions();

  function fetchGenreFilter(param) {
    props.dispatch(GenreAction.requestGenreFilter(param));
  }

  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }

  function fetchSubgenreFilter(param) {
    props.dispatch(SubGenreAction.requestSubgenreFilter(param));
  }

  function fetchartistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function fetchPlatformsFilter(param) {
    props.dispatch(StreamPlatformAction.requestPlatformFilter(param));
  }

  function fetchStatusFilter(param) {
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  function fetchlanguageFilter(param) {
    props.dispatch(LanguageAction.requestLanguageFilter(param));
  }

  function fetchPlan(params) {
    props.dispatch(PlanAction.requestPlan(params));
  }

  let AskToGenerateUPCEAN = ["Yes", "No"];
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "releaseInfo",
    };

    let id = localStorage.getItem("id");

    // let filter = [`["status.keyword","must","","term","activated"]`];

    const label_param = {
      // filters: `[${filter}]`,
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "releaseInfo",
    };

    fetchGenreFilter(param);
    fetchLabelFilter(label_param);
    fetchSubgenreFilter(param);
    fetchartistFilter(param);
    fetchPlatformsFilter(param);
    fetchResource(id);
    fetchStatusFilter(param);
    fetchPlan(param);
    // handleChange(null, "1");
    fetchlanguageFilter(param);
  }, [localStorage.getItem("id")]);

  let formProps = {};
  const { _id, _source } = props?.releaseById;

  if (props?.releaseById) {
    Object.assign(
      formProps,
      { id: _id },
      {
        ask_to_generate_upc:
          _source?.ask_to_generate_upc !== null
            ? _source?.ask_to_generate_upc
              ? "Yes"
              : "No"
            : "Yes",
      },
      { thumbnail_url: _source?.thumbnail_url },
      { title: _source?.title },
      { artists: _source && _source.artists ? _source.artists : [""] },
      {
        featuring_artists:
          _source && _source.featuring_artists
            ? _source.featuring_artists
            : [""],
      },
      { genre_type: _source?.genre_type },
      { copyrighted_to: _source?.copyrighted_to },
      { digital_release_date: _source?.digital_release_date },
      { exclusive_release_date: _source?.exclusive_release_date },
      { sub_genre_type: _source?.sub_genre_type },
      { label: _source?.label },
      { main_release_date: _source?.main_release_date },
      {
        plan_id: _source
          ? _source.plan_id
            ? _source.plan_id.id
              ? _source.plan_id.id
              : ""
            : ""
          : "",
      },
      { published_by: _source?.published_by },
      {
        production_year:
          _source && _source.production_year
            ? _source.production_year
            : currentYear,
      },
      { UPC_EAN: _source?.UPC_EAN },
      { format: _source?.format },
      { producer_catalogue_number: _source?.producer_catalogue_number },
      { physical_release_date: _source?.physical_release_date },
      { exclusive_release_date: _source?.exclusive_release_date },
      { status: _source?.status },
      { tracks: _source?.tracks },
      { type_of_release: _source?.type_of_release },
      {
        "music/album":
          _source &&
          _source["music/album"] &&
          _source["music/album"]?.length !== 0
            ? _source["music/album"]
            : [""],
      },
      { meta_language: _source?.meta_language },
      {
        phonographic_copyright_year:
          _source && _source.phonographic_copyright_year
            ? _source.phonographic_copyright_year
            : currentYear,
      },
      {
        copyright_year:
          _source && _source.copyright_year
            ? _source.copyright_year
            : currentYear,
      }
    );
  }

  const [openCoverPicUploader, setOpenCoverPicUploader] = useState(false);

  const releaseTypeList = [
    { id: "audio", name: "audio" },
    { id: "video", name: "video" },
  ];

  let formatArray = ["single", "EP", "album"];

  const [highImage, sethighImage] = useState("");
  const [lowImage, setLowImage] = useState("");

  // Status IDs that have permission to edit
  function idsWithEditPermission() {
    return ["1", "4", "10", "9"].includes(_source?.status?.id);
  }

  useEffect(() => {
    if (!props.isRequesting) {
      setLowImage(() => {
        !props.isRequesting &&
          formProps &&
          formProps.thumbnail_url &&
          RetrieveLowImageUrl(formProps.thumbnail_url);
      });
    }
  }, [!props.isRequesting]);

  const startYear = 0;
  const yearArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => startYear + index
  );

  let initial = {};

  let artistArray = [];
  formProps && formProps.artists && formProps.artists.length === 0
    ? artistArray.push("")
    : formProps?.artists?.map((item) => {
        artistArray.push({
          artist_id: item?.artist_id?.id,
          artist_name: item?.artist_id?.artist_name,
          stream_platforms: item?.artist_id?.stream_platforms,
        });
      });

  // artist list for primaray and featuring artist dropdowns
  let artistList = [];
  if (props.artistFilter.hits) {
    props.artistFilter.hits.hits.forEach((value, index) => {
      artistList.push({
        key: index,
        artist_id: value._id,
        artist_name: value._source.artist_name,
        stream_platforms: value._source.stream_platforms,
      });
    });
  }

  const [selectedgenre, setSelectedGenre] = useState(null);
  let genreList = [];
  if (props.genreFilter.hits) {
    props.genreFilter.hits.hits.forEach((value) => {
      genreList.push({
        id: value._id,
        name: value._source.genre_name,
      });
    });
  }

  let subgenrelist = [];

  if (props.subgenreFilter.hits) {
    props.subgenreFilter.hits?.hits
      ?.filter((item) => item?._source?.genre?.id === parseInt(selectedgenre))
      .map((item) => {
        return subgenrelist.push({
          id: item._id,
          name: item._source?.subgenre_name,
        });
      });
  }

  let labelList = [];
  if (props.labelFilter.hits) {
    props.labelFilter.hits.hits.forEach((value) => {
      labelList.push({
        id: value._id,
        name: value._source.label_name,
      });
    });
  }

  let nameslist = [];

  if (
    props.language &&
    typeof props.language.list_of_language_data === "object"
  ) {
    for (let key in props.language.list_of_language_data) {
      const item = props.language.list_of_language_data[key];
      // const name = item.name;
      nameslist.push({ id: key, name: item.name });
    }
  }

  let featuringArtistArray = [];
  formProps &&
  formProps.featuring_artists &&
  formProps.featuring_artists.length === 0
    ? featuringArtistArray.push("")
    : formProps?.featuring_artists?.map((item) => {
        featuringArtistArray.push({
          artist_id: item?.artist_id?.id,
          artist_name: item?.artist_id?.artist_name,
          stream_platforms: item?.artist_id?.stream_platforms,
        });
      });

  let allPlatforms = [];
  if (props.stream_platform_filter.hits) {
    props.stream_platform_filter.hits.hits.forEach((value) => {
      allPlatforms.push({
        stream_platform_id: value._id,
        stream_platform_name: value._source.stream_platform_name,
      });
    });
  }

  if (formProps) {
    initial = {
      thumbnail_url: formProps.thumbnail_url,
      title: formProps.title,
      "music/album": formProps["music/album"],
      artists: artistArray,
      featuring_artists: featuringArtistArray,
      genre_type:
        formProps && formProps.genre_type
          ? {
              name: formProps.genre_type?.genre_name ?? "",
              id: formProps.genre_type?.id ?? "",
            }
          : null,
      copyrighted_to: formProps.copyrighted_to,
      various_artist: false,
      sub_genre_type:
        formProps && formProps.genre_type
          ? {
              name: formProps.sub_genre_type?.subgenre_name ?? "",
              id: formProps.sub_genre_type?.id ?? "",
            }
          : null,
      label: formProps.label?.id ?? "",
      plan_id: formProps.plan_id,
      published_by: formProps.published_by,
      physical_release_date: formProps.physical_release_date,
      production_year: formProps.production_year ?? "",
      UPC_EAN: formProps.UPC_EAN,
      ask_to_generate_upc: formProps.ask_to_generate_upc,
      format: formProps.format ?? "",
      producer_catalogue_number: formProps.producer_catalogue_number,
      status: formProps.status?.id ?? "",
      type_of_release: formProps.type_of_release,
      copyright_year: formProps.copyright_year ?? "",
      phonographic_copyright_year: formProps.phonographic_copyright_year ?? "",
      meta_language: formProps.meta_language?.meta_language ?? "",
    };
  } else {
    initial = {
      title: "",
      "music/album": [""],
      artists: [""],
      featuring_artists: [],
      genre_type: null,
      copyrighted_to: "",
      various_artist: false,
      sub_genre_type: null,
      label: "",
      plan_id: "",
      status: "",
      published_by: "",
      physical_release_date: "",
      production_year: new Date().getFullYear(),
      UPC_EAN: "",
      ask_to_generate_upc: "Yes",
      format: "",
      producer_catalogue_number: "",
      thumbnail_url: "",
      type_of_release: "",
      copyright_year: `${new Date().getFullYear()}`,
      phonographic_copyright_year: `${new Date().getFullYear()}`,
      meta_language: "",
    };
  }

  let planList = [];
  if (props.plan && props.plan.hits) {
    props.plan.hits.hits &&
      props.plan.hits.hits.length > 0 &&
      props.plan.hits.hits.map((plan) => {
        planList.push({
          id: plan._id,
          name: plan._source.plan_name,
        });
      });

    if (initial.format == "single")
      planList = planList.filter((plan) => /T1|T2|T3/.test(plan.name));
  }

  const handleSubmit = () => {
    if (formRef?.current) {
      formRef.current.handleSubmit();
    }
  };

  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const key = String.fromCharCode(keyCode);
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
    if (!alphanumericRegex.test(key)) {
      event.preventDefault();
    }
  };

  const handleNumericKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const key = String.fromCharCode(keyCode);
    const numericRegex = /^[0-9]*$/; // Only allow numbers
    if (!numericRegex.test(key)) {
      event.preventDefault(); // Prevent non-numeric input
    }
  };

  useEffect(() => {
    const shouldSubmit = location.state?.shouldSubmit;

    if (shouldSubmit) {
      setSubmissionRedirect(true);
      handleSubmit();
    }
  }, [!props.isRequesting && location.state?.shouldSubmit]);

  const updateShouldSubmitInState = (newValue) => {
    // Check if the History API is available
    if (window.history && window.history.replaceState) {
      // Get the current state from the history object
      const currentState = window.history.state || {};

      // Create a new state object with the updated 'shouldSubmit' value
      const newState = {
        ...currentState,
        usr: {
          ...currentState.usr,
          shouldSubmit: newValue,
        },
      };

      // Replace the current state with the updated state
      window.history.replaceState(newState, "");

      // Optional: You can also update the 'location.state' directly if needed
      location.state = newState;
    }
  };

  function Required() {
    return (
      <>
        <span style={{ color: "red" }}>Required</span>{" "}
        <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  function RequiredText() {
    return (
      <p
        style={{
          color: "red",
          fontSize: "14px",
          marginTop: "-8px",
          marginLeft: "-10px",
        }}
      >
        <span>Required</span> <span style={{ color: "red" }}>*</span>
      </p>
    );
  }
  const [loading, setLoading] = useState(false);
  const [lowImageLoader, setlowImageLoader] = useState(false);
  const [highImageLoader, sethighImageLoader] = useState(false);

  const handleFileUpload = (value, setFieldValue, event) => {
    const MAX_FILE_SIZE_MB = 5;
    let formData = new FormData();

    const fileSizeMB = event.target?.files[0]?.size / (1024 * 1024); // Convert bytes to MB

    const image = new Image();
    const reader = new FileReader();

    reader.onloadend = function () {
      image.src = reader.result;

      image.onload = function () {
        const width = this.width;
        const height = this.height;

        if (fileSizeMB <= MAX_FILE_SIZE_MB) {
          if (width === 3000 && height === 3000) {
            sethighImageLoader(true);

            fetch(
              `${environment.api.fileUpload}/add?current_page=releaseInfo&release_id=${formProps.id}&thumbnail=true`,
              {
                method: "POST",
                headers: {
                  Authorization: "Bearer " + access_tocken,
                },
                body: formData,
              }
            )
              .then((res) => res.json())
              .then((response) => {
                if (response?.status === "success") {
                  setFieldValue("thumbnail_url", response.url);
                  RetrieveHighImageUrl(response.url);
                } else {
                  sethighImageLoader(false);
                }
              });
          } else {
            // Image doesn't meet the required dimensions, handle the error.
            alert("Please select a 3000x3000 pixel image.");
          }
        } else {
          // Image doesn't meet the required dimensions, handle the error.
          alert(`Please select a file less than ${MAX_FILE_SIZE_MB}MB.`);
        }
      };
    };

    if (value) {
      reader.readAsDataURL(value);
    }

    formData.append("file", value);
    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
  };

  function RetrieveLowImageUrl(thumbnail_url) {
    setlowImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    // Low Quality Image

    let lowQualityImage = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=releaseInfo&path=thumbnail/${thumbnail_url}&release_id=${formProps.id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setlowImageLoader(false);
        lowQualityImage = URL.createObjectURL(blob);

        setLowImage(lowQualityImage);
      });
  }

  // Hight Quality Image
  function RetrieveHighImageUrl(thumbnail_url) {
    sethighImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let highQualityImage = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=releaseInfo&path=${thumbnail_url}&release_id=${formProps.id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        sethighImageLoader(false);
        highQualityImage = URL.createObjectURL(blob);
        sethighImage(highQualityImage);
        // let id = localStorage.getItem("id");
        // fetchResource(id);
      });
  }

  useEffect(() => {
    if (openCoverPicUploader) {
      sethighImage(() => {
        !props.isRequesting &&
          formProps &&
          formProps.thumbnail_url &&
          RetrieveHighImageUrl(formProps.thumbnail_url);
      });
    }
  }, [openCoverPicUploader]);

  // if (openCoverPicUploader) {
  //   RetrieveHighImageUrl(formProps.thumbnail_url);
  // }

  const [dltLoading, setdltLoading] = useState(false);

  function SaveThumbnailUrl(values) {
    if (formProps?.id) {
      let obj = {};

      let artistIds = [];

      values &&
        values.artists &&
        values.artists[0] !== null &&
        values?.artists?.map((item) => {
          item.artist_id &&
            artistIds.push({
              artist_id: item.artist_id,
            });
        });

      let featuring_artist_ids = [];

      values &&
        values.featuring_artists &&
        values.featuring_artists[0] !== null &&
        values?.featuring_artists?.map((item) => {
          item.artist_id &&
            featuring_artist_ids.push({
              artist_id: item.artist_id,
            });
        });

      Object.assign(
        obj,
        values.title ? { title: values.title } : { title: "" },
        values.copyrighted_to
          ? {
              copyrighted_to: values.copyrighted_to,
            }
          : {
              copyrighted_to: "",
            },
        values.format && {
          format: values.format,
        },
        values.genre_type
          ? {
              genre_type: values.genre_type.id,
            }
          : {
              genre_type: null,
            },
        values.label && {
          label: values.label,
        },

        values.status && {
          status: values.status,
        },

        values.sub_genre_type
          ? {
              sub_genre_type: values.sub_genre_type.id,
            }
          : {
              sub_genre_type: null,
            },
        values.production_year
          ? {
              production_year: values.production_year,
            }
          : {
              production_year: null,
            },
        values.published_by
          ? {
              published_by: values.published_by,
            }
          : {
              published_by: null,
            },
        values.producer_catalogue_number
          ? {
              producer_catalogue_number: values.producer_catalogue_number,
            }
          : {
              producer_catalogue_number: null,
            },
        values.physical_release_date
          ? {
              physical_release_date: values.physical_release_date,
            }
          : {
              physical_release_date: null,
            },
        artistIds?.length !== 0
          ? {
              artists: artistIds,
            }
          : {
              artists: [],
            },
        featuring_artist_ids?.length !== 0
          ? {
              featuring_artists: featuring_artist_ids,
            }
          : {
              featuring_artists: [],
            },
        values.thumbnail_url
          ? {
              thumbnail_url: values.thumbnail_url,
            }
          : {
              thumbnail_url: null,
            },
        values.ask_to_generate_upc && {
          ask_to_generate_upc:
            values.ask_to_generate_upc === "No" ? false : true,
        },
        values.UPC_EAN
          ? {
              UPC_EAN:
                values.ask_to_generate_upc === "Yes" ? "" : values.UPC_EAN,
            }
          : {
              UPC_EAN: null,
            },
        values.plan_id
          ? {
              plan_id: values.plan_id,
            }
          : {
              plan_id: null,
            },
        values.type_of_release && {
          type_of_release: values.type_of_release,
        },
        values["music/album"] &&
          Array.isArray(values["music/album"]) &&
          values["music/album"].length === 1 &&
          values["music/album"][0] === ""
          ? {
              ["music/album"]: null,
            }
          : {
              ["music/album"]: values["music/album"],
            },

        values.meta_language && {
          meta_language: values.meta_language,
        },

        values.phonographic_copyright_year
          ? {
              phonographic_copyright_year:
                values.phonographic_copyright_year.toString(),
            }
          : {
              phonographic_copyright_year: null,
            },
        values.copyright_year
          ? {
              copyright_year: values.copyright_year.toString(),
            }
          : {
              copyright_year: null,
            }
      );

      let params = {
        current_page: "releaseInfo",
      };
      props
        .dispatch(ReleasesAction.requestPutRelease(obj, formProps.id, params))
        .then(() => {
          let id = localStorage.getItem("id");
          fetchResource(id);
        });
      // .then(() => {
      //   props.dispatch(ReleasesAction.requestReleaseById(formProps.id));
      // });
    }
  }

  function DeleteThumbnailUrl(values) {
    setdltLoading(true);
    if (formProps?.id) {
      let obj = {};

      Object.assign(obj, {
        thumbnail_url: null,
      });

      let params = {
        current_page: "releaseInfo",
      };
      props
        .dispatch(ReleasesAction.requestPutRelease(obj, formProps.id, params))
        .then(() => {
          let id = localStorage.getItem("id");
          fetchResource(id);
          setOpenCoverPicUploader(false);
          setdltLoading(false);
        });
      // .then(() => {
      //   props.dispatch(ReleasesAction.requestReleaseById(formProps.id));
      // });
    }
  }

  const inputRef = useRef("");
  const artistFieldRef = useRef("");
  const primaryArtistFieldRef = useRef(null);
  const featuringArtistFieldRef = useRef(null);

  const CustomOption = ({ option }) => (
    <Box
      sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
    >
      <Typography component={"p"}>
        {/* {option.artist_name} */}
        {option.hasOwnProperty("artist_name") ? option.artist_name : ""}
      </Typography>
      <Typography component={"div"} className="d-flex">
        {option.stream_platforms?.map((item) => (
          <>
            <Typography
              sx={{
                textDecoration: "none",
                color: theme.palette.primary.main,
                marginX: 1,
              }}
            >
              {item.stream_platform_artist_id &&
              item.stream_platform_id === "1" ? (
                <FontAwesomeIcon icon={faSpotify}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "2" ? (
                <FontAwesomeIcon icon={faApple}></FontAwesomeIcon>
              ) : item.stream_platform_artist_id &&
                item.stream_platform_id === "3" ? (
                <FontAwesomeIcon icon={faYoutube}></FontAwesomeIcon>
              ) : null}
            </Typography>
          </>
        ))}
      </Typography>
    </Box>
  );

  const ArtistSelectDailoge = forwardRef(
    (
      {
        name,
        label,
        placeholder,
        options,
        values,
        touched,
        errors,
        setFieldValue,
        required,
        openProp,
        disabled,
        title,
      },
      ref
    ) => {
      return (
        <FieldArray name={name}>
          {({ insert, remove, push }) => (
            <Box sx={{ width: "100%" }}>
              {Array.isArray(values[name]) &&
                values[name].map((item, index) => (
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    container
                    gap={3}
                    justifyContent="center"
                    //alignItems={"flex-start"}
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      textAlign="right"
                      className="form_label"
                      style={{ direction: "ltr" }}
                      mt={1.5}
                    >
                      {index === 0 && (
                        <Typography>
                          {label} {required && "*"}
                        </Typography>
                      )}
                    </Grid>

                    <Grid item lg={8} md={8} sm={8} my={0.5}>
                      <Grid lg={12} md={12} sm={12} item container>
                        <Grid
                          item
                          xs={
                            values[name][index] || values[name][index - 1]
                              ? 11
                              : 12
                          }
                        >
                          <Tooltip title={title} arrow placement="right-end">
                            <Field
                              width="100%"
                              style={{
                                sx: "100%",
                              }}
                              disabled={disabled}
                              // name={name}
                              name={`${name}[${index}]`}
                              size="small"
                              component={AutocompleteDialoge}
                              innerRef={ref}
                              remove={() => {
                                values[name].length !== 1 && remove(index);
                              }}
                              fetchartistFilter={fetchartistFilter}
                              values={values}
                              options={options ? options : []}
                              textFieldProps={{
                                variant: "outlined",
                                placeholder: placeholder,
                              }}
                              renderOption={(props, option) => {
                                if (option && option.artist_name) {
                                  return (
                                    <li {...props} key={item.artist_id}>
                                      <CustomOption option={option} />
                                    </li>
                                  );
                                }
                              }}
                              filterOptions={(options, { inputValue }) => {
                                ref.current = inputValue;
                                let minCharsToShowDropdown = 2;
                                let filteredOptions;

                                if (
                                  inputValue.length < minCharsToShowDropdown
                                ) {
                                  return []; // If less than minCharsToShowDropdown, hide the dropdown
                                } else {
                                  filteredOptions = options.filter(
                                    (option) =>
                                      option.artist_name &&
                                      option.artist_name
                                        .toLowerCase()
                                        .includes(
                                          inputValue && inputValue.toLowerCase()
                                        )
                                  );

                                  filteredOptions = filteredOptions.sort(
                                    (a, b) =>
                                      a.artist_name.localeCompare(b.artist_name)
                                  );

                                  filteredOptions = [
                                    ...filteredOptions,
                                    {
                                      artist_name: `Add "${inputValue}"`,
                                    },
                                  ];
                                }

                                return filteredOptions;
                              }}
                              sx={{
                                width: "-webkit-fill-available",
                              }}
                              // groupBy={(group) => group.artist_name}
                              getOptionLabel={(option) => {
                                return option && option.artist_name;
                              }}
                              value={values[name][index]}
                              isOptionEqualToValue={(option, value) => {
                                return option.artist_name === value.artist_name;
                              }}
                              error={
                                Boolean(errors[name]) && Boolean(touched[name])
                              }
                              helperText={
                                Boolean(touched[name]) && errors[name]
                              }
                            />
                          </Tooltip>
                        </Grid>
                        <Grid item lg={1} md={1} sm={1}>
                          {(values[name][index] || values[name][index - 1]) && (
                            <IconButton
                              onClick={() => {
                                remove(index);
                                values[name].length === 1 && push("");
                              }}
                            >
                              <DeleteIcon color="primary" />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {values[name] &&
                values[name][values[name].length - 1] &&
                values[name][values[name] && values[name].length - 1].length !==
                  0 && (
                  <Grid
                    container
                    lg={11}
                    md={11}
                    sm={11}
                    mt={1}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    px={1}
                  >
                    <IconButton onClick={() => push("")}>
                      <AddCircleIcon color="primary" />
                    </IconButton>
                  </Grid>
                )}
            </Box>
          )}
        </FieldArray>
      );
    }
  );

  function ReturnDisableReleaseType(releaseData) {
    if (releaseData?.type_of_release !== null) return true;
    if (
      releaseData?.status?.id === "1" ||
      releaseData?.status?.id === "10" ||
      releaseData?.status?.id === "4" ||
      releaseData?.status?.id === "9"
    ) {
      return false;
    } else {
      return true;
    }
  }

  const returnDateAndTime = (recievedDate) => {
    const inputDate = new Date(recievedDate);

    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1; // Months are zero-based, so add 1
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    // Convert hours to 12-hour format and determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    // Format the date and time
    const formattedDate = `${day}-${month}-${year} at ${formattedHours}:${minutes} ${ampm}`;

    return formattedDate;
  };

  return (
    <Box //p={1}
      ref={boxRef}
      paddingLeft={2}
      paddingRight={2}
      paddingBottom={2}
    >
      <LoadingIndicator isActive={props.isRequesting}>
        {!props.isRequesting && (
          <Formik
            initialValues={initial}
            validate={(values) => {
              function isAlphanumericWithBoth(values) {
                const containsLetters = /[a-zA-Z]/.test(values);
                const containsNumbers = /[0-9]/.test(values);
                const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

                if (
                  containsLetters &&
                  containsNumbers &&
                  !containsSpecialChars
                ) {
                  // The string contains both letters and numbers (alphanumeric) and no special characters.
                  return true;
                } else {
                  // The string does not meet the criteria.
                  return false;
                }
              }
              var errors = {};
              var priceErr = {};
              if (!values.title) {
                errors.title = <RequiredText />;
              }
              if (!values.format) {
                errors.format = <Required />;
              }
              if (!values.label) {
                errors.label = <Required />;
              }

              if (!values.producer_catalogue_number) {
                errors.producer_catalogue_number = <RequiredText />;
              }

              if (values.producer_catalogue_number) {
                // errors.producer_catalogue_number = <Required />;

                if (!isAlphanumericWithBoth(values.producer_catalogue_number)) {
                  errors.producer_catalogue_number =
                    "producer catalogue number should only contain alphanumeric characters";
                }
              }
              if (!values.genre_type) {
                errors.genre_type = <RequiredText />;
              }
              if (!values.sub_genre_type) {
                errors.sub_genre_type = <RequiredText />;
              }
              if (!values.physical_release_date) {
                errors.physical_release_date = <Required />;
              }
              if (!values.plan_id) {
                errors.plan_id = <Required />;
                priceErr.plan_id = <Required />;
              }
              if (!values.copyright_year) {
                errors.copyright_year = <Required />;
              }
              if (!values.production_year) {
                errors.production_year = <Required />;
              }
              // if (values.artists.length == 0) {
              //   errors.artists = <Required />;
              // }

              if (values.artists) {
                let trueValue = values.artists.some((item) => item !== "");
                !trueValue && (errors.artists = []);
                !trueValue && (errors.artists[0] = <RequiredText />);
              }

              if (!values.thumbnail_url) {
                errors.thumbnail_url = <Required />;
              }

              if (!values.copyrighted_to) {
                errors.copyrighted_to = <RequiredText />;
              }

              if (!values.published_by) {
                errors.published_by = <RequiredText />;
              }

              if (!values.phonographic_copyright_year) {
                errors.phonographic_copyright_year = <Required />;
              }

              if (!values.type_of_release) {
                errors.type_of_release = <Required />;
              }

              if (!values.meta_language) {
                errors.meta_language = <Required />;
              }

              if (values.ask_to_generate_upc === "No") {
                if (!values.UPC_EAN) {
                  errors.UPC_EAN = <Required />;
                }
              }

              return location.state?.shouldSubmit && location.state.priceError
                ? priceErr
                : location.state?.shouldSubmit && errors;
            }}
            enableReinitialize={true}
            validateOnBlur={true}
            validateOnChange
            onSubmit={(values) => {
              setLoading(true);
              let artistIds = [];

              values &&
                values.artists &&
                values.artists[0] !== null &&
                values?.artists?.map((item) => {
                  item.artist_id &&
                    artistIds.push({
                      artist_id: item.artist_id,
                    });
                });

              let featuring_artist_ids = [];

              values &&
                values.featuring_artists &&
                values.featuring_artists[0] !== null &&
                values?.featuring_artists?.map((item) => {
                  item.artist_id &&
                    featuring_artist_ids.push({
                      artist_id: item.artist_id,
                    });
                });

              let obj = {};

              const returnFormattedDate = (dateString) => {
                const date = new Date(dateString);

                // Get the date components
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it's zero-based
                const day = String(date.getDate()).padStart(2, "0");

                // Create the formatted date string
                const formattedDate = `${year}-${month}-${day}`;

                return formattedDate;
              };

              Object.assign(
                obj,
                values.title ? { title: values.title.trim() } : { title: "" },
                values.copyrighted_to
                  ? {
                      copyrighted_to: values.copyrighted_to.trim(),
                    }
                  : {
                      copyrighted_to: "",
                    },
                values.format && {
                  format: values.format,
                },
                values.genre_type
                  ? {
                      genre_type: values.genre_type.id,
                    }
                  : {
                      genre_type: null,
                    },
                values.label && {
                  label: values.label,
                },

                values.status && {
                  status: values.status,
                },

                values.sub_genre_type
                  ? {
                      sub_genre_type: values.sub_genre_type.id,
                    }
                  : {
                      sub_genre_type: null,
                    },
                values.production_year
                  ? {
                      production_year: values.production_year,
                    }
                  : {
                      production_year: currentYear,
                    },
                values.published_by
                  ? {
                      published_by: values.published_by.trim(),
                    }
                  : {
                      published_by: null,
                    },
                values.producer_catalogue_number
                  ? {
                      producer_catalogue_number:
                        values.producer_catalogue_number.trim(),
                    }
                  : {
                      producer_catalogue_number: null,
                    },
                values.physical_release_date
                  ? {
                      physical_release_date: returnFormattedDate(
                        values.physical_release_date
                      ),
                    }
                  : {
                      physical_release_date: null,
                    },
                artistIds?.length !== 0
                  ? {
                      artists: artistIds,
                    }
                  : {
                      artists: [],
                    },
                featuring_artist_ids?.length !== 0
                  ? {
                      featuring_artists: featuring_artist_ids,
                    }
                  : {
                      featuring_artists: [],
                    },
                values.thumbnail_url
                  ? {
                      thumbnail_url: values.thumbnail_url,
                    }
                  : {
                      thumbnail_url: null,
                    },
                values.ask_to_generate_upc && {
                  ask_to_generate_upc:
                    values.ask_to_generate_upc === "No" ? false : true,
                },
                values.UPC_EAN
                  ? {
                      UPC_EAN:
                        values.ask_to_generate_upc === "Yes"
                          ? ""
                          : values.UPC_EAN.trim(),
                    }
                  : {
                      UPC_EAN: null,
                    },
                values.plan_id
                  ? {
                      plan_id: values.plan_id,
                    }
                  : {
                      plan_id: null,
                    },
                values.type_of_release && {
                  type_of_release: values.type_of_release,
                },
                values["music/album"] &&
                  Array.isArray(values["music/album"]) &&
                  values["music/album"].length === 1 &&
                  values["music/album"][0] === ""
                  ? {
                      ["music/album"]: null,
                    }
                  : {
                      ["music/album"]: values["music/album"],
                    },

                values.meta_language && {
                  meta_language: values.meta_language,
                },

                values.phonographic_copyright_year
                  ? {
                      phonographic_copyright_year:
                        values.phonographic_copyright_year.toString(),
                    }
                  : {
                      phonographic_copyright_year: currentYear,
                    },
                values.copyright_year
                  ? {
                      copyright_year: values.copyright_year.toString(),
                    }
                  : {
                      copyright_year: currentYear,
                    }
              );

              if (formProps?.id) {
                let params = { current_page: "releaseInfo" };

                let callBack = (res) => {
                  if (res.status === "success") {
                    updateShouldSubmitInState(false);
                    if (submissionRedirect) navigate("/submission");
                    else navigate("/upload");

                    props.dispatch(
                      ReleasesAction.requestReleaseById(formProps.id, params)
                    );
                  }
                  setLoading(false);
                };

                props.dispatch(
                  ReleasesAction.requestPutRelease(
                    obj,
                    formProps.id,
                    params,
                    callBack
                  )
                );
              }
            }}
            innerRef={formRef}
          >
            {({
              errors,
              isValid,
              helperText,
              touched,
              dirty,
              handleChange,
              setFieldValue,
              values,
              submitForm,
            }) => (
              <Form style={{ width: "100%" }}>
                {!props.isRequesting && (
                  <>
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      sm={12}
                      md={12}
                      alignItems="center"
                      justifyContent={"space-between"}
                      style={{
                        position: "sticky",
                        top: `${boxRef?.current?.offsetTop}px`,
                        backgroundColor: theme.palette.background.default,
                        zIndex: 10,
                      }}
                    >
                      <Grid
                        item
                        xl={10}
                        lg={10}
                        md={10}
                        sm={10}
                        container
                        alignItems={"center"}
                        justifyContent="flex-start"
                        gap={2}
                        paddingTop={1}
                      >
                        <Grid item>
                          <h2>
                            {!props.isRequesting &&
                              formatName(
                                `${_source?.title ? _source?.title : ""}`
                              )}
                          </h2>
                        </Grid>

                        <Grid item>
                          <BreadCrumbs pathname={window.location.pathname} />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        sm={12}
                        md={12}
                        xl={12}
                        className="d-flex justify-content-end"
                        sx={{
                          paddingTop: "10px",
                          paddingX: {
                            lg: 20,
                            md: 1,
                            sm: 1,
                          },
                        }}
                      >
                        <Grid item lg={1} md={2} sm={2}>
                          <MuiButton
                            disabled={
                              _source
                                ? _source.status && idsWithEditPermission()
                                  ? false
                                  : true
                                : false
                            }
                            loading={loading}
                            type="submit"
                            name="Save"
                            width="100%"
                            save
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container lg={12} p={1}>
                      <Grid
                        container
                        item
                        lg={12}
                        xl={12}
                        md={12}
                        sm={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <Grid
                          container
                          lg={10}
                          sm={11}
                          md={11}
                          xl={11}
                          sx={{
                            padding: {
                              lg: 5,
                              md: 1,
                              sm: 1,
                            },
                          }}
                        >
                          <Grid item lg={6} xl={6} md={6} sm={6}>
                            <TextFieldWithLabel
                              label="Release Title"
                              name="title"
                              placeholder="YYY Official Music Video"
                              size="small"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Albums require a title"
                            />

                            <br />
                            {/* <TextFieldWithLabel
                              label="Version/Subtitle"
                              name="music/album"
                              placeholder="Version/Subtitle"
                              toolTipText=""
                              errors={errors}
                              touched={touched}
                            /> */}

                            <FieldArrayComponent
                              name="music/album"
                              label={"Version/Subtitle"}
                              values={values}
                              errors={errors}
                              touched={touched}
                              title="Enter Version/Subtitle"
                            />

                            <br />

                            <SelectFieldObject
                              label="Release Type"
                              name="type_of_release"
                              options={releaseTypeList}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={ReturnDisableReleaseType(
                                _source && _source
                              )}
                              title="Enter the Type of Release"
                            />
                            <br />
                            <ArtistSelectDailoge
                              ref={primaryArtistFieldRef}
                              name="artists"
                              artistFieldRef={artistFieldRef}
                              label="Primary Artist"
                              placeholder="Primary Artist"
                              options={artistList ? artistList : []}
                              values={values}
                              touched={touched}
                              errors={errors}
                              required={true}
                              openProp={
                                values && values.artists ? values.artists : []
                              }
                              setFieldValue={setFieldValue}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter a new artist or select an existing catalog artist as an additional main artist or other artist role.Each field should contain only one artist name unless the artists are generally listed together as a group.Spelling should be consistent across all artist's content"
                            />
                            <br />

                            <ArtistSelectDailoge
                              ref={featuringArtistFieldRef}
                              name="featuring_artists"
                              label="Featuring"
                              placeholder="Featuring Artist"
                              options={artistList ? artistList : []}
                              values={values}
                              touched={touched}
                              errors={errors}
                              openProp={
                                values && values.featuring_artists
                                  ? values.featuring_artists
                                  : []
                              }
                              setFieldValue={setFieldValue}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter the Featuring Artist"
                            />
                            <br />

                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={3}
                              marginBottom={3}
                              alignItems={"center"}
                            >
                              <Grid
                                item
                                lg={3}
                                md={3}
                                sm={3}
                                mt={2}
                                className="form_label"
                                style={{ direction: "rtl" }}
                              ></Grid>

                              <Grid item lg={8} md={8} sm={8}>
                                <Typography className="mx-3">
                                  Various Artists/Compilation
                                </Typography>
                              </Grid>
                            </Grid>
                            <AutoCompleteForm
                              label="Genre"
                              name="genre_type"
                              options={genreList}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Choose the product's genre.Storefront genre categorization often occurs at the discretion of the music service and may differ slightly from the chosen genre."
                            />
                            {setSelectedGenre(values.genre_type?.id)}
                            <br />
                            <AutoCompleteForm
                              label="Subgenre"
                              name="sub_genre_type"
                              options={subgenrelist}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              values={values}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter the SubGenreType from the selected Genre Field"
                            />
                            {/* 
                          <SelectFieldObject
                            label="Subgenre"
                            name="sub_genre_type"
                            options={subgenrelist}
                            size="small"
                            placeholder="Select an option"
                            errors={errors}
                            touched={touched}
                            required={true}
                            disabled={
                              _source
                                ? _source.status &&
                                  _source.status.status_name === "draft"
                                  ? false
                                  : true
                                : false
                            }
                          /> */}
                            <br />
                            <SelectFieldObject
                              label="Label Name"
                              name="label"
                              options={labelList}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Search and choose labels associated to user account.Contact label representative to add new labels."
                            />
                            <br />
                            <SelectFieldObject
                              label="Meta Language"
                              name="meta_language"
                              options={nameslist}
                              size="small"
                              required={true}
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Choose the Language in which the metadata on this product is primarily written.If there is more than one language,choose the most prevalent"
                            />
                            <br />
                            <SelectField
                              label="Format"
                              name="format"
                              options={formatArray}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              setFieldValue={setFieldValue}
                              setvalue={
                                planList &&
                                planList.filter((item) => {
                                  return item.id === "1";
                                })
                                  ? planList.filter((item) => {
                                      return item.id === "1";
                                    })[0] &&
                                    planList.filter((item) => {
                                      return item.id === "1";
                                    })[0].id
                                  : ""
                              }
                              disabled={
                                !_source &&
                                (!_source?.status?.status_name === "draft" ||
                                  !_source?.status?.status_name ===
                                    "waiting_for_client")
                                  ? true
                                  : _source?.format
                                  ? true
                                  : false
                              }
                              title={"Enter the format as Single,EP,Album"}
                            />
                            <br />
                            <SelectFieldObject
                              label="Plan"
                              name="plan_id"
                              options={planList}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter Product Price.All proces are wholesale/suggested retail list price(SRLP) in US dollars.The final retail price is set at the discretion of music service and cannot be guaranteed."
                            />
                            <br />
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={3}
                              display="flex"
                              justifyContent="center"
                              alignItems={"center"}
                            >
                              <Grid
                                item
                                lg={3}
                                md={3}
                                sm={3}
                                textAlign="right"
                                className="form_label"
                              >
                                <Typography
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                  }}
                                >
                                  <UploadPopper
                                    format={
                                      "Input the first date a music was officially released."
                                    }
                                  >
                                    <InfoIcon sx={{ fontSize: 15 }} />
                                    Physical/Original Release Date *
                                  </UploadPopper>{" "}
                                </Typography>
                              </Grid>

                              <Grid item lg={8} md={8} sm={8}>
                                {/* <Field
                                component={MuiDateField}
                                size={"small"}
                                disablePast
                                value={
                                  values.physical_release_date
                                    ? values.physical_release_date
                                    : null
                                }
                                name={`physical_release_date`}
                                fullWidth
                                textFieldProps={{
                                  label: false,
                                  margin: "normal",
                                  variant: "outlined",
                                  fullWidth: true,
                                }}
                              />
                              <ErrorMessage name="physical_release_date" /> */}
                                <Tooltip
                                  title="Choose the date on which the earliest format of this product was first made available for consumption.This is often the same as the Release Date and would most often differ when there is a previously released version in another format."
                                  arrow
                                  placement="right-end"
                                >
                                  <div>
                                    <FastField
                                      component={MuiDatePicker}
                                      size="small"
                                      value={
                                        values.physical_release_date
                                          ? values.physical_release_date
                                          : null
                                      }
                                      disabled={
                                        _source
                                          ? _source.status &&
                                            idsWithEditPermission()
                                            ? false
                                            : true
                                          : false
                                      }
                                      disablePast={false}
                                      format="DD/MM/YYYY"
                                      name={`physical_release_date`}
                                      textFieldProps={{
                                        fullWidth: true,
                                        margin: "normal",
                                        variant: "outlined",
                                      }}
                                    />
                                  </div>
                                </Tooltip>
                                &nbsp;
                                <ErrorMessage name="physical_release_date" />
                              </Grid>
                            </Grid>
                            <br />
                            <SelectField
                              label="Production Year"
                              name="production_year"
                              options={yearArray.sort((a, b) => b - a)}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter the production Year"
                            />
                            <br />
                            <SelectField
                              label="Copyright Year"
                              name="copyright_year"
                              options={yearArray.sort((a, b) => b - a)}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter the copyright year"
                            />
                            <br />
                            <SelectField
                              label="Phonographic Copyright Year"
                              name="phonographic_copyright_year"
                              options={yearArray.sort((a, b) => b - a)}
                              size="small"
                              placeholder="Select an option"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter phonographic copyright text.Enter the year date on which the sound recording was produced and the name of the copyright owner of the sound recording"
                            />
                            <br />
                            {/* <TextFieldWithLabel
                            label="UPC/EAN"
                            name="UPC_EAN"
                            placeholder="UPC EAN"
                            errors={errors}
                            touched={touched}
                          /> */}
                            {values.ask_to_generate_upc === "Yes" ? (
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                gap={3}
                                alignItems={"center"}
                                justifyContent="center"
                                display="flex"
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  className="form_label"
                                  style={{ direction: "ltr" }}
                                >
                                  <Typography>UPC/EAN</Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  textAlign="left"
                                >
                                  <Tooltip
                                    title="Choose UPC to have Ingrooves assign a upc on submission of the product"
                                    arrow
                                    placement="right-end"
                                  >
                                    <FastField
                                      as={MuiTextField}
                                      variant="outlined"
                                      placeholder="UPC/EAN"
                                      id={"UPC_EAN"}
                                      fieldheight="30px"
                                      size="small"
                                      name="UPC_EAN"
                                      disabled={true}
                                      className="col-10"
                                    />
                                  </Tooltip>
                                  {/* <ErrorMessage name={name} /> */}
                                </Grid>
                              </Grid>
                            ) : (
                              <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                container
                                gap={3}
                                alignItems={"center"}
                                justifyContent="center"
                                display="flex"
                              >
                                <Grid
                                  item
                                  lg={3}
                                  md={3}
                                  sm={3}
                                  textAlign="right"
                                  className="form_label"
                                  style={{ direction: "ltr" }}
                                >
                                  <Typography>UPC/EAN *</Typography>
                                </Grid>
                                <Grid
                                  item
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  textAlign="left"
                                >
                                  <Tooltip
                                    title=" Specify a value for UPC/EAN "
                                    arrow
                                    placement="right-end"
                                  >
                                    <FastField
                                      as={MuiTextField}
                                      disabled={
                                        _source
                                          ? _source.status &&
                                            idsWithEditPermission()
                                            ? false
                                            : true
                                          : false
                                      }
                                      variant="outlined"
                                      onPaste={(e) => e.preventDefault()}
                                      placeholder="UPC/EAN"
                                      id={"UPC_EAN"}
                                      fieldheight="30px"
                                      handleKeyPress={handleNumericKeyPress}
                                      maxLength={14}
                                      size="small"
                                      name="UPC_EAN"
                                      error={
                                        Boolean(errors.UPC_EAN) &&
                                        Boolean(touched.UPC_EAN)
                                      }
                                      // helperText={
                                      //   Boolean(touched.UPC_EAN) && errors.UPC_EAN
                                      // }
                                      className="col-10"
                                    />
                                  </Tooltip>
                                  <ErrorMessage name="UPC_EAN" />
                                </Grid>
                              </Grid>
                            )}
                            <br />
                            <Grid
                              item
                              lg={12}
                              md={12}
                              sm={12}
                              container
                              gap={3}
                              alignItems={"center"}
                              justifyContent="center"
                              display="flex"
                            >
                              <Grid
                                item
                                lg={3}
                                md={3}
                                sm={3}
                                textAlign="right"
                                className="form_label"
                                style={{ direction: "ltr" }}
                              >
                                <Typography>
                                  Ask to generate an UPC/EAN
                                </Typography>
                              </Grid>
                              <Grid item lg={8} md={8} sm={8} textAlign="left">
                                <Field name="ask_to_generate_upc">
                                  {({ field }) => (
                                    <RadioGroup
                                      {...field}
                                      row
                                      // value={askToGenerateISRC}
                                      onChange={(event) => {
                                        setFieldValue(
                                          "ask_to_generate_upc",
                                          event.target.value
                                        );

                                        if (event.target.value === "Yes") {
                                          setFieldValue("UPC_EAN", "");
                                        }
                                        // handleRadioChange(event);
                                        // field.onChange(event);
                                      }}
                                    >
                                      {AskToGenerateUPCEAN.map((option) => (
                                        <FormControlLabel
                                          key={option}
                                          className="p-0 m-0"
                                          value={option}
                                          control={
                                            <Radio
                                              disabled={
                                                _source
                                                  ? _source.status &&
                                                    idsWithEditPermission()
                                                    ? false
                                                    : true
                                                  : false
                                              }
                                            />
                                          }
                                          label={
                                            <span
                                              style={{
                                                fontSize: 12,
                                              }}
                                            >
                                              {option}
                                            </span>
                                          }
                                          sx={{ fontSize: 12 }}
                                        />
                                      ))}
                                    </RadioGroup>
                                  )}
                                </Field>
                              </Grid>
                            </Grid>
                            <br />
                            <TextFieldWithLabel
                              label="&#169; Copyright"
                              name="copyrighted_to"
                              placeholder="Copyright"
                              errors={errors}
                              size="small"
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter copyrighted notice text.Enter the year date of first publication of the entire musical work and the name of the copyright owner.For derivative works or compilations,enter the date of publication of the work as a hole."
                            />
                            <br />
                            <TextFieldWithLabel
                              label="&#x24C5; Published"
                              name="published_by"
                              placeholder="Published"
                              size="small"
                              errors={errors}
                              touched={touched}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter the  published by field"
                            />
                            <br />
                            <TextFieldWithLabel
                              label="Producer Catalogue Number"
                              name="producer_catalogue_number"
                              placeholder="Producer Catalogue Number"
                              errors={errors}
                              touched={touched}
                              size="small"
                              handleKeyPress={handleKeyPress}
                              required={true}
                              disabled={
                                _source
                                  ? _source.status && idsWithEditPermission()
                                    ? false
                                    : true
                                  : false
                              }
                              title="Enter catalog # that is unique within choosen labels's catalog"
                            />
                            <br />
                            <br />
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            xl={6}
                            md={6}
                            sm={6}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <Typography
                              component={"div"}
                              onClick={() => {
                                setOpenCoverPicUploader(true);
                              }}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                width: {
                                  xl: "192px",
                                  lg: "200px",
                                  md: "100px",
                                  sm: "100px",
                                  xs: "100px",
                                },
                                height: {
                                  xl: "192px",
                                  lg: "200px",
                                  md: "100px",
                                  sm: "100px",
                                  xs: "100px",
                                },
                                cursor: `${
                                  _source && _source.status.id === "2"
                                    ? "default"
                                    : "pointer"
                                }`,
                                backgroundColor: theme.palette.background.paper,
                                border: `1px dashed ${theme.palette.text.dark}`,
                              }}
                            >
                              <Field
                                name="thumbnail_url"
                                // disabled={true}
                              >
                                {({
                                  field: { value },
                                  form: { setFieldValue },
                                }) => (
                                  <>
                                    {value && lowImage ? (
                                      <img
                                        src={lowImage}
                                        alt="Invalid Image"
                                        onError={(e) => {}}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          objectFit: "contain",
                                        }}
                                      />
                                    ) : lowImageLoader ? (
                                      <CircleLoader />
                                    ) : (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          width: "100%",
                                          height: "100%",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faFileArrowUp}
                                          style={{
                                            color: theme.palette.common.grey,
                                            width: "37px",
                                            height: "50px",
                                          }}
                                        ></FontAwesomeIcon>
                                        <Typography
                                          component={"span"}
                                          sx={{
                                            color: theme.palette.common.grey,
                                            fontSize: "16px",
                                            mt: "15px",
                                          }}
                                        >
                                          Upload Cover
                                        </Typography>
                                      </Box>
                                    )}

                                    <input
                                      id="thumbnail-upload"
                                      type="file"
                                      accept="image/*"
                                      style={{ display: "none" }}
                                      onChange={(e) => {
                                        handleFileUpload(
                                          e.target.files[0],
                                          setFieldValue,
                                          e
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </Field>
                            </Typography>
                            <Typography
                              component={"span"}
                              sx={{
                                textAlign: "center",
                                fontSize: "12px",
                                color: theme.palette.primary.light,
                                mt: 1,
                              }}
                            >
                              Image size should be in 3000px x 3000px
                              <br />
                              With Jpeg Format
                              <br />
                              <ErrorMessage name="thumbnail_url" />
                            </Typography>
                            <CoverPicUploadDialog
                              open={
                                openCoverPicUploader &&
                                _source &&
                                idsWithEditPermission()
                              }
                              highImage={values.thumbnail_url && highImage}
                              highImageLoader={highImageLoader}
                              onClose={() => {
                                setOpenCoverPicUploader(false);
                              }}
                              onSave={() => {
                                SaveThumbnailUrl(values);
                              }}
                              onDelete={() => {
                                DeleteThumbnailUrl(values);
                              }}
                              dltLoading={dltLoading}
                              artists={values.artists}
                              title={values.title}
                              thumbnail_url={
                                _source && _source.thumbnail_url
                                  ? _source.thumbnail_url
                                  : null
                              }
                            />
                            {(props?.releaseById?._source?.status?.id == 10 ||
                              props?.releaseById?._source?.status?.id == 9) &&
                              props?.releaseById?._source?.submission_history
                                ?.length > 0 && (
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    marginTop: "30px",
                                  }}
                                >
                                  Changes Required :
                                </Typography>
                              )}
                            <div
                              style={{
                                maxHeight:
                                  props?.releaseById?._source
                                    ?.submission_history?.length > 30
                                    ? "500px"
                                    : "auto",
                                overflowY: "auto",
                                overflowX: "hidden",
                                width: "75%",
                              }}
                            >
                              {(props?.releaseById?._source?.status?.id == 10 ||
                                props?.releaseById?._source?.status?.id == 9) &&
                                props?.releaseById?._source
                                  ?.submission_history &&
                                props?.releaseById?._source?.submission_history
                                  .length > 0 &&
                                props?.releaseById?._source?.submission_history.map(
                                  (item, index) => (
                                    <Box>
                                      <Grid
                                        item
                                        container
                                        alignItems={"center"}
                                        justifyContent="space-between"
                                        sx={{
                                          backgroundColor: "white",
                                          padding: "10px",
                                          borderRadius: "5px",
                                          border: `1px solid ${theme.palette.borderColor.textField}`,
                                        }}
                                        my={0.1}
                                      >
                                        <Grid item>
                                          <Typography
                                            key={index}
                                            variant="body1"
                                            gutterBottom
                                          >
                                            {props?.releaseById?._source?.status
                                              ?.id == 9 && (
                                              <div>
                                                <b>{index + 1}. </b>{" "}
                                                {item.fields &&
                                                  `Field: ${item.fields.join(
                                                    ", "
                                                  )}`}
                                                <br />
                                                Message: {item.message}
                                              </div>
                                            )}
                                            {props?.releaseById?._source?.status
                                              ?.id == 10 &&
                                              `${index + 1}. ${item.message}`}
                                          </Typography>
                                        </Grid>

                                        <Grid item>
                                          <Typography
                                            key={index}
                                            variant="body1"
                                            gutterBottom
                                            sx={{
                                              fontSize: "12px",
                                            }}
                                          >
                                            {/* {`${index + 1}. ${item.message}`} */}
                                            {returnDateAndTime(
                                              item.messaged_on
                                            )}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Box>
                                  )
                                )}
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Form>
            )}
          </Formik>
        )}
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(ReleaseInformation);

// Custom filter function
const customFilterOptions = (options, { inputValue }) => {
  let minCharsToShowDropdown = 2;
  if (inputValue.length < minCharsToShowDropdown) {
    return []; // If less than minCharsToShowDropdown, hide the dropdown
  }

  // Filter options based on the entered value after 2-3 letters
  const filteredOptions = options.filter((option) =>
    option.artist_name.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Sort the filtered options in ascending order
  return filteredOptions.sort((a, b) =>
    a.artist_name.localeCompare(b.artist_name)
  );
};

const TextFieldWithLabel = ({
  label,
  name,
  placeholder,
  errors,
  touched,
  inputMode,
  handleKeyPress,
  required,
  disabled,
  title,
}) => (
  <>
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      container
      gap={3}
      alignItems={"center"}
      justifyContent="center"
      display="flex"
    >
      <Grid
        item
        lg={3}
        md={3}
        sm={3}
        textAlign="right"
        className="form_label"
        style={{ direction: "ltr" }}
      >
        <Typography>
          {label} {required && "*"}
        </Typography>
      </Grid>
      <Grid item lg={8} md={8} sm={8}>
        <Tooltip title={title} arrow placement="right-end">
          <Field
            variant="outlined"
            as={MuiTextField}
            fieldheight="30px"
            id={name}
            inputMode={inputMode}
            handleKeyPress={handleKeyPress}
            size="small"
            name={name}
            placeholder={placeholder}
            error={Boolean(errors[name]) && Boolean(touched[name])}
            helperText={Boolean(touched[name]) && errors[name]}
            disabled={disabled}
          />
        </Tooltip>
      </Grid>
    </Grid>
  </>
);

const SelectField = ({
  label,
  name,
  options,
  size,
  placeholder,
  errors,
  touched,
  required,
  setFieldValue,
  setvalue,
  disabled,
  title,
}) => {
  return (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      container
      gap={3}
      display="flex"
      justifyContent="center"
      //alignItems={"center"}
    >
      <Grid
        item
        lg={3}
        md={3}
        sm={3}
        textAlign="right"
        className="form_label"
        style={{
          direction: "ltr",
        }}
        mt={0.5}
      >
        <Typography>
          {label} {required && "*"}
        </Typography>
      </Grid>

      <Grid item lg={8} md={8} sm={8}>
        <FastField name={name}>
          {({ field, meta }) => (
            <Tooltip title={title} arrow placement="right-end">
              <Select
                {...field}
                MenuProps={menuProps}
                input={<OutlinedInput {...inputProps} disabled={disabled} />}
                displayEmpty
                size={size}
                style={{ width: "100%", height: "30px" }}
                error={meta.touched && meta.error ? true : false}
                onChange={(event) => {
                  // Custom logic to handle the onChange event
                  const newValue = event.target.value;

                  if (newValue === "single") {
                    setFieldValue("plan_id", setvalue);
                  }
                  // Update the form field with the selected value
                  field.onChange(event);
                }}
              >
                {placeholder && (
                  <MenuItem value="" disabled>
                    <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
                  </MenuItem>
                )}
                {options.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </Tooltip>
          )}
        </FastField>
        &nbsp;
        <ErrorMessage name={name} />
      </Grid>
    </Grid>
  );
};

const AutoCompleteForm = (props) => {
  const {
    name,
    label,
    placeholder,
    options,
    errors,
    touched,
    required,
    values,
    disabled,
    title,
  } = props;

  return (
    <Grid
      item
      lg={12}
      md={12}
      sm={12}
      container
      gap={3}
      display="flex"
      justifyContent="center"
      alignItems={"center"}
    >
      <Grid
        item
        lg={3}
        md={3}
        sm={3}
        textAlign="right"
        className="form_label"
        style={{
          direction: "ltr",
        }}
      >
        <Typography>
          {label} {required && "*"}{" "}
        </Typography>
      </Grid>

      <Grid item lg={8} md={8} sm={8}>
        <Tooltip title={title} arrow placement="right-end">
          <Field
            name={name}
            size="small"
            component={AutoCompleteMultiSelect}
            disableClearable
            disabled={disabled}
            options={options ? options : []}
            // filterOptions={customFilterOptionslyrics}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            renderOption={(props, item) => (
              <li {...props} key={item.id}>
                {item.name}
              </li>
            )}
            margin={0}
            getOptionLabel={(option) => option.name}
            textFieldProps={{
              margin: "normal",
              variant: "outlined",
              placeholder: placeholder,
            }}
            // error={Boolean(errors[name]) && Boolean(touched[name])}
            // helperText={Boolean(touched[name]) && errors[name]}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};

export const FieldArrayComponent = ({
  name,
  label,
  required,
  values,
  errors,
  touched,
  title,
  disabled,
}) => {
  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <Box sx={{ width: "100%" }}>
          {Array.isArray(values[name]) &&
            values[name].map((item, index) => (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                gap={3}
                alignItems={"center"}
                justifyContent="center"
                display="flex"
              >
                <Grid
                  item
                  lg={3}
                  md={3}
                  sm={3}
                  textAlign="right"
                  className="form_label"
                  style={{ direction: "ltr" }}
                >
                  {index === 0 && (
                    <Typography color={theme.palette.primary.main}>
                      {label} {required && "*"}
                    </Typography>
                  )}
                </Grid>
                <Grid item lg={8} md={8} sm={8}>
                  <Grid item className="d-flex">
                    <Tooltip title={title} arrow placement="right-end">
                      <Field
                        as={MuiTextField}
                        variant="outlined"
                        placeholder={"Enter Version/Subtitle"}
                        id={`${name}[${index}]`}
                        size="small"
                        name={`${name}[${index}]`}
                        disabled={disabled}
                        error={errors && Boolean(errors.hasOwnProperty(name))}
                      />
                    </Tooltip>
                    {values[name].length !== 1 && (
                      <IconButton onClick={() => remove(index)}>
                        <DeleteIcon color="primary" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            ))}

          {values[name] &&
            values[name][values[name].length - 1] &&
            values[name][values[name] && values[name].length - 1].length !==
              0 && (
              <Grid
                container
                lg={11}
                md={11}
                sm={11}
                mt={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                px={1}
              >
                <IconButton onClick={() => push("")}>
                  <AddCircleIcon color="primary" />
                </IconButton>
              </Grid>
            )}
        </Box>
      )}
    </FieldArray>
  );
};
