import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import YTRevenueReportGetModel from "./models/YTRevenueReportGetModel";

export default class YTRevenueReportEffect {
  static async requestYTRevenueReport(params) {
    const endpoint = environment.api.ytRevenueReport;

    return EffectUtility.getToModel(YTRevenueReportGetModel, endpoint, params);
  }

  static async requestYTRevenueReportExport(params, callBackfunction) {
    const endpoint = environment.api.ytRevenueReport;

    let response = EffectUtility.getToModel(
      YTRevenueReportGetModel,
      endpoint,
      params
    );

    if (response) {
      response.then((resp) => {
        callBackfunction(resp);
      });
    }
    return response;
  }

  static async requestYTRevenueReportClear() {
    return {};
  }

  static async requestYTRevenueReportFilterClear() {
    return {};
  }

  static async requestYTRevenueReportFilter(params) {
    const endpoint = environment.api.ytRevenueReport;
    return EffectUtility.getToModel(YTRevenueReportGetModel, endpoint, params);
  }
}
