import BaseReducer from "../../utilities/BaseReducer";
import PagePermissionAction from "./PagePermissionAction";

// PageRoleReduser function
export default class PagePermissionReduser extends BaseReducer {
  initialState = {
    pagePermission: [],
  };
  [PagePermissionAction.REQUEST_PAGE_PERMISSION_FINISHED](state, action) {
    return {
      ...state,
      pagePermission: action.payload,
    };
  }
}
