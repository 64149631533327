import BaseReducer from "../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

// TrackReduser
export default class A3ReportsLabelReducer extends BaseReducer {
  initialState = {
    clientLabelReport: [],
  };
  [A3ReportsAction.REQUEST_A3REPORTS_LABEL_FINISHED](state, action) {
    return {
      ...state,
      clientLabelReport: action.payload,
    };
  }
}
