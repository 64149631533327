import {
  Box,
  Grid,
  IconButton,
  Switch,
  Typography,
  createFilterOptions,
  Chip,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import React, { useState, useRef } from "react";
import { connect } from "react-redux";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { theme } from "../App";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { ModelScreenContainer } from "../../components/componentLayout/ComponentLayout";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckboxTree from "react-checkbox-tree";
import CheckBoxTwoToneIcon from "@mui/icons-material/CheckBoxTwoTone";
import { FastField, Field, Form, Formik } from "formik";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import MuiButton from "../../components/muiButton/MuiButton";
import UserResourceAction from "../../stores/userResource/UserResourceAction";
import jwtDecode from "jwt-decode";
import InfoIcon from "@mui/icons-material/Info";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const UserAccessPost = (props) => {
  const boxRef = useRef(null);

  const [rows, setRows] = useState(() => {
    if (props.edit) {
      const updatedResource =
        props?.resourceList &&
        props?.resourceList.length > 0 &&
        props?.resourceList
          ?.map((item, index) => ({
            id: item.id,
            sl_no: index + 1,
            resource_id: item.resource_id,
            resource_name: item.resource_name,
            methods: [
              { GET: false },
              { POST: false },
              { PUT: false },
              { DELETE: false },
            ],
          }))
          .map((item) => {
            const excludedItem = props.accessEditData.exclude.find(
              (excludeItem) => excludeItem.endpoint === item.resource_id
            );
            if (excludedItem) {
              return {
                ...item,
                methods: item.methods.map((methodObj) => {
                  const [methodKey] = Object.keys(methodObj);
                  const shouldExclude =
                    excludedItem.methods.includes(methodKey);
                  return { [methodKey]: shouldExclude };
                }),
              };
            }
            return item;
          });

      return updatedResource;
    } else {
      return (
        (props?.resourceList &&
          props?.resourceList.length > 0 &&
          props?.resourceList?.map((item, index) => ({
            id: item.id,
            sl_no: index + 1,
            resource_id: item.resource_id,
            resource_name: item.resource_name,
            methods: [
              { GET: false },
              { POST: false },
              { PUT: false },
              { DELETE: false },
            ],
          }))) ||
        []
      );
    }
  });

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
    },
    {
      field: "resource_name",
      headerName: "Resource Name",
      width: 150,
    },

    {
      width: 300,
      field: "actions",
      type: "actions",
      headerName: (
        <span style={{ display: "flex" }}>
          &nbsp; &nbsp; &nbsp; <span>{"GET"}</span>&nbsp;&nbsp; &nbsp; &nbsp;
          <span>{"POST"}</span>
          &nbsp; &nbsp; &nbsp;&nbsp; <span>{"PUT"}</span>&nbsp;&nbsp;
          &nbsp;&nbsp; <span>{"DELETE"}</span>&nbsp; &nbsp;&nbsp;
        </span>
      ),
      renderCell: (params) => (
        <div style={{ display: "flex" }}>
          {params &&
            params.row &&
            Array.isArray(params.row.methods) &&
            params.row.methods.map((method, index) => {
              const methodName = Object.keys(method)[0];
              return (
                <div key={methodName} style={{ marginRight: "10px" }}>
                  <Switch
                    checked={method[methodName]}
                    onChange={(event) => {
                      const { row } = params;
                      const updatedMethods = [...row.methods];
                      updatedMethods[index] = {
                        [methodName]: event.target.checked,
                      };
                      const updatedRow = { ...row, methods: updatedMethods };
                      const updatedRows = rows.map((r) =>
                        r.id === updatedRow.id ? updatedRow : r
                      );
                      setRows(updatedRows);
                    }}
                  />
                </div>
              );
            })}
        </div>
      ),
    },
  ];

  const [expanded, setExpanded] = useState([]);

  let nodesList = [];
  props.page_mapping_list &&
    props.page_mapping_list.map((item) => {
      nodesList.push({
        value: item.page_id,
        label: item.page_name,
      });
    });

  let AllPagesList = [];
  nodesList &&
    nodesList.map((event) => {
      AllPagesList.push({
        page_id: event.value,
        page_name: event.label,
      });
    });

  console.log("Nodelist", { nodesList, AllPagesList });

  // const [selectedPages, setSelectedPages] = useState(
  //   props.roleEditData ? props.roleEditData.page_mapping_array : []
  // );

  const [selectedPages, setSelectedPages] = useState([]);

  let pagesidArrayofObj = [];
  const returnpage_idsObjectsArray = (idarray) => {
    pagesidArrayofObj = props.page_mapping_list.filter((item) =>
      idarray.includes(item.page_id)
    );
    return pagesidArrayofObj;
  };

  const [checked, setChecked] = useState(() => {
    if (
      props.edit &&
      props.accessEditData &&
      props.accessEditData.page_ids &&
      props.accessEditData.page_ids.length !== 0
    ) {
      return props.accessEditData.page_ids;
    } else {
      return (
        nodesList &&
        nodesList
          .filter((item) => {
            return (
              item.value !== "subClient" &&
              item.value !== "subClientAccess" &&
              item.value !== "revenueSharePolicy"
            );
          })
          .map((item) => {
            return item.value;
          })
      );
    }
  });

  async function PagesHandleChanege(setFieldValue, page) {
    let treeValues = [];

    treeValues = AllPagesList.filter((val) => page.includes(val.page_id));

    setSelectedPages(treeValues);
    setFieldValue("page_ids", treeValues);
  }

  function GridCellCheckboxRenderer(setFieldValue, formikValues) {
    let counter = 1;

    const auth = localStorage.getItem("Auth");
    const decodedData = jwtDecode(auth);

    if (decodedData.parent_client_id === "*") {
      nodesList = nodesList.filter(
        (item) =>
          !item.value.includes("subClient") &&
          !item.value.includes("revenueSharePolicy") &&
          !item.value.includes("revenueClaim")
      );
    }

    return (
      <Grid container>
        {nodesList.map((node) => (
          <Grid item xs={12} key={node.value}>
            <span>{counter++}. </span>
            <Checkbox
              checked={checked.includes(node.value)}
              onChange={(e) => {
                const isChecked = e.target.checked;
                const updatedChecked = isChecked
                  ? [...checked, node.value]
                  : checked.filter((value) => value !== node.value);
                setChecked(updatedChecked);
                PagesHandleChanege(setFieldValue, updatedChecked);
              }}
            />
            {node.label}
          </Grid>
        ))}
      </Grid>
    );
  }

  let UserObject = [];

  let getUserObject = (id) => {
    if (props.client_list && props.client_list.length !== 0) {
      UserObject = props.client_list.filter((val) => {
        return val.id === id;
      });
    }
    return UserObject[0];
  };

  let artistsArrayofObj = [];
  const returnARtistObjectsArray = (idarray) => {
    artistsArrayofObj = props.artist_list.filter((item) =>
      idarray.includes(item.id)
    );

    return artistsArrayofObj;
  };

  let labelsArrayofObj = [];
  const returnLabelsObjectsArray = (idarray) => {
    labelsArrayofObj = props.label_list.filter((item) =>
      idarray.includes(item.id)
    );

    return labelsArrayofObj;
  };

  let initial = {};

  if (props.edit) {
    initial = {
      user_id:
        props.accessEditData && props.accessEditData.user_id
          ? getUserObject(props.accessEditData.user_id.id)
          : null,
      artists:
        props.accessEditData &&
        props.accessEditData.artists &&
        props.accessEditData.artists.length !== 0
          ? returnARtistObjectsArray(props.accessEditData.artists)
          : [],

      labels:
        props.accessEditData &&
        props.accessEditData.labels &&
        props.accessEditData.labels.length !== 0
          ? returnLabelsObjectsArray(props.accessEditData.labels)
          : [],

      page_ids:
        props.accessEditData &&
        props.accessEditData.page_ids &&
        props.accessEditData.page_ids.length !== 0
          ? returnpage_idsObjectsArray(props.accessEditData.page_ids)
          : [],
    };
  } else {
    initial = {
      user_id: null,
      artists: props.artist_list.filter((item) => item.id === "*"),
      labels: [],
      page_ids: [],
    };
  }

  const closefunction = () => {
    props.onClose();
    setChecked([]);
    setSelectedPages({});
  };

  function Required() {
    return (
      <>
        <span style={{ color: "red" }}>Required</span>{" "}
        <span style={{ color: "red" }}>*</span>
      </>
    );
  }

  let starIndex = -1;
  for (let i = 0; i < props.artist_list.length; i++) {
    if (props.artist_list[i].id === "*") {
      starIndex = i;
      break;
    }
  }

  // If "*" is found, move it to the first index
  if (starIndex !== -1) {
    const starItem = props.artist_list.splice(starIndex, 1)[0];
    props.artist_list.unshift(starItem);
  }

  let starlabIndex = -1;
  for (let i = 0; i < props.label_list.length; i++) {
    if (props.label_list[i].id === "*") {
      starlabIndex = i;
      break;
    }
  }

  // If "*" is found, move it to the first index
  if (starlabIndex !== -1) {
    const starItem = props.label_list.splice(starlabIndex, 1)[0];
    props.label_list.unshift(starItem);
  }

  return (
    <Box ref={boxRef} style={{ paddingBottom: "25px" }}>
      <Formik
        enableReinitialize
        initialValues={initial}
        onSubmit={(values) => {
          let exclude = [];

          rows &&
            rows.length !== 0 &&
            rows.map((item) => {
              exclude.push({
                endpoint: item.resource_id,
                methods: item.methods
                  .filter((item) => Object.values(item)[0] === true)
                  .map((item) => Object.keys(item)[0]),
              });
            });

          let post_obj = {};

          Object.assign(
            post_obj,
            values.user_id && {
              user_id: values.user_id.id,
            },
            values.artists.length !== 0 && values.artists.length === 1
              ? {
                  artists: values.artists.map((item) => {
                    return item.id;
                  }),
                }
              : values.artists.length > 1 && {
                  artists:
                    values.artists.filter((item) => {
                      return item.id !== "*";
                    }) &&
                    values.artists.filter((item) => {
                      return item.id !== "*";
                    }).length !== 0
                      ? values.artists
                          .filter((item) => {
                            return item.id !== "*";
                          })
                          .map((item) => {
                            return item.id;
                          })
                      : null,
                },

            values.labels.length !== 0 && values.labels.length === 1
              ? {
                  labels: values.labels.map((item) => {
                    return item.id;
                  }),
                }
              : values.labels.length > 1 && {
                  labels:
                    values.labels.filter((item) => {
                      return item.id !== "*";
                    }) &&
                    values.labels.filter((item) => {
                      return item.id !== "*";
                    }).length !== 0
                      ? values.labels
                          .filter((item) => {
                            return item.id !== "*";
                          })
                          .map((item) => {
                            return item.id;
                          })
                      : null,
                },

            values.labels.length !== 0 && {
              labels: values.labels.map((item) => {
                return item.id;
              }),
            },
            values.page_ids.length !== 0
              ? {
                  page_ids: values.page_ids.map((item) => {
                    return item.page_id;
                  }),
                }
              : {
                  page_ids:
                    nodesList &&
                    nodesList
                      .filter((item) => {
                        return (
                          item.value !== "subClient" &&
                          item.value !== "subClientAccess"
                        );
                      })
                      .map((item) => {
                        return item.value;
                      }),
                },
            {
              client_id: "",
            },
            rows &&
              rows.length !== 0 && {
                exclude: exclude.filter((item) => item.methods.length > 0),
              }
          );

          if (props.edit) {
            props
              .dispatch(
                UserResourceAction.requestPutUserResource(
                  JSON.stringify(post_obj),
                  props.accessEditData.id
                )
              )
              .then(() => {
                let param = {
                  page_limit: "none",
                  order_by: `[["modified_on","desc"]]`,
                };
                props.dispatch(UserResourceAction.requestUserResource(param));
                props.onClose();
              });
          } else {
            props
              .dispatch(
                UserResourceAction.requestPostUserResource(
                  JSON.stringify(post_obj)
                )
              )
              .then(() => {
                let param = {
                  page_limit: "none",
                  order_by: `[["created_on","desc"]]`,
                };
                props.dispatch(UserResourceAction.requestUserResource(param));
                props.onClose();
              });
          }
        }}
        validate={(values) => {
          var errors = {};

          if (!values.user_id) {
            errors.user_id = <Required />;
          }

          if (values.artists && !values.artists.length) {
            errors.artists = <Required />;
          }

          if (values.labels && !values.labels.length) {
            errors.labels = <Required />;
          }

          return errors;
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          setFieldValue,
          values,
        }) => (
          <Box>
            <Form>
              <Box
                style={{
                  position: "sticky",
                  // position: "fixed",
                  top: `${boxRef?.current?.offsetTop}px`,
                  zIndex: "10",
                  backgroundColor: theme.palette.background.default,
                }}
              >
                <Grid item container lg={12} sm={12} md={12}>
                  <Grid item lg={2} md={2} sm={2} xs={2}>
                    <IconButton>
                      <ArrowCircleLeftIcon
                        style={{
                          height: "40px",
                          width: "40px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          closefunction();
                        }}
                      />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    lg={10}
                    md={10}
                    sm={10}
                    xs={10}
                    display="flex"
                    justifyContent={"center"}
                  >
                    <Typography
                      component={"div"}
                      fontSize={30}
                      sx={{
                        color: theme.palette.primary.main,
                        fontWeight: "bold",
                        justifySelf: "center",
                      }}
                    >
                      {!props.edit
                        ? formatName("Add User Access")
                        : formatName("Edit User Access")}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  alignItems="center"
                  justifyContent={"flex-start"}
                  xs={12}
                >
                  <Grid item container sm={12} md={3}>
                    <AutoCompleteFormsingle
                      multiple={false}
                      values={values}
                      name="user_id"
                      label="User"
                      placeholder="User"
                      options={props.client_list}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>

                  <Grid item container sm={12} md={4}>
                    <AutoCompleteFormmultiple
                      multiple={true}
                      name="artists"
                      label="Artists"
                      placeholder="Artists"
                      options={props.artist_list}
                      errors={errors}
                      touched={touched}
                      values={values}
                      title="Select the Artist Name"
                    />
                  </Grid>

                  <Grid item container sm={12} md={4}>
                    <AutoCompleteFormmultiple
                      name="labels"
                      multiple={true}
                      label="Labels"
                      placeholder="Labels"
                      options={props.label_list}
                      errors={errors}
                      touched={touched}
                      values={values}
                      title="Select the Label Name"
                    />
                  </Grid>

                  <Grid item sm={12} md={1}>
                    <MuiButton name="Save" type="submit" />
                  </Grid>
                  <Grid item container xs={12} justifyContent={"center"}>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        paddingX: "10px",
                        paddingY: "18px",
                        textAlign: "center",
                      }}
                    >
                      <InfoIcon />
                      &nbsp; The page permission changes when the user Logout
                      and Re-Login to the Application
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid
                item
                container
                lg={12}
                sm={12}
                md={12}
                // justifyContent={"center"}
              >
                {/* <Grid item container lg={6} sm={6} md={6}>
                  <Grid item container lg={10} sm={10} md={10}>
                    <Box sx={{ width: "100%" }}>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                          className="p-0 m-0"
                          sx={{
                            backgroundColor:
                              theme.palette.datagrid.background.datagridHeader,
                            fontSize: theme.palette.datagrid.Columns.fontSize,
                            fontWeight: theme.typography.fontWeightBold,
                          }}
                        >
                          <Typography
                            sx={{
                              backgroundColor:
                                theme.palette.datagrid.background
                                  .datagridHeader,
                              fontSize: theme.palette.datagrid.Columns.fontSize,
                              fontWeight: theme.typography.fontWeightBold,
                              textAlign: "center",
                              width: "100%",
                            }}
                            // className="bg-warning"
                          >
                            This User has been Excluded from These Resources
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails className="p-0 m-0">
                          <DataGridFunction
                            rowHeight={60}
                            rows={rows}
                            columns={columns}
                            headerHeight={50}
                          />
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  </Grid>
                </Grid> */}

                <Grid item container lg={6} sm={6} md={6}>
                  <Grid item container lg={10} sm={10} md={10}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      className="scrollable"
                      sx={{
                        height: "fit-content",
                        overflow: "auto",
                        overscrollBehaviorY: "scroll",
                        backgroundColor: "white",
                        padding: 2,
                        borderRadius: theme.borderRadius,
                        border: "solid 0.5px #d3e2e6",
                        boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.16)",
                      }}
                    >
                      {GridCellCheckboxRenderer(setFieldValue, values)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default connect(mapDispatchToProps)(UserAccessPost);
const AutoCompleteFormsingle = (props) => {
  const {
    name,
    label,
    placeholder,
    options,
    errors,
    touched,
    multiple,
    values,
  } = props;

  return (
    <Grid item container alignItems={"center"}>
      <Grid item xl={2} textAlign="right">
        <Typography color={theme.palette.text.form}>{label} * </Typography>
      </Grid>
      <Grid item xl={10} textAlign="left" px={1}>
        <Tooltip title="Select User from the list" arrow placement="top">
          <FastField
            name={name}
            size="small"
            multiple={multiple}
            component={AutoCompleteMultiSelect}
            fieldheight="30px"
            options={options ? options : []}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: (option) => option.name,
            })}
            // value={props.edit && values ? values.name : null}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            textFieldProps={{
              margin: "normal",
              variant: "outlined",
              placeholder: placeholder,
            }}
            // error={Boolean(errors[name]) && Boolean(touched[name])}
            // helperText={Boolean(touched[name]) && errors[name]}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};
const AutoCompleteFormmultiple = (props) => {
  const {
    name,
    label,
    placeholder,
    options,
    errors,
    touched,
    multiple,
    values,
    title,
  } = props;

  return (
    <Grid item container alignItems={"center"}>
      <Grid
        item
        xl={2}
        textAlign="right"
        className="form_label"
        // style={{ direction: "rtl" }}
      >
        <Typography color={theme.palette.text.form}>{label} * </Typography>
      </Grid>
      <Grid item xl={10} textAlign="left" px={1}>
        <Tooltip title={title} arrow placement="top">
          <Field
            name={name}
            size="small"
            multiple={multiple}
            limitTags={1}
            component={AutoCompleteMultiSelect}
            fieldheight="30px"
            options={options ? options : []}
            filterOptions={createFilterOptions({
              matchFrom: "start",
              stringify: (option) => option.name,
            })}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            textFieldProps={{
              margin: "normal",
              variant: "outlined",
              placeholder: values && !values.name ? placeholder : null,
            }}
            // error={Boolean(errors[name]) && Boolean(touched[name])}
            // helperText={Boolean(touched[name]) && errors[name]}
          />
        </Tooltip>
      </Grid>
    </Grid>
  );
};
