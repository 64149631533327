import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import React from "react";
import { theme } from "../../App";
import Rettrive from "../../releases/Rettrive";
import { useNavigate } from "react-router";

const ReleaseCountBox = ({ releasecount, labelFilter }) => {
  const navigate = useNavigate();

  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  function diversionFunction(item) {
    if (item.key_id == 8) {
      navigate("/delivered");
    } else {
      navigate("/releases", {
        state: {
          status: { id: item.key_id, status_name: item.key },
          // label: labelFilter,
        },
      });
    }
  }

  releasecount = releasecount.sort((a, b) => b.count - a.count);

  return (
    <>
      {releasecount?.map(
        (item) =>
          item.key_id !== "6" && (
            <Card sx={{ width: "220px" }}>
              <CardContent>
                <Box sx={{ cursor: "pointer" }}>
                  <Box
                    sx={{
                      display: "flex",
                      boxShadow: theme.shadows,
                      borderRadius: 1,
                      transition: "transform 0.1s ease-in",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.6)",
                      },
                    }}
                    onClick={() => {
                      diversionFunction(item);
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: "center",
                        width: "100%",
                        padding: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 20,
                          fontWeight: "bold",
                        }}
                      >
                        {item.count}
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-around",
                      paddingY: 1,
                    }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // textTransform: "capitalize",
                        fontWeight: "bold",
                      }}
                    >
                      {convertToTitleCase(item.key)}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          )
      )}
    </>
  );
};

export default ReleaseCountBox;
