import React, { useState } from "react";
import {
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik, Field, getIn } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import StatusAction from "../../stores/status/StatusAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../App";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import LabelAction from "../../stores/label/LabelAction";
import { HighlightOff } from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  label_name: yup
    .string("Enter your Label name")
    .trim()
    .min(1, "Label name should be of minimum 1 characters length")
    .required("Label name is required"),
});

const LabelPost = (props) => {
  let projectProps = props.projectProps;

  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      label_name: props.projectEditData.label_name,
    };
  } else {
    initial = {
      label_name: "",
    };
  }
  // Formik

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          // parameter for showing list based on the modified_by
          const params = {
            order_by: `[["created_on","desc"]]`,

            page_limit: 15,
          };
          const param = {
            order_by: `[["created_on","desc"]]`,

            page_limit: "none",
          };
          if (props.edit) {
            let post_obj = {};

            Object.assign(
              post_obj,
              values.label_name && {
                label_name: values.label_name,
              }
            );

            let page_params = {
              current_page: "label",
            };

            props
              .dispatch(
                LabelAction.requestPutLabel(
                  JSON.stringify(post_obj),
                  props.projectEditData.id,
                  page_params
                )
              )
              .then(() => {
                props.dispatch(LabelAction.requestLabel(params));
                props.dispatch(LabelAction.requestLabelFilter(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            let post_obj = {};

            let page_params = {
              current_page: "label",
            };

            Object.assign(
              post_obj,
              values.label_name && {
                label_name: values.label_name,
              }
            );
            props
              .dispatch(LabelAction.requestPostLabel(post_obj, page_params))
              .then(() => {
                const params = {
                  order_by: `[["created_on","desc"]]`,

                  page_limit: 15,
                };
                const param = {
                  order_by: `[["created_on","desc"]]`,

                  page_limit: "none",
                };
                props.dispatch(LabelAction.requestLabel(params));
                props.dispatch(LabelAction.requestLabelFilter(param));
                props.onClose();
                setLoading(false);
              });
          }

          // formikHelpers.resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%", marginBottom: 5 }}>
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"div"}
                    fontSize={30}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      justifySelf: "center",
                    }}
                  >
                    {!props.edit
                      ? formatName("Add Label Name")
                      : formatName("Edit Label Name")}
                  </Typography>

                  <HighlightOff
                    close={() => {
                      props.onClose();
                    }}
                  />
                </Typography>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Label Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Label Name"
                      id="label_name"
                      size="small"
                      name="label_name"
                      error={
                        Boolean(errors.label_name) &&
                        Boolean(touched.label_name)
                      }
                      helperText={
                        Boolean(touched.label_name) && errors.label_name
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Add Label"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Submit"
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(LabelPost);
