import { Alert, Snackbar } from "@mui/material";
import React from "react";
import { theme } from "../../views/App";

function MuiSnackbarAlert({ open, onClose, message }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={1000}
      onClose={onClose}
    >
      <Alert
        onClose={onClose}
        severity="warning"
        sx={{
          width: "100%",
          borderRadius: "5px",
          backgroundColor: theme.palette.secondary.main,
          color: "white",
        }}
      >
        {message ? message : ""}
      </Alert>
    </Snackbar>
  );
}

export default MuiSnackbarAlert;
