import React, { Suspense } from "react";
import {} from "connected-react-router";
import { Route, Routes } from "react-router-dom";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import { connect } from "react-redux";
import routes from "./routecoll";

const mapStateToProps = (state) => ({
  auth: state.auth,
});
class MyAuthRoute extends React.Component {
  render() {
    return (
      <Suspense fallback={<LoadingIndicator isActive={true} />}>
        <Routes>
          {routes.data
            .filter((col) => col.category === "Auth")
            .map((item) => {
              return (
                <Route
                  exact="true"
                  key={item.id}
                  path={item.path}
                  element={item.element}
                />
              );
            })}
        </Routes>
      </Suspense>
    );
  }
}
export default connect(mapStateToProps)(MyAuthRoute);
