import React, { useState } from "react";
import environment from "environment";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import a3Logo from "../../Images/a3_web_logo_image.webp";

const ProfileRetrieve = (props) => {
  const [loading, setLoading] = useState(false);

  const [imgUrl, setImgUrl] = useState(() => {
    props.user_profile_url && RetrieveImageUrl(props.user_profile_url);
  });

  function RetrieveImageUrl() {
    setLoading(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    let userId = localStorage.getItem("userId");

    let imageUrl = "";
    fetch(
      `${environment.api.baseApi}/get_profile_picture?path=${props.user_profile_url}&user_id=${userId}&current_page=profile`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);

        setImgUrl(imageUrl);
        setLoading(false);
      });
  }

  return (
    <>
      {!loading && imgUrl ? (
        <img
          src={imgUrl}
          // alt="Preview"
          onError={(e) => {
            e.target.src = a3Logo;
          }}
          style={{
            width: "100%",
            height: "100%",
            // objectFit: "cover",
            overflow: "hidden",
          }}
        />
      ) : !imgUrl && !props.user_profile_url ? (
        <img
          src={a3Logo}
          // alt="Preview"
          onError={() => {
            // Error handling code goes here
          }}
          style={{
            width: "100%",
            aspectRatio: "1/1",
            // height: "100%",
            // objectFit: "fill",
            overflow: "hidden",
            borderTopLeftRadius: props.borderTopLeftRadius ?? "",
            borderTopRightRadius: props.borderTopRightRadius ?? "",
          }}
        />
      ) : (
        <CircleLoader width={"20px"} height={"20px"} />
      )}
    </>
  );
};

export default ProfileRetrieve;
