import ActionUtility from "../../utilities/ActionUtility";
import ReleasesEffect from "./ReleasesEffect";

export default class ReleasesAction {
  static REQUEST_RELEASES = "ReleasesAction.REQUEST_RELEASES";
  static REQUEST_RELEASES_FINISHED = "ReleasesAction.REQUEST_RELEASES_FINISHED";

  static REQUEST_TOP_5_DELIVERED_RELEASES =
    "ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES";
  static REQUEST_TOP_5_DELIVERED_RELEASES_FINISHED =
    "ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES_FINISHED";

  static REQUEST_DRAFT_RELEASES = "ReleasesAction.REQUEST_DRAFT_RELEASES";
  static REQUEST_DRAFT_RELEASES_FINISHED =
    "ReleasesAction.REQUEST_DRAFT_RELEASES_FINISHED";

  static REQUEST_RELEASE_FILTER = "ReleasesAction.REQUEST_RELEASE_FILTER";
  static REQUEST_RELEASE_FILTER_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_FILTER_FINISHED";

  static REQUEST_PUT_RELEASE = "ReleasesAction.REQUEST_PUT_RELEASE";
  static REQUEST_PUT_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_PUT_RELEASE_FINISHED";

  static REQUEST_POST_FINAL_RELEASE =
    "ReleasesAction.REQUEST_POST_FINAL_RELEASE";
  static REQUEST_POST_FINAL_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_POST_FINAL_RELEASE_FINISHED";

  static REQUEST_POST_RELEASE = "ReleasesAction.REQUEST_POST_RELEASE";
  static REQUEST_POST_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_POST_RELEASE_FINISHED";

  static REQUEST_RELEASE_CLEAR = "ReleasesAction.REQUEST_RELEASE_CLEAR";
  static REQUEST_RELEASE_CLEAR_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_CLEAR_FINISHED";

  static REQUEST_RELEASE_FILTER_CLEAR =
    "ReleasesAction.REQUEST_RELEASE_FILTER_CLEAR";
  static REQUEST_RELEASE_FILTER_CLEAR_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_FILTER_CLEAR_FINISHED";

  static REQUEST_RELEASE_BY_ID = "ReleasesAction.REQUEST_RELEASE_BY_ID";
  static REQUEST_RELEASE_BY_ID_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_BY_ID_FINISHED";

  static REQUEST_RELEASE_BY_ID_CLEAR =
    "ReleasesAction.REQUEST_RELEASE_BY_ID_CLEAR";
  static REQUEST_RELEASE_BY_ID_CLEAR_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_BY_ID_CLEAR_FINISHED";

  static REQUEST_DELETE_RELEASE = "ReleasesAction.REQUEST_DELETE_RELEASE";
  static REQUEST_DELETE_RELEASE_FINISHED =
    "ReleasesAction.REQUEST_DELETE_RELEASE_FINISHED";

  static REQUEST_FINAL_RELEASES = "ReleasesAction.REQUEST_FINAL_RELEASES";
  static REQUEST_FINAL_RELEASES_FINISHED =
    "ReleasesAction.REQUEST_FINAL_RELEASES_FINISHED";

  static REQUEST_WITHDRAW_RELEASE_FROM_REVIEW =
    "ReleasesAction.REQUEST_WITHDRAW_RELEASE_FROM_REVIEW";
  static REQUEST_WITHDRAW_RELEASE_FROM_REVIEW_FINISHED =
    "ReleasesAction.REQUEST_WITHDRAW_RELEASE_FROM_REVIEW_FINISHED";

  static REQUEST_FINAL_RELEASE_BY_ID =
    "ReleasesAction.REQUEST_FINAL_RELEASE_BY_ID";
  static REQUEST_FINAL_RELEASE_BY_ID_FINISHED =
    "ReleasesAction.REQUEST_FINAL_RELEASE_BY_ID_FINISHED";

  static REQUEST_RELEASE_EXISTINGTRACKS =
    "ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS";
  static REQUEST_RELEASE_EXISTINGTRACKS_FINISHED =
    "ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS_FINISHED";

  // METHODS
  static requesReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASES,
        ReleasesEffect.requesReleases,
        params
      );
    };
  }

  static requesTop5DeliveredReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES,
        ReleasesEffect.requesTop5DeliveredReleases,
        params
      );
    };
  }

  static requesDraftReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_DRAFT_RELEASES,
        ReleasesEffect.requesDraftReleases,
        params
      );
    };
  }

  static requesReleaseClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_CLEAR,
        ReleasesEffect.requesReleaseClear
      );
    };
  }

  static requesReleaseFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_FILTER_CLEAR,
        ReleasesEffect.requesReleaseFilterClear
      );
    };
  }

  static requestReleaseFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_FILTER,
        ReleasesEffect.requestReleaseFilter,
        params
      );
    };
  }
  static requestPutRelease(data, id, params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_PUT_RELEASE,
        ReleasesEffect.requestPutRelease,
        data,
        id,
        params,
        callback
      );
    };
  }

  static requestPostFinalRelease(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_POST_FINAL_RELEASE,
        ReleasesEffect.requestPostFinalRelease,
        data,
        id,
        params
      );
    };
  }

  static requestPostRelease(data, callBack, current_page) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_POST_RELEASE,
        ReleasesEffect.requestPostRelease,
        data,
        callBack,
        current_page
      );
    };
  }

  static requestReleaseById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_BY_ID,
        ReleasesEffect.requestReleaseById,
        id,
        params,
        callBack
      );
    };
  }

  static requestFinalReleaseById(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_FINAL_RELEASE_BY_ID,
        ReleasesEffect.requestFinalReleaseById,
        id,
        params,
        callBack
      );
    };
  }

  static requestDeleteRelease(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_DELETE_RELEASE,
        ReleasesEffect.requestDeleteRelease,
        id,
        params
      );
    };
  }

  static requestFinalReleases(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_FINAL_RELEASES,
        ReleasesEffect.requestFinalReleases,
        params
      );
    };
  }

  static RequestWithdrawReleaseFromReview(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_WITHDRAW_RELEASE_FROM_REVIEW,
        ReleasesEffect.RequestWithdrawReleaseFromReview,
        id,
        params
      );
    };
  }

  // Calling Release By ID - ExistingTracks
  static requestReleaseExistingTrack(id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS,
        ReleasesEffect.requestReleaseExistingTrack,
        id,
        params,
        callBack
      );
    };
  }
}
