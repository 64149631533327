import React, { useEffect, useState } from "react";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { Box, Grid, useTheme } from "@mui/material";
import TopComposerAction from "../../../stores/topComposer/TopComposerAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import TopLyricistAction from "../../../stores/topLyricist/TopLyricistAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TopLyricistAction.REQUEST_TOP_LYRICIST,
  ]),
  top_lyricists_report: state.top_lyricists_report.top_lyricists_report || [],
  pagination: state.top_lyricists_report.top_lyricists_report || [],
});

function TopLyricist(props) {
  const theme = useTheme();
  const { isRequesting } = props;

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchtoplyricist(params);
  }, []);

  function fetchtoplyricist(params) {
    props.dispatch(TopLyricistAction.requestTopLyricist(params));
  }

  const rows = [];

  const topLyricists = props?.top_lyricists_report?.top_lyricists;

  if (topLyricists && typeof topLyricists === "object") {
    topLyricists.map((item, index) => {
      rows.push({
        sl_no: index + 1,
        id: index + 1,
        lyricist_name: item.lyricist_name,
        tracks: item.tracks || "",
        views: item.views || "",
      });
    });
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 80,
    },

    {
      // width: 140,
      flex: 1,
      field: "lyricist_name",
      headerName: "Lyricist name",
    },

    {
      width: 280,
      field: "tracks",
      headerName: "No-Track",
    },

    {
      // width: 140,
      flex: 1,
      field: "views",
      headerName: "No-Views",
    },
  ];
  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={80}
                isRequesting={isRequesting}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(TopLyricist);
