import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography, Stack, Paper } from "@mui/material";
import { connect } from "react-redux";
import NetRevenueByMonthAction from "../../stores/netRevenueReportByMonth/NetRevenueByMonthAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import { theme } from "../App";
import { Bar, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import { getRandomColor } from "./ReportsReusedFunctions";
import { useNavigate } from "react-router";
import { NivoBarChart } from "../../components/nivoCharts/NivoBarChart";
import TrackwiseRevenueSummary from "../revenue/TrackwiseRevenueSummary";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import ReactEcharts from "echarts-for-react";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    NetRevenueByMonthAction.REQUEST_TRACKWISE_REVENUE,
  ]),
  trackwiseRevenue: state.trackwiseRevenue.trackwiseRevenue || [],
});

function TrackwiseRevenue(props) {
  ChartJS.register(ArcElement, Tooltip, Legend);

  const navigate = useNavigate();

  const { isRequesting, reportViewType } = props;
  const [titleName, setTitleName] = useState(null);
  const [ISRC, setISRC] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [openTrackRevenueSummary, setTrackRevenueSummary] = useState(false);
  const [searchClicked, setSearchClicked] = useState({
    titleName: false,
    ISRC: false,
  });
  const [clearClicked, setClearClicked] = useState({
    titleName: false,
    ISRC: false,
  });
  const [alertPopUp, setAlertPopUp] = useState(false);

  function requestTrackwiseRevenue(params) {
    props.dispatch(NetRevenueByMonthAction.requestTrackwiseRevenue(params));
  }

  let clientList = [];
  props?.clientFilter?.hits?.hits?.map((item) => {
    if (item?._source?.client_name)
      clientList.push({
        id: item?._id,
        client_name: item._source.client_name,
      });
  });

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
      hide: true,
    },

    {
      field: "key",
      headerName: `Track Name`,
      flex: 3,
    },
    {
      field: "revenue",
      headerName: "Revenue ($)",
      flex: 3,
    },
    {
      field: "percentage",
      headerName: "Percentage (%)",
      flex: 3,
    },
  ];

  const rows = [];
  if (props.trackwiseRevenue?.track_wise_revenue) {
    props.trackwiseRevenue?.track_wise_revenue?.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: index + 1,
        revenue: item.revenue,
        key: typeof item.key === "string" ? item.key : item.key.track_name,
        track_id: typeof item.key === "string" ? item.key : item.key.track_id,
        percentage: item.percentage,
      });
    });
  }

  useEffect(() => {
    let params = {
      youtube: true,
      current_page: "raw_report",
    };
    requestTrackwiseRevenue(params);
  }, []);

  const data = props.trackwiseRevenue;

  const colors = [
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
    `${theme.pieChart.color3}`,
    `${theme.pieChart.color4}`,
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color6}`,
  ];

  let countData = [];
  let nameLabels = [];
  let percentlables = [];
  let percentagedata = [];
  nameLabels = rows?.map((item) => {
    return item.key;
  });

  countData = rows?.map((item) => {
    return item.revenue;
  });
  percentagedata = rows.map((item) => {
    return Number(item.percentage).toFixed(2);
  });
  countData.map((item, index) => {
    index < colors.length && colors.push(getRandomColor());
  });

  let keysArray = [];
  const chartData = props.trackwiseRevenue?.track_wise_revenue?.reduce(
    (result, item) => {
      const keyName = item.key.track_name || "others";
      const percentage = item.percentage || "others";

      const index = result.findIndex((entry) => entry.key === keyName);
      const existingKeys = keysArray.filter((entry) => entry === keyName);
      if (index !== -1) {
        result.push({
          key: `${keyName} (${
            result.filter((entry) => entry.key === keyName).length + 1
          })`,
          [keyName]: item.revenue,
          track_id: item.key.track_id && item.key.track_id,
          percentage: percentage,
          isrc: item.key.isrc,
        });

        keysArray.push(`${keyName} (${existingKeys.length + 1})`);
      } else {
        result.push({
          key: keyName,
          [keyName]: item.revenue,
          track_id: item.key.track_id && item.key.track_id,
          percentage: percentage,
          isrc: item.key.isrc,
        });

        keysArray.push(keyName);
      }
      return result;
    },
    []
  );

  //Dynamic Bar Chart Height
  const barChartHeightFactor = 4;
  let barChartHeight = 65;
  if (chartData?.length > 40) {
    barChartHeight = Math.max(65, chartData?.length * barChartHeightFactor);
  }

  let legendOffset,
    maxYAxisLength = 0;
  if (chartData?.length > 0) {
    maxYAxisLength =
      chartData &&
      Math.max(
        ...chartData?.map((item) => {
          return String(item.key?.length);
        })
      );
    if (maxYAxisLength > 30) legendOffset = -115 - maxYAxisLength * 3;
    else if (maxYAxisLength > 20) legendOffset = -110 - maxYAxisLength * 2;
    else if (maxYAxisLength > 10) legendOffset = -100 - maxYAxisLength * 2;
    else if (maxYAxisLength > 0) legendOffset = -90;
  }

  const handleTrackChange = (event) => {
    setTitleName(event.target.value);
  };
  const handleISRCChange = (event) => {
    setISRC(event.target.value);
  };

  const handleKeyPress = (event, caller) => {
    if (event.key === "Enter") {
      if (caller == "trackTitle")
        setSearchClicked({ titleName: true, ISRC: false });
      if (caller == "isrc") setSearchClicked({ titleName: false, ISRC: true });
    }
  };

  function applyFilter() {
    const filter = [];
    let params = {};

    if (titleName && searchClicked.titleName) {
      filter.push(
        `["track_title.keyword","must","","wildcard","*${escapeSequencing(
          titleName
        )}*"]`
      );
    } else if (ISRC && searchClicked.ISRC) {
      filter.push(`["isrc.keyword","must","","wildcard","*${ISRC}*"]`);
    } else return;

    params = {
      filters: `[${filter}]`,
      youtube: "true",
      current_page: "raw_report",
    };

    requestTrackwiseRevenue(params);
  }

  useEffect(() => {
    clearFunction();
  }, [clearClicked]);

  useEffect(() => {
    applyFilter();
  }, [searchClicked.titleName, searchClicked.ISRC]);

  function clearFunction() {
    const filter = [];
    let params = {};

    if (
      (clearClicked.titleName && searchClicked.titleName) ||
      (clearClicked.ISRC && searchClicked.ISRC)
    ) {
      params = {
        youtube: "true",
        current_page: "raw_report",
      };
      requestTrackwiseRevenue(params);
      setSearchClicked({ titleName: false, ISRC: false });
    }
  }

  const getOptions = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    const seriesData = rows.map((item, index) => ({
      name: item.key,
      value: item.revenue,
      percentage: item.percentage,
      itemStyle: {
        color: colors[index], // Use theme colors dynamically
      },
    }));

    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.name}: ${formattedvalue} (${params.data.percentage}%)`;
        },
      },

      yAxis: {
        type: "category",
        data: nameLabels,
        axisLabel: {
          hideOverlap: true,
          rich: {
            width: 100,
            overflow: "breakAll",
          },
        },
      },
      xAxis: {
        type: "value",
        axisLabel: {
          hideOverlap: true,
        },
      },
      grid: {
        left: "18%",
        right: "5%",
      },
      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      legend: {
        show: true,
        orient: "vertical",
        icon: "circle", // Set the orientation of the legend=        icon: "circle",
        right: 0,
        top: "10%",
        type: "scroll",
        itemGap: 20,
        itemStyle: {
          borderColor: "#6c757d",
          borderWidth: 1,
        },
        itemHeight: 17,
      },

      series: [
        {
          type: "bar",
          data: seriesData,
          label: {
            formatter: (params) => {
              let val = params.value;
              let formattedvalue = formatValue(val);
              return formattedvalue;
            },
            show: true,
            position: "right",
          },
        },
      ],
    };
  };

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes popIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            }
            50% {
              transform: scale(1.05);
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
    `,
        }}
      />

      {navigator.onLine ? (
        <Grid
          container
          item
          xs={12}
          xl={12}
          lg={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          px={5}
          gap={3}
        >
          <Grid
            container
            item
            alignItems="center"
            justifyContent="center"
            sx={{
              fontSize: "24px",
            }}
          >
            <Grid item>
              <span style={{ fontWeight: "bold" }}>
                {formatName("Top Trackwise Revenue")} - Audio Streaming
              </span>
            </Grid>
            <Grid item mx={2}>
              <MuiTextField
                value={titleName ? titleName : ""}
                fieldheight="35px"
                width="15vw"
                placeholder="Search by Track title"
                onChange={handleTrackChange}
                onKeyDown={(e) => handleKeyPress(e, "trackTitle")}
                disabled={searchClicked.ISRC}
                InputProps={{
                  endAdornment: (
                    <>
                      {titleName && (
                        <CloseIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setTitleName(null);
                            if (!searchClicked.titleName) return;
                            setClearClicked({ titleName: true, ISRC: false });
                          }}
                        />
                      )}
                      <SearchIcon
                        sx={{
                          cursor: !searchClicked.ISRC ? "pointer" : "normal",
                        }}
                        onClick={() => {
                          if (!titleName) {
                            !searchClicked.ISRC && setAlertPopUp(true);
                            return;
                          }
                          setSearchClicked({ titleName: true, ISRC: false });
                        }}
                      />
                    </>
                  ),
                }}
              />
            </Grid>
            <Grid item mr={2}>
              <MuiTextField
                value={ISRC ? ISRC : ""}
                fieldheight="35px"
                width="15vw"
                placeholder="Search by ISRC"
                onChange={handleISRCChange}
                onKeyDown={(e) => handleKeyPress(e, "isrc")}
                disabled={searchClicked.titleName}
                InputProps={{
                  endAdornment: (
                    <>
                      {ISRC && (
                        <CloseIcon
                          sx={{ cursor: "pointer" }}
                          onClick={() => {
                            setISRC(null);
                            if (!searchClicked.ISRC) return;
                            setClearClicked({ titleName: false, ISRC: true });
                          }}
                        />
                      )}
                      <SearchIcon
                        sx={{
                          cursor: !searchClicked.titleName
                            ? "pointer"
                            : "normal",
                        }}
                        onClick={() => {
                          if (!ISRC) {
                            !searchClicked.titleName && setAlertPopUp(true);
                            return;
                          }
                          setSearchClicked({ titleName: false, ISRC: true });
                        }}
                      />
                    </>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <LoadingIndicator isActive={isRequesting}>
            {!isRequesting && props.trackwiseRevenue ? (
              <>
                {reportViewType === "grid" ? (
                  <DataGridFunction
                    curser={"pointer"}
                    columns={columns}
                    rows={rows}
                    rowHeight={60}
                    height={"74vh"}
                    onRowClick={(e) => {
                      if (e.row.key == "others") return;
                      setRowData(e.row);
                      setTrackRevenueSummary(true);
                    }}
                  />
                ) : (
                  <>
                    {chartData && chartData.length > 0 && (
                      <Typography
                        sx={{
                          height: `${barChartHeight}vh`,
                          marginLeft: "auto",
                          marginRight: "auto",
                          animation: "popIn 0.4s ease-in-out",
                          border: "2px solid rgba(0, 0, 0, 0.1)",
                          boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                          backgroundColor: "#FFFFFF",
                          borderRadius: "8px",
                        }}
                      >
                        {(searchClicked.titleName || searchClicked.ISRC) &&
                          chartData.length == 1 && (
                            <Typography
                              sx={{ textAlign: "center", fontWeight: "bold" }}
                              pt={1}
                            >
                              {chartData[0].key} ({chartData[0].isrc})
                            </Typography>
                          )}
                        <div>
                          <ReactEcharts
                            style={{
                              height: "61vh",
                              width: "90%",
                            }}
                            option={getOptions()}
                          />
                        </div>
                      </Typography>
                    )}
                  </>
                )}
                {!isRequesting && chartData?.length == 0 && <NoDataFound />}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Please select a filter first"}
      />
      <TrackwiseRevenueSummary
        open={openTrackRevenueSummary}
        onClose={() => {
          setTrackRevenueSummary(false);
        }}
        trackID={rowData?.track_id}
        trackName={rowData?.key}
      />
    </React.Fragment>
  );
}

function escapeSequencing(text) {
  return text.replace(/(["'\\*])/g, "\\$1");
}

export default connect(mapStateToProps)(TrackwiseRevenue);
