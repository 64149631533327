import ActionUtility from "../../utilities/ActionUtility";
import TopLanguagesEffect from "./TopLanguagesEffect";

export default class TopLanguagesAction {
  // TopLanguagesAction action with an function declaration
  static REQUEST_TOP_LANGUAGES = "TopLanguagesAction.REQUEST_TOP_LANGUAGES";
  static REQUEST_TOP_LANGUAGES_FINISHED =
    "TopLanguagesAction.REQUEST_TOP_LANGUAGES_FINISHED";

  static REQUEST_TOP_LANGUAGES_FILTER =
    "TopLanguagesAction.REQUEST_TOP_LANGUAGES_FILTER";
  static REQUEST_TOP_LANGUAGES_FILTER_FINISHED =
    "TopLanguagesAction.REQUEST_TOP_LANGUAGES_FILTER_FINISHED";

  // METHODS
  static requestTopLanguages(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLanguagesAction.REQUEST_TOP_LANGUAGES,
        TopLanguagesEffect.requestTopLanguages,
        params
      );
    };
  }

  static requestTopLanguagesFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLanguagesAction.REQUEST_TOP_LANGUAGES_FILTER,
        TopLanguagesEffect.requestTopLanguagesFilter,
        params
      );
    };
  }
}
