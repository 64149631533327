import BaseReducer from "../../utilities/BaseReducer";
import TopLabelAction from "./TopLabelAction";

export default class TopLabelFilterReducer extends BaseReducer {
  //initial state of TopLabelFilterReduser
  initialState = {
    topLabelFilter: [],
  };
  //TopLabelFilterReduser request action finish
  [TopLabelAction.REQUEST_TOPLABEL_FILTER_FINISHED](state, action) {
    return {
      ...state,
      topLabelFilter: action.payload,
    };
  }
}
