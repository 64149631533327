import React, { useEffect, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  ListItem,
  Paper,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import ClientRevenueSharePolicyAction from "../../stores/clientRevenueSharingPolicy/ClientRevenueSharePolicyAction";
import { ShadowContainer } from "../../components/componentLayout/ComponentLayout";
import { theme } from "../App";
import CircularLoadingIndicator from "../../components/loading-indicator/CircularLoadingIndicator";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function SyncLabelSharePopper(props) {
  const [syncing, setSyncing] = useState({
    youtube: false,
    others: false,
  });

  const [open, setOpen] = React.useState(false);
  const syncLabelSharePopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      syncLabelSharePopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      syncLabelSharePopperRef.current &&
      syncLabelSharePopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={syncLabelSharePopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={syncLabelSharePopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <ShadowContainer borderRadius={5}>
              <Paper
                style={{
                  width: "auto",
                  minWidth: 200,
                  height: "auto",
                  padding: 2,
                  //   boxShadow: theme.shadows,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    style={{
                      maxHeight: 250,
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                    className="scrollable"
                  >
                    <ListItem
                      className="d-flex justify-content-between"
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: theme.palette.background.default,
                        },
                      }}
                      onClick={() => {
                        setSyncing(() => {
                          let newSyncing = { ...syncing, youtube: true };
                          return newSyncing;
                        });
                        const params = {
                          revenue_report: "youtube",
                          current_page: "revenueSharePolicy",
                        };
                        props
                          .dispatch(
                            ClientRevenueSharePolicyAction.requestSyncClientRevenueSharePolicy(
                              {},
                              props.clientId,
                              params
                            )
                          )
                          .then(() => {
                            setSyncing(() => {
                              let newSyncing = { ...syncing, youtube: false };
                              return newSyncing;
                            });
                          });
                      }}
                    >
                      <span>Youtube </span>
                      <span className="mx-3">
                        {syncing.youtube && (
                          <CircularProgress color="inherit" size={15} />
                        )}
                      </span>
                    </ListItem>
                    <ListItem
                      className="d-flex justify-content-between"
                      sx={{
                        cursor: "pointer",

                        "&:hover": {
                          backgroundColor: theme.palette.background.default,
                        },
                      }}
                      onClick={() => {
                        setSyncing(() => {
                          let newSyncing = { ...syncing, others: true };
                          return newSyncing;
                        });
                        props
                          .dispatch(
                            ClientRevenueSharePolicyAction.requestSyncClientRevenueSharePolicy(
                              {},
                              props.clientId,
                              {
                                current_page: "revenueSharePolicy",
                              }
                            )
                          )
                          .then(() => {
                            setSyncing(() => {
                              let newSyncing = { ...syncing, others: false };
                              return newSyncing;
                            });
                          });
                      }}
                    >
                      <span>Others </span>
                      <span className="mx-3">
                        {syncing.others && (
                          <CircularProgress color="inherit" size={15} />
                        )}
                      </span>
                    </ListItem>
                  </div>
                </ClickAwayListener>
              </Paper>
            </ShadowContainer>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(SyncLabelSharePopper);
