import ActionUtility from "../../utilities/ActionUtility";
import TopReleaseEffect from "./TopReleaseEffect";

export default class TopReleaseAction {
  // TopReleaseAction action with an function declaration
  static REQUEST_TOPRELEASE = "TopReleaseAction.REQUEST_TOPRELEASE";
  static REQUEST_TOPRELEASE_FINISHED =
    "TopReleaseAction.REQUEST_TOPRELEASE_FINISHED";

  static REQUEST_TOPRELEASE_FILTER =
    "TopReleaseAction.REQUEST_TOPRELEASE_FILTER";
  static REQUEST_TOPRELEASE_FILTER_FINISHED =
    "TopReleaseAction.REQUEST_TOPRELEASE_FILTER_FINISHED";

  static REQUEST_PUT_TOPRELEASE = "TopReleaseAction.REQUEST_PUT_TOPRELEASE";
  static REQUEST_PUT_TOPRELEASE_FINISHED =
    "TopReleaseAction.REQUEST_PUT_TOPRELEASE_FINISHED";

  static REQUEST_POST_TOPRELEASE = "TopReleaseAction.REQUEST_POST_TOPRELEASE";
  static REQUEST_POST_TOPRELEASE_FINISHED =
    "TopReleaseAction.REQUEST_POST_TOPRELEASE_FINISHED";

  static REQUEST_DELETE_TOPRELEASE =
    "TopReleaseAction.REQUEST_DELETE_TOPRELEASE";
  static REQUEST_DELETE_TOPRELEASE_FINISHED =
    "TopReleaseAction.REQUEST_DELETE_TOPRELEASE_FINISHED";

  // METHODS
  // Organization GET function
  static requestTopRelease(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopReleaseAction.REQUEST_TOPRELEASE,
        TopReleaseEffect.requestTopRelease,
        params,
        callBack
      );
    };
  }

  static requestTopReleaseFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopReleaseAction.REQUEST_TOPRELEASE_FILTER,
        TopReleaseEffect.requestTopReleaseFilter,
        params
      );
    };
  }
  static requestPutTopRelease(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopReleaseAction.REQUEST_PUT_TOPRELEASE,
        TopReleaseEffect.requestPutTopRelease,
        data,
        id
      );
    };
  }

  static requestPostTopRelease(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopReleaseAction.REQUEST_POST_TOPRELEASE,
        TopReleaseEffect.requestPostTopRelease,
        data
      );
    };
  }

  static requestDeleteTopRelease(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopReleaseAction.REQUEST_DELETE_TOPRELEASE,
        TopReleaseEffect.requestDeleteTopRelease,
        id
      );
    };
  }
}
