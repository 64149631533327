import BaseReducer from "../../utilities/BaseReducer";
import BlogsAction from "./BlogsAction";

// BlogsReducer
export default class BlogsReducer extends BaseReducer {
  initialState = {
    blogs: [],
  };
  [BlogsAction.REQUEST_BLOGS_FINISHED](state, action) {
    return {
      ...state,
      blogs: action.payload,
    };
  }
}
