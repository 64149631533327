import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import EffectUtility from "../../utilities/EffectUtility";
import UserModel from "./models/UserModel";
import UserPostModel from "./models/UserPostModel";
import UserPutModel from "./models/UserPutModel";
import UserByIdModel from "./models/UserByIdModel";
import AdminResetPasswordPutModel from "./models/AdminResetPasswordPutModel";
import UserResetPasswordPutModel from "./models/UserResetPasswordPutModel";
import ORMDeviceResetModel from "./models/ORMDeviceResetModel";
import UserProfileImgUpdateModel from "./models/UserProfileImgUpdateModel";
import WorkingStatusUpdateModel from "./models/WorkingStatusUpdateModel";

export default class UserEffect {
  //get USER request end point
  static async requestUser(params) {
    const endpoint = environment.api.user;

    return EffectUtility.getToModel(UserModel, endpoint, params);
  }
  //   // Post api
  static async requestPostUser(data, callBack) {
    const endpoint = environment.api.user;
    let response = EffectUtility.postToModel(UserPostModel, endpoint, data);

    response.then((res) => {
      if (res.status === "success" && callBack) {
        callBack();
      }
    });

    return response;
  }

  //Put api
  static async requestPutUser(data, id) {
    const endpoint = environment.api.user + "/" + id;

    return EffectUtility.putToModel(UserPutModel, endpoint, data);
  }

  //Username Filter api
  static async requestFilterUserName() {
    const endpoint = environment.api.user;

    return EffectUtility.getToModel(UserModel, endpoint);
  }

  //get error end point
  static async requestError() {
    const endpoint = environment.api.errorExample;
    const response = await HttpUtility.get(endpoint);

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }

    return response.data;
  }

  //get USER request end point with page_limit none
  static async requestUserFilter() {
    const endpoint = environment.api.userfilter;

    return EffectUtility.getToModel(UserModel, endpoint);
  }

  // my profile single userbyId
  static async requestUserById(id) {
    const endpoint = environment.api.user + "/" + id;

    return EffectUtility.getToModel(UserByIdModel, endpoint);
  }

  // // put admin reset password
  // static async requestAdminResetPassword(data) {

  //   const endpoint = environment.api.adminResetPassword;
  //   return EffectUtility.putToModel(AdminResetPasswordPutModel, endpoint, data);
  // }

  static async requestAdminResetPasswordPut(data) {
    const endpoint = environment.api.adminResetPassword;
    return EffectUtility.putToModel(AdminResetPasswordPutModel, endpoint, data);
  }

  static async requestUserResetPassword(data) {
    const endpoint = environment.api.userResetPassword;
    return EffectUtility.putToModel(UserResetPasswordPutModel, endpoint, data);
  }

  static async requestPutUserProfileImg_upload(data) {
    const endpoint = environment.api.userProfileImgUpdate;
    return EffectUtility.putToModel(UserProfileImgUpdateModel, endpoint, data);
  }

  static async requestPutUserWorkingStatus(data) {
    const endpoint = environment.api.workingStatus;
    return EffectUtility.putToModel(WorkingStatusUpdateModel, endpoint, data);
  }

  static async requestDeviceReset(id) {
    const endpoint = environment.api.ormResetDevice + "/" + id;
    return EffectUtility.deleteToModel(ORMDeviceResetModel, endpoint, id);
  }
}
