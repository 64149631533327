import React, { useEffect } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import TerritoriesAction from "../../stores/territories/TerritoriesAction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import MuiButton from "../../components/muiButton/MuiButton";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TerritoriesAction.REQUEST_TERRITORIES,
  ]),
  territories: state.territories.territories || {},
});

const TerritoryDailog = (props) => {
  function fetchTerritoriesData() {
    let params = {
      page_limit: "none",
      current_page: "releases",
    };
    props.dispatch(TerritoriesAction.requestTerritories(params));
  }
  useEffect(() => {
    fetchTerritoriesData();
  }, []);

  function renderCountryFlag(countryCode) {
    return (
      <Typography
        component={"img"}
        width="20"
        alt=""
        src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
      />
    );
  }

  let filteredArray = {};

  //   filteredArray =props?.territories?.territory_data;

  if (props?.territories?.territory_data && props.list) {
    for (let key in props.territories.territory_data) {
      filteredArray[key] = props.territories.territory_data[key].filter(
        (item) => {
          return props?.list.includes(item.country_code);
        }
      );
    }
  }

  const separatedByCountrys = {};

  for (const key in filteredArray) {
    filteredArray[key].map((item) => {
      const continentName = item.continent_name ?? "";

      if (continentName) {
        if (!separatedByCountrys[continentName]) {
          separatedByCountrys[continentName] = [];
        }
        separatedByCountrys[continentName].push(item);
      }
    });
  }

  const numKeys =
    separatedByCountrys && Object.keys(separatedByCountrys).length;

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth={numKeys > 3 ? "md" : "sm"}
      // scroll={scroll}
    >
      <DialogTitle id="scroll-dialog-title">
        {formatName("Territories")}
      </DialogTitle>

      <DialogContent>
        <Grid container>
          {separatedByCountrys &&
            Object.entries(separatedByCountrys).map(([key, values]) => (
              <Grid item xs={numKeys > 3 ? 3 : 6} key={key}>
                <Grid container direction="column">
                  <Grid item>
                    <strong>{formatName(key)}</strong>
                  </Grid>
                  {values.map((value, index) => (
                    <Grid item key={`${key}_${index + 1}`}>
                      <p>
                        {renderCountryFlag(value.country_code)}&nbsp;
                        {value.country_name}
                      </p>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}

          {numKeys === 0 && (
            <strong>{formatName("No Territories Found")}</strong>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <MuiButton
          onClick={() => {
            props.onClose();
          }}
          name="Close"
        />
      </DialogActions>
    </Dialog>
  );
};

export default connect(mapStateToProps)(TerritoryDailog);
