import { BaseModel } from "sjs-base-model";

export default class A3ReportsMonthwiseModel extends BaseModel {
  "Net Revenue By Month" = [];

  constructor(data1) {
    super();
    this.update(data1);
  }
}
