import { BaseModel } from "sjs-base-model";

export default class AdminResetPasswordPutModel extends BaseModel {
  //USER PUT model
  message = "";
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
