import React, { useCallback, useEffect, useMemo, useState } from "react";
import DataGridFunction from "../../components/datagridComponent/datagrid";
// Pagination component
import Grid from "@mui/material/Grid";
import { Box, IconButton, useTheme, TextField, Tooltip } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import MuiButton from "../../components/muiButton/MuiButton";
import PostPlusButton from "../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ClientPost from "./ClientPost";
import ClientAction from "../../stores/client/ClientAction";
import { connect } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LanguageAction from "../../stores/language/LanguageAction";
import TimeZoneAction from "../../stores/timezone/TimeZoneAction";
import ProfileRetrieve from "./ProfileRetrieve";
import KeyIcon from "@mui/icons-material/Key";
import ResetAdminPassword from "../../components/resetAdminPassword/ResetAdminPassword";
import environment from "environment";
import LabelAction from "../../stores/label/LabelAction";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ClientKebabPopper from "../../components/poppers/ClientKebabPopper";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
// mapstatetoprops function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ClientAction.REQUEST_CLIENT,
    ClientAction.REQUEST_CLIENT_FILTER,
    LanguageAction.REQUEST_LANGUAGE,
    TimeZoneAction.REQUEST_TIMEZONE,
    LabelAction.REQUEST_LABELS_FILTER,
  ]),
  client: state.client.client || [],
  //client: state.client.client.hits || [],
  //client: state.client.hits || [],
  pagination: state.client.client || [],
  language: state.language.language || [],
  clientFilter: state.clientFilter.clientFilter || [],
  timezone: state.timezone.timezone || {},
  labelFilter: state.labelFilter.labelFilter || [],
});
function Client(props) {
  // const [showDropDowns, setShowDropDowns] = useState([
  //   "Client Name",
  //   "City",
  //   "Type of Client",
  //   "Content Delivered",
  //   "Royalty Rate-2",
  //   "Royalty Rate-3",
  //   "Royalty Rate-4",
  //   "Specific Agent Royalty Rate",
  //   "Specific Store Royalty Rate",
  //   "Specific Territory Royalty Rate",
  // ]);

  // const [selected, setSelected] = useState([
  //   "Client Name",
  //   "City",
  //   "Type of Client",
  //   "Content Delivered",
  //   "Royalty Rate-2",
  //   "Royalty Rate-3",
  //   "Royalty Rate-4",
  //   "Specific Agent Royalty Rate",
  //   "Specific Store Royalty Rate",
  //   "Specific Territory Royalty Rate",
  // ]);

  //******************************************************** SetState
  let { state } = useLocation();
  const theme = useTheme();
  const [searchClient, setsearchClient] = useState(null);
  const [Phonenumber, setPhonenumber] = useState(null);
  const [Mail, setMail] = useState(null);
  const [open, setOpen] = useState(null);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [phoneCodes, setPhoneCodes] = useState([]);

  const { isRequesting } = props;

  // *********************************************  fetch methods

  // **************************************************  UseEffect

  function fetchClient(params) {
    props.dispatch(ClientAction.requestClient(params));
  }
  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }
  function fetchlanguageFilter(param) {
    props.dispatch(LanguageAction.requestLanguageFilter(param));
  }
  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }
  function fetchtimezone() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(TimeZoneAction.requestTimeZone(params));
  }

  function fetchPhoneCodes() {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    fetch(`${environment.api.baseApi}/musiclabels_phone_codes`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        if (response) {
          response.country_codes && setPhoneCodes(response.country_codes);
        }
      });
  }

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "client",
    };
    fetchClientFilter(param);
    fetchLabelFilter(param);
    let params = {
      page_limit: `${page_limit}`,
      order_by: '[["created_on", "desc"]]',
    };

    fetchClient(params);
    fetchlanguageFilter(param);
    fetchtimezone();
    fetchPhoneCodes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);
  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const clientListHandleChange = (event) => {
    setsearchClient(event.target.value);
  };

  const phoneListHandleChange = (event) => {
    setPhonenumber(event.target.value);
  };
  const mailListHandleChange = (event) => {
    setMail(event.target.value);
  };
  // *******************************************************  handle Filter
  const handleFilter = () => {
    const filter = [];

    if (searchClient !== null) {
      filter.push(
        `["client_name.keyword","must","","wildcard","*${searchClient}*"]`
      );
    }
    if (Phonenumber !== null) {
      filter.push(
        `["phone_number.keyword","must","","wildcard","*${Phonenumber}*"]`
      );
    }
    if (Mail !== null) {
      filter.push(
        `["main_email_address.keyword","must","","wildcard","*${Mail}*"]`
      );
    }
    if (searchClient !== null || Phonenumber !== null || Mail !== null) {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchClient(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    setFilterLoader(false);
    // Clear the state
    if (searchClient !== null || Phonenumber !== null || Mail !== null) {
      setClearLoader(true);
      setsearchClient(null);
      setPhonenumber(null);
      setMail(null);
    }
    setFilterClicked(false);
    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: page_limit,
    };
    FilterClicked && fetchClient(params);
  };
  //********************************************************* pagination

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      // width: 100,
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "user_profile_url",
      headerName: "Profile",
      width: 70,
      renderCell: (params) => {
        return (
          <div>
            <Box
              className="image-container"
              style={{
                width: 37,
                maxHeight: 37,
                padding: 5,
                // backgroundColor: "#d0e9eb",
                display: "grid",
                placeItems: "center",
              }}
            >
              {/* {params?.row?.user_profile_url  ( */}
              <ProfileRetrieve
                user_profile_url={params?.row?.user_profile_url}
                params={params}
              />
              {/* ) : (
                <ImageIcon
                  style={{
                    width: "100%",
                    height: "100%",
                    color: theme.palette.primary.light,
                  }}
                />
              )} */}
            </Box>
          </div>
        );
      },
    },
    {
      field: "client_name",
      headerName: " Sub Client Name",
      width: 150,
      flex: 1.5,
    },
    {
      field: "default_language",
      headerName: "Default Language",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "default_time_zone",
      headerName: "Default Time Zone",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "first_name",
      headerName: "First Name",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "main_email_address",
      headerName: "Main Email",
      // width: 250,
      flex: 2,
    },
    {
      field: "postal_address",
      headerName: "Postal Address",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "postal_code",
      headerName: "Postal Code",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "city",
      headerName: "City",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      // width: 250,
      flex: 1,
    },
    {
      field: "created_by",
      headerName: "Created By",
      // width: 250,
      flex: 1,
    },
    {
      field: "created_on",
      headerName: "Created On",
      // width: 250,
      flex: 1,
    },
    {
      field: "royalties_email_address",
      headerName: "Royalties Email",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "type_of_client",
      headerName: "Type of Client",
      hide: true,
      // width: 250,
      // flex: 1,
    },
    {
      field: "enabled",
      headerName: "Enabled",
      flex: 0.5,
      resizable: false,
      width: 100,
    },

    {
      field: "yt_content_id",
      headerName: "Youtube content id",
      // flex: 2,
      resizable: false,
      width: 150,
      hide: true,
    },

    {
      field: "yt_original",
      headerName: "Youtube Original",
      // flex: 2,
      resizable: false,
      width: 150,
      hide: true,
    },

    {
      field: "yt_music",
      headerName: "Youtube Music",
      // flex: 2,
      resizable: false,
      width: 150,
      hide: true,
    },

    {
      field: "music_stores",
      headerName: "Music Stores",
      // flex: 2,
      resizable: false,
      width: 150,
      hide: true,
    },

    // {
    //   field: "user_profile_url",
    //   headerName: "user_profile_url",
    //   // flex: 2,
    //   resizable: false,
    //   width: 100,
    //   hide: true,
    // },

    // {
    //   field: "account_manager",
    //   headerName: "Account Manager",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "all_content_delivered",
    //   headerName: "Content Delivered",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "business_unit",
    //   headerName: "Business Unit",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "channel_manager",
    //   headerName: "Channel Manager",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "client_classification",
    //   headerName: "Client Classification",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "contact",
    //   headerName: "Name of client",
    //   // ...
    // },
    // {
    //   field: "contact",
    //   headerName: "Email Address",
    //   // ...
    // },
    // {
    //   field: "contract_end_date",
    //   headerName: "Contract End Date",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "contract_signature_date",
    //   headerName: "Contract Signature Date",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "contracting_a3_entity",
    //   headerName: "Contract A3 Entity",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "cost_centre",
    //   headerName: "Cost Centre",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "digital_marketing_manager",
    //   headerName: "Digital Marketing Manager",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "first_account_signatory",
    //   headerName: "First Account Signatory",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "main_royalty_rate",
    //   headerName: "Main Royalty Rate",
    //   // width: 250,
    //   // flex: 1,
    // },

    // {
    //   field: "neighboring_rates",
    //   headerName: "Neighbouring Rates",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "number_of_release",
    //   headerName: "Number of Release",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "project_manager",
    //   headerName: "Project Manager",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "royalty_rate_2",
    //   headerName: "Royalty Rate 2",
    //   // width: 250,
    //   // flex: 1,
    // },

    // {
    //   field: "royalty_rate_3",
    //   headerName: "Royalty Rate 3",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "royalty_rate_4",
    //   headerName: "Royalty Rate 4",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "specific_agent_royalty_rate",
    //   headerName: "Specific agent Royalty Rate",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "specific_store_royalty_rate",
    //   headerName: "Specific store Royalty Rate",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "specific_territory_royalty_rate",
    //   headerName: "Specific territory Royalty Rate",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "starting_event",
    //   headerName: "Starting Event",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "turn_over_begin",
    //   headerName: "Turn Over Begin",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "turn_over_last_quarter",
    //   headerName: "Turn over last Quarter",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "last_created",
    //   headerName: "Last Created",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "main_music_genre",
    //   headerName: "Main Music Genre",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "mobile_number",
    //   headerName: "Mobile Number",
    //   // width: 250,
    //   // flex: 1,
    // },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   // width: 250,
    //   // flex: 1,
    // },

    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      hide: false,
      flex: 0.5,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpen(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
            title="Edit"
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
    // {
    //   field: "reset_password",
    //   headerName: "Reset",
    //   width: 150,
    //   flex: 0.5,
    //   renderCell: (params) => (
    //     <IconButton
    //       title="Reset"
    //       onClick={(event) => {
    //         //event.ignore = true;
    //         setEditRowsModel(params.row);
    //         setOpenPassword(true);
    //       }}
    //       sx={{ border: "0" }}
    //     >
    //       <KeyIcon />
    //     </IconButton>
    //   ),
    // },
    {
      field: "more",
      type: "actions",
      headerName: (
        <Tooltip title="More Options" arrow>
          <MoreVertIcon
            style={{
              color: "#2a5e6c",
              height: "20px",
              width: "20px",
            }}
          />
        </Tooltip>
      ),
      flex: 0.5,
      cellClassName: "actions",
      renderCell: (params) => (
        <div>
          <ClientKebabPopper
            popperRowData={params.row}
            clientID={params.row?.id}
            allowMobileLogin={params.row?.allow_mobile_login}
          >
            <MoreVertIcon
              style={{
                color: "#2a5e6c",
                height: "20px",
                width: "20px",
                cursor: "pointer",
              }}
            />
          </ClientKebabPopper>
        </div>
      ),
    },
  ];

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setFilterLoader(false);
    setClearLoader(false);
    const filter = [];
    let params = {};

    if (searchClient !== null) {
      filter.push(
        `["client_name.keyword","must","","wildcard","*${searchClient}*"]`
      );
    }

    if (Phonenumber !== null) {
      filter.push(
        `["phone_number.keyword","must","","wildcard","*${Phonenumber}*"]`
      );
    }
    if (Mail !== null) {
      filter.push(
        `["main_email_address.keyword","must","","wildcard","*${Mail}*"]`
      );
    }
    if (
      searchClient !== null ||
      Phonenumber !== null ||
      (Mail !== null && FilterClicked)
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["client_name.keyword", "asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,

        order_by: `[["client_name.keyword", "asc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchClient(params);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];
  if (props.client && props.client.hits) {
    props.client.hits.hits &&
      props.client.hits.hits.length > 0 &&
      props.client.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          _index: item._index,
          _score: item._score,
          client_name: item._source.client_name,
          created_by: item._source.created_by.username,
          created_on: new Date(item._source.created_on).toLocaleString(),
          country: item._source.country,
          default_language: item._source.default_language,
          default_time_zone: item._source.default_time_zone,
          enabled: item._source.enabled === true ? "Enabled" : "Disabled",
          yt_content_id:
            item._source.yt_content_id && item._source.yt_content_id === true
              ? "Enabled"
              : "Disabled",
          yt_original:
            item._source.yt_original && item._source.yt_original === true
              ? "Enabled"
              : "Disabled",
          yt_music:
            item._source.yt_music && item._source.yt_music === true
              ? "Enabled"
              : "Disabled",
          music_stores:
            item._source.music_stores && item._source.music_stores === true
              ? "Enabled"
              : "Disabled",
          allow_mobile_login:
            item._source.allow_mobile_login &&
            item._source.allow_mobile_login === true
              ? "Enabled"
              : "Disabled",
          first_name: item._source.first_name,
          last_name: item._source.last_name,
          main_email_address: item._source.main_email_address,
          postal_address: item._source.postal_address,
          postal_code: item._source.postal_code,
          city: item._source.city,
          phone_number: item._source.phone_number,
          royalties_email_address: item._source.royalties_email_address,
          type_of_client: item._source.type_of_client,
          user_profile_url: item._source.user_profile_url,
          revenue_sharing: item._source.revenue_sharing,
          username: item._source.username,
          owned_labels: item._source.owned_labels,
          account_holder_name: item._source?.bank_details?.account_holder_name,
          account_number: item._source?.bank_details?.account_number,
          bank_name: item._source?.bank_details?.bank_name,
          ifsc: item._source?.bank_details?.ifsc,
          gst_no: item._source?.bank_details?.gst_no,
          bank_address: item._source?.bank_details?.bank_address,
          branch_name: item._source?.bank_details?.branch_name,
          pan_no: item._source?.bank_details?.pan_no,
          bank_gst_no_checkbox:
            item?._source?.bank_details?.bank_gst_no_checkbox,
          // account_manager: item._source.account_manager,

          // all_content_delivered: item._source.all_content_delivered,
          // business_unit: item._source.business_unit,
          // channel_manager: item._source.channel_manager,
          // client_classification: item._source.client_classification,

          // contract_end_date: item._source.contract_end_date,
          // contract_signature_date: item._source.contract_signature_date,
          // contracting_a3_entity: item._source.contracting_a3_entity,

          // cost_centre: item._source.cost_centre,
          // digital_marketing_manager: item._source.digital_marketing_manager,
          // first_account_signatory: item._source.first_account_signatory,
          // main_royalty_rate: item._source.main_royalty_rate,

          // neighboring_rates: item._source.neighboring_rates,
          // number_of_release: item._source.number_of_release,
          // project_manager: item._source.project_manager,
          // royalty_rate_2: item._source.royalty_rate_2,
          // royalty_rate_3: item._source.royalty_rate_3,
          // royalty_rate_4: item._source.royalty_rate_4,
          // specific_agent_royalty_rate: item._source.specific_agent_royalty_rate,
          // specific_store_royalty_rate: item._source.specific_store_royalty_rate,
          // specific_territory_royalty_rate:
          //   item._source.specific_territory_royalty_rate,
          // starting_event: item._source.starting_event,
          // turn_over_begin: item._source.turn_over_begin,
          // turn_over_last_quarter: item._source.turn_over_last_quarter,
          // last_created: item._source.last_created,
          // main_music_genre: item._source.main_music_genre,
          // mobile_number: item._source.mobile_number,
          // status: item._source.status,
        });
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleFilter();
    }
  };

  let typeclient = [];
  if (props.clientFilter.hits)
    props.clientFilter.hits.hits.forEach((item) => {
      typeclient.push(item._source.type_of_client);
    });
  const typeclientuniqueList = ["Individual", "Organization"];

  let languageList = [];

  if (props.language && props.language.list_of_language_data) {
    const languageData = props.language.list_of_language_data;

    Object.keys(languageData).forEach((abbreviation) => {
      const language = languageData[abbreviation];
      languageList.push(language.name);
    });
  }

  let timezonelist = [];
  props.timezone &&
    props.timezone.timezonedata &&
    Object.values(props.timezone.timezonedata).length > 0 &&
    Object.values(props.timezone.timezonedata).map((valuedata) => {
      timezonelist.push(valuedata.abbr);
    });
  timezonelist.sort();

  timezonelist = [...new Set(timezonelist)];

  let nameslist = [];
  if (
    props.language &&
    typeof props.language.list_of_language_data === "object"
  ) {
    for (let key in props.language.list_of_language_data) {
      const item = props.language.list_of_language_data[key];
      const name = item.name;
      nameslist.push(name);
    }
  }

  let labelList = [];
  if (props.labelFilter.hits) {
    props.labelFilter.hits.hits.forEach((value) => {
      labelList.push({
        id: value._id,
        label_name: value._source.label_name,
      });
    });
  }

  const clientDataGrid = useMemo(
    () => (
      <DataGridFunction
        rows={rows}
        columns={columns}
        rowHeight={60}
        pagelength={props.pagination.total_page_no}
        page={props.pagination.current_page_no}
        handleChange={handleChange}
      />
    ),
    [props]
  );

  return (
    <React.Fragment>
      {/* Tag post modal screen */}
      <Box>
        <ClientPost
          open={open}
          onSubmit={() => {}}
          onClose={() => setOpen(false)}
          projectEditData={editRowsModel}
          edit={edit}
          typeclientuniqueList={typeclientuniqueList}
          timezonelist={timezonelist}
          nameslist={nameslist}
          phoneCodes={phoneCodes}
          labelList={labelList}
          // cityuniqueList={cityuniqueList}
          // contentDeliveredList={contentDeliveredList}
        />
        {/*************************************************** Tag Filter */}

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="space-between"
          pr={1}
          pb={1}
        >
          <Grid item container xl={5} lg={5} md={8} sm={8} xs={8} gap={2}>
            {/* {selected && selected.includes("Client Name") && ( */}
            <Grid item lg={3.4} md={7.4} sm={7.4} mt={1}>
              <TextField
                variant="outlined"
                placeholder="Search Client Name"
                sx={{ width: "100%" }}
                value={searchClient ? searchClient : ""}
                size="small"
                onChange={clientListHandleChange}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="search" onClick={handleFilter}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={3.4} md={7.4} sm={7.4} mt={1}>
              <TextField
                variant="outlined"
                placeholder="Search Phone Name"
                sx={{ width: "100%" }}
                value={Phonenumber ? Phonenumber : ""}
                size="small"
                onChange={phoneListHandleChange}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="search" onClick={handleFilter}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={3.4} md={7.4} sm={7.4} mt={1}>
              <TextField
                variant="outlined"
                placeholder="Search Mail Address"
                sx={{ width: "100%" }}
                value={Mail ? Mail : ""}
                size="small"
                onChange={mailListHandleChange}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="search" onClick={handleFilter}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xl={7}
            lg={7}
            md={10}
            sm={11}
            mt={{ md: 2, lg: 2, sm: 2, xl: 2 }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={2}
              justifyContent="flex-start"
            >
              <Grid item xl={2} lg={2} md={2} sm={3}>
                <MuiButton
                  loading={filterLoader && isRequesting}
                  name={"Filter"}
                  width="100%"
                  onClick={handleFilter}
                />
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={3}>
                <MuiButton
                  width="100%"
                  loading={clearLoader && isRequesting}
                  name={"Clear"}
                  onClick={ClearState}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={2} sm={3}>
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  editMode={editMode}
                />
              </Grid>
              {/* <Grid item xl={2} lg={2} md={2.2} sm={1.5} mt={1} pl={2}>
                <IconButton
                  sx={{
                    backgroundColor: theme.palette.background.paper,
                  }}
                  onClick={(e) => handleClick(e)}
                >
                  {!openPopper ? (
                    <MoreVertIcon sx={{ color: theme.palette.primary.main }} />
                  ) : (
                    <ClearIcon sx={{ color: theme.palette.primary.main }} />
                  )}
                </IconButton>
                <Popper
                  sx={{ width: 200 }}
                  open={openPopper}
                  anchorEl={popperAnchor}
                >
                  <Paper sx={{ p: 1.2, m: 1 }}>
                    {showDropDowns.map((item, index) => (
                      <>
                        <Box
                          component="icon"
                          style={{ display: "flex", width: "100%" }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                value={item}
                                size="small"
                                checked={selected && selected.includes(item)}
                                label={item}
                                onChange={handleChangeDropDowns}
                              />
                            }
                            label={item}
                          />
                        </Box>
                      </>
                    ))}
                  </Paper>
                </Popper>
              </Grid> */}
            </Grid>
          </Grid>
          <ResetAdminPassword
            rowData={editRowsModel}
            open={openPassword}
            onClose={() => {
              setOpenPassword(false);
            }}
          />
        </Grid>
        {/* </Grid> */}
        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid
                conatiner
                item
                lg={12}
                xl={12}
                md={12}
                sm={12}
                xs={12}
                pr={1}
              >
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {clientDataGrid}
                </LoadingIndicator>
              </Grid>
            </Grid>
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(Client);
