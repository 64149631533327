import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

export default class OrmNotificationGetByIdModel extends BaseModel {
  hits = {};
  max_score = 0;
  total = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
