import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  TextField,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiButton from "../../components/muiButton/MuiButton";
import SearchIcon from "@mui/icons-material/Search";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import { connect } from "react-redux";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { faIndustry, faTrash } from "@fortawesome/free-solid-svg-icons";
import RefreshIcon from "@mui/icons-material/Refresh";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useNavigate, useLocation } from "react-router";
import ClientAction from "../../stores/client/ClientAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import UserResourceAction from "../../stores/userResource/UserResourceAction";
import UserAccessPost from "./UserAccessPost";
import ArtistAction from "../../stores/artist/ArtistAction";
import UserAction from "../../stores/user/UserAction";
import routes from "../../components/Route/routecoll";
import ResourceAction from "../../stores/resource/ResourceAction";
import LabelAction from "../../stores/label/LabelAction";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ClientAction.REQUEST_CLIENT_FILTER,
    UserResourceAction.REQUEST_USER_RESOURCE,
    UserResourceAction.REQUEST_USER_RESOURCE_FILTER,
    ArtistAction.REQUEST_ARTIST_FILTER,
    ResourceAction.REQUEST_RESOURCE,
    LabelAction.REQUEST_LABELS_FILTER,
  ]),
  userResource: state.userResource.userResource || [],
  userResourceFilter: state.userResourceFilter.userResourceFilter || [],
  pagination: state.userResource.userResource || [],
  clientFilter: state.clientFilter.clientFilter || [],
  artistFilter: state.artistFilter.artistFilter || [],
  resource: state.resource.resource || [],
  labelFilter: state.labelFilter.labelFilter || [],
});
function UserAccess(props) {
  const { isRequesting } = props;
  const [access, setAccess] = useState(null);
  const [accountstatus, setAccountstatus] = useState(null);
  const [permission, setPermission] = useState(null);
  const location = useLocation();
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [open, setOpen] = React.useState(false);
  const [user, setUser] = React.useState(null);

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
    };
    fetchClientFilter(param);
    fetchartistFilter(param);
    fetchLabelFilter(param);
    fetchUserResource(param);
    fetchUserResourceFilter(param);
    fetchResource();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }

  function fetchResource() {
    props.dispatch(ResourceAction.requestResource());
  }

  function fetchUserResource(param) {
    props.dispatch(UserResourceAction.requestUserResource(param));
  }

  function fetchUserResourceFilter(param) {
    props.dispatch(UserResourceAction.requestUserResourceFilter(param));
  }

  function fetchartistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);
  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const navigate = useNavigate();

  const AccesslistHandleChange = (event, access) => {
    if (access != null) {
      setAccess(access);
    } else {
      setAccess(null);
    }
  };

  const AccountStatuslistHandleChange = (event, accountstatus) => {
    if (accountstatus != null) {
      setAccountstatus(accountstatus);
    } else {
      setAccountstatus(null);
    }
  };

  const PermissionlistHandleChange = (event, permission) => {
    if (permission != null) {
      setPermission(permission);
    } else {
      setPermission(null);
    }
  };
  const accesslist = ["One Release", "New Release", "Multiple Release"];

  const accountlist = ["pending connection", "password created"];
  const permissionlist = ["advenced user", "standard user"];

  let client_list = [];

  props.clientFilter?.hits?.hits?.map((item) => {
    client_list.push({
      id: item._id,
      name: item._source.client_name,
    });
  });

  let artist_list = [];

  props.artistFilter?.hits?.hits?.map((item) => {
    artist_list.push({
      id: item._id,
      name: item._source.artist_name,
    });
  });

  artist_list.push({
    id: "*",
    name: "All",
  });

  let label_list = [];

  props.labelFilter?.hits?.hits?.map((item) => {
    label_list.push({
      id: item._id,
      name: item._source.label_name,
    });
  });

  label_list.push({
    id: "*",
    name: "All",
  });

  function formatString(str) {
    // Remove the forward slash
    var formattedStr = str && str.replace("/", "");

    // Replace underscores with spaces
    formattedStr = formattedStr && formattedStr.split("_").join(" ");

    // Capitalize the first letter
    formattedStr =
      formattedStr &&
      formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

    return formattedStr;
  }

  let resourceList = [];

  props.resource?.hits?.hits?.map((item, index) => {
    resourceList.push({
      id: item._id,
      resource_id: item._source.resource_name,
      resource_name: formatString(item._source.resource_name),
    });
  });

  let page_mapping_list = [];

  if (routes && routes.length !== 0) {
    routes.data
      .filter((item) => item.category !== "Auth")
      .map((item) => {
        page_mapping_list.push({
          page_name: item.title,
          page_id: item.id,
        });
      });
  }

  const removeDuplicateObjects = (arr) => {
    const uniqueIds = {};
    return arr.filter((item) => {
      if (!uniqueIds[item.page_id]) {
        uniqueIds[item.page_id] = true;
        return true;
      }
      return false;
    });
  };

  page_mapping_list = removeDuplicateObjects(page_mapping_list);

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
      flex: 1,
    },
    {
      field: "client_id",
      headerName: "Client Name",
      width: 120,
      flex: 3,
      resizable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        return params?.row?.client_id?.client_name;
      },
    },

    {
      field: "user_id",
      headerName: "Sub Client Name",
      width: 120,
      flex: 3,

      resizable: false,
      align: "center",
      headerAlign: "center",
      hide: true,
    },

    {
      field: "user_id_string",
      headerName: "Sub Client Name",
      width: 120,
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 3,
    },

    {
      field: "artists",
      headerName: "Artists",
      width: 120,
      resizable: false,
      align: "center",
      headerAlign: "center",
      hide: true,
    },

    {
      width: 50,
      field: "actions",
      type: "actions",
      flex: 1,
      headerName: (
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        ></FontAwesomeIcon>
      ),
      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setEditRowsModel(params.row);
            setOpen(true);
            // navigate(`/userResource/${params.id}`);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
    {
      width: 50,
      flex: 1,
      field: "delete",
      type: "actions",
      headerName: (
        <FontAwesomeIcon
          icon={faTrash}
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        ></FontAwesomeIcon>
      ),
      //
      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Delete"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            if (params?.row?.id) {
              props
                .dispatch(
                  UserResourceAction.requestDeleteUserResource(params.row.id)
                )
                .then(() => {
                  const params = {
                    order_by: '[["created_on", "desc"]]',
                    page_limit: page_limit,
                  };
                  fetchUserResource(params);
                  const filterparam = {
                    page_limit: "none",
                    order_by: `[["created_on","desc"]]`,
                  };

                  fetchUserResourceFilter(filterparam);
                });
            }
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const rows = [];

  props.userResource?.hits?.hits?.map((item, index) => {
    rows.push({
      sl_no: item._id
        ? props.pagination.current_page_no * page_limit + ++index
        : "",
      id: item._id,
      client_id: item._source.client_id,
      user_id: item._source.user_id,
      user_id_string: item._source.user_id.client_name,
      artists: item._source.artists,
      labels: item._source.labels,
      page_ids: item._source.page_ids,
      exclude: item._source.exclude,

      // user_id: item._source.user_id,
    });
  });

  function applyFilter() {}

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      applyFilter();
    }
  };

  const handleSearchChange = (event, value) => {
    setUser(value);
  };

  useEffect(() => {
    let params = {};
    const filter = [];

    if (user !== null) {
      filter.push(`["user_id.keyword","must","","match","${user.id}"]`);
    }

    params = {
      filters: `[${filter}]`,
      order_by: '[["created_on", "desc"]]',
    };

    props.dispatch(UserResourceAction.requestUserResource(params));
  }, [user]);

  return (
    <React.Fragment>
      {open && (
        <UserAccessPost
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          artist_list={artist_list}
          client_list={client_list}
          edit={edit}
          accessEditData={editRowsModel}
          resourceList={resourceList}
          label_list={label_list}
          page_mapping_list={page_mapping_list}
        />
      )}

      {!open && (
        <Box sx={{ paddingTop: 3 }}>
          <Grid
            display="flex"
            justifyContent="center"
            container
            item
            lg={12}
            md={12}
            sm={12}
          >
            <Grid
              item
              container
              lg={11}
              sm={12}
              md={12}
              xl={11}
              className="d-flex justify-content-between"
            >
              <Grid item lg={7} md={10} sm={12} container gap={1}>
                <Grid item lg={2} md={3} sm={3}>
                  <Typography>Search by User:</Typography>
                </Grid>
                <Grid item lg={6} md={8} sm={8}>
                  {/* <MuiTextField
                    variant="outlined"
                    placeholder={"Search By User"}
                    fieldheight="33px"
                    sx={{ width: "100%" }}
                    value={user}
                    size="small"
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyPress}
                    // InputProps={{
                    //   endAdornment: <SearchIcon />,
                    // }}
                  /> */}

                  <AutoCompleteComponent
                    value={user}
                    list={client_list}
                    textFieldProps={{
                      placeholder: "Search By User",
                    }}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      //setting value property to custom value
                      option.name === value.name
                    }
                    renderOption={(props, item) => (
                      <li {...props} key={item.id}>
                        {item.name}
                      </li>
                    )}
                    handleChange={handleSearchChange}
                    size="small"
                    width="100%"
                  />
                </Grid>
              </Grid>
              <Grid item lg={2} md={2} sm={2}>
                <MuiButton
                  type="submit"
                  name="Add a new user"
                  width="100%"
                  onClick={() => {
                    setOpen(true);
                    setEdit(false);
                  }}
                />
              </Grid>
            </Grid>
            {/* <Grid container lg={11} sm={12} md={12} xl={11}>
              <Grid item lg={6} xl={6} md={6} sm={6}>
                <br />
                <br />
                <Grid item lg={12} md={12} sm={12} container gap={1}>
                  <Grid item lg={3} md={3} sm={3}>
                    <Typography>Search by User:</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <MuiTextField
                      variant="outlined"
                      placeholder={"Search By User"}
                      fieldheight="33px"
                      sx={{ width: "100%" }}
                      value={user}
                      size="small"
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyPress}
                      InputProps={{
                        endAdornment: <SearchIcon />,
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <Grid item lg={12} md={12} sm={12} container gap={1}>
                  <Grid item lg={3} md={3} sm={3}>
                    <Typography>This user has access to::</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <AutoCompleteComponent
                      value={access}
                      list={accesslist}
                      textFieldProps={{
                        placeholder: "Access List",
                      }}
                      handleChange={AccesslistHandleChange}
                      size="small"
                      width="100%"
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        // stringify: (option) => option.subgenre_name,
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} xl={6} md={6} sm={6}>
                <br />
                <br />
                <Grid item lg={12} md={12} sm={12} container gap={1}>
                  <Grid item lg={3} md={3} sm={3}>
                    <Typography>Account Status:</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <AutoCompleteComponent
                      value={accountstatus}
                      list={accountlist}
                      textFieldProps={{
                        placeholder: "All",
                      }}
                      handleChange={AccountStatuslistHandleChange}
                      size="small"
                      width="100%"
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        // stringify: (option) => option.subgenre_name,
                      })}
                    />
                  </Grid>
                </Grid>
                <br />
                <br />
                <Grid item lg={12} md={12} sm={12} container gap={1}>
                  <Grid item lg={3} md={3} sm={3}>
                    <Typography>Permission Level:</Typography>
                  </Grid>
                  <Grid item lg={8} md={8} sm={8}>
                    <AutoCompleteComponent
                      value={permission}
                      list={permissionlist}
                      textFieldProps={{
                        placeholder: "All",
                      }}
                      //   multiple
                      //   getOptionLabel={(option) => (option ? option : null)}
                      handleChange={PermissionlistHandleChange}
                      size="small"
                      width="100%"
                      filterOptions={createFilterOptions({
                        matchFrom: "start",
                        // stringify: (option) => option.permission,
                      })}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>

          <br />
          <br />
          <Grid
            display="flex"
            justifyContent="center"
            container
            item
            lg={12}
            md={12}
            sm={12}
          >
            <Grid item lg={11} md={12} sm={12} xs={12}>
              {/* loading icon */}
              <LoadingIndicator isActive={isRequesting}>
                {/* datagrid Component */}

                <DataGridFunction
                  columns={columns}
                  rows={rows}
                  rowHeight={60}
                  //   pagelength={props.pagination.total_page_no}
                  //   page={props.pagination.current_page_no}
                  //   handleChange={handleChange}
                />
              </LoadingIndicator>
            </Grid>
          </Grid>
        </Box>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(UserAccess);
