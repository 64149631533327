import React, { useEffect, useState } from "react";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TopTerritoriesAction from "../../../stores/TopTerritories/TopTerritoriesAction";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import * as echarts from "echarts";
import * as d3 from "d3-geo";
import worldJson from "../worldJson.json";
import ErrorBoundary from "../../../components/ErrorBoundary";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  topTerritoriesRequesting: selectRequesting(state, [
    TopTerritoriesAction.REQUEST_TOP_TERRITORIES,
  ]),
  top_territories_report:
    state.top_territories_report.top_territories_report || [],
});
function TerritoryReport(props) {
  const { topTerritoriesRequesting } = props;

  const [error, setError] = useState(null);
  const [mapReady, setMapReady] = useState(false);

  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };

  const TopTerritoriesrows = [];

  props?.top_territories_report?.top_territories?.map((item, index) => {
    TopTerritoriesrows.push({
      sl_no: ++index,
      id: ++index,
      count: item.count,

      percent: item.percentage.toFixed(2),
      country_name: item.key ? item.key.country_name : "",
      country_code: item.key ? item.key.country_code : "",
    });
  });

  const seriesData = TopTerritoriesrows.map((item) => ({
    name: item.country_name,
    value: item.count,
    percentage: item.percent,
    country_code: item?.country_code,
  }));
  const maxValue = Math.max(...seriesData.map((item) => item.value));
  const minValue = Math.min(...seriesData.map((item) => item.value));

  const formatDataView = (opt) => {
    const axisData = opt.series[0].data.map((item) => item.name);
    const series = opt.series[0].data.map((item) => formatValue(item.value));

    let table =
      '<table style="width:100%;text-align:center;border-collapse:collapse;"><thead><tr>' +
      '<th style="border:1px solid #ddd;padding:8px;">Top Territory</th>' +
      '<th style="border:1px solid #ddd;padding:8px;">Value</th>' +
      "</tr></thead><tbody>";

    for (let i = 0, l = axisData.length; i < l; i++) {
      table +=
        "<tr>" +
        '<td style="border:1px solid #ddd;padding:8px;">' +
        axisData[i] +
        "</td>" +
        '<td style="border:1px solid #ddd;padding:8px;">' +
        series[formatValue(i)] +
        "</td>" +
        "</tr>";
    }
    table += "</tbody></table>";
    return table;
  };

  const getOption = () => {
    try {
      const projection = d3.geoMercator();

      return {
        title: {
          left: "right",
        },
        tooltip: {
          trigger: "item",
          showDelay: 0,
          transitionDuration: 0.2,
          formatter: function (params) {
            let formattedValue = formatValue(params.value);
            return `${params.name}: ${formattedValue}`;
          },
        },
        visualMap: {
          left: "right",
          min: minValue,
          max: maxValue,
          inRange: {
            color: [
              "#969393",
              "#08c7c4",
              "#4175a8",
              "#38808e",
              "#c19c58",
              "#c67969",
              "#44a076",
            ],
          },
          text: ["High", "Low"],
          calculable: true,
        },
        toolbox: {
          show: true,
          left: "left",
          top: "top",
          feature: {
            dataView: {
              readOnly: false,
              optionToContent: function (opt) {
                return formatDataView(opt);
              },
            },
            saveAsImage: {},
          },
        },
        series: [
          {
            name: "Top Territories",
            type: "map",
            map: "world",
            projection: {
              project: function (point) {
                return projection(point);
              },
              unproject: function (point) {
                return projection.invert(point);
              },
            },
            emphasis: {
              label: {
                show: true,
              },
            },
            data: seriesData,
          },
        ],
      };
    } catch (e) {
      setError(`Error generating chart options: ${e.message}`);
      return {};
    }
  };

  useEffect(() => {
    echarts.registerMap("world", worldJson);
    setMapReady(true);
  }, []);

  const onChartClick = (params) => {};

  return !topTerritoriesRequesting ? (
    <ErrorBoundary message="Error in loading report">
      <Box style={{ width: "100%", height: "100%" }}>
        {error && <div style={{ color: "red" }}>{error}</div>}
        {mapReady ? (
          <ReactEcharts
            option={getOption()}
            style={{ width: "100%", height: "100%" }}
            onEvents={{ click: onChartClick }}
          />
        ) : null}
      </Box>
    </ErrorBoundary>
  ) : (
    <LoadingIndicator isActive={topTerritoriesRequesting}>
      <Box
        sx={{
          width: "100%",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingBgImage width={"50%"} />
      </Box>
    </LoadingIndicator>
  );
}

export default connect(mapStateToProps)(TerritoryReport);
