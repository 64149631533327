import LogoutEffect from "./LogoutEffect";
import ActionUtility from "../../utilities/ActionUtility";

export default class LogoutAction {
  static REQUEST_LOGOUT = "LogoutAction.REQUEST_LOGOUT";
  static REQUEST_LOGOUT_FINISHED = "LogoutAction.REQUEST_LOGOUT_FINISHED";

  static token = localStorage.getItem("Auth");

  static requestLogout(Access_token, refresh_token) {
    return async (dispatch) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        LogoutAction.REQUEST_LOGOUT,
        LogoutEffect.requestLogout,
        Access_token,
        refresh_token
      );
    };
  }
}
