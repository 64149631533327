import { BaseModel } from "sjs-base-model";

export default class YTRevenueReportGetModel extends BaseModel {
  _shards = {};
  current_page_no = 0;
  hits = {};
  total_page_no = 0;
  timed_out = false;
  took = 0;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
