import ActionUtility from "../../utilities/ActionUtility";
import TimeZoneEffect from "./TimeZoneEffect";

export default class TimeZoneAction {
  // TimeZoneAction action with an function declaration
  static REQUEST_TIMEZONE = "TimeZoneAction.REQUEST_TIMEZONE";
  static REQUEST_TIMEZONE_FINISHED = "TimeZoneAction.REQUEST_TIMEZONE_FINISHED";

  static REQUEST_TIMEZONE_FILTER = "TimeZoneAction.REQUEST_TIMEZONE_FILTER";
  static REQUEST_TIMEZONE_FILTER_FINISHED =
    "TimeZoneAction.REQUEST_TIMEZONE_FILTER_FINISHED";

  // METHODS
  // TIMEZONE GET function
  static requestTimeZone(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TimeZoneAction.REQUEST_TIMEZONE,
        TimeZoneEffect.requestTimeZone,
        params
      );
    };
  }

  static requestTimeZoneFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TimeZoneAction.REQUEST_TIMEZONE_FILTER,
        TimeZoneEffect.requestTimeZoneFilter,
        params
      );
    };
  }
}
