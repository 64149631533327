import ActionUtility from "../../utilities/ActionUtility";
import ArtistEffect from "./ArtistEffect";

export default class ArtistAction {
  // ArtistAction action with an function declaration
  static REQUEST_ARTIST = "ArtistAction.REQUEST_ARTIST";
  static REQUEST_ARTIST_FINISHED = "ArtistAction.REQUEST_ARTIST_FINISHED";

  static REQUEST_ARTIST_FILTER = "ArtistAction.REQUEST_ARTIST_FILTER";
  static REQUEST_ARTIST_FILTER_FINISHED =
    "ArtistAction.REQUEST_ARTIST_FILTER_FINISHED";

  static REQUEST_PUT_ARTIST = "ArtistAction.REQUEST_PUT_ARTIST";
  static REQUEST_PUT_ARTIST_FINISHED =
    "ArtistAction.REQUEST_PUT_ARTIST_FINISHED";

  static REQUEST_POST_ARTIST = "ArtistAction.REQUEST_POST_ARTIST";
  static REQUEST_POST_ARTIST_FINISHED =
    "ArtistAction.REQUEST_POST_ARTIST_FINISHED";

  // METHODS
  // Organization GET function
  static requestArtist(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ArtistAction.REQUEST_ARTIST,
        ArtistEffect.requestArtist,
        params
      );
    };
  }

  static requestArtistFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ArtistAction.REQUEST_ARTIST_FILTER,
        ArtistEffect.requestArtistFilter,
        params
      );
    };
  }
  static requestPutArtist(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ArtistAction.REQUEST_PUT_ARTIST,
        ArtistEffect.requestPutArtist,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostArtist(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ArtistAction.REQUEST_POST_ARTIST,
        ArtistEffect.requestPostArtist,
        data
      );
    };
  }
}
