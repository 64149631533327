import BaseReducer from "../../utilities/BaseReducer";
import SubGenreAction from "./SubGenreAction";

export default class SubGenreFilterReducer extends BaseReducer {
  //initial state of StatusFilterReduser
  initialState = {
    subgenreFilter: [],
  };
  //StatusFilterReduser request action finish
  [SubGenreAction.REQUEST_SUBGENRE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      subgenreFilter: action.payload,
    };
  }

  [SubGenreAction.REQUEST_SUBGENRE_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      subgenreFilter: action.payload,
    };
  }
}
