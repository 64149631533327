import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import UserResourceModel from "./model/UserResourceModel";
import UserResourcePutModel from "./model/UserResourcePutModel";
import UserResourcePostModel from "./model/UserResourcePostModel";

// UserResourceEffect
export default class UserResourceEffect {
  // calling an API according to the userResource model
  // Get method for the
  static async requestUserResource(params) {
    const endpoint = environment.api.userResource;
    return EffectUtility.getToModel(UserResourceModel, endpoint, params);
  }

  static async requesUserResourceClear() {
    return {};
  }
  static async requestUserResourceFilterClear() {
    return {};
  }
  // Get method for the userResource filter
  static async requestUserResourceFilter(params) {
    const endpoint = environment.api.userResource;
    return EffectUtility.getToModel(UserResourceModel, endpoint, params);
  }

  // put method for the userResource
  static async requestPutUserResource(data, id) {
    const endpoint = environment.api.userResource + "/" + id;
    return EffectUtility.putToModel(UserResourcePutModel, endpoint, data);
  }

  // post method for the userResource
  static async requestPostUserResource(data) {
    const endpoint =
      environment.api.userResource + "/add" + "?current_page=Labels";
    return EffectUtility.postToModel(UserResourcePostModel, endpoint, data);
  }
  static async requestDeleteUserResource(id) {
    const endpoint = environment.api.userResource + "/" + id;
    return EffectUtility.deleteToModel(UserResourcePostModel, endpoint);
  }
}
