import React from "react";
import "./NoDataFound.css";

// No data found
export default function NoDataFound() {
  return (
    <div className="nodataFoundContainer">
      <p className="nodataFoundText">No data found</p>
    </div>
  );
}
