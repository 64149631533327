import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TimeZoneModel from "./model/TimeZoneModel";

// TimeZoneEffect
export default class TimeZoneEffect {
  // calling an API according to the TimeZoneEffect model
  static async requestTimeZone(params) {
    const endpoint = environment.api.timezone;
    return EffectUtility.getToModel(TimeZoneModel, endpoint, params);
  }

  // Get method for the TimeZone filter
  static async requestTerritoriesFilter(params) {
    const endpoint = environment.api.timezone;
    return EffectUtility.getToModel(TimeZoneModel, endpoint, params);
  }
}
