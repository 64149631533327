import environment from "environment";
import RevenueClaimGetModel from "./models/RevenueClaimGetModel";
import ClientRevenueBalanceGetModel from "./models/ClientRevenueBalanceGetModel";
import EffectUtility from "../../utilities/EffectUtility";
import RevenueClaimPostModel from "./models/RevenueClaimPostModel";

export default class RevenueClaimEffect {
  static async requestRevenueClaim(params) {
    const endpoint = environment.api.revenueClaim;

    return EffectUtility.getToModel(RevenueClaimGetModel, endpoint, params);
  }

  static async requestRevenueClaimFilter(params) {
    const endpoint = environment.api.revenueClaim;

    return EffectUtility.getToModel(RevenueClaimGetModel, endpoint, params);
  }

  static async requestClientRevenueBalance() {
    const endpoint = environment.api.clientRevenueBalance;

    return EffectUtility.getToModel(ClientRevenueBalanceGetModel, endpoint);
  }

  static async requestRevenueClaimPost(data) {
    const endpoint = environment.api.revenueClaimRequest;

    return EffectUtility.postToModel(RevenueClaimPostModel, endpoint, data);
  }

  static async requestGenerateClientInvoice(data, id, params, callBack) {
    const endpoint = environment.api.generateClientInvoice + "/" + id;
    let response = EffectUtility.postToModel(
      RevenueClaimPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }
}
