import React from "react";
import ReactEcharts from "echarts-for-react";
import { theme } from "../../App";
import { getRandomColor } from "../../reports/ReportsReusedFunctions";
import { Box } from "@mui/material";
import ErrorBoundary from "../../../components/ErrorBoundary";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TopStoreAction from "../../../stores/topStoresReport/TopStoreAction";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  topStoreRequesting: selectRequesting(state, [
    TopStoreAction.REQUEST_TOPSTORE,
  ]),
  topStore: state.topStore.topStore || [],
});
function RetailerReport(props) {
  const topStoreRows = [];

  const { detailReportOpen, setRetailerSelected, topStoreRequesting } = props;

  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };

  if (props.topStore && props.topStore.top_stores_report) {
    props.topStore.top_stores_report &&
      props.topStore.top_stores_report.length > 0 &&
      props.topStore.top_stores_report.map((item, index) => {
        topStoreRows.push({
          sl_no: ++index,
          id: ++index,
          count: item.count,
          stores: item.key,
          percent: item.percentage.toFixed(2),
        });
      });
  }
  const nameLabel = topStoreRows.map((item) => item.stores);
  const seriesData = topStoreRows.map((item, index) => {
    const itemColor =
      index < theme.palette.dashboardCharts.length
        ? theme.palette.dashboardCharts[index]
        : getRandomColor();
    return {
      value: item.count,
      percentage: item.percent,
      itemStyle: {
        color: itemColor,
      },
    };
  });
  const onChartClick = (params) => {
    setRetailerSelected(params.name);
  };

  const getOptions = () => {
    return {
      tooltip: {
        trigger: "axis", // or 'item' if you want tooltips for individual bars
        axisPointer: {
          type: "shadow", // This option adds a shadow to the xAxis or yAxis pointer for better visualization
        },
        confine: true,
        formatter: (params) => {
          let val = params[0].value;
          let formattedVal = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params[0].color};border-radius:50%;"></span>`;
          return `${colorSpan}${params[0].name}:<br>${formattedVal} (${params[0].data.percentage}%)`;
        },
      },

      grid: {
        top: "5%",
        left: "10%",
        right: "5%",
        bottom: "15 %",
      },

      dataZoom: [
        {
          type: "inside",
          filterMode: "weakFilter",
        },
      ],
      xAxis: [
        {
          type: "category",
          data: nameLabel,
          axisLabel: {
            rotate: 35, // Rotate labels by 45 degrees
            hideOverlap: true,
            overflow: "break", // Break long labels
            width: 50, // Set a maximum width for the labels
          },
          name: detailReportOpen ? "Retailer" : "",
          nameLocation: "bottom",
          nameTextStyle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: "BookAntiqua",
            verticalAlign: "left",
            lineHeight: 25,
            fontSize: 20,
            padding: [75, 0, 0, 0],
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: (value) => formatValue(value),
            hideOverlap: true,
          },
          axisPointer: {
            triggerTooltip: true,
          },
          name: detailReportOpen ? "Sum Of Quantity" : "",
          nameLocation: "middle",
          nameTextStyle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: "BookAntiqua",
            fontSize: 20,
            lineHeight: 0,
            padding: [0, 0, 70, 0],
          },
        },
      ],
      series: [
        {
          data: seriesData,
          type: "bar",
          itemStyle: {
            borderRadius: [12, 12, 0, 0],
          },
          barWidth: 15,
        },
      ],
    };
  };

  const onEvents = {
    // click: isFilterApplied() ? null : onChartClick,
    click: onChartClick,
    mouseup: (e) => {},
    mousedown: (e) => {},
  };

  return !topStoreRequesting ? (
    <ErrorBoundary message="Error in loading report">
      <Box sx={{ height: "100%" }}>
        <ReactEcharts
          style={{
            height: "100%",
            width: "90%",
          }}
          option={getOptions()}
          onEvents={onEvents}
        />
      </Box>
    </ErrorBoundary>
  ) : (
    <LoadingIndicator isActive={topStoreRequesting}>
      <Box
        sx={{
          width: "100%",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingBgImage width={"50%"} />
      </Box>
    </LoadingIndicator>
  );
}

export default connect(mapStateToProps)(RetailerReport);
