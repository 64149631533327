import { BaseModel } from "sjs-base-model";

export default class LogoutModel extends BaseModel {
  " ";

  /*
   * Client-Side properties (Not from API)
   */

  constructor(data) {
    super();

    this.update(data);
  }
}
