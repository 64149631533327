import BaseReducer from "../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// DraftReleaseRefuser
export default class DraftReleaseReduser extends BaseReducer {
  initialState = {
    draftRelease: [],
  };
  [ReleasesAction.REQUEST_DRAFT_RELEASES_FINISHED](state, action) {
    return {
      ...state,
      draftRelease: action.payload,
    };
  }

  //   [ReleasesAction.REQUEST_RELEASE_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       draftRelease: action.payload,
  //     };
  //   }
}
