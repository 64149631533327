import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import PlanModel from "./model/PlanModel";

// PlanEffect
export default class PlanEffect {
  // calling an API according to the plan model
  static async requestPlan(params) {
    const endpoint = environment.api.plan;
    return EffectUtility.getToModel(PlanModel, endpoint, params);
  }

  // Get method for the plan filter
  static async requestPlanFilter(params) {
    const endpoint = environment.api.plan;
    return EffectUtility.getToModel(PlanModel, endpoint, params);
  }
}
