import ActionUtility from "../../utilities/ActionUtility";
import TopLyricistEffect from "./TopLyricistEffect";

export default class TopLyricistAction {
  // TopLyricistAction action with an function declaration
  static REQUEST_TOP_LYRICIST = "TopLyricistAction.REQUEST_TOP_LYRICIST";
  static REQUEST_TOP_LYRICIST_FINISHED =
    "TopLyricistAction.REQUEST_TOP_LYRICIST_FINISHED";

  static REQUEST_TOP_LYRICIST_FILTER =
    "TopLyricistAction.REQUEST_TOP_LYRICIST_FILTER";
  static REQUEST_TOP_LYRICIST_FILTER_FINISHED =
    "TopLyricistAction.REQUEST_TOP_LYRICIST_FILTER_FINISHED";

  // METHODS
  static requestTopLyricist(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLyricistAction.REQUEST_TOP_LYRICIST,
        TopLyricistEffect.requestTopLyricist,
        params
      );
    };
  }

  static requestTopLyricistFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopLyricistAction.REQUEST_TOP_LYRICIST_FILTER,
        TopLyricistEffect.requestTopLyricistFilter,
        params
      );
    };
  }
}
