import BaseReducer from "../../utilities/BaseReducer";
import TopTrackAction from "./TopTrackAction";

// TrackReduser
export default class TopTrackReduser extends BaseReducer {
  initialState = {
    topTrack: [],
  };
  [TopTrackAction.REQUEST_TOPTRACK_FINISHED](state, action) {
    return {
      ...state,
      topTrack: action.payload,
    };
  }
}
