import React from "react";
import TopLabelAction from "../../../stores/topLabelReport/TopLabelAction";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { Box } from "@mui/material";
import ReactEcharts from "echarts-for-react";
import { getRandomColor } from "../../reports/ReportsReusedFunctions";
import { theme } from "../../App";
import ErrorBoundary from "../../../components/ErrorBoundary";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  topLabelRequesting: selectRequesting(state, [
    TopLabelAction.REQUEST_TOPLABEL,
  ]),
  topLabel: state.topLabel.topLabel || [],
});

function LabelReport(props) {
  const { setLabelSelected, topLabelRequesting } = props;

  console.log("topLabelRequesting", topLabelRequesting);

  const topLabelRows = [];

  if (props.topLabel) {
    props.topLabel.top_labels &&
      props.topLabel.top_labels.length > 0 &&
      props.topLabel.top_labels.map((item, index) => {
        topLabelRows.push({
          sl_no: index + 1,
          id: item.key,
          label: item?.label_name,
          stream_percentage: item?.stream_percentage,
          audio_streams: item?.count,
        });
      });
  }
  const nameLabel = topLabelRows.map((item) => item.labels);
  const seriesData = topLabelRows.map((item, index) => {
    const itemColor =
      index < theme.palette.dashboardCharts.length
        ? theme.palette.dashboardCharts[index]
        : getRandomColor();
    return {
      name: item.label,
      value: item.audio_streams,
      percentage: item.stream_percentage,
      id: item.id,
      itemStyle: {
        color: itemColor,
      },
    };
  });

  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };

  const getOptions = () => {
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          let val = params.value;
          let formattedVal = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return ` ${colorSpan} ${params.name}: ${formattedVal} (${params.data.percentage}%)`;
        },
      },
      grid: {
        top: "10%",
        left: "0%",
        right: "5%",
        bottom: "5%",
      },
      legend: {
        icon: "circle",
        top: 0,
        type: "scroll",

        itemStyle: {
          borderColor: "#6c757d",
          borderWidth: 1,
        },
      },
      series: [
        {
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          emphasis: {
            focus: "series",
          },
          labelLine: {
            show: true,
          },
          label: {
            show: true,
            formatter: (params) => {
              let formattedValue = formatValue(params.value);
              let percentage = params.percent.toFixed(2);
              return `${formattedValue}\n(${percentage}%)`;
            },
          },
          data: seriesData,
        },
      ],
    };
  };

  const onChartClick = (params) => {
    setLabelSelected(params.data.id);
  };
  const onEvents = {
    // click: isFilterApplied() ? null : onChartClick,
    click: onChartClick,
  };
  return !topLabelRequesting ? (
    <ErrorBoundary message="Error in loading report">
      <Box sx={{ height: "100%" }}>
        <ReactEcharts
          style={{
            height: "100%",
            width: "90%",
          }}
          option={getOptions()}
          onEvents={onEvents}
        />
      </Box>
    </ErrorBoundary>
  ) : (
    <LoadingIndicator isActive={topLabelRequesting}>
      <Box
        sx={{
          width: "100%",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingBgImage width={"50%"} />
      </Box>
    </LoadingIndicator>
  );
}

export default connect(mapStateToProps)(LabelReport);
