import BaseReducer from "../../utilities/BaseReducer";
import GenreAction from "./GenreAction";

export default class GenreFilterReduser extends BaseReducer {
  //initial state of GenreFilterReduser
  initialState = {
    genreFilter: [],
  };
  //GenreFilterReduser request action finish
  [GenreAction.REQUEST_GENRE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      genreFilter: action.payload,
    };
  }

  [GenreAction.REQUEST_GENRE_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      genreFilter: action.payload,
    };
  }
}
