import AuthAction from "./AuthAction";
import BaseReducer from "../../utilities/BaseReducer";

export default class AuthReducer extends BaseReducer {
  initialState = {
    access_token: "",
    authenticated: false,
  };

  [AuthAction.REQUEST_LOGIN_FINISHED](state, action) {
    return {
      ...state,
      access_token: action.payload,
      authenticated: true,
    };
  }
}
