import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TopDevicesModel from "./models/TopDeviceModel";

// TopDevicesEffect
export default class TopDevicesEffect {
  // calling an API according to the track model
  // Get method for the
  static async requestTopDevices(params, callBack) {
    const endpoint = environment.api.topDevices;
    let response = EffectUtility.getToModel(TopDevicesModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  // Get method for the track filter
  static async requestTopDevicesFilter(params) {
    const endpoint = environment.api.topDevices;
    return EffectUtility.getToModel(TopDevicesModel, endpoint, params);
  }
}
