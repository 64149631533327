import React, { useEffect, useState } from "react";
import environment from "environment";
import CircleLoader from "../../components/circularLoder/CircleLoader";

import a3Logo from "../../Images/a3_web_logo_image.webp";
import "./Rettrive.css";

const Rettrive = (props) => {
  const [loading, setLoading] = useState(false);

  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    props.thumbnail_url && RetrieveImageUrl(props.thumbnail_url);
  }, []);

  function RetrieveImageUrl() {
    setLoading(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=${
        props.current_page
      }&path=thumbnail/${props.thumbnail_url}&release_id=${
        props.params?.id
          ? props.params?.id
          : props.release_id
          ? props.release_id
          : ""
      }`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);

        setImgUrl(imageUrl);
        setLoading(false);
      });
  }

  return (
    <>
      {!loading && imgUrl ? (
        <img
          src={imgUrl}
          // alt="Preview"
          onError={(e) => {
            e.target.src = a3Logo;
          }}
          style={{
            width: "100%",
            // aspectRatio: "1/1",

            // padding: 3,
            height: "100%",
            // objectFit: "fill",
            // overflow: "hidden",
            borderTopLeftRadius: props.borderTopLeftRadius ?? "",
            borderTopRightRadius: props.borderTopRightRadius ?? "",
          }}
        />
      ) : !imgUrl && !props.thumbnail_url ? (
        <img
          src={a3Logo}
          // alt="Preview"
          onError={() => {
            // Error handling code goes here
          }}
          style={{
            width: "100%",
            aspectRatio: "1/1",
            height: "100%",
            // objectFit: "fill",
            overflow: "hidden",
            borderTopLeftRadius: props.borderTopLeftRadius ?? "",
            borderTopRightRadius: props.borderTopRightRadius ?? "",
          }}
        />
      ) : (
        <CircleLoader width={"20px"} height={"20px"} />
      )}
    </>
  );
};

export default Rettrive;
