import { BaseModel } from "sjs-base-model";

export default class AuthModel extends BaseModel {
  access_token = "";
  expires_in = 0;
  not_before_policy = 0;
  refresh_expires_in = 0;
  refresh_token = "";
  scope = "";
  session_state = "";
  token_type = "";

  /*
   * Client-Side properties (Not from API)
   */

  constructor(data) {
    super();

    this.update(data);
  }
}
