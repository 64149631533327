import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Typography,
  Grow,
} from "@mui/material";
import { theme } from "../../views/App";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PeopleIcon from "@mui/icons-material/People";
import AlbumIcon from "@mui/icons-material/Album";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import DatePicker from "../../components/datePicker/DateRangePicker";
import FastRewindRoundedIcon from "@mui/icons-material/FastRewindRounded";
import LyricsOutlinedIcon from "@mui/icons-material/LyricsOutlined";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import AutoStoriesOutlinedIcon from "@mui/icons-material/AutoStoriesOutlined";
import TodayIcon from "@mui/icons-material/Today";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { keyframes } from "@emotion/react";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";

function CumulativeTracksMultiLevelFilter(props) {
  const squareBoxStyle = {
    borderRadius: "9px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
    border: "solid 0.5px #86aeb6",
    height: 100,
    width: 160,
    cursor: "pointer",
  };
  const miniBoxStyle = {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
    height: 40,
    width: 100,
    cursor: "pointer",
  };

  const zoomIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
  `;

  const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(5px);
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
  `;

  const [releaseDate, setReleaseDate] = useState([null, null]);
  const [label, setLabel] = useState(null);
  const [ISRC, setISRC] = useState(null);
  const [producerCatalog, setProducerCatalog] = useState(null);
  const [trackName, setTrackName] = useState(null);
  const [releaseName, setReleaseName] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const [searchString, setSearchString] = useState(null);
  const [noFiltersAlert, setNoFiltersAlert] = useState(false);

  useEffect(() => {
    if (props.open) {
      setTabValue(0);
      !props.filterContents.find((filter) => filter.id == "2") &&
        setReleaseName(null);
      !props.filterContents.find((filter) => filter.id == "3") &&
        setTrackName(null);
      !props.filterContents.find((filter) => filter.id == "4") &&
        setLabel(null);
      !props.filterContents.find((filter) => filter.id == "5") && setISRC(null);
      !props.filterContents.find((filter) => filter.id == "6") &&
        setReleaseDate([null, null]);
      !props.filterContents.find((filter) => filter.id == "7") &&
        setProducerCatalog(null);
    }
  }, [props.open]);

  function chipContent() {
    const content = [];

    // if (client) content.push({ id: "1", name: client.client_name });
    if (releaseName) content.push({ id: "2", name: releaseName });
    if (trackName) content.push({ id: "3", name: trackName });
    if (label) content.push({ id: "4", name: label.label_name });
    if (ISRC) content.push({ id: "5", name: ISRC });
    if (releaseDate[0] && releaseDate[1]) {
      let stringDate = ``;
      releaseDate.map((item, index) => {
        const inputDate = new Date(
          releaseDate[index]?.$d || releaseDate[index]
        );
        const day = inputDate.getDate().toString().padStart(2, "0"); // Get day and pad with leading zero if needed
        const month = (inputDate.getMonth() + 1).toString().padStart(2, "0"); // Get month (Note: Months are zero-based) and pad with leading zero if needed
        const year = inputDate.getFullYear();
        stringDate = stringDate + `${day}/${month}/${year}`;
        if (index === 0) {
          stringDate += " - ";
        }
      });
      content.push({ id: "6", name: stringDate });
    }
    if (producerCatalog) content.push({ id: "7", name: producerCatalog });

    props.setChipContent(content);
  }

  function filterContentUpdate() {
    let content = [];

    // if (client) content.push({ id: "1", data: client });
    if (releaseName) content.push({ id: "2", data: releaseName });
    if (trackName) content.push({ id: "3", data: trackName });
    if (label) content.push({ id: "4", data: label });
    if (ISRC) content.push({ id: "5", data: ISRC });
    if (releaseDate[0] && releaseDate[1]) {
      content.push({ id: "6", data: releaseDate });
    }
    if (producerCatalog) content.push({ id: "7", data: producerCatalog });
    props.setFilterContents(content);
  }

  function releaseHandleChange(e, releaseName) {
    setReleaseName(releaseName);
  }
  function trackHandleChange(e, track) {
    setTrackName(track);
  }
  function labelHandleChange(e, labelName) {
    setLabel(labelName);
  }
  function ISRCHandleChange(e, ISRC) {
    setISRC(ISRC);
  }
  function producerCatalogNoHandleChange(e, producerCatalog) {
    setProducerCatalog(producerCatalog);
  }
  function releaseDateHandleChange(date) {
    setReleaseDate(date);
  }

  const handleInputChange = (event) => {
    if (tabValue === 2) {
      releaseHandleChange(null, event.target.value);
    } else if (tabValue === 3) {
      trackHandleChange(null, event.target.value);
    } else if (tabValue === 5) {
      ISRCHandleChange(null, event.target.value);
    } else if (tabValue === 7) {
      producerCatalogNoHandleChange(null, event.target.value);
    }

    setSearchString(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (tabValue === 2) {
        releaseHandleChange(null, searchString);
      } else if (tabValue === 3) {
        trackHandleChange(null, searchString);
      } else if (tabValue === 5) {
        ISRCHandleChange(null, searchString);
      } else if (tabValue === 7) {
        producerCatalogNoHandleChange(null, searchString);
      }
      setTabValue(0);
      setSearchString(null);
    }
  };

  useEffect(() => {
    filterContentUpdate();
  }, [
    releaseName,
    trackName,
    ISRC,
    releaseDate[0],
    releaseDate[1],
    label,
    producerCatalog,
  ]);

  function deleteFunction(filterID) {
    // if (filterID == "1" && client) setClient(null);
    if (filterID == "2" && releaseName) setReleaseName(null);
    else if (filterID == "3" && trackName) setTrackName(null);
    else if (filterID == "4" && label) setLabel(null);
    else if (filterID == "5" && ISRC) setISRC(null);
    else if (filterID == "6" && releaseDate[0] && releaseDate[1])
      setReleaseDate([null, null]);
    else if (filterID == "7" && producerCatalog) setProducerCatalog(null);
    else setNoFiltersAlert(true);
  }

  function emptyCheck(tabValue) {
    if (
      // (tabValue == "1" && !client) ||
      (tabValue == "2" && !releaseName) ||
      (tabValue == "3" && !trackName) ||
      (tabValue == "4" && !label) ||
      (tabValue == "5" && !ISRC) ||
      (tabValue == "6" && !releaseDate[0] && !releaseDate[1]) ||
      (tabValue == "7" && !producerCatalog)
    ) {
      setNoFiltersAlert(true);
      return 0;
    }
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.onClose();
      }}
      fullWidth
      maxWidth="lg"
      TransitionComponent={Grow}
      transitionDuration={400}
    >
      <DialogContent>
        <DialogContentText
          sx={{
            color: theme.palette.primary.main,
          }}
        >
          <>
            <Box
              sx={{
                borderBottom: `2px solid ${theme.palette.common.grey}`,
              }}
            >
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
              >
                <Grid item px={1} sx={{ display: "flex" }} mb={1}>
                  <Typography sx={{ fontSize: "20px" }}>
                    <FilterAltIcon sx={{ marginBottom: "4px" }} /> Select
                    Filters
                  </Typography>
                </Grid>
                <Grid item px={1}>
                  <HighlightOffIcon
                    sx={{
                      cursor: "pointer",
                      marginBottom: "5px",
                      "&:hover": {
                        transition: "transform 0.5s, color 0.2s",
                        transform: "scale(1.2)",
                        color: theme.palette.secondary.main,
                      },
                    }}
                    onClick={() => {
                      props.onClose();
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Grid xs={12} sx={{ marginY: "20px" }}></Grid>
            {!tabValue ? (
              <Box>
                <Grid
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  pt={5}
                  gap={5}
                  justifyContent="center"
                  alignItems="center"
                >
                  {/* CLIENT SECTION START */}
                  {/* Not Present for Client Portal */}

                  {/* RELEASE NAME SECTION START */}

                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...squareBoxStyle,
                      animation: `${zoomIn} 0.4s ease-in-out`,
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                    onClick={() => {
                      setTabValue(2);
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                      >
                        <PlayCircleOutlineIcon style={{ fontSize: "40px" }} />
                      </Grid>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                        px={0.5}
                        mt={0.5}
                      >
                        <span
                          className="p-0 m-0"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          Release Name
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* TRACK NAME SECTION START */}

                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...squareBoxStyle,
                      animation: `${zoomIn} 0.4s ease-in-out`,
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                    onClick={() => {
                      setTabValue(3);
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                      >
                        <LyricsOutlinedIcon style={{ fontSize: "40px" }} />
                      </Grid>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                        px={0.5}
                        mt={0.5}
                      >
                        <span
                          className="p-0 m-0"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          Track Name
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* LABEL SECTION START */}

                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...squareBoxStyle,
                      animation: `${zoomIn} 0.5s ease-in-out`,
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                    onClick={() => {
                      setTabValue(4);
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                      >
                        <AlbumIcon style={{ fontSize: "40px" }} />
                      </Grid>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                        px={0.5}
                        mt={0.5}
                      >
                        <span
                          className="p-0 m-0"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          Label
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* ISRC SECTION START */}

                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...squareBoxStyle,
                      animation: `${zoomIn} 0.6s ease-in-out`,
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                    onClick={() => {
                      setTabValue(5);
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                      >
                        <PinOutlinedIcon style={{ fontSize: "40px" }} />
                      </Grid>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                        px={0.5}
                        mt={0.5}
                      >
                        <span
                          className="p-0 m-0"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          ISRC
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* DATE SECTION START */}

                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...squareBoxStyle,
                      animation: `${zoomIn} 0.7s ease-in-out`,
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                    onClick={() => {
                      setTabValue(6);
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                      >
                        <TodayIcon style={{ fontSize: "40px" }} />
                      </Grid>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                        px={0.5}
                        mt={0.5}
                      >
                        <span
                          className="p-0 m-0"
                          style={{
                            fontSize: "20px",
                          }}
                        >
                          Release Date
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* PRODUCER CATALOG NUMBER SECTION */}

                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...squareBoxStyle,
                      animation: `${zoomIn} 0.8s ease-in-out`,
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                    onClick={() => {
                      setTabValue(7);
                    }}
                  >
                    <Grid>
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                      >
                        <AutoStoriesOutlinedIcon style={{ fontSize: "40px" }} />
                      </Grid>

                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        textAlign="center"
                        px={0.5}
                        mt={0.5}
                      >
                        <span
                          className="p-0 m-0"
                          style={{
                            fontSize: "20px",
                            lineHeight: "1.2",
                          }}
                        >
                          Producer Catalog Number
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <>
                {tabValue && (
                  <>
                    <Grid item container sm={1} mb={2}>
                      <Box
                        sx={{
                          padding: "3px",
                          border: `1px solid ${theme.palette.common.grey}`,
                          borderRadius: "4px",
                          backgroundColor: theme.palette.primary.main,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.palette.text.first_letter,
                          },
                        }}
                        onClick={() => {
                          setTabValue(0);
                        }}
                      >
                        <FastRewindRoundedIcon
                          style={{ color: "white", marginBottom: "4px" }}
                        />
                        <span style={{ color: "white", marginRight: "5px" }}>
                          {" "}
                          Back
                        </span>
                      </Box>
                    </Grid>

                    {/* Inner Grid Elements */}
                    <Grid
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      pt={2}
                      pb={2}
                      gap={3}
                      justifyContent="center"
                      alignItems="center"
                    >
                      {/* Client */}
                      {/* Not Present for Client Portal */}

                      {/* Release Name */}
                      <Grid
                        xs={12}
                        sm={6}
                        md={2}
                        item
                        container
                        sx={{
                          ...miniBoxStyle,
                          animation: tabValue == 2 && `${slideIn} 0.3s linear`,
                          border:
                            tabValue === 2
                              ? `solid 2px ${theme.palette.primary.main}`
                              : miniBoxStyle.border,
                          backgroundColor:
                            tabValue === 2
                              ? theme.palette.text.first_letter
                              : "",
                          borderRadius: tabValue === 2 ? "10px" : "5px",
                        }}
                        textAlign="center"
                        justifyContent={"center"}
                        alignItems="center"
                        onClick={() => {
                          setTabValue(2);
                        }}
                      >
                        <Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                            alignItems={"center"}
                            px={0.5}
                            sx={{ animation: `${zoomIn} 0.2s ease-in-out` }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: tabValue === 2 ? "bold" : "",
                              }}
                            >
                              <PlayCircleOutlineIcon
                                style={{ marginBottom: "4px" }}
                              />{" "}
                              Release Name
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Track Name */}
                      <Grid
                        xs={12}
                        sm={6}
                        md={2}
                        item
                        container
                        sx={{
                          ...miniBoxStyle,
                          animation: tabValue == 3 && `${slideIn} 0.3s linear`,
                          border:
                            tabValue === 3
                              ? `solid 2px ${theme.palette.primary.main}`
                              : miniBoxStyle.border,
                          backgroundColor:
                            tabValue === 3
                              ? theme.palette.text.first_letter
                              : "",
                          borderRadius: tabValue === 3 ? "10px" : "5px",
                        }}
                        textAlign="center"
                        justifyContent={"center"}
                        alignItems="center"
                        onClick={() => {
                          setTabValue(3);
                        }}
                      >
                        <Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                            alignItems={"center"}
                            px={0.5}
                            sx={{ animation: `${zoomIn} 0.2s ease-in-out` }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: tabValue === 3 ? "bold" : "",
                              }}
                            >
                              <LyricsOutlinedIcon /> Track Name
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Label */}
                      <Grid
                        xs={12}
                        sm={6}
                        md={2}
                        item
                        container
                        sx={{
                          ...miniBoxStyle,
                          animation: tabValue == 4 && `${slideIn} 0.3s linear`,
                          border:
                            tabValue === 4
                              ? `solid 2px ${theme.palette.primary.main}`
                              : miniBoxStyle.border,
                          backgroundColor:
                            tabValue === 4
                              ? theme.palette.text.first_letter
                              : "",
                          borderRadius: tabValue === 4 ? "10px" : "5px",
                        }}
                        textAlign="center"
                        justifyContent={"center"}
                        alignItems="center"
                        onClick={() => {
                          setTabValue(4);
                        }}
                      >
                        <Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                            alignItems={"center"}
                            px={0.5}
                            sx={{ animation: `${zoomIn} 0.2s ease-in-out` }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: tabValue === 4 ? "bold" : "",
                              }}
                            >
                              <AlbumIcon style={{ marginBottom: "4px" }} />{" "}
                              Label
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* ISRC */}
                      <Grid
                        xs={12}
                        sm={6}
                        md={2}
                        item
                        container
                        sx={{
                          ...miniBoxStyle,
                          animation: tabValue == 5 && `${slideIn} 0.3s linear`,
                          border:
                            tabValue === 5
                              ? `solid 2px ${theme.palette.primary.main}`
                              : miniBoxStyle.border,
                          backgroundColor:
                            tabValue === 5
                              ? theme.palette.text.first_letter
                              : "",
                          borderRadius: tabValue === 5 ? "10px" : "5px",
                        }}
                        textAlign="center"
                        justifyContent={"center"}
                        alignItems="center"
                        onClick={() => {
                          setTabValue(5);
                        }}
                      >
                        <Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                            alignItems={"center"}
                            px={0.5}
                            sx={{ animation: `${zoomIn} 0.2s ease-in-out` }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: tabValue === 5 ? "bold" : "",
                              }}
                            >
                              <PinOutlinedIcon
                                style={{ marginBottom: "4px" }}
                              />{" "}
                              ISRC
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Release Date */}
                      <Grid
                        xs={12}
                        sm={6}
                        md={2}
                        item
                        container
                        sx={{
                          ...miniBoxStyle,
                          animation: tabValue == 6 && `${slideIn} 0.3s linear`,
                          border:
                            tabValue === 6
                              ? `solid 2px ${theme.palette.primary.main}`
                              : miniBoxStyle.border,
                          backgroundColor:
                            tabValue === 6
                              ? theme.palette.text.first_letter
                              : "",
                          borderRadius: tabValue === 6 ? "10px" : "5px",
                        }}
                        textAlign="center"
                        justifyContent={"center"}
                        alignItems="center"
                        onClick={() => {
                          setTabValue(6);
                        }}
                      >
                        <Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                            alignItems={"center"}
                            px={0.5}
                            sx={{ animation: `${zoomIn} 0.2s ease-in-out` }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: tabValue === 6 ? "bold" : "",
                              }}
                            >
                              <TodayIcon style={{ marginBottom: "4px" }} />{" "}
                              Release Date
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Producer Catalog Number */}
                      <Grid
                        xs={12}
                        sm={6}
                        md={2}
                        item
                        container
                        sx={{
                          ...miniBoxStyle,
                          animation: tabValue == 7 && `${slideIn} 0.3s linear`,
                          border:
                            tabValue === 7
                              ? `solid 2px ${theme.palette.primary.main}`
                              : miniBoxStyle.border,
                          backgroundColor:
                            tabValue === 7
                              ? theme.palette.text.first_letter
                              : "",
                          borderRadius: tabValue === 7 ? "10px" : "5px",
                        }}
                        textAlign="center"
                        justifyContent={"center"}
                        alignItems="center"
                        onClick={() => {
                          setTabValue(7);
                        }}
                      >
                        <Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                            alignItems={"center"}
                            px={0.5}
                            sx={{ animation: `${zoomIn} 0.2s ease-in-out` }}
                          >
                            <span
                              style={{
                                fontSize: "20px",
                                fontWeight: tabValue === 7 ? "bold" : "",
                              }}
                            >
                              <AutoStoriesOutlinedIcon /> Producer Catalog
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {tabValue === 1 &&
                  {
                    /* Not Present for Client Portal */
                  }}
                {(tabValue === 2 ||
                  tabValue === 3 ||
                  tabValue === 5 ||
                  tabValue === 7) && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginY: "10px",
                        animation: `${slideIn} 0.3s ease-in-out`,
                      }}
                    >
                      {tabValue === 2
                        ? "Search Release Name"
                        : tabValue === 3
                        ? "Search Track Name"
                        : tabValue === 5
                        ? "Search ISRC"
                        : tabValue === 7
                        ? "Search Producer Catalog Number"
                        : ""}
                    </Typography>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      sx={{
                        width: { sm: "100%", md: "30%" },
                      }}
                    >
                      <MuiTextField
                        value={
                          tabValue == 2
                            ? releaseName
                            : tabValue == 3
                            ? trackName
                            : tabValue == 5
                            ? ISRC
                            : tabValue == 7
                            ? producerCatalog
                            : null
                        }
                        variant="outlined"
                        placeholder={"Type here ..."}
                        sx={{ width: "100%" }}
                        size="small"
                        onChange={handleInputChange}
                        onKeyDown={handleKeyPress}
                      />
                    </Grid>
                  </>
                )}
                {tabValue === 4 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginY: "10px",
                        animation: `${slideIn} 0.3s ease-in-out`,
                      }}
                    >
                      Select Label
                    </Typography>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      sx={{ width: { sm: "100%", md: "30%" } }}
                    >
                      <AutoCompleteComponent
                        value={label}
                        list={props.dropDownLists.labelList}
                        textFieldProps={{
                          placeholder: "Label Name",
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.label_name
                        }
                        getOptionLabel={(option) => option.label_name}
                        renderOption={(props, item) => (
                          <li {...props} key={item.id}>
                            {item.label_name}
                          </li>
                        )}
                        handleChange={labelHandleChange}
                        size="small"
                        width="100%"
                        disableClearable
                      />
                    </Grid>
                  </>
                )}
                {tabValue === 6 && (
                  <>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        marginY: "10px",

                        animation: `${slideIn} 0.3s ease-in-out`,
                      }}
                    >
                      Select Date Range
                    </Typography>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      sx={{ width: { sm: "100%", md: "30%" } }}
                    >
                      <DatePicker
                        value={releaseDate ? releaseDate : [null, null]}
                        onChange={releaseDateHandleChange}
                        maxDate={new Date()}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </>
        </DialogContentText>
      </DialogContent>
      <div style={{ height: "70px" }}>
        <Grid container justifyContent={"flex-end"} px={4} gap={2}>
          {tabValue !== 0 && (
            <>
              <Button
                onClick={() => {
                  if (emptyCheck(tabValue) == 0) return;
                  chipContent();
                  props.setFilterClicked(true);
                  filterContentUpdate();
                  props.updateFilterContents();
                  props.onClose();
                }}
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "5px",
                  textTransform: "none",
                  width: "150px",
                }}
              >
                Apply
              </Button>
              <Button
                onClick={() => {
                  if (emptyCheck(tabValue) == 0) return;
                  deleteFunction(tabValue);
                  props.deleteChip(tabValue);
                }}
                variant="contained"
                style={{
                  borderRadius: "5px",
                  textTransform: "none",
                  width: "150px",
                  backgroundColor: theme.palette.secondary.main,
                }}
              >
                Reset
              </Button>
            </>
          )}
        </Grid>
      </div>
      <MuiSnackbarAlert
        open={noFiltersAlert}
        onClose={() => setNoFiltersAlert(false)}
        message={"Incorrect Filter Detected !!"}
      />
    </Dialog>
  );
}

export default CumulativeTracksMultiLevelFilter;
