import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import { Alert } from "@mui/material";
import { theme } from "../App";
import AssetsAction from "../../stores/assets/AssetsAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AssetsAction.REQUEST_ASSETS,
    AssetsAction.REQUEST_ASSETS_FILTER,
  ]),
  assets: state.assets.assets.hits || [],
  assetsFilter: state.assetsFilter.assetsFilter || [],
});

const AssetsEditName = (props) => {
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setShowAlert(false);
    setIsEdit(false);
  }, [props.open]);

  let initial = {
    newName: "",
  };

  return (
    <Dialog
      open={props.open}
      onClose={() => props.onClose(isEdit)}
      fullWidth
      maxWidth="sm"
    >
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          if (values.newName.trim()) {
            setIsEdit(true);
            let obj = { assets_name: values.newName.trim() };
            let params = {
              current_page: "upload",
            };
            props
              .dispatch(
                AssetsAction.requestPutAssets(
                  JSON.stringify(obj),
                  props.rows.id,
                  params
                )
              )
              .then(() => {
                setLoading(false);
                props.onClose(true);
                // let params = {
                //   page_limit: "none",
                //   filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
                //   current_page: "upload",
                // };
                // fetchAssets(params);
              });
          } else {
            setShowAlert(true);
            setLoading(false);
          }
        }}
        //validateOnBlur={true}
        //validateOnChange
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => (
          // Form Tag from Formik
          <Form>
            {/* Content of the dialog Box  */}
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10 ">
                      <h3
                        className="dailogtext"
                        style={{ color: theme.palette.text.primary }}
                      >
                        <b>
                          <span
                            style={{ color: theme.palette.text.first_letter }}
                          >
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;E
                          </span>
                          dit &nbsp;
                          <span
                            style={{ color: theme.palette.text.first_letter }}
                          >
                            F
                          </span>
                          ile &nbsp;
                          <span
                            style={{ color: theme.palette.text.first_letter }}
                          >
                            N
                          </span>
                          ame &nbsp;
                        </b>
                      </h3>
                    </div>
                    <div className="col-2">
                      <IconButton
                        title="Close"
                        onClick={() => {
                          props.onClose(isEdit);
                        }}
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </DialogContentText>
              {/* Form Filed inside Dialog Box  */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Old Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5}>
                    <Field
                      as={MuiTextField}
                      fullWidth
                      size="small"
                      name="oldName"
                      disabled
                      defaultValue={props.rows.assets_name}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ mb: 2 }}>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                    New Name :
                  </Grid>
                  <Grid item lg={5} md={5} sm={5} mt={2}>
                    <Field
                      as={MuiTextField}
                      fullWidth
                      variant="outlined"
                      size="small"
                      name="newName"
                      placeholder="Enter new File Name"
                    />
                  </Grid>
                </Grid>
              </Grid>
              {showAlert && (
                <Alert
                  severity="error"
                  onClose={() => setShowAlert(false)}
                  sx={{ width: "75%" }}
                >
                  New File Name cannot be empty
                </Alert>
              )}
            </DialogContent>
            <DialogActions className="m-1 my-4">
              {/* Calling ButtonComponent to perform form actions  */}
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  <MuiButton
                    name={"Submit"}
                    type="submit"
                    loading={loading}
                    size="large"
                    width="100%"
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  <MuiButton
                    name={"Reset"}
                    type="reset"
                    size="large"
                    width="100%"
                    cancel
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapStateToProps)(AssetsEditName);
