import { BaseModel } from "sjs-base-model";

// AssetsBulkPutModel
export default class AssetsBulkPutModel extends BaseModel {
  data = [];
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
