import BaseReducer from "../../utilities/BaseReducer";
import ClientAction from "./ClientAction";

// StatusReduser
export default class ClientReduser extends BaseReducer {
  initialState = {
    status: [],
  };
  [ClientAction.REQUEST_CLIENT_FINISHED](state, action) {
    return {
      ...state,
      client: action.payload,
    };
  }

  [ClientAction.REQUEST_CLIENT_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      client: action.payload,
    };
  }
}
