import ActionUtility from "../../utilities/ActionUtility";
import ReleaseStatusCountEffect from "./ReleaseStatusCountEffect";

export default class ReleaseStatusCountAction {
  // ReleaseStatusCountAction action with an function declaration
  static REQUEST_RELEASESTATUSCOUNT =
    "ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT";
  static REQUEST_RELEASESTATUSCOUNT_FINISHED =
    "ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT_FINISHED";

  static REQUEST_RELEASESTATUSCOUNT_FILTER =
    "ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT_FILTER";
  static REQUEST_RELEASESTATUSCOUNT_FILTER_FINISHED =
    "ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT_FILTER_FINISHED";

  static REQUEST_PUT_RELEASESTATUSCOUNT =
    "ReleaseStatusCountAction.REQUEST_PUT_RELEASESTATUSCOUNT";
  static REQUEST_PUT_RELEASESTATUSCOUNT_FINISHED =
    "ReleaseStatusCountAction.REQUEST_PUT_RELEASESTATUSCOUNT_FINISHED";

  static REQUEST_POST_RELEASESTATUSCOUNT =
    "ReleaseStatusCountAction.REQUEST_POST_RELEASESTATUSCOUNT";
  static REQUEST_POST_RELEASESTATUSCOUNT_FINISHED =
    "ReleaseStatusCountAction.REQUEST_POST_RELEASESTATUSCOUNT_FINISHED";

  static REQUEST_DELETE_RELEASESTATUSCOUNT =
    "ReleaseStatusCountAction.REQUEST_DELETE_RELEASESTATUSCOUNT";
  static REQUEST_DELETE_RELEASESTATUSCOUNT_FINISHED =
    "ReleaseStatusCountAction.REQUEST_DELETE_RELEASESTATUSCOUNT_FINISHED";

  // METHODS
  // Organization GET function
  static requestReleaseStatusCount(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT,
        ReleaseStatusCountEffect.requestReleaseStatusCount,
        params,
        callBack
      );
    };
  }

  static requestReleaseStatusCountFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleaseStatusCountAction.REQUEST_RELEASESTATUSCOUNT_FILTER,
        ReleaseStatusCountEffect.requestReleaseStatusCountFilter,
        params
      );
    };
  }
  static requestPutReleaseStatusCount(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleaseStatusCountAction.REQUEST_PUT_RELEASESTATUSCOUNT,
        ReleaseStatusCountEffect.requestPutReleaseStatusCount,
        data,
        id
      );
    };
  }

  static requestPostReleaseStatusCount(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleaseStatusCountAction.REQUEST_POST_RELEASESTATUSCOUNT,
        ReleaseStatusCountEffect.requestPostReleaseStatusCount,
        data
      );
    };
  }

  static requestDeleteReleaseStatusCount(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ReleaseStatusCountAction.REQUEST_DELETE_RELEASESTATUSCOUNT,
        ReleaseStatusCountEffect.requestDeleteReleaseStatusCount,
        id
      );
    };
  }
}
