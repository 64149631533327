import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TicketsModel from "./models/TicketsModel";
import TicketsPutModel from "./models/TicketsPutModel";
import TicketsPostModel from "./models/TicketsPostModel";

export default class TicketsEffect {
  // calling an API according to the ticket model
  // Get method for the
  static async requestTickets(params) {
    const endpoint = environment.api.ticket;
    return EffectUtility.getToModel(TicketsModel, endpoint, params);
  }

  static async requestTicketsClear() {
    return {};
  }

  static async requestTicketsFilterClear() {
    return {};
  }

  static async requestTicketsFilter(params) {
    const endpoint = environment.api.tickets;
    return EffectUtility.getToModel(TicketsModel, endpoint, params);
  }

  static async requestPutTickets(data, id) {
    const endpoint = environment.api.tickets + "/" + id;
    return EffectUtility.putToModel(TicketsPutModel, endpoint, data);
  }

  static async requestPostTickets(data, params) {
    const endpoint = environment.api.ticket + "/add";
    return EffectUtility.postToModel(TicketsPostModel, endpoint, data, params);
  }
}
