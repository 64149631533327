import BaseReducer from "../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

// TrackReduser
export default class A3ReportsStorewiseReducer extends BaseReducer {
  initialState = {
    storewiseRevenueReport: [],
  };
  [A3ReportsAction.REQUEST_A3REPORTS_STOREWISE_FINISHED](state, action) {
    return {
      ...state,
      storewiseRevenueReport: action.payload,
    };
  }
}
