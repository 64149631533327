import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { Box, Grid, Snackbar, Typography, Tooltip, Paper } from "@mui/material";
import { theme } from "../App";
import SnackbarContent from "@mui/material/SnackbarContent";
import MuiButton from "../../components/muiButton/MuiButton";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import { styled } from "@mui/system";
import { useNavigate } from "react-router";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MigrateTracksAction from "../../stores/migrateTracks/MigrateTracksAction";
import ErrorTwoToneIcon from "@mui/icons-material/ErrorTwoTone";

const HoverIcon = styled(AddCircleIcon)`
  font-size: 32px;
  transition: transform 0.5s;
  &:hover {
    transform: scale(1.2);
  }
`;

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    MigrateTracksAction.REQUEST_MIGRATESPOTIFY,
  ]),
  migrateTracks: state.spotifyMigrateTrack.spotifyMigrateTrack || [],
});

function MigrateTracks(props) {
  const [initialStart, setInitialStart] = useState(false);
  const [platform, setPlatform] = useState(null);
  const [ISRC, setISRC] = useState("");
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);
  const [buttonMessage, setButtonMessage] = useState("Migrate");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { isRequesting } = props;
  const [page_limit] = useState(15);

  function migrateSpotifyTracks(params) {
    const callback = (res) => {
      const responseData = res.success;

      const releaseIds =
        responseData &&
        responseData
          ?.filter((item) => "release_id" in item)
          .map((item) => item.release_id);

      releaseIds &&
        releaseIds?.forEach((releaseId) => {
          //localStorage.setItem("draft_releaseid", releaseId);
          navigate("/releases");
        });

      if (!responseData) {
        setButtonMessage("Failed !");
        setTimeout(() => {
          setButtonMessage("Migrate");
          setLoading(false);
          setInitialStart(false);
          setISRC("");
        }, 2000);
      }
    };
    props.dispatch(
      MigrateTracksAction.requestPostSpotifyMigrateTracks(params, callback)
    );
  }

  function migrateAppleMusicTracks(params) {
    const callback = (res) => {
      const responseData = res.success;

      const releaseIds = responseData
        ?.filter((item) => "release_id" in item)
        .map((item) => item.release_id);

      releaseIds?.forEach((releaseId) => {
        //localStorage.setItem("draft_releaseid", releaseId);
        navigate("/releases");
      });
      if (!responseData) {
        setButtonMessage("Failed !");
        setTimeout(() => {
          setButtonMessage("Migrate");
          setLoading(false);
          setInitialStart(false);
          setISRC("");
        }, 2000);
      }
    };
    // props
    //   .dispatch(MigrateTracksAction.requestPostAppleMigrateTracks(params, callback))
  }

  useEffect(() => {
    setLoading(false);
    setInitialStart(false);
  }, []);

  const platformList = ["Apple Music", "Spotify"];

  const platformHandleChange = (event, platform) => {
    setPlatform(platform);
  };
  function ISRCHandleChange(e, ISRC) {
    setISRC(ISRC);
  }

  const handleInputChange = (event) => {
    ISRCHandleChange(null, event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      fetchTracksMigrate();
    }
  };

  let rows = [];
  rows.push({
    sl_no: 1,
    id: 1,
    platform: platform,
    isrc: ISRC,
  });

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "platform",
      headerName: "Platform",
      width: 160,
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "isrc",
      headerName: "ISRC",
      width: 160,
      align: "center",
      flex: 2,
      headerAlign: "center",
    },
  ];

  function fetchTracksMigrate() {
    if (!platform || !ISRC) {
      setAlertMessage("Please provide all Information !!");
      setAlertPopUp(true);
      return;
    }
    if (!isAlphanumericWithBoth(ISRC)) {
      setAlertMessage("ISRC entered is incorrect !!");
      setAlertPopUp(true);
      return;
    }

    setButtonMessage("Migrating ....");
    setLoading(true);
    setInitialStart(true);

    let params = {
      isrc: ISRC,
    };

    if (platform === "Spotify") {
      migrateSpotifyTracks(params);
    } else if (platform === "Apple Music") {
      setLoading(false);
      setAlertMessage("Currently Apple Music not available (Coming soon)");
      setAlertPopUp(true);
      // migrateAppleMusicTracks(params);
    }
  }

  const clearFilter = () => {
    if (!platform && !ISRC) {
      setAlertMessage("No data to clear !!");
      setAlertPopUp(true);
      return;
    }
    setLoading(false);
    setInitialStart(false);
    setPlatform(null);
    setISRC("");
    setButtonMessage("Migrate");
  };

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes blink-shadow {
            0% {
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
            }
            50% {
              box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.6);
            }
            100% {
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.4);
            }
          }
      `,
        }}
      />
      <Box>
        <Paper
          elevation={10}
          sx={{
            padding: "20px",
            marginX: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.4)",
          }}
        >
          <Grid container>
            <Grid
              container
              lg={12}
              md={12}
              sm={12}
              justifyContent="center"
              spacing={1}
            >
              <Grid item lg={2} md={3} sm={3.5} mt={0.5}>
                <AutoCompleteComponent
                  value={platform}
                  list={platformList}
                  textFieldProps={{
                    placeholder: "Platform",
                  }}
                  handleChange={platformHandleChange}
                  size="small"
                  width="100%"
                  disableClearable
                />
              </Grid>
              <Grid item lg={2} md={3} sm={3.5} mt={0.5}>
                <MuiTextField
                  variant="outlined"
                  placeholder={"ISRC"}
                  sx={{ width: "100%" }}
                  value={ISRC}
                  size="small"
                  onChange={handleInputChange}
                  onKeyDown={handleKeyPress}
                />
              </Grid>
              <Grid item lg={6} md={6} sm={5}>
                <Grid
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  mt={0}
                >
                  <Grid
                    lg={8}
                    sm={8}
                    md={8}
                    display={"flex"}
                    justifyContent={"end"}
                    mt={1}
                    mr={2}
                    sx={{ gap: 2 }}
                  >
                    <Grid lg={3} md={3} sm={3}>
                      <MuiButton
                        name={"Add"}
                        width="100%"
                        onClick={() => {
                          if (!platform || !ISRC) {
                            setAlertMessage(
                              "Please provide all Information !!"
                            );
                            setAlertPopUp(true);
                          } else setInitialStart(true);
                        }}
                        sx={{
                          transition: "box-shadow 0.3s, transform 0.3s",
                          "&:hover": {
                            boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.6)",
                          },
                        }}
                      />
                    </Grid>
                    <Grid lg={3} md={3} sm={3}>
                      <MuiButton
                        width="100%"
                        name={"Clear"}
                        onClick={clearFilter}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>

        {navigator.onLine ? (
          platform && ISRC && initialStart ? (
            <Box>
              <Grid
                display="flex"
                justifyContent="center"
                container
                lg={12}
                md={12}
                sm={12}
                my={2}
              >
                {" "}
                <Grid item lg={10} md={10} sm={10} xs={12} mt={5}>
                  <LoadingIndicator isActive={isRequesting}>
                    <DataGridFunction
                      rows={rows}
                      columns={columns}
                      rowHeight={60}
                      height={"15vh"}
                    />
                  </LoadingIndicator>
                  <Tooltip title="Click to Migrate / Add new release" arrow>
                    <Paper
                      elevation={10}
                      sx={{
                        cursor: "pointer",
                        width: "40%",
                        marginTop: "20px",
                        padding: "10px",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "auto",
                        alignItems: "center",
                        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                        transition: "box-shadow 0.3s, transform 0.3s",
                        "&:hover": {
                          boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.6)",
                        },
                        animation: loading
                          ? "blink-shadow 0.7s infinite"
                          : "none",
                      }}
                      onClick={fetchTracksMigrate}
                    >
                      <div>
                        <b
                          style={{
                            color: buttonMessage === "Failed !" && "red",
                          }}
                        >
                          {/* {loading ? "Migrating ...." : "Migrate"}  */}
                          {buttonMessage}
                        </b>
                        &nbsp;
                      </div>
                      {!loading && <HoverIcon />}
                    </Paper>
                  </Tooltip>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                width: "100%",
              }}
              mt={5}
            >
              <LoadingIndicator isActive={isRequesting}>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "10px",
                    fontWeight: "bold",
                    fontSize: "32px",
                    textShadow: "2px 2px 20px rgba(0, 0, 0, 0.4)",
                  }}
                >
                  Please provide above information to start
                </Typography>
              </LoadingIndicator>
            </Grid>
          )
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
      <Snackbar
        open={alertPopUp}
        autoHideDuration={500}
        onClose={() => setAlertPopUp(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <SnackbarContent
          message={
            <Typography
              variant="body1"
              // color={theme.palette.secondary.main}
              color={"white"}
              fontWeight={"bold"}
            >
              <ErrorTwoToneIcon />
              &nbsp; {alertMessage}
            </Typography>
          }
          sx={{
            borderRadius: "5px",
            background: theme.palette.primary.main,
            // background: theme.palette.primary.light,
            // border: `1px solid ${theme.palette.primary.main}`,
          }}
        />
      </Snackbar>
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(MigrateTracks);

function isAlphanumericWithBoth(values) {
  const containsLetters = /[a-zA-Z]/.test(values);
  const containsNumbers = /[0-9]/.test(values);
  const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

  if (containsLetters && containsNumbers && !containsSpecialChars) {
    return true;
  } else {
    return false;
  }
}
