import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import StreamPlatformModel from "./model/StreamPlatformModel";
import StreamPlatformPostModel from "./model/StreamPlatformPostModel";
import StreamPlatformPutModel from "./model/StreamPlatformPutModel";

// StreamPlatformEffect
export default class StreamPlatformEffect {
  // calling an API according to the stream_platform model
  static async requesPlatform(params) {
    const endpoint = environment.api.stream_platform;
    return EffectUtility.getToModel(StreamPlatformModel, endpoint, params);
  }

  static async requesPlatformClear() {
    return {};
  }

  static async requesPlatformFilterClear() {
    return {};
  }

  // Get method for the stream_platform filter
  static async requestPlatformFilter(params) {
    const endpoint = environment.api.stream_platform;
    return EffectUtility.getToModel(StreamPlatformModel, endpoint, params);
  }

  // put method for the stream_platform
  static async requestPutPlatform(data, id) {
    const endpoint = environment.api.stream_platform + "/" + id;
    return EffectUtility.putToModel(StreamPlatformPutModel, endpoint, data);
  }

  // post method for the stream_platform
  static async requestPostPlatform(data) {
    const endpoint = environment.api.stream_platform + "/add";
    return EffectUtility.postToModel(StreamPlatformPostModel, endpoint, data);
  }
}
