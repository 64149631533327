import { BaseModel } from "sjs-base-model";

// SyncPlaylinkModel
export default class TrackStatusCountGet extends BaseModel {
  track_status_count = [];

  constructor(data) {
    super();
    this.update(data);
  }
}
