import { BaseModel } from "sjs-base-model";
import StatusDataModel from "./StatusDataModel";

// StatusDataHitsModel
export default class StatusDataHitsModel extends BaseModel {
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [StatusDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
