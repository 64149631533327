import { BaseModel } from "sjs-base-model";

export default class UserByIdModel extends BaseModel {
  data = {};
  success = "";
  constructor(data1) {
    super();

    this.update(data1);
  }
}
