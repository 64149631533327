import { Box, Button, Grid, ListItem, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import ReleasesShadowTableAction from "../../stores/releasesShadowTable/ReleasesShadowTableAction";
import Rettrive from "../releases/Rettrive";
import BreadCrumbs from "./BreadCrumbs";
import TreeViewComponnet from "./TreeViewComponnet";
import ImageIcon from "@mui/icons-material/Image";
import { theme } from "../App";
import AddTaskIcon from "@mui/icons-material/AddTask";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RaiseTicketPopper from "../../components/poppers/RaiseTicketPopper";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import SearchIcon from "@mui/icons-material/Search";
import MuiButton from "../../components/muiButton/MuiButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ReleasesShadowTabel from "./ReleasesShadowTabel";
import { format } from "date-fns";
import DatePicker from "../../components/datePicker/DateRangePicker";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    ReleasesShadowTableAction.REQUEST_RELEASES_SHADOW_TABLE_BY_ID,
  ]),
  releaseById: state.releaseById.releaseById || {},
  releasesShadowTableById:
    state.releasesShadowTableById.releasesShadowTableById || {},
  pagination: state.releasesShadowTableById.releasesShadowTableById || [],
});

const ShadowTabel = (props) => {
  function fetchResource(resourceId) {
    props.dispatch(ReleasesAction.requestReleaseById(resourceId));
  }

  const { isRequesting } = props;

  const [page_limit] = useState(20);

  function fetchReleasesShadowTableById(params) {
    props.dispatch(
      ReleasesShadowTableAction.requestReleasesShadowTableById(params)
    );
  }

  useEffect(() => {
    // let id = localStorage.getItem("id");
    // fetchResource(id);
    let params = {
      page_limit: `${page_limit}`,
      order_by: '[["modified_on", "desc"]]',
      current_page: "releaseActivities",
    };
    fetchReleasesShadowTableById(params);
  }, []);

  // const { _source } = props?.releaseById && props.releaseById;

  const [title, setTitle] = useState("");
  const [FilterClicked, setFilterClicked] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [createdDate, setCreatedDate] = useState([null, null]);

  function CustomDateFilter() {
    return `["log.main_release_date","must","lte","range","${format(
      createdDate[1].$d,
      "yyyy-MM-dd"
    )}"],["log.main_release_date","must","gte","range","${format(
      createdDate[0].$d,
      "yyyy-MM-dd"
    )}"]`;
  }

  const handleSearchChange = (event) => {
    setTitle(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      applyFilter();
    }
  };

  function applyFilter() {
    const filter = [];

    if (title !== "") {
      filter.push(`["log.title.keyword","must","","wildcard","*${title}*"]`);
    }

    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }

    if (title !== "" || (createdDate[0] !== null && createdDate[1] !== null)) {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        current_page: "shadowtable",
      };
      fetchReleasesShadowTableById(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  }

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setFilterLoader(false);
    setClearLoader(false);

    const filter = [];
    let params = {};

    if (title !== "") {
      filter.push(`["log.title.keyword","must","","wildcard","*${title}*"]`);
    }
    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }
    if (
      (title !== "" || (createdDate[0] !== null && createdDate[1] !== null)) &&
      FilterClicked
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["modified_on", "desc"]]',
        current_page: "releaseActivities",
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        page_limit: `${page_limit}`,
        order_by: '[["modified_on", "desc"]]',
        current_page: "releaseActivities",
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchReleasesShadowTableById(params);
    }
  };

  const ClearState = () => {
    // setType("");
    // Clear the state
    if (title !== "" || createdDate[0] !== null || createdDate[1] !== null) {
      setClearLoader(true);
      setFilterLoader(false);
      setTitle("");
      setCreatedDate([null, null]);
    }
    setFilterClicked(false);
    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: `${page_limit}`,
      current_page: "releases",
    };
    FilterClicked && fetchReleasesShadowTableById(params);
  };

  const dateValueHandleChange = (date) => {
    setCreatedDate(date);
  };
  let maxDate = new Date();
  const releasesShadowTabel = useMemo(
    () => (
      <ReleasesShadowTabel
        props={props}
        // deleteRelease={deleteRelease}
        isRequesting={isRequesting}
        page_limit={page_limit}
        handleChange={handleChange}
      />
    ),
    [props]
  );

  return (
    <Box p={1}>
      {/* <Grid
        item
        container
        xl={12}
        lg={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={10}
          container
          alignItems={"center"}
          justifyContent="flex-start"
          gap={2}
        >
          <Grid item>
            <h2>{formatName(`${_source?.title}`)}</h2>
          </Grid>

          <Grid item>
            <BreadCrumbs pathname={window.location.pathname} />
          </Grid>
        </Grid>
      </Grid> */}

      <Grid
        container
        p={4}
        // sx={{ overflow: "hidden", height: "100%" }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="space-between"
          alignItems={"flex-end"}
        >
          <Grid item lg={3} md={3} sm={3}>
            <Typography>
              {props?.releasesShadowTableById?.hits?.total?.value ?? "0"}&nbsp;
              {formatName("Results Found")}
            </Typography>
          </Grid>

          <Grid item lg={5} md={12} sm={12} container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={1}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                item
                lg={12}
                sm={7}
                md={7}
                sx={{ gap: 2 }}
                justifyContent="flex-end"
                container
                alignItems={"flex-start"}
              >
                <Grid item lg={3} md={3} sm={3}>
                  <MuiTextField
                    variant="outlined"
                    placeholder={"Search Title"}
                    fieldheight="33px"
                    sx={{ width: "100%" }}
                    value={title}
                    // autoComplete="off"
                    size="small"
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyPress}
                    InputProps={{
                      endAdornment: <SearchIcon onClick={applyFilter} />,
                    }}
                  />
                </Grid>
                <Grid item lg={5} md={5} sm={5}>
                  <DatePicker
                    value={createdDate ? createdDate : [null, null]}
                    onChange={dateValueHandleChange}
                    maxDate={maxDate}
                  />
                </Grid>

                <Grid item lg={1.5} md={2} sm={2}>
                  <MuiButton
                    name={"Filter"}
                    save
                    width="100%"
                    onClick={applyFilter}
                    loading={filterLoader && isRequesting}
                  />
                </Grid>
                <Grid item lg={1.5} md={2} sm={2}>
                  <MuiButton
                    cancel={true}
                    name={"Clear"}
                    width="100%"
                    onClick={ClearState}
                    loading={clearLoader && isRequesting}
                  />
                </Grid>
                {/* <Grid>
                  <PostPlusButton
                    open={open}
                    openCloseModal={openCloseModal}
                    editMode={editMode}
                  />
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Box>
        <Grid item container alignItems={"center"} justifyContent="center">
          <Grid item xs={12}>
            <LoadingIndicator isActive={isRequesting}>
              {isRequesting ? (
                <Box>
                  <LoadingBgImage />
                </Box>
              ) : (
                releasesShadowTabel
              )}
            </LoadingIndicator>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default connect(mapStateToProps)(ShadowTabel);
