import ActionUtility from "../../utilities/ActionUtility";
import LabelEffect from "../label/LabelEffect";

export default class ActionGenerator {
  static generateActions(actionName, effectModule) {
    console.log("effectModule", effectModule);

    const REQUEST_ACTION = `ActionGenerator.REQUEST_${actionName}`;
    const REQUEST_ACTION_FINISHED = `ActionGenerator.REQUEST_${actionName}_FINISHED`;

    const REQUEST_ACTION_FILTER = `ActionGenerator.REQUEST_${actionName}_FILTER`;
    const REQUEST_ACTION_FILTER_FINISHED = `ActionGenerator.REQUEST_${actionName}_FILTER_FINISHED`;

    const REQUEST_PUT_ACTION = `ActionGenerator.REQUEST_PUT_${actionName}`;
    const REQUEST_PUT_ACTION_FINISHED = `ActionGenerator.REQUEST_PUT_${actionName}_FINISHED`;

    const REQUEST_POST_ACTION = `ActionGenerator.REQUEST_POST_${actionName}`;
    const REQUEST_POST_ACTION_FINISHED = `ActionGenerator.REQUEST_POST_${actionName}_FINISHED`;

    const REQUEST_ACTION_CLEAR = `ActionGenerator.REQUEST_${actionName}_CLEAR`;
    const REQUEST_ACTION_CLEAR_FINISHED = `ActionGenerator.REQUEST_${actionName}_CLEAR_FINISHED`;

    const REQUEST_ACTION_FILTER_CLEAR = `ActionGenerator.REQUEST_${actionName}_FILTER_CLEAR`;
    const REQUEST_ACTION_FILTER_CLEAR_FINISHED = `ActionGenerator.REQUEST_${actionName}_FILTER_CLEAR_FINISHED`;

    return {
      // Action types
      REQUEST_ACTION,
      REQUEST_ACTION_FINISHED,
      REQUEST_ACTION_FILTER,
      REQUEST_ACTION_FILTER_FINISHED,
      REQUEST_PUT_ACTION,
      REQUEST_PUT_ACTION_FINISHED,
      REQUEST_POST_ACTION,
      REQUEST_POST_ACTION_FINISHED,
      REQUEST_ACTION_CLEAR,
      REQUEST_ACTION_CLEAR_FINISHED,
      REQUEST_ACTION_FILTER_CLEAR,
      REQUEST_ACTION_FILTER_CLEAR_FINISHED,

      // Action methods
      [`request${actionName}`]: (params) => async (dispatch, getState) => {
        await ActionUtility.createThunkEffect(
          dispatch,
          REQUEST_ACTION,
          this.getEffectFunction(actionName, "request", effectModule),
          params
        );
      },

      [`request${actionName}Clear`]: () => async (dispatch, getState) => {
        await ActionUtility.createThunkEffect(
          dispatch,
          REQUEST_ACTION_CLEAR,
          this.getEffectFunction(actionName, "clear", effectModule)
        );
      },

      [`request${actionName}FilterClear`]: () => async (dispatch, getState) => {
        await ActionUtility.createThunkEffect(
          dispatch,
          REQUEST_ACTION_FILTER_CLEAR,
          this.getEffectFunction(actionName, "filterClear", effectModule)
        );
      },

      [`request${actionName}Filter`]:
        (params) => async (dispatch, getState) => {
          await ActionUtility.createThunkEffect(
            dispatch,
            REQUEST_ACTION_FILTER,
            this.getEffectFunction(actionName, "filter", effectModule),
            params
          );
        },

      [`requestPut${actionName}`]:
        (data, id, params) => async (dispatch, getState) => {
          await ActionUtility.createThunkEffect(
            dispatch,
            REQUEST_PUT_ACTION,
            this.getEffectFunction(actionName, "put", effectModule),
            data,
            id,
            params
          );
        },

      [`requestPost${actionName}`]:
        (data, params) => async (dispatch, getState) => {
          await ActionUtility.createThunkEffect(
            dispatch,
            REQUEST_POST_ACTION,
            this.getEffectFunction(actionName, "post", effectModule),
            data,
            params
          );
        },
    };
  }

  static getEffectFunction(actionName, method, effectModule) {
    return `${LabelEffect}.request${actionName}${
      method.charAt(0).toUpperCase() + method.slice(1)
    }`;
  }
}
