import {
  Alert,
  Box,
  Grid,
  IconButton,
  ListItem,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TrackAction from "../../stores/track/TrackAction";
import ArtistAction from "../../stores/artist/ArtistAction";
import ClientAction from "../../stores/client/ClientAction";
import LanguageAction from "../../stores/language/LanguageAction";
import GenreAction from "../../stores/genre/GenreAction";
import SubGenreAction from "../../stores/subgenre/SubGenreAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import PlanAction from "../../stores/plan/PlanAction";
import { useLocation } from "react-router";
import ActorAction from "../../stores/actor/ActorAction";
import ActressAction from "../../stores/actress/ActressAction";
import DirectorAction from "../../stores/director/DirectorAction";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReleaseDetailsTracksDialog from "./ReleaseDetailsTracksDialog";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    ArtistAction.REQUEST_ARTIST_FILTER,
    ClientAction.REQUEST_CLIENT_FILTER,
    LanguageAction.REQUEST_LANGUAGE,
    GenreAction.REQUEST_GENRE_FILTER,
    SubGenreAction.REQUEST_SUBGENRE_FILTER,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    PlanAction.REQUEST_PLAN,
    ActorAction.REQUEST_ACTOR_FILTER,
    ActressAction.REQUEST_ACTRESS_FILTER,
    DirectorAction.REQUEST_DIRECTOR_FILTER,
  ]),
  track: state.track.track.hits || [],
  artistFilter: state.artistFilter.artistFilter || [],
  clientFilter: state.clientFilter.clientFilter || [],
  language: state.language.language || [],
  genreFilter: state.genreFilter.genreFilter || [],
  subgenreFilter: state.subgenreFilter.subgenreFilter || [],
  releaseById: state.releaseById.releaseById || {},
  plan: state.plan.plan || {},
  actorFilter: state.actorFilter.actorFilter || [],
  actressFilter: state.actressFilter.actressFilter || [],
  directorFilter: state.directorFilter.directorFilter || [],
});

function ReleaseDetailsTracks(props) {
  const [openTrackDialog, setOpenTrackDialog] = useState(false);
  const [trackData, setTrackData] = useState(null);
  const [copied, setCopied] = useState(false);
  const location = useLocation();

  function fetchTracks(params) {
    props.dispatch(TrackAction.requestTrack(params));
  }

  useEffect(() => {
    const params = {
      order_by: `[["created_on","desc"]]`,
      page_limit: "none",
      current_page: "release_details",
      filters: `[["release_id.keyword","must","","match","${props.releaseId}"]]`,
    };

    fetchTracks(params);
  }, []);

  const rows = [];

  if (props.track.hits) {
    props.track.hits.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: item._id,
        _index: item._index,
        arranger: item._source.arranger,
        ask_to_generate_an_isrc: item._source.ask_to_generate_an_isrc,
        author: item._source.author,
        client_id: item._source.client_id,
        client_id_object: item._source.client_id,
        composer: item._source.composer,
        content_type: item._source.content_type,
        created_by:
          item._source.created_by && item._source.created_by.user_name,
        created_on: item._source.created_on,
        featuring: item._source.featuring,
        genre: item._source.genre,
        director: item._source.director,
        primary_actor: item._source.primary_actor,
        primary_actress: item._source.primary_actress,
        instrumental: item._source.instrumental,
        isrc: item._source.isrc,
        lyrics: item._source.lyrics,
        lyrics_language: item._source.lyrics_language,
        parental_advisory: item._source.parental_advisory,
        plan_id: item._source.plan_id ? item._source.plan_id : "",
        preview_start: item._source.preview_start,
        primary_artists: item._source.primary_artists,
        primary_track_type: item._source.primary_track_type,
        producer: item._source.producer,
        producer_catalouge_number: item._source.producer_catalouge_number,
        production_year: item._source.production_year,
        published_by: item._source.published_by,
        publisher: item._source.publisher,
        remixer: item._source.remixer,
        release_id: item._source.release_id,
        secondary_genre: item._source.secondary_genre,
        secondary_track_type: item._source.secondary_track_type,
        seconday_sub_genre: item._source.seconday_sub_genre,
        stream_id: item._source.stream_id,
        sub_genre_name: item._source.sub_genre_name,
        title: item._source.title,
        track_title_language: item._source.track_title_language,
        ["music/album"]:
          Array.isArray(item._source["music/album"]) &&
          item._source["music/album"]?.length !== 0
            ? item._source["music/album"]?.join(",")
            : "",
        views: item._source.views,
        phonographic_copyright_year: item._source.phonographic_copyright_year,
        copyright_year: item._source.copyright_year,
        copyrighted_to: item._source.copyrighted_to,
      });
    });
  }

  let planList = [];

  if (props.plan && props.plan.hits) {
    props.plan.hits.hits &&
      props.plan.hits.hits.length > 0 &&
      props.plan.hits.hits.map((plan) => {
        planList.push({
          id: plan._id,
          name: plan._source.plan_name,
        });
      });
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      flex: 0.5,
    },
    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0">
            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              <div style={{ width: "60%" }}>
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.primary_artists?.length ? "By " : ""}
                  {params?.row?.primary_artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.primary_artists?.length - 1 &&
                        ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      field: "music/album",
      headerName: "Version",
      width: 150,
      flex: 1,
    },
    {
      field: "genre",
      headerName: "Genre",
      width: 200,
      renderCell: (params) => {
        return `${params.row.genre?.genre_name} > ${
          params.row.sub_genre_name?.subgenre_name
            ? params.row.sub_genre_name.subgenre_name
            : ""
        }`;
      },
      flex: 2,
    },
    {
      field: "isrc",
      headerName: "ISRC",
      width: 150,
      flex: 2,
    },
    {
      field: "plan_id",
      headerName: "Price tier",
      width: 150,
      renderCell: (params) => {
        return params.row.plan_id?.plan_name;
      },
      flex: 2,
    },

    {
      // field: "link",
      headerName: "View details",
      type: "actions",
      width: 100,
      renderCell: (params, event) => {
        return (
          <>
            <RemoveRedEyeIcon
              onClick={() => {
                setTrackData(params.row);
                setOpenTrackDialog(true);
              }}
            />
          </>
        );
      },
    },
  ];

  return (
    <Box width={"100%"}>
      <ReleaseDetailsTracksDialog
        open={openTrackDialog}
        onClose={() => {
          setOpenTrackDialog(false);
          setTrackData(null);
        }}
        trackData={trackData}
      />
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        // className="bg-warning"
        alignItems={"center"}
        justifyContent="space-between"
        my={0.5}
      >
        <Grid item>
          <Typography component={"span"}>
            {formatName(`${rows?.length}`)} Results found
          </Typography>
        </Grid>
      </Grid>

      {navigator.onLine ? (
        <Grid
          display="flex"
          justifyContent="center"
          container
          item
          lg={12}
          md={12}
          sm={12}
        >
          <LoadingIndicator isActive={props.isRequesting}>
            {props.track.hits ? (
              <DataGridFunction
                curser={"pointer"}
                columns={columns}
                rows={rows}
                rowHeight={60}
                height={rows.length * 60 + 100}
              />
            ) : null}
          </LoadingIndicator>
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(ReleaseDetailsTracks);
