import BaseReducer from "../../utilities/BaseReducer";
import ActressAction from "./ActressAction";

// ActressReuser
export default class ActressReducer extends BaseReducer {
  initialState = {
    actress: [],
  };
  [ActressAction.REQUEST_ACTRESS_FINISHED](state, action) {
    return {
      ...state,
      actress: action.payload,
    };
  }

  [ActressAction.REQUEST_ACTRESS_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      actress: action.payload,
    };
  }
}
