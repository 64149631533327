import ActionUtility from "../../utilities/ActionUtility";
import TopTerritoriesEffect from "./TopTerritoriesEffect";

export default class TopTerritoriesAction {
  // TopTerritoriesAction action with an function declaration
  static REQUEST_TOP_TERRITORIES =
    "TopTerritoriesAction.REQUEST_TOP_TERRITORIES";
  static REQUEST_TOP_TERRITORIES_FINISHED =
    "TopTerritoriesAction.REQUEST_TOP_TERRITORIES_FINISHED";

  static REQUEST_TOP_TERRITORY = "TopTerritoriesAction.REQUEST_TOP_TERRITORY";
  static REQUEST_TOP_TERRITORY_FINISHED =
    "TopTerritoriesAction.REQUEST_TOP_TERRITORY_FINISHED";

  static REQUEST_TOP_TERRITORIES_FILTER =
    "TopTerritoriesAction.REQUEST_TOP_TERRITORIES_FILTER";
  static REQUEST_TOP_TERRITORIES_FILTER_FINISHED =
    "TopTerritoriesAction.REQUEST_TOP_TERRITORIES_FILTER_FINISHED";

  // METHODS
  // blog GET function
  static requestTopTerritories(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTerritoriesAction.REQUEST_TOP_TERRITORIES,
        TopTerritoriesEffect.requestTopTerritories,
        params
      );
    };
  }

  static requestTopTerritory(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTerritoriesAction.REQUEST_TOP_TERRITORY,
        TopTerritoriesEffect.requestTopTerritory,
        params
      );
    };
  }

  static requestTopTerritoriesFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopTerritoriesAction.REQUEST_TOP_TERRITORIES_FILTER,
        TopTerritoriesEffect.requestTopTerritoriesFilter,
        params
      );
    };
  }
}
