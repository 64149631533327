import React, { useEffect, useState, useCallback } from "react";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import PostPlusButton from "../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import MuiButton from "../../components/muiButton/MuiButton";
import { Grid, TextField, Box } from "@mui/material";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DirectorAction from "../../stores/director/DirectorAction";
import SearchIcon from "@mui/icons-material/Search";
import DirectorPost from "./DirectorPost";
import Rettrive from "../releases/Rettrive";
import ImageIcon from "@mui/icons-material/Image";
import { theme } from "../App";
import RenderMedia from "../../components/renderMedia/RenderMedia";
import { faIndustry, faTrash } from "@fortawesome/free-solid-svg-icons";

// mapstatetoprops function

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    DirectorAction.REQUEST_DIRECTOR,
    DirectorAction.REQUEST_DIRECTOR_FILTER,
  ]),
  pagination: state.director.director || [],
  director: state.director.director || [],
  directorFilter: state.directorFilter.directorFilter || [],
});

function FeaturingDirector(props) {
  //******************************************************** SetState
  const [searchdirector, setSearchdirector] = useState(null);
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);

  // post plus button close model
  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // ***************************************************** Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      width: 100,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "profile_image_url",
      headerName: "",
      width: 70,
      renderCell: (params) => {
        return (
          <div>
            <Box
              style={{
                width: 37,
                height: 33,
                maxHeight: 33,
                backgroundColor: "#d0e9eb",
                display: "grid",
                placeItems: "center",
                overflow: "hidden",
              }}
            >
              {params?.row?.profile_image_url ? (
                <RenderMedia
                  image_url={params?.row?.profile_image_url}
                  mediaType="img"
                  height="100%"
                />
              ) : (
                <Box sx={{ padding: 1 }}>
                  <ImageIcon
                    style={{
                      width: "100%",
                      height: "100%",
                      color: theme.palette.primary.light,
                    }}
                  />
                </Box>
              )}
            </Box>
          </div>
        );
      },
    },

    {
      field: "director_name",
      headerName: "Director Name",
      width: 250,
    },

    {
      field: "created_by",
      headerName: "Created By",
      width: 250,
    },

    {
      width: 50,
      field: "actions",
      type: "actions",
      flex: 1,
      headerName: (
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        ></FontAwesomeIcon>
      ),
      renderCell: (params) => (
        <IconButton
          title="Edit"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setEditRowsModel(params.row);
            setOpen(true);
          }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
    {
      width: 50,
      flex: 1,
      field: "delete",
      type: "actions",
      headerName: (
        <FontAwesomeIcon
          icon={faTrash}
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        ></FontAwesomeIcon>
      ),
      renderCell: (params) => (
        <IconButton
          title="Delete"
          sx={{ border: "0" }}
          onClick={(event) => {
            event.ignore = true;
            if (params?.row?.id) {
              props
                .dispatch(DirectorAction.requestDeleteDirector(params.row.id))
                .then(() => {
                  const params = {
                    order_by: '[["created_on", "desc"]]',
                    page_limit: page_limit,
                  };
                  fetchDirector(params);
                  const filterparam = {
                    page_limit: "none",
                    order_by: `[["created_on","desc"]]`,
                  };

                  fetchDirectorFilter(filterparam);
                });
            }
          }}
        >
          <FontAwesomeIcon
            icon={faTrash}
            style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
  ];

  const { isRequesting } = props;

  // *********************************************  fetch methods

  function fetchDirector(params) {
    props.dispatch(DirectorAction.requestDirector(params));
  }

  function fetchDirectorFilter(param) {
    props.dispatch(DirectorAction.requestDirectorFilter(param));
  }

  // **************************************************  UseEffect
  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
    };
    fetchDirectorFilter(param);
    let params = {
      page_limit: `${page_limit}`,
      order_by: '[["created_on", "desc"]]',
    };
    fetchDirector(params);
  }, []);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setFilterLoader(false);
    setClearLoader(false);
    const filter = [];
    let params = {};

    if (searchdirector !== null) {
      filter.push(
        `["director_name.keyword","must","","wildcard","*${searchdirector}*"]`
      );
    }
    if (searchdirector !== null && FilterClicked) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["director_name.keyword","asc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["director_name.keyword","asc"]]`,
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchDirector(params);
    }
  };

  // *******************************************************  handle Filter
  const handleFilter = () => {
    const filter = [];

    if (searchdirector !== null) {
      filter.push(
        `["director_name.keyword","must","","wildcard","*${searchdirector}*"]`
      );
    }

    if (searchdirector !== null) {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };
      fetchDirector(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];

  if (props.director.hits) {
    props.director.hits.hitslength !== 0 &&
      props.director.hits.hits.map((item, index) => {
        return rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          _index: item._index,
          _score: item._score,
          director_name: item._source.director_name,
          created_by: item._source.created_by?.user_name,
          order_number: item._source.order_number,
          profile_image_url: item._source.profile_image_url,
        });
      });
  }

  // ****************************************** tag name handle change

  const DirectorListHandleChange = (event) => {
    setSearchdirector(event.target.value);
  };

  // ********************************************* Tag Filter List
  let DirectorList = [];
  if (props.directorFilter.hits) {
    props.directorFilter.hits.hits.forEach((value) => {
      DirectorList.push(value._source.director_name);
    });
  }

  const uniqueList = [...new Set(DirectorList)];

  //***************************************** */ project nama list

  // ************************************************* clear function

  const ClearState = () => {
    // Clear the state

    if (searchdirector !== null) {
      setClearLoader(true);
      setSearchdirector(null);
    }
    setFilterClicked(false);
    const params = {
      order_by: `[["created_on","desc"]]`,
      page_limit: 15,
    };
    FilterClicked && fetchDirector(params);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleFilter();
    }
  };
  return (
    <React.Fragment>
      {/* Tag post modal screen */}
      <Box>
        <DirectorPost
          open={open}
          onSubmit={() => {}}
          onClose={() => setOpen(false)}
          projectEditData={editRowsModel}
          edit={edit}
        />

        {/*************************************************** Tag Filter */}

        <Grid container>
          <Grid
            container
            lg={12}
            md={12}
            sm={12}
            justifyContent="center"
            spacing={1}
          >
            <Grid item lg={2} md={3} sm={3.5} mt={1}>
              <TextField
                variant="outlined"
                placeholder="Search Director Name"
                sx={{ width: "100%" }}
                value={searchdirector ? searchdirector : ""}
                size="small"
                onChange={DirectorListHandleChange}
                onKeyDown={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <IconButton aria-label="search" onClick={handleFilter}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={5}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                container
                spacing={2}
                justifyContent="flex-end"
                mt={0}
              >
                <Grid
                  lg={8}
                  sm={8}
                  md={8}
                  display={"flex"}
                  justifyContent={"end"}
                  mt={1}
                  mr={2}
                  sx={{ gap: 2 }}
                >
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      name={"Filter"}
                      width="100%"
                      onClick={handleFilter}
                      loading={filterLoader && isRequesting}
                    />
                  </Grid>
                  <Grid lg={3} md={3} sm={3}>
                    <MuiButton
                      width="100%"
                      loading={clearLoader && isRequesting}
                      name={"Clear"}
                      onClick={ClearState}
                    />
                  </Grid>
                  <Grid>
                    {/* post button component*/}
                    <PostPlusButton
                      open={open}
                      openCloseModal={openCloseModal}
                      editMode={editMode}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* data grid */}
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid item lg={8} md={12} sm={12} xs={12} mt={1}>
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {props.director.hits ? (
                    <DataGridFunction
                      columns={columns}
                      rows={rows}
                      rowHeight={60}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                    />
                  ) : null}
                </LoadingIndicator>
              </Grid>
            </Grid>
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(FeaturingDirector);
