import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import PostPlusButton from "../../components/postPlusButton/PostPlusButton";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import MuiButton from "../../components/muiButton/MuiButton";
import Grid from "@mui/material/Grid";
import { IconButton, useTheme } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import ClientAction from "../../stores/client/ClientAction";
import { useLocation, useNavigate } from "react-router";
import ClientRevenueSharePolicyAction from "../../stores/clientRevenueSharingPolicy/ClientRevenueSharePolicyAction";
import ClientRevenueSharePolicyPost from "./ClientRevenueSharePolicyPost";
import StreamPlatformAction from "../../stores/streamPlatform/StreamPlatformAction";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import SyncLabelSharePopper from "./SyncLabelSharePopper";
// mapstatetoprops function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ClientAction.REQUEST_CLIENT,
    ClientAction.REQUEST_CLIENT_FILTER,
    ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY,
    StreamPlatformAction.REQUEST_STREAM_PLATFORM_FILTER,
  ]),
  client: state.client.client || [],
  clientFilter: state.clientFilter.clientFilter || [],
  stream_platform_filter:
    state.stream_platform_filter.stream_platform_filter || [],
  clientRevenueSharePolicy:
    state.clientRevenueSharePolicy.clientRevenueSharePolicy || [],
  pagination: state.clientRevenueSharePolicy.clientRevenueSharePolicy || [],
});
function ClientRevenueSharePolicy(props) {
  const navigate = useNavigate();
  const location = useLocation();

  const clientID = location?.state?.client_id;
  const clientName = location?.state?.client_name;
  const userName = location?.state?.username;

  //******************************************************** SetState
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [edit, setEdit] = useState(false);
  const [page_limit] = useState(15);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [searchClient, setsearchClient] = useState(null);

  const { isRequesting } = props;

  // **************************************************  UseEffect
  function fetchClient(params) {
    props.dispatch(ClientAction.requestClient(params));
  }

  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }

  function fetchStreamPlatformFilter(param) {
    props.dispatch(StreamPlatformAction.requestPlatformFilter(param));
  }

  function fetchClientRevenueSharePolicy(params) {
    props.dispatch(
      ClientRevenueSharePolicyAction.requestClientRevenueSharePolicy(params)
    );
  }

  useEffect(() => {
    const param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      // filters: `[["parent_client_id.keyword","must","","match","*"]]`,
      current_page: "revenueSharePolicy",
    };
    fetchClientFilter(param);

    let filter_param = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "revenueSharePolicy",
    };

    fetchStreamPlatformFilter(filter_param);

    let list_param = {
      page_limit: page_limit,
      order_by: `[["created_on","desc"]]`,
      current_page: "revenueSharePolicy",
    };
    // fetchClient(list_param);
    fetchClientRevenueSharePolicy(list_param);
  }, []);

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);
  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  // ******************************************************  handle Filter
  const handleFilter = () => {
    const filter = [];

    if (searchClient !== null) {
      filter.push(
        `["client_id","must","","match","${searchClient.client_id}"]`
      );
    }

    if (searchClient) {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
      };

      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      fetchClientRevenueSharePolicy(params);
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  };

  const ClearState = () => {
    setFilterLoader(false);
    if (searchClient !== null) {
      setClearLoader(true);
      setsearchClient(null);
    }
    setFilterClicked(false);
    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: page_limit,
    };

    FilterClicked && fetchClientRevenueSharePolicy(params);
  };
  //********************************************************* pagination

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      // width: 100,
      // flex: 1,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "client_name",
      headerName: "Sub Client Name",
      width: 150,
      flex: 2,
    },
    {
      field: "standard_policy_share_percentage",
      headerName: "Standard Policy",
      flex: 2,
      //resizable: false,
      width: 100,
    },

    {
      field: "sync_label_share",
      type: "actions",
      headerName: "Sync Label Share",
      width: 160,
      hide: false,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <SyncLabelSharePopper clientId={params.row.id}>
          <IconButton
            title="Sync Label Share"
            sx={{ border: "0", color: theme.palette.primary.main }}
          >
            <CurrencyExchangeIcon fontSize="small" />
          </IconButton>
        </SyncLabelSharePopper>
      ),
    },
    // {
    //   field: "edit",
    //   type: "actions",
    //   headerName: "Edit",
    //   width: 160,
    //   hide: false,
    //   flex: 2,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <IconButton
    //       title="Edit"
    //       onClick={(event) => {
    //         event.ignore = true;
    //         setEdit(true);
    //         setOpen(true);
    //         setEditRowsModel(params.row);
    //       }}
    //       sx={{ border: "0" }}
    //     >
    //       {}
    //       <FontAwesomeIcon
    //         icon={faPenToSquare}
    //         style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
    //         title="Edit"
    //       ></FontAwesomeIcon>
    //     </IconButton>
    //   ),
    // },
  ];

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    setFilterLoader(false);
    setClearLoader(false);
    const filter = [];
    let params = {};

    if (searchClient !== null) {
      filter.push(
        `["client_id","must","","match","${searchClient.client_id}"]`
      );
    }

    if (searchClient !== null) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    } else {
      params = {
        page_number: `${value}` - 1,
        order_by: `[["created_on","desc"]]`,
        page_limit: `${page_limit}`,
      };
    }

    if (value - 1 !== props.pagination.current_page_no) {
      fetchClientRevenueSharePolicy(params);
    }
  };

  // ********************************************************* DataGrid  rows
  const rows = [];

  if (props.clientRevenueSharePolicy && props.clientRevenueSharePolicy.hits) {
    props.clientRevenueSharePolicy.hits.hits &&
      props.clientRevenueSharePolicy.hits.hits.length > 0 &&
      props.clientRevenueSharePolicy.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          client_id: item._source.client_id,
          standard_policy: item._source.standard_policy,
          per_store_share_policy: item._source.per_store_share_policy,
          per_territory_share_policy: item._source.per_territory_share_policy,
          client_name: item._source.client_id?.client_name,
          created_by: item._source.created_by.user_name,
          created_on: new Date(item._source.created_on).toLocaleString(),
          per_store_share_policy: item._source.per_store_share_policy,
          per_territory_share_policy: item._source.per_territory_share_policy,
          standard_policy_service_charge:
            item._source.standard_policy?.service_charge,
          standard_policy_share_percentage:
            item._source.standard_policy?.share_percentage,

          super_client: item._source.super_client,
          super_client_policy: item._source.super_client_policy,
        });
      });
  }

  let client_list = [];
  props.clientFilter?.hits?.hits?.length > 0 &&
    props.clientFilter?.hits?.hits.map((client) => {
      client_list.push({
        client_id: client._id,
        client_name: client._source?.client_name,
        username: client._source?.username,
      });
    });

  let stream_platform_list = [];
  props.stream_platform_filter?.hits?.hits?.length > 0 &&
    props.stream_platform_filter.hits.hits.map((platform) => {
      stream_platform_list.push({
        platform_id: platform._id,
        platform_name: platform._source?.stream_platform_name
          ? platform._source?.stream_platform_name
          : "",
      });
    });

  function clientSearchHandleChange(event, clientSearch) {
    setsearchClient(clientSearch);
  }

  useEffect(() => {
    if (clientID) {
      newAddSubClientEditData();
    }
  }, [clientID]);

  function newAddSubClientEditData() {
    setEditRowsModel({
      id: clientID,
      client_id: {
        client_id: clientID,
        client_name: clientName,
        username: userName,
      },
      standard_policy: {
        share_percentage: "",
        service_charge: "",
      },
      per_store_share_policy: [
        {
          store_name: "",
          share_percentage: "",
          service_charge: "",
        },
      ],
    });
    setOpen(true);
    setEdit(true);
  }

  return (
    <React.Fragment>
      <Box>
        <ClientRevenueSharePolicyPost
          open={open}
          onSubmit={() => {}}
          onClose={() => setOpen(false)}
          editData={editRowsModel}
          edit={edit}
          client_list={client_list}
          stream_platform_list={stream_platform_list}
          clientID={clientID}
        />

        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="space-between"
          pr={1}
          pb={1}
        >
          <Grid item container xl={9} lg={9} md={12} sm={12} xs={12} gap={2}>
            <Grid item lg={3} md={3} sm={3} mt={1}>
              <AutoCompleteComponent
                value={searchClient}
                list={client_list}
                textFieldProps={{
                  placeholder: "Sub Client Name",
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.client_name
                }
                getOptionLabel={(option) => option.client_name}
                renderOption={(props, item) => (
                  <li {...props} key={item.id}>
                    {item.client_name}
                  </li>
                )}
                handleChange={clientSearchHandleChange}
                size="small"
                width="100%"
                fieldheight="33px"
              />
            </Grid>
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={6}
            sm={7}
            mt={{ md: 1, lg: 0, sm: 1, xl: 0 }}
          >
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={2}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xl={4} lg={4} md={3} sm={4} mt={1}>
                <MuiButton
                  loading={filterLoader && isRequesting}
                  name={"Filter"}
                  width="100%"
                  onClick={handleFilter}
                />
              </Grid>
              <Grid item xl={4} lg={4} md={3} sm={4} mt={1}>
                <MuiButton
                  width="100%"
                  loading={clearLoader && isRequesting}
                  name={"Clear"}
                  onClick={ClearState}
                />
              </Grid>
              <Grid item xl={2} lg={1.8} md={1.8} sm={2} mt={1}>
                <PostPlusButton
                  open={open}
                  openCloseModal={openCloseModal}
                  editMode={editMode}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {navigator.onLine ? (
          <Box>
            <Grid
              display="flex"
              justifyContent="center"
              container
              item
              lg={12}
              md={12}
              sm={12}
            >
              {" "}
              <Grid
                conatiner
                item
                lg={12}
                xl={12}
                md={12}
                sm={12}
                xs={12}
                pr={1}
              >
                {/* loading icon */}
                <LoadingIndicator isActive={isRequesting}>
                  {/* datagrid Component */}
                  {props.clientRevenueSharePolicy.hits?.hits && (
                    <DataGridFunction
                      rows={rows}
                      columns={columns}
                      rowHeight={60}
                      pagelength={props.pagination.total_page_no}
                      page={props.pagination.current_page_no}
                      handleChange={handleChange}
                    />
                  )}
                </LoadingIndicator>
              </Grid>
            </Grid>
          </Box>
        ) : (
          alert("No Internet Connection") || (
            <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
          )
        )}
      </Box>
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(ClientRevenueSharePolicy);
