import TrackAction from "./TrackAction";
import BaseReducer from "../../utilities/BaseReducer";

// ExistingTrackReduser
export default class ExistingTrackReduser extends BaseReducer {
  initialState = {
    existingTracks: [],
  };
  [TrackAction.REQUEST_EXISTINGTRACKS_FINISHED](state, action) {
    return {
      ...state,
      existingTracks: action.payload,
    };
  }
}
