import React, { useEffect, useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  Grid,
  Typography,
  useTheme,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";

import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiButton from "../../components/muiButton/MuiButton";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import GenreAction from "../../stores/genre/GenreAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import SearchIcon from "@mui/icons-material/Search";

import DatePicker from "../../components/datePicker/DateRangePicker";
import { format } from "date-fns";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import NewReleaseDialog from "./NewReleaseDialog";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import {
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import ArtistAction from "../../stores/artist/ArtistAction";
import ReleaseTable from "./ReleaseTable";
import LabelAction from "../../stores/label/LabelAction";
import StatusAction from "../../stores/status/StatusAction";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import { useLocation } from "react-router";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_RELEASES,
    GenreAction.REQUEST_GENRE,
    ReleasesAction.REQUEST_RELEASE_FILTER,
    ArtistAction.REQUEST_ARTIST_FILTER,
    LabelAction.REQUEST_LABELS_FILTER,
    StatusAction.REQUEST_STATUS_FILTER,
  ]),
  release: state.release.release || [],
  releaseFilter: state.releaseFilter.releaseFilter || [],
  genre: state.genre.genre || [],
  pagination: state.release.release || [],
  artistFilter: state.artistFilter.artistFilter || [],
  statusFilter: state.statusFilter.statusFilter || [],
  labelFilter: state.labelFilter.labelFilter || [],
});

function Releases(props) {
  const { isRequesting } = props;

  const location = useLocation();
  let divertedStatus = location?.state?.status;

  const [page_limit] = useState(15);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [scroll, setScroll] = React.useState("body");
  const [FilterClicked, setFilterClicked] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);
  const [openTerritoryDialog, setOpenTerritoryyDialog] = React.useState(false);
  const [releaseDataIssue, setReleaseDataIssue] = useState(null);
  const [openIssueRaiseDialog, setOpenIssueRaiseDialog] = useState(false);

  const [pageNumber, setPageNumber] = useState(() => {
    if (divertedStatus) {
      if (localStorage.getItem("catalogueFilters")) {
        let filters = JSON.parse(localStorage.getItem("catalogueFilters"));
        filters.pageNumber = 1;
        localStorage.setItem("catalogueFilters", JSON.stringify(filters));
      }
      return 1;
    }
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters")).pageNumber;
    } else {
      return 1;
    }
  });

  const [title, setTitle] = useState(() => {
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters")).title;
    } else {
      return null;
    }
  });
  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("catalogueFilters"));
    if (filters) {
      filters.title = null;
      filters.searchLabel = null;
      filters.status = null;
      filters.searchGenre = null;
      filters.releaseType = null;
      filters.pageNumber = 1;
      filters.type = null;
      localStorage.setItem("catalogueFilters", JSON.stringify(filters));
    }
  }, []);

  const [searchLabel, setSearchLabel] = useState(() => {
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters")).searchLabel;
    } else {
      return null;
    }
  });

  const [searchGenre, setsearchGenre] = useState(() => {
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters")).searchGenre;
    } else {
      return null;
    }
  });

  const [status, setStatus] = useState(() => {
    if (divertedStatus) {
      if (localStorage.getItem("catalogueFilters")) {
        let filters = JSON.parse(localStorage.getItem("catalogueFilters"));
        filters.status = null;
        localStorage.setItem("catalogueFilters", JSON.stringify(filters));
      }
      return {
        id: divertedStatus.id,
        status_name: convertToTitleCase(divertedStatus.status_name),
      };
    }
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters")).status;
    } else {
      return null;
    }
  });

  const [type, setType] = useState(() => {
    if (divertedStatus) return "Status";
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters")).type;
    } else {
      return "";
    }
  });

  const [releaseType, setReleaseType] = useState(() => {
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters")).releaseType;
    } else {
      return null;
    }
  });
  const [createdDate, setCreatedDate] = useState(() => {
    if (localStorage.getItem("catalogueFilters")) {
      return [
        JSON.parse(localStorage.getItem("catalogueFilters")).createdDate &&
        JSON.parse(localStorage.getItem("catalogueFilters")).createdDate[0]
          ? JSON.parse(localStorage.getItem("catalogueFilters")).createdDate[0]
          : null,
        JSON.parse(localStorage.getItem("catalogueFilters")).createdDate &&
        JSON.parse(localStorage.getItem("catalogueFilters")).createdDate[1]
          ? JSON.parse(localStorage.getItem("catalogueFilters")).createdDate[1]
          : null,
      ];
    } else {
      return [null, null];
    }
  });

  const [producerCatalog, setProducerCatalog] = useState(() => {
    if (localStorage.getItem("catalogueFilters")) {
      return JSON.parse(localStorage.getItem("catalogueFilters"))
        .producerCatalog;
    } else {
      return null;
    }
  });

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("catalogueFilters"));
    if (filters) {
      filters.title && setTitle(filters.title);
      filters.searchLabel && setSearchLabel(filters.searchLabel);
      filters.searchGenre && setsearchGenre(filters.searchGenre);
      filters.status && setStatus(filters.status);
      filters.releaseType && setReleaseType(filters.releaseType);
      filters.type && setType(filters.type);
      filters.createdDate && setCreatedDate(filters.createdDate);
      filters.pageNumber && setPageNumber(filters.pageNumber);
      filters.producerCatalog && setProducerCatalog(filters.producerCatalog);
    }
  }, []);
  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleCategoryClose = () => {
    setOpenTerritoryyDialog(false);
  };

  function fetchArtistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }

  function fetchStatusFilter(param) {
    props.dispatch(StatusAction.requestStatusFilter(param));
  }

  useEffect(() => {
    const filter = [`["status", "must_not", "", "terms", [8]]`];
    if (title !== "" && type === "Title") {
      filter.push(
        `["title.keyword","must","","wildcard","*${escapeSequencing(title)}*"]`
      );
    }

    if (title !== "" && type === "UPC") {
      filter.push(`["UPC_EAN.keyword","must","","wildcard","*${title}*"]`);
    }

    if (title !== "" && type === "ISRC") {
      filter.push(`["isrc.keyword","must","","wildcard","*${title}*"]`);
    }

    if (searchLabel !== null) {
      filter.push(`["label.keyword","must","","match","${searchLabel.id}"]`);
    }

    if (searchGenre !== null) {
      filter.push(
        `["genre_type.keyword","must","","match","${searchGenre.id}"]`
      );
    }

    if (status !== null) {
      filter.push(`["status.keyword","must","","match","${status.id}"]`);
    }

    if (releaseType !== null) {
      filter.push(
        `["type_of_release.keyword","must","","match","${releaseType.toLowerCase()}"]`
      );
    }

    if (producerCatalog) {
      filter.push(
        `["producer_catalogue_number.keyword","must","","wildcard","*${producerCatalog}*"]`
      );
    }

    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }

    if (
      title !== "" ||
      searchLabel !== null ||
      searchGenre !== null ||
      status !== null ||
      releaseType !== null ||
      type !== null ||
      producerCatalog != null ||
      (createdDate[0] !== null && createdDate[1] !== null)
    ) {
      let params = {
        filters: `[${filter}]`,
        page_number: pageNumber > 0 ? pageNumber - 1 : 0,
        order_by: '[["modified_on", "desc"]]',
        page_limit: `${page_limit}`,
        current_page: "releases",
      };
      fetchReleases(params);

      const filterparam = {
        filters: `[${filter}]`,
        page_limit: "none",
        page_number: 0,
        order_by: `[["created_on","desc"]]`,
        current_page: "releases",
      };

      const param = {
        page_limit: "none",
        page_number: 0,
        order_by: `[["created_on","desc"]]`,
        current_page: "releases",
      };

      fetchGenre();
      // let label_filter = [`["status.keyword","must","","term","activated"]`];

      const label_param = {
        // filters: `[${label_filter}]`,
        page_limit: "none",
        order_by: `[["created_on","desc"]]`,
        current_page: "releaseInfo",
      };

      fetchArtistFilter(param);
      fetchLabelFilter(label_param);
      fetchStatusFilter(param);
      localStorage.setItem(
        "catalogueFilters",
        JSON.stringify({
          title,
          searchLabel,
          searchGenre,
          status,
          releaseType,
          createdDate,
          type,
          pageNumber: pageNumber,
          producerCatalog,
        })
      );
    }
  }, []);

  function fetchReleases(params) {
    props.dispatch(ReleasesAction.requesReleases(params));
  }

  function fetchGenre() {
    const param = {
      page_limit: "none",
      order_by: `[["genre_name.keyword", "asc"]]`,
      current_page: "releases",
    };
    props.dispatch(GenreAction.requestGenre(param));
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  let labelList = [];

  props?.labelFilter?.hits?.hits?.map((item) => {
    if (item?._source?.label_name)
      labelList.push({
        id: item?._id,
        label_name: item._source.label_name,
      });
  });
  labelList.sort((a, b) => a.label_name.localeCompare(b.label_name));
  function isObjectEqual(obj1, obj2) {
    return obj1.id === obj2.id;
  }

  labelList = labelList.filter(
    (obj, index, self) => index === self.findIndex((o) => isObjectEqual(o, obj))
  );

  let genreList = [];

  props?.genre?.hits?.hits?.map((item) => {
    if (item?._source?.genre_name)
      genreList.push({
        id: item?._id,
        genre_name: item._source.genre_name,
      });
  });

  function isObjectEqual(obj1, obj2) {
    return obj1.id === obj2.id;
  }

  genreList = genreList.filter(
    (obj, index, self) => index === self.findIndex((o) => isObjectEqual(o, obj))
  );

  let statusList = [];
  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  props?.statusFilter?.hits?.hits?.map((item) => {
    if (item?._source?.status_name)
      statusList.push({
        id: item?._id,
        status_name:
          item._source && item._source.status_name
            ? convertToTitleCase(item._source.status_name)
            : "",
      });
  });
  statusList.sort((a, b) => a.status_name.localeCompare(b.status_name));
  statusList = statusList.filter((item) => {
    return item.id !== "8";
  });

  let artistArray = [];

  if (props.artistFilter.hits) {
    props.artistFilter.hits.hits.forEach((value) => {
      artistArray.push({
        artist_id: value._id,
        artist_name: value._source.artist_name,
      });
    });
  }

  const handleSearchChange = (event) => {
    if (type == "Producer Catalog Number")
      setProducerCatalog(event.target.value);
    else setTitle(event.target.value);
  };

  const labelListHandleChange = (event, searchLabel) => {
    if (searchLabel != null) {
      setSearchLabel(searchLabel);
    } else {
      setSearchLabel(null);
    }
  };

  const genreListHandleChange = (event, searchGenre) => {
    if (searchGenre != null) {
      setsearchGenre(searchGenre);
    } else {
      setsearchGenre(null);
    }
  };

  const statusListHandleChange = (event, status) => {
    if (status !== null) {
      setStatus(status);
    } else {
      setStatus(null);
    }
  };

  const releaseTypeHandleChange = (event, type) => {
    if (type !== null) {
      setReleaseType(type);
    } else {
      setReleaseType(null);
    }
  };

  const [artist, setArtist] = useState([]);

  const artistListHandleChange = (event, value) => {
    if (value !== null) {
      setArtist(value);
    } else {
      setArtist(null);
    }
  };

  const isPastDate = (date) => {
    const now = new Date();
    return date <= now;
  };

  function CustomDateFilter() {
    let filterString = ``;

    if (createdDate[1].$d || createdDate[0].$d)
      filterString = `["main_release_date","must","lte","range","${format(
        createdDate[1].$d,
        "yyyy-MM-dd"
      )}"],["main_release_date","must","gte","range","${format(
        createdDate[0].$d,
        "yyyy-MM-dd"
      )}"]`;
    else {
      filterString = `["main_release_date","must","lte","range","${new Date(
        createdDate[1]
      )
        .toISOString()
        .slice(0, 10)}"],["main_release_date","must","gte","range","${new Date(
        createdDate[0]
      )
        .toISOString()
        .slice(0, 10)}"]`;
    }

    return filterString;
  }

  const releaseTypeList = ["audio", "video", "ringtone"];

  // Update status and clear other states when it has a value
  useEffect(() => {
    if (status) {
      setSearchLabel(null);
      setsearchGenre(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setTitle("");
      setArtist([]);
      setProducerCatalog(null);
    }
  }, [status]);

  useEffect(() => {
    if (searchLabel) {
      setStatus(null);
      setsearchGenre(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setTitle("");
      setArtist([]);
      setProducerCatalog(null);
    }
  }, [searchLabel]);

  useEffect(() => {
    if (searchGenre) {
      setStatus(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setTitle("");
      setArtist([]);
      setSearchLabel(null);
      setProducerCatalog(null);
    }
  }, [searchGenre]);

  useEffect(() => {
    if (releaseType) {
      setSearchLabel(null);
      setStatus(null);
      setsearchGenre(null);
      setCreatedDate([null, null]);
      setTitle("");
      setArtist([]);
      setProducerCatalog(null);
    }
  }, [releaseType]);

  useEffect(() => {
    if (createdDate[0] || createdDate[1]) {
      setSearchLabel(null);
      setsearchGenre(null);
      setStatus(null);
      setReleaseType(null);
      setTitle("");
      setArtist([]);
      setProducerCatalog(null);
    }
  }, [createdDate]);

  useEffect(() => {
    if (title) {
      setSearchLabel(null);
      setsearchGenre(null);
      setStatus(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setArtist([]);
      setProducerCatalog(null);
    }
  }, [title]);

  useEffect(() => {
    if (producerCatalog) {
      setSearchLabel(null);
      setsearchGenre(null);
      setStatus(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setTitle("");
      setArtist([]);
    }
  }, [producerCatalog]);

  function applyFilter() {
    const filter = [`["status", "must_not", "", "terms", [8]]`];

    if (title !== "" && type === "Title") {
      filter.push(
        `["title.keyword","must","","wildcard","*${escapeSequencing(title)}*"]`
      );
    }

    if (title !== "" && type === "UPC") {
      filter.push(`["UPC_EAN.keyword","must","","wildcard","*${title}*"]`);
    }

    if (title !== "" && type === "ISRC") {
      filter.push(`["isrc.keyword","must","","wildcard","*${title}*"]`);
    }

    if (searchLabel !== null) {
      filter.push(`["label.keyword","must","","match","${searchLabel.id}"]`);
    }

    if (searchGenre !== null) {
      filter.push(
        `["genre_type.keyword","must","","match","${searchGenre.id}"]`
      );
    }

    if (artist && artist.length !== 0) {
      filter.push(`["artist.keyword","must","","match","${artist}"]`);
    }

    if (status !== null) {
      filter.push(`["status.keyword","must","","match","${status.id}"]`);
    }

    if (releaseType !== null) {
      filter.push(
        `["type_of_release.keyword","must","","match","${releaseType.toLowerCase()}"]`
      );
    }

    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }

    if (producerCatalog) {
      filter.push(
        `["producer_catalogue_number.keyword","must","","wildcard","*${producerCatalog}*"]`
      );
    }

    if (
      (title && title !== "") ||
      searchLabel !== null ||
      searchGenre !== null ||
      status !== null ||
      releaseType !== null ||
      producerCatalog != null ||
      (createdDate[0] !== null && createdDate[1] !== null) ||
      (artist && artist.length !== 0)
    ) {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        current_page: "releases",
        order_by: '[["modified_on", "desc"]]',
      };
      fetchReleases(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      localStorage.setItem(
        "catalogueFilters",
        JSON.stringify({
          title,
          searchLabel,
          searchGenre,
          status,
          releaseType,
          createdDate,
          type,
          pageNumber: pageNumber,
          producerCatalog,
        })
      );
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  }

  const ClearState = () => {
    setType("");
    // Clear the state
    if (
      title !== "" ||
      searchLabel !== null ||
      searchGenre !== null ||
      status !== null ||
      releaseType !== null ||
      producerCatalog != null ||
      (createdDate[0] !== null && createdDate[1] !== null)
    ) {
      setClearLoader(true);
      setFilterLoader(false);
      setTitle("");
      setSearchLabel(null);
      setsearchGenre(null);
      setStatus(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setProducerCatalog(null);
      localStorage.removeItem(
        "catalogueFilters",
        JSON.stringify({
          title,
          searchLabel,
          searchGenre,
          status,
          releaseType,
          createdDate,
          type,
          pageNumber: pageNumber,
          producerCatalog,
        })
      );
    } else {
      setClearLoader(false);
    }
    setFilterClicked(false);
    const filter = [`["status", "must_not", "", "terms", [8]]`];

    const params = {
      filters: `[${filter}]`,
      order_by: '[["modified_on", "desc"]]',
      page_limit: page_limit,
      current_page: "releases",
    };
    // FilterClicked && fetchReleases(params);
    fetchReleases(params);
  };

  const dateValueHandleChange = (date) => {
    setCreatedDate(date);
  };

  const handleChange = (event, value, deleteAction) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    localStorage.setItem(
      "catalogueFilters",
      JSON.stringify({
        title,
        searchLabel,
        searchGenre,
        status,
        releaseType,
        createdDate,
        type,
        pageNumber: value,
        producerCatalog,
      })
    );

    setPageNumber(value);

    setFilterLoader(false);
    setClearLoader(false);

    const filter = [`["status", "must_not", "", "terms", [8]]`];
    let params = {};

    if (title !== "" && type === "Title") {
      filter.push(
        `["title.keyword","must","","wildcard","*${escapeSequencing(title)}*"]`
      );
    }

    if (title !== "" && type === "UPC") {
      filter.push(`["UPC_EAN.keyword","must","","term","${title}"]`);
    }

    if (title !== "" && type === "ISRC") {
      filter.push(`["isrc.keyword","must","","wildcard","*${title}*"]`);
    }

    if (searchLabel !== null) {
      filter.push(`["label.keyword","must","","match","${searchLabel.id}"]`);
    }

    if (searchGenre !== null) {
      filter.push(
        `["genre_type.keyword","must","","match","${searchGenre.id}"]`
      );
    }

    if (status !== null) {
      filter.push(`["status.keyword","must","","match","${status.id}"]`);
    }

    if (releaseType !== null) {
      filter.push(
        `["type_of_release.keyword","must","","match","${releaseType.toLowerCase()}"]`
      );
    }
    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }
    if (producerCatalog) {
      filter.push(
        `["producer_catalogue_number.keyword","must","","wildcard","*${producerCatalog}*"]`
      );
    }

    if (
      (title !== "" ||
        searchLabel !== null ||
        searchGenre !== null ||
        status !== null ||
        releaseType !== null ||
        producerCatalog != null ||
        (createdDate[0] !== null && createdDate[1] !== null)) &&
      FilterClicked
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: '[["modified_on", "desc"]]',

        page_limit: `${page_limit}`,
        current_page: "releases",
      };
    } else {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: '[["modified_on", "desc"]]',

        current_page: "releases",
        page_limit: `${page_limit}`,
      };
    }
    const isPageNumberDifferent =
      value - 1 !== props.pagination.current_page_no;
    if (isPageNumberDifferent || deleteAction) {
      fetchReleases(params);
    }
  };

  const fetchReleasesWithdraw = (event) => {
    // localStorage.setItem(
    //   "catalogueFilters",
    //   JSON.stringify({
    //     title,
    //     searchLabel,
    //     searchGenre,
    //     status,
    //     releaseType,
    //     createdDate,
    //     type,
    //     pageNumber: value,
    //   })
    // );

    const filter = [`["status", "must_not", "", "terms", [8]]`];
    let params = {};

    if (title !== "" && type === "Title") {
      filter.push(
        `["title.keyword","must","","wildcard","*${escapeSequencing(title)}*"]`
      );
    }

    if (title !== "" && type === "UPC") {
      filter.push(`["UPC_EAN.keyword","must","","term","${title}"]`);
    }

    if (title !== "" && type === "ISRC") {
      filter.push(`["isrc.keyword","must","","wildcard","*${title}*"]`);
    }

    if (searchLabel !== null) {
      filter.push(`["label.keyword","must","","match","${searchLabel.id}"]`);
    }

    if (searchGenre !== null) {
      filter.push(
        `["genre_type.keyword","must","","match","${searchGenre.id}"]`
      );
    }

    if (status !== null) {
      filter.push(`["status.keyword","must","","match","${status.id}"]`);
    }

    if (releaseType !== null) {
      filter.push(
        `["type_of_release.keyword","must","","match","${releaseType.toLowerCase()}"]`
      );
    }
    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }
    if (producerCatalog) {
      filter.push(
        `["producer_catalogue_number.keyword","must","","wildcard","*${producerCatalog}*"]`
      );
    }

    if (
      (title !== "" ||
        searchLabel !== null ||
        searchGenre !== null ||
        status !== null ||
        releaseType !== null ||
        producerCatalog != null ||
        (createdDate[0] !== null && createdDate[1] !== null)) &&
      FilterClicked
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: `${pageNumber}` - 1,
        order_by: '[["modified_on", "desc"]]',

        page_limit: `${page_limit}`,
        current_page: "releases",
      };
    } else {
      params = {
        filters: `[${filter}]`,
        page_number: `${pageNumber}` - 1,
        order_by: '[["modified_on", "desc"]]',

        current_page: "releases",
        page_limit: `${page_limit}`,
      };
    }
    fetchReleases(params);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      applyFilter();
    }
  };

  let filterArray = [
    "Status",
    "Type",
    "Title",
    // "Album",
    // "Artist",
    "Label",
    // "UPC",
    "ISRC",
    "Release Date",
    "Producer Catalog Number",
    // "Genre",
  ];

  const handleSelectChange = (e, v) => {
    setType(e.target.value);
    setTitle("");
    setSearchLabel(null);
    setsearchGenre(null);
    setStatus(null);
    setReleaseType(null);
    setCreatedDate([null, null]);
    setProducerCatalog(null);
  };

  let maxDate = new Date();
  const [deleteloading, setdeleteloading] = useState(false);

  function deleteRelease(id) {
    setdeleteloading(true);
    let page_params = {
      current_page: "releases",
    };
    props
      .dispatch(ReleasesAction.requestDeleteRelease(id, page_params))
      .then((event) => {
        let newPage = pageNumber;
        if (props.release?.hits?.total?.value % 15 == 1) {
          // if only one row is present
          newPage = pageNumber - 1;
          setPageNumber(newPage);
        }
        handleChange(null, newPage, true);
        setdeleteloading(false);
      });
  }

  const releasesTable = useMemo(
    () => (
      <ReleaseTable
        props={props}
        isRequesting={isRequesting}
        deleteRelease={deleteRelease}
        page_limit={page_limit}
        handleChange={handleChange}
        deleteloading={deleteloading}
        fetchReleasesWithdraw={() => {
          fetchReleasesWithdraw();
        }}
      />
    ),
    [props]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <Grid container sx={{ height: "100%", padding: 4 }}>
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          justifyContent="space-between"
          alignItems={"flex-end"}
        >
          <Grid item lg={3} md={3} sm={3}>
            {!props.isRequesting && (
              <Typography>
                {props?.release?.hits?.total?.value ?? "0"}&nbsp;
                {formatName("Results Found")}
              </Typography>
            )}
          </Grid>

          <Grid item lg={5} md={12} sm={12} container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={1}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                item
                lg={12}
                sm={7}
                md={7}
                sx={{ gap: 2 }}
                justifyContent="flex-end"
                container
                alignItems={"flex-start"}
              >
                <Grid item lg={3} md={3} sm={3}>
                  <SelectField
                    value={type}
                    name="Select Type"
                    label="Select Type"
                    placeholder="Select Type"
                    options={filterArray}
                    size="small"
                    handleSelectChange={handleSelectChange}
                  />
                </Grid>

                {(type === "Title" || type === "UPC" || type === "ISRC") && (
                  <Grid item lg={3} md={3} sm={3}>
                    <MuiTextField
                      variant="outlined"
                      placeholder={
                        type === "Title"
                          ? "Search Title"
                          : type === "UPC"
                          ? "Search UPC"
                          : type === "ISRC"
                          ? "Search ISRC"
                          : type === "Producer Catalog Number"
                          ? "Search Producer Catalog Number"
                          : ""
                      }
                      fieldheight="33px"
                      sx={{ width: "100%" }}
                      value={title}
                      size="small"
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyPress}
                      InputProps={{
                        endAdornment: <SearchIcon onClick={applyFilter} />,
                      }}
                    />
                  </Grid>
                )}
                {type === "Producer Catalog Number" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <MuiTextField
                      variant="outlined"
                      placeholder={"Search Producer Catalog Number"}
                      fieldheight="33px"
                      sx={{ width: "100%" }}
                      value={producerCatalog}
                      size="small"
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyPress}
                      InputProps={{
                        endAdornment: <SearchIcon onClick={applyFilter} />,
                      }}
                    />
                  </Grid>
                )}

                {type === "Genre" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      value={searchGenre}
                      list={genreList}
                      textFieldProps={{
                        placeholder: "Genre Name",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.genre_name
                      }
                      getOptionLabel={(option) => option.genre_name}
                      handleChange={genreListHandleChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>
                )}

                {type === "Label" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      value={searchLabel}
                      list={labelList}
                      textFieldProps={{
                        placeholder: "Label Name",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.label_name
                      }
                      getOptionLabel={(option) => option.label_name}
                      renderOption={(props, item) => (
                        <li {...props} key={item.id}>
                          {item.label_name}
                        </li>
                      )}
                      handleChange={labelListHandleChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>
                )}

                {type === "Status" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      value={status}
                      list={statusList}
                      textFieldProps={{
                        placeholder: "Status Name",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.status_name
                      }
                      getOptionLabel={(option) => option.status_name}
                      handleChange={statusListHandleChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>
                )}

                {type === "Type" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      value={releaseType}
                      list={["Audio", "Video"]}
                      textFieldProps={{
                        placeholder: "Type of Release",
                      }}
                      handleChange={releaseTypeHandleChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>
                )}

                {type === "Release Date" && (
                  <Grid item lg={5} md={5} sm={5}>
                    <DatePicker
                      value={
                        createdDate ? createdDate : [new Date(), new Date()]
                      }
                      onChange={dateValueHandleChange}
                      maxDate={maxDate}
                    />
                  </Grid>
                )}

                {/* {type === "Artist" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      multiple
                      value={artist}
                      list={artistArray}
                      textFieldProps={{
                        placeholder: "Artist Name",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.artist_name
                      }
                      getOptionLabel={(option) => option.artist_name}
                      handleChange={artistListHandleChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>
                )} */}

                <Grid item lg={1.5} md={2} sm={2}>
                  <MuiButton
                    name={"Filter"}
                    save
                    width="100%"
                    onClick={applyFilter}
                    loading={filterLoader && isRequesting}
                  />
                </Grid>
                <Grid item lg={1.5} md={2} sm={2}>
                  <MuiButton
                    cancel={true}
                    name={"Clear"}
                    width="100%"
                    onClick={ClearState}
                    loading={clearLoader && isRequesting}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          xl={12}
          md={12}
          sm={12}
          xs={12}
          mt={1}
          sx={{
            height: "-webkit-fill-available",
          }}
        >
          <LoadingIndicator isActive={isRequesting}>
            {isRequesting ? (
              <Box
                sx={
                  {
                    // display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    // height: "100%",
                    // backgroundColor: "yellow",
                  }
                }
              >
                <LoadingBgImage />
              </Box>
            ) : (
              releasesTable
            )}
          </LoadingIndicator>
        </Grid>
      </Grid>
    </Box>
  );
}
export default connect(mapStateToProps)(Releases);

const SelectField = ({
  label,
  name,
  options,
  value,
  size,
  placeholder,
  handleSelectChange,
}) => {
  return (
    <Grid container>
      <Select
        input={<OutlinedInput {...inputProps} />}
        MenuProps={menuProps}
        value={value}
        displayEmpty
        size={size}
        style={{ width: "100%" }}
        onChange={handleSelectChange}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

function escapeSequencing(text) {
  return text.replace(/(["'\\*])/g, "\\$1");
}
