import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { Box, Typography, CardMedia, ListItem } from "@mui/material";
import TrackAction from "../../../stores/track/TrackAction";
import { connect } from "react-redux";
import ImageIcon from "@mui/icons-material/Image";
import Rettrive from "../../releases/Rettrive";
import { theme } from "../../App";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import TopTrackAction from "../../../stores/topTrackReport/TopTrackAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
// import TopTrackAction from "../../stores/topTrackReport/TopTrackAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    TrackAction.REQUEST_TRACK_FILTER,
    TopTrackAction.REQUEST_TOPTRACK,
  ]),
  topTracks: state.topTrack.topTrack || [],
});

function TopTracksAnalytics(props) {
  const navigate = useNavigate();
  const { isRequesting } = props;

  function fetchTopTrack(param) {
    props.dispatch(TopTrackAction.requestTopTrack(param));
  }

  useEffect(() => {
    const toptrackparam = {
      order_by: `[["views","desc"]]`,
      filters: `[["content_type.keyword","must","","match","audio"]]`,
      current_page: "analytics",
    };
    // fetchTopTrack();
    fetchTopTrack(toptrackparam);
  }, []);

  const columns = [
    {
      field: "sl_no",
      headerName: "S No.",
      align: "center",
      headerAlign: "center",
    },
    // {
    //   field: "thumbnail_url",
    //   headerName: "",
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         <Box
    //           style={{
    //             width: 37,
    //             maxHeight: 37,
    //             padding: 5,
    //             // backgroundColor: "#d0e9eb",
    //             // display: "grid",
    //             // placeItems: "center",
    //           }}
    //         >
    //           {/* {params?.row?.thumbnail_url */}
    //           <Rettrive
    //             thumbnail_url={params?.row?.thumbnail_url}
    //             params={params}
    //             current_page={"analytics"}
    //           />
    //           {/* )} */}
    //         </Box>
    //       </div>
    //     );
    //   },
    // },
    {
      // width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        console.log("params", params);
        return (
          <ListItem className="p-0 m-0">
            <div
              style={
                {
                  // marginLeft: 5,
                  // width: "100%",
                  // display: "flex",
                }
              }
            >
              <div
              // style={{ width: "30%" }}
              >
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {}
                  {params &&
                  params.row &&
                  params.row.artists &&
                  params.row.artists.length !== 0
                    ? "By "
                    : ""}
                  {params.row &&
                    params.row.artists &&
                    params.row.artists.length !== 0 &&
                    params.row.artists.map((item, index) => (
                      <span key={index}>
                        {item.artist_id?.artist_name}
                        {index !== params?.row?.artists?.artists?.length - 1 &&
                          ", "}
                      </span>
                    ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },

    {
      field: "views",
      headerName: "Views",
      // width: 150,
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "genre",
      headerName: "Genre",
      // width: 250,
      flex: 1,
      hide: false,
    },
    {
      field: "isrc",
      headerName: "ISRC",
      // width: 250,
      flex: 1,
      hide: false,
    },
    {
      field: "created_on",
      headerName: "Created on",
      // width: 220,
      flex: 1,
      resizable: false,
    },
    {
      field: "production_year",
      headerName: "Production Year",
      // width: 150,
      flex: 1,
      resizable: true,
    },
    {
      field: "publisher",
      headerName: "Publisher",
      // width: 150,
      flex: 2,
      resizable: true,
    },
    {
      field: "release_id",
      headerName: "Release name",
      // width: 150,
      flex: 2,
      resizable: true,
    },
  ];

  let rows = [];

  if (props.topTracks && props.topTracks.hits) {
    props.topTracks.hits &&
      props.topTracks.hits.hits.length !== 0 &&
      props.topTracks.hits.hits.map((item, index) => {
        let primaryArtists = "";

        primaryArtists = item._source.primary_artists
          .map((artist) => artist.artist_id.artist_name)
          .join(", ");
        // console.log(
        //   "TopTracksAnalytics - pic",
        //   item._source.release_id?.thumbnail_url
        // );
        rows.push({
          id: item._id,
          sl_no: index + 1,
          thumbnail_url: item._source.release_id?.thumbnail_url,
          title: item._source.title,
          artists: item._source.primary_artists,
          isrc: item._source.isrc,
          created_on: new Date(item._source.created_on).toLocaleString(),
          genre: item._source.genre.genre_name,
          production_year: item._source.production_year,
          views: item._source.views,
          publisher: item._source.publisher,
          release_id: item._source.release_id.title,
        });
      });
  }

  const handleRowClick = (event, row) => {
    console.log("trackdata", event?.id);
    navigate(`/tracksAnalytics`, {
      state: {
        trackData: event?.id,
      },
    });
  };

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const filter = [];

    const params = {
      page_number: `${value}` - 1,
      order_by: `[["views","desc"]]`,
    };

    if (value - 1 !== props.pagination.current_page_no) {
      fetchTopTrack(params);
    }
  };

  return (
    <Box>
      <div style={{ height: 400, width: "100%" }}>
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting ? (
            <DataGridFunction
              curser={"pointer"}
              columns={columns}
              isRequesting={isRequesting}
              rows={rows}
              rowHeight={60}
              onRowClick={(e, r) => {
                handleRowClick(e, r);
              }}
              // pagelength={props.pagination.total_page_no}
              // page={props.pagination.current_page_no}
              // handleChange={handleChange}
            />
          ) : (
            <Box>
              <LoadingBgImage />
            </Box>
          )}
        </LoadingIndicator>
      </div>
    </Box>
  );
}

export default connect(mapStateToProps)(TopTracksAnalytics);
