import BaseReducer from "../../utilities/BaseReducer";
import TopAlbumAction from "./TopAlbumAction";

export default class TopAlbumFilterReducer extends BaseReducer {
  //initial state of TopAlbumFilterReduser
  initialState = {
    top_albums_report_filter: [],
  };
  //TopAlbumFilterReduser request action finish
  [TopAlbumAction.REQUEST_TOP_ALBUM_FILTER_FINISHED](state, action) {
    return {
      ...state,
      top_albums_report_filter: action.payload,
    };
  }
}
