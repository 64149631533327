import { BaseModel } from "sjs-base-model";

// TopComposerModel
export default class TopComposerModel extends BaseModel {
  // response getting from the api mentioned end point

  top_composers = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
