import BaseReducer from "../../utilities/BaseReducer";
import MigrateTracksAction from "./MigrateTracksAction";

// MigrateTracksReuser
export default class MigrateSpotifyTracksReducer extends BaseReducer {
  initialState = {
    spotifyMigrateTrack: [],
  };
  [MigrateTracksAction.REQUEST_POST_MIGRATESPOTIFY_FINISHED](state, action) {
    return {
      ...state,
      spotifyMigrateTrack: action.payload,
    };
  }

  // [MigrateTracksAction.REQUEST_MIGRATETRACKS_CLEAR_FINISHED](state, action) {
  //   return {
  //     ...state,
  //     spotifyMigrateTrack: action.payload,
  //   };
  // }
}
