import { Box, Tab, Grid, Typography, IconButton } from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { connect } from "react-redux";
import environment from "environment";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { theme } from "../App";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import ReleaseDetailsTracks from "./ReleaseDetailsTracks";
import ReleaseDetailsHistory from "./ReleaseDetailsHistory";
import ReleaseSubmissionHistory from "./ReleaseSubmissionHistory";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [ReleasesAction.REQUEST_RELEASE_BY_ID]),
  releaseById: state.releaseById.releaseById || {},
});

const ReleaseDetails = (props) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [imgUrl, setImgUrl] = useState("");
  const [imageLoader, setImageLoader] = useState(false);
  const [value, setValue] = React.useState("1");
  const [trackId, settrackId] = React.useState(null);
  const [releaseId] = useState(localStorage.getItem("release_details_id"));

  const [open, setOpen] = React.useState(false);

  const [tabvalue, settabvalue] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function fetchReleaseById(releaseId, params) {
    const callBack = (res) => {
      if (res?.found === true) {
        setImgUrl(() => {
          res?._source?.thumbnail_url &&
            RetrieveImageUrl(res?._source?.thumbnail_url);
        });
      }
    };
    props.dispatch(
      ReleasesAction.requestReleaseById(releaseId, params, callBack)
    );
  }

  useEffect(() => {
    const param = {
      current_page: "release_details",
    };

    releaseId ? fetchReleaseById(releaseId, param) : navigate("/releases");
  }, []);

  function RetrieveImageUrl(thumbnail_url) {
    setImageLoader(true);
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let imageUrl = "";
    fetch(
      `${environment.api.retriveAsset}?current_page=release_details&path=thumbnail/${thumbnail_url}&release_id=${releaseId}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageLoader(false);
        imageUrl = URL.createObjectURL(blob);

        setImgUrl(imageUrl);
      });
  }

  return (
    <Box p={1}>
      <Grid item lg={2} md={2} sm={2} xs={2}>
        <IconButton>
          <ArrowCircleLeftIcon
            style={{
              height: "40px",
              width: "40px",
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/releases");
              localStorage.removeItem("release_details_id");
            }}
          />
        </IconButton>
      </Grid>
      <Grid
        item
        container
        xl={12}
        lg={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent={"space-between"}
        // className="bg-warning"
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={10}
          container
          alignItems={"center"}
          justifyContent="flex-start"
        >
          <Grid item>
            <Typography sx={{ fontSize: 20 }}>
              {formatName(`Release Details`)}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <LoadingIndicator isActive={props.isRequesting}>
        {
          <div style={{ marginBottom: "20px" }}>
            <Box className="d-flex">
              <Grid
                item
                sx={{
                  width: {
                    lg: "273px",
                    md: "200px",
                    sm: "150px",
                  },
                  height: {
                    lg: "250px",
                    md: "180px",
                    sm: "130px",
                  },
                  // border: `1px solid ${theme.palette.borderColor.imageBox}`,
                  // backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  m: 0,
                  p: 0,
                }}
              >
                {imgUrl ? (
                  <Box
                    sx={{
                      width: {
                        lg: "273px",
                        md: "200px",
                        sm: "150px",
                      },
                      height: {
                        lg: "250px",
                        md: "180px",
                        sm: "130px",
                      },
                      backgroundColor: theme.palette.background.imageBox,
                    }}
                  >
                    <img
                      src={imgUrl}
                      alt="Cover Picture"
                      onError={() => {
                        // Error handling code goes here
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        overflow: "hidden",
                        // objectFit: "cover",
                      }}
                    />
                  </Box>
                ) : imageLoader ? (
                  <CircleLoader />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faFileArrowUp}
                      style={{
                        color: theme.palette.common.grey,
                        width: "37px",
                        height: "50px",
                      }}
                    ></FontAwesomeIcon>
                    <Typography
                      component={"span"}
                      sx={{
                        color: theme.palette.common.grey,
                        fontSize: "16px",
                        mt: "15px",
                      }}
                    ></Typography>
                  </Box>
                )}
              </Grid>

              <Grid container item lg={12} md={12} sm={12} ml={3} gap={2}>
                <Grid item lg={8} md={12} sm={12}>
                  <h2>{props.releaseById?._source?.title}</h2>
                </Grid>
                <Grid item lg={5.5} md={8} sm={12}>
                  {[
                    {
                      label: "Title",
                      value: props.releaseById?._source?.title,
                    },
                    { label: "Version", value: null },
                    {
                      label: "Label",
                      value: props.releaseById?._source?.label?.label_name,
                    },
                    {
                      label: "UPC",
                      value: props.releaseById?._source?.UPC_EAN,
                    },
                    {
                      label: "Catalog no.",
                      value:
                        props.releaseById?._source?.producer_catalogue_number,
                    },
                    {
                      label: "Genre",
                      value:
                        props.releaseById?._source?.genre_type?.genre_name &&
                        `${
                          props.releaseById?._source?.genre_type?.genre_name
                            ? props.releaseById?._source?.genre_type?.genre_name
                            : ""
                        } > ${
                          props.releaseById?._source?.sub_genre_type
                            ?.subgenre_name
                            ? props.releaseById?._source?.sub_genre_type
                                ?.subgenre_name
                            : ""
                        }`,
                    },
                    { label: "Compilation", value: null },
                  ].map((item, index) => (
                    <Typography
                      key={index}
                      component={"p"}
                      width="100%"
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold" }}
                        width="50%"
                      >
                        {item.label} :
                      </Typography>
                      <Typography component={"span"} width="50%">
                        {item.value}
                      </Typography>
                    </Typography>
                  ))}
                </Grid>
                <Grid item lg={5.5} md={8} sm={12}>
                  {[
                    {
                      label: "Release Date",
                      value: props.releaseById?._source?.main_release_date,
                    },
                    {
                      label: "Original Release Date",
                      value:
                        props.releaseById?._source?.physical_release_date?.slice(
                          0,
                          10
                        ),
                    },
                    {
                      label: "Pre-Order Date",
                      value: props.releaseById?._source?.pre_order_release_date,
                    },
                    {
                      label: "Price Tier",
                      value: props.releaseById?._source?.plan_id?.plan_name,
                    },
                    {
                      label: "C Line",
                      value: `${
                        props.releaseById?._source?.copyrighted_to ? "©" : ""
                      } ${props.releaseById?._source?.copyright_year ?? ""} ${
                        props.releaseById?._source?.copyrighted_to ?? ""
                      }`,
                    },
                    {
                      label: "P Line",
                      value: `${
                        props.releaseById?._source?.published_by ? "Ⓟ" : ""
                      } ${
                        props.releaseById?._source
                          ?.phonographic_copyright_year ?? ""
                      } ${props.releaseById?._source?.published_by ?? ""}`,
                    },
                  ].map((item, index) => (
                    <Typography
                      key={index}
                      component={"p"}
                      width="100%"
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography
                        component={"span"}
                        sx={{ fontWeight: "bold" }}
                        width="50%"
                      >
                        {item.label} :
                      </Typography>
                      <Typography component={"span"} width="50%">
                        {item.value}
                      </Typography>
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <br />

            <TabContext value={value}>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.tab,
                  boxShadow: "0 3px 6px 0px #c6bcbc33",
                }}
              >
                <TabList
                  onChange={handleChange}
                  sx={{
                    "& .MuiTabs-indicator": { display: "none" },
                    "& .MuiTab-root": {
                      position: "relative",
                      zIndex: 100,
                      border: 0,
                      height: "54px",
                      borderRadius: 0,
                      backgroundColor: theme.palette.background.tab,
                      textTransform: "none",
                      color: "#2a5e6c",
                      "&.Mui-selected": {
                        borderRadius: "0 0 15px 0",
                        border: `1px solid #6c64640a`,
                        boxShadow: theme.shadows,
                        zIndex: 2,
                        backgroundColor: theme.palette.background.default,
                        color: theme.palette.text.primary,
                      },
                    },
                  }}
                >
                  <Tab label="Tracks" value="1" />
                  <Tab label="History" value="2" />
                  <Tab label="Submission History" value="3" />
                </TabList>
              </Box>

              <Box
                className="scrollable"
                sx={{ height: `auto`, width: "100%" }}
              >
                <TabPanel value="1" sx={{ padding: 0, margin: 0 }}>
                  <ReleaseDetailsTracks
                    releaseId={releaseId}
                    settrackId={settrackId}
                    settabvalue={settabvalue}
                  />
                </TabPanel>

                <TabPanel value="2" sx={{ padding: 0, margin: 0 }}>
                  <ReleaseDetailsHistory releaseId={releaseId} />
                </TabPanel>
                <TabPanel value="3" sx={{ padding: 0, margin: 0 }}>
                  <ReleaseSubmissionHistory releaseData={props.releaseById} />
                </TabPanel>
              </Box>
            </TabContext>
          </div>
        }
      </LoadingIndicator>
    </Box>
  );
};

export default connect(mapStateToProps)(ReleaseDetails);
