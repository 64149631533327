import { BaseModel } from "sjs-base-model";

// TopLanguagesModel
export default class TopLanguagesModel extends BaseModel {
  // response getting from the api mentioned end point

  top_languages = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
