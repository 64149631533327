import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ReleasesShadowTableIdModel from "./model/ReleasesShadowTableIdModel";
import ReleasesShadowTableModel from "./model/ReleasesShadowTableModel";

// ReleasesShadowTableEffect
export default class ReleasesShadowTableEffect {
  // calling an API according to the label model
  static async requestReleasesShadowTable(params) {
    const endpoint = environment.api.releasesShadowTable;
    return EffectUtility.getToModel(ReleasesShadowTableModel, endpoint, params);
  }

  static async requestReleasesShadowTableClear() {
    return {};
  }

  static async requestReleasesShadowTableById(params) {
    const endpoint = environment.api.releasesShadowTable;
    return EffectUtility.getToModel(ReleasesShadowTableModel, endpoint, params);
  }
}
