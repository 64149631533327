import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TopLyricistModel from "./model/TopLyricistModel";

// TopLyricistEffect
export default class TopLyricistEffect {
  // calling an API according to the plan model
  static async requestTopLyricist(params) {
    const endpoint = environment.api.top_lyricists_report;
    return EffectUtility.getToModel(TopLyricistModel, endpoint, params);
  }

  // Get method for the plan filter
  static async requestTopLyricistFilter(params) {
    const endpoint = environment.api.top_lyricists_report;
    return EffectUtility.getToModel(TopLyricistModel, endpoint, params);
  }
}
