import React, { useEffect } from "react";
import SignIn from "../../views/Login/SignIn";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Analytics, LogoutOutlined, Person } from "@mui/icons-material";
import Logout from "../../views/logout/Logout";
import PageNotFound from "../PageNotFound";
import Dashboard from "../../views/dashboard/Dashboard";
import Releases from "../../views/releases/Releases";
import AnalyticsMain from "../../views/analytics/Analytics";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import Client from "../../views/client/Client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import UserAccess from "../../views/useraccess/UserAccess";
import Profile from "../../views/profile/Profile";
import Promote from "../../views/promote/Promote";
import Finances from "../../views/finances/Finances";
import DeliveredReleases from "../../views/deliveredReleases/DeliveredReleases";
import Issues from "../../views/issues/Issues";
import ReleaseInformation from "../../views/ReleaseSubPages/ReleaseInformation";
import Upload from "../../views/ReleaseSubPages/Upload";
import Tracks from "../../views/ReleaseSubPages/Tracks";
import Releasedate from "../../views/ReleaseSubPages/Releasedate";
import Submission from "../../views/ReleaseSubPages/Submission";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import QueueMusicIcon from "@mui/icons-material/QueueMusic";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import InfoIcon from "@mui/icons-material/Info";
import ShadowTabel from "../../views/ReleaseSubPages/ShadowTabel";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";
import FeaturingActor from "../../views/actor/FeaturingActor";
import FeaturingActress from "../../views/actress/FeaturingActress";
import FeaturingDirector from "../../views/director/FeaturingDirector";
import NoDataFound from "../nodatafound/NoDataFound";
import DeliveredReleaseDetails from "../../views/deliveredReleases/DeliveredReleaseDetails";
import Promotion from "../../views/ReleaseSubPages/Promotion";
import LabelGridView from "../../views/label/LabelGridView";
import NotificationList from "../../views/notification/NotificationList";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ReleaseDetails from "../../views/releaseDetails/ReleaseDetails";
import MigrateTracks from "../../views/migrateTracks/migrateTracks";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import PaymentIcon from "@mui/icons-material/Payment";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SummarizeIcon from "@mui/icons-material/Summarize";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import PaidIcon from "@mui/icons-material/Paid";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import ClientRevenueSharePolicy from "../../views/revenue/ClientRevenueSharePolicy";
import TrackRevenue from "../../views/revenue/TrackRevenue";
import RevenueReport from "../../views/revenue/RevenueReport";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import TracksAnalytics from "../../views/analytics/components/TracksAnalytics";
import A3Reports from "../../views/reports/A3Reports";
import NetRevenueByMonth from "../../views/monthwiseRevenue/NetRevenueByMonth";
import YTRevenueReport from "../../views/revenue/YTRevenueReport";
import YTNetRevenueByMonth from "../../views/monthwiseRevenueYT/YTNetRevenueByMonth";
import RevenueClaim from "../../views/revenueClaim/RevenueClaim";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import DynamicReport from "../../views/dashboard1/DynamicReport";
const idFromLocalStorage = localStorage.getItem("id");

const routes = {
  data: [
    // Dashboard page

    {
      category: "Main",
      path: "/dashboard" || "/",
      element: <Dashboard />,
      id: "dashboard",
      title: "Dashboard",
      icon: <DashboardIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Release",
      path: "/delivered",
      element: <DeliveredReleases />,
      id: "delivered",
      title: "Delivered",
      icon: <SummarizeIcon />,
      type: "item",
      roles: [],
    },

    //Releases Page
    {
      category: "Release",
      path: "/releases",
      element: <Releases />,
      id: "releases",
      title: "Catalog",
      icon: <DashboardIcon />,
      type: "item",
      roles: [],
    },
    // release info
    {
      category: "Release",
      path: "/releaseInfo",
      element: <ReleaseInformation />,
      id: "releaseInfo",
      title: "Release Information",
      icon: <InfoIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      // path: `/upload/${idFromLocalStorage}`,
      path: `/upload`,
      element: <Upload />,
      id: "upload",
      title: "Upload",
      icon: <FileUploadIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      // path: `/tracks/${idFromLocalStorage}`,
      path: `/tracks`,
      element: <Tracks />,
      id: "tracks",
      title: "Tracks",
      icon: <QueueMusicIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      // path: `/releaseDate/${idFromLocalStorage}`,
      path: `/releaseDate`,
      element: <Releasedate />,
      id: "releaseDate",
      title: "Release Date",
      icon: <ScheduleIcon />,
      type: "item",
      roles: [],
      disable: true,
    },
    {
      category: "Release",
      path: `/promotion`,
      element: <Promotion />,
      id: "promotion",
      title: "Promotion",
      icon: <QueueMusicIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    {
      category: "Release",
      // path: `/submission/${idFromLocalStorage}`,
      path: `/submission`,
      element: <Submission />,
      id: "submission",
      title: "Submission",
      icon: <TaskAltIcon />,
      type: "item",
      roles: [],
      disable: true,
    },

    // {
    //   category: "Main",
    //   path: "/FeaturingActor",
    //   element: <FeaturingActor />,
    //   id: "FeaturingActor",
    //   title: "FeaturingActor",
    //   icon: <DashboardIcon />,
    //   type: "item",
    //   roles: [],
    // },

    // {
    //   category: "Main",
    //   path: "/FeaturingActress",
    //   element: <FeaturingActress />,
    //   id: "FeaturingActress",
    //   title: "FeaturingActress",
    //   icon: <DashboardIcon />,
    //   type: "item",
    //   roles: [],
    // },
    // {
    //   category: "Main",
    //   path: "/FeaturingDirector",
    //   element: <FeaturingDirector />,
    //   id: "FeaturingDirector",
    //   title: "FeaturingDirector",
    //   icon: <DashboardIcon />,
    //   type: "item",
    //   roles: [],
    // },

    //SIGN IN ROUTE DATA
    {
      category: "Auth",
      path: "/",
      element: <SignIn />,
      id: "sign",
      title: localStorage.getItem("Auth") ? null : "Sign In",
      icon: <InboxIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Main",
      path: "/",
      element: <Dashboard />,
      id: "dashboard",
      title: "Dashboard",
      icon: <DashboardIcon />,
      type: "item",
      roles: [],
      hide: true,
    },
    {
      category: "Main",
      path: "/report",
      element: <DynamicReport />,
      id: "dashboard",
      title: "Report",
      icon: <AccountBoxIcon />,
      type: "item",
      roles: [],
      hide: true,
    },
    // {
    //   category: "Main",
    //   path: "/promote",
    //   // element: <PageNotFound page="Promotion" />,
    //   element: <Promote />,
    //   id: "promote",
    //   title: "Promote",
    //   icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
    //   type: "item",
    //   roles: [],
    // },
    //status page
    // {
    //   category: "Main",
    //   path: "/status",
    //   element: <Status />,
    //   id: "status",
    //   title: "Status",
    //   icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
    //   type: "item",
    //   roles: [],
    // },
    // label page
    {
      category: "Main",
      path: "/label",
      element: <LabelGridView />,
      id: "label",
      title: "Label",
      icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
      type: "item",
      roles: [],
    },
    // {
    //   category: "Main",
    //   path: "/assets",
    //   element: <Assets />,
    //   id: "assets",
    //   title: "Assets",
    //   icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
    //   type: "item",
    //   roles: [],
    // },

    //stream Platform page
    // {
    //   category: "Main",
    //   path: "/releasePlatform",
    //   element: <ReleasePlatformGridView />,
    //   id: "releasePlatform",
    //   title: "Release Platform",
    //   icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
    //   type: "item",
    //   roles: [],
    // },

    // {
    //   category: "Main",
    //   path: "/artist",
    //   element: <Artist />,
    //   id: "artist",
    //   title: "Artist",
    //   icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
    //   type: "item",
    //   roles: [],
    // },

    // {
    //   category: "Main",
    //   path: "/track",
    //   element: <Track />,
    //   id: "track",
    //   title: "Track",
    //   icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
    //   type: "item",
    //   roles: [],
    // },

    //genre page
    // {
    //   category: "Main",
    //   path: "/genre",
    //   element: <Genre />,
    //   id: "genre",
    //   title: "Genre",
    //   icon: <PlaylistPlayIcon />,
    //   type: "item",
    //   roles: [],
    // },

    //subgenre page
    // {
    //   category: "Main",
    //   path: "/subgenre",
    //   element: <Subgenre />,
    //   id: "subgenre",
    //   title: "Subgenre",
    //   icon: <FontAwesomeIcon icon={faTicket}></FontAwesomeIcon>,
    //   type: "item",
    //   roles: [],
    // },

    // Analytics Page Route Data
    // {
    //   category: "Main",
    //   path: "/analytics",
    //   element: <AnalyticsMain />,
    //   id: "analytics",
    //   title: "Analytics",
    //   icon: <Analytics />,
    //   type: "item",
    //   roles: [],
    // },

    {
      category: "Main",
      path: "/profile",
      element: <Profile />,
      id: "profile",
      title: "Profile",
      icon: <AccountBoxIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    //client page
    {
      category: "Main",
      path: "/subClient",
      element: <Client />,
      id: "subClient", // IF you want to change this Subclient Page id, please change it in UserAccessPost page also
      title: "Sub-Client",
      icon: <GroupIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/subClientAccess",
      element: <UserAccess />,
      id: "subClientAccess", // IF you want to change this Subclient access Page id, please change it in UserAccessPost page also
      title: "Sub-Client Access",
      icon: <AdminPanelSettingsIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    // Logout Page
    {
      category: "Logout",
      path: "/logout",
      element: <Logout />,
      id: "logout",
      title: "Logout",
      icon: <LogoutOutlined />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "*",
      element: <PageNotFound page="NoDataFound" />,
      id: "pageNotFound",
      title: "Page Not Found",
      icon: <ScheduleIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    //Finances
    // {
    //   category: "Main",
    //   path: "/revenue",
    //   element: <Finances />,
    //   id: "revenue",
    //   title: "Revenue",
    //   icon: <ScheduleIcon />,
    //   type: "item",
    //   roles: [],
    // },

    {
      category: "Main",
      path: "/requests",
      element: <Issues />,
      id: "requests",
      title: "Requests",
      icon: <ScheduleIcon />,
      type: "item",
      roles: [],
    },

    // {
    //   category: "Main",
    //   path: `/releaseActivities`,
    //   element: <ShadowTabel />,
    //   id: "releaseActivities",
    //   title: "Release Activities",
    //   icon: <AccountTreeIcon />,
    //   type: "item",
    //   roles: [],
    //   disable: true,
    // },

    {
      category: "Main",
      path: `/deliveredRelease`,
      element: <DeliveredReleaseDetails />,
      id: "delivered_release_details",
      title: "Delivered Release Details",
      icon: <AccountTreeIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: `/notifications`,
      element: <NotificationList />,
      id: "notifications",
      title: "Notifications",
      icon: <NotificationsIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: `/release_details`,
      element: <ReleaseDetails />,
      id: "release_details",
      title: "Release Details",
      icon: <AccountTreeIcon />,
      type: "item",
      roles: [],
      hide: true,
    },

    {
      category: "Main",
      path: "/analytics",
      element: <AnalyticsMain />,
      id: "analytics",
      title: "Stream Analytics",
      icon: <Analytics />,
      type: "item",
      roles: [],
    },
    {
      category: "Main",
      path: "/tracksAnalytics",
      element: <TracksAnalytics />,
      id: "tracksAnalytics",
      title: "Track Analytics",
      icon: <Analytics />,
      type: "item",
      roles: [],
      hide: true,
    },

    // {
    //   category: "Main",
    //   path: `/migrateTracks`,
    //   element: <MigrateTracks />,
    //   id: "migrateTracks",
    //   title: "Migrate Tracks",
    //   icon: <MultipleStopIcon />,
    //   type: "item",
    //   roles: [],
    // },

    {
      category: "Revenue",
      path: "/reports",
      element: <A3Reports />,
      id: "raw_report",
      title: "Life Time Revenue",
      icon: <AssessmentIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Revenue",
      path: `/monthwiseRevenue`,
      element: <NetRevenueByMonth />,
      id: "monthwise_revenue",
      title: "Monthwise Revenue",
      icon: <CalendarMonthIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Revenue",
      path: `/trackRevenue`,
      element: <TrackRevenue />,
      id: "trackRevenue",
      title: "Cumulative Tracks",
      icon: <AccountBalanceWalletIcon />,
      type: "item",
      roles: [],
    },
    {
      category: "Revenue",
      path: `/revenueReport`,
      element: <RevenueReport />,
      id: "revenueReport",
      title: "Revenue Report",
      icon: <RequestQuoteIcon />,
      type: "item",
      roles: [],
    },

    {
      category: "Revenue",
      path: `/revenueSharePolicy`,
      element: <ClientRevenueSharePolicy />,
      id: "revenueSharePolicy", // IF you want to change this revenueSharePolicy Page id, please change it in UserAccessPost page also
      title: "Subclient Settings",
      icon: <PaidIcon />,
      type: "item",
      roles: [],
    },
    // {
    //   category: "Revenue",
    //   path: `/YTmonthwiseRevenue`,
    //   element: <YTNetRevenueByMonth />,
    //   id: "monthwise_yt_revenue",
    //   title: "YT Monthwise Revenue",
    //   icon: <CalendarMonthIcon />,
    //   type: "item",
    //   roles: [],
    // },

    {
      category: "Revenue",
      path: "/revenueClaim",
      element: <RevenueClaim />,
      id: "revenueClaim",
      title: "Payment Status",
      icon: <PointOfSaleIcon />,
      type: "item",
      roles: [],
    },

    // {
    //   category: "Revenue",
    //   path: `/YTrevenueReport`,
    //   element: <YTRevenueReport />,
    //   id: "ytRevenueReport",
    //   title: "YT Revenue Export",
    //   icon: <RequestQuoteIcon />,
    //   type: "item",
    //   roles: [],
    // },
  ],
};

export default routes;
