import { BaseModel } from "sjs-base-model";

// ReleaseStatusCountPutModel
export default class ReleaseStatusCountPutModel extends BaseModel {
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
