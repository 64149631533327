import ActionUtility from "../../utilities/ActionUtility";
import TicketsEffect from "./TicketsEffect";

export default class TicketsAction {
  // TicketsAction action with an function declaration
  static REQUEST_TICKETS = "TicketsAction.REQUEST_TICKETS";
  static REQUEST_TICKETS_FINISHED = "TicketsAction.REQUEST_TICKETS_FINISHED";

  static REQUEST_TICKETS_FILTER = "TicketsAction.REQUEST_TICKETS_FILTER";
  static REQUEST_TICKETS_FILTER_FINISHED =
    "TicketsAction.REQUEST_TICKETS_FILTER_FINISHED";

  static REQUEST_PUT_TICKETS = "TicketsAction.REQUEST_PUT_TICKETS";
  static REQUEST_PUT_TICKETS_FINISHED =
    "TicketsAction.REQUEST_PUT_TICKETS_FINISHED";

  static REQUEST_POST_TICKETS = "TicketsAction.REQUEST_POST_TICKETS";
  static REQUEST_POST_TICKETS_FINISHED =
    "TicketsAction.REQUEST_POST_TICKETS_FINISHED";

  static REQUEST_TICKETS_CLEAR = "TicketsAction.REQUEST_TICKETS_CLEAR";
  static REQUEST_TICKETS_CLEAR_FINISHED =
    "TicketsAction.REQUEST_TICKETS_CLEAR_FINISHED";

  static REQUEST_TICKETS_FILTER_CLEAR =
    "TicketsAction.REQUEST_TICKETS_FILTER_CLEAR";
  static REQUEST_TICKETS_FILTER_CLEAR_FINISHED =
    "TicketsAction.REQUEST_TICKETS_FILTER_CLEAR_FINISHED";

  // METHODS
  static requestTickets(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS,
        TicketsEffect.requestTickets,
        params
      );
    };
  }

  static requestTicketsClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_CLEAR,
        TicketsEffect.requestTicketsClear
      );
    };
  }

  static requestTicketsFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_FILTER_CLEAR,
        TicketsEffect.requestTicketsFilterClear
      );
    };
  }

  static requestTicketsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_TICKETS_FILTER,
        TicketsEffect.requestTicketsFilter,
        params
      );
    };
  }
  static requestPutTickets(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_PUT_TICKETS,
        TicketsEffect.requestPutTickets,
        data,
        id
      );
    };
  }

  static requestPostTickets(data, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TicketsAction.REQUEST_POST_TICKETS,
        TicketsEffect.requestPostTickets,
        data,
        params
      );
    };
  }
}
