import BaseReducer from "../../utilities/BaseReducer";
import LanguageAction from "./LanguageAction";

// LanguageReduser
export default class LanguageReduser extends BaseReducer {
  initialState = {
    language: [],
  };
  [LanguageAction.REQUEST_LANGUAGE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      language: action.payload,
    };
  }
}
