import React, { useState, useRef, forwardRef } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Popover,
  Box,
  Button,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Grid,
  OutlinedInput,
} from "@mui/material";
export default function CalendarPopover({
  setStartDate,
  setEndDate,
  setTrackSelected,
  setLabelSelected,
  setRetailerSelected,
  setCountrySelected,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedDateRange, setSelectedDateRange] = React.useState([
    null,
    null,
  ]);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);
  const [SelectedValue, setSelectedValue] = useState("");
  const SelectRef = useRef();
  const handleClick = () => {
    setAnchorEl(SelectRef.current);
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    if (value === "Last 7 Days") {
      handleLast7DaysClick();
    } else if (value === "Last 1 Month") {
      handleLast1MonthClick();
    } else if (value === "Last 3 Months") {
      handleLast3MonthClick();
    } else if (value === "Custom") {
      handleClick();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDateRangeChange = (newValue) => {
    setSelectedDateRange(newValue);
    const [start, end] = newValue;
    if (start && end) {
      setTempStartDate(dayjs(start).format("YYYY-MM-DD"));
      setTempEndDate(dayjs(end).format("YYYY-MM-DD"));
    }
  };
  const handleFilterClick = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
    }
    handleClose();
  };
  const handleLast7DaysClick = () => {
    const today = dayjs();
    const start = today.subtract(7, "day").format("YYYY-MM-DD");
    const end = today.format("YYYY-MM-DD");
    setStartDate(start);
    setEndDate(end);
  };
  const handleLast1MonthClick = () => {
    const today = dayjs();
    const startOfLastMonth = today
      .subtract(1, "month")

      .format("YYYY-MM-DD");
    const end = today.format("YYYY-MM-DD");
    setStartDate(startOfLastMonth);
    setEndDate(end);
  };
  const handleLast3MonthClick = () => {
    const today = dayjs();
    const startOfThreeMonthsAgo = today
      .subtract(3, "month")

      .format("YYYY-MM-DD");
    const end = today.format("YYYY-MM-DD");
    setStartDate(startOfThreeMonthsAgo);
    setEndDate(end);
  };
  const handleResetClick = () => {
    const today = dayjs();
    const startOfT30DaysAgo = today.subtract(30, "day").format("YYYY-MM-DD");
    const end = today.format("YYYY-MM-DD");
    setStartDate(startOfT30DaysAgo);
    setEndDate(end);
    setSelectedValue("");
    setLabelSelected(null);
    setTrackSelected(null);
    setRetailerSelected(null);
    setCountrySelected(null);
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box display="flex" alignItems="center">
        <FormControl sx={{ m: 1, minWidth: 80 }}>
          {/* <InputLabel id="demo-simple-select-autowidth-label">
            Filter
          </InputLabel> */}
          <SelectField
            value={SelectedValue}
            handleSelectChange={handleSelectChange}
            size="small" // You can use "small" or "medium" based on your need
            placeholder="Select Date Filter"
            options={["Last 7 Days", "Last 1 Month", "Last 3 Months", "Custom"]}
            ref={SelectRef}
          />
        </FormControl>
        {SelectedValue === "Custom" && (
          <IconButton onClick={handleClick} title="Choose Custom Date Filter">
            <CalendarMonthIcon sx={{ fontSize: 32 }} />
          </IconButton>
        )}
        <Button onClick={handleResetClick} variant="contained" sx={{ ml: 1 }}>
          Reset
        </Button>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          sx={{
            p: 2,
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          <StaticDateRangePicker
            value={selectedDateRange}
            onChange={handleDateRangeChange}
            slotProps={{
              actionBar: { actions: [] },
            }}
            calendars={2}
          />
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
            <Button onClick={handleFilterClick} variant="contained">
              Filter
            </Button>
          </Box>
        </Box>
      </Popover>
    </LocalizationProvider>
  );
}
const SelectField = forwardRef(
  (
    { label, name, options, value, size, placeholder, handleSelectChange },
    ref
  ) => {
    return (
      <Grid container>
        <Select
          input={<OutlinedInput />}
          MenuProps={{}}
          value={value}
          displayEmpty
          size={size}
          style={{ width: "100%" }}
          onChange={handleSelectChange}
          ref={ref}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
            </MenuItem>
          )}
          {options.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    );
  }
);
