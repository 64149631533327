import BaseReducer from "../../utilities/BaseReducer";
import TopStoreAction from "./TopStoreAction";

// AudioStreamsReduser
export default class AudioStreamsReduser extends BaseReducer {
  initialState = {
    audioStreams: [],
  };
  [TopStoreAction.REQUEST_AUDIO_STREAMS_FINISHED](state, action) {
    return {
      ...state,
      audioStreams: action.payload,
    };
  }
}
