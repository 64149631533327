import ActionUtility from "../../utilities/ActionUtility";
import ActressEffect from "./ActressEffect";

export default class ActressAction {
  // ActressAction action with an function declaration
  static REQUEST_ACTRESS = "ActressAction.REQUEST_ACTRESS";
  static REQUEST_ACTRESS_FINISHED = "ActressAction.REQUEST_ACTRESS_FINISHED";

  static REQUEST_ACTRESS_FILTER = "ActressAction.REQUEST_ACTRESS_FILTER";
  static REQUEST_ACTRESS_FILTER_FINISHED =
    "ActressAction.REQUEST_ACTRESS_FILTER_FINISHED";

  static REQUEST_PUT_ACTRESS = "ActressAction.REQUEST_PUT_ACTRESS";
  static REQUEST_PUT_ACTRESS_FINISHED =
    "ActressAction.REQUEST_PUT_ACTRESS_FINISHED";

  static REQUEST_POST_ACTRESS = "ActressAction.REQUEST_POST_ACTRESS";
  static REQUEST_POST_ACTRESS_FINISHED =
    "ActressAction.REQUEST_POST_ACTRESS_FINISHED";

  static REQUEST_ACTRESS_CLEAR = "ActressAction.REQUEST_ACTRESS_CLEAR";
  static REQUEST_ACTRESS_CLEAR_FINISHED =
    "ActressAction.REQUEST_ACTRESS_CLEAR_FINISHED";

  static REQUEST_ACTRESS_FILTER_CLEAR =
    "ActressAction.REQUEST_ACTRESS_FILTER_CLEAR";
  static REQUEST_ACTRESS_FILTER_CLEAR_FINISHED =
    "ActressAction.REQUEST_ACTRESS_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_ACTRESS = "ActressAction.REQUEST_DELETE_ACTRESS";
  static REQUEST_DELETE_ACTRESS_FINISHED =
    "ActressAction.REQUEST_DELETE_ACTRESS_FINISHED";

  // METHODS
  // ACTRESS GET function
  static requestActress(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActressAction.REQUEST_ACTRESS,
        ActressEffect.requestActress,
        params
      );
    };
  }

  static requestActressClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActressAction.REQUEST_ACTRESS_CLEAR,
        ActressEffect.requestActressClear
      );
    };
  }

  static requestActressFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActressAction.REQUEST_ACTRESS_FILTER_CLEAR,
        ActressEffect.requestActressFilterClear
      );
    };
  }

  static requestActressFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActressAction.REQUEST_ACTRESS_FILTER,
        ActressEffect.requestActressFilter,
        params
      );
    };
  }
  static requestPutActress(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActressAction.REQUEST_PUT_ACTRESS,
        ActressEffect.requestPutActress,
        data,
        id
      );
    };
  }

  static requestPostActress(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActressAction.REQUEST_POST_ACTRESS,
        ActressEffect.requestPostActress,
        data,
        params,
        callBack
      );
    };
  }
  static requestDeleteActress(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ActressAction.REQUEST_DELETE_ACTRESS,
        ActressEffect.requestDeleteActress,
        id
      );
    };
  }
}
