import React, { Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import { connect } from "react-redux";
import routes from "./routecoll";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
  auth: state.auth,
  pagePermission: state.pagePermission.pagePermission || [],
});
const MyRoute = (props) => {
  // getting page_permission from local Storage
  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  page_permission = [...new Set(page_permission)];

  // let Filter = routes.data.map((item) => {
  //   return { id: item.id, name: item.title };
  // });

  // console.log("ROUTES", Filter);

  return (
    <Suspense fallback={<LoadingIndicator isActive={true} />}>
      <Routes>
        {page_permission &&
          page_permission.length !== 0 &&
          page_permission.length === 1 &&
          page_permission[0] === "*" &&
          routes.data
            .filter((col) => col.category === "Main")
            .map((item) => {
              return (
                <Route
                  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            })}

        {page_permission &&
          page_permission.length !== 0 &&
          page_permission[0] !== "*" &&
          page_permission.map((allowed_page_Id) => {
            return routes.data
              .filter(
                (col) => col.category === "Main" && col.id === allowed_page_Id
              )
              .map((item) => {
                return (
                  <Route
                    exact="true"
                    path={item.path}
                    key={item.id}
                    element={item.element}
                  />
                );
              });
          })}

        {page_permission &&
          page_permission.length !== 0 &&
          page_permission.length === 1 &&
          page_permission[0] === "*" &&
          routes.data
            .filter((col) => col.category === "Release")
            .map((item) => {
              return (
                <Route
                  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            })}

        {page_permission &&
          page_permission.length !== 0 &&
          page_permission[0] !== "*" &&
          page_permission.map((allowed_page_Id) => {
            return routes.data
              .filter(
                (col) =>
                  col.category === "Release" && col.id === allowed_page_Id
              )
              .map((item) => {
                return (
                  <Route
                    exact="true"
                    path={item.path}
                    key={item.id}
                    element={item.element}
                  />
                );
              });
          })}

        {/* **********************************   REVENUE   ************************************* */}
        {page_permission &&
          page_permission.length !== 0 &&
          page_permission.length === 1 &&
          page_permission[0] === "*" &&
          routes.data
            .filter((col) => col.category === "Revenue")
            .map((item) => {
              return (
                <Route
                  exact="true"
                  path={item.path}
                  key={item.id}
                  element={item.element}
                />
              );
            })}

        {page_permission &&
          page_permission.length !== 0 &&
          page_permission[0] !== "*" &&
          page_permission.map((allowed_page_Id) => {
            {
              return routes.data
                .filter(
                  (col) =>
                    col.category === "Revenue" && col.id === allowed_page_Id
                )
                .map((item) => {
                  return (
                    <Route
                      exact="true"
                      path={item.path}
                      key={item.id}
                      element={item.element}
                    />
                  );
                });
            }
          })}

        {/**********************Logout***************************************/}
        {routes.data
          .filter((col) => col.category === "Logout")
          .map((item) => {
            return (
              <Route
                exact="true"
                path={item.path}
                key={item.id}
                element={item.element}
              />
            );
          })}
      </Routes>
    </Suspense>
  );
};
export default connect(mapStateToProps)(MyRoute);
