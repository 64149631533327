import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import MuiButton from "../../components/muiButton/MuiButton";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";

function ReleaseDetailsTracksDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullWidth
      maxWidth={"xs"}
      // scroll={scroll}
    >
      <DialogTitle id="scroll-dialog-title">
        {formatName("Track Details")}
      </DialogTitle>

      <DialogContent>
        <Grid container lg={12} md={12} sm={12}>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Title :</Typography>
              {props.trackData?.title}
            </Typography>
          </Grid>

          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Content type :</Typography>
              {props.trackData?.content_type}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Primary track type :</Typography>
              {props.trackData?.primary_track_type}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Secondary Track Type :</Typography>
              {props.trackData?.secondary_track_type}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Instrumental :</Typography>
              {props.trackData?.instrumental ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Primary artist :</Typography>
              {props.trackData?.primary_artists?.map((artist, index) => (
                <span key={index}>
                  {artist.artist_id.artist_name}
                  {index !== props.trackData?.primary_artists?.length - 1 &&
                    ", "}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Featuring :</Typography>
              {props.trackData?.featuring?.map((artist, index) => (
                <span key={index}>
                  {artist.artist_id.artist_name}
                  {index !== props.trackData?.featuring.length - 1 && ", "}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Remixer :</Typography>
              {props.trackData?.remixer}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Author :</Typography>
              {props.trackData?.author?.map((item, index) => (
                <span key={index}>
                  {item}
                  {index !== props.trackData?.author.length - 1 && ", "}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Composer :</Typography>
              {props.trackData?.composer?.map((item, index) => (
                <span key={index}>
                  {item}
                  {index !== props.trackData?.composer.length - 1 && ", "}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Arranger :</Typography>
              {props.trackData?.arranger?.map((item, index) => (
                <span key={index}>
                  {item}
                  {index !== props.trackData?.arranger.length - 1 && ", "}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Producer :</Typography>
              {props.trackData?.producer?.map((item, index) => (
                <span key={index}>
                  {item}
                  {index !== props.trackData?.producer.length - 1 && ", "}
                </span>
              ))}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>CopyRighted Year :</Typography>
              {props.trackData?.copyright_year}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>
                Phonographic Copyright Year :
              </Typography>
              {props.trackData?.phonographic_copyright_year}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Production Year :</Typography>
              {props.trackData?.production_year}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>CopyRighted To :</Typography>
              {props.trackData?.copyrighted_to}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Publisher :</Typography>
              {props.trackData?.publisher}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Published by :</Typography>
              {props.trackData?.published_by}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>ISRC :</Typography>
              {props.trackData?.isrc}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Genre :</Typography>
              {props.trackData?.genre ? props.trackData?.genre.genre_name : ""}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Subgenre :</Typography>
              {props.trackData?.sub_genre_name
                ? props.trackData?.sub_genre_name.subgenre_name
                : ""}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Actor :</Typography>
              {/* {props.trackData?.title} */}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Actress :</Typography>
              {/* {props.trackData?.title} */}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Director :</Typography>
              {/* {props.trackData?.title} */}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Lyrics :</Typography>
              {props.trackData?.lyrics}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Lyrics Language :</Typography>
              {props.trackData?.lyrics_language?.lyrics_language_name}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Track Title Language :</Typography>
              {props.trackData?.track_title_language?.track_title_language_name}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>
                Producer catalogue Number :
              </Typography>
              {props.trackData?.producer_catalouge_number}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Parental Advisory :</Typography>
              {props.trackData?.parental_advisory}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Version SubTitle :</Typography>
              {props.trackData &&
                props.trackData["music/album"] &&
                props.trackData["music/album"]}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            <Typography component={"div"}>
              <Typography component={"span"}>Preview Start time :</Typography>
              {props.trackData?.preview_start}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MuiButton
          onClick={() => {
            props.onClose();
          }}
          name="Close"
        />
      </DialogActions>
    </Dialog>
  );
}

export default ReleaseDetailsTracksDialog;
