import { BaseModel } from "sjs-base-model";

// SubGenrePostModel
export default class SubGenrePostModel extends BaseModel {
  subgenre = "";

  constructor(data2) {
    super();

    this.update(data2);
  }
}
