import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useNavigate } from "react-router";
import CancelIcon from "@mui/icons-material/Cancel";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { theme } from "../App";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const NotificationDialog = (props) => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  console.log("notificationsData", props.notificationsData);

  const notification = props?.notificationsData;

  const handleClose = () => {
    props.onClose();
  };

  function returnLocalTime(time) {
    let timeConversion = new Date(time);
    timeConversion =
      timeConversion.toDateString() +
      ", " +
      timeConversion.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });

    return timeConversion;
  }

  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <Box>
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          //   className="bg-warning"
          px={3}
          py={1}
          sx={{
            borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
          }}
        >
          <Grid item sx={{ color: theme.palette.primary.main }}>
            <h1
              style={{
                fontSize: "24px",
              }}
            >
              {formatName("Notification")}
            </h1>
          </Grid>
          <Grid item sx={{ color: theme.palette.text.gray }}>
            <IconButton onClick={props.onClose}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />
        <Box p={2}>
          <Grid container lg={12} md={12} sm={12} spacing={2}>
            <Grid item lg={12} md={12} sm={12}>
              <Typography component={"div"}>
                <Typography component={"span"} sx={{ fontWeight: "bold" }}>
                  Title :{" "}
                </Typography>
                {notification?.notification_title}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Typography component={"div"}>
                <Typography component={"span"} sx={{ fontWeight: "bold" }}>
                  Message :{" "}
                </Typography>
                {notification?.notification_msg}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Typography component={"div"}>
                <Typography component={"span"} sx={{ fontWeight: "bold" }}>
                  Created By :{" "}
                </Typography>
                {notification?.created_by?.user_name}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Typography component={"span"} sx={{ fontWeight: "bold" }}>
                Created On :{" "}
              </Typography>
              {notification?.created_on
                ? returnLocalTime(notification.created_on)
                : ""}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};
export default connect(mapStateToProps)(NotificationDialog);
