import { Box, Avatar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import UserAction from "../../../stores/user/UserAction";
import jwtDecode from "jwt-decode";
import { useTheme } from "@mui/material";
import UserProfileAction from "../../../stores/userprofile/UserProfileAction";
import environment from "environment";
import { styled } from "@material-ui/core";

const ContainAvatar = styled(Avatar)({
  cursor: "pointer",
  backgroundColor: (theme) => theme.palette.primary.main,
  objectFit: "contain", // This will make the image fit inside the Avatar while preserving its aspect ratio
  width: "100%", // Set the width to 100% to ensure the image fits inside the Avatar
});

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    UserAction.REQUEST_USER_BY_ID,
    UserProfileAction.REQUEST_USERPROFILE,
  ]),
  userById: state.userById.userById || [],
  userprofile: state.userprofile.userprofile || [],
});

function AvatarIcon(props) {
  const theme = useTheme();

  function fetchUserprofile() {
    let Id = localStorage.getItem("userId");

    let params = {
      current_page: "profile",
      user_id: Id,
    };
    props.dispatch(UserProfileAction.requestUserprofile(params));
  }

  useEffect(() => {
    fetchUserprofile();
    // fetchTerritoriesData();
    // fetchtimezone();
  }, []);

  let FirstLetter = props.userprofile._source
    ? props.userprofile._source.client_name
    : "";

  // const [imgUrl, setImgUrl] = useState(() => {
  //   props.userprofile &&
  //     props.userprofile &&
  //     props.userprofile._source &&
  //     RetrieveImageUrl(props.userprofile._source.user_profile_url);
  // });

  const [imgUrl, setImgUrl] = useState(null);

  // useEffect(() => {
  //   props.userprofile &&
  //     props.userprofile._source &&
  //     props.userprofile._source.user_profile_url && RetrieveImageUrl(userProfileUrl);
  // }, [
  //   props.userprofile &&
  //     props.userprofile._source &&
  //     props.userprofile._source.user_profile_url,
  // ]);

  function RetrieveImageUrl(url) {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    let userId = localStorage.getItem("userId");

    let imageUrl = "";
    fetch(
      `${environment.api.baseApi}/get_profile_picture?path=thumbnail/${url}&user_id=${userId}&current_page=profile`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);
        setImgUrl(imageUrl);
      });
  }

  return (
    <Box>
      <Avatar
        alt="Remy Sharp"
        sx={{
          cursor: "pointer",
          backgroundColor: theme.palette.primary.main,
          objectFit: "cover",
        }}
        src={`${imgUrl}`}
      >
        {props.userprofile._source ? <h5>{FirstLetter.slice(0, 1)}</h5> : ""}
      </Avatar>
    </Box>
  );
}

export default connect(mapStateToProps)(AvatarIcon);
