import environment from "environment";
import HttpErrorResponseModel from "../../models/HttpErrorResponseModel";
import HttpUtility from "../../utilities/HttpUtility";
import LogoutModel from "./model/LogoutModel";

export default class AuthEffect {
  static async requestLogout(Access_token, refresh_token) {
    const endpoint = environment.api.logout;
    const response = await HttpUtility.post(endpoint, {
      Access_token: Access_token,
      refresh_token: refresh_token,
    });

    if (response instanceof HttpErrorResponseModel) {
      return response;
    }
    localStorage.removeItem("Auth");
    localStorage.clear();

    return new LogoutModel(response.data);
  }
}
