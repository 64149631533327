import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import AuthAction from "../../stores/Auth/AuthAction";
import { connect } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import "./signin.css";
import logo from "../../components/logo/logo@3x.webp";
import { Grid, TextField, useTheme } from "@mui/material";
import MuiButton from "../../components/muiButton/MuiButton";
import { Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router";
import { createBrowserHistory } from "history";
import PrivacyPolicy from "../../components/privacyPolicy/PrivacyPolicy";
import bgImage from "../../Images/a3_label_web_welvome_bg.webp";
import leftLogo from "../../Images/a3_label_web_music_left.webp";
import rightLogo from "../../Images/a3_label_web_music_right.webp";
import SplashScreen from "../splashscreen/SplashScreen";
import PagePermissionAction from "../../stores/pagePermission/PagePermissionAction";

const mapStateToProps = (state, ownProps) => ({
  isRequesting: selectRequesting(state, [AuthAction.REQUEST_LOGIN]),
});

function SignIn(props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showSplashScreen, setShowSplashScreen] = React.useState(true);
  const history = createBrowserHistory();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = yup.object().shape({
    email: yup.string().trim().required("Email/UserName is required"),
    password: yup.string().trim().required("Password is required"),
  });

  let initial = {};

  initial = {
    email: "",
    password: "",
  };

  const textFieldForUsernameRef = React.useRef(null);
  const textFieldForPasswordRef = React.useRef(null);
  const buttonForLoginRef = React.useRef(null);

  const [privacyPolicyOpen, setPrivacyPolicyOpen] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSplashScreen(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (showSplashScreen) {
    return <SplashScreen />;
  }

  return (
    <div style={{ display: "flex", width: "100%", backgroundColor: "#fff" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <img src={bgImage} alt="" style={{ width: "100%", height: "100vh" }} />

        <div className="login_logo">
          <img
            src={logo}
            className="logoContainer"
            style={{
              aspectRatio: "4/3",
            }}
            alt="logo"
          />
        </div>

        <div className="left_login_music logo">
          <img src={leftLogo} alt="Left Logo" style={{ width: "100%" }} />
        </div>

        <div className="login_fields">
          <Formik
            style={{ width: "100%" }}
            initialValues={initial}
            validationSchema={validationSchema}
            onSubmit={(values, formikHelpers) => {
              const callBackFunction = () => {
                if (
                  location.state.from !== "/logout" &&
                  (location.state.from === "/releaseInfo" ||
                    location.state.from === "/upload" ||
                    location.state.from === "/tracks" ||
                    location.state.from === "/releaseDate" ||
                    location.state.from === "/promotion" ||
                    location.state.from === "/submission")
                ) {
                  navigate("/releases", {
                    state: { from: location.pathname },
                  });
                } else if (location.state.from === "/logout") {
                  navigate("/dashboard", {
                    state: { from: location.pathname },
                  });
                } else {
                  navigate(location.state.from, {
                    state: { from: location.pathname },
                  });
                }
                let params = {
                  page_limit: "none",
                };
                props.dispatch(
                  PagePermissionAction.requestPagePermission(params)
                );
              };

              const callBackFunctiontwo = () => {
                let params = {
                  page_limit: "none",
                };
                props
                  .dispatch(PagePermissionAction.requestPagePermission(params))
                  .then(() => {
                    if (location.state.from !== "/logout") {
                      navigate("/dashboard", {
                        state: { from: location.pathname },
                      });
                    }
                  });
              };

              const params = {
                // login_from: "mobile",
              };

              props.dispatch(
                AuthAction.requestLogin(
                  values.email,
                  values.password,
                  callBackFunction,
                  callBackFunctiontwo,
                  params
                )
              );
            }}
          >
            {({ values, errors, isValid, touched, dirty, handleChange }) => (
              <Form style={{ width: "100%" }}>
                <Box sx={{ width: "100%" }}>
                  <Grid
                    container
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    laptop={12}
                    justifyContent={"center"}
                    // alignItems="center"
                    // mt={{
                    //   xl: "10%",
                    //   lg: "12%",
                    //   md: "12%",
                    //   sm: "25%",
                    //   xs: "18%",
                    //   laptop: "2%",
                    // }}
                  >
                    <Grid item xl={3} lg={4} laptop={5} md={6} sm={8} xs={6}>
                      <Box className="login-box">
                        <Grid
                          // sx={{ opacity: "0.8" }}
                          // onSubmit={handleSubmit}
                          item
                          container
                          flexDirection={"column"}
                          alignItems={"center"}
                          alignSelf={"center"}
                        >
                          <Grid
                            item
                            container
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            laptop={12}
                            justifyContent={"center"}
                            alignItems="center"
                            mb={1}
                          >
                            <span
                              className="signin-header"
                              style={{
                                fontFamily: theme.typography.fontFamily,
                                fontSize: "16px",
                              }}
                            >
                              Sign in to your Account
                            </span>
                          </Grid>
                          <Grid
                            item
                            width={"65%"}
                            // className="bg-warning"
                          >
                            <Field
                              // className="bg-warning"
                              id="email"
                              as={TextField}
                              name="email"
                              autoComplete="email"
                              placeholder="User Name"
                              inputRef={textFieldForUsernameRef}
                              error={
                                Boolean(errors.email) && Boolean(touched.email)
                              }
                              helperText={
                                Boolean(touched.email) && errors.email
                              }
                              autoFocus
                              sx={{
                                border: `1px ${theme.palette.primary.main}`,
                                borderRadius:
                                  theme.palette.buttonComponent.borderRadius
                                    .small,
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                                "& .MuiOutlinedInput-root": {
                                  height: "40px",
                                  "&.Mui-focused fieldset": {
                                    border: `1.5px solid ${theme.palette.primary.main}`,
                                  },
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className="p-0 m-0"
                                  >
                                    <PersonIcon sx={{ color: "#e1e6e8" }} />
                                  </InputAdornment>
                                ),
                                onKeyPress: (event) => {
                                  const { key } = event;
                                  if (key === "Enter") {
                                    textFieldForPasswordRef.current.focus();
                                  }
                                },
                              }}
                              fullWidth
                            />
                            <br />
                            <br />

                            <Field
                              name="password"
                              as={TextField}
                              type={showPassword ? "text" : "password"}
                              id="password"
                              inputRef={textFieldForPasswordRef}
                              placeholder="Password"
                              autoComplete="current-password"
                              error={
                                Boolean(errors.password) &&
                                Boolean(touched.password)
                              }
                              helperText={
                                Boolean(touched.password) && errors.password
                              }
                              sx={{
                                border: `1px ${theme.palette.primary.main}`,
                                borderRadius:
                                  theme.palette.buttonComponent.borderRadius
                                    .small,
                                "& legend": { display: "none" },
                                "& fieldset": { top: 0 },
                                "& .MuiOutlinedInput-root": {
                                  height: "40px",
                                  "&.Mui-focused fieldset": {
                                    border: `1.5px solid ${theme.palette.primary.main}`,
                                  },
                                },
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className="p-0 m-0"
                                  >
                                    <LockIcon sx={{ color: "#e1e6e8" }} />
                                  </InputAdornment>
                                ),

                                onKeyPress: (event) => {
                                  const { key } = event;
                                  if (key === "Enter") {
                                    buttonForLoginRef.current &&
                                      buttonForLoginRef.current.click();
                                  }
                                },

                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    className="p-0 m-0"
                                  >
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                      onMouseDown={handleMouseDownPassword}
                                      edge="end"
                                    >
                                      {showPassword ? (
                                        <VisibilityOff
                                          sx={{ color: "#e1e6e8" }}
                                        />
                                      ) : (
                                        <Visibility sx={{ color: "#e1e6e8" }} />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              fullWidth
                            />
                            <br />
                            <br />
                            <br />

                            <MuiButton
                              loading={props.isRequesting}
                              className="submit-btn"
                              type="submit"
                              name={
                                !props.isRequesting ? "Login" : "Logging In"
                              }
                              width={"100%"}
                              buttonRef={buttonForLoginRef}
                              login
                            />
                            <br />
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid
                        item
                        xs={12}
                        pt={2}
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography
                          sx={{
                            color: "#797676!important",
                            fontSize: theme.typography.fontSize,
                          }}
                        >
                          &copy;A3 LABELS 2023 - All Right Reserved.
                        </Typography>
                        <Typography
                          sx={{
                            color: "#797676!important",
                            fontSize: theme.typography.fontSize,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setPrivacyPolicyOpen(true);
                          }}
                        >
                          Privacy
                        </Typography>

                        <PrivacyPolicy
                          open={privacyPolicyOpen}
                          onClose={() => {
                            setPrivacyPolicyOpen(false);
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </Formik>
        </div>

        <div className="right_login_music logo">
          <img src={rightLogo} alt="Right Logo" style={{ width: "100%" }} />
        </div>
      </div>
    </div>
  );
}
export default connect(mapStateToProps)(SignIn);
