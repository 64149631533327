import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ClientRevenueSharePolicyGetModel from "./models/ClientRevenueSharePolicyGetModel";
import ClientRevenueSharePolicyPutModel from "./models/ClientRevenueSharePolicyPutModel";
import ClientRevenueSharePolicyPostModel from "./models/ClientRevenueSharePolicyPostModel";

export default class ClientRevenueSharePolicyEffect {
  // calling an API according to the stream_platform model
  static async requestClientRevenueSharePolicy(params) {
    const endpoint = environment.api.clientRevenueSharePolicy;
    return EffectUtility.getToModel(
      ClientRevenueSharePolicyGetModel,
      endpoint,
      params
    );
  }

  static async requestClientRevenueSharePolicyClear() {
    return {};
  }

  static async requesClientRevenueSharePolicyFilterClear() {
    return {};
  }

  static async requestClientRevenueSharePolicyFilter(params) {
    const endpoint = environment.api.clientRevenueSharePolicy;
    return EffectUtility.getToModel(
      ClientRevenueSharePolicyGetModel,
      endpoint,
      params
    );
  }

  static async requestPutClientRevenueSharePolicy(data, id) {
    const endpoint = environment.api.clientRevenueSharePolicy + "/" + id;
    return EffectUtility.putToModel(
      ClientRevenueSharePolicyPutModel,
      endpoint,
      data
    );
  }

  static async requestPostClientRevenueSharePolicy(data, current_page) {
    const baseEndpoint = environment.api.clientRevenueSharePolicy;
    const endpoint = current_page ? baseEndpoint + current_page : baseEndpoint;

    return EffectUtility.postToModel(
      ClientRevenueSharePolicyPostModel,
      endpoint,
      data
    );
  }

  static async requestSyncClientRevenueSharePolicy(data, clientId, params) {
    const endpoint = environment.api.syncLabelShareRevenue + "/" + clientId;
    return EffectUtility.putToModel(
      ClientRevenueSharePolicyPutModel,
      endpoint,
      data,
      params
    );
  }
}
