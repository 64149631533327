import BaseReducer from "../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// Releases Reducer
export default class FinalReleasesReducer extends BaseReducer {
  initialState = {
    finalReleases: [],
  };
  [ReleasesAction.REQUEST_FINAL_RELEASES_FINISHED](state, action) {
    return {
      ...state,
      finalReleases: action.payload,
    };
  }
}
