import {
  Box,
  Button,
  IconButton,
  ListItem,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import Rettrive from "./Rettrive";
import ImageIcon from "@mui/icons-material/Image";
import PublicIcon from "@mui/icons-material/Public";
// import TerritoryDailog from "./TerritoryDailog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReleasesMorePopper from "../../components/poppers/ReleasesMorePopper";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { theme } from "../App";
import { useNavigate } from "react-router";
import RaiseTicketPopper from "../../components/poppers/RaiseTicketPopper";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { connect } from "react-redux";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import RaiseIssueDialog from "./RaiseIssueDialog";
import a3Logo from "../../Images/a3_web_logo_image.webp";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import { faLessThanEqual } from "@fortawesome/free-solid-svg-icons";
import "./Rettrive.css";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const ReleaseTable = (props) => {
  const navigate = useNavigate();
  // const [openTerritoryDialog, setOpenTerritoryyDialog] = React.useState(false);
  const [territorySelected, setTerritorySelected] = React.useState(null);
  const [edit, setEdit] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [openIssueRaiseDialog, setOpenIssueRaiseDialog] = useState(false);
  const [releaseDataIssue, setReleaseDataIssue] = useState(null);
  const [withdrawRequestConfirm, setwithdrawRequestConfirm] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteReleaseId, setDeleteReleaseId] = useState(null);

  const rows = [];

  const issueTypes = [
    "Label Request",
    "Take down request",
    "YT Claim release",
    "Promotion Request",
    "Metadata Request",
  ];

  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  if (props.props.release && props.props.release.hits) {
    props.props.release.hits.hits &&
      props.props.release.hits.hits.length > 0 &&
      props.props.release.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.props.pagination.current_page_no * props.page_limit +
              ++index
            : "",
          id: item._id,
          type_of_release:
            item._source.type_of_release && item._source.type_of_release
              ? item._source.type_of_release.charAt(0).toUpperCase() +
                item._source.type_of_release.slice(1)
              : "",
          status:
            item._source.status && item._source.status.status_name
              ? convertToTitleCase(item._source.status.status_name)
              : "",
          status_obj: item._source.status && item._source.status,
          thumbnail_url: item._source.thumbnail_url,
          title: item._source.title,
          artists: item._source.artists,
          label: item._source.label,
          digital_release_date: item._source.digital_release_date,
          no_of_tracks: item._source.tracks?.length,
          UPC_EAN: item._source.UPC_EAN,
          copyrighted_to: item._source.copyrighted_to,
          featuring_artists: item._source.featuring_artists,
          main_release_date: item._source.main_release_date,
          modified_on: item._source.modified_on,
          pre_order_release_date: item._source.pre_order_release_date,
          production_year: item._source.production_year,
          created_on: item._source.created_on,
          published_by: item._source.published_by,
          producer_catalogue_number: item._source.producer_catalogue_number,
          genre_type: item._source.genre_type,
          sub_genre_type: item._source.sub_genre_type,
          format: item._source.format,
          territory: item._source.territory,
          thumbnail_url: item._source.thumbnail_url,
        });
      });
  }

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  function WithdrawReleaseSubmit(releaseID) {
    let param = {
      current_page: "releases",
    };

    props
      .dispatch(
        ReleasesAction.RequestWithdrawReleaseFromReview(releaseID, param)
      )
      .then(() => {
        // let params = {
        //   page_limit: "15",
        //   order_by: '[["modified_on", "desc"]]',
        //   current_page: "releases",
        // };
        // props.dispatch(ReleasesAction.requesReleases(params));

        props.fetchReleasesWithdraw();
      });
    setwithdrawRequestConfirm(null);
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },

    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        return (
          <ListItem className="p-0 m-0" sx={{ gap: 2 }}>
            <div>
              <Box
                className="image-container"
                style={{
                  borderRadius: 5,
                  display: "grid",
                  placeItems: "center",
                  overflow: "hidden",
                }}
              >
                <Rettrive
                  thumbnail_url={params?.row?.thumbnail_url}
                  params={params}
                  current_page={"releases"}
                />
              </Box>
            </div>

            <div
              style={{
                // marginLeft: 5,
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
              }}
              // className="bg-warning"
            >
              <div
                style={{ width: "60%" }}
                // className="bg-success"
              >
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.artists?.length ? "By " : ""}
                  {params?.row?.artists?.map((item, index) => (
                    <span key={index}>
                      {Object.keys(item).length && item.artist_id.artist_name}
                      {index !== params?.row?.artists?.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },

    {
      width: 130,
      flex: 1,
      field: "status",
      headerName: "Status",
      renderCell: (params) => {
        return (
          <div
            style={{
              color:
                params?.row?.status == "Rejected" &&
                theme.palette.secondary.main,
            }}
          >
            {params?.row?.status}
          </div>
        );
      },
    },

    {
      width: 80,
      flex: 1,
      field: "type_of_release",

      headerName: "Type",
      renderCell: (params) => {
        return params.row.type_of_release ? params.row.type_of_release : "";
      },
    },

    {
      width: 130,
      flex: 1,
      field: "thumbnail_url",
      headerName: "Thumbnail",
      hide: true,
    },

    {
      width: 130,
      flex: 1,
      field: "artists",
      headerName: "Artists",
      hide: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.artists &&
              params.row.artists.length > 0 &&
              params.row.artists.map((artist) => (
                <>
                  <span>{artist.artist_id.artist_name}</span>
                  <br />
                </>
              ))}
          </div>
        );
      },
    },

    {
      width: 130,
      flex: 1,
      field: "label",
      headerName: "Label",
      renderCell: (params) => {
        return params.row.label && params.row.label.label_name
          ? params.row.label.label_name
          : "";
      },
    },
    {
      width: 200,
      flex: 1,
      field: "created_on",
      headerName: "Created On",
      renderCell: (params) => {
        return formatDate(params.row.created_on);
      },
    },
    {
      width: 200,
      flex: 1,
      field: "main_release_date",
      headerName: "Release date",
      renderCell: (params) => {
        return formatDate(params.row.main_release_date);
      },
    },

    {
      width: 200,
      flex: 1,
      field: "modified_on",
      headerName: "Last updated",
      renderCell: (params) => {
        return formatDate(params.row.modified_on);
      },
    },

    { width: 130, flex: 1, field: "no_of_tracks", headerName: "No-Track" },
    { width: 130, flex: 1, field: "UPC_EAN", hide: true, headerName: "UPC" },

    // {
    //   field: "deliveredTerritories",
    //   headerName: "Delivered Territories & Stores",
    //   resizable: false,
    //   width: 200,
    //   hide: true,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             width: "100%",
    //           }}
    //         >
    //           <Button
    //             variant="outlined"
    //             onClick={() => {
    //               setOpenTerritoryyDialog(true);
    //               setTerritorySelected(params.row.territory);
    //             }}
    //             style={{
    //               padding: 2,
    //               display: "flex",
    //               borderColor: theme.palette.common.grey,
    //               justifyContent: "space-between",
    //             }}
    //           >
    //             <PublicIcon
    //               size="small"
    //               sx={{ color: theme.palette.common.grey }}
    //             />
    //             <Typography>
    //               {params.row.territory ? params.row.territory.length : 0}{" "}
    //               terrs.
    //             </Typography>
    //           </Button>

    //           {/* <Button
    //             variant="outlined"
    //             onClick={handleClickOpen("body")}
    //             style={{
    //               height: "18px",
    //               position: "relative",
    //             }}
    //           >
    //             <IconButton
    //               title="Globe"
    //               sx={{
    //                 border: "0",
    //                 position: "absolute",
    //                 left: 0,
    //               }}
    //             >
    //               <PublicIcon
    //                 color="active"
    //                 style={{ height: "20px", width: "20px" }}
    //               />
    //             </IconButton>
    //             <span style={{ marginLeft: "25px" }}>240 terrs.</span>
    //           </Button> */}
    //           {/* <Dialog open={open} onClose={handleClose} scroll={scroll}>
    //             <DialogTitle id="scroll-dialog-title">Territories</DialogTitle>
    //             <DialogContent dividers={scroll === "body"}></DialogContent>
    //           </Dialog> */}
    //         </div>
    //       </>
    //     );
    //   },
    // },
    {
      width: 50,
      field: "actions",
      type: "actions",
      headerName: (
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        ></FontAwesomeIcon>
      ),
      renderCell: (params) => {
        return (
          <IconButton
            title={
              params.row.status_obj?.id === "2"
                ? "Request Withdraw from Release"
                : (params.row.status_obj?.id === "1" ||
                    params.row.status_obj?.id === "4" ||
                    params.row.status_obj?.id === "10" ||
                    params.row.status_obj?.id === "9") &&
                  "Edit"
            }
            sx={{ border: "0" }}
            onClick={(event) => {
              if (
                params.row.status_obj?.id === "1" ||
                params.row.status_obj?.id === "4" ||
                params.row.status_obj?.id === "10" ||
                params.row.status_obj?.id === "9"
              ) {
                event.ignore = true;
                setEdit(true);
                setEditRowsModel(params.row);
                localStorage.setItem("releaseFormOpen", false);
                localStorage.setItem("id", params.id);

                navigate(`/releaseInfo`);
              }

              if (params.row.status_obj?.id === "2") {
                setwithdrawRequestConfirm(params.id);
              }
            }}
          >
            {params.row.status_obj?.id === "1" ||
            params.row.status_obj?.id === "4" ||
            params.row.status_obj?.id === "10" ||
            params.row.status_obj?.id === "9" ? (
              <FontAwesomeIcon
                icon={faPenToSquare}
                style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
              ></FontAwesomeIcon>
            ) : (
              params.row.status_obj?.id === "2" && (
                <KeyboardReturnIcon
                  style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
                />
              )
            )}
          </IconButton>
        );
      },
    },
    // {
    //   width: 50,
    //   field: "delete",
    //   type: "actions",
    //   headerName: (
    //     <FontAwesomeIcon
    //       icon={faTrash}
    //       style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //     ></FontAwesomeIcon>
    //   ),
    //   //
    //   // width: 160,
    //   cellClassName: "actions",
    //   renderCell: (params) => (
    //     <IconButton
    //       title="Delete"
    //       sx={{ border: "0" }}
    //       onClick={(event) => {
    //         event.ignore = true;
    //         if (params?.row?.id) {
    //           props
    //             .dispatch(ReleasesAction.requestDeleteRelease(params.row.id))
    //             .then(() => {
    //               const params = {
    //                 order_by: '[["created_on", "desc"]]',
    //                 page_limit: page_limit,
    //               };
    //               fetchReleases(params);
    //               const filterparam = {
    //                 page_limit: "none",
    //                 order_by: `[["created_on","desc"]]`,
    //               };

    //               fetchReleasesFilter(filterparam);
    //             });
    //         }
    //       }}
    //     >
    //       <FontAwesomeIcon
    //         icon={faTrash}
    //         style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
    //       ></FontAwesomeIcon>
    //     </IconButton>
    //   ),
    // },

    {
      width: 50,
      field: "issues",
      type: "actions",
      headerName: (
        <div title=" Change Request">
          <AddTaskIcon
            style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
          />
        </div>
      ),
      //
      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => (
        <RaiseTicketPopper
          issueTypes={issueTypes}
          onClick={(issueType) => {
            setOpenIssueRaiseDialog(true);
            setReleaseDataIssue({ releaseData: params.row, type: issueType });
          }}
        >
          <div title=" Change Request">
            <AddTaskIcon
              style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
            />
          </div>
        </RaiseTicketPopper>
      ),
    },

    {
      width: 50,
      field: "more",
      type: "actions",
      headerName: (
        <MoreVertIcon
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        />
      ),
      //
      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => (
        <ReleasesMorePopper
          onClick={(event) => {
            if (params?.row?.id) {
              setDeleteReleaseId(params.row.id);
            }
            setConfirmDialog(true);
          }}
          releaseId={params.row.id}
          deleteloading={props.deleteloading}
          status={params.row.status_obj?.status_name}
        >
          <MoreVertIcon
            style={{
              color: "#2a5e6c",
              height: "20px",
              width: "20px",
              cursor: "pointer",
            }}
          />
        </ReleasesMorePopper>
      ),
    },
  ];

  // const territories = useMemo(
  //   () => (
  //     <TerritoryDailog
  //       list={territorySelected}
  //       open={openTerritoryDialog}
  //       onClose={() => {
  //         setOpenTerritoryyDialog(false);
  //       }}
  //     />
  //   ),
  //   [openTerritoryDialog]
  // );

  return (
    <>
      {/* Withdraw confirmation dialog Box */}
      <Dialog open={Boolean(withdrawRequestConfirm)} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Would you like to withdraw your Submit request ?</h5>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setwithdrawRequestConfirm(null);
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              WithdrawReleaseSubmit(withdrawRequestConfirm);
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Release Confirmation Dialog Box */}
      <Dialog open={Boolean(confirmDialog)} fullWidth maxWidth="sm">
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Are you sure you want to delete the Release ?</h5>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setConfirmDialog(false);
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              props.deleteRelease(deleteReleaseId);
              setConfirmDialog(false);
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>

      {/* {territories} */}
      <DataGridFunction
        columns={columns}
        rows={rows}
        isRequesting={props.isRequesting}
        rowHeight={80}
        pagelength={props.props?.pagination?.total_page_no}
        page={props.props?.pagination?.current_page_no}
        handleChange={props.handleChange}
        height={"75vh"}
      />
      <RaiseIssueDialog
        open={openIssueRaiseDialog}
        onClose={() => {
          setOpenIssueRaiseDialog(false);
        }}
        releaseInfo={releaseDataIssue}
        current_page={"releases"}
      />
    </>
  );
};

export default connect(mapDispatchToProps)(ReleaseTable);
