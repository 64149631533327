import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  ListItem,
} from "@mui/material";
import { theme } from "../../views/App";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MuiMonthPicker from "../../components/datePicker/MuiMonthPicker";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import dayjs from "dayjs";
import { format } from "date-fns";
import RevenueReportAction from "../../stores/revenueReport/RevenueReportAction";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { CSVLink } from "react-csv";
import YTRevenueReportAction from "../../stores/ytRevenueReport/YTRevenueReportAction";
import environment from "environment";

// mapstatetoprops function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    RevenueReportAction.REQUEST_REVENUE_REPORT_EXPORT,
  ]),
  revenueReportExport: state.revenueReportExport.revenueReportExport || [],
});

function ExportDailoge(props) {
  const miniBoxStyle = {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
    height: 40,
    width: 100,
    pointerEvents: "none",
  };
  const hoverStyle = {
    transform: "scale(1.1)",
    transition: "transform 0.2s ease-in-out",
  };

  const [tabValue, setTabValue] = useState(0);
  const [monthFilter, setMonthFilter] = useState(null);

  const [noFiltersAlert, setNoFiltersAlert] = useState(false);

  // function monthFilterHandleChange(date) {
  //   setMonthFilter(date);
  // }

  useEffect(() => {
    if (props.open) {
      setMonthFilter(props?.editRowsModel?.monthDate);
    }
  }, [props.open]);

  let filterdArray =
    props?.editRowsModel?.report_type !== "Stores Revenue Report"
      ? [
          "client_name",
          "retailer_name",
          "day",
          "label_name",
          "artist",
          "release_title",
          "upc",
          "isrc",
          "territory",
          "asset_type",
          "quantity_net",
          "label_share",
          "label_share_amount",
          "share_percentage",
          "client_share_amount",
          "amount",
        ]
      : [
          "client_name",
          "period",
          "retailer_name",
          "retailer_reporting_period",
          "sales_reported_date",
          "label_name",
          "artist",
          "release_title",
          "upc",
          "release_catalouge_number",
          "isrc",
          "genre",
          "release_date",
          "territory",
          "asset_type",
          "sales_classification",
          "sales_description",
          "quantity_net",
          "label_share",
          "label_share_amount",
          "mix_(version)",
          "retailer_stmt_country_iso",
          "share_percentage",
          "client_share_amount",
          "amount",
        ];

  let [errorMessageNote, seterrorMessageNote] = useState(null);

  const [checkedItems, setCheckedItems] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);

  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  let [selectAll, setSelectAll] = useState(false);

  const [csvData, setCsvData] = useState([]);
  const csvLink = useRef();

  const YTexportReportFunction = () => {
    const filter = [];

    function monthFilterResponse2(date) {
      const firstDayOfMonth = dayjs(date).startOf("month");
      const lastDayOfMonth = dayjs(date).endOf("month");
      let filterString = ``;

      filterString = `["sales_reported_date","must","gte","range","${format(
        firstDayOfMonth.$d,
        "yyyy-MM-dd"
      )}"],["sales_reported_date","must","lte","range","${format(
        lastDayOfMonth.$d,
        "yyyy-MM-dd"
      )}"]`;

      return filterString;
    }

    if (
      monthFilter &&
      props?.editRowsModel?.report_type === "Stores Revenue Report"
    ) {
      filter.push(monthFilterResponse2(monthFilter));
    }

    if (!monthFilter) {
      setNoFiltersAlert(true);
      seterrorMessageNote("Please Select Month");
    } else {
      setExportLoading(true);
      let params = {};

      function monthFilterResponse(date) {
        const firstDayOfMonth = dayjs(date).startOf("month");
        const lastDayOfMonth = dayjs(date).endOf("month");
        let filterString = ``;

        filterString = `["day","must","gte","range","${format(
          firstDayOfMonth.$d,
          "yyyy-MM-dd"
        )}"],["day","must","lte","range","${format(
          lastDayOfMonth.$d,
          "yyyy-MM-dd"
        )}"]`;

        return filterString;
      }

      if (
        monthFilter &&
        props?.editRowsModel?.report_type === "Youtube Revenue Report"
      ) {
        filter.push(monthFilterResponse(monthFilter));
      }

      let access_token = localStorage.getItem("Auth")
        ? JSON.parse(localStorage.getItem("Auth")).access_token
        : "";

      const replace_items = [
        { id: "client_id", name: "client_name" },
        { id: "label", name: "label_name" },
        { id: "cv_label_share", name: "label_share" },
        { id: "cv_share_percentage", name: "share_percentage" },
        { id: "cv_amount", name: "amount" },
      ];

      // Replace matching names in checkeditems with the corresponding id from replace_items
      const updatedCheckedItems = checkedItems.map((item) => {
        const replacement = replace_items.find(
          (replaceItem) => replaceItem.name === item
        );
        return replacement ? replacement.id : item;
      });

      console.log("checkedItems", updatedCheckedItems);

      const post_data = {
        fields: updatedCheckedItems,
      };

      fetch(
        `${environment.api.export_revenue_report}?report_type=${
          props?.editRowsModel?.report_type === "Youtube Revenue Report"
            ? "youtube_revenue"
            : "stores_revenue"
        }&order_by=[["created_on","desc"]]&current_page=revenueReport&filters=[${filter}]`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + access_token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(post_data),
        }
      )
        .then((response) => {
          // Check if the response status is OK (200)
          if (!response.ok) {
            return response.json().then((data) => {
              if (data.message) {
                setErrorMessage(data.message);
                setAlertPopUp(true);
                setExportLoading(false);
              }
              throw new Error("Network response was not ok");
            });
          }

          // Check the content type in the response header
          const contentType = response.headers.get("content-type");
          console.log("contentType", contentType);

          // Extract the filename from the Content-Disposition header
          if (contentType === "text/csv; charset=utf-8") {
            return response.blob(); // Convert the response to a Blob
          }

          // Handle unexpected content type
          throw new Error("Unexpected content type: " + contentType);
        })
        .then((csvBlob) => {
          console.log("csvBlob", csvBlob);
          setExportLoading(false);

          // Create a Blob URL for the CSV data
          const blobUrl = URL.createObjectURL(csvBlob);

          // Create an anchor element for downloading
          const downloadLink = document.createElement("a");
          downloadLink.href = blobUrl;
          downloadLink.target = "_blank";
          downloadLink.download =
            props?.editRowsModel?.report_type === "Stores Revenue Report"
              ? `Streaming Report (${dayjs(monthFilter).format(
                  "MMM YYYY"
                )}).csv`
              : props?.editRowsModel?.report_type === "Youtube Revenue Report"
              ? `Yt Revenue Report (${dayjs(monthFilter).format(
                  "MMM YYYY"
                )}).csv`
              : ""; // Set the desired file name

          // Trigger a click event on the anchor element to initiate the download
          downloadLink.click();

          // Clean up the Blob URL
          URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => {
          console.error("There was an error with the fetch request:", error);
          setExportLoading(false);
        });
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setCheckedItems([...filterdArray]);
    } else {
      setCheckedItems([]);
    }
    setSelectAll(!selectAll);
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @keyframes zoomInFade {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-8px);
          }
          60% {
            transform: translateY(2px);
          }
        }
      `,
        }}
      />

      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose();
          setCheckedItems([]);
          setMonthFilter(null);
          setSelectAll(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <DialogContentText>
            <>
              <Box
                sx={{
                  borderBottom: `2px solid ${theme.palette.common.grey}`,
                }}
              >
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Grid item px={1} sx={{ display: "flex" }} mb={1}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FilterAltIcon
                        sx={{ marginBottom: "2px", marginRight: "10px" }}
                      />
                      Export Parameters
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    px={1}
                    sx={{
                      display: "inline-block",
                      borderRadius: "50%",
                      padding: "4px",
                      transition: "background-color 0.5s ease-in",
                      "&:hover": {
                        ...hoverStyle,
                        backgroundColor: theme.palette.common.grey,
                      },
                    }}
                  >
                    <CloseIcon
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: theme.palette.secondary.main,
                        },
                      }}
                      onClick={() => {
                        setTabValue(0);
                        props.onClose();
                        setCheckedItems([]);
                        setMonthFilter(null);
                        setSelectAll(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid xs={12} sx={{ marginY: "20px" }}></Grid>
              <>
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  alignItems={"center"}
                  justifyContent="flex-start"
                  gap={1}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...miniBoxStyle,
                      border: miniBoxStyle.border,
                      borderRadius: "10px",
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      textAlign="center"
                      alignItems={"center"}
                      px={0.5}
                      //mt={0.5}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        {props?.editRowsModel?.month}
                      </span>
                    </Grid>
                  </Grid>

                  {/* <Grid
                    item
                    lg={3}
                    md={3}
                    sm={3}
                    sx={{
                      width: { sm: "100%", md: "30%" },
                      pointerEvents: exportLoading && "none",
                      opacity: exportLoading && 0.5,
                    }}
                  >
                    <MuiMonthPicker
                      onChange={monthFilterHandleChange}
                      value={monthFilter}
                    />
                  </Grid> */}

                  <Grid item>
                    <FormControlLabel
                      className="p-0 m-0"
                      sx={{
                        zIndex: 1,
                        flex: 1,
                      }}
                      control={
                        <Checkbox
                          title="Select all Fileds"
                          sx={{
                            zIndex: 1,
                            padding: "5px",
                            border: `1px solid ${theme.palette.common.grey}`,
                            borderRadius: "4px",
                          }}
                          checked={
                            selectAll &&
                            checkedItems.length === filterdArray.length
                              ? true
                              : false
                          }
                          onChange={() => handleSelectAll()}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </>

              {
                <Box
                  className=" my-2 scrollable p-1"
                  height={"auto"}
                  style={{
                    color: theme.palette.primary.main,
                  }}
                  maxHeight={300}
                >
                  {filterdArray?.map((item) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={checkedItems.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      }
                      label={item
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    />
                  ))}
                </Box>
              }
            </>
          </DialogContentText>
        </DialogContent>

        <CSVLink
          data={csvData}
          //   filename="RevenueReport.csv"
          filename={
            props?.editRowsModel?.report_type === "Stores Revenue Report"
              ? `Streaming Report (${dayjs(monthFilter).format(
                  "MMM YYYY"
                )}).csv`
              : props?.editRowsModel?.report_type === "Youtube Revenue Report"
              ? `Yt Revenue Report (${dayjs(monthFilter).format(
                  "MMM YYYY"
                )}).csv`
              : ""
          }
          className="hidden"
          ref={csvLink}
          target="_blank"
        />

        <div>
          <Grid container justifyContent={"flex-end"} p={3} gap={2}>
            {
              <>
                <Button
                  onClick={() => {
                    YTexportReportFunction();
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "5px",
                    textTransform: "none",
                    width: "150px",
                  }}
                  disabled={
                    !selectAll && checkedItems?.length === 0 ? true : false
                  }
                >
                  {!exportLoading ? "Export" : "Downloading..."}
                </Button>
              </>
            }
          </Grid>
        </div>
        <MuiSnackbarAlert
          open={noFiltersAlert}
          onClose={() => setNoFiltersAlert(false)}
          message={errorMessageNote}
        />
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps)(ExportDailoge);
