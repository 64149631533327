import { BaseModel } from "sjs-base-model";

export default class TrackwiseRevenueGetModel extends BaseModel {
  // response getting from the api mentioned end point
  track_wise_revenue = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
