import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Form, Formik } from "formik";
import { connect } from "react-redux";
import RevenueReportAction from "../../stores/revenueReport/RevenueReportAction";
import MuiButton from "../../components/muiButton/MuiButton";
import Papa from "papaparse";
import MuiTextField from "../../components/muiTextField/MuiTextField";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

const allowedExtensions = ["csv"];

const RevenueReportFileUploader = (props) => {
  const buttonForLoginRef = React.useRef(null);

  //Initial values
  let initial = {};
  if (props.edit) {
    initial = {};
  } else {
    initial = {};
  }

  useEffect(() => {}, [props.errorMessage]);

  // This state will store the parsed data
  const [data, setData] = useState([]);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState("");

  // It will store the file uploaded by the user
  const [file, setFile] = useState("");

  // This function will be called when
  // the file input changes
  const handleFileChange = (e) => {
    setError("");

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it not
      // included in the allowed extensions
      // we show the error
      const fileExtension = inputFile?.type.split("/")[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError("Please input a csv file");
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };
  const handleParse = () => {
    // If user clicks the parse button without
    // a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user
    // to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file
    // loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;

      const columns = Object.keys(parsedData[0]);
      setData(columns);
      props.set_csv_data(parsedData);
      props.onClose();
    };
    reader.readAsText(file);
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        enableReinitialize
        initialValues={initial}
        // validationSchema={validationSchema}
        onSubmit={(values, formikHelpers) => {
          // formikHelpers.resetForm();
        }}
      >
        {({
          values,
          errors,
          isValid,
          touched,
          dirty,
          handleChange,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "80%" }}>
                <div className="text-center pb-2">
                  <div className="row p-3">
                    <div className="col-10">
                      <h3 className="dailogtext">
                        <b>
                          <span className="span_first_letter">I</span>mport{" "}
                          <span className="span_first_letter">CSV</span>
                        </b>{" "}
                      </h3>
                    </div>
                    <div className="col-2 closebtn">
                      <i
                        className="fa fa-times-circle-o fa-2x"
                        title="Close"
                        style={{ size: "15px" }}
                        aria-hidden="true"
                        onClick={() => {
                          props.onClose();
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              </DialogContentText>
            </DialogContent>

            <Grid
              xl={12}
              container
              alignItems="center"
              justifyContent="center"
              //   className="bg-warning"
            >
              <Grid
                item
                xl={5}
                // className="bg-success"
                textAlign="center"
              >
                {/* <h6>Upload CSV File</h6> */}
                <MuiTextField
                  type="file"
                  onChange={handleFileChange}
                  id="csvInput"
                  InputProps={{
                    inputProps: {
                      accept: ".csv",
                    },
                  }}
                />
                {error && <Box>{error ? error : null}</Box>}
                {/* <Box mt={1}>
                  <MuiButton name="Convert" onClick={handleParse} />
                </Box> */}
              </Grid>
            </Grid>

            {props.errorMessage && props.errorMessage.raw && (
              <Box my={1} px={4}>
                {Array.isArray(props.errorMessage.raw.data.message) &&
                  props.errorMessage.raw.data.message?.length !== 0 &&
                  props.errorMessage.raw.data.message?.map((item, i) => (
                    <>
                      {item.error && (
                        <span key={i}>
                          <span style={{ fontWeight: "bold" }}></span>
                          <span style={{ fontWeight: "bold" }}>
                            Row {item.row}
                            {"."}&nbsp;
                          </span>
                          {item.error}

                          <br />
                        </span>
                      )}
                    </>
                  ))}
              </Box>
            )}

            {props.errorMessage && props.errorMessage.message && (
              <Box my={1} px={4}>
                {Array.isArray(props.errorMessage.message) &&
                  props.errorMessage.message?.length !== 0 &&
                  props.errorMessage.message?.map((item, i) => (
                    <>
                      {item.message && (
                        <span key={i}>
                          <span style={{ fontWeight: "bold" }}></span>
                          <span style={{ fontWeight: "bold" }}>
                            Row {item.row}
                            {"."}&nbsp;
                          </span>
                          {item.message === "Failed"
                            ? item.reason
                            : item.message}

                          <br />
                        </span>
                      )}
                    </>
                  ))}
              </Box>
            )}

            <DialogActions>
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={4.8} md={5} sm={5} mb={3}>
                  <MuiButton
                    name={"Add"}
                    type="submit"
                    width="100%"
                    onClick={
                      props.onSubmit
                        ? async () => {
                            if (!file) {
                              return setError("Enter a valid file");
                            } else {
                              await props.onSubmit(file);
                              setFile(null);
                            }
                          }
                        : handleParse
                    }
                    disabled={values.boolean}
                    loading={props.loading}
                    buttonRef={buttonForLoginRef}
                  />
                </Grid>

                <Grid item lg={4.8} md={5} sm={5} mb={3}>
                  <MuiButton
                    name={"Cancel"}
                    onClick={() => {
                      props.onClose();
                      // Formik.resetForm();
                    }}
                    width="100%"
                  />
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(RevenueReportFileUploader);
