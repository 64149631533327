import { BaseModel } from "sjs-base-model";

// AudiostreamsModel
export default class TopDevicesModel extends BaseModel {
  top_device_type_report = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
