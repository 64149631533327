import { Box, Grid, IconButton, ListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import SyncIcon from "@mui/icons-material/Sync";
import { theme } from "../App";
import TrackAction from "../../stores/track/TrackAction";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

function ReleasesDeliveryTab(props) {
  const columns = [
    {
      field: "MusicServiceName",
      headerName: "Music Service",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 2.5,
    },
    {
      field: "SyndicatedAt",
      headerName: "Ordered",
      flex: 2.5,
      renderCell: (params) => {
        let time = new Date(params.row.SyndicatedAt);
        return time.toLocaleString();
      },
    },
    {
      field: "TransferredAt",
      headerName: "Transferred",
      flex: 2.5,
      renderCell: (params) => {
        let time = new Date(params.row.TransferredAt);
        return time.toLocaleString();
      },
    },
    {
      field: "SyndicationLevel",
      headerName: "Batch",
      flex: 2.5,
    },
    {
      field: "TerritoryList",
      headerName: "Territory",
      flex: 2.5,
      renderCell: (params) => {
        return `${params.row.TerritoryList?.length} Countries`;
      },
    },
  ];

  const rows = [];

  if (props.releaseData) {
    props.releaseData?._source?.deliveries?.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: index + 1,
        ...item,
      });
    });
  }

  const [isSyncingStores, setisSyncingStores] = useState(false);

  const syncStoresFunction = () => {
    setisSyncingStores(true);
    let params = {
      current_page: "delivered_release_details",
    };
    props
      .dispatch(TrackAction.requestSyncReleaseStores(props.releaseId, params))
      .then(() => {
        setisSyncingStores(false);
        const params = {
          current_page: "delivered_release_details",
        };
        props.dispatch(
          ReleasesAction.requestFinalReleaseById(props.releaseId, params)
        );
      });
  };

  return (
    <Box width="100%">
      {/* <Grid container item lg={12} md={12} sm={12}>
        <Typography component={"span"}>
          {formatName(`${rows?.length}`)} Results found
        </Typography>
      </Grid> */}

      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        // className="bg-warning"
        alignItems={"center"}
        justifyContent="space-between"
        my={1.5}
      >
        <Grid item>
          <Typography component={"span"}>
            {formatName(`${rows?.length}`)} Results found
          </Typography>
        </Grid>

        {/* <Grid item>
          <Box px={4}>
            <ListItem className="p-0 m-0">
              <div>
                <IconButton
                  title="Sync Stores"
                  onClick={() => {
                    syncStoresFunction();
                  }}
                >
                  <SyncIcon
                    sx={{
                      color: theme.palette.primary.main,
                      animation: isSyncingStores
                        ? "rotateAnimation 2s linear infinite"
                        : "",
                    }}
                  />
                </IconButton>
              </div>
              <span>Sync</span>
            </ListItem>
          </Box>
        </Grid> */}
      </Grid>

      {props.releaseData ? (
        <DataGridFunction
          columns={columns}
          rows={rows}
          rowHeight={60}
          height={500}
        />
      ) : null}
    </Box>
  );
}

export default connect(mapStateToProps)(ReleasesDeliveryTab);
