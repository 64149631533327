import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import BlogsModel from "./model/BlogsModel";

// BlogsEffect
export default class BlogsEffect {
  // calling an API according to the plan model
  static async requestBlogs(params) {
    const endpoint = environment.api.blogs;
    return EffectUtility.getToModel(BlogsModel, endpoint, params);
  }

  // Get method for the plan filter
  static async requestBlogsFilter(params) {
    const endpoint = environment.api.blogs;
    return EffectUtility.getToModel(BlogsModel, endpoint, params);
  }
}
