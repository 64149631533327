import styles from "./LoadingIndicator.module.scss";

import React from "react";
import classNames from "classnames";
import { Box } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// loading
function LoadingIndicator(props) {
  const { children, isActive, className } = props;
  const cssClasses = classNames(className, {
    [styles.wrapper]: isActive,
  });

  return (
    <Box className={cssClasses} width="100%">
      {isActive && (
        <Box sx={{ width: "100%" }}>
          <LinearProgress />
        </Box>
      )}
      {children}
    </Box>
  );
}

export default LoadingIndicator;
