import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Grid, IconButton, useTheme, Typography } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import TopLanguagesAction from "../../../stores/topLanguages/TopLanguagesAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TopLanguagesAction.REQUEST_TOP_LANGUAGES,
  ]),
  top_languages_report: state.top_languages_report.top_languages_report || [],
});

function TopLanguage(props) {
  console.log("props", props);
  const { isRequesting } = props;
  const navigate = useNavigate();

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchTopLanguages(params);
  }, []);

  function fetchTopLanguages(params) {
    props.dispatch(TopLanguagesAction.requestTopLanguages(params));
  }

  const rows = [];

  props?.top_languages_report?.top_languages?.map((item, index) => {
    console.log("item", Object.values(item)[0]);
    rows.push({
      sl_no: ++index,
      id: ++index,

      language_name: item && Object.keys(item)[0],
      tracks:
        item && Object.values(item)[0] ? Object.values(item)[0].tracks : "",
      views: item && Object.values(item)[0] ? Object.values(item)[0].views : "",
    });
  });

  //Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 80,
    },

    {
      // width: 140,
      flex: 1,
      field: "language_name",
      headerName: "Language name",
    },

    {
      width: 280,
      field: "tracks",
      headerName: "No-Track",
    },

    {
      // width: 140,
      flex: 1,
      field: "views",
      headerName: "No-Views",
    },
  ];

  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={80}
                isRequesting={isRequesting}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(TopLanguage);
