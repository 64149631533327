import BaseReducer from "../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

export default class A3ReportsArtistReducer extends BaseReducer {
  initialState = {
    clientArtistReport: [],
  };
  [A3ReportsAction.REQUEST_A3REPORTS_ARTIST_FINISHED](state, action) {
    return {
      ...state,
      clientArtistReport: action.payload,
    };
  }
}
