import BaseReducer from "../../utilities/BaseReducer";
import YTNetRevenueByMonthAction from "./YTNetRevenueByMonthAction";

export default class YTNetRevenueByMonthFilterReducer extends BaseReducer {
  initialState = {
    ytNetRevenueByMonthFilter: [],
  };
  [YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ytNetRevenueByMonthFilter: action.payload,
    };
  }

  [YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ytNetRevenueByMonthFilter: action.payload,
    };
  }
}
