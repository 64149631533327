import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { Box } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import ReactEcharts from "echarts-for-react";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TopStoreAction from "../../../stores/topStoresReport/TopStoreAction";
import { connect } from "react-redux";
import { theme } from "../../App";

const mapStateToProps = (state) => ({
  audioStreamsRequesting: selectRequesting(state, [
    TopStoreAction.REQUEST_AUDIO_STREAMS,
  ]),
  audioStreams: state.audioStreams.audioStreams || [],
});
function AudioStreamsChart(props) {
  const [chartInstance, setChartInstance] = useState(null);

  const { detailReportOpen, setStartDate, setEndDate, audioStreamsRequesting } =
    props;

  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };

  const audio_streams = [];

  if (props.audioStreams && props.audioStreams.audio_streams) {
    props.audioStreams.audio_streams.length > 0 &&
      props.audioStreams.audio_streams.map((item, index) => {
        audio_streams.push({
          sl_no: ++index,
          id: ++index,
          count: item.count,
          date: item.key,
        });
      });
  }

  const getOption = () => {
    const nameLabel = audio_streams.map((item) => item.date);
    const originalData = audio_streams.map((item) => item.count);

    return {
      tooltip: {
        trigger: "axis",
        formatter: (params) => {
          let val = params[0].value;
          let formattedVal = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${theme.palette.primary.main};border-radius:50%;"></span>`;
          return `${params[0].name}:<br>${colorSpan}  ${formattedVal} `;
        },
      },
      grid: {
        top: "5%",
        left: "5%",
        right: "5%",
        bottom: "5%",
        containLabel: true,
      },
      xAxis: [
        {
          type: "category",
          data: nameLabel,
          name: detailReportOpen ? "Day" : "",
          nameLocation: "bottom",
          nameTextStyle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: "BookAntiqua",
            verticalAlign: "left",
            lineHeight: 25,
            fontSize: 20,
            padding: [30, 0, 0, 0],
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            formatter: (value) => formatValue(value),
            hideOverlap: true,
          },
          axisPointer: {
            triggerTooltip: true,
          },
          name: detailReportOpen ? "Sum Of Quantity" : "",
          nameLocation: "middle",
          nameTextStyle: {
            color: theme.palette.primary.main,
            fontWeight: "bold",
            fontFamily: "BookAntiqua",
            fontSize: 20,
            lineHeight: 0,
            padding: [0, 0, 50, 0],
          },
        },
      ],
      series: [
        {
          data: originalData,
          type: "line",
          color: theme.palette.primary.main,
        },
      ],
      brush: {
        toolbox: ["lineX", "clear"],
        xAxisIndex: 0,
      },
      toolbox: {
        feature: {
          brush: {
            type: ["lineX", "clear"],
          },
        },
      },
    };
  };

  useEffect(() => {
    if (chartInstance) {
      let isBrushing = false;
      let coordRange;

      const handleBrushSelected = (params) => {
        if (
          params.batch &&
          params.batch.length > 0 &&
          params.batch[0].areas &&
          params.batch[0].areas.length > 0
        ) {
          const selected = params.batch[0].areas[0];
          coordRange = selected.coordRange;
          isBrushing = true;
        }
      };
      const handleMouseUp = () => {
        if (isBrushing && coordRange) {
          const nameLabel = audio_streams.map((item) => item.date);
          setStartDate(nameLabel[coordRange[0]]);
          setEndDate(nameLabel[coordRange[1]]);
          isBrushing = false;
        }
      };

      chartInstance.on("brushSelected", handleBrushSelected);
      document.addEventListener("mouseup", handleMouseUp);

      return () => {
        chartInstance.off("brushSelected", handleBrushSelected);
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }
  }, [chartInstance, audio_streams, setStartDate, setEndDate]);

  console.log("audioStreamsRequesting", audioStreamsRequesting, props);

  return !audioStreamsRequesting ? (
    <ErrorBoundary message="Error in loading report">
      <Box sx={{ width: "100%", height: "100%" }}>
        <ReactEcharts
          option={getOption()}
          onChartReady={(chart) => setChartInstance(chart)}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    </ErrorBoundary>
  ) : (
    <LoadingIndicator isActive={audioStreamsRequesting}>
      <Box
        sx={{
          width: "100%",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingBgImage width={"50%"} />
      </Box>
    </LoadingIndicator>
  );
}

export default connect(mapStateToProps)(AudioStreamsChart);
