import ActionUtility from "../../utilities/ActionUtility";
import A3ReportsEffect from "./A3ReportsEffect";

export default class A3ReportsAction {
  // A3ReportsAction action with an function declaration
  static REQUEST_A3REPORTS_LABEL = "A3ReportsAction.REQUEST_A3REPORTS_LABEL";
  static REQUEST_A3REPORTS_LABEL_FINISHED =
    "A3ReportsAction.REQUEST_A3REPORTS_LABEL_FINISHED";

  static REQUEST_A3REPORTS_ARTIST = "A3ReportsAction.REQUEST_A3REPORTS_ARTIST";
  static REQUEST_A3REPORTS_ARTIST_FINISHED =
    "A3ReportsAction.REQUEST_A3REPORTS_ARTIST_FINISHED";

  static REQUEST_A3REPORTS_RELEASE =
    "A3ReportsAction.REQUEST_A3REPORTS_RELEASE";
  static REQUEST_A3REPORTS_RELEASE_FINISHED =
    "A3ReportsAction.REQUEST_A3REPORTS_RELEASE_FINISHED";

  static REQUEST_A3REPORTS_STOREWISE =
    "A3ReportsAction.REQUEST_A3REPORTS_STOREWISE";
  static REQUEST_A3REPORTS_STOREWISE_FINISHED =
    "A3ReportsAction.REQUEST_A3REPORTS_STOREWISE_FINISHED";

  static REQUEST_A3REPORTS_MONTHWISE =
    "A3ReportsAction.REQUEST_A3REPORTS_MONTHWISE";
  static REQUEST_A3REPORTS_MONTHWISE_FINISHED =
    "A3ReportsAction.REQUEST_A3REPORTS_MONTHWISE_FINISHED";

  static REQUEST_A3REPORTS_COUNTRYWISE =
    "A3ReportsAction.REQUEST_A3REPORTS_COUNTRYWISE";
  static REQUEST_A3REPORTS_COUNTRYWISE_FINISHED =
    "A3ReportsAction.REQUEST_A3REPORTS_COUNTRYWISE_FINISHED";

  static REQUEST_A3LABEL_REVENUE_REPORT =
    "A3ReportsAction.REQUEST_A3LABEL_REVENUE_REPORT";
  static REQUEST_A3LABEL_REVENUE_REPORT_FINISHED =
    "A3ReportsAction.REQUEST_A3LABEL_REVENUE_REPORT_FINISHED";

  // METHODS
  // Organization GET function
  static requestLabelReports(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3ReportsAction.REQUEST_A3REPORTS_LABEL,
        A3ReportsEffect.requestLabelReports,
        params,
        callBack
      );
    };
  }

  static requestArtistReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3ReportsAction.REQUEST_A3REPORTS_ARTIST,
        A3ReportsEffect.requestArtistReports,
        params
      );
    };
  }

  static requestReleaseReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3ReportsAction.REQUEST_A3REPORTS_RELEASE,
        A3ReportsEffect.requestReleaseReports,
        params
      );
    };
  }

  static requestStorewiseReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3ReportsAction.REQUEST_A3REPORTS_STOREWISE,
        A3ReportsEffect.requestStorewiseReports,
        params
      );
    };
  }

  static requestMonthwiseReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3ReportsAction.REQUEST_A3REPORTS_MONTHWISE,
        A3ReportsEffect.requestMonthwiseReports,
        params
      );
    };
  }

  // Territory or Country Reports
  static requestCountrywiseReports(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3ReportsAction.REQUEST_A3REPORTS_COUNTRYWISE,
        A3ReportsEffect.requestCountrywiseReports,
        params
      );
    };
  }

  //Label Revenue Report
  static requestLabelRevenueReport(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        A3ReportsAction.REQUEST_A3LABEL_REVENUE_REPORT,
        A3ReportsEffect.requestLabelRevenueReport,
        params
      );
    };
  }
}
