import React from "react";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import ToastsAction from "../../stores/toasts/ToastsAction";

import { Button } from "@mui/material";
const mapStateToProps = (state) => ({
  toasts: state.toasts,
});

function Toasts(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  if (props.toasts.items.length === 0) {
    return null;
  }

  const action = (key, model) => {
    if (model.type === "success") {
      setTimeout(() => {
        props.dispatch(ToastsAction.removeById(key));
        closeSnackbar(key);
      }, 500);
    }

    return (
      <>
        {model.type !== "success" ? (
          <Button
            onClick={() => {
              props.dispatch(ToastsAction.removeById(key));
              closeSnackbar(key);
            }}
          >
            Dismiss
          </Button>
        ) : null}
      </>
    );
  };

  // let filtered_array = [];

  // if (props.toasts.items) {
  //   let array = props.toasts.items;
  //   let type = "error";

  //   for (let index = 0; index < array.length; index++) {
  //     if (array[index].type === type) {
  //       filtered_array.push(array[index]);
  //       break;
  //     } else if (array[index].type !== type) {
  //       filtered_array.push(array[index]);
  //     }
  //   }
  // }

  return (
    // eslint-disable-next-line array-callback-return
    props.toasts.items.map((model) => {
      enqueueSnackbar(model.messagesString ? model.messagesString : "", {
        preventDuplicate: true,
        autoHideDuration: model.type === "success" ? 1000 : null,
        action: action(model.id, model),
        key: model.id,
        variant: model.type,
      });
    })
  );
}

export { Toasts as Unconnected };

export default connect(mapStateToProps)(Toasts);
