import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Grid, IconButton, useTheme, Typography } from "@mui/material";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../App";
import TopArtistAction from "../../../stores/topArtistReport/TopArtistAction";
import RenderMedia from "../../../components/renderMedia/RenderMedia";
import ImageIcon from "@mui/icons-material/Image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileArrowUp } from "@fortawesome/free-solid-svg-icons";
import {
  faApple,
  faSpotify,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TopArtistAction.REQUEST_TOPARTIST,
    TopArtistAction.REQUEST_TOPARTIST_FILTER,
  ]),
  pagination: state.topArtist.topArtist || [],
  topArtist: state.topArtist.topArtist || [],
  topArtistFilter: state.topArtistFilter.topArtistFilter || [],
});

function TopArtists(props) {
  const [page_limit] = useState(15);
  const { isRequesting } = props;

  function fetchTopArtist(params) {
    props.dispatch(TopArtistAction.requestTopArtist(params));
  }

  const rows = [];

  const returnPlatformIcons = (platform) => {
    let platformLogo = [];
    platform.map((item) => {
      item.stream_platform_id === "1" &&
        item.stream_platform_artist_id &&
        platformLogo.push(
          <FontAwesomeIcon
            className="mx-2"
            style={{
              fontSize: "16px",
            }}
            icon={faSpotify}
          ></FontAwesomeIcon>
        );

      item.stream_platform_id === "2" &&
        item.stream_platform_artist_id &&
        platformLogo.push(
          <FontAwesomeIcon
            style={{
              fontSize: "16px",
            }}
            className="mx-2"
            icon={faApple}
          ></FontAwesomeIcon>
        );

      item.stream_platform_id === "3" &&
        item.stream_platform_artist_id &&
        platformLogo.push(
          <FontAwesomeIcon
            style={{
              fontSize: "16px",
            }}
            className="mx-2"
            icon={faYoutube}
          ></FontAwesomeIcon>
        );
    });
    return platformLogo;
  };

  if (props.topArtist) {
    props.topArtist.top_artists &&
      props.topArtist.top_artists.length > 0 &&
      props.topArtist.top_artists.map((item, index) => {
        rows.push({
          sl_no: index + 1,
          id: item.key,
          artist_name: item.artist_name,

          stream_percentage: item?.stream_percentage,
          audio_streams: item?.count,
          stream_platforms:
            item.stream_platforms && item.stream_platforms.length !== 0
              ? returnPlatformIcons(item.stream_platforms)
                ? returnPlatformIcons(item.stream_platforms).map((item) => {
                    console.log("item", item);
                    return item;
                  })
                : null
              : null,
        });
      });
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "artist_name",
      headerName: "Artists",
      flex: 2,
    },

    {
      field: "stream_percentage",
      headerName: "Stream Percentage",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "audio_streams",
      headerName: "Audio Streams",
      flex: 1,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "stream_platforms",
      headerName: "Stream platforms",
      flex: 1,
      renderCell: (params) => <>{params.row.stream_platforms}</>,
    },
  ];

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchTopArtist(params);
  }, []);

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                isRequesting={isRequesting}
                rows={rows}
                rowHeight={80}
                pagelength={props.pagination.total_page_no}
                page={props.pagination.current_page_no}
                handleChange={handleChange}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(TopArtists);
