import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";

// Reuse Pagination components
export default function PaginationUI(props) {
  return (
    <div>
      <Stack spacing={2}>
        <Pagination
          variant="text"
          shape="rounded"
          // color="primary"
          // defaultPage={1}
          count={props.pagelength}
          page={props.page + 1}
          onChange={props.handleChange}
          renderItem={(page) => (
            <PaginationItem
              {...page}
              disableRipple
              sx={{
                color: "#86aeb6",
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                  borderBottom: "3px solid #e64b25",
                  fontWeight: "bold",
                  color: "#2a5e6c",
                },
              }}
            />
          )}
          // sx={{
          //   position: "relative",
          //   bottom: 10,
          //   top: 10,
          // }}
        />
      </Stack>
    </div>
  );
}
