import BaseReducer from "../../utilities/BaseReducer";
import DirectorAction from "./DirectorAction";

export default class DirectorFilterReducer extends BaseReducer {
  //initial state of DirectorFilterReduser
  initialState = {
    directorFilter: [],
  };
  //DirectorFilterReduser request action finish
  [DirectorAction.REQUEST_DIRECTOR_FILTER_FINISHED](state, action) {
    return {
      ...state,
      directorFilter: action.payload,
    };
  }

  [DirectorAction.REQUEST_DIRECTOR_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      directorFilter: action.payload,
    };
  }
}
