import { BaseModel } from "sjs-base-model";

export default class A3ReportsArtistModel extends BaseModel {
  client_wise_artist_data = [];

  constructor(data1) {
    super();
    this.update(data1);
  }
}
