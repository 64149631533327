import { BaseModel } from "sjs-base-model";

export default class TimeZoneModel extends BaseModel {
  timezonedata = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
