import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

export default class UserAccessModel extends BaseModel {
  //USER access data model
  impersonate = boolean;
  manage = boolean;
  manageGroupMembership = boolean;
  mapRoles = boolean;
  view = boolean;

  constructor(data1) {
    super();

    this.update(data1);
  }
}
