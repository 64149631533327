import { Box, Grid } from "@mui/material";
import React from "react";
import { theme } from "../../views/App";
import { Doughnut } from "react-chartjs-2";

const TopRetailer = (props) => {
  let keysArray;
  let valuesArray;
  let highestPercentage;

  if (props && props.topRetailerReport) {
    const sortedData = [...props.topRetailerReport].sort(
      (a, b) => b.count - a.count
    );

    // Get the key with the highest count
    const highestCountKey = sortedData[0].key;
    highestPercentage = parseFloat(sortedData[0].percentage).toFixed(2);

    keysArray = [highestCountKey, "Others"];
    valuesArray = [sortedData[0].count];

    let sumOfOthers = 0;
    for (let i = 1; i < sortedData.length; i++) {
      sumOfOthers += sortedData[i].count;
    }

    valuesArray.push(sumOfOthers);
  }

  const data = {
    labels: keysArray,
    datasets: [
      {
        data: valuesArray,
        backgroundColor: [
          theme.palette.primary.main,
          theme.palette.primary.light,
        ],
        // hoverBackgroundColor: [
        //   theme.palette.primary.main,
        //   theme.palette.background.default,
        // ],
      },
    ],
  };
  const options = {
    cutout: "60%",
    plugins: {
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
    animations: {
      tension: {
        duration: 900,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    hoverBorderColor: "black",
    hoverBorderWidth: 1,
  };

  return (
    <Box
      width={"100%"}
      height={200}
      sx={{
        background: theme.palette.background.default,
        borderRadius: "12px",
      }}
    >
      <Box paddingY={0} paddingX={2} width="100%">
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="center"
          alignItems={"flex-start"}
          height="100%"
        >
          <Grid
            item
            container
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={9}
            justifyContent="flex-start"
            alignItems={"center"}
            height="100%"
          >
            <Box width={"100%"} height="100%">
              <Grid
                item
                container
                justifyContent={"center"}
                alignItems="flex-start"
                flexDirection={"column"}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                height="100%"
              >
                <Grid container alignItems="center">
                  <Grid item xs={7}>
                    <Box>
                      <h6
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        TOP RETAILER
                      </h6>
                      <p
                        style={{
                          fontSize: 18,
                        }}
                      >
                        {props?.topRetailerReport && props?.topRetailerReport[0]
                          ? props.topRetailerReport[0].key
                          : null}
                      </p>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    textAlign="center"
                    style={{ height: "100px" }}
                  >
                    <Doughnut data={data} options={options} />
                  </Grid>
                </Grid>

                <Grid item container xs={12} mt={7}>
                  <Grid item xs={6}>
                    <Box sx={{ lineHeight: 1.2 }}>
                      <div
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        CURRENT
                      </div>
                      <div
                        className="p-0 m-0"
                        style={{
                          fontSize: 32,
                        }}
                      >
                        {props?.topRetailerReport && props?.topRetailerReport[0]
                          ? props.topRetailerReport[0].count
                          : null}
                      </div>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Box
                      sx={{
                        lineHeight: 1.2,
                      }}
                    >
                      <div
                        style={{
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        % OF GLOBAL
                      </div>
                      <div
                        className="p-0 m-0"
                        style={{
                          fontSize: 32,
                          textAlign: "center",
                        }}
                      >
                        {highestPercentage}%
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TopRetailer;
