import React, { useState } from "react";
import environment from "environment";
import { Avatar } from "@mui/material";

const AvatarLogo = (props) => {
  const [imgUrl, setImgUrl] = useState(() => {
    props.url && RetrieveImageUrl(props.url);
  });

  function RetrieveImageUrl() {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    let userId = localStorage.getItem("userId");

    let imageUrl = "";
    fetch(
      `${environment.api.baseApi}/get_profile_picture?path=${props.url}&user_id=${userId}&current_page=profile`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        imageUrl = URL.createObjectURL(blob);

        setImgUrl(imageUrl);
      });
  }

  return (
    <Avatar
      src={imgUrl}
      // sx={{
      //   width: 50,
      //   height: 50,
      // }}
    />
  );
};

export default AvatarLogo;
