import { BaseModel } from "sjs-base-model";

// TopReleaseModel
export default class TopReleaseModel extends BaseModel {
  // response getting from the api mentioned end point
  // _shards = {};
  // current_page_no = 0;
  // hits = {};
  // total_page_no = 0;
  // timed_out = false;
  // took = 0;
  top_releases_list = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
