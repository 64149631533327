import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  IconButton,
  ListItem,
} from "@mui/material";
import { theme } from "../../views/App";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MuiMonthPicker from "../../components/datePicker/MuiMonthPicker";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import dayjs from "dayjs";
import { format } from "date-fns";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { CSVLink } from "react-csv";
import YTRevenueReportAction from "../../stores/ytRevenueReport/YTRevenueReportAction";

// mapstatetoprops function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    YTRevenueReportAction.REQUEST_YT_REVENUE_REPORT_EXPORT,
  ]),
  ytRevenueReportExport:
    state.ytRevenueReportExport.ytRevenueReportExport || [],
});

function YTRevenueExportDailoge(props) {
  const miniBoxStyle = {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
    height: 40,
    width: 100,
    pointerEvents: "none",
  };
  const hoverStyle = {
    transform: "scale(1.1)",
    transition: "transform 0.2s ease-in-out",
  };

  const [tabValue, setTabValue] = useState(0);
  const [monthFilter, setMonthFilter] = useState(null);

  const [noFiltersAlert, setNoFiltersAlert] = useState(false);

  function monthFilterHandleChange(date) {
    setMonthFilter(date);
  }

  let filterdArray = [
    "client_name",
    "Retailer",
    "Retailer Reporting Period",
    "label_name",
    "Artist",
    "release_title",
    "upc",
    "Song",
    "isrc",
    "territory",
    "asset_type",
    "quantity_net",
    "Label Revenue share %",
    "Label Share Amount",
  ];

  if (JSON.parse(localStorage.getItem("page_permission")).includes("*")) {
    filterdArray.push(
      "Mix (Version)",
      "Retailer STMT Country ISO",
      "Client Revenue Share %",
      "Client Share Amount",
      "Gross Net"
    );
  }

  let [errorMessageNote, seterrorMessageNote] = useState(null);

  const [checkedItems, setCheckedItems] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);

  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter((item) => item !== value));
    } else {
      setCheckedItems([...checkedItems, value]);
    }
  };

  let [selectAll, setSelectAll] = useState(false);

  const [csvData, setCsvData] = useState([]);
  const csvLink = useRef();

  const exportReportFunction = () => {
    if (!monthFilter) {
      setNoFiltersAlert(true);
      seterrorMessageNote("Please Select Month");
    } else {
      setExportLoading(true);
      const filter = [];
      let params = {};

      function monthFilterResponse(date) {
        const firstDayOfMonth = dayjs(date).startOf("month");
        const lastDayOfMonth = dayjs(date).endOf("month");
        let filterString = ``;

        filterString = `["day","must","gte","range","${format(
          firstDayOfMonth.$d,
          "yyyy-MM-dd"
        )}"],["day","must","lte","range","${format(
          lastDayOfMonth.$d,
          "yyyy-MM-dd"
        )}"]`;

        return filterString;
      }

      if (monthFilter) {
        filter.push(monthFilterResponse(monthFilter));
      }

      params = {
        filters: `[${filter}]`,
        order_by: `[["created_on","desc"]]`,
        page_limit: `none`,
      };

      const callBackfunction = (response) => {
        let Export = [];
        if (
          response &&
          response.hits &&
          response.hits.hits &&
          response.hits.hits.length > 0
        ) {
          response.hits.hits.forEach((item) => {
            let created_obj = {};
            let alldata = {};

            let isClient = false;
            if (
              JSON.parse(localStorage.getItem("page_permission")).includes("*")
            ) {
              isClient = true;
            }

            Object.assign(alldata, {
              "Client Name": item._source.client_id.client_name,
              Retailer: item._source.retailer_name,
              "Retailer Reporting Period": item._source.day,
              "Label Name": item._source.label.label_name,
              Artist: item._source?.artist,
              "Release Title": item._source.release_title,
              UPC: item._source.upc,
              Song: item._source.track_title,
              ISRC: item._source.isrc,
              Territory: item._source.territory,
              "Asset Type": item._source.asset_type,
              "Owned Views": item._source.quantity_net,
              "Gross Net": item._source.cv_amount,
              "Client Revenue Share %": item._source.cv_share_percentage,
              "Client Share Amount": item._source.client_share_amount,
              "Label Revenue share %": item._source.cv_label_share,
              "Label Share Amount": item._source.label_share_amount,
            });

            if (
              JSON.parse(localStorage.getItem("page_permission")).includes("*")
            ) {
              Object.assign(alldata, {
                "Client Revenue Share %": item._source.cv_share_percentage,
                "Label Share Amount": item._source.label_share_amount,
              });
            }

            Object.assign(
              created_obj,
              checkedItems.includes("client_name") && {
                "Client Name": item._source.client_id.client_name,
              },
              checkedItems.includes("Retailer") && {
                Retailer: item._source.retailer_name,
              },
              checkedItems.includes("Retailer Reporting Period") && {
                "Retailer Reporting Period": item._source.day,
              },
              checkedItems.includes("label_name") && {
                "Label Name": item._source.label.label_name,
              },
              checkedItems.includes("Artist") && {
                Artist: item._source?.artist,
              },
              checkedItems.includes("release_title") && {
                "Release Title": item._source.release_title,
              },
              checkedItems.includes("upc") && {
                upc: item._source.upc,
              },

              checkedItems.includes("Song") && {
                Song: item._source.track_title,
              },
              checkedItems.includes("isrc") && {
                isrc: item._source.isrc,
              },

              checkedItems.includes("Retailer STMT Country ISO") && {
                "Retailer STMT Country ISO":
                  item._source.retailer_stmt_country_iso,
              },
              checkedItems.includes("territory") && {
                Territory: item._source.territory,
              },
              checkedItems.includes("asset_type") && {
                "Asset Type": item._source.asset_type,
              },
              checkedItems.includes("Sales Classification") && {
                "Sales Classification": item._source.sales_classification,
              },
              checkedItems.includes("Sales Description") && {
                "Sales Description": item._source.sales_description,
              },
              checkedItems.includes("quantity_net") && {
                "Owned Views": item._source.quantity_net,
              },
              checkedItems.includes("Gross Net") && {
                "Gross Net": item._source.cv_amount,
              },
              checkedItems.includes("Client Revenue Share %") && {
                "Client Revenue Share %": item._source.cv_share_percentage,
              },
              checkedItems.includes("Client Share Amount") && {
                "Client Share Amount": item._source.client_share_amount,
              },
              checkedItems.includes("Label Revenue share %") && {
                "Label Revenue share %": item._source.cv_label_share,
              },
              checkedItems.includes("Label Share Amount") && {
                "Label Share Amount": item._source.label_share_amount,
              }
            );
            Export.push(
              filterdArray.length === checkedItems.length
                ? alldata
                : created_obj
            );
          });
          setCsvData(Export);
        } else {
          //   setNoFiltersAlert(true);
          //   seterrorMessageNote("No Data Found");
        }

        setTimeout(() => {
          setExportLoading(false);
          csvLink.current.link.click();
          setCsvData([]);
          Export = [];
        }, 2000);
      };

      function fetchYTRevenueReportExport(params) {
        props.dispatch(
          YTRevenueReportAction.requestYTRevenueReportExport(
            params,
            callBackfunction
          )
        );
      }

      fetchYTRevenueReportExport(params);
    }
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setCheckedItems([...filterdArray]);
    } else {
      setCheckedItems([]);
    }
    setSelectAll(!selectAll);
  };

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @keyframes zoomInFade {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-8px);
          }
          60% {
            transform: translateY(2px);
          }
        }
      `,
        }}
      />

      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose();
          setCheckedItems([]);
          setMonthFilter(null);
          setSelectAll(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <DialogContentText>
            <>
              <Box
                sx={{
                  borderBottom: `2px solid ${theme.palette.common.grey}`,
                }}
              >
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Grid item px={1} sx={{ display: "flex" }} mb={1}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FilterAltIcon
                        sx={{ marginBottom: "2px", marginRight: "10px" }}
                      />
                      Export Parameters
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    px={1}
                    sx={{
                      display: "inline-block",
                      borderRadius: "50%",
                      padding: "4px",
                      transition: "background-color 0.5s ease-in",
                      "&:hover": {
                        ...hoverStyle,
                        backgroundColor: theme.palette.common.grey,
                      },
                    }}
                  >
                    <CloseIcon
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: theme.palette.secondary.main,
                        },
                      }}
                      onClick={() => {
                        setTabValue(0);
                        props.onClose();
                        setCheckedItems([]);
                        setMonthFilter(null);
                        setSelectAll(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid xs={12} sx={{ marginY: "20px" }}></Grid>
              <>
                <Grid
                  item
                  container
                  lg={12}
                  md={12}
                  sm={12}
                  alignItems={"center"}
                  justifyContent="flex-start"
                  gap={1}
                >
                  <Grid
                    xs={12}
                    sm={6}
                    md={2}
                    item
                    container
                    sx={{
                      ...miniBoxStyle,
                      border: miniBoxStyle.border,
                      borderRadius: "10px",
                    }}
                    textAlign="center"
                    justifyContent={"center"}
                    alignItems="center"
                  >
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      textAlign="center"
                      alignItems={"center"}
                      px={0.5}
                      //mt={0.5}
                    >
                      <span
                        style={{
                          fontSize: "20px",
                          fontWeight: "bold",
                        }}
                      >
                        Month
                      </span>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={3}
                    md={3}
                    sm={3}
                    sx={{
                      width: { sm: "100%", md: "30%" },

                      pointerEvents: exportLoading && "none",
                      opacity: exportLoading && 0.5,
                    }}
                  >
                    <MuiMonthPicker
                      onChange={monthFilterHandleChange}
                      value={monthFilter}
                    />
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      className="p-0 m-0"
                      sx={{
                        zIndex: 1,
                        flex: 1,
                      }}
                      control={
                        <Checkbox
                          title="Select all Fileds"
                          sx={{
                            zIndex: 1,
                            padding: "5px",
                            border: `1px solid ${theme.palette.common.grey}`,
                            borderRadius: "4px",
                          }}
                          checked={
                            selectAll &&
                            checkedItems.length === filterdArray.length
                              ? true
                              : false
                          }
                          onChange={() => handleSelectAll()}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </>

              {
                <Box
                  className=" my-2 scrollable p-1"
                  height={"auto"}
                  style={{
                    color: theme.palette.primary.main,
                  }}
                  maxHeight={300}
                >
                  {filterdArray?.map((item) => (
                    <FormControlLabel
                      key={item}
                      control={
                        <Checkbox
                          checked={checkedItems.includes(item)}
                          onChange={() => handleCheckboxChange(item)}
                        />
                      }
                      label={(item === "quantity_net" ? "owned_views" : item)
                        .replace(/_/g, " ")
                        .replace(/\b\w/g, (c) => c.toUpperCase())}
                    />
                  ))}
                </Box>
              }
            </>
          </DialogContentText>
        </DialogContent>

        <CSVLink
          data={csvData}
          //   filename="RevenueReport.csv"
          filename={`Yt Revenue Report (${dayjs(monthFilter).format(
            "MMM YYYY"
          )}).csv`}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />

        <div>
          <Grid container justifyContent={"flex-end"} p={3} gap={2}>
            {
              <>
                <Button
                  onClick={() => {
                    exportReportFunction();
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "5px",
                    textTransform: "none",
                    width: "150px",
                  }}
                  disabled={
                    !selectAll && checkedItems?.length === 0 ? true : false
                  }
                >
                  {!exportLoading ? "Export" : "Downloading..."}
                </Button>
              </>
            }
          </Grid>
        </div>
        <MuiSnackbarAlert
          open={noFiltersAlert}
          onClose={() => setNoFiltersAlert(false)}
          message={errorMessageNote}
        />
      </Dialog>
    </>
  );
}

export default connect(mapStateToProps)(YTRevenueExportDailoge);
