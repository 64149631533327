import TrackAction from "./TrackAction";
import BaseReducer from "../../utilities/BaseReducer";

// ExistingTrackReduser
export default class TrackStatusCountReduser extends BaseReducer {
  initialState = {
    trackStatusCount: [],
  };
  [TrackAction.REQUEST_TRACKSTATUSCOUNT_FINISHED](state, action) {
    return {
      ...state,
      trackStatusCount: action.payload,
    };
  }
}
