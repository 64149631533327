import { Box, Typography } from "@mui/material";
import React from "react";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";

function NoDataFoundReports(props) {
  return (
    <Box
      mt={5}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ textAlign: "center", fontSize: "18px" }}>
        <ThumbDownAltIcon style={{ fontSize: 150 }} />
        <Typography component={"p"} fontSize={"18px"} mt={2}>
          It seems there is no data available right now. <br />
          Please reach out to support@a3labels.in for any questions.
        </Typography>
      </Box>
    </Box>
  );
}

export default NoDataFoundReports;
