import { BaseModel } from "sjs-base-model";

// TopLabelPostModel
export default class TopLabelPostModel extends BaseModel {
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
