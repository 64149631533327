import { BaseModel } from "sjs-base-model";

// Assets delete Model
export default class AssetsDeleteModel extends BaseModel {
  _id = "";
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
