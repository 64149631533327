import BaseReducer from "../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

export default class A3LabelRevenueReportReducer extends BaseReducer {
  initialState = {
    labelRevenueReport: [],
  };
  [A3ReportsAction.REQUEST_A3LABEL_REVENUE_REPORT_FINISHED](state, action) {
    return {
      ...state,
      labelRevenueReport: action.payload,
    };
  }
}
