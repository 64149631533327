import BaseReducer from "../../utilities/BaseReducer";
import LabelAction from "./LabelAction";

export default class LabelFilterReduser extends BaseReducer {
  //initial state of LabelFilterReduser
  initialState = {
    labelFilter: [],
  };
  //LabelFilterReduser request action finish
  [LabelAction.REQUEST_LABELS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      labelFilter: action.payload,
    };
  }

  [LabelAction.REQUEST_LABELS_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      labelFilter: action.payload,
    };
  }
}
