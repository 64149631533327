import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik, Field, getIn } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../App";
import AssetsAction from "../../stores/assets/AssetsAction";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  assets_name: string()
    .required("Enter Assets name")
    .trim()
    .min(1, "Assets name should be of minimum 1 characters length")
    .required("Assets name is required"),
});

const AssetsPost = (props) => {
  let initial = {};
  if (props.edit) {
    initial = {
      assets_name: props.editData.assets_name,
      release_id: props.editData.release_id,
      // asset_url: props.editData.asset_url,
    };
  } else {
    initial = {
      assets_name: "",
      release_id: props.releaseId,
      // asset_url: props.url,
    };
  }
  // Formik

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          // parameter for showing list based on the modified_by
          const params = {
            current_page: "upload",
          };

          values = {
            ...values,
            assets_name: values.assets_name.trim(),
          };

          if (props.edit) {
            props
              .dispatch(
                AssetsAction.requestPutAssets(
                  JSON.stringify(values),
                  props.editData.id,
                  params
                )
              )
              .then(() => {
                props.onSubmit();
                props.onClose();
                setLoading(false);
              });
          } else {
            const callBack = (res) => {
              props.setAssetId(res.id);
            };

            props
              .dispatch(
                AssetsAction.requestPostAssets(values, callBack, params)
              )
              .then(() => {
                props.onSubmit();
                props.onClose();
                setLoading(false);
              });
          }
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%", marginBottom: 5 }}>
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"div"}
                    fontSize={30}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      justifySelf: "center",
                    }}
                  >
                    {!props.edit
                      ? formatName("Add Assets Name")
                      : formatName("Edit Assets Name")}
                  </Typography>

                  <Typography
                    component={"span"}
                    sx={{ justifySelf: "end", marginLeft: 3 }}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <HighlightOffIcon sx={{ fontSize: 20 }} />
                  </Typography>
                </Typography>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Assets Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Assets Name"
                      id="assets_name"
                      size="small"
                      name="assets_name"
                      error={
                        Boolean(errors.assets_name) &&
                        Boolean(touched.assets_name)
                      }
                      helperText={
                        Boolean(touched.assets_name) && errors.assets_name
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Add Asset"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Submit"
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(AssetsPost);
