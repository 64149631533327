import ActionUtility from "../../utilities/ActionUtility";
import ClientEffect from "./ClientEffect";

export default class ClientAction {
  // ClientAction action with an function declaration
  static REQUEST_CLIENT = "ClientAction.REQUEST_CLIENT";
  static REQUEST_CLIENT_FINISHED = "ClientAction.REQUEST_CLIENT_FINISHED";

  static REQUEST_CLIENT_FILTER = "ClientAction.REQUEST_CLIENT_FILTER";
  static REQUEST_CLIENT_FILTER_FINISHED =
    "ClientAction.REQUEST_CLIENT_FILTER_FINISHED";

  static REQUEST_PUT_CLIENT = "ClientAction.REQUEST_PUT_CLIENT";
  static REQUEST_PUT_CLIENT_FINISHED =
    "ClientAction.REQUEST_PUT_CLIENT_FINISHED";

  static REQUEST_POST_CLIENT = "ClientAction.REQUEST_POST_CLIENT";
  static REQUEST_POST_CLIENT_FINISHED =
    "ClientAction.REQUEST_POST_CLIENT_FINISHED";

  static REQUEST_PUT_CLIENT_BULKUPDATE =
    "ClientAction.REQUEST_PUT_CLIENT_BULKUPDATE";
  static REQUEST_PUT_CLIENT_BULKUPDATE_FINISHED =
    "ClientAction.REQUEST_PUT_CLIENT_BULKUPDATE_FINISHED";

  static REQUEST_CLIENT_CLEAR = "ClientAction.REQUEST_CLIENT_CLEAR";
  static REQUEST_CLIENT_CLEAR_FINISHED =
    "ClientAction.REQUEST_CLIENT_CLEAR_FINISHED";

  static REQUEST_CLIENT_FILTER_CLEAR =
    "ClientAction.REQUEST_CLIENT_FILTER_CLEAR";
  static REQUEST_CLIENT_FILTER_CLEAR_FINISHED =
    "ClientAction.REQUEST_CLIENT_FILTER_CLEAR_FINISHED";

  // Device Reset
  static REQUEST_MOBILE_DEVICE_RESET =
    "ClientAction.REQUEST_MOBILE_DEVICE_RESET";
  static REQUEST_MOBILE_DEVICE_RESET_FINISHED =
    "ClientAction.REQUEST_MOBILE_DEVICE_RESET_FINISHED";

  // METHODS
  // Organization GET function
  static requestClient(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_CLIENT,
        ClientEffect.requestClient,
        params
      );
    };
  }

  static requestClientClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_CLIENT_CLEAR,
        ClientEffect.requestClientClear
      );
    };
  }

  static requestClientFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_CLIENT_FILTER_CLEAR,
        ClientEffect.requestClientFilterClear
      );
    };
  }

  static requestClientFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_CLIENT_FILTER,
        ClientEffect.requestClientFilter,
        params
      );
    };
  }
  static requestPutClient(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_PUT_CLIENT,
        ClientEffect.requestPutClient,
        data,
        id
      );
    };
  }

  // Organizationfunction
  static requestPostClient(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_POST_CLIENT,
        ClientEffect.requestPostClient,
        data,
        callBack
      );
    };
  }

  static requestPutClientBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_PUT_CLIENT_BULKUPDATE,
        ClientEffect.requestPutClientBulkUpdate,
        data
      );
    };
  }

  static requestDeviceReset(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientAction.REQUEST_MOBILE_DEVICE_RESET,
        ClientEffect.requestDeviceReset,
        id
      );
    };
  }
}
