import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ReleasesModel from "./models/ReleasesModel";
import ReleasePutModel from "./models/ReleasePutModel";
import ReleasePostModel from "./models/ReleasePostModel";
import ReleaseByIdModel from "./models/ReleaseByIdModel";

export default class ReleasesEffect {
  // calling an API according to the releases model
  static async requesReleases(params) {
    const endpoint = environment.api.release;
    return EffectUtility.getToModel(ReleasesModel, endpoint, params);
  }

  static async requesTop5DeliveredReleases(params) {
    const endpoint = environment.api.finalReleases;
    return EffectUtility.getToModel(ReleasesModel, endpoint, params);
  }

  static async requesDraftReleases(params) {
    const endpoint = environment.api.release;
    return EffectUtility.getToModel(ReleasesModel, endpoint, params);
  }

  static async requesReleaseClear() {
    return {};
  }

  static async requesReleaseFilterClear() {
    return {};
  }

  // Get method for the release filter
  static async requestReleaseFilter(params) {
    const endpoint = environment.api.release;
    return EffectUtility.getToModel(ReleasesModel, endpoint, params);
  }

  // put method for the release
  static async requestPutRelease(data, id, params, callback) {
    const endpoint = environment.api.release + "/" + id;

    let response = EffectUtility.putToModel(
      ReleasePutModel,
      endpoint,
      data,
      params
    );

    if (callback) {
      response.then((res) => {
        callback(res);
      });
    }
    return response;
  }

  // post final release method for the release
  static async requestPostFinalRelease(data, id, params) {
    const endpoint = environment.api.finalReleases + "/" + id;
    return EffectUtility.postToModel(ReleasePostModel, endpoint, data, params);
  }

  // post method for the release
  static async requestPostRelease(data, callBack, current_page) {
    const baseEndpoint = environment.api.release + "/add";
    const endpoint = current_page ? baseEndpoint + current_page : baseEndpoint;

    let response = EffectUtility.postWithIdModel(
      ReleasePostModel,
      endpoint,
      data
    );

    response.then((resp) => {
      if (callBack && resp.status === "success") {
        callBack(resp);
      }
    });

    return response;
  }

  static async requestReleaseById(id, params, callBack) {
    const endpoint = environment.api.release + "/" + id;
    let response = EffectUtility.getToModel(ReleaseByIdModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestFinalReleaseById(id, params, callBack) {
    const endpoint = environment.api.finalReleases + "/" + id;
    let response = EffectUtility.getToModel(ReleaseByIdModel, endpoint, params);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestDeleteRelease(id, params) {
    const endpoint = environment.api.release + "/" + id;
    return EffectUtility.deleteToModel(ReleasePostModel, endpoint, params);
  }

  static async requestFinalReleases(params) {
    const endpoint = environment.api.finalReleases;
    return EffectUtility.getToModel(ReleasesModel, endpoint, params);
  }

  static async RequestWithdrawReleaseFromReview(id, params) {
    const endpoint = environment.api.withdrawRelease + "/" + id;
    return EffectUtility.getToModel(ReleasePostModel, endpoint, params);
  }

  static async requestReleaseExistingTrack(id, params, callBack) {
    const endpoint = environment.api.release + "/" + id;
    let response = EffectUtility.getToModel(ReleaseByIdModel, endpoint);

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }
    return response;
  }
}
