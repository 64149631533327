import ActionUtility from "../../utilities/ActionUtility";
import RevenueReportEffect from "./RevenueReportEffect";

export default class RevenueReportAction {
  static REQUEST_REVENUE_REPORT = "RevenueReportAction.REQUEST_REVENUE_REPORT";
  static REQUEST_REVENUE_REPORT_FINISHED =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_FINISHED";

  static REQUEST_REVENUE_REPORT_EXPORT =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_EXPORT";
  static REQUEST_REVENUE_REPORT_EXPORT_FINISHED =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_EXPORT_FINISHED";

  static REQUEST_REVENUE_REPORT_FILTER =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER";
  static REQUEST_REVENUE_REPORT_FILTER_FINISHED =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER_FINISHED";

  static REQUEST_FILTER_REVENUE_REPORT =
    "RevenueReportAction.REQUEST_FILTER_REVENUE_REPORT";
  static REQUEST_FILTER_REVENUE_REPORT_FINISHED =
    "RevenueReportAction.REQUEST_FILTER_REVENUE_REPORT_FINISHED";

  static REQUEST_PUT_REVENUE_REPORT =
    "RevenueReportAction.REQUEST_PUT_REVENUE_REPORT";
  static REQUEST_PUT_REVENUE_REPORT_FINISHED =
    "RevenueReportAction.REQUEST_PUT_REVENUE_REPORT_FINISHED";

  static REQUEST_POST_REVENUE_REPORT =
    "RevenueReportAction.REQUEST_POST_REVENUE_REPORT";
  static REQUEST_POST_REVENUE_REPORT_FINISHED =
    "RevenueReportAction.REQUEST_POST_REVENUE_REPORT_FINISHED";

  static REQUEST_REVENUE_REPORT_CLEAR =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_CLEAR";
  static REQUEST_REVENUE_REPORT_CLEAR_FINISHED =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_CLEAR_FINISHED";

  static REQUEST_REVENUE_REPORT_FILTER_CLEAR =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER_CLEAR";
  static REQUEST_REVENUE_REPORT_FILTER_CLEAR_FINISHED =
    "RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER_CLEAR_FINISHED";

  // METHODS
  static requestRevenueReport(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_REVENUE_REPORT,
        RevenueReportEffect.requestRevenueReport,
        params
      );
    };
  }

  static requestRevenueReportExport(params, callBackfunction) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_REVENUE_REPORT_EXPORT,
        RevenueReportEffect.requestRevenueReportExport,
        params,
        callBackfunction
      );
    };
  }

  static requestRevenueReportClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_REVENUE_REPORT_CLEAR,
        RevenueReportEffect.requestRevenueReportClear
      );
    };
  }

  static requesRevenueReportFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER_CLEAR,
        RevenueReportEffect.requesRevenueReportFilterClear
      );
    };
  }

  static requestRevenueReportFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER,
        RevenueReportEffect.requestRevenueReportFilter,
        params
      );
    };
  }

  static requestFilterRevenueReport(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_FILTER_REVENUE_REPORT,
        RevenueReportEffect.requestFilterRevenueReport,
        params
      );
    };
  }

  static requestPutRevenueReport(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_PUT_REVENUE_REPORT,
        RevenueReportEffect.requestPutRevenueReport,
        data,
        id
      );
    };
  }

  static requestPostRevenueReport(data, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueReportAction.REQUEST_POST_REVENUE_REPORT,
        RevenueReportEffect.requestPostRevenueReport,
        data,
        callBack
      );
    };
  }
}
