import React, { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
    };
  }

  componentDidCatch(componentError, errorInfo) {
    const componentStack = errorInfo.componentStack;
    // console.log({ errorInfo: componentStack });
    // Send error to Datadog
  }

  render() {
    if (this.state.hasError) {
      return (
        <h6>
          {this.props.message ? this.props.message : "Something went wrong."}
        </h6>
      );
    }
    return this.props.children;
  }
}
