import ActionUtility from "../../utilities/ActionUtility";
import NotificationEffect from "./NotificationEffect.js";

export default class NotificationAction {
  static REQUEST_NOTIFICATIONS = "NotificationAction.REQUEST_NOTIFICATIONS";
  static REQUEST_NOTIFICATIONS_FINISHED =
    "NotificationAction.REQUEST_NOTIFICATIONS_FINISHED";

  static REQUEST_FILTER_NOTIFICATIONS =
    "NotificationAction.REQUEST_FILTER_NOTIFICATIONS";
  static REQUEST_FILTER_NOTIFICATIONS_FINISHED =
    "NotificationAction.REQUEST_FILTER_NOTIFICATIONS_FINISHED";

  static REQUEST_NOTIFICATIONS_BY_ID =
    "NotificationAction.REQUEST_NOTIFICATIONS_BY_ID";
  static REQUEST_NOTIFICATIONS_BY_ID_FINISHED =
    "NotificationAction.REQUEST_NOTIFICATIONS_BY_ID_FINISHED";

  static REQUEST_POST_NOTIFICATIONS =
    "NotificationAction.REQUEST_POST_NOTIFICATIONS";
  static REQUEST_POST_NOTIFICATIONS_FINISHED =
    "NotificationAction.REQUEST_POST_NOTIFICATIONS_FINISHED";

  static REQUEST_PUT_NOTIFICATIONS =
    "NotificationAction.REQUEST_PUT_NOTIFICATIONS";
  static REQUEST_PUT_NOTIFICATIONS_FINISHED =
    "NotificationAction.REQUEST_PUT_NOTIFICATIONS_FINISHED";

  static REQUEST_PUT_NOTIFICATIONS_BULK_UPDATE =
    "NotificationAction.REQUEST_PUT_NOTIFICATIONS_BULK_UPDATE";
  static REQUEST_PUT_NOTIFICATIONS_BULK_UPDATE_FINISHED =
    "NotificationAction.REQUEST_PUT_NOTIFICATIONS_BULK_UPDATE_FINISHED";

  static REQUEST_DELETE_NOTIFICATIONS =
    "NotificationAction.REQUEST_DELETE_NOTIFICATIONS";
  static REQUEST_DELETE_NOTIFICATIONS_FINISHED =
    "NotificationAction.REQUEST_DELETE_NOTIFICATIONS_FINISHED";

  //METHODS

  static requestNotifications(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NotificationAction.REQUEST_NOTIFICATIONS,
        NotificationEffect.requestNotifications,
        params,
        callback
      );
    };
  }

  static requestFilterNotifications(params, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
        NotificationEffect.requestFilterNotifications,
        params,
        callback
      );
    };
  }

  static requestNotificationsById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NotificationAction.REQUEST_NOTIFICATIONS_BY_ID,
        NotificationEffect.requestNotificationsById,
        id,
        params
      );
    };
  }

  static requestPutNotificationsBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NotificationAction.REQUEST_PUT_NOTIFICATIONS_BULK_UPDATE,
        NotificationEffect.requestPutNotificationsBulkUpdate,
        data
      );
    };
  }

  //   static requestPostOrmNotifications(data) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         NotificationAction.REQUEST_POST_NOTIFICATIONS,
  //         NotificationEffect.requestPostOrmNotifications,
  //         data
  //       );
  //     };
  //   }

  static requestPutNotifications(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NotificationAction.REQUEST_PUT_NOTIFICATIONS,
        NotificationEffect.requestPutNotifications,
        data,
        id
      );
    };
  }

  //   static requestDeleteOrmNotifications(id) {
  //     return async (dispatch, getState) => {
  //       await ActionUtility.createThunkEffect(
  //         dispatch,
  //         NotificationAction.REQUEST_DELETE_ORM_NOTIFICATIONS,
  //         NotificationEffect.requestDeleteOrmNotifications,
  //         id
  //       );
  //     };
  //   }
}
