import { BaseModel } from "sjs-base-model";
import GenreDataModel from "./GenreDataModel";

// GenreDataHitsModel
export default class GenreDataHitsModel extends BaseModel {
  // response getting from the api

  _id = "";
  _index = "";
  _score = 0.0;
  _source = [GenreDataModel];
  constructor(data) {
    super();
    this.update(data);
  }
}
