import BaseReducer from "../../utilities/BaseReducer";
import NetRevenueByMonthAction from "./NetRevenueByMonthAction";

export default class TrackwiseRevenueReduser extends BaseReducer {
  initialState = {
    trackwiseRevenue: [],
  };
  [NetRevenueByMonthAction.REQUEST_TRACKWISE_REVENUE_FINISHED](state, action) {
    return {
      ...state,
      trackwiseRevenue: action.payload,
    };
  }
}
