import { BaseModel } from "sjs-base-model";

// SubGenrePutModel
export default class SubGenrePutModel extends BaseModel {
  subgenre = "";

  constructor(data2) {
    super();
    this.update(data2);
  }
}
