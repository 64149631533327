import { BaseModel } from "sjs-base-model";

export default class NotificationModel extends BaseModel {
  // response getting from the api mentioned end point

  notification_subscribed = Boolean;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
