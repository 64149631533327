import { BaseModel } from "sjs-base-model";
import { boolean } from "yup";

export default class NotificationsBulkUpdateModel extends BaseModel {
  data = [];
  //   status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
