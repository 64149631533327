import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../App";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useNavigate } from "react-router";
import {
  ErrorMessage,
  FastField,
  Field,
  Form,
  Formik,
  useFormik,
  useFormikContext,
} from "formik";
import MuiButton from "../../components/muiButton/MuiButton";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import TicketsAction from "../../stores/tickets/TicketsAction";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
});

const RaiseIssueDialog = (props) => {
  const navigate = useNavigate();

  let initial = {
    title: `${props?.releaseInfo?.type}-for-release-id-${props?.releaseInfo?.releaseData?.id}`,
    description: "",
  };
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    props.onClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <Formik
        enableReinitialize
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.title) {
            errors.title = "Title is Required";
          }

          if (!values.description) {
            errors.description = "Details is Required";
          }

          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);

          let obj = {
            ticket_desc: values.description,
            ticket_name: values.title,
            release_id: props?.releaseInfo?.releaseData?.id,
          };

          let params = {
            current_page: props.current_page,
          };

          props
            .dispatch(
              TicketsAction.requestPostTickets(JSON.stringify(obj), params)
            )
            .then(() => {
              setLoading(false);
              props.onClose();
            });
        }}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <Box>
              <Grid
                item
                container
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                justifyContent="space-between"
                alignItems={"center"}
                px={3}
                py={1}
                sx={{
                  borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
                }}
              >
                <Grid item sx={{ color: theme.palette.text.gray }}>
                  {props?.releaseInfo?.type}
                </Grid>
              </Grid>
              <hr
                className="p-0 m-0"
                style={{ color: theme.palette.text.gray }}
              />
              <Box p={5}>
                <h1
                  style={{
                    fontSize: theme.typography.h1.fontSize,
                  }}
                >
                  {formatName("Enter the Details")}
                </h1>
                <Grid container lg={12} md={12} sm={12} pt={3}>
                  <FastField
                    className={
                      touched.description && errors.description
                        ? "ticket_desc_test_area_error scrollable"
                        : "ticket_desc_test_area scrollable"
                    }
                    name="description"
                    component="textarea"
                    value={values.ticket_desc}
                    onChange={handleChange}
                    // defaultValue={demoText.textarea}
                    type="text"
                    maxRows={5}
                    minRows={5}
                    style={{
                      height: 150,
                      padding: "10px",
                      boxSizing: "border-box",
                      color: `${theme.palette.text.primary}`,
                      width: "100%",
                    }}
                    aria-label="maximum height"
                    placeholder="Enter Issue details"
                    label="description"
                  />

                  <Typography
                    component={"div"}
                    sx={{ color: theme.palette.secondary.main }}
                  >
                    <ErrorMessage name="description" />
                  </Typography>
                </Grid>
              </Box>

              <DialogActions>
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent="flex-end"
                >
                  <Grid item lg={3} md={3} sm={3} mr={2} minWidth={200}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <MuiButton
                          loading={loading}
                          name={"Raise"}
                          type="submit"
                          width="100%"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <MuiButton
                          name={"Cancel"}
                          width="100%"
                          cancel
                          onClick={() => {
                            props.onClose();
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </DialogActions>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default connect(mapStateToProps)(RaiseIssueDialog);
