import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  IconButton,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { ModelScreenContainer } from "../../componentLayout/ComponentLayout";
import { theme } from "../../../views/App";
import { useLocation, useNavigate } from "react-router";
import { Analytics, LogoutOutlined, Person } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import GroupIcon from "@mui/icons-material/Group";
import routes from "../../Route/routecoll";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function ProfilePopper(props) {
  const [open, setOpen] = React.useState(false);
  const errorLogPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const navigate = useNavigate();
  const location = useLocation();

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      errorLogPopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      errorLogPopperRef.current &&
      errorLogPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  // getting page_permission from local Storage
  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  page_permission = [...new Set(page_permission)];

  const returnMainMenu = (routes) => {
    let filtered_route_main = [];

    let filtered_route = [];

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission.length === 1 &&
      page_permission[0] === "*"
    ) {
      routes.data.forEach((col) => {
        if (col.category === "Main") {
          filtered_route_main.push(col);
        }
      });
    }

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission[0] !== "*"
    ) {
      filtered_route = routes.data.filter((col) => {
        page_permission.forEach((allowed_page_Id) => {
          if (col.category === "Main" && col.id === allowed_page_Id) {
            filtered_route_main.push(col);
          }
        });
      });
    }

    // routes.data.filter((col) => {
    //   if (col.category === "Main") {
    //     filtered_route_main.push(col);
    //   }
    // });

    console.log("filtered_route_main===>", filtered_route_main);

    let dropdownList = ["profile", "subClient", "subClientAccess"];

    filtered_route_main = filtered_route_main.filter((obj) =>
      dropdownList.includes(obj.id)
    );

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <ListItemButton
              title={text.title}
              onClick={() => {
                navigate(`${text.path}`, {
                  state: {
                    from: location.pathname,
                  },
                });
                setOpen(false);
              }}
            >
              <ListItemIcon
                sx={{
                  color: theme.palette.text.main,
                  minWidth: "36px",
                }}
              >
                <span
                  style={{
                    color: theme.palette.primary.main,
                  }}
                >
                  {text.icon}
                </span>
              </ListItemIcon>
              <ListItemText
                primary={text.title}
                sx={{
                  color: theme.palette.text.main,
                  opacity: open ? 1 : 0,
                }}
              />
            </ListItemButton>
          );
        })
      : null;
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={errorLogPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={errorLogPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 150,
                height: "auto",
                padding: 0,
                boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {returnMainMenu(routes)}
                  {/* <ListItemButton
                    title={"My Profile"}
                    onClick={() => {
                      navigate("/profile", {
                        state: {
                          from: location.pathname,
                        },
                      });
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: theme.palette.text.main,
                        minWidth: "36px",
                      }}
                    >
                      <AccountBoxIcon
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"My Profile"}
                      sx={{
                        color: theme.palette.text.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>

                  <ListItemButton
                    title={"Client"}
                    onClick={() => {
                      navigate("/client", {
                        state: {
                          from: location.pathname,
                        },
                      });
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: theme.palette.text.main,
                        minWidth: "36px",
                      }}
                    >
                      <GroupIcon
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Client"}
                      sx={{
                        color: theme.palette.text.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>

                  <ListItemButton
                    title={"UserAccess"}
                    onClick={() => {
                      navigate("/useraccess", {
                        state: {
                          from: location.pathname,
                        },
                      });
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: theme.palette.text.main,
                        minWidth: "36px",
                      }}
                    >
                      <AdminPanelSettingsIcon
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"UserAccess"}
                      sx={{
                        color: theme.palette.text.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton> */}

                  <ListItemButton
                    title={"Logout"}
                    onClick={() => {
                      navigate("/logout", {
                        state: {
                          from: location.pathname,
                        },
                      });
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: theme.palette.text.main,
                        minWidth: "36px",
                      }}
                    >
                      <LogoutOutlined
                        style={{
                          color: theme.palette.primary.main,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={"Logout"}
                      sx={{
                        color: theme.palette.text.main,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(ProfilePopper);
