import ActionUtility from "../../utilities/ActionUtility";
import TopAlbumEffect from "./TopAlbumEffect";

export default class TopAlbumAction {
  // TopAlbumAction action with an function declaration
  static REQUEST_TOP_ALBUM = "TopAlbumAction.REQUEST_TOP_ALBUM";
  static REQUEST_TOP_ALBUM_FINISHED =
    "TopAlbumAction.REQUEST_TOP_ALBUM_FINISHED";

  static REQUEST_TOP_ALBUM_FILTER = "TopAlbumAction.REQUEST_TOP_ALBUM_FILTER";
  static REQUEST_TOP_ALBUM_FILTER_FINISHED =
    "TopAlbumAction.REQUEST_TOP_ALBUM_FILTER_FINISHED";

  // METHODS
  static requestTopAlbum(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopAlbumAction.REQUEST_TOP_ALBUM,
        TopAlbumEffect.requestTopAlbum,
        params
      );
    };
  }

  static requestTopAlbumFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopAlbumAction.REQUEST_TOP_ALBUM_FILTER,
        TopAlbumEffect.requestTopAlbumFilter,
        params
      );
    };
  }
}
