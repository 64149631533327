import React, { useEffect, useRef, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MyRoute from "../Route/routes";
import { connect, useDispatch } from "react-redux";
import MyAuthRoute from "../Route/Authroute";
import routes from "../Route/routecoll";
import MenuNavLink from "./components/MenuNavLink";
import logo from "../logo/logo@3x.webp";
import "./MainNav.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import {
  Card,
  CardMedia,
  ClickAwayListener,
  Collapse,
  Grid,
  Grow,
  Paper,
  Popper,
  Switch,
  useMediaQuery,
} from "@mui/material";
import AuthAction from "../../stores/Auth/AuthAction";
import AvatarIcon from "./components/AvatarIcon";
// import { Grammarly } from "@grammarly/editor-sdk-react";
import { demoClientId } from "../grammerlyPluginId/grammerlyDemo";
import ErrorLogPopper from "./components/ErrorLogPopper";
import { ErrorLogs } from "../../middlewares/errorToastMiddleware";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import UserAction from "../../stores/user/UserAction";
import WelcomeScreen from "../../views/welcome/WelcomeScreen";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewReleaseDialog from "../../views/releases/NewReleaseDialog";
import ProfilePopper from "./components/ProfilePopper";
import NotificationBox from "../notificationPopOver/NotificationBox";
import environment from "environment";
// import { RssFeed } from "@material-ui/icons";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import NewReleases from "@mui/icons-material/NewReleases";
import poweredByImage from "../../Images/aiplex_logo.png";
import jwtDecode from "jwt-decode";
import NotificationAction from "../../stores/notifications/NotificationAction";
import NotificationDialog from "../../views/notification/NotificationDialog";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PagePermissionAction from "../../stores/pagePermission/PagePermissionAction";
// ***************************************************************************************

const drawerWidth = 220;
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    AuthAction.REQUEST_LOGIN,
    UserAction.REQUEST_USER_BY_ID,
    PagePermissionAction.REQUEST_PAGE_PERMISSION,
    NotificationAction.REQUEST_FILTER_NOTIFICATIONS,
  ]),
  auth: state.auth,
  pagePermission: state.pagePermission.pagePermission || [],
  userById: state.userById.userById || [],
  userprofile: state.userprofile.userprofile || [],
  NotificationsFilter: state.NotificationsFilter.NotificationsFilter || [],
});
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,

  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme, open }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: `calc(100% - 16px - calc(${theme.spacing(8)}))`,
  zIndex: theme.zIndex.drawer + 1,
  // boxShadow: theme.shadows,
  borderBottom: `11px solid ${theme.palette.background.primary}`,
  padding: 10,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    // margin Left: drawerWidth,
    width: `calc(100% - ${drawerWidth}px - 15px)`,
    // boxShadow: theme.shadows,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

// *****************************************************************************************************************************
function MiniDrawer(props) {
  const theme = useTheme();
  const { isRequesting } = props;

  const [notificationsData, setNotificationData] = useState({});
  const [openNotificationDialog, setOpenNotificationsDialog] = useState(false);

  const [openReleaseDialog, setOpenReleaseDialog] = useState(false);

  let pathname = window.location.pathname;
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();

  const [welcomeOpen, setWelcomeOpen] = React.useState(
    JSON.parse(localStorage.getItem("open"))
  );

  let selectedRow = null;

  const io = require("socket.io-client");
  const [IsUserLogin, setIsUserLogin] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [newNotificationsCount, setNewNotificationsCount] = useState(0);
  const [notificationReceived, setNotificationReceived] = useState(null);

  const socketRef = useRef(null);

  function fetchNotifications() {
    let params = {
      page_limit: "none",
    };

    // the below callback When the user login for the first time, the latest unread notification need to open
    let callback = (Response) => {
      Response?.hits &&
        Response.hits.length > 0 &&
        setNotificationData({
          id: Response.hits[0]._id,
          created_by: Response.hits[0]?._source.created_by,
          created_on: Response.hits[0]?._source.created_on,
          modified_by: Response.hits[0]?._source.modified_by,
          modified_on: Response.hits[0]?._source.modified_on,
          notification_image_url:
            Response.hits[0]?._source.notification_image_url,
          notification_msg: Response.hits[0]?._source.notification_msg,
          notification_msg_read_status:
            Response.hits[0]?._source.notification_msg_read_status,
          notification_sent: Response.hits[0]?._source.notification_sent,
          notification_title: Response.hits[0]?._source.notification_title,
          notification_type: Response.hits[0]?._source.notification_type,
        });

      // Response?.hits &&
      //   Response.hits.length > 0 &&
      //   Response.hits.filter(
      //     (item) => item._source.notification_msg_read_status === false
      //   ).length &&
      //   setNewNotificationsCount(
      //     Response.hits.filter(
      //       (item) => item._source.notification_msg_read_status === false
      //     ).length
      //   );

      Response?.hits &&
        Response.hits.length > 0 &&
        setOpenNotificationsDialog(true);
    };

    props.dispatch(
      NotificationAction.requestFilterNotifications(params, callback)
    );
  }

  // Socket service for notifications

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Disconnect the socket when the page is about to unload
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);

      // Cleanup function: Disconnect the socket when the component is unmounted
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
      }
    };
  }, [socketConnected]);

  useEffect(() => {
    if (IsUserLogin) fetchNotifications();

    if (IsUserLogin && socketConnected === false) {
      // alert("CONNECTING TO SOCKET");
      socketRef.current = io(`wss://${environment.api.socketApi}`, {
        transportOptions: {
          polling: {
            extraHeaders: {
              "user-id": jwtDecode(localStorage.getItem("Auth")).sub,
            },
          },
        },
        logger: false,
      });

      socketRef.current.on("connect", () => {
        // alert("Connected to Socket");
        setSocketConnected(true);
        localStorage.setItem("socket_id", socketRef.current.id);

        socketRef.current.on("message", (data) => {
          alert("Received message:", data);
        });
      });

      return () => {
        // Disconnect the socket when the component is re-rendered
        if (socketRef.current && socketConnected) {
          socketRef.current.disconnect();
          setSocketConnected(false);
        }
      };
    }
  }, [IsUserLogin]);

  useEffect(() => {
    if (socketRef.current !== null) {
      socketRef.current.on("connect", () => {
        // alert("Connected to Socket");
        setSocketConnected(true);

        socketRef.current.on("message", (data) => {
          alert("Received message:", data);
        });

        socketRef.current.on("disconnect", () => {});
      });
    }
  }, [socketRef.current]);

  useEffect(() => {
    if (socketConnected) {
      socketRef.current.on("send_notification", (data) => {
        setNotificationReceived(data);
      });

      socketRef.current.on("disconnect", () => {
        // alert("disconnected_from_server");
        setSocketConnected(false);
      });
    }
  }, [socketConnected]);

  if (!IsUserLogin) {
    if (localStorage.getItem("Auth") != null) {
      setIsUserLogin(true);
    } else {
      // Cleanup the socket when the user is not logged in
      if (socketRef.current && socketConnected) {
        socketRef.current.disconnect();
        setSocketConnected(false);
      }
    }
  }

  useEffect(() => {
    if (notificationReceived !== null) {
      setNotificationData({
        id: null,
        created_by: notificationReceived.created_by,
        created_on: notificationReceived.created_on,
        modified_by: notificationReceived.modified_by,
        modified_on: notificationReceived.modified_on,
        notification_image_url: notificationReceived.notification_image_url,
        notification_msg: notificationReceived.notification_msg,
        notification_msg_read_status:
          notificationReceived.notification_msg_read_status,
        notification_sent: notificationReceived.notification_sent,
        notification_title: notificationReceived.notification_title,
        notification_type: notificationReceived.notification_type,
      });

      setOpenNotificationsDialog(true);

      // alert(
      //   `${
      //     notificationReceived.notification_title &&
      //     notificationReceived.notification_title
      //   }\nDescription : ${
      //     notificationReceived.notification_msg &&
      //     notificationReceived.notification_msg
      //   }\n`
      // );
      setNotificationReceived((prev) => null);

      setNewNotificationsCount((prev) => {
        return prev + 1;
      });
    }
  }, [notificationReceived]);

  useEffect(() => {
    if (!isRequesting) {
      setNewNotificationsCount(
        props.NotificationsFilter.hits?.hits?.filter(
          (item) => item._source.notification_msg_read_status === false
        ).length
      );
    }
  }, [props.NotificationsFilter]);

  if (!IsUserLogin) {
    if (localStorage.getItem("Auth") != null) {
      setIsUserLogin(true);
    } else {
      socketRef.current && socketRef.current.close();
    }
  }

  // getting page_permission from local Storage
  let page_permission = localStorage.getItem("page_permission")
    ? [...JSON.parse(localStorage.getItem("page_permission"))]
    : [];

  page_permission = [...new Set(page_permission)];

  if (routes) {
    routes.data
      .filter((item) => item.path === pathname)
      .map((value) => (selectedRow = value.id));
  }

  useEffect((event) => {
    if (localStorage.getItem("open") !== null) {
      setWelcomeOpen(JSON.parse(localStorage.getItem("open")));
      if (localStorage.getItem("Auth") === null) {
        navigate("/");
      }
    } else {
      localStorage.setItem("open", true);
      setWelcomeOpen(JSON.parse(localStorage.getItem("open")));
    }

    if (localStorage.getItem("Auth") === null) {
      navigate("/", { state: { from: location.pathname } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [open, setOpen] = React.useState(true);

  // const [grammarlyActive, setGrammarlyActive] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // redirect to dashboard when you click on logo
  const logoClick = (item) => {
    navigate("/");
  };

  const returnMainMenu = (routes) => {
    let filtered_route_main = [];

    let filtered_route = [];

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission.length === 1 &&
      page_permission[0] === "*"
    ) {
      routes.data.forEach((col) => {
        if (col.category === "Main") {
          filtered_route_main.push(col);
        }
      });
    }

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission[0] !== "*"
    ) {
      filtered_route = routes.data.filter((col) => {
        page_permission.forEach((allowed_page_Id) => {
          if (col.category === "Main" && col.id === allowed_page_Id) {
            filtered_route_main.push(col);
          }
        });
      });
    }

    // routes.data.filter((col) => {
    //   if (col.category === "Main") {
    //     filtered_route_main.push(col);
    //   }
    // });

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <ListItem
              key={text.id + index}
              disablePadding
              selected={selectedRow === text.id}
              onClick={(event) => {
                localStorage.setItem("pathIndex", text.id + index);
              }}
              sx={{
                display: text.hide === true ? "none" : "block",
              }}
            >
              <MenuNavLink to={`${text.path}`}>
                <ListItemButton title={text.title}>
                  <ListItemIcon
                    sx={{
                      color: theme.palette.text.white,
                      minWidth: "36px",
                    }}
                  >
                    {text.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    sx={{
                      color: theme.palette.text.white,
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </MenuNavLink>
            </ListItem>
          );
        })
      : null;
  };

  const isScreenSmall = useMediaQuery("(max-width:1200px)");

  const [openMenu, setOpenMenu] = React.useState(false);
  const menuPopperRef = React.useRef(null);

  useEffect(() => {
    if (!isScreenSmall) {
      setOpenMenu(false);
    }
  }, [isScreenSmall]);

  const handleMenuPopper = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (menuPopperRef.current && menuPopperRef.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const topHeaderRef = useRef(null);
  const [headerHeight, setHeaderHeight] = useState(0);

  const onlineAlertRef = useRef(null);
  const [alertBoxHeight, setAlertBoxHeight] = useState(0);

  useEffect(() => {
    const topHeaderHeight =
      topHeaderRef.current && topHeaderRef.current.offsetHeight;
    setHeaderHeight(topHeaderHeight);

    const alertHeight =
      onlineAlertRef.current && onlineAlertRef.current.offsetHeight;
    setAlertBoxHeight(alertHeight);
  });

  const [openReleases, setOpenReleases] = React.useState(false);

  const handleReleasesSettings = () => {
    setOpenReleases(!openReleases);
  };

  let releases_menu_array = [];
  let releases_menu_array_main = [];

  if (
    page_permission &&
    page_permission.length !== 0 &&
    page_permission.length === 1 &&
    page_permission[0] === "*"
  ) {
    {
      releases_menu_array = routes.data.filter(
        (col) => col.category === "Release"
      );
      releases_menu_array[0] &&
        releases_menu_array_main.push(releases_menu_array[0]);
    }
  }

  if (
    page_permission &&
    page_permission.length !== 0 &&
    page_permission[0] !== "*"
  ) {
    page_permission &&
      page_permission.length > 0 &&
      page_permission.map((allowed_page_Id) => {
        releases_menu_array = routes.data.filter(
          (col) => col.category === "Release" && col.id === allowed_page_Id
        );
        releases_menu_array[0] &&
          releases_menu_array_main.push(releases_menu_array[0]);
      });
  }

  const returnReleasesMenu = (routes) => {
    let filtered_route_main = [];

    let filtered_route = [];

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission.length === 1 &&
      page_permission[0] === "*"
    ) {
      routes.data.forEach((col) => {
        if (col.category === "Release") {
          filtered_route_main.push(col);
        }
      });
    }

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission[0] !== "*"
    ) {
      filtered_route = routes.data.filter((col) => {
        page_permission.forEach((allowed_page_Id) => {
          if (col.category === "Release" && col.id === allowed_page_Id) {
            filtered_route_main.push(col);
          }
        });
      });
    }

    const actionFunction = () => {
      localStorage.getItem("id") && localStorage.removeItem("id");

      return filtered_route_main.filter((item) => {
        return item.id === "releases" || item.id === "delivered";
      });
    };

    let sliced_filtered_route_main =
      !localStorage.getItem("id") || window.location.pathname === "/releases"
        ? actionFunction()
        : filtered_route_main;

    return sliced_filtered_route_main.length > 0
      ? sliced_filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openReleases}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.text.white,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={text.title}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: theme.palette.text.white,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color: theme.palette.text.white,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  const [openRevenue, setOpenRevenue] = React.useState(false);

  const handleRevenueSettings = () => {
    setOpenRevenue(!openRevenue);
  };

  let revenue_menu_array = [];
  let revenue_menu_array_main = [];

  if (
    page_permission &&
    page_permission.length !== 0 &&
    page_permission.length === 1 &&
    page_permission[0] === "*"
  ) {
    {
      revenue_menu_array = routes.data.filter(
        (col) => col.category === "Revenue"
      );
      revenue_menu_array[0] &&
        revenue_menu_array_main.push(revenue_menu_array[0]);
    }
  }

  if (
    page_permission &&
    page_permission.length !== 0 &&
    page_permission[0] !== "*"
  ) {
    page_permission &&
      page_permission.length > 0 &&
      page_permission.map((allowed_page_Id) => {
        revenue_menu_array = routes.data.filter(
          (col) => col.category === "Revenue" && col.id === allowed_page_Id
        );
        revenue_menu_array[0] &&
          revenue_menu_array_main.push(revenue_menu_array[0]);
      });
  }

  const returnRevenueMenu = (routes) => {
    let filtered_route_main = [];

    let filtered_route = [];

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission.length === 1 &&
      page_permission[0] === "*"
    ) {
      routes.data.forEach((col) => {
        if (col.category === "Revenue") {
          filtered_route_main.push(col);
        }
      });
    }

    if (
      page_permission &&
      page_permission.length !== 0 &&
      page_permission[0] !== "*"
    ) {
      filtered_route = routes.data.filter((col) => {
        page_permission.forEach((allowed_page_Id) => {
          if (col.category === "Revenue" && col.id === allowed_page_Id) {
            filtered_route_main.push(col);
          }
        });
      });
    }

    return filtered_route_main.length > 0
      ? filtered_route_main.map((text, index) => {
          return (
            <Collapse
              in={!openRevenue}
              timeout="auto"
              unmountOnExit
              key={text.id + index}
            >
              <ListItem
                component="div"
                disablePadding
                sx={{
                  color: theme.palette.text.white,
                  display: text.hide === true ? "none" : "block",
                }}
                selected={selectedRow === text.id}
                onClick={(event) => {
                  localStorage.setItem("pathIndex", text.id + index);
                }}
              >
                <MenuNavLink to={`${text.path}`}>
                  <ListItemButton
                    title={text.title}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                        color: theme.palette.text.white,
                        minWidth: "30px",
                        marginRight: "12px",
                        marginLeft: "12px",
                      }}
                    >
                      {text.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.title}
                      sx={{
                        color: theme.palette.text.white,
                        opacity: open ? 1 : 0,
                      }}
                    />
                  </ListItemButton>
                </MenuNavLink>
              </ListItem>
            </Collapse>
          );
        })
      : null;
  };

  return (
    // **************************************************************
    <React.Fragment>
      <Box
        sx={{ display: "flex", height: "100vh" }}
        // className="bg-warning"
      >
        <CssBaseline />

        {props.auth.access_token === "" ||
        props.auth.access_token === undefined ? (
          <>
            <MyAuthRoute />
          </>
        ) : welcomeOpen && location.pathname === "/" ? (
          <WelcomeScreen
            open={welcomeOpen}
            onClose={() => {
              // setWelcomeOpen(false);
              localStorage.setItem("open", false);
            }}
          />
        ) : (
          <>
            <AppBar
              open={open}
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                backgroundColor: theme.palette.background.paper,
                scrollbarWidth: "none",
              }}
              ref={topHeaderRef}
            >
              <NotificationDialog
                open={openNotificationDialog}
                onClose={() => {
                  setOpenNotificationsDialog(false);
                }}
                notificationsData={notificationsData}
              />

              <Toolbar sx={{ height: "auto" }}>
                <Grid
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  justifyContent="space-between"
                  alignItems="center"
                  // p={0.5}
                >
                  <Grid item container xl={5} lg={5} md={5.5} sm={7} xs={6}>
                    <Grid
                      lg={12}
                      md={12}
                      sm={12}
                      xl={12}
                      container
                      // className="bg-warning"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Grid
                        // xl={5}
                        // lg={5}
                        // md={5}
                        // sm={5}
                        // xs={5}
                        marginLeft={"24px"}
                        // className="wrapper"
                      >
                        <Card>
                          <CardMedia
                            component="img"
                            sx={{
                              maxWidth: "120px",
                              maxHeight: "55px",
                              height: "auto",
                            }}
                            image={logo}
                            onClick={() => {
                              logoClick();
                            }}
                          />
                        </Card>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item container xl={7} lg={7} md={6.5} sm={5} xs={6}>
                    <Grid
                      item
                      container
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      // className="bg-light"
                    >
                      <Grid
                        container
                        item
                        lg={12}
                        xl={12}
                        sm={12}
                        sx={{
                          display: {
                            lg: "flex",
                            xl: "flex",
                            laptop: "none",
                            sm: "none",
                          },
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                        // className="bg-warning"
                      >
                        <Grid
                          // xl={0.5}
                          // lg={0.5}
                          // md={0.5}
                          // sm={0.5}
                          // xs={0.5}
                          // className="bg-info"

                          mr={2}
                          component="button"
                          sx={{
                            backgroundColor: "transparent",
                            textAlign: "inherit",
                            border: "none",
                            color: theme.palette.appbar.icons,
                          }}
                          // className="bg-dark"
                        >
                          <NotificationBox
                            count={newNotificationsCount}
                            setNewNotificationsCount={setNewNotificationsCount}
                            fetchNotifications={fetchNotifications}
                            sx={{
                              width: theme.typography.appIcon.width,
                              height: theme.typography.appIcon.width,
                            }}
                          />
                        </Grid>

                        <Grid
                          sx={{
                            borderRight: 1,
                            height: "45px",
                            borderRightColor:
                              theme.palette.primary.contrastText,
                          }}
                        ></Grid>

                        <Grid
                          paddingX={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {/* <ErrorLogPopper errorLogs={ErrorLogs}> */}
                          <Box>
                            <Typography
                              sx={{
                                display: "flex",
                                justifyContent: "end",
                                alignItems: "center",
                                lineHeight: 1,
                              }}
                              component="div"
                              fontFamily={theme.typography.fontFamily}
                              fontSize={{
                                xl: "24px",
                                lg: "18px",
                                md: "12px",
                                sm: "11px",
                                xs: "10px",
                              }}
                              // width="100%"
                              fontWeight={theme.typography.fontWeightRegular}
                              color={theme.palette.primary.main}
                              // className="bg-warning"
                            >
                              {routes.data.map((item) => {
                                if (item.path === pathname) {
                                  let text = item.title;
                                  const myArray = text && text.split(" ");
                                  return (
                                    <>
                                      {myArray &&
                                        myArray.map((item) => (
                                          <div>
                                            <p className="dashboard-title">
                                              {item}
                                            </p>
                                          </div>
                                        ))}
                                    </>
                                  );
                                }
                              })}
                            </Typography>{" "}
                            <Typography
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                lineHeight: 1,
                              }}
                              component="div"
                              fontFamily={theme.typography.fontFamily}
                              fontSize={{
                                xl: "12px",
                                lg: "10px",
                                md: "11px",
                                sm: "10px",
                                xs: "9px",
                              }}
                              // width="100%"
                              fontWeight={theme.typography.fontWeightRegular}
                              color={theme.palette.primary.main}
                              // className="bg-warning"
                            >
                              {props.userprofile && props.userprofile._source
                                ? props.userprofile._source.client_name
                                : ""}
                            </Typography>
                          </Box>
                          {/* </ErrorLogPopper> */}
                        </Grid>

                        <Grid
                          sx={{
                            borderRight: 1,
                            height: "45px",
                            borderRightColor:
                              theme.palette.primary.contrastText,
                          }}
                        ></Grid>

                        <Grid
                          // xl={1}
                          // lg={1}
                          // md={0.5}
                          // sm={1}
                          // xs={1}
                          paddingRight={1}
                          paddingLeft={2}
                          // className="bg-dark"
                          display="flex"
                          justifyContent="center"
                          alignSelf="center"
                        >
                          <ProfilePopper>
                            <Typography>
                              <AvatarIcon />
                            </Typography>
                          </ProfilePopper>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        item
                        lg={12}
                        xl={12}
                        sm={12}
                        sx={{
                          display: {
                            lg: "none",
                            xl: "none",

                            laptop: "flex",
                            sm: "flex",
                          },
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                        // className="bg-warning"
                      >
                        <Typography
                          component={"div"}
                          ref={menuPopperRef}
                          id="composition-button"
                          aria-controls={
                            openMenu ? "composition-menu" : undefined
                          }
                          aria-expanded={openMenu ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleMenuPopper}
                          style={{ backgroundColor: "none" }}
                        >
                          <IconButton>
                            <MenuIcon />
                          </IconButton>
                        </Typography>

                        <Popper
                          open={openMenu}
                          anchorEl={menuPopperRef.current}
                          role={undefined}
                          placement="bottom-start"
                          transition
                          disablePortal
                          sx={{ zIndex: 100 }}
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === "bottom-start"
                                    ? "left top"
                                    : "left bottom",
                              }}
                            >
                              <Paper style={{ width: 250 }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                  <div
                                    autoFocusItem={open}
                                    id="composition-menu"
                                    aria-labelledby="composition-button"
                                  >
                                    <Grid
                                      container
                                      lg={12}
                                      md={12}
                                      sm={12}
                                      p={2}
                                      sx={{ boxShadow: `${theme.shadows}` }}
                                    >
                                      <Grid
                                        item
                                        lg={9}
                                        md={9}
                                        sm={9}
                                        paddingX={2}
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                            alignItems: "center",
                                          }}
                                          component="div"
                                          fontFamily={
                                            theme.typography.fontFamily
                                          }
                                          // fontSize={{
                                          //   xl: "24px",
                                          //   lg: "18px",
                                          //   md: "12px",
                                          //   sm: "11px",
                                          //   xs: "10px",
                                          // }}
                                          fontSize={"22px"}
                                          // width="100%"
                                          fontWeight={
                                            theme.typography.fontWeightRegular
                                          }
                                          color={theme.palette.text.white}
                                        >
                                          {routes.data.map((item) => {
                                            if (item.path === pathname) {
                                              let text = item.title;
                                              const myArray = text.split(" ");
                                              return (
                                                <>
                                                  {myArray.map((item) => (
                                                    <div>
                                                      <p className="dashboard-title">
                                                        {item}
                                                      </p>
                                                    </div>
                                                  ))}
                                                </>
                                              );
                                            }
                                          })}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>

                        <Grid
                          item
                          // lg={3}
                          // md={3}
                          // sm={3}
                          // paddingRight={1}
                          // paddingLeft={2}
                          // className="bg-dark"
                          // display="flex"
                          // justifyContent="center"
                          // alignSelf="center"
                        >
                          <Grid p={1}>
                            <ProfilePopper>
                              <div>
                                <AvatarIcon />
                              </div>
                            </ProfilePopper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>
            <Drawer
              variant="permanent"
              open={open}
              hideBackdrop={true}
              sx={{
                "& .MuiDrawer-paper": { border: "none" },
                "& .MuiPaper-root": {
                  backgroundColor: theme.palette.background.primary,
                },
                "& .MuiTypography-root": {
                  fontWeight: theme.typography.fontWeightBold,
                },
              }}
            >
              <DrawerHeader
                sx={{
                  ...(!open && {
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: 1,
                  }),
                }}
              >
                <IconButton
                  color="light"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    ...(open && { display: "none" }),
                    color: "#ffffff",
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  onClick={handleDrawerClose}
                  sx={{ ...(!open && { display: "none" }), color: "#ffffff" }}
                >
                  {theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )}
                </IconButton>
              </DrawerHeader>

              <List
                sx={{
                  // height: { lg: "90vh", xs: "80vh" },
                  // display: "flex",
                  // justifyContent: "space-between",
                  // flexDirection: "column",
                  // selected and (selected + hover) states
                  "&& .Mui-selected, && .Mui-selected:hover": {
                    bgcolor: theme.palette.background.drawerMenu,
                  },
                  // "&& .MuiListItem-root:hover": {
                  //   bgcolor: theme.palette.background.drawerMenu,
                  // },
                }}
                className="scrollable"
              >
                {/* main */}
                <ListItem
                  key={"newRelease"}
                  disablePadding
                  onClick={(event) => {
                    localStorage.setItem("pathIndex", "newRelease");
                    setOpenReleaseDialog(true);
                  }}
                >
                  <MenuNavLink>
                    <ListItemButton title={"New Release"}>
                      <ListItemIcon
                        sx={{
                          color: theme.palette.text.white,
                          minWidth: "36px",
                        }}
                      >
                        <FontAwesomeIcon icon={faFolderOpen}></FontAwesomeIcon>
                      </ListItemIcon>
                      <ListItemText
                        primary={"New Release"}
                        sx={{
                          color: theme.palette.text.white,
                          opacity: open ? 1 : 0,
                        }}
                      />
                    </ListItemButton>
                  </MenuNavLink>
                </ListItem>

                {returnMainMenu(routes)}

                {/* releases */}

                <ListItem disablePadding sx={{ display: "block" }}>
                  {releases_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={"Releases"}
                      onClick={handleReleasesSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.text.white,
                          minWidth: "36px",
                        }}
                      >
                        <NewReleases />
                      </ListItemIcon>
                      <ListItemText
                        primary="Releases"
                        sx={{
                          color: theme.palette.text.white,

                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openReleases ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openReleases ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnReleasesMenu(routes)}
                </ListItem>

                {/* REVENUE PAGES MENU */}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {revenue_menu_array_main.length > 0 && (
                    <ListItemButton
                      title={"Revenue"}
                      onClick={handleRevenueSettings}
                    >
                      <ListItemIcon
                        sx={{
                          color: theme.palette.text.white,
                          minWidth: "36px",
                        }}
                      >
                        <MonetizationOnIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary="Revenue"
                        sx={{
                          color: theme.palette.text.white,

                          opacity: open ? 1 : 0,
                        }}
                      />
                      <IconButton
                        sx={{
                          ...(open && { display: "none" }),
                        }}
                      >
                        {openRevenue ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                      <IconButton sx={{ ...(!open && { display: "none" }) }}>
                        {openRevenue ? <ExpandMore /> : <ExpandLess />}
                      </IconButton>
                    </ListItemButton>
                  )}
                  {returnRevenueMenu(routes)}
                </ListItem>

                {/********************************Logout page************************************/}
                <ListItem disablePadding sx={{ display: "block" }}>
                  {routes.data
                    .filter((col) => col.category === "Logout")
                    .map((text, index) => (
                      <ListItem
                        key={text.id + index}
                        disablePadding
                        sx={{
                          display: text.hide === true ? "none" : "block",
                        }}
                      >
                        <MenuNavLink to={`${text.path}`}>
                          <ListItemButton title={text.title}>
                            <ListItemIcon
                              sx={{
                                color: theme.palette.text.white,
                                minWidth: "36px",
                              }}
                            >
                              {text.icon}
                            </ListItemIcon>
                            <ListItemText
                              primary={text.title}
                              sx={{
                                color: theme.palette.text.white,
                                opacity: open ? 1 : 0,
                              }}
                            />
                          </ListItemButton>
                        </MenuNavLink>
                      </ListItem>
                    ))}
                </ListItem>
              </List>
              {open && (
                <Box
                  sx={{
                    mt: "auto",
                    paddingX: 2,
                    paddingY: 1,
                    bottom: 0,
                    backgroundColor: theme.palette.background.primary,
                  }}
                >
                  <Typography mb={1} style={{ color: "white" }}>
                    Powered By
                  </Typography>

                  <img
                    src={poweredByImage}
                    alt=""
                    onClick={() => {
                      window.open("https://www.aiplex.com");
                    }}
                    style={{ height: 27, width: 105, cursor: "pointer" }}
                  />
                </Box>
              )}
            </Drawer>

            <Box
              component="main"
              sx={{
                flexGrow: 1,
                pl: 2,
                // position: "relative",
                // marginTop: 10,
              }}
            >
              <NewReleaseDialog
                open={openReleaseDialog}
                onClose={() => {
                  setOpenReleaseDialog(false);
                }}
              />
              <Box height={headerHeight}>
                <DrawerHeader />
              </Box>
              <Box
                sx={{
                  display: navigator.onLine ? "none" : "block",
                }}
              >
                <Alert
                  severity="error"
                  hidden={navigator.onLine}
                  ref={onlineAlertRef}
                >
                  <AlertTitle>
                    <strong>No Internet!</strong>
                  </AlertTitle>
                  Please make sure you are connected to an internet.
                </Alert>
              </Box>

              <Box height={`calc(100vh - ${headerHeight + alertBoxHeight}px )`}>
                {/* <Grammarly clientId={grammarlyActive ? demoClientId : null}> */}
                {props.pagePermission?.allowed_pages?.length === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                      padding: "20px",
                    }}
                  >
                    <h1> Label access not given</h1>
                    <h2>
                      Please contact your client "
                      {props.userprofile?._source?.created_by?.user_name}" to
                      access the information.
                    </h2>
                  </Box>
                )}
                <MyRoute />
                {/* </Grammarly> */}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(MiniDrawer);
