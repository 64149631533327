import BaseReducer from "../../utilities/BaseReducer";
import BlogsAction from "./BlogsAction";

export default class BlogsFilterReducer extends BaseReducer {
  //initial state of BlogsFilterReducer
  initialState = {
    blogsFilter: [],
  };
  //BlogsFilterReducer request action finish
  [BlogsAction.REQUEST_BLOGS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      blogsFilter: action.payload,
    };
  }
}
