import React, { useEffect, useState, useCallback, useMemo } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import {
  DialogActions,
  Grid,
  IconButton,
  Typography,
  useTheme,
  TextField,
  OutlinedInput,
  MenuItem,
  Select,
  ListItem,
  Avatar,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndustry, faTrash } from "@fortawesome/free-solid-svg-icons";
import AlbumIcon from "@mui/icons-material/Album";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PublicIcon from "@mui/icons-material/Public";
import DialogContent from "@mui/material/DialogContent";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DialogTitle from "@mui/material/DialogTitle";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiButton from "../../components/muiButton/MuiButton";
import PostPlusButton from "../../components/postPlusButton/PostPlusButton";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";

import GenreAction from "../../stores/genre/GenreAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import SearchIcon from "@mui/icons-material/Search";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import DatePicker from "../../components/datePicker/DateRangePicker";
import { format } from "date-fns";

import MuiTextField from "../../components/muiTextField/MuiTextField";

import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import {
  inputProps,
  menuProps,
} from "../../components/releaseFormComponents/reusedComponents/ReusedComponents";
import environment from "environment";
import ImageIcon from "@mui/icons-material/Image";

import AddTaskIcon from "@mui/icons-material/AddTask";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RaiseTicketPopper from "../../components/poppers/RaiseTicketPopper";
import RaiseIssueDialog from "../releases/RaiseIssueDialog";
import Rettrive from "../releases/Rettrive";
import NewReleaseDialog from "../releases/NewReleaseDialog";
// import TerritoryDailog from "../releases/TerritoryDailog";
import DeliveredReleaseMorePopper from "./DeliveredReleaseMorePopper";
import LabelAction from "../../stores/label/LabelAction";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    ReleasesAction.REQUEST_FINAL_RELEASES,
    GenreAction.REQUEST_GENRE,
    ReleasesAction.REQUEST_RELEASE_FILTER,
    LabelAction.REQUEST_LABELS_FILTER,
  ]),
  finalReleases: state.finalReleases.finalReleases || [],
  releaseFilter: state.releaseFilter.releaseFilter || [],
  genre: state.genre.genre || [],
  pagination: state.finalReleases.finalReleases || [],
  labelFilter: state.labelFilter.labelFilter || [],
});

function DeliveredReleases(props) {
  const theme = useTheme();
  const { isRequesting } = props;
  const navigate = useNavigate();

  const [editRowsModel, setEditRowsModel] = useState({});
  const [page_limit] = useState(15);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [scroll, setScroll] = React.useState("body");

  const [FilterClicked, setFilterClicked] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);

  // const [openTerritoryDialog, setOpenTerritoryyDialog] = React.useState(false);
  const [territorySelected, setTerritorySelected] = React.useState(null);
  const [releaseDataIssue, setReleaseDataIssue] = useState(null);
  const [openIssueRaiseDialog, setOpenIssueRaiseDialog] = useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  // const handleCategoryClose = () => {
  //   setOpenTerritoryyDialog(false);
  // };

  const [pageNumber, setPageNumber] = useState(() => {
    if (localStorage.getItem("DeliveredFilters")) {
      return JSON.parse(localStorage.getItem("DeliveredFilters")).pageNumber;
    } else {
      return 0;
    }
  });

  const [title, setTitle] = useState(() => {
    if (localStorage.getItem("DeliveredFilters")) {
      return JSON.parse(localStorage.getItem("DeliveredFilters")).title;
    } else {
      return null;
    }
  });

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("DeliveredFilters"));
    if (filters) {
      filters.title = null;
      filters.searchLabel = null;
      // filters.status = null;
      filters.pageNumber = `0`;
      filters.releaseType = null;
      filters.type = null;
      localStorage.setItem("DeliveredFilters", JSON.stringify(filters));
    }
  }, []);

  const [searchLabel, setSearchLabel] = useState(() => {
    if (localStorage.getItem("DeliveredFilters")) {
      return JSON.parse(localStorage.getItem("DeliveredFilters")).searchLabel;
    } else {
      return null;
    }
  });

  // const [status, setStatus] = useState(() => {
  //   if (localStorage.getItem("DeliveredFilters")) {
  //     return JSON.parse(localStorage.getItem("DeliveredFilters")).status;
  //   } else {
  //     return null;
  //   }
  // });

  const [releaseType, setReleaseType] = useState(() => {
    if (localStorage.getItem("DeliveredFilters")) {
      return JSON.parse(localStorage.getItem("DeliveredFilters")).releaseType;
    } else {
      return null;
    }
  });

  const [createdDate, setCreatedDate] = useState(() => {
    if (localStorage.getItem("DeliveredFilters")) {
      return [
        JSON.parse(localStorage.getItem("DeliveredFilters")).createdDate &&
        JSON.parse(localStorage.getItem("DeliveredFilters")).createdDate[0]
          ? JSON.parse(localStorage.getItem("DeliveredFilters")).createdDate[0]
          : null,
        JSON.parse(localStorage.getItem("DeliveredFilters")).createdDate &&
        JSON.parse(localStorage.getItem("DeliveredFilters")).createdDate[1]
          ? JSON.parse(localStorage.getItem("DeliveredFilters")).createdDate[1]
          : null,
      ];
    } else {
      return [null, null];
    }
  });

  useEffect(() => {
    let filters = JSON.parse(localStorage.getItem("DeliveredFilters"));
    if (filters) {
      filters.title && setTitle(filters.title);
      filters.searchLabel && setSearchLabel(filters.searchLabel);
      // filters.status && setStatus(filters.status);
      filters.releaseType && setReleaseType(filters.releaseType);
      filters.type && setType(filters.type);
      filters.createdDate && setCreatedDate(filters.createdDate);
      filters.pageNumber && setPageNumber(filters.pageNumber);
    }
  }, []);

  useEffect(() => {
    const filter = [`["status", "must", "", "terms", [8]]`];
    if (title !== "" && type === "Title") {
      filter.push(`["title.keyword","must","","wildcard","*${title}*"]`);
    }

    if (title !== "" && type === "UPC") {
      filter.push(`["UPC_EAN.keyword","must","","wildcard","*${title}*"]`);
    }

    if (title !== "" && type === "ISRC") {
      filter.push(`["isrc.keyword","must","","wildcard","*${title}*"]`);
    }

    if (searchLabel !== null) {
      filter.push(`["label.keyword","must","","match","${searchLabel.id}"]`);
    }

    // if (status !== null) {
    //   filter.push(`["status.keyword","must","","match","${status.id}"]`);
    // }

    if (releaseType !== null) {
      filter.push(
        `["type_of_release.keyword","must","","match","${releaseType.toLowerCase()}"]`
      );
    }

    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }

    const filterparam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "delivered",
    };

    if (
      title !== "" ||
      searchLabel !== null ||
      // status !== null ||
      releaseType !== null ||
      type !== null ||
      (createdDate[0] !== null && createdDate[1] !== null)
    ) {
      let params = {
        page_limit: `${page_limit}`,
        page_number: pageNumber > 0 ? pageNumber - 1 : 0,
        order_by: '[["modified_on", "desc"]]',
        current_page: "delivered",
        // the below line is to filter the final releases which are in delivered status
        filters: `[${filter}]`,
      };

      fetchFinalReleases(params);
      fetchGenre();
      // let label_filter = [`["status.keyword","must","","term","activated"]`];

      const label_param = {
        // filters: `[${label_filter}]`,
        page_limit: "none",
        order_by: `[["created_on","desc"]]`,
        current_page: "delivered",
      };
      fetchLabelFilter(label_param);
      localStorage.setItem(
        "DeliveredFilters",
        JSON.stringify({
          title,
          searchLabel,
          // status,
          releaseType,
          createdDate,
          type,
          pageNumber: pageNumber,
        })
      );
    }
  }, []);

  function fetchFinalReleases(params) {
    props.dispatch(ReleasesAction.requestFinalReleases(params));
  }

  function fetchLabelFilter(param) {
    props.dispatch(LabelAction.requestLabelFilter(param));
  }
  function fetchGenre() {
    const param = {
      page_limit: "none",
      order_by: `[["genre_name.keyword", "asc"]]`,
      current_page: "delivered",
    };
    props.dispatch(GenreAction.requestGenre(param));
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const genreList = [];
  if (props.genre && props.genre.hits) {
    props.genre.hits.hits &&
      props.genre.hits.hits.length > 0 &&
      props.genre.hits.hits.map((genre) => {
        genreList.push({
          genre_id: genre._id,
          genre_name: genre._source.genre_name,
        });
      });
  }

  let labelList = [];

  props?.labelFilter?.hits?.hits?.map((item) => {
    if (item?._source?.label_name)
      labelList.push({
        id: item?._id,
        label_name: item._source.label_name,
      });
  });
  labelList.sort((a, b) => a.label_name.localeCompare(b.label_name));
  function isObjectEqual(obj1, obj2) {
    return obj1.id === obj2.id;
  }

  labelList = labelList.filter(
    (obj, index, self) => index === self.findIndex((o) => isObjectEqual(o, obj))
  );
  // labelList.sort((a, b) => b - a);

  // let statusList = [
  //   { id: 7, status_name: "Pending For Review" },
  //   { id: 8, status_name: "Delivered" },
  // ];

  // props?.releaseFilter?.hits?.hits?.map((item) => {
  //   if (item?._source?.status?.status_name)
  //     statusList.push({
  //       id: item?._source?.status?.id,
  //       status_name: item._source.status.status_name,
  //     });
  // });

  // statusList = statusList.filter(
  //   (obj, index, self) => index === self.findIndex((o) => isObjectEqual(o, obj))
  // );

  // let artistArray = [];

  // if (props.artistFilter.hits) {
  //   props.artistFilter.hits.hits.forEach((value) => {
  //     artistArray.push({
  //       artist_id: value._id,
  //       artist_name: value._source.artist_name,
  //     });
  //   });
  // }

  const handleSearchChange = (event) => {
    setTitle(event.target.value);
  };

  const labelListHandleChange = (event, searchLabel) => {
    if (searchLabel != null) {
      setSearchLabel(searchLabel);
    } else {
      setSearchLabel(null);
    }
  };

  // const statusListHandleChange = (event, status) => {
  //   if (status !== null) {
  //     setStatus(status);
  //   } else {
  //     setStatus(null);
  //   }
  // };

  const releaseTypeHandleChange = (event, type) => {
    if (type !== null) {
      setReleaseType(type);
    } else {
      setReleaseType(null);
    }
  };

  const [artist, setArtist] = useState([]);

  const artistListHandleChange = (event, value) => {
    if (value !== null) {
      setArtist(value);
    } else {
      setArtist(null);
    }
  };

  const isPastDate = (date) => {
    const now = new Date();
    return date <= now;
  };

  function CustomDateFilter() {
    let filterString = ``;

    if (createdDate[1].$d || createdDate[0].$d)
      filterString = `["main_release_date","must","lte","range","${format(
        createdDate[1].$d,
        "yyyy-MM-dd"
      )}"],["main_release_date","must","gte","range","${format(
        createdDate[0].$d,
        "yyyy-MM-dd"
      )}"]`;
    else {
      filterString = `["main_release_date","must","lte","range","${new Date(
        createdDate[1]
      )
        .toISOString()
        .slice(0, 10)}"],["main_release_date","must","gte","range","${new Date(
        createdDate[0]
      )
        .toISOString()
        .slice(0, 10)}"]`;
    }

    return filterString;
  }

  const releaseTypeList = ["audio", "video", "ringtone"];

  // useEffect(() => {
  //   if (status) {
  //     setSearchLabel(null);
  //     setReleaseType(null);
  //     setCreatedDate([null, null]);
  //     setTitle("");
  //     setArtist([]);
  //   }
  // }, [status]);

  useEffect(() => {
    if (searchLabel) {
      // setStatus(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setTitle("");
      setArtist([]);
    }
  }, [searchLabel]);

  useEffect(() => {
    if (releaseType) {
      setSearchLabel(null);
      // setStatus(null);
      setCreatedDate([null, null]);
      setTitle("");
      setArtist([]);
    }
  }, [releaseType]);

  useEffect(() => {
    if (createdDate[0] || createdDate[1]) {
      setSearchLabel(null);
      // setStatus(null);
      setReleaseType(null);
      setTitle("");
      setArtist([]);
    }
  }, [createdDate]);

  useEffect(() => {
    if (title) {
      setSearchLabel(null);
      // setStatus(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      setArtist([]);
    }
  }, [title]);

  function applyFilter() {
    const filter = [`["status", "must", "", "terms", [8]]`];

    if (title !== "" && type === "Title") {
      filter.push(`["title.keyword","must","","wildcard","*${title}*"]`);
    }

    if (title !== "" && type === "UPC") {
      filter.push(`["UPC_EAN.keyword","must","","wildcard","*${title}*"]`);
    }

    if (title !== "" && type === "ISRC") {
      filter.push(`["isrc.keyword","must","","wildcard","*${title}*"]`);
    }

    if (searchLabel !== null) {
      filter.push(`["label.keyword","must","","match","${searchLabel.id}"]`);
    }

    if (artist && artist.length !== 0) {
      filter.push(`["artist.keyword","must","","match","${artist}"]`);
    }

    // if (status !== null) {
    //   filter.push(`["status.keyword","must","","match","${status.id}"]`);
    // } else {
    //   filter.push(`["status","must","","terms",[7 , 8]]`);
    // }

    if (releaseType !== null) {
      filter.push(
        `["type_of_release.keyword","must","","match","${releaseType.toLowerCase()}"]`
      );
    }

    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }

    if (
      (title && title !== "") ||
      searchLabel !== null ||
      // status !== null ||
      releaseType !== null ||
      (createdDate[0] !== null && createdDate[1] !== null) ||
      (artist && artist.length !== 0)
    ) {
      const params = {
        filters: `[${filter}]`,
        page_number: 0,
        page_limit: `${page_limit}`,
        current_page: "delivered",
      };
      fetchFinalReleases(params);
      setFilterLoader(true);
      setClearLoader(false);
      setFilterClicked(true);
      localStorage.setItem(
        "DeliveredFilters",
        JSON.stringify({
          title,
          searchLabel,
          // status,
          releaseType,
          createdDate,
          type,
          pageNumber: pageNumber,
        })
      );
    } else {
      alert("Please Add Filters");
      setClearLoader(false);
    }
  }

  const ClearState = () => {
    setType("");
    // Clear the state
    if (
      title !== "" ||
      searchLabel !== null ||
      // status !== null ||
      releaseType !== null ||
      (createdDate[0] !== null && createdDate[1] !== null)
    ) {
      setClearLoader(true);
      setFilterLoader(false);
      setTitle("");
      setSearchLabel(null);
      // setStatus(null);
      setReleaseType(null);
      setCreatedDate([null, null]);
      localStorage.removeItem(
        "DeliveredFilters",
        JSON.stringify({
          title,
          searchLabel,
          // status,
          releaseType,
          createdDate,
          type,
          pageNumber: pageNumber,
        })
      );
    }
    setFilterClicked(false);
    const params = {
      order_by: '[["created_on", "desc"]]',
      page_limit: page_limit,
      current_page: "delivered",
      filters: `[["status","must","","terms",[8]]]`,
    };
    fetchFinalReleases(params);
  };

  const dateValueHandleChange = (date) => {
    setCreatedDate(date);
  };

  function formatDate(inputDate) {
    if (inputDate) {
      const date = new Date(inputDate);
      const formattedDate = date.toLocaleDateString("en-GB");
      return formattedDate;
    } else {
      return "";
    }
  }

  //Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 130,
    },

    {
      width: 250,
      flex: 2,
      field: "title",
      headerName: "Title / Artist",
      renderCell: (params) => {
        // console.log("check==>", Rettrive(params?.row?.thumbnail_url, params));

        return (
          <ListItem className="p-0 m-0">
            <div>
              <Box
                className="image-container"
                style={{
                  borderRadius: 5,

                  display: "grid",
                  placeItems: "center",
                  overflow: "hidden",
                }}
              >
                {/* {params?.row?.thumbnail_url ? ( */}
                <Rettrive
                  thumbnail_url={params?.row?.thumbnail_url}
                  params={params}
                  current_page={"delivered"}
                />
                {/* ) : (
                  <ImageIcon
                    style={{
                      width: "100%",
                      height: "100%",
                      color: theme.palette.primary.light,
                    }}
                  />
                )} */}
              </Box>
            </div>

            <div
              style={{
                marginLeft: 5,
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
              }}
              // className="bg-warning"
            >
              <div
                style={{ width: "60%" }}
                // className="bg-success"
              >
                <span>{params?.row?.title}</span>
                <br />
                <span style={{ color: "#bebec0" }}>
                  {params?.row?.artists?.length ? "By " : ""}
                  {params?.row?.artists?.map((item, index) => (
                    <span key={index}>
                      {item.artist_id.artist_name}
                      {index !== params?.row?.artists?.length - 1 && ", "}
                    </span>
                  ))}
                </span>
              </div>
            </div>
          </ListItem>
        );
      },
    },
    {
      width: 130,
      flex: 1,
      field: "status",
      headerName: "Status",
      cellClassName: "status",
    },
    {
      width: 80,
      flex: 1,
      field: "type_of_release",
      headerName: "Type",
      renderCell: (params) => {
        return params.row.type_of_release ? params.row.type_of_release : "";
      },
    },

    {
      width: 130,
      flex: 1,
      field: "thumbnail_url",
      headerName: "Thumbnail",
      hide: true,
    },

    {
      width: 130,
      flex: 1,
      field: "artists",
      headerName: "Artists",
      hide: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.artists &&
              params.row.artists.length > 0 &&
              params.row.artists.map((artist) => (
                <>
                  <span>{artist.artist_id.artist_name}</span>
                  <br />
                </>
              ))}
          </div>
        );
      },
    },

    {
      width: 130,
      flex: 1,
      field: "label",
      headerName: "Label",
      renderCell: (params) => {
        return params.row.label && params.row.label.label_name
          ? params.row.label.label_name
          : "";
      },
    },
    {
      width: 200,
      flex: 1,
      field: "created_on",
      headerName: "Created On",
      renderCell: (params) => {
        return formatDate(params.row.created_on);
      },
    },
    {
      width: 200,
      flex: 1,
      field: "main_release_date",
      headerName: "Release Date",
      renderCell: (params) => {
        return formatDate(params.row.main_release_date);
      },
    },
    { width: 130, flex: 1, field: "no_of_tracks", headerName: "No of Track" },
    { width: 130, flex: 1, field: "UPC_EAN", headerName: "UPC" },

    // {
    //   field: "deliveredTerritories",
    //   headerName: "Delivered Territories & Stores",
    //   resizable: false,
    //   hide: true,
    //   width: 200,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <div
    //           style={{
    //             display: "flex",
    //             flexDirection: "column",
    //             width: "100%",
    //           }}
    //         >
    //           <Button
    //             variant="outlined"
    //             onClick={() => {
    //               setOpenTerritoryyDialog(true);
    //               setTerritorySelected(params.row.territory);
    //             }}
    //             style={{
    //               padding: 2,
    //               display: "flex",
    //               borderColor: theme.palette.common.grey,
    //               justifyContent: "space-between",
    //             }}
    //           >
    //             <PublicIcon
    //               size="small"
    //               sx={{ color: theme.palette.common.grey }}
    //             />
    //             <Typography>
    //               {params.row.territory ? params.row.territory.length : 0}{" "}
    //               terrs.
    //             </Typography>
    //           </Button>

    //           {/* <Button
    //             variant="outlined"
    //             onClick={handleClickOpen("body")}
    //             style={{
    //               height: "18px",
    //               position: "relative",
    //             }}
    //           >
    //             <IconButton
    //               title="Globe"
    //               sx={{
    //                 border: "0",
    //                 position: "absolute",
    //                 left: 0,
    //               }}
    //             >
    //               <PublicIcon
    //                 color="active"
    //                 style={{ height: "20px", width: "20px" }}
    //               />
    //             </IconButton>
    //             <span style={{ marginLeft: "25px" }}>240 terrs.</span>
    //           </Button> */}
    //           {/* <Dialog open={open} onClose={handleClose} scroll={scroll}>
    //             <DialogTitle id="scroll-dialog-title">Territories</DialogTitle>
    //             <DialogContent dividers={scroll === "body"}></DialogContent>
    //           </Dialog> */}
    //         </div>
    //       </>
    //     );
    //   },
    // },

    {
      width: 50,
      field: "issues",
      type: "actions",
      headerName: (
        <div title="Add Task">
          <AddTaskIcon
            style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
          />
        </div>
      ),
      //
      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => (
        <RaiseTicketPopper
          issueTypes={issueTypes}
          onClick={(issueType) => {
            setOpenIssueRaiseDialog(true);
            setReleaseDataIssue({ releaseData: params.row, type: issueType });
          }}
        >
          <div title="Add Task">
            <AddTaskIcon
              style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
            />
          </div>
        </RaiseTicketPopper>
      ),
    },

    {
      width: 50,
      field: "more",
      type: "actions",
      headerName: (
        <MoreVertIcon
          style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
        />
      ),
      //
      // width: 160,
      cellClassName: "actions",
      renderCell: (params) => (
        <DeliveredReleaseMorePopper
          options={["View Details"]}
          releaseId={params.row.id}
        >
          <MoreVertIcon
            style={{ color: "#2a5e6c", height: "20px", width: "20px" }}
          />
        </DeliveredReleaseMorePopper>
      ),
    },
  ];

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    localStorage.setItem(
      "DeliveredFilters",
      JSON.stringify({
        title,
        searchLabel,
        // status,
        releaseType,
        createdDate,
        type,
        pageNumber: value,
      })
    );

    // setPageNumber(value);

    setFilterLoader(false);
    setClearLoader(false);

    const filter = [`["status", "must", "", "terms", [8]]`];
    let params = {};

    if (title !== "" && type === "Title") {
      filter.push(`["title.keyword","must","","wildcard","*${title}*"]`);
    }

    if (title !== "" && type === "UPC") {
      filter.push(`["UPC_EAN.keyword","must","","term","${title}"]`);
    }

    if (title !== "" && type === "ISRC") {
      filter.push(`["isrc.keyword","must","","wildcard","*${title}*"]`);
    }

    if (searchLabel !== null) {
      filter.push(`["label.keyword","must","","match","${searchLabel.id}"]`);
    }

    // if (status !== null) {
    //   filter.push(`["status.keyword","must","","match","${status.id}"]`);
    // } else {
    //   filter.push(`["status","must","","terms",[8]]`);
    // }

    if (releaseType !== null) {
      filter.push(
        `["type_of_release.keyword","must","","match","${releaseType.toLowerCase()}"]`
      );
    }
    if (createdDate[0] !== null && createdDate[1] !== null) {
      filter.push(CustomDateFilter());
    }
    if (
      (title !== "" ||
        searchLabel !== null ||
        // status !== null ||
        releaseType !== null ||
        (createdDate[0] !== null && createdDate[1] !== null)) &&
      FilterClicked
    ) {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
        page_limit: `${page_limit}`,
        current_page: "delivered",
      };
    } else {
      params = {
        filters: `[${filter}]`,
        page_number: `${value}` - 1,
        order_by: '[["created_on", "desc"]]',
        current_page: "delivered",
        page_limit: `${page_limit}`,
      };
    }
    if (value - 1 !== props.pagination.current_page_no) {
      fetchFinalReleases(params);
    }
  };

  const rows = [];

  function convertToTitleCase(inputString) {
    return inputString
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  if (props.finalReleases && props.finalReleases.hits) {
    props.finalReleases.hits.hits &&
      props.finalReleases.hits.hits.length > 0 &&
      props.finalReleases.hits.hits.map((item, index) => {
        rows.push({
          sl_no: item._id
            ? props.pagination.current_page_no * page_limit + ++index
            : "",
          id: item._id,
          type_of_release:
            item._source.type_of_release && item._source.type_of_release
              ? item._source.type_of_release.charAt(0).toUpperCase() +
                item._source.type_of_release.slice(1)
              : "",
          status: item._source.status?.status_name
            ? item._source.status.id === "7"
              ? "Pending For Review"
              : convertToTitleCase(item._source.status.status_name)
            : "",
          thumbnail_url: item._source.thumbnail_url,
          title: item._source.title,
          artists: item._source.artists,
          label: item._source.label,
          digital_release_date: item._source.digital_release_date,
          no_of_tracks: item._source.tracks?.length,
          UPC_EAN: item._source.UPC_EAN,
          copyrighted_to: item._source.copyrighted_to,
          featuring_artists: item._source.featuring_artists,
          main_release_date: item._source.main_release_date,
          pre_order_release_date: item._source.pre_order_release_date,
          production_year: item._source.production_year,
          published_by: item._source.published_by,
          producer_catalogue_number: item._source.producer_catalogue_number,
          genre_type: item._source.genre_type,
          sub_genre_type: item._source.sub_genre_type,
          format: item._source.format,
          territory: item._source.territory,
          created_on: item._source.created_on,
        });
      });
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      applyFilter();
    }
  };

  let filterArray = ["Type", "Title", "Label", "UPC", "ISRC", "Release Date"];

  const [type, setType] = useState(() => {
    if (localStorage.getItem("DeliveredFilters")) {
      return JSON.parse(localStorage.getItem("DeliveredFilters")).type;
    } else {
      return "";
    }
  });

  const handleSelectChange = (e, v) => {
    setType(e.target.value);
    setTitle("");
    setSearchLabel(null);
    // setStatus(null);
    setReleaseType(null);
    setCreatedDate([null, null]);
  };

  let maxDate = new Date();

  const issueTypes = [
    "Change Request",
    "Label Request",
    "Take down Request",
    "YT Claim Release",
    "Promotion Request",
    "Metadata Request",
  ];

  const releasesTable = useMemo(
    (item) => (
      <DataGridFunction
        columns={columns}
        rows={rows}
        isRequesting={isRequesting}
        rowHeight={80}
        pagelength={props.pagination.total_page_no}
        page={props.pagination.current_page_no}
        handleChange={handleChange}
      />
    ),
    [props]
  );

  return (
    <Box sx={{ width: "auto" }}>
      {/* <NewReleaseDialog
        open={open}
        onSubmit={() => {}}
        onClose={() => {
          setOpen(false);
        }}
        edit={edit}
        genreList={genreList}
        releaseTypeList={releaseTypeList}
      /> */}
      {/* <TerritoryDailog
        list={territorySelected}
        open={openTerritoryDialog}
        onClose={() => {
          setOpenTerritoryyDialog(false);
        }}
      /> */}

      <Grid
        container
        sx={{
          height: "100%",
          paddingX: 4,
          paddingTop: 1,
        }}
      >
        <Grid
          container
          lg={12}
          md={12}
          sm={12}
          pb={1}
          justifyContent="space-between"
        >
          <Grid item lg={3} md={3} sm={3} pt={1}>
            <Typography>
              {props?.finalReleases?.hits?.total?.value ?? "0"}&nbsp;
              {formatName("Results Found")}
            </Typography>
          </Grid>

          <Grid item lg={5} md={12} sm={12} container>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              container
              spacing={1}
              justifyContent="flex-end"
              mt={0}
            >
              <Grid
                item
                lg={12}
                sm={7}
                md={7}
                sx={{ gap: 2 }}
                justifyContent="flex-end"
                container
                alignItems={"flex-start"}
              >
                <Grid item lg={3} md={3} sm={3}>
                  <SelectField
                    value={type}
                    name="Select Type"
                    label="Select Type"
                    placeholder="Select Type"
                    options={filterArray}
                    size="small"
                    handleSelectChange={handleSelectChange}
                  />
                </Grid>

                {(type === "Title" || type === "UPC" || type === "ISRC") && (
                  <Grid item lg={3} md={3} sm={3}>
                    <MuiTextField
                      variant="outlined"
                      placeholder={
                        type === "Title"
                          ? "Search Title"
                          : type === "UPC"
                          ? "Search UPC"
                          : type === "ISRC"
                          ? "Search ISRC"
                          : ""
                      }
                      fieldheight="33px"
                      sx={{ width: "100%" }}
                      value={title}
                      size="small"
                      onChange={handleSearchChange}
                      onKeyDown={handleKeyPress}
                      InputProps={{
                        endAdornment: (
                          <SearchIcon
                            onClick={applyFilter}
                            sx={{ cursor: "pointer" }}
                          />
                        ),
                      }}
                    />
                  </Grid>
                )}

                {type === "Label" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      value={searchLabel}
                      list={labelList}
                      textFieldProps={{
                        placeholder: "Label Name",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.label_name
                      }
                      getOptionLabel={(option) => option.label_name}
                      renderOption={(props, item) => (
                        <li {...props} key={item.id}>
                          {item.label_name}
                        </li>
                      )}
                      handleChange={labelListHandleChange}
                      size="small"
                      width="100%"
                      height="33px"
                    />
                  </Grid>
                )}

                {/* {type === "Status" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      value={status}
                      list={statusList}
                      textFieldProps={{
                        placeholder: "Status Name",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.status_name
                      }
                      getOptionLabel={(option) => option.status_name}
                      handleChange={statusListHandleChange}
                      size="small"
                      width="100%"
                    />
                  </Grid>
                )} */}

                {type === "Type" && (
                  <Grid item lg={3} md={3} sm={3}>
                    <AutoCompleteComponent
                      value={releaseType}
                      list={["Audio", "Video"]}
                      textFieldProps={{
                        placeholder: "Type of Release",
                      }}
                      handleChange={releaseTypeHandleChange}
                      size="small"
                      width="100%"
                      height="33px"
                    />
                  </Grid>
                )}

                {type === "Release Date" && (
                  <Grid item lg={5} md={5} sm={5}>
                    <DatePicker
                      value={createdDate ? createdDate : [null, null]}
                      onChange={dateValueHandleChange}
                      maxDate={maxDate}
                      height="33px"
                    />
                  </Grid>
                )}

                <Grid item lg={1.5} md={2} sm={2}>
                  <MuiButton
                    name={"Filter"}
                    width="100%"
                    onClick={applyFilter}
                    loading={filterLoader && isRequesting}
                  />
                </Grid>
                <Grid item lg={1.5} md={2} sm={2}>
                  <MuiButton
                    cancel={true}
                    name={"Clear"}
                    width="100%"
                    onClick={ClearState}
                    loading={clearLoader && isRequesting}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          xl={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            height: "-webkit-fill-available",
          }}
        >
          <LoadingIndicator isActive={isRequesting}>
            {isRequesting ? (
              <Box>
                <LoadingBgImage />
              </Box>
            ) : (
              releasesTable
            )}
          </LoadingIndicator>
        </Grid>
      </Grid>
      <RaiseIssueDialog
        open={openIssueRaiseDialog}
        onClose={() => {
          setOpenIssueRaiseDialog(false);
        }}
        releaseInfo={releaseDataIssue}
        current_page={"delivered"}
      />
    </Box>
  );
}
export default connect(mapStateToProps)(DeliveredReleases);

const SelectField = ({
  label,
  name,
  options,
  value,
  size,
  placeholder,
  handleSelectChange,
}) => {
  return (
    <Grid container>
      <Select
        input={<OutlinedInput {...inputProps} />}
        MenuProps={menuProps}
        value={value}
        displayEmpty
        size={size}
        style={{ width: "100%" }}
        onChange={handleSelectChange}
      >
        {placeholder && (
          <MenuItem value="" disabled>
            <span style={{ color: "#b7c4c2" }}>{placeholder}</span>
          </MenuItem>
        )}
        {options.map((item) => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};
