import { BaseModel } from "sjs-base-model";

export default class A3ReportsStorewiseModel extends BaseModel {
  store_wise_revenue = [];

  constructor(data1) {
    super();
    this.update(data1);
  }
}
