import React, { useState, forwardRef } from "react";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { fieldToTextField } from "formik-material-ui";
import { format } from "date-fns";
import { theme } from "../../views/App";
import { isValid } from "date-fns";

const MuiCalendar = forwardRef(({ value, onChange, maxDate, ...props }) => {
  const {
    form: { setTouched, setFieldValue, touched },
  } = props;
  const { error, helperText, ...field } = fieldToTextField(props);
  const { name } = field;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateCalendar
        {...props}
        {...field}
        value={value ? dayjs(value) : null}
        onChange={(value) => {
          setFieldValue(name, format(value.$d, "yyyy-MM-dd"));
        }}
        onBlur={() => setTouched({ ...touched, [name]: true })}
        sx={{
          backgroundColor: theme.palette.background.default,
          fontSize: "10px",

          ".MuiPickersCalendarHeader-root": {
            backgroundColor: theme.palette.calendar.background.header,
            m: 0,
          },
        }}
        // disablePast
        dayOfWeekFormatter={(day) => {
          return day;
        }}
      />
    </LocalizationProvider>
  );
});

export default MuiCalendar;
