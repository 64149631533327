import BaseReducer from "../../utilities/BaseReducer";
import ActorAction from "./ActorAction";

export default class ActorFilterReducer extends BaseReducer {
  //initial state of ActorFilterReduser
  initialState = {
    actorFilter: [],
  };
  //ActorFilterReduser request action finish
  [ActorAction.REQUEST_ACTOR_FILTER_FINISHED](state, action) {
    return {
      ...state,
      actorFilter: action.payload,
    };
  }

  [ActorAction.REQUEST_ACTOR_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      actorFilter: action.payload,
    };
  }
}
