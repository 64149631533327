import {
  Box,
  Grid,
  Toolbar,
  IconButton,
  Dialog,
  Typography,
  Button,
  AppBar,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import logo from "./logo/a3Label.webp";
import "./Genereteinvoice.css";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GenerateInvoice(props) {
  const componentRef = useRef();
  let [pageWidth, setPageWidth] = useState(210);
  let [pageHeight, setPageHeight] = useState(297);
  const [totalAmount, setTotalAmount] = useState(0);

  let { invoiceData } = props;

  useEffect(() => {
    let total = 0;
    invoiceData?.transaction_summary &&
      invoiceData?.transaction_summary.length > 0 &&
      invoiceData?.transaction_summary.map((item, index) => {
        total = total + item.amount;
      });

    setTotalAmount(total);
  }, []);

  let total = 0;
  invoiceData?.transaction_summary &&
    invoiceData?.transaction_summary.length > 0 &&
    invoiceData?.transaction_summary.map((item, index) => {
      total = total + item.amount;
    });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `A3Invoice-${invoiceData?.client_name}(${dateFormatter(
      invoiceData?.created_on
    )})`,
  });

  function dateFormatter(dateString) {
    if (!dateString) return;

    const [date, time] = dateString.split(", ");
    const [hours] = time.split(":");

    let period = "AM";
    let hours12 = parseInt(hours, 10);

    if (hours12 >= 12) {
      period = "PM";
      if (hours12 > 12) {
        hours12 -= 12;
      }
    }

    return `${date}, ${hours12}${period}`;
  }

  const NumberToWords = (number) => {
    const convertToWords = (num) => {
      // Define arrays for words
      const units = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
      ];
      const teens = [
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];
      const tens = [
        "",
        "ten",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      // Convert a number less than 1000 to words
      const convertLessThanThousand = (num) => {
        if (num === 0) return "";
        if (num < 10) return units[num];
        if (num < 20) return teens[num - 11];
        if (num < 100)
          return tens[Math.floor(num / 10)] + " " + units[num % 10];
        return (
          units[Math.floor(num / 100)] +
          " hundred " +
          convertLessThanThousand(num % 100)
        );
      };

      // Convert a number less than 1 million to words
      const convertLessThanMillion = (num) => {
        if (num < 1000) {
          return convertLessThanThousand(num);
        } else {
          return (
            convertLessThanThousand(Math.floor(num / 1000)) +
            " thousand " +
            convertLessThanThousand(num % 1000)
          );
        }
      };

      // Convert the entire number to words
      if (num === 0) return "zero";
      if (num < 1000000) {
        return convertLessThanMillion(num);
      } else {
        return (
          convertLessThanThousand(Math.floor(num / 1000000)) +
          " million " +
          convertLessThanMillion(num % 1000000)
        );
      }
    };

    return convertToWords(number);
  };

  function keyBeautification(key) {
    const words = key?.split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  return (
    <Dialog
      fullScreen
      open={props.open}
      onClose={props.onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Invoice
          </Typography>
          <Button
            autoFocus
            color="inherit"
            onClick={() => {
              handlePrint();
            }}
          >
            Download
          </Button>
        </Toolbar>
      </AppBar>
      <Box>
        <Box
          ref={componentRef}
          sx={{
            width: `100%`,
            marginTop: 1,
          }}
        >
          <Box
            sx={{
              // width: `${pageWidth}mm`,
              // height: `${pageHeight}mm`,
              padding: 3,
              backgroundColor: "white",
              margin: "auto",
              // border: "1px solid gray",
              boxSizing: "border-box",
              marginBottom: 3,
              color: "black",
              fontFamily: "sans-serif",
            }}
          >
            <div
              style={{
                borderBottom: "1px solid gray",
                width: "100%",
                height: "max-content",
              }}
            >
              <div style={{ justifySelf: "flex-start" }}>
                <img className="reportLogo" src={logo} alt="" />
              </div>
            </div>
            <Box
              className="d-flex justify-content-center font-weight-bold"
              sx={{ width: "100%" }}
            >
              Tax Invoice
            </Box>
            <Box>
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td
                      style={{ textAlign: "left", width: "50%" }}
                      colSpan={24}
                    >
                      <span className="font-weight-bold">
                        {invoiceData?.invoice?.seller_details?.name
                          ? invoiceData?.invoice?.seller_details.name
                          : ""}
                      </span>
                      <div>
                        <span>
                          {invoiceData?.invoice?.seller_details?.address
                            ? invoiceData?.invoice?.seller_details?.address
                            : ""}
                        </span>
                        <br />
                        <span>
                          {" "}
                          {invoiceData?.invoice?.seller_details?.email_id
                            ? invoiceData?.invoice?.seller_details.email_id
                            : ""}{" "}
                        </span>
                        <br />
                        <span>
                          {invoiceData?.invoice?.seller_details?.ph_number
                            ? invoiceData?.invoice?.seller_details.ph_number
                            : ""}
                        </span>
                        <br />
                      </div>
                    </td>
                    <td colSpan={12} style={{ width: "25%" }}>
                      <span>Invoice No.</span>
                      <br />
                      <span className="font-weight-bold">
                        {invoiceData?.invoice_number}
                      </span>
                    </td>
                    <td colSpan={12} style={{ width: "25%" }}>
                      Dated : <br />
                      <span className="font-weight-bold">
                        {" "}
                        {invoiceData?.invoice?.invoice_date}
                      </span>
                    </td>
                  </tr>

                  <tr>
                    <td style={{ textAlign: "left" }} colSpan={24}>
                      <span> Buyer ( Bill to )</span> <br />
                      <span className="font-weight-bold">
                        {invoiceData?.invoice?.buyer_details?.name
                          ? invoiceData.invoice?.buyer_details.name
                          : ""}
                      </span>
                      <div>
                        <span>
                          {invoiceData?.invoice?.buyer_details?.address
                            ? invoiceData.invoice?.buyer_details.address
                            : ""}
                        </span>
                        <br />
                        <span>
                          {invoiceData?.invoice?.buyer_details?.email_id
                            ? invoiceData.invoice?.buyer_details.email_id
                            : ""}
                        </span>
                        <br />
                        <span>
                          {invoiceData?.invoice?.buyer_details?.ph_number
                            ? invoiceData.invoice?.buyer_details.ph_number
                            : ""}
                        </span>
                        <br />
                      </div>
                    </td>
                    <td style={{ width: "50%" }} colSpan={24}>
                      <span className="font-weight-bold">Bank Details</span>
                      <br />
                      <span>
                        Beneficiary Name :{" "}
                        {invoiceData?.invoice?.buyer_details?.bank_details
                          ?.account_holder_name
                          ? invoiceData.invoice?.buyer_details.bank_details
                              ?.account_holder_name
                          : ""}
                      </span>
                      <div>
                        <span>
                          Beneficiary Account No. :{" "}
                          {invoiceData?.invoice?.buyer_details?.bank_details
                            ?.account_number
                            ? invoiceData.invoice?.buyer_details.bank_details
                                ?.account_number
                            : ""}
                        </span>
                        <br />
                        <span>
                          Beneficiary Bank name :{" "}
                          {invoiceData?.invoice?.buyer_details?.bank_details
                            ?.bank_name
                            ? invoiceData.invoice?.buyer_details.bank_details
                                ?.bank_name
                            : ""}
                        </span>
                        <br />
                        <span>
                          Beneficiary IFSC Code :{" "}
                          {invoiceData?.invoice?.buyer_details?.bank_details
                            ?.ifsc
                            ? invoiceData.invoice?.buyer_details.bank_details
                                ?.ifsc
                            : ""}
                        </span>
                        <br />
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{ textAlign: "left", width: "10px" }}
                      colSpan={2}
                    >
                      Sl no.
                    </td>
                    <td style={{ textAlign: "center" }} colSpan={42}>
                      Description of Services
                    </td>
                    <td style={{ textAlign: "center" }} colSpan={2}>
                      HSN/SAC
                    </td>
                    <td style={{ textAlign: "center" }} colSpan={2}>
                      Amount
                    </td>
                  </tr>

                  <tr
                    style={{
                      width: `100%`,
                    }}
                  >
                    <td style={{ textAlign: "left" }} colSpan={2}>
                      1
                    </td>
                    <td style={{ textAlign: "center" }} colSpan={42}>
                      <span>
                        <span className="font-weight-bold">
                          Revenue for month :{" "}
                        </span>
                        {invoiceData?.sales_period?.length
                          ? invoiceData.sales_period.map((item, index) => (
                              <span>
                                {index !== invoiceData.sales_period.length - 1
                                  ? `${item}, `
                                  : item}
                              </span>
                            ))
                          : ""}
                      </span>
                    </td>
                    <td style={{ textAlign: "center" }} colSpan={2}>
                      9973
                    </td>

                    <td style={{ textAlign: "center" }} colSpan={2}>
                      <span className="font-weight-bold">
                        {invoiceData?.claim_value
                          ? invoiceData.claim_value
                          : ""}
                      </span>
                    </td>
                  </tr>

                  <tr
                    style={{
                      width: `100%`,
                    }}
                  >
                    <td
                      style={{ textAlign: "left", width: "10%" }}
                      colSpan={2}
                    ></td>
                    <td
                      style={{ textAlign: "right", width: "40%" }}
                      colSpan={42}
                    >
                      <span>Total</span>
                    </td>

                    <td
                      style={{ textAlign: "center", width: "10%" }}
                      colSpan={2}
                    ></td>

                    <td
                      style={{ textAlign: "center", width: "10%" }}
                      colSpan={2}
                    >
                      <span className="font-weight-bold">
                        ${" "}
                        {invoiceData?.claim_value
                          ? invoiceData?.claim_value
                          : ""}
                      </span>
                    </td>
                  </tr>

                  <tr
                    style={{
                      width: `100%`,
                    }}
                  >
                    <td
                      style={{ textAlign: "left", width: "10%" }}
                      colSpan={48}
                    >
                      <span>Amount Paid (in words)</span>
                      <br />
                      <span className="font-weight-bold">
                        {invoiceData?.claim_value &&
                          keyBeautification(
                            NumberToWords(Math.floor(invoiceData?.claim_value))
                          ) + ` Only`}
                      </span>
                    </td>
                  </tr>

                  <tr
                    style={{
                      width: `100%`,
                    }}
                  >
                    <td
                      style={{ textAlign: "left", width: "10%" }}
                      colSpan={24}
                    >
                      <span>
                        Remarks :{" "}
                        {invoiceData?.invoice?.remarks
                          ? invoiceData.invoice?.remarks
                          : ""}
                      </span>
                      <br />
                      <span>
                        Transaction Id :{" "}
                        {invoiceData?.invoice?.transaction_id
                          ? invoiceData.invoice?.transaction_id
                          : ""}
                      </span>
                    </td>
                    <td
                      style={{ textAlign: "left", width: "10%" }}
                      colSpan={24}
                    >
                      <span className="font-weight-bold">
                        Seller Bank Details
                      </span>
                      <br />
                      <span>
                        Beneficiary Name :{" "}
                        {invoiceData?.invoice?.seller_details?.bank_details?.[
                          "A/C_holder_name"
                        ]
                          ? invoiceData.invoice?.seller_details.bank_details?.[
                              "A/C_holder_name"
                            ]
                          : ""}
                      </span>
                      <div>
                        <span>
                          Beneficiary Account No. :{" "}
                          {invoiceData?.invoice?.seller_details?.bank_details?.[
                            "A/c_no"
                          ]
                            ? invoiceData.invoice?.seller_details
                                .bank_details?.["A/c_no"]
                            : ""}
                        </span>
                        <br />
                        <span>
                          Beneficiary Bank name :{" "}
                          {invoiceData?.invoice?.seller_details?.bank_details
                            ?.bank_name
                            ? invoiceData.invoice?.seller_details.bank_details
                                ?.bank_name
                            : ""}
                        </span>
                        <br />
                        <span>
                          Beneficiary IFSC Code :{" "}
                          {invoiceData?.invoice?.seller_details?.bank_details
                            ?.branch_and_IFCS_code
                            ? invoiceData.invoice?.seller_details.bank_details
                                ?.branch_and_IFCS_code
                            : ""}
                        </span>
                        <br />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div width="100%" style={{ marginTop: 1 }} textAlign="center">
                This is a Computer Generated Invoice
              </div>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}

export default GenerateInvoice;
