import { BaseModel } from "sjs-base-model";

// UserResourcePostModel
export default class UserResourcePostModel extends BaseModel {
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
