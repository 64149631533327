import { BaseModel } from "sjs-base-model";

export default class ReleasewiseRevenueGetModel extends BaseModel {
  // response getting from the api mentioned end point
  release_wise_revenue = [];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
