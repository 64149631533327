import BaseReducer from "../../utilities/BaseReducer";
import TopArtistAction from "./TopArtistAction";

export default class TopArtistFilterReducer extends BaseReducer {
  //initial state of TopArtistFilterReduser
  initialState = {
    topArtistFilter: [],
  };
  //TopArtistFilterReduser request action finish
  [TopArtistAction.REQUEST_TOPARTIST_FILTER_FINISHED](state, action) {
    return {
      ...state,
      topArtistFilter: action.payload,
    };
  }
}
