import { BaseModel } from "sjs-base-model";

// TopLyricistModel
export default class TopLyricistModel extends BaseModel {
  // response getting from the api mentioned end point

  top_lyricists = {};

  constructor(data1) {
    super();

    this.update(data1);
  }
}
