import React from "react";
import { DataGridPro, LicenseInfo } from "@mui/x-data-grid-pro";
import NoDataFound from "../nodatafound/NoDataFound";
import { makeStyles } from "@material-ui/core/styles/";
import { theme } from "../../views/App";
import { Box, Stack } from "@mui/material";
import PaginationUI from "../pagination/Pagination";
import "./datagrid.css";

LicenseInfo.setLicenseKey(
  "958fef58ed50f3faf70194d92e62f97eTz03NTIwMixFPTE3MjY5ODIwMTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function DataGridFunction({
  columns,
  rows,
  rowReordering,
  pagination,
  rowHeight,
  getRowHeight,
  onCellClick,
  checkboxSelection,
  curser,
  getRowId,
  rowBorder,
  rowColor,
  headerColor,
  onSelectionModelChange,
  apiRef,
  onRowClick,
  pagelength,
  onRowOrderChange,
  page,
  handleChange,
  headerHeight,
  height,
  selectionModel,
  getRowClassName,
  isRequesting,
  initialState,
  getDetailPanelHeight,
  getDetailPanelContent,
}) {
  const useStyles = makeStyles({
    root: {
      "& .MuiDataGrid-cellCenter": {
        justifyContent: "center",
        display: "none",
      },
      "& .MuiDataGrid-cellContent": {
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        position: "relative",
        pointerEvents: "none",
      },

      "& .MuiDataGrid-cell": {
        border: rowBorder ? "1px solid #f7f7f6" : "",
        padding: rowBorder ? "8px" : "",
      },

      // header background color
      "& .css-1oi61yq-MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.datagrid.background.datagridHeader,
        color: theme.palette.primary.main,
      },
      // " .MuiDataGrid-columnHeader .MuiDataGrid-columnHeader--sortable": {},
      // "& .MuiDataGrid-main": { borderRadius: 0 },
      "& .MuiDataGrid-columnHeaders": {
        backgroundColor:
          headerColor ?? theme.palette.datagrid.background.datagridHeader,
        borderTop: headerColor ? "1px solid #dddddd" : "",
        borderBottom: headerColor ? "1px solid #dddddd" : "",
      },

      "& .MuiDataGrid-columnHeaderTitle": {
        // fontSize: theme.palette.datagrid.Columns.fontSize,
        fontSize: theme.palette.datagrid.Columns.fontSize,
        width: "100%",
      },
      // row background color
      // "& .MuiDataGrid-cell": {
      //   backgroundColor: theme.palette.datagrid.background.datagridCell,
      // },
      "& .MuiDataGrid-row": {
        backgroundColor:
          !getRowClassName && theme.palette.datagrid.background.datagridCell,

        "&:hover": {
          backgroundColor:
            !getRowClassName && theme.palette.datagrid.background.hover,
          cursor: curser === "pointer" ? "pointer" : "default",
        },
      },
    },
  });
  const classes = useStyles();

  return (
    //*******************************************  Datagrid components
    <Box
      sx={{
        // paddingTop: 1,
        height: rows.length === 0 ? "15vh" : height ? height : "80vh",
        width: "100%",
      }}
    >
      <DataGridPro
        components={{
          NoRowsOverlay: () => !isRequesting && <NoDataFound />,
          Footer: () =>
            pagelength ? (
              <PaginationUI
                pagelength={pagelength}
                page={page}
                handleChange={handleChange}
              />
            ) : null,
        }}
        componentsProps={{
          pagination: pagination,
        }}
        onRowOrderChange={onRowOrderChange}
        rowReordering={rowReordering}
        disableVirtualization
        className={classes.root}
        density="compact"
        rowHeight={rowHeight}
        rows={rows}
        getRowHeight={getRowHeight}
        onCellClick={onCellClick}
        getRowId={getRowId ? getRowId : (rows) => rows.id}
        columns={columns}
        checkboxSelection={checkboxSelection}
        selectionModel={selectionModel}
        disableSelectionOnClick
        onRowClick={onRowClick}
        getRowClassName={
          getRowClassName
            ? getRowClassName
            : (params) =>
                rowColor ? (params.id % 2 === 0 ? "oddRow" : "") : ""
        }
        sx={{
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: theme.palette.buttonComponent.fontSize.extraLarge,
            fontWeight: theme.typography.fontWeightBold,
          },
          fontSize: theme.palette.buttonComponent.fontSize.large,
          // "& .MuiDataGrid-row:hover": {
          //   backgroundColor: "red",
          // },
          "& .MuiDataGrid-virtualScroller": {
            // overflowY: "scroll",
            // scrollbarWidth: "thin",
            // scrollbarColor: "#ccc #fff",
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "9px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#b0d2da",
              borderRadius: "3px",
              "&:hover": {
                backgroundColor: "#85bac6",
              },
            },
          },
        }}
        headerHeight={headerHeight ? headerHeight : 70}
        hideFooterPagination={true}
        onSelectionModelChange={onSelectionModelChange}
        apiRef={apiRef}
        initialState={initialState}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        //   pageSize={pageSize}
        //   onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        //   rowsPerPageOptions={[5, 10, 20]}
        //   pagination
      />
    </Box>
  );
}

export default DataGridFunction;
