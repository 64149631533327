import BaseReducer from "../../utilities/BaseReducer";
import TopAlbumAction from "./TopAlbumAction";

// TopAlbumReduser
export default class TopAlbumReducer extends BaseReducer {
  initialState = {
    top_albums_report: [],
  };
  [TopAlbumAction.REQUEST_TOP_ALBUM_FINISHED](state, action) {
    return {
      ...state,
      top_albums_report: action.payload,
    };
  }
}
