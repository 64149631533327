import { BaseModel } from "sjs-base-model";

export default class A3ReportsLabelModel extends BaseModel {
  client_wise_label_data = [];

  constructor(data1) {
    super();
    this.update(data1);
  }
}
