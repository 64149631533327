import { theme } from "../../views/App";

export const formatName = (name) => {
  const words = name.split(" ");

  const formattedWords = words.map((word, index) => (
    <span key={index}>
      <span style={{ color: theme.palette.text.first_letter }}>
        {word.charAt(0)}
      </span>
      {word.slice(1)}{" "}
    </span>
  ));
  return formattedWords;
};
