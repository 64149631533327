import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from "react";
import dayjs from "dayjs";
import MuiTextField from "../muiTextField/MuiTextField";
import { theme } from "../../views/App";

function MuiMonthPicker({ onChange, value, width }) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={value ? dayjs(value) : null}
        views={["month", "year"]}
        disableFuture
        slots={{
          textField: MuiTextField,
        }}
        onChange={onChange}
        slotProps={{
          textField: {
            variant: "outlined",
            size: "small",
            InputProps: {
              style: {
                backgroundColor: theme.palette.background.paper,
                borderRadius: "8px",
                height: theme.palette.field.height,
                width: width || "100%",
              },
              size: "small",
            },
            placeholder: "Select Month",
          },
        }}
      />
    </LocalizationProvider>
  );
}

export default MuiMonthPicker;
