import React from "react";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { Box, IconButton, Typography } from "@mui/material";

const ReportContainer = ({
  name,
  id,
  setDetailReportId,
  setDetailReportOpen,
  children,
  overflowY = false,
  className,
}) => {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between", // Align the name to the left and the IconButton to the right
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{name}</Typography>
        <IconButton
          onClick={() => {
            setDetailReportOpen(true);
            setDetailReportId(id);
          }}
          title="Maximize"
        >
          <FullscreenIcon fontSize="medium" />
        </IconButton>
      </Box>
      <Box
        className={className}
        sx={{
          flex: 1,
          overflowY: overflowY ? "auto" : null,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ReportContainer;
