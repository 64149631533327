import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ActorModel from "./model/ActorModel";
import ActorPostModel from "./model/ActorPostModel";
import ActorPutModel from "./model/ActorPutModel";

//ActorEffect
export default class ActorEffect {
  // calling an API according to theActor model
  static async requestActor(params) {
    const endpoint = environment.api.actor;
    return EffectUtility.getToModel(ActorModel, endpoint, params);
  }

  static async requestActorClear() {
    return {};
  }

  static async requestActorFilterClear() {
    return {};
  }

  // Get method for theActor filter
  static async requestActorFilter(params) {
    const endpoint = environment.api.actor;
    return EffectUtility.getToModel(ActorModel, endpoint, params);
  }

  // put method for theActor
  static async requestPutActor(data, id) {
    const endpoint = environment.api.actor + "/" + id;
    return EffectUtility.putToModel(ActorPutModel, endpoint, data);
  }

  // post method for theActor
  static async requestPostActor(data, params, callBack) {
    const endpoint = environment.api.actor + "/add";
    let response = EffectUtility.postToModel(
      ActorPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestDeleteActor(id) {
    const endpoint = environment.api.actor + "/" + id;
    return EffectUtility.deleteToModel(ActorPostModel, endpoint);
  }
}
