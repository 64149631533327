import BaseReducer from "../../utilities/BaseReducer";
import ArtistAction from "./ArtistAction";

export default class ArtistFilterReduser extends BaseReducer {
  //initial state of ArtistFilterReduser
  initialState = {
    artistFilter: [],
  };
  //ArtistFilterReduser request action finish
  [ArtistAction.REQUEST_ARTIST_FILTER_FINISHED](state, action) {
    return {
      ...state,
      artistFilter: action.payload,
    };
  }
}
