import React, { useEffect, useState } from "react";
import { Avatar, Box, Dialog, Grid, Typography } from "@mui/material";
import { connect } from "react-redux";
import environment from "environment";
import ReactPlayer from "react-player";
import NotStartedIcon from "@mui/icons-material/NotStarted";
import { theme } from "../App";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const RetriveAssetDialog = (props) => {
  const { RetriveData, releaseId } = props;

  const [assetUrl, setAssetUrl] = useState("");
  const [imageLoader, setImageLoader] = useState(false);

  const onClosefunction = () => {
    // stopGetRequest(props.onClose);
    props.onClose();
  };

  let access_tocken = localStorage.getItem("Auth")
    ? JSON.parse(localStorage.getItem("Auth")).access_token
    : "";

  return (
    <Dialog
      open={props.open}
      onClose={onClosefunction}
      fullWidth
      maxWidth="sm"
      p={5}
    >
      <Box>
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          //   className="bg-warning"
          px={3}
          py={1}
          sx={{
            borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
          }}
        >
          <Grid item sx={{ color: theme.palette.text.gray }}>
            <Typography>{props.RetriveData.assets_name}</Typography>
          </Grid>
          <Grid item sx={{ color: theme.palette.text.gray }}>
            Media Player
          </Grid>
        </Grid>
        <Box>
          <Grid
            item
            container
            xl={12}
            alignItems="center"
            justifyContent={"center"}
          >
            <Grid
              item
              xl={10}
              container
              textAlign="center"
              //   className="bg-warning"
              alignItems={"center"}
              justifyContent={"center"}
              p={5}
            >
              {props?.RetriveData?.asset_url ? (
                <ReactPlayer
                  url={`${environment.api.retriveTrackAsset}?path=${props?.RetriveData?.asset_url}&release_id=${releaseId}&client_id=${RetriveData.client_id.id}&token=${access_tocken}&current_page=upload`}
                  controls={true}
                  width="100%"
                  height={"auto"}
                  maxWidth="100%"
                  maxHeight={"auto"}
                  className=" p-0 m-0"
                  style={{
                    padding: 0,
                    margin: 0,
                  }}
                />
              ) : (
                <NotStartedIcon />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(RetriveAssetDialog);
