import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import rootStore from "./stores/rootStore";
import App from "./views/App";
import { SnackbarProvider } from "notistack";
import reportWebVitals from "./reportWebVitals";

const initialState = {};
const store = rootStore(initialState);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SnackbarProvider maxSnack={30}>
    <Provider store={store}>
      <App />
    </Provider>
  </SnackbarProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
