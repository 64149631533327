import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import ActressModel from "./model/ActressModel";
import ActressPostModel from "./model/ActressPostModel";
import ActressPutModel from "./model/ActressPutModel";

// ActressEffect
export default class ActressEffect {
  // calling an API according to the Actress model
  static async requestActress(params) {
    const endpoint = environment.api.actress;
    return EffectUtility.getToModel(ActressModel, endpoint, params);
  }

  static async requestActressClear() {
    return {};
  }

  static async requestActressFilterClear() {
    return {};
  }

  // Get method for the Actress filter
  static async requestActressFilter(params) {
    const endpoint = environment.api.actress;
    return EffectUtility.getToModel(ActressModel, endpoint, params);
  }

  // put method for the Actress
  static async requestPutActress(data, id) {
    const endpoint = environment.api.actress + "/" + id;
    return EffectUtility.putToModel(ActressPutModel, endpoint, data);
  }

  // post method for the Actress
  static async requestPostActress(data, params, callBack) {
    const endpoint = environment.api.actress + "/add";
    let response = EffectUtility.postToModel(
      ActressPostModel,
      endpoint,
      data,
      params
    );

    if (callBack) {
      response.then((res) => {
        callBack(res);
      });
    }

    return response;
  }

  static async requestDeleteActress(id) {
    const endpoint = environment.api.actress + "/" + id;
    return EffectUtility.deleteToModel(ActressPostModel, endpoint);
  }
}
