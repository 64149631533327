import BaseReducer from "../../utilities/BaseReducer";
import RevenueReportAction from "./RevenueReportAction";

export default class RevenueReportExportReducer extends BaseReducer {
  initialState = {
    revenueReportExport: [],
  };
  [RevenueReportAction.REQUEST_REVENUE_REPORT_EXPORT_FINISHED](state, action) {
    return {
      ...state,
      revenueReportExport: action.payload,
    };
  }
}
