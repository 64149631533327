import { BaseModel } from "sjs-base-model";

// LabelModel
export default class UserProfileModel extends BaseModel {
  // response getting from the api mentioned end point
  _id = "";
  _index = "";
  _primary_term = "";
  _seq_no = "";
  _source = {};
  _version = 0;
  found = false;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
