import React, { useEffect, useRef, useState } from "react";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { ModelScreenContainer } from "../../componentLayout/ComponentLayout";
import { theme } from "../../../views/App";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function ErrorLogPopper(props) {
  const [open, setOpen] = React.useState(false);
  const errorLogPopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      errorLogPopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      errorLogPopperRef.current &&
      errorLogPopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  const returnResource = (error) => {
    let errorString = error;
    errorString = errorString.split("?");
    return errorString[0];
  };

  return (
    <Grid container justifyContent={"center"}>
      <Typography
        component={"div"}
        ref={errorLogPopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          flexDirection: "column",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={errorLogPopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper
              style={{
                width: "auto",
                minWidth: 200,
                height: "auto",
                padding: 20,
                boxShadow: theme.shadows,
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div
                  autoFocusItem={open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                >
                  {props.errorLogs && props.errorLogs.length > 0 ? (
                    <Grid
                      item
                      xs={12}
                      sx={{
                        height: 250,
                        overflow: "auto",
                        scrollbarWidth: "none",
                      }}
                    >
                      {props.errorLogs.map((error, ind) => (
                        <Grid item sx={{ marginBottom: 1 }}>
                          <ModelScreenContainer key={ind}>
                            <span>Status: {error.status}</span>{" "}
                            <span>
                              Message: {error.data.message}{" "}
                              {`(${error.data.status})`}
                            </span>
                            <p>
                              Resource :{" "}
                              {error.endpoint && returnResource(error.endpoint)}
                            </p>
                          </ModelScreenContainer>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Grid>No errors </Grid>
                  )}
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(ErrorLogPopper);
