import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import AssessmentIcon from "@mui/icons-material/Assessment";
function PageNotFound(props) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 1,
      }}
    >
      <Box sx={{ textAlign: "center", fontSize: "18px" }}>
        <Grid>
          <AssessmentIcon sx={{ fontSize: 250 }} />
        </Grid>
        {props.page == "Balance" && (
          <Typography component={"p"} fontSize={"18px"}>
            It doesn't look like you have any {props.page} available just yet.
          </Typography>
        )}
        {props.page == "Balance" && (
          <Typography component={"p"} fontSize={"18px"}>
            It can take a few months for your {props.page} to process.
          </Typography>
        )}

        {props.page == "Reports" && (
          <Typography component={"p"} fontSize={"18px"}>
            It seems there is no data available right now.
          </Typography>
        )}
        <Typography component={"p"} fontSize={"18px"}>
          Please reach out to support@a3labels.in for any questions.
        </Typography>
      </Box>
    </Box>
  );
}

export default PageNotFound;
