import BaseReducer from "../../utilities/BaseReducer";
import YTNetRevenueByMonthAction from "./YTNetRevenueByMonthAction";

export default class YTNetRevenueByMonthReducer extends BaseReducer {
  initialState = {
    ytNetRevenueByMonth: [],
  };
  [YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      ytNetRevenueByMonth: action.payload,
    };
  }
}
