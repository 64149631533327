import BaseReducer from "../../utilities/BaseReducer";
import RevenueClaimAction from "./RevenueClaimAction";

export default class RevenueClaimReducer extends BaseReducer {
  initialState = {
    revenueClaim: [],
  };
  [RevenueClaimAction.REQUEST_REVENUE_CLAIM_FINISHED](state, action) {
    return {
      ...state,
      revenueClaim: action.payload,
    };
  }
}
