import ActionUtility from "../../utilities/ActionUtility";
import BlogsEffect from "./BlogsEffect";

export default class BlogsAction {
  // BlogsAction action with an function declaration
  static REQUEST_BLOGS = "BlogsAction.REQUEST_BLOGS";
  static REQUEST_BLOGS_FINISHED = "BlogsAction.REQUEST_BLOGS_FINISHED";

  static REQUEST_BLOGS_FILTER = "BlogsAction.REQUEST_BLOGS_FILTER";
  static REQUEST_BLOGS_FILTER_FINISHED =
    "BlogsAction.REQUEST_BLOGS_FILTER_FINISHED";

  // METHODS
  // blog GET function
  static requestBlogs(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_BLOGS,
        BlogsEffect.requestBlogs,
        params
      );
    };
  }

  static requestBlogsFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        BlogsAction.REQUEST_BLOGS_FILTER,
        BlogsEffect.requestBlogsFilter,
        params
      );
    };
  }
}
