import BaseReducer from "../../utilities/BaseReducer";
import UserProfileAction from "./UserProfileAction";

// NotificationSubscriptionReduser
export default class NotificationSubscriptionReduser extends BaseReducer {
  initialState = {
    notificationSubscription: [],
  };
  [UserProfileAction.REQUEST_NOTIFICATION_SUBSCIPTION_FINISHED](state, action) {
    return {
      ...state,
      notificationSubscription: action.payload,
    };
  }
}
