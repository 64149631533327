/*
 * Base is the default environment for production.
 * Add everything here and override value in other files if needed.
 * https://blog.usejournal.com/my-awesome-custom-react-environment-variables-setup-8ebb0797d8ac
 */
export default function baseEnv({ baseApi, socketApi, a3TunesApi, logsApi }) {
  return {
    route: {
      baseRoute: "/react-redux-architecture", // Fixes issue with Github Pages
    },
    api: {
      baseApi: `${baseApi}`,
      socketApi: `${socketApi}`,
      a3TunesApi: `${a3TunesApi}`,
      logsApi: `${logsApi}`,
      user: `${baseApi}/user`,
      login: `${baseApi}/v1/api/login`,
      logout: `${baseApi}/v1/api/logout`,
      status: `${baseApi}/musiclabels_release_status`,
      assets: `${baseApi}/musiclabels_assets`,
      artist: `${baseApi}/musiclabels_artist`,
      track: `${baseApi}/musiclabels_track`,
      updateplaylink: `${baseApi}/update_playlink`,
      syncplaylink: `${baseApi}/update_track_play_link`,
      syncreleasesstores: `${baseApi}/sync_delivered_stores`,
      client: `${baseApi}/musiclabels_sub_client`,
      genre: `${baseApi}/musiclabels_genre`,
      label: `${baseApi}/musiclabels_label`,
      postlabel: `${baseApi}/request_musiclabel`,
      stream_platform: `${baseApi}/musiclabels_stream_platform`,
      subgenre: `${baseApi}/musiclabels_subgenre`,
      release: `${baseApi}/musiclabels_release`,
      plan: `${baseApi}/musiclabels_plan`,
      territories: `${baseApi}/musiclabels_territories`,
      fileUpload: `${baseApi}/musiclabels_file_upload`,
      retriveAsset: `${baseApi}/musiclabels_retrive_asset`,
      retriveTrackAsset: `${baseApi}/musiclabels_track_preview`,
      userprofile: `${baseApi}/user_details`,
      mailSubscription: `${baseApi}/mail_subscription`,
      notificationSubscription: `${baseApi}/notification_subscription`,
      userResource: `${baseApi}/musiclabel_user_resource`,
      timezone: `${baseApi}/timezonedata`,
      language: `${baseApi}/listoflanguage`,
      errorExample: "https://httpstat.us/520",
      audioStreams: `${baseApi}/audio_streams`,
      topTracks: `${baseApi}/top_track_report`,
      topTracksWithDateFilter: `${baseApi}/top_track_with_date_filter`,
      topReleases: `${baseApi}/top_releases_list`,
      topStore: `${baseApi}/top_stores_report`,
      topDevices: `${baseApi}/top_device_type_report`,
      releaseStatusCount: `${baseApi}/release_status_count`,
      trackStatusCount: `${baseApi}/track_status_count`,
      resource: `${baseApi}/musiclabels_resource`,
      userprofileupdate: `${baseApi}/update_my_profile_picture`,
      ticket: `${baseApi}/musiclabels_ticket`,
      tickets: `${baseApi}/musiclabels_tickets`,
      finalReleases: `${baseApi}/musiclabels_final_release`,
      postToIngrooves: `${baseApi}/push_to_ingrooves`,
      pagePermission: `${baseApi}/allowed_pages`,
      releasesShadowTable: `${baseApi}/musiclabels_release_shadow`,
      actor: `${baseApi}/musiclabels_actor`,
      actress: `${baseApi}/musiclabels_actress`,
      director: `${baseApi}/musiclabels_director`,
      withdrawRelease: `${baseApi}/withdraw_release_from_review`,
      userResetPassword: `${baseApi}/v1/api/userResetPassword`,
      adminResetPassword: `${baseApi}/v1/api/adminResetPassword`,
      clientResetDevice: `${baseApi}/mobile_device_id_mapping`,
      topArtist: `${baseApi}/top_artists_report`,
      topLabel: `${baseApi}/top_label_report`,
      notificationsData: `${baseApi}/a3_notifications_data`,
      notificationsDataBulkUpdate: `${baseApi}/a3_notifications_bulk_update`,
      blogs: `${baseApi}/musiclabels_blog`,
      top_territories_report: `${baseApi}/top_territories_report`,
      top_languages_report: `${baseApi}/top_languages_report`,
      top_lyricists_report: `${baseApi}/top_lyricists_report`,
      top_albums_report: `${baseApi}/top_albums_report`,
      top_composers_report: `${baseApi}/top_composers_report`,
      spotifyMigrateTrack: `${baseApi}/musiclabels_release_by_spotify`,
      appleMusicMigrateTrack: `${baseApi}/musiclabels_release_by_apple_music`,
      //Revenue
      clientRevenueSharePolicy: `${baseApi}/client_revenue_sharing_policy`,
      syncLabelShareRevenue: `${baseApi}/musiclabels_sync_label_share_revenue`,
      revenueReport: `${baseApi}/revenue_report`,
      filterRevenueReport: `${baseApi}/revenue_report_filter`,
      revenueReportFileUploader: `${baseApi}/revenue_report_file_uploader`,
      trackRevenue: `${baseApi}/musiclabels_track_revenue`,
      updateBankDetails: `${baseApi}/update_my_bank_details`,
      trackBulk: `${baseApi}/musiclabels_track_bulk_update`,
      albumPlayLink: `${baseApi}/musiclabels_release_playlink`,

      // Revenue Reports
      netRevenueByMonth: `${baseApi}/net_revenue_by_month`,
      clientArtistReport: `${baseApi}/client_wise_artist_report`,
      storewiseRevenueReport: `${baseApi}/musiclabels_store_revenue`,
      clientReleaseReport: `${baseApi}/client_wise_release_report`,
      monthwiseRevenueReport: `${baseApi}/net_revenue_by_month`,
      clientLabelReport: `${baseApi}/client_wise_label_report`,
      countrywiseRevenueReport: `${baseApi}/revenue_by_country`,
      labelRevenueReport: `${baseApi}/musiclabels_label_revenue`,
      trackwiseRevenue: `${baseApi}/musiclabels_track_wise_revenue`,
      releasewiseRevenue: `${baseApi}/musiclabels_release_wise_revenue`,
      artistwiseRevenue: `${baseApi}/musiclabels_artist_revenue`,
      trackwiseRevenueSummary: `${baseApi}/musiclabels_track_wise_summary`,

      // YT revenue reports
      ytRevenueReport: `${baseApi}/youtube_revenue_report`,
      ytNetRevenueByMonth: `${baseApi}/musiclabels_youtube_net_revenue_by_month`,

      // revenue claims
      revenueClaim: `${baseApi}/musiclabels_revenue_claim`,
      clientRevenueBalance: `${baseApi}/musiclabels_client_revenue_balance`,
      revenueClaimRequest: `${baseApi}/revenue_claim_request`,
      generateClientInvoice: `${baseApi}/generate_client_invoice`,
      export_revenue_report: `${baseApi}/export_revenue_report`,
    },

    isProduction: true,
    isDevelopment: false,
    isTesting: false,
  };
}
