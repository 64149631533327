import BaseReducer from "../../utilities/BaseReducer";
import NetRevenueByMonthAction from "./NetRevenueByMonthAction";

export default class ArtistwiseRevenueReduser extends BaseReducer {
  initialState = {
    artistwiseRevenue: [],
  };
  [NetRevenueByMonthAction.REQUEST_ARTISTWISE_REVENUE_FINISHED](state, action) {
    return {
      ...state,
      artistwiseRevenue: action.payload,
    };
  }
}
