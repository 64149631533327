import BaseReducer from "../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

// TrackReduser
export default class A3ReportsCountrywiseReducer extends BaseReducer {
  initialState = {
    countrywiseRevenueReport: [],
  };
  [A3ReportsAction.REQUEST_A3REPORTS_COUNTRYWISE_FINISHED](state, action) {
    return {
      ...state,
      countrywiseRevenueReport: action.payload,
    };
  }
}
