import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import TerritoriesAction from "../../stores/territories/TerritoriesAction";
import LoadingIndicator from "../loading-indicator/LoadingIndicator";
import MuiButton from "../muiButton/MuiButton";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TerritoriesAction.REQUEST_TERRITORIES,
  ]),
  territories: state.territories.territories || {},
});

const Territories = (props) => {
  function fetchTerritoriesData() {
    let params = {
      page_limit: "none",
    };
    props.dispatch(TerritoriesAction.requestTerritories(params));
  }

  useEffect(() => {
    fetchTerritoriesData();
  }, []);

  let Africa = [];
  let Antartica = [];
  let Australia = [];
  let Europe = [];
  let Northamerica = [];
  let southamerica = [];
  let Asia = [];

  if (props.territories && props.territories.territory_data) {
    Africa = props.territories.territory_data.AF;
    Antartica = props.territories.territory_data.AN;
    Australia = props.territories.territory_data.OC;
    Europe = props.territories.territory_data.EU;
    Northamerica = props.territories.territory_data.NA;
    southamerica = props.territories.territory_data.SA;
    Asia = props.territories.territory_data.AS;
  }

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    setCheckedItems(
      props.releseData && props.releseData._source
        ? props.releseData._source.territory
          ? props.releseData._source.territory
          : []
        : []
    );
  }, []);

  const handleCheckboxChange = (value) => {
    if (checkedItems.includes(value.country_code)) {
      setCheckedItems(
        checkedItems.filter((item) => item !== value.country_code)
      );
    } else {
      setCheckedItems([...checkedItems, value.country_code]);
    }
  };

  const handleSaveTerritories = () => {
    setLoading(true);
    let Obj = {};

    Object.assign(Obj, checkedItems && { territory: [...checkedItems] });

    props
      .dispatch(
        ReleasesAction.requestPutRelease(
          Obj,
          props.releseData && props.releseData._id
        )
      )
      .then(() => {
        setLoading(false);
        props.dispatch(ReleasesAction.requestReleaseById(props.releseData._id));
      });
  };

  let AsiaIdArray = [];

  Asia &&
    Asia.map((item) => {
      AsiaIdArray.push(item.country_code);
    });

  let AfricaIdArray = [];

  Africa &&
    Africa.map((item) => {
      AfricaIdArray.push(item.country_code);
    });

  let AntarticaIdArray = [];

  Antartica &&
    Antartica.map((item) => {
      AntarticaIdArray.push(item.country_code);
    });

  let AustraliaIdArray = [];

  Australia &&
    Australia.map((item) => {
      AustraliaIdArray.push(item.country_code);
    });

  let EuropeIdArray = [];

  Europe &&
    Europe.map((item) => {
      EuropeIdArray.push(item.country_code);
    });

  let NorthamericaIdArray = [];

  Northamerica &&
    Northamerica.map((item) => {
      NorthamericaIdArray.push(item.country_code);
    });

  let southamericaIdArray = [];

  southamerica &&
    southamerica.map((item) => {
      southamericaIdArray.push(item.country_code);
    });

  let removed_asian_array = [
    ...AfricaIdArray,
    ...AntarticaIdArray,
    ...AustraliaIdArray,
    ...EuropeIdArray,
    ...NorthamericaIdArray,
    ...southamericaIdArray,
  ];

  const checkAllAsiaCountries = () => {
    setCheckedItems([...checkedItems, ...AsiaIdArray]);
  };

  const UncheckAllAsiaCountries = () => {
    const newArray = checkedItems.filter((item) => !AsiaIdArray.includes(item));

    setCheckedItems(newArray);
  };

  const checkAllEuropeCountries = () => {
    setCheckedItems([...checkedItems, ...EuropeIdArray]);
  };

  const UncheckAllEuropeCountries = () => {
    const newArray = checkedItems.filter(
      (item) => !EuropeIdArray.includes(item)
    );

    setCheckedItems(newArray);
  };

  const checkAllAfricaCountries = () => {
    setCheckedItems([...checkedItems, ...AfricaIdArray]);
  };

  const UncheckAllAfricaCountries = () => {
    const newArray = checkedItems.filter(
      (item) => !AfricaIdArray.includes(item)
    );

    setCheckedItems(newArray);
  };

  const checkAllAustraliaCountries = () => {
    setCheckedItems([...checkedItems, ...AustraliaIdArray]);
  };

  const UncheckAllAustraliaCountries = () => {
    const newArray = checkedItems.filter(
      (item) => !AustraliaIdArray.includes(item)
    );

    setCheckedItems(newArray);
  };

  const checkAllAntarticaCountries = () => {
    setCheckedItems([...checkedItems, ...AntarticaIdArray]);
  };

  const UncheckAllAntarticaCountries = () => {
    const newArray = checkedItems.filter(
      (item) => !AntarticaIdArray.includes(item)
    );

    setCheckedItems(newArray);
  };

  const checkAllNorthamericaCountries = () => {
    setCheckedItems([...checkedItems, ...NorthamericaIdArray]);
  };

  const UncheckAllNorthamericaCountries = () => {
    const newArray = checkedItems.filter(
      (item) => !NorthamericaIdArray.includes(item)
    );

    setCheckedItems(newArray);
  };

  const checkAllSouthamericaCountries = () => {
    setCheckedItems([...checkedItems, ...southamericaIdArray]);
  };

  const UncheckAllSouthamericaCountries = () => {
    const newArray = checkedItems.filter(
      (item) => !southamericaIdArray.includes(item)
    );

    setCheckedItems(newArray);
  };

  const [loading, setLoading] = useState(false);

  function renderCountryFlag(countryCode) {
    return (
      <Typography
        component={"img"}
        width="20"
        alt=""
        src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
      />
    );
  }

  return (
    <Box width={"100%"}>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        className="d-flex justify-content-end"
      >
        <Grid item lg={1} md={1} sm={1}>
          <MuiButton
            name={"Save"}
            width="100%"
            onClick={() => {
              handleSaveTerritories();
            }}
            loading={loading}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xl={12}
        lg={12}
        md={12}
        sm={12}
        xs={12}
        // className="bg-warning"
        justifyContent={"center"}
        alignItems="flex-start"
      >
        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={{
            borderRight: `1px dashed black`,
          }}
          px={0.5}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Asia
          </span>

          <br />

          <span>
            <span
              onClick={() => {
                checkAllAsiaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Check All
            </span>{" "}
            /
            <span
              onClick={() => {
                UncheckAllAsiaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              UnCheck All
            </span>
          </span>
          <br />

          {Asia &&
            Asia.length !== 0 &&
            Asia.map((item) => {
              return (
                <Grid item xl={12}>
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.country_code)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                    label={
                      <span>
                        {renderCountryFlag(item.country_code)}{" "}
                        {item.country_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </span>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>

        <Grid
          item
          xl={2}
          lg={2}
          md={2}
          sm={2}
          xs={2}
          style={{
            borderRight: `1px dashed black`,
          }}
          px={0.5}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Europe
          </span>

          <br />

          <span>
            <span
              onClick={() => {
                checkAllEuropeCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Check All
            </span>{" "}
            /
            <span
              onClick={() => {
                UncheckAllEuropeCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              UnCheck All
            </span>
          </span>
          <br />
          {Europe &&
            Europe.length !== 0 &&
            Europe.map((item) => {
              return (
                <Grid item xl={12}>
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.country_code)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                    label={
                      <span>
                        {renderCountryFlag(item.country_code)}{" "}
                        {item.country_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </span>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>

        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          style={{
            borderRight: `1px dashed black`,
          }}
          px={0.5}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Africa
          </span>

          <br />

          <span>
            <span
              onClick={() => {
                checkAllAfricaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Check All
            </span>{" "}
            /
            <span
              onClick={() => {
                UncheckAllAfricaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              UnCheck All
            </span>
          </span>
          <br />
          {Africa &&
            Africa.length !== 0 &&
            Africa.map((item) => {
              return (
                <Grid item xl={12}>
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.country_code)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                    label={
                      <span>
                        {renderCountryFlag(item.country_code)}{" "}
                        {item.country_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </span>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
        <Grid
          item
          xl={3}
          lg={3}
          md={3}
          sm={3}
          xs={3}
          style={{
            borderRight: `1px dashed black`,
          }}
          px={0.5}
        >
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Australia
          </span>

          <br />

          <span>
            <span
              onClick={() => {
                checkAllAustraliaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Check All
            </span>{" "}
            /
            <span
              onClick={() => {
                UncheckAllAustraliaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              UnCheck All
            </span>
          </span>
          <br />

          {Australia &&
            Australia.length !== 0 &&
            Australia.map((item) => {
              return (
                <Grid item xl={12}>
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.country_code)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                    label={
                      <span>
                        {renderCountryFlag(item.country_code)}{" "}
                        {item.country_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </span>
                    }
                  />
                </Grid>
              );
            })}

          <br />

          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Antartica
          </span>

          <br />

          <span>
            <span
              onClick={() => {
                checkAllAntarticaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Check All
            </span>{" "}
            /
            <span
              onClick={() => {
                UncheckAllAntarticaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              UnCheck All
            </span>
          </span>
          <br />

          {Antartica &&
            Antartica.length !== 0 &&
            Antartica.map((item) => {
              return (
                <Grid item xl={12}>
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.country_code)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                    label={
                      <span>
                        {renderCountryFlag(item.country_code)}{" "}
                        {item.country_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </span>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={2} px={0.5}>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Northamerica
          </span>

          <br />

          <span>
            <span
              onClick={() => {
                checkAllNorthamericaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Check All
            </span>{" "}
            /
            <span
              onClick={() => {
                UncheckAllNorthamericaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              UnCheck All
            </span>
          </span>
          <br />
          {Northamerica &&
            Northamerica.length !== 0 &&
            Northamerica.map((item) => {
              return (
                <Grid item xl={12}>
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.country_code)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                    label={
                      <span>
                        {renderCountryFlag(item.country_code)}{" "}
                        {item.country_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </span>
                    }
                  />
                </Grid>
              );
            })}

          <br />

          <span
            style={{
              fontWeight: "bold",
            }}
          >
            Southamerica
          </span>

          <br />

          <span>
            <span
              onClick={() => {
                checkAllSouthamericaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              Check All
            </span>{" "}
            /
            <span
              onClick={() => {
                UncheckAllSouthamericaCountries();
              }}
              style={{
                cursor: "pointer",
              }}
            >
              UnCheck All
            </span>
          </span>
          <br />

          {southamerica &&
            southamerica.length !== 0 &&
            southamerica.map((item) => {
              return (
                <Grid item xl={12}>
                  <FormControlLabel
                    key={item}
                    control={
                      <Checkbox
                        checked={checkedItems.includes(item.country_code)}
                        onChange={() => handleCheckboxChange(item)}
                      />
                    }
                    label={
                      <span>
                        {renderCountryFlag(item.country_code)}{" "}
                        {item.country_name
                          .replace(/_/g, " ")
                          .replace(/\b\w/g, (c) => c.toUpperCase())}
                      </span>
                    }
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </Box>
  );
};

export default connect(mapStateToProps)(Territories);
