import RevenueReportAction from "./RevenueReportAction";
import BaseReducer from "../../utilities/BaseReducer";

export default class FilterRevenueReportReducer extends BaseReducer {
  initialState = {
    revenueReportFilter: [],
  };
  [RevenueReportAction.REQUEST_FILTER_REVENUE_REPORT_FINISHED](state, action) {
    return {
      ...state,
      filterRevenueReport: action.payload,
    };
  }

  //   [RevenueReportAction.REQUEST_REVENUE_REPORT_FILTER_CLEAR_FINISHED](
  //     state,
  //     action
  //   ) {
  //     return {
  //       ...state,
  //       filterRevenueReport: action.payload,
  //     };
  //   }
}
