import { BaseModel } from "sjs-base-model";

// TopTrackModel
export default class TopTrackWithDateFilterModel extends BaseModel {
  // response getting from the api mentioned end point
  Top_tracks_for_given_dates = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
