export function getRandomColor(alpha = 1) {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }

  // Make the color lighter by blending it with white
  const lighterColor = blendColors(color, "#FFFFFF", 0.5);

  // Combine the lighter color with the alpha value
  return `${lighterColor}${Math.round(alpha * 255).toString(16)}`;
}

// Function to blend two colors with a given ratio
export function blendColors(color1, color2, ratio) {
  const hex = (c) => parseInt(c, 16);
  const blend = (a, b, ratio) => Math.round((1 - ratio) * a + ratio * b);

  const r1 = hex(color1.substring(1, 3));
  const g1 = hex(color1.substring(3, 5));
  const b1 = hex(color1.substring(5, 7));

  const r2 = hex(color2.substring(1, 3));
  const g2 = hex(color2.substring(3, 5));
  const b2 = hex(color2.substring(5, 7));

  const blendedR = blend(r1, r2, ratio);
  const blendedG = blend(g1, g2, ratio);
  const blendedB = blend(b1, b2, ratio);

  return `#${blendedR.toString(16)}${blendedG.toString(16)}${blendedB.toString(
    16
  )}`;
}
