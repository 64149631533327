import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import MainNav from "../components/main-nav/MainNav";
import { useDispatch } from "react-redux";
import Toasts from "../components/toasts/Toasts";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthAction from "../stores/Auth/AuthAction";
import HttpErrorResponseModel from "../models/HttpErrorResponseModel";
import "../App.css";
//CUSTOM THEME CREATION
export const theme = createTheme({
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          border: "1px solid #2a5e6c",
        },
      },
    },
  },

  palette: {
    primary: {
      main: "#03595a",
      contrastText: "#e5e2e2",
      dark: "#000000",
      light: "#9dc0c9",
    },
    secondary: {
      main: "#e93017",
      light: "#f79f8a",
    },
    common: {
      grey: "#d9d9d9",
    },
    info: {
      main: "#4e5c5e",
    },
    appbar: {
      icons: "#a0bfc9",
    },

    text: {
      primary: "#03595a",
      light: "#9dc0c9",
      dark: "#707070",
      gray: "#cccccc",
      first_letter: "#d8aa4f",
      imageBox: "#cce2e7",
      white: "white",
      error: "#f26d4e",
      form: "#585f5f",
    },

    field: {
      height: "33px",
    },

    divider: "#e2e2e2",

    borderColor: {
      textField: "#a0bfc9",
      imageBox: "#e7eef0",
      uploadButtons: "#6bc7ce",
      uploadAssetButton: "#bebec0",
    },

    background: {
      main: "#2a5e6c",
      primary: "#0c8089",
      default: "#f3f9fb",
      paper: "#ffffff",
      button: "#d6ebf0",
      comman: "#797676",
      border: "#e5e5ea",
      light: "#d8eaef",
      drawerMenu: "#0b6e76",
      imageBox: "#ecf7fa",
      tab: "#bfe1e5",
      error: "#fcd3ca",
      music: "#91bcbf",
      button: "#d0e9eb",
    },

    dashboardCharts: [
      "#44a076",
      "#c67969",
      "#c19c58",
      "#38808e",
      "#38808e",
      "#4175a8",
      "#08c7c4",
      "#969393",
    ],

    // Datagrid
    datagrid: {
      background: {
        datagridCell: "#ffffff",
        datagridHeader: "#bee1e5",
        hover: "#eeeeee",
        duplicate: "#dbe7eb",
        duplicateHover: "#91bcbf",
      },
      Columns: {
        fontSize: "14px",
      },
    },

    calendar: {
      background: {
        header: "#d0dfe3",
        body: "#f3f9fb",
      },
    },

    addButton: {
      height: "40px",
      width: "40px",
      borderRadius: "55%",
    },

    //Button Component

    buttonComponent: {
      background: {
        cancel: "#d8aa4f",
        primary: "#57828e",
        success: "#35C37D",
        error: "#ff4444",
      },
      hover: {
        primary: "#0b2e36",
        success: "#035029",
        error: "#CC0000",
      },
      color: "#d6ebf0",
      border: "solid 1px #eaeaea",
      borderRadius: {
        small: "5px",
        medium: "10px",
        large: "12px",
      },
      fontSize: {
        small: "10px",
        medium: "12px",
        large: "14px",
        extraLarge: "16px",
      },
      paddingX: {
        small: "10px",
        medium: "20px",
        large: "30px",
      },
      width: {
        small: "100px",
        medium: "150px",
        large: "200px",
      },
      // borderRadius: {
      //   small: "8px",
      //   medium: "16px",
      // },
    },

    dashboard: {
      red: "#f79f8a",
      yellow: "#ffe203",
      green: "#29c060",
      lightGreen: "#06f234",
      timeText: "#bfbfbf",
      lightTeal: "#a0bfc9",
      icons: "#899da2",
    },
  },

  table: {
    header: "#e4ecef",
    rowColor: "rgba(54, 121, 137, 0.08)",
    rowText: "#39717c",
    border: "#e4ecef",
  },

  reports: {
    gridBorder: "#e5e2e2",
  },

  pieChart: {
    color1: "#fc8d72",
    color2: "#22bcba",
    color3: "#cae5e1",
    color4: "#b8c7c0",
    color5: "#41808e",
    color6: "#a69bac",
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
    },
  },

  typography: {
    fontFamily: "BookAntiqua",
    fontWeightRegular: "normal",
    // fontSize: "14px",
    fontWeightBold: "bold",
    fontStyle: "normal",
    fontStretch: "normal",

    h2: {
      fontSize: "28px",
      fontFamily: "BookAntiqua",
    },

    h3: {
      fontSize: "24px",
      fontFamily: "BookAntiqua",
    },

    h1: {
      fontSize: "31px",
      fontFamily: "BookAntiqua",
    },
    card: {
      fontSize: "36px",
      fontFamily: "BookAntiqua",
    },
    cardstream: {
      fontSize: "15px",
      fontFamily: "BookAntiqua",
    },
    button: {
      fontWeight: 500,
      fontSize: "0.875rem",
      textTransform: "uppercase",
      border: "solid 1px #eaeaea",
      borderRadius: "3px",
      color: "#d6ebf0",
    },
    subtitle1: {
      fontFamily: "BookAntiqua",
      fontSize: "18px",
    },
    appIcon: {
      width: 18,
      height: 18,
    },
    body1: {
      fontSize: "14px",
    },
    padding: 0,
  },
  shadows: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
  customer_popover_shadows: `0 3px 2px 0 rgba(0, 0, 0, 0.16)`,
  shape: { borderRadius: 3 },
  border: "1px solid #f2f2f2",
  borderRadius: "8px",
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          ":-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
            backgroundColor: "red !important",
          },
        },
      },
    },

    // Name of the component
    MuiDataGrid: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
      cell: {
        background: "red !important",
        content: "hello!",
        padding: 0,
      },
    },

    //Mui Form control style overrides

    MuiFormControl: {
      styleOverrides: {
        fullWidth: true,
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root": {
            outline: "none",
            backgroundColor: "#fff",
            fontSize: "14px",
            borderRadius: "5px",
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #2a5e6c",
              outline: "none",
            },
          },
          ".MuiAutocomplete-tagSizeMedium": {
            height: "fit-content",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          outline: "solid 1px #a0bfc9",
          backgroundColor: "#fff",
          fontSize: "14px",
          borderRadius: "5px",
          // ".MuiSelect-select":{
          //   border:"1.5px solid 2a5e6c"
          // }
        },
      },
    },
  },
});

export default function App() {
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const init = async () => {
    await dispatch(AuthAction.InitLogin()).then((model) => {
      const isError = model instanceof HttpErrorResponseModel;
    });
  };

  useEffect(() => {
    init();
  }, [init]);

  const [online, setOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setOnline(true);
    const handleOffline = () => setOnline(false);
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [navigator.onLine]);

  console.log("ONLINE", online);

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <MainNav online={online} />
          <Toasts />
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}
