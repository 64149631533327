import BaseReducer from "../../utilities/BaseReducer";
import UserResourceAction from "./UserResourceAction";

export default class UserResourceFilterReducer extends BaseReducer {
  initialState = {
    userResourceFilter: [],
  };
  [UserResourceAction.REQUEST_USER_RESOURCE_FILTER_FINISHED](state, action) {
    return {
      ...state,
      userResourceFilter: action.payload,
    };
  }

  [UserResourceAction.REQUEST_USER_RESOURCE_FILTER_CLEAR_FINISHED](
    state,
    action
  ) {
    return {
      ...state,
      userResourceFilter: action.payload,
    };
  }
}
