import BaseReducer from "../../utilities/BaseReducer";
import A3ReportsAction from "./A3ReportsAction";

// TrackReduser
export default class A3ReportsReleaseReducer extends BaseReducer {
  initialState = {
    clientReleaseReport: [],
  };
  [A3ReportsAction.REQUEST_A3REPORTS_RELEASE_FINISHED](state, action) {
    return {
      ...state,
      clientReleaseReport: action.payload,
    };
  }
}
