import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
import { Grid, IconButton, useTheme, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndustry, faTrash } from "@fortawesome/free-solid-svg-icons";
import AlbumIcon from "@mui/icons-material/Album";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import PublicIcon from "@mui/icons-material/Public";
import DialogContent from "@mui/material/DialogContent";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import DialogTitle from "@mui/material/DialogTitle";
import TerritoriesAction from "../../../stores/territories/TerritoriesAction";
import MuiButton from "../../../components/muiButton/MuiButton";
import PostPlusButton from "../../../components/postPlusButton/PostPlusButton";
import AutoCompleteComponent from "../../../components/autoCompleteComponent/Autocomplete";
import GenreAction from "../../../stores/genre/GenreAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import { theme } from "../../App";
import { makeStyles } from "@material-ui/core";
import { Palette } from "@mui/icons-material";
import TopTerritoriesAction from "../../../stores/TopTerritories/TopTerritoriesAction";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TopTerritoriesAction.REQUEST_TOP_TERRITORIES,
  ]),
  top_territories_report:
    state.top_territories_report.top_territories_report || [],
});

function TopTerritories(props) {
  const theme = useTheme();
  const { isRequesting } = props;
  const navigate = useNavigate();

  const [editRowsModel, setEditRowsModel] = useState({});
  const [page_limit] = useState(15);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("body");
  const [title, setTitle] = useState(null);
  const [FilterClicked, setFilterClicked] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [clearLoader, setClearLoader] = useState(false);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchTopTerritories(params);
  }, []);

  function fetchTopTerritories(params) {
    props.dispatch(TopTerritoriesAction.requestTopTerritories(params));
  }

  const openCloseModal = useCallback((open) => {
    setOpen(open);
  }, []);

  const editMode = useCallback((edit) => {
    setEdit(false);
  }, []);

  const genreList = [];
  if (props.genre && props.genre.hits) {
    props.genre.hits.hits &&
      props.genre.hits.hits.length > 0 &&
      props.genre.hits.hits.map((genre) => {
        genreList.push({
          genre_id: genre._id,
          genre_name: genre._source.genre_name,
        });
      });
  }

  const titleList = [];

  props?.releaseFilter?.hits?.hits?.map((item) => {
    titleList.push(item._source.title);
  });

  const TitleListHandleChange = (event, title) => {
    if (title != null) {
      setTitle(title);
    } else {
      setTitle("");
    }
  };

  const rows = [];

  props?.top_territories_report?.top_territories?.map((item, index) => {
    rows.push({
      sl_no: ++index,
      id: ++index,
      count: item.count,

      percent: item.percentage.toFixed(2),
      country_name: item.key ? item.key.country_name : "",
    });
  });

  //Columns
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 80,
    },

    {
      // width: 140,
      flex: 1,
      field: "country_name",
      headerName: "Country name",
    },

    {
      width: 280,
      field: "count",
      headerName: "Audio Stream",
    },

    {
      // width: 140,
      flex: 1,
      field: "percent",
      headerName: "Stream Percentage",
    },
  ];

  const handleChange = (event, value) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={80}
                isRequesting={isRequesting}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(TopTerritories);
