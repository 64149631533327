import BaseReducer from "../../utilities/BaseReducer";
import TrackRevenueAction from "./TrackRevenueAction";

export default class TrackRevenueReducer extends BaseReducer {
  initialState = {
    trackRevenue: [],
  };
  [TrackRevenueAction.REQUEST_TRACK_REVENUE_FINISHED](state, action) {
    return {
      ...state,
      trackRevenue: action.payload,
    };
  }

  [TrackRevenueAction.REQUEST_TRACK_REVENUE_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      trackRevenue: action.payload,
    };
  }
}
