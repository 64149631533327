import { BaseModel } from "sjs-base-model";

// SyncPlaylinkModel
export default class SyncPlaylinkModel extends BaseModel {
  status = "";
  message = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
