import BaseReducer from "../../utilities/BaseReducer";
import ActorAction from "./ActorAction";

// ActorReuser
export default class ActorReducer extends BaseReducer {
  initialState = {
    actor: [],
  };
  [ActorAction.REQUEST_ACTOR_FINISHED](state, action) {
    return {
      ...state,
      actor: action.payload,
    };
  }

  [ActorAction.REQUEST_ACTOR_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      actor: action.payload,
    };
  }
}
