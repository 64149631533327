import ActionUtility from "../../utilities/ActionUtility";
import NetRevenueByMonthEffect from "./NetRevenueByMonthEffect";

export default class NetRevenueByMonthAction {
  // NetRevenueByMonthAction action with an function declaration
  static REQUEST_NET_REVENUE_BY_MONTH =
    "NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH";
  static REQUEST_NET_REVENUE_BY_MONTH_FINISHED =
    "NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FINISHED";

  static REQUEST_NET_REVENUE_BY_MONTH_FILTER =
    "NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER";
  static REQUEST_NET_REVENUE_BY_MONTH_FILTER_FINISHED =
    "NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER_FINISHED";

  static REQUEST_NET_REVENUE_BY_MONTH_FILTER_CLEAR =
    "NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER_CLEAR";
  static REQUEST_NET_REVENUE_BY_MONTH_FILTER_CLEAR_FINISHED =
    "NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER_CLEAR_FINISHED";

  // TrackwiseRevenue action with an function declaration
  static REQUEST_TRACKWISE_REVENUE =
    "NetRevenueByMonthAction.REQUEST_TRACKWISE_REVENUE";
  static REQUEST_TRACKWISE_REVENUE_FINISHED =
    "NetRevenueByMonthAction.REQUEST_TRACKWISE_REVENUE_FINISHED";

  // ReleasewiseRevenue action with an function declaration
  static REQUEST_RELEASEWISE_REVENUE =
    "NetRevenueByMonthAction.REQUEST_RELEASEWISE_REVENUE";
  static REQUEST_RELEASEWISE_REVENUE_FINISHED =
    "NetRevenueByMonthAction.REQUEST_RELEASEWISE_REVENUE_FINISHED";

  // ReleasewiseRevenue action with an function declaration
  static REQUEST_ARTISTWISE_REVENUE =
    "NetRevenueByMonthAction.REQUEST_ARTISTWISE_REVENUE";
  static REQUEST_ARTISTWISE_REVENUE_FINISHED =
    "NetRevenueByMonthAction.REQUEST_ARTISTWISE_REVENUE_FINISHED";

  // METHODS
  static requestNetRevenueByMonth(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH,
        NetRevenueByMonthEffect.requestNetRevenueByMonth,
        params
      );
    };
  }

  static requestNetRevenueByMonthFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER,
        NetRevenueByMonthEffect.requestNetRevenueByMonthFilter,
        params
      );
    };
  }

  static requestNetRevenueByMonthFilterClear(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER_CLEAR,
        NetRevenueByMonthEffect.requestNetRevenueByMonthFilterClear,
        params
      );
    };
  }

  // METHODS
  static requestTrackwiseRevenue(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NetRevenueByMonthAction.REQUEST_TRACKWISE_REVENUE,
        NetRevenueByMonthEffect.requestTrackwiseRevenue,
        params
      );
    };
  }

  static requestReleasewiseRevenue(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NetRevenueByMonthAction.REQUEST_RELEASEWISE_REVENUE,
        NetRevenueByMonthEffect.requestReleasewiseRevenue,
        params
      );
    };
  }

  static requestArtistwiseRevenue(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        NetRevenueByMonthAction.REQUEST_ARTISTWISE_REVENUE,
        NetRevenueByMonthEffect.requestArtistwiseRevenue,
        params
      );
    };
  }
}
