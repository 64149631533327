import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography, Stack, Paper } from "@mui/material";
import { connect } from "react-redux";
import NetRevenueByMonthAction from "../../stores/netRevenueReportByMonth/NetRevenueByMonthAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import { theme } from "../App";
import { Bar, Doughnut } from "react-chartjs-2";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import { getRandomColor } from "./ReportsReusedFunctions";
import ReactEcharts from "echarts-for-react";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    NetRevenueByMonthAction.REQUEST_ARTISTWISE_REVENUE,
  ]),
  artistwiseRevenue: state.artistwiseRevenue.artistwiseRevenue || [],
});

function ArtistwiseRevenue(props) {
  const { isRequesting, reportViewType } = props;
  const [alertPopUp, setAlertPopUp] = useState(false);

  function requestArtistwiseRevenue(params) {
    props.dispatch(NetRevenueByMonthAction.requestArtistwiseRevenue(params));
  }

  let clientList = [];
  props?.clientFilter?.hits?.hits?.map((item) => {
    if (item?._source?.client_name)
      clientList.push({
        id: item?._id,
        client_name: item._source.client_name,
      });
  });

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
      hide: true,
    },

    {
      field: "key",
      headerName: `Artist Name`,
      flex: 3,
    },
    {
      field: "revenue",
      headerName: "Revenue ($)",
      flex: 3,
    },
    {
      field: "percentage",
      headerName: "Percentage (%)",
      flex: 3,
    },
  ];

  const rows = [];
  if (props.artistwiseRevenue?.artist_wise_revenue) {
    props.artistwiseRevenue?.artist_wise_revenue?.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: index + 1,
        revenue: item.revenue,
        key: item.key,
        percentage: item.percentage,
      });
    });
  }

  const colors = [
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
    `${theme.pieChart.color3}`,
    `${theme.pieChart.color4}`,
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color6}`,
  ];

  let countData = [];
  let nameLabels = [];
  let percentlables = [];

  nameLabels = rows?.map((item) => {
    return item.key;
  });

  countData = rows?.map((item) => {
    return item.revenue;
  });

  let percentagedata = [];

  percentagedata = rows.map((item) => {
    return Number(item.percentage).toFixed(2);
  });

  countData &&
    countData.map((item, index) =>
      index < colors.length ? null : colors.push(getRandomColor())
    );

  const state = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        type: "bar", // Change type to 'bar' for a vertical bar chart
        label: nameLabels && nameLabels,
        data: countData && countData,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const getOptions = () => {
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    const seriesData = rows.map((item, index) => ({
      name: item.key,
      value: item.revenue,
      percentage: item.percentage,
      itemStyle: {
        color: colors[index],
      },
    }));
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val);
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.name}: ${formattedvalue} (${params.data.percentage}%)`;
        },
      },
      legend: {
        orient: "vertical",
        icon: "circle", // Set the orientation of the legend=        icon: "circle",
        right: 10,
        top: "15%",
        type: "scroll",
        itemGap: 20,
        itemStyle: {
          borderColor: "#6c757d",
          borderWidth: 1,
        },
        itemHeight: 17,
      },
      grid: {
        top: "10%",
        left: "25%",
        right: "25%",
        bottom: "10%",
      },
      xAxis: {
        type: "category",
        data: nameLabels,
        axisLabel: {
          hideOverlap: true,
        },
      },

      yAxis: {
        type: "value",
        axisLabel: {
          hideOverlap: true,
        },
      },
      series: [
        {
          type: "bar",

          data: seriesData,
          emphasis: {
            focus: "series",
          },

          label: {
            show: false,
          },
        },
      ],
    };
  };

  useEffect(() => {
    let params = {
      youtube: true,
      current_page: "raw_report",
    };
    requestArtistwiseRevenue(params);
  }, []);

  const data = props.artistwiseRevenue;

  return (
    <React.Fragment>
      {navigator.onLine ? (
        <Grid
          container
          item
          xs={12}
          xl={12}
          lg={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          px={5}
          gap={3}
        >
          <Grid
            item
            sx={{
              fontSize: "24px",
            }}
          >
            <Grid item>
              <span style={{ fontWeight: "bold" }}>
                {formatName("Top Artistwise Revenue")} - Audio Streaming
              </span>
              {/* <span>|</span> */}
              <span
                style={{
                  color: "grey",
                }}
              ></span>
            </Grid>
          </Grid>

          <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />

          <LoadingIndicator isActive={isRequesting}>
            {!isRequesting && props.artistwiseRevenue ? (
              <>
                {reportViewType === "grid" ? (
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={60}
                    height={"74vh"}
                  />
                ) : data?.artist_wise_revenue?.length ? (
                  <Typography
                    sx={{
                      width: { lg: "75%", md: "90%" },
                      height: "inherit",
                      marginLeft: "auto",
                      marginRight: "auto",
                      animation: "popIn 0.4s ease-in-out",
                      border: "2px solid rgba(0, 0, 0, 0.1)",
                      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                    }}
                    className="justify-content-center align-items-center"
                  >
                    <div style={{ marginLeft: "10%" }}>
                      <ReactEcharts
                        style={{
                          height: "25vh",
                          width: "100%",
                        }}
                        option={getOptions()}
                      />
                    </div>
                  </Typography>
                ) : (
                  <NoDataFound />
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Please select a filter first"}
      />
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(ArtistwiseRevenue);
