import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import React from "react";
import { theme } from "../../App";
import Rettrive from "../../releases/Rettrive";
import { useNavigate } from "react-router";

const LatestDraftsBox = ({ latestDraftsList }) => {
  function convertDate(item) {
    const dateObj = new Date(item);

    const day = dateObj.getDate();
    const monthNames = new Intl.DateTimeFormat("en", { month: "short" }).format;
    const monthName = monthNames(dateObj);

    const dayWithSuffix = getDayWithSuffix(day);
    return item ? `${dayWithSuffix}\n${monthName}` : "";
  }
  let navigate = useNavigate();

  function getDayWithSuffix(day) {
    const suffix =
      day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";
    return `${day}${suffix}`;
  }

  return (
    <>
      {latestDraftsList?.map((item) => (
        <Card
          sx={{ width: "220px", cursor: "pointer" }}
          onClick={() => {
            localStorage.setItem("id", item._id);
            navigate("/releaseInfo");
          }}
        >
          <CardContent>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  boxShadow: theme.shadows,
                  borderRadius: 1,
                }}
              >
                <Box
                  sx={{
                    height: "100px",
                    display: "flex",
                    width: "50%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Rettrive
                    thumbnail_url={item?._source?.thumbnail_url}
                    release_id={item._id}
                    current_page={"dashboard"}
                  />
                </Box>
                <Box
                  sx={{
                    height: "100px",
                    width: "50%",
                    alignItems: "center",
                    padding: 1,
                    overflow: "hidden",
                  }}
                >
                  {item?._source?.physical_release_date && (
                    <Typography sx={{ fontSize: 10, textAlign: "center" }}>
                      Release Date
                    </Typography>
                  )}
                  <Typography
                    sx={{
                      fontSize: 35,
                      fontWeight: "bold",
                      whiteSpace: "pre-line",
                      textAlign: "center",
                      lineHeight: 1,
                    }}
                  >
                    {convertDate(item?._source?.physical_release_date)}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  paddingY: 1,
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "capitalize",
                  }}
                >
                  {item?._source?.title}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default LatestDraftsBox;
