import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LogoutAction from "../../stores/logout/LogoutAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [LogoutAction.REQUEST_LOGOUT]),
});

function Logout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let auth = JSON.parse(localStorage.getItem("Auth"));
    let access_token = auth && auth.access_token;
    let refresh_token = auth && auth.refresh_token;

    dispatch(LogoutAction.requestLogout(access_token, refresh_token)).then(
      () => {
        // navigate("/", { replace: true });
        localStorage.removeItem("Auth");
        localStorage.clear();
        window.location.reload();
      }
    );
  }, []);

  return null;
}

export default connect(mapStateToProps)(Logout);
