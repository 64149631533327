import { BaseModel } from "sjs-base-model";

export default class ReleasePostModel extends BaseModel {
  status = "";
  data = {};
  id = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
