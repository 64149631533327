import RevenueClaimEffect from "./RevenueClaimEffect";
import ActionUtility from "../../utilities/ActionUtility";

export default class RevenueClaimAction {
  static REQUEST_REVENUE_CLAIM = "RevenueClaimAction.REQUEST_REVENUE_CLAIM";
  static REQUEST_REVENUE_CLAIM_FINISHED =
    "RevenueClaimAction.REQUEST_REVENUE_CLAIM_FINISHED";

  static REQUEST_REVENUE_CLAIM_FILTER =
    "RevenueClaimAction.REQUEST_REVENUE_CLAIM_FILTER";
  static REQUEST_REVENUE_CLAIM_FILTER_FINISHED =
    "RevenueClaimAction.REQUEST_REVENUE_CLAIM_FILTER_FINISHED";

  static REQUEST_CLIENT_REVENUE_BALANCE =
    "RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE";
  static REQUEST_CLIENT_REVENUE_BALANCE_FINISHED =
    "RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE_FINISHED";

  static REQUEST_REVENUE_CLAIM_POST =
    "RevenueClaimAction.REQUEST_REVENUE_CLAIM_POST";
  static REQUEST_REVENUE_CLAIM_POST_FINISHED =
    "RevenueClaimAction.REQUEST_REVENUE_CLAIM_POST_FINISHED";

  static REQUEST_GENERATE_CLIENT_INVOICE =
    "RevenueClaimAction.REQUEST_GENERATE_CLIENT_INVOICE";
  static REQUEST_GENERATE_CLIENT_INVOICE_FINISHED =
    "RevenueClaimAction.REQUEST_GENERATE_CLIENT_INVOICE_FINISHED";

  // METHODS
  static requestRevenueClaim(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_REVENUE_CLAIM,
        RevenueClaimEffect.requestRevenueClaim,
        params
      );
    };
  }

  static requestRevenueClaimFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_REVENUE_CLAIM_FILTER,
        RevenueClaimEffect.requestRevenueClaimFilter,
        params
      );
    };
  }

  static requestClientRevenueBalance() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_CLIENT_REVENUE_BALANCE,
        RevenueClaimEffect.requestClientRevenueBalance
      );
    };
  }

  static requestRevenueClaimPost(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_REVENUE_CLAIM_POST,
        RevenueClaimEffect.requestRevenueClaimPost,
        data
      );
    };
  }

  static requestGenerateClientInvoice(data, id, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        RevenueClaimAction.REQUEST_GENERATE_CLIENT_INVOICE,
        RevenueClaimEffect.requestGenerateClientInvoice,
        data,
        id,
        params,
        callBack
      );
    };
  }
}
