import { BaseModel } from "sjs-base-model";

export default class TopArtistModel extends BaseModel {
  top_artists = [];
  constructor(data1) {
    super();

    this.update(data1);
  }
}
