import { BaseModel } from "sjs-base-model";

// AssetsPostModel
export default class AssetsPostModel extends BaseModel {
  id = "";
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
