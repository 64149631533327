import React, { useEffect } from "react";
import { Box, Grid, ListItem, Paper, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { theme } from "../../views/App";
import { ShadowContainer } from "../../components/componentLayout/ComponentLayout";
import { useNavigate } from "react-router";

// mapstateto props function
const mapDispatchToProps = (dispatch) => {
  return {};
};

function DeliveredReleaseMorePopper(props) {
  const [open, setOpen] = React.useState(false);
  const morePopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const navigate = useNavigate();

  // return focus to the button when we transitioned from !open -> open
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      morePopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (morePopperRef.current && morePopperRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Grid container>
      <Typography
        component={"div"}
        ref={morePopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>

      <Popper
        open={open}
        anchorEl={morePopperRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
              transform: open ? "translateX(-200px)" : "none",
            }}
          >
            <ShadowContainer borderRadius={5}>
              <Paper
                style={{
                  width: "auto",
                  minWidth: 200,
                  height: "auto",
                  padding: 2,
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <div
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                  >
                    {props.options &&
                      props.options.length > 0 &&
                      props.options.map((option) => (
                        <ListItem
                          sx={{
                            cursor: "pointer",
                            "&:hover": {
                              backgroundColor: theme.palette.background.default,
                            },
                          }}
                          onClick={(event) => {
                            event.ignore = true;
                            option === "View Details" &&
                              navigate("/deliveredRelease", {
                                state: {
                                  releaseId: props.releaseId,
                                },
                              });
                          }}
                        >
                          {option}
                        </ListItem>
                      ))}
                  </div>
                </ClickAwayListener>
              </Paper>
            </ShadowContainer>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}

export default connect(mapDispatchToProps)(DeliveredReleaseMorePopper);
