import ActionUtility from "../../utilities/ActionUtility";
import TrackEffect from "./TrackEffect";

export default class TrackAction {
  // TrackAction action with an function declaration
  static REQUEST_TRACK = "TrackAction.REQUEST_TRACK";
  static REQUEST_TRACK_FINISHED = "TrackAction.REQUEST_TRACK_FINISHED";

  static REQUEST_TRACK_BY_ID = "TrackAction.REQUEST_TRACK_BY_ID";
  static REQUEST_TRACK_BY_ID_FINISHED =
    "TrackAction.REQUEST_TRACK_BY_ID_FINISHED";

  static REQUEST_SYNC_TRACK_PLAYLINK =
    "TrackAction.REQUEST_SYNC_TRACK_PLAYLINK";
  static REQUEST_SYNC_TRACK_PLAYLINK_FINISHED =
    "TrackAction.REQUEST_SYNC_TRACK_PLAYLINK_FINISHED";

  static REQUEST_SYNC_RELEASES_STORES =
    "TrackAction.REQUEST_SYNC_RELEASES_STORES";
  static REQUEST_SYNC_RELEASES_STORES_FINISHED =
    "TrackAction.REQUEST_SYNC_RELEASES_STORES_FINISHED";

  static REQUEST_TRACK_BY_ID_CLEAR = "TrackAction.REQUEST_TRACK_BY_ID_CLEAR";
  static REQUEST_TRACK_BY_ID_CLEAR_FINISHED =
    "TrackAction.REQUEST_TRACK_BY_ID_CLEAR_FINISHED";

  static REQUEST_TRACK_FILTER = "TrackAction.REQUEST_TRACK_FILTER";
  static REQUEST_TRACK_FILTER_FINISHED =
    "TrackAction.REQUEST_TRACK_FILTER_FINISHED";

  static REQUEST_PUT_TRACK = "TrackAction.REQUEST_PUT_TRACK";
  static REQUEST_PUT_TRACK_FINISHED = "TrackAction.REQUEST_PUT_TRACK_FINISHED";

  static REQUEST_UPDATE_PLAYLINK = "TrackAction.REQUEST_UPDATE_PLAYLINK";
  static REQUEST_UPDATE_PLAYLINK_FINISHED =
    "TrackAction.REQUEST_UPDATE_PLAYLINK_FINISHED";

  static REQUEST_ALBUM_PLAYLINK = "TrackAction.REQUEST_ALBUM_PLAYLINK";
  static REQUEST_ALBUM_PLAYLINK_FINISHED =
    "TrackAction.REQUEST_ALBUM_PLAYLINK_FINISHED";

  static REQUEST_POST_TRACK = "TrackAction.REQUEST_POST_TRACK";
  static REQUEST_POST_TRACK_FINISHED =
    "TrackAction.REQUEST_POST_TRACK_FINISHED";

  static REQUEST_DELETE_TRACK = "TrackAction.REQUEST_DELETE_TRACK";
  static REQUEST_DELETE_TRACK_FINISHED =
    "TrackAction.REQUEST_DELETE_TRACK_FINISHED";

  static REQUEST_EXISTINGTRACKS = "TrackAction.REQUEST_EXISTINGTRACKS";
  static REQUEST_EXISTINGTRACKS_FINISHED =
    "TrackAction.REQUEST_EXISTINGTRACKS_FINISHED";

  static REQUEST_EXISTINGTRACKS_CLEAR = "TrackAction.REQUEST_EXISTINGTRACKS";
  static REQUEST_EXISTINGTRACKS_CLEAR_FINISHED =
    "TrackAction.REQUEST_EXISTINGTRACKS_FINISHED";

  static REQUEST_PUT_TRACK_BULKUPDATE =
    "TrackAction.REQUEST_PUT_TRACK_BULKUPDATE";
  static REQUEST_PUT_TRACK_BULKUPDATE_FINISHED =
    "TrackAction.REQUEST_PUT_TRACK_BULKUPDATE_FINISHED";

  static REQUEST_TRACKSTATUSCOUNT = "TrackAction.REQUEST_TRACKSTATUSCOUNT";
  static REQUEST_TRACKSTATUSCOUNT_FINISHED =
    "TrackAction.REQUEST_TRACKSTATUSCOUNT_FINISHED";

  // METHODS
  // Organization GET function
  static requestTrack(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_TRACK,
        TrackEffect.requestTrack,
        params,
        callBack
      );
    };
  }

  static requestSyncReleaseStores(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_SYNC_RELEASES_STORES,
        TrackEffect.requestSyncReleaseStores,
        id,
        params
      );
    };
  }

  static requestSyncTrackPlaylink(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_SYNC_TRACK_PLAYLINK,
        TrackEffect.requestSyncTrackPlaylink,
        id,
        params
      );
    };
  }

  static requestTrackById(id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_TRACK_BY_ID,
        TrackEffect.requestTrackById,
        id,
        params
      );
    };
  }

  static requestTrackByIdClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_TRACK_BY_ID_CLEAR,
        TrackEffect.requestTrackByIdClear
      );
    };
  }

  static requestTrackFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_TRACK_FILTER,
        TrackEffect.requestTrackFilter,
        params
      );
    };
  }
  static requestPutTrack(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_PUT_TRACK,
        TrackEffect.requestPutTrack,
        data,
        id,
        params
      );
    };
  }

  static requestUpdatePlayLink(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_UPDATE_PLAYLINK,
        TrackEffect.requestUpdatePlayLink,
        data,
        id,
        params
      );
    };
  }

  static requestAlbumPlayLink(data, id, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_ALBUM_PLAYLINK,
        TrackEffect.requestAlbumPlayLink,
        data,
        id,
        params
      );
    };
  }

  static requestPostTrack(data, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_POST_TRACK,
        TrackEffect.requestPostTrack,
        data,
        params
      );
    };
  }

  static requestDeleteTrack(id, callback) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_DELETE_TRACK,
        TrackEffect.requestDeleteTrack,
        id,
        callback
      );
    };
  }

  // Used to get existing tracks by ID
  static requestExistingTracks(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_EXISTINGTRACKS,
        TrackEffect.requestExistingTracks,
        params
      );
    };
  }

  static requestExistingTracksClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_EXISTINGTRACKS_CLEAR,
        TrackEffect.requestExistingTracksClear
      );
    };
  }

  //Track Row Ordering
  static requestPutTrackBulkUpdate(data) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_PUT_TRACK_BULKUPDATE,
        TrackEffect.requestPutTrackBulkUpdate,
        data
      );
    };
  }

  //Dashboard Track Status Count
  static requestTrackStatusCount(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TrackAction.REQUEST_TRACKSTATUSCOUNT,
        TrackEffect.requestTrackStatusCount,
        params
      );
    };
  }
}
