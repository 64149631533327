import ActionUtility from "../../utilities/ActionUtility";
import TopDevicesEffect from "./TopDevicesEffect";

export default class TopDevicesAction {
  // TopDevicesAction action with an function declaration
  static REQUEST_TOPDEVICES = "TopDevicesAction.REQUEST_TOPDEVICES";
  static REQUEST_TOPDEVICES_FINISHED =
    "TopDevicesAction.REQUEST_TOPDEVICES_FINISHED";

  static REQUEST_TOPDEVICES_FILTER =
    "TopDevicesAction.REQUEST_TOPDEVICES_FILTER";
  static REQUEST_TOPDEVICES_FILTER_FINISHED =
    "TopDevicesAction.REQUEST_TOPDEVICES_FILTER_FINISHED";

  // METHODS
  // Organization GET function
  static requestTopDevices(params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopDevicesAction.REQUEST_TOPDEVICES,
        TopDevicesEffect.requestTopDevices,
        params,
        callBack
      );
    };
  }

  static requestTopDevicesFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        TopDevicesAction.REQUEST_TOPDEVICES_FILTER,
        TopDevicesEffect.requestTopDevicesFilter,
        params
      );
    };
  }
}
