import React, { useState } from "react";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Typography,
  createFilterOptions,
  Chip,
  Avatar,
} from "@mui/material";
import { Form, Formik, Field, getIn, ErrorMessage, FastField } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import ClientAction from "../../stores/client/ClientAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../App";
import LockIcon from "@mui/icons-material/Lock";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AutoCompleteMultiSelect from "../../components/autoCompleteComponent/AutoCompleteMultiSelect";
import DatePickerComponent from "../../components/datePicker/DatePickerComponent";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import CloseIcon from "@mui/icons-material/Close";
import environment from "environment";
import DeleteIcon from "@mui/icons-material/Delete";
import ActorAction from "../../stores/actor/ActorAction";
import AvatarLogo from "../../views/client/AvatarLogo";

// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  actor_name: yup
    .string("Enter your Actor name")
    .trim()
    .min(1, "Actor name should be of minimum 1 characters length")
    .required("Actor name is required"),
});

const ActorPost = (props) => {
  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      actor_name: props.projectEditData.actor_name,
      profile_image_url: props.projectEditData.profile_image_url,
      boolval: props.projectEditData.profile_image_url ? true : false,
    };
  } else {
    initial = {
      actor_name: "",
      profile_image_url: "",
      boolean: false,
      boolval: false,
    };
  }

  const handlFileUpload = (value, setFieldValue, event) => {
    setFieldValue("boolean", true);

    // event.preventDefault();
    let formData = new FormData();
    formData.append("file", value);

    let access_tocken = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";

    fetch(`${environment.api.baseApi}/profile_picture_upload`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + access_tocken,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        setFieldValue("profile_image_url", response.url);
        setFieldValue("boolean", false);
      });
  };

  function Required() {
    return (
      <span style={{ color: "red", fontSize: "14px" }}>
        <span>Required</span> <span>*</span>
      </span>
    );
  }

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        enableReinitialize
        initialValues={initial}
        validate={(values) => {
          var errors = {};
          if (!values.actor_name) {
            errors.actor_name = <Required />;
          }
          return errors;
        }}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          // parameter for showing list based on the modified_by

          if (props.edit) {
            let post_obj = {};

            Object.assign(
              post_obj,
              values.actor_name && {
                actor_name: values.actor_name,
              },
              {
                profile_image_url: values.profile_image_url,
              }
            );

            props
              .dispatch(
                ActorAction.requestPutActor(
                  JSON.stringify(post_obj),
                  props.projectEditData.id
                )
              )
              .then(() => {
                const params = {
                  order_by: `[["modified_on","desc"]]`,
                  page_limit: 15,
                };
                const param = {
                  order_by: `[["modified_on","desc"]]`,
                  page_limit: "none",
                };
                props.dispatch(ActorAction.requestActor(params));
                props.dispatch(ActorAction.requestActorFilter(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            let post_obj = {};

            Object.assign(
              post_obj,
              values.actor_name && {
                actor_name: values.actor_name,
              },
              values.profile_image_url && {
                profile_image_url: values.profile_image_url,
              }
            );
            props.dispatch(ActorAction.requestPostActor(post_obj)).then(() => {
              const params = {
                order_by: `[["created_on","desc"]]`,

                page_limit: 15,
              };
              const param = {
                order_by: `[["created_on","desc"]]`,

                page_limit: "none",
              };
              props.dispatch(ActorAction.requestActor(params));
              props.dispatch(ActorAction.requestActorFilter(param));
              props.onClose();
              setLoading(false);
            });
          }

          // formikHelpers.resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
          setFieldValue,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%", marginBottom: 5 }}>
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"div"}
                    fontSize={30}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      justifySelf: "center",
                    }}
                  >
                    {!props.edit
                      ? formatName("Add Actor Name")
                      : formatName("Edit Actor Name")}
                  </Typography>

                  <Typography
                    component={"span"}
                    sx={{
                      justifySelf: "end",
                      marginLeft: 3,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <HighlightOffIcon sx={{ fontSize: 20 }} />
                  </Typography>
                </Typography>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Actor Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder=" Actor Name"
                      id="actor_name"
                      size="small"
                      name="actor_name"
                      error={
                        Boolean(errors.actor_name) &&
                        Boolean(touched.actor_name)
                      }
                      helperText={
                        Boolean(touched.actor_name) && errors.actor_name
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>

                <Grid item lg={12} md={12} sm={12} container mt={2}>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Profile Picture :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Profile Picture"
                      id="profile_image_url"
                      size="small"
                      name="profile_image_url"
                      error={
                        Boolean(errors.profile_image_url) &&
                        Boolean(touched.profile_image_url)
                      }
                      helperText={
                        Boolean(touched.profile_image_url) &&
                        errors.profile_image_url
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      disabled={values.boolean}
                      loading={loading}
                      type="submit"
                      name="Add Actor"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Submit"
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={() => {
                        document.querySelector("input[type='file']").value = "";
                      }}
                      width="100%"
                      cancel
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      cancel
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(ActorPost);
