import BaseReducer from "../../utilities/BaseReducer";
import NotificationAction from "./NotificationAction";

export default class NotificationsByIdReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    NotificationsById: [],
  };

  //operation Filter
  [NotificationAction.REQUEST_NOTIFICATIONS_BY_ID_FINISHED](state, action) {
    return {
      ...state,
      NotificationsById: action.payload,
    };
  }
}
