import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  Typography,
} from "@mui/material";
import { theme } from "../../views/App";
import CloseIcon from "@mui/icons-material/Close";
import StoreIcon from "@mui/icons-material/Store";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import PeopleIcon from "@mui/icons-material/People";
import AlbumIcon from "@mui/icons-material/Album";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiMonthPicker from "../../components/datePicker/MuiMonthPicker";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import PianoIcon from "@mui/icons-material/Piano";
import LabelPost from "../label/LabelPost";
import dayjs from "dayjs";

function RevenueMultiLevelFilter(props) {
  const squareBoxStyle = {
    borderRadius: "9px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
    border: "solid 0.5px #86aeb6",
    height: 100,
    width: 160,
    cursor: "pointer",
  };
  const miniBoxStyle = {
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.61), inset 0 -17px 66px 0 #f3f9fb",
    height: 40,
    width: 100,
    cursor: "pointer",
  };
  const hoverStyle = {
    transform: "scale(1.1)",
    transition: "transform 0.2s ease-in-out",
  };

  const [tabValue, setTabValue] = useState(0);
  const [client, setClient] = useState(null);
  const [releaseFilter, setReleaseFilter] = useState(null);
  const [isrcFilter, setisrcFilter] = useState(null);
  const [upcFilter, setupcFilter] = useState(null);

  const [retailer, setRetailer] = useState(null);
  const [monthFilter, setMonthFilter] = useState(null);
  const [label, setLabel] = useState(null);

  const [searchString, setSearchString] = useState(null);

  const [noFiltersAlert, setNoFiltersAlert] = useState(false);
  const [runAnimation, setRunAnimation] = useState(true);

  useEffect(() => {
    setNoFiltersAlert(false);
    if (props.open == true) {
      setTabValue(0);

      setClient(props.filterContents.find((item) => item.id == "1")?.data);
      setReleaseFilter(
        props.filterContents.find((item) => item.id == "2")?.data
      );

      setisrcFilter(props.filterContents.find((item) => item.id == "6")?.data);
      setupcFilter(props.filterContents.find((item) => item.id == "7")?.data);

      setRetailer(props.filterContents.find((item) => item.id == "3")?.data);
      setMonthFilter(props.filterContents.find((item) => item.id == "4")?.data);
      setLabel(props.filterContents.find((item) => item.id == "5")?.data);
    }
  }, [props.open]);

  function chipContent() {
    const content = [];

    if (client) content.push({ id: "1", name: client.client_name });
    if (releaseFilter) content.push({ id: "2", name: releaseFilter.name });
    if (isrcFilter) content.push({ id: "6", name: isrcFilter.name });
    if (upcFilter) content.push({ id: "7", name: upcFilter.name });

    if (retailer) content.push({ id: "3", name: retailer });
    if (monthFilter) {
      const date = new Date(monthFilter);
      let monthName = date.toLocaleString("default", { month: "long" });
      content.push({ id: "4", name: monthName });
    }
    if (label) content.push({ id: "5", name: label.label_name });

    props.setChipContent(content);
  }

  function filterContentUpdate() {
    let content = [];

    if (client) content.push({ id: "1", data: client });
    if (releaseFilter) content.push({ id: "2", data: releaseFilter });
    if (isrcFilter) content.push({ id: "6", data: isrcFilter });
    if (upcFilter) content.push({ id: "7", data: upcFilter });

    if (retailer) content.push({ id: "3", data: retailer });
    if (monthFilter) content.push({ id: "4", data: monthFilter });
    if (label) content.push({ id: "5", data: label });

    props.setFilterContents(content);
  }

  function clientHandleChange(e, clientName) {
    setClient(clientName);
  }
  function releaseHandleChange(e, releaseName) {
    setReleaseFilter(releaseName);
  }

  function isrcHandleChange(e, releaseName) {
    setisrcFilter(releaseName);
  }

  function upcHandleChange(e, releaseName) {
    setupcFilter(releaseName);
  }

  function retailerHandleChange(e, retailerSearch) {
    setRetailer(retailerSearch);
  }
  function monthFilterHandleChange(date) {
    setMonthFilter(date);
  }
  function labelHandleChange(e, labelName) {
    setLabel(labelName);
  }

  const handleInputChange = (event) => {
    if (tabValue === 2) {
      releaseHandleChange(null, event.target.value);
    }

    if (tabValue === 6) {
      isrcHandleChange(null, event.target.value);
    }

    if (tabValue === 7) {
      upcHandleChange(null, event.target.value);
    }

    setSearchString(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (tabValue === 2) {
        releaseHandleChange(null, searchString);
      }

      if (tabValue === 6) {
        isrcHandleChange(null, searchString);
      }

      if (tabValue === 7) {
        upcHandleChange(null, searchString);
      }

      setSearchString(null);
    }
  };

  useEffect(() => {
    filterContentUpdate();
  }, [
    client,
    releaseFilter,
    retailer,
    monthFilter,
    label,
    isrcFilter,
    upcFilter,
  ]);

  function deleteFunction(filterID) {
    if (filterID == "1" && client) setClient(null);
    else if (filterID == "2" && releaseFilter) setReleaseFilter(null);
    else if (filterID == "6" && isrcFilter) setisrcFilter(null);
    else if (filterID == "7" && upcFilter) setupcFilter(null);
    else if (filterID == "3" && retailer) setRetailer(null);
    else if (filterID == "4" && monthFilter) setMonthFilter(null);
    else if (filterID == "5" && label) setLabel(null);
    else setNoFiltersAlert(true);
  }

  function emptyCheck(tabValue) {
    if (
      (tabValue == "1" && !client) ||
      (tabValue == "2" && !releaseFilter) ||
      (tabValue == "6" && !isrcFilter) ||
      (tabValue == "7" && !upcFilter) ||
      (tabValue == "3" && !retailer) ||
      (tabValue == "4" && !monthFilter) ||
      (tabValue == "5" && !label)
    ) {
      setNoFiltersAlert(true);
      return 0;
    }
  }

  return (
    <>
      <style
        dangerouslySetInnerHTML={{
          __html: `
        @keyframes zoomInFade {
          0% {
            opacity: 0;
            transform: scale(0.5);
          }
          100% {
            opacity: 1;
            transform: scale(1);
          }
        }
        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-8px);
          }
          60% {
            transform: translateY(2px);
          }
        }
      `,
        }}
      />

      <Dialog
        open={props.open}
        onClose={() => {
          props.onClose();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <DialogContentText>
            <>
              <Box
                sx={{
                  borderBottom: `2px solid ${theme.palette.common.grey}`,
                }}
              >
                <Grid
                  item
                  container
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Grid item px={1} sx={{ display: "flex" }} mb={1}>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        color: theme.palette.primary.main,
                      }}
                    >
                      <FilterAltIcon
                        sx={{ marginBottom: "2px", marginRight: "10px" }}
                      />
                      Select Filters
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    px={1}
                    sx={{
                      display: "inline-block",
                      borderRadius: "50%",
                      padding: "4px",
                      transition: "background-color 0.5s ease-in",
                      "&:hover": {
                        ...hoverStyle,
                        backgroundColor: theme.palette.common.grey,
                      },
                    }}
                  >
                    <CloseIcon
                      sx={{
                        cursor: "pointer",
                        "&:hover": {
                          color: theme.palette.secondary.main,
                        },
                      }}
                      onClick={() => {
                        setTabValue(0);
                        props.onClose();
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Grid xs={12} sx={{ marginY: "20px" }}></Grid>
              {!tabValue ? (
                <Box>
                  <Grid
                    container
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    pt={5}
                    gap={3}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* Sub-Client SECTION START */}
                    {/* <Grid
                      xs={12}
                      sm={6}
                      md={2}
                      item
                      container
                      sx={{
                        ...squareBoxStyle,
                        animation:
                          runAnimation && "zoomInFade 0.2s ease-in-out",
                        ":hover": {
                          ...hoverStyle,
                        },
                      }}
                      textAlign="center"
                      justifyContent={"center"}
                      alignItems="center"
                      onClick={() => {
                        setTabValue(1);
                      }}
                    >
                      <Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                        >
                          <PeopleIcon
                            style={{ fontSize: "40px", color: "#92b9c4" }}
                            onClick={() => {
                              setTabValue(1);
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                          px={0.5}
                          mt={0.5}
                        >
                          <span
                            className="p-0 m-0"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            Sub-Client
                          </span>
                        </Grid>
                      </Grid>
                    </Grid> */}

                    {/* RELEASE SECTION START */}

                    <Grid
                      xs={12}
                      sm={6}
                      md={2}
                      item
                      container
                      sx={{
                        ...squareBoxStyle,
                        animation:
                          runAnimation && "zoomInFade 0.3s ease-in-out",
                        ":hover": {
                          ...hoverStyle,
                        },
                      }}
                      textAlign="center"
                      justifyContent={"center"}
                      alignItems="center"
                      onClick={() => {
                        setTabValue(2);
                      }}
                    >
                      <Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                        >
                          <AlbumIcon
                            style={{ fontSize: "40px", color: "#92b9c4" }}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                          px={0.5}
                          mt={0.5}
                        >
                          <span
                            className="p-0 m-0"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            Release
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* RETAILER SECTION START */}

                    {props?.page !== "YT_Revenue" && (
                      <Grid
                        xs={12}
                        sm={6}
                        md={2}
                        item
                        container
                        sx={{
                          ...squareBoxStyle,
                          animation:
                            runAnimation && "zoomInFade 0.4s ease-in-out",
                          ":hover": {
                            ...hoverStyle,
                          },
                        }}
                        textAlign="center"
                        justifyContent={"center"}
                        alignItems="center"
                        onClick={() => {
                          setTabValue(3);
                        }}
                      >
                        <Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                          >
                            <StoreIcon
                              style={{ fontSize: "40px", color: "#92b9c4" }}
                            />
                          </Grid>

                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            textAlign="center"
                            px={0.5}
                            mt={0.5}
                          >
                            <span
                              className="p-0 m-0"
                              style={{
                                fontSize: "20px",
                              }}
                            >
                              Retailer
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}

                    {/* MONTH SECTION START */}

                    <Grid
                      xs={12}
                      sm={6}
                      md={2}
                      item
                      container
                      sx={{
                        ...squareBoxStyle,
                        animation:
                          runAnimation && "zoomInFade 0.6s ease-in-out",
                        ":hover": {
                          ...hoverStyle,
                        },
                      }}
                      textAlign="center"
                      justifyContent={"center"}
                      alignItems="center"
                      onClick={() => {
                        setTabValue(4);
                      }}
                    >
                      <Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                        >
                          <CalendarMonthIcon
                            style={{ fontSize: "40px", color: "#92b9c4" }}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                          px={0.5}
                          mt={0.5}
                        >
                          <span
                            className="p-0 m-0"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            Month
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Labels */}
                    <Grid
                      xs={12}
                      sm={6}
                      md={2}
                      item
                      container
                      sx={{
                        ...squareBoxStyle,
                        animation:
                          runAnimation && "zoomInFade 0.6s ease-in-out",
                      }}
                      textAlign="center"
                      justifyContent={"center"}
                      alignItems="center"
                      onClick={() => {
                        setTabValue(5);
                      }}
                    >
                      <Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                        >
                          <PianoIcon
                            style={{ fontSize: "40px", color: "#92b9c4" }}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                          px={0.5}
                          mt={0.5}
                        >
                          <span
                            className="p-0 m-0"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            Labels
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* ISRC */}
                    <Grid
                      xs={12}
                      sm={6}
                      md={2}
                      item
                      container
                      sx={{
                        ...squareBoxStyle,
                        animation:
                          runAnimation && "zoomInFade 0.6s ease-in-out",
                      }}
                      textAlign="center"
                      justifyContent={"center"}
                      alignItems="center"
                      onClick={() => {
                        setTabValue(6);
                      }}
                    >
                      <Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                        >
                          <PianoIcon
                            style={{ fontSize: "40px", color: "#92b9c4" }}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                          px={0.5}
                          mt={0.5}
                        >
                          <span
                            className="p-0 m-0"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            ISRC
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* UPC */}
                    <Grid
                      xs={12}
                      sm={6}
                      md={2}
                      item
                      container
                      sx={{
                        ...squareBoxStyle,
                        animation:
                          runAnimation && "zoomInFade 0.6s ease-in-out",
                      }}
                      textAlign="center"
                      justifyContent={"center"}
                      alignItems="center"
                      onClick={() => {
                        setTabValue(7);
                      }}
                    >
                      <Grid>
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                        >
                          <PianoIcon
                            style={{ fontSize: "40px", color: "#92b9c4" }}
                          />
                        </Grid>

                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          textAlign="center"
                          px={0.5}
                          mt={0.5}
                        >
                          <span
                            className="p-0 m-0"
                            style={{
                              fontSize: "20px",
                            }}
                          >
                            UPC
                          </span>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <>
                  {tabValue && (
                    <>
                      <Grid item container sm={1}>
                        <Box
                          sx={{
                            padding: "4px",
                            border: `1px solid ${theme.palette.common.grey}`,
                            borderRadius: "4px",
                            backgroundColor: theme.palette.primary.main,
                            cursor: "pointer",
                            ":hover": {
                              ...hoverStyle,
                              transform: "scale(1.1)",
                            },
                          }}
                          onClick={() => {
                            setTabValue(0);
                          }}
                        >
                          <ArrowBackIosIcon style={{ color: "white" }} />
                          <span style={{ color: "white", marginRight: "5px" }}>
                            Back
                          </span>
                        </Box>
                      </Grid>
                      {/* Sliding Grid */}
                      <Grid
                        container
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        pt={2}
                        pb={5}
                        gap={3}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {/* Sub-Client */}
                        {/* <Grid
                          xs={12}
                          sm={6}
                          md={2}
                          item
                          container
                          sx={{
                            ...miniBoxStyle,
                            animation: tabValue == 1 && "bounce 0.5s linear",
                            border:
                              tabValue === 1
                                ? `solid 2px ${theme.palette.primary.main}`
                                : miniBoxStyle.border,
                            backgroundColor:
                              tabValue === 1
                                ? theme.palette.text.first_letter
                                : "",
                            borderRadius: tabValue === 1 ? "10px" : "5px",
                            ...(tabValue !== 1 && {
                              ":hover": {
                                ...hoverStyle,
                              },
                            }),
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            setTabValue(1);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              px={0.5}
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: tabValue === 1 ? "bold" : "",
                                }}
                              >
                                Sub-Client
                              </span>
                            </Grid>
                          </Grid>
                        </Grid> */}

                        {/* Release */}
                        <Grid
                          xs={12}
                          sm={6}
                          md={2}
                          item
                          container
                          sx={{
                            ...miniBoxStyle,
                            animation: tabValue == 2 && "bounce 0.5s linear",
                            border:
                              tabValue === 2
                                ? `solid 2px ${theme.palette.primary.main}`
                                : miniBoxStyle.border,
                            backgroundColor:
                              tabValue === 2
                                ? theme.palette.text.first_letter
                                : "",
                            borderRadius: tabValue === 2 ? "10px" : "5px",
                            ...(tabValue !== 2 && {
                              ":hover": {
                                ...hoverStyle,
                              },
                            }),
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            setTabValue(2);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              alignItems={"center"}
                              px={0.5}
                              //mt={0.5}
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: tabValue === 2 ? "bold" : "",
                                }}
                              >
                                Release
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Retailer */}
                        {props?.page !== "YT_Revenue" && (
                          <Grid
                            xs={12}
                            sm={6}
                            md={2}
                            //md={4}
                            //lg={3}
                            item
                            container
                            sx={{
                              ...miniBoxStyle,
                              animation: tabValue == 3 && "bounce 0.5s linear",
                              border:
                                tabValue === 3
                                  ? `solid 2px ${theme.palette.primary.main}`
                                  : miniBoxStyle.border,
                              backgroundColor:
                                tabValue === 3
                                  ? theme.palette.text.first_letter
                                  : "",
                              borderRadius: tabValue === 3 ? "10px" : "5px",
                              ...(tabValue !== 3 && {
                                ":hover": {
                                  ...hoverStyle,
                                },
                              }),
                            }}
                            textAlign="center"
                            justifyContent={"center"}
                            alignItems="center"
                            onClick={() => {
                              setTabValue(3);
                            }}
                          >
                            <Grid>
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                textAlign="center"
                                alignItems={"center"}
                                px={0.5}
                              >
                                <span
                                  style={{
                                    fontSize: "20px",
                                    fontWeight: tabValue === 3 ? "bold" : "",
                                  }}
                                >
                                  Retailer
                                </span>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                        {/* Month */}
                        <Grid
                          xs={12}
                          sm={6}
                          md={2}
                          //md={4}
                          //lg={3}
                          item
                          container
                          sx={{
                            ...miniBoxStyle,
                            animation: tabValue == 4 && "bounce 0.5s linear",
                            border:
                              tabValue === 4
                                ? `solid 2px ${theme.palette.primary.main}`
                                : miniBoxStyle.border,
                            backgroundColor:
                              tabValue === 4
                                ? theme.palette.text.first_letter
                                : "",
                            borderRadius: tabValue === 4 ? "10px" : "5px",
                            ...(tabValue !== 4 && {
                              ":hover": {
                                ...hoverStyle,
                              },
                            }),
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            setTabValue(4);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              alignItems={"center"}
                              px={0.5}
                              //mt={0.5}
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: tabValue === 4 ? "bold" : "",
                                }}
                              >
                                Month
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                        {/* Labels */}
                        <Grid
                          xs={12}
                          sm={6}
                          md={2}
                          //md={4}
                          //lg={3}
                          item
                          container
                          sx={{
                            ...miniBoxStyle,
                            animation: tabValue == 5 && "bounce 0.5s linear",
                            border:
                              tabValue === 5
                                ? `solid 2px ${theme.palette.primary.main}`
                                : miniBoxStyle.border,
                            backgroundColor:
                              tabValue === 5
                                ? theme.palette.text.first_letter
                                : "",
                            borderRadius: tabValue === 5 ? "10px" : "5px",
                            ...(tabValue !== 5 && {
                              ":hover": {
                                ...hoverStyle,
                              },
                            }),
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            setTabValue(5);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              alignItems={"center"}
                              px={0.5}
                              //mt={0.5}
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: tabValue === 5 ? "bold" : "",
                                }}
                              >
                                Labels
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* ISRC */}
                        <Grid
                          xs={12}
                          sm={6}
                          md={2}
                          //md={4}
                          //lg={3}
                          item
                          container
                          sx={{
                            ...miniBoxStyle,
                            animation: tabValue == 6 && "bounce 0.5s linear",
                            border:
                              tabValue === 6
                                ? `solid 2px ${theme.palette.primary.main}`
                                : miniBoxStyle.border,
                            backgroundColor:
                              tabValue === 6
                                ? theme.palette.text.first_letter
                                : "",
                            borderRadius: tabValue === 6 ? "10px" : "5px",
                            ...(tabValue !== 6 && {
                              ":hover": {
                                ...hoverStyle,
                              },
                            }),
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            setTabValue(6);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              alignItems={"center"}
                              px={0.5}
                              //mt={0.5}
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: tabValue === 5 ? "bold" : "",
                                }}
                              >
                                ISRC
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>

                        {/* UPC */}
                        <Grid
                          xs={12}
                          sm={6}
                          md={2}
                          //md={4}
                          //lg={3}
                          item
                          container
                          sx={{
                            ...miniBoxStyle,
                            animation: tabValue == 7 && "bounce 0.5s linear",
                            border:
                              tabValue === 7
                                ? `solid 2px ${theme.palette.primary.main}`
                                : miniBoxStyle.border,
                            backgroundColor:
                              tabValue === 7
                                ? theme.palette.text.first_letter
                                : "",
                            borderRadius: tabValue === 7 ? "10px" : "5px",
                            ...(tabValue !== 7 && {
                              ":hover": {
                                ...hoverStyle,
                              },
                            }),
                          }}
                          textAlign="center"
                          justifyContent={"center"}
                          alignItems="center"
                          onClick={() => {
                            setTabValue(7);
                          }}
                        >
                          <Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              textAlign="center"
                              alignItems={"center"}
                              px={0.5}
                              //mt={0.5}
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: tabValue === 7 ? "bold" : "",
                                }}
                              >
                                UPC
                              </span>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {tabValue === 1 && (
                    <>
                      <Typography sx={{ fontSize: "16px", marginY: "10px" }}>
                        Select Sub-Client
                      </Typography>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        sx={{ width: { sm: "100%", md: "30%" } }}
                      >
                        <AutoCompleteComponent
                          value={client}
                          list={props.clientList}
                          textFieldProps={{
                            placeholder: "Sub-Client Name",
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.client_name
                          }
                          getOptionLabel={(option) => option.client_name}
                          handleChange={clientHandleChange}
                          size="small"
                          width="100%"
                          disableClearable
                          //fieldheight="33px"
                        />
                      </Grid>
                    </>
                  )}
                  {tabValue === 2 && (
                    <>
                      <Typography sx={{ fontSize: "16px", marginY: "10px" }}>
                        Enter Release Title to Filter
                      </Typography>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        sx={{ width: { sm: "100%", md: "30%" } }}
                      >
                        {/* <AutoCompleteComponent
                          value={releaseFilter}
                          // list={releasesList}
                          list={props.groupedRoles}
                          textFieldProps={{
                            placeholder: "Releases",
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.name
                          }
                          getOptionLabel={(option) => option.name}
                          handleChange={releaseHandleChange}
                          size="small"
                          width="100%"
                          disableClearable
                          //fieldheight="33px"
                        /> */}
                        <MuiTextField
                          name="release"
                          variant="outlined"
                          placeholder={"Type here ..."}
                          fieldheight="33px"
                          sx={{ width: "100%" }}
                          value={releaseFilter}
                          size="small"
                          //onChange={retailerHandleChange}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyPress}
                          // InputProps={{
                          //   endAdornment: <SearchIcon onClick={applyFilter} />,
                          // }}
                        />
                      </Grid>
                    </>
                  )}
                  {tabValue === 3 && (
                    <>
                      <Typography sx={{ fontSize: "16px", marginY: "10px" }}>
                        Select Retailer
                      </Typography>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        sx={{ width: { sm: "100%", md: "30%" } }}
                      >
                        {document.getElementById("outlined-basic") &&
                          (document.getElementById("outlined-basic").value =
                            "")}

                        <AutoCompleteComponent
                          value={retailer}
                          list={
                            props.platforms_list ? props.platforms_list : []
                          }
                          textFieldProps={{
                            placeholder: "Select Retailer",
                          }}
                          getOptionLabel={(option) => option.name}
                          isOptionEqualToValue={(option, value) =>
                            option && option.id === value && value.id
                          }
                          handleChange={retailerHandleChange}
                          size="small"
                          width="100%"
                          disableClearable
                          //fieldheight="33px"
                        />
                        {/* <MuiTextField
                          variant="outlined"
                          placeholder={"Type here ..."}
                          fieldheight="33px"
                          sx={{ width: "100%" }}
                          value={retailer}
                          size="small"
                          //onChange={retailerHandleChange}
                          onChange={handleInputChange}
                          onKeyDown={handleKeyPress}
                          // InputProps={{
                          //   endAdornment: <SearchIcon onClick={applyFilter} />,
                          // }}
                        /> */}
                      </Grid>
                    </>
                  )}

                  {tabValue === 4 && (
                    <>
                      <Typography sx={{ fontSize: "16px", marginY: "10px" }}>
                        Select Sales Reported Month
                      </Typography>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        sx={{ width: { sm: "100%", md: "30%" } }}
                      >
                        <MuiMonthPicker
                          onChange={monthFilterHandleChange}
                          value={
                            monthFilter
                              ? monthFilter
                              : dayjs().subtract(1, "month").startOf("month")
                          }
                        />
                      </Grid>
                    </>
                  )}

                  {tabValue === 5 && (
                    <>
                      <Typography sx={{ fontSize: "16px", marginY: "10px" }}>
                        Select Label
                      </Typography>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        sx={{ width: { sm: "100%", md: "30%" } }}
                      >
                        <AutoCompleteComponent
                          value={label}
                          list={props.labelList}
                          textFieldProps={{
                            placeholder: "Label Name",
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.label_name
                          }
                          getOptionLabel={(option) => option.label_name}
                          handleChange={labelHandleChange}
                          size="small"
                          width="100%"
                          disableClearable
                          //fieldheight="33px"
                        />
                      </Grid>
                    </>
                  )}

                  {tabValue === 6 && (
                    <>
                      <Typography sx={{ fontSize: "16px", marginY: "10px" }}>
                        Enter ISRC to Filter
                      </Typography>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        sx={{ width: { sm: "100%", md: "30%" } }}
                      >
                        <MuiTextField
                          name="isrc"
                          variant="outlined"
                          placeholder={"Type here ..."}
                          fieldheight="33px"
                          sx={{ width: "100%" }}
                          value={isrcFilter}
                          size="small"
                          onChange={handleInputChange}
                          onKeyDown={handleKeyPress}
                        />
                      </Grid>
                    </>
                  )}

                  {tabValue === 7 && (
                    <>
                      <Typography sx={{ fontSize: "16px", marginY: "10px" }}>
                        Enter UPC to Filter
                      </Typography>
                      <Grid
                        item
                        lg={3}
                        md={3}
                        sm={3}
                        sx={{ width: { sm: "100%", md: "30%" } }}
                      >
                        <MuiTextField
                          name="upc"
                          variant="outlined"
                          placeholder={"Type here ..."}
                          fieldheight="33px"
                          sx={{ width: "100%" }}
                          value={upcFilter}
                          size="small"
                          onChange={handleInputChange}
                          onKeyDown={handleKeyPress}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </>
          </DialogContentText>
        </DialogContent>
        <div style={{ height: tabValue === 0 ? "150px" : "auto" }}>
          <Grid container justifyContent={"flex-end"} p={3} gap={2}>
            {tabValue !== 0 && (
              <>
                <Button
                  onClick={() => {
                    if (emptyCheck(tabValue) == 0) return;
                    chipContent();
                    filterContentUpdate();
                    props.updateFilterContents();
                    props.onClose();
                  }}
                  variant="contained"
                  color="primary"
                  style={{
                    borderRadius: "5px",
                    textTransform: "none",
                    width: "150px",
                  }}
                >
                  Apply
                </Button>
                <Button
                  onClick={() => {
                    if (emptyCheck(tabValue) == 0) return;
                    deleteFunction(tabValue);
                    props.deleteChip(tabValue);
                  }}
                  variant="contained"
                  style={{
                    borderRadius: "5px",
                    textTransform: "none",
                    width: "150px",
                    backgroundColor: theme.palette.secondary.main,
                  }}
                >
                  Reset
                </Button>
              </>
            )}
          </Grid>
        </div>
        <MuiSnackbarAlert
          open={noFiltersAlert}
          onClose={() => setNoFiltersAlert(false)}
          message={"No Filters Applied !!"}
        />
      </Dialog>
    </>
  );
}

export default RevenueMultiLevelFilter;
