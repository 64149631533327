import { BaseModel } from "sjs-base-model";

export default class A3ReportsCountrywiseModel extends BaseModel {
  "Net Revenue By Country" = [];

  constructor(data1) {
    super();
    this.update(data1);
  }
}
