import React from "react";
import bgImage from "../../Images/a3_lab_splash_image.webp";

const SplashScreen = () => {
  return (
    <div style={{ display: "flex", width: "100%", backgroundColor: "#fff" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <img src={bgImage} alt="" style={{ width: "100%", height: "90vh" }} />
      </div>
    </div>
  );
};

export default SplashScreen;
