import React, { useEffect } from "react";
import bgImage from "../../Images/a3_label_web_welvome_bg.webp";
import rightLogo from "../../Images/a3_label_web_music_right.webp";
import leftLogo from "../../Images/a3_label_web_music_left.webp";
import middleLogo from "../../components/logo/logo@3x.webp";
import "./Welcome.css";
import { useNavigate } from "react-router";

const WelcomeScreen = (props) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.open) {
      const timeout = setTimeout(() => {
        props.onClose();
        navigate("/dashboard");
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, []);

  return (
    <div style={{ display: "flex", width: "100%", backgroundColor: "#fff" }}>
      <div style={{ position: "relative", width: "100%" }}>
        <img src={bgImage} alt="" style={{ width: "100%", height: "90vh" }} />
        <div className="left_music logo">
          <img src={leftLogo} alt="Left Logo" style={{ width: "100%" }} />
        </div>
        <div className="center_logo">
          <p className="welcome_text">WELCOME TO</p>
          <img className="center_image" src={middleLogo} alt="Middle Logo" />
        </div>
        <div className="right_music logo">
          <img src={rightLogo} alt="Right Logo" style={{ width: "100%" }} />
        </div>
        <div className="bottom_container" style={{ width: "100%" }}>
          <p className="bottom_text">MAKE MUSIC HAPPEN</p>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
