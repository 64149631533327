import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Typography, Stack, Paper } from "@mui/material";
import { connect } from "react-redux";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import YTNetRevenueByMonthAction from "../../stores/monthwiseRevenueYT/YTNetRevenueByMonthAction";
import YTMonthwiseRevenueTabView from "./YTMonthwiseRevenueTabView";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH,
  ]),
  ytNetRevenueByMonth: state.ytNetRevenueByMonth.ytNetRevenueByMonth || [],
});

function YTNetRevenueByMonth(props) {
  const { isRequesting } = props;

  const [monthSelected, setMonthSelected] = useState(null);

  const [filterLoader, setFilterLoader] = useState(false);
  const [alertPopUp, setAlertPopUp] = useState(false);

  useEffect(() => {
    fetchNetRevenueByMonth();
  }, []);

  function fetchNetRevenueByMonth(params) {
    props
      .dispatch(YTNetRevenueByMonthAction.requestNetRevenueByMonth(params))
      .then(() => {
        setFilterLoader(false);
      });
  }

  let clientList = [];
  props?.clientFilter?.hits?.hits?.map((item) => {
    if (item?._source?.client_name)
      clientList.push({
        id: item?._id,
        client_name: item._source.client_name,
      });
  });

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
      hide: true,
    },

    {
      field: "key",
      headerName: `Month`,
      flex: 3,
      renderCell: (params) => {
        return <span>{returnMonthName(params.row.key)}</span>;
      },
    },
    {
      field: "count",
      headerName: "Total Revenue ($)",
      flex: 3,
    },
  ];

  const rows = [];
  if (props.ytNetRevenueByMonth["Net Revenue By Month"]) {
    props.ytNetRevenueByMonth["Net Revenue By Month"]?.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: index + 1,
        count: item.total_revenue,
        key: item.key,
        summary: item.summary,
      });
    });
  }

  function returnMonthName(monthValue) {
    // expected the month value be like 2023-09
    const dateString = monthValue;
    const [year, month] = dateString.split("-");
    const formattedDate = new Date(`${year}-${month}-01`).toLocaleDateString(
      "en-US",
      { year: "numeric", month: "long" }
    );

    return formattedDate;
  }

  console.log("YT_revenue", props);

  return (
    <React.Fragment>
      {navigator.onLine ? (
        <>
          {!monthSelected && (
            <Box sx={{ padding: 5 }}>
              <Grid
                display="flex"
                justifyContent="center"
                container
                item
                lg={12}
                md={12}
                sm={12}
              >
                <Grid
                  sx={{
                    fontSize: "24px",
                    display: "flex",
                    alignItems: "center",
                  }}
                  gap={1}
                >
                  <span style={{ fontWeight: "bold" }}>
                    {formatName("Monthwise Revenue")}
                  </span>
                </Grid>

                <Grid item xs={12} mx={3}>
                  <LoadingIndicator isActive={isRequesting}>
                    {!isRequesting &&
                    props.ytNetRevenueByMonth["Net Revenue By Month"] ? (
                      <DataGridFunction
                        curser="pointer"
                        columns={columns}
                        rows={rows}
                        rowHeight={60}
                        height={"74vh"}
                        onRowClick={(e) => {
                          props
                            .dispatch(
                              YTNetRevenueByMonthAction.requestNetRevenueByMonthFilterClear()
                            )
                            .then(() => {
                              setMonthSelected(e?.row?.key);
                            });
                        }}
                      />
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "70vh",
                        }}
                      >
                        <LoadingBgImage />
                      </Box>
                    )}
                  </LoadingIndicator>
                </Grid>
              </Grid>
            </Box>
          )}

          {monthSelected && (
            <Box>
              <YTMonthwiseRevenueTabView
                setMonthSelected={setMonthSelected}
                monthSelected={monthSelected}
              />
            </Box>
          )}
        </>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Please select a filter first"}
      />
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(YTNetRevenueByMonth);
