import BaseReducer from "../../utilities/BaseReducer";
import NotificationAction from "./NotificationAction";

export default class NotificationReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    Notifications: [],
  };

  //operation Filter
  [NotificationAction.REQUEST_NOTIFICATIONS_FINISHED](state, action) {
    return {
      ...state,
      Notifications: action.payload,
    };
  }
}
