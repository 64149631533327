import BaseReducer from "../../utilities/BaseReducer";
import ActressAction from "./ActressAction";

export default class ActressFilterReducer extends BaseReducer {
  //initial state of ActressFilterReduser
  initialState = {
    actressFilter: [],
  };
  //ActressFilterReduser request action finish
  [ActressAction.REQUEST_ACTRESS_FILTER_FINISHED](state, action) {
    return {
      ...state,
      actressFilter: action.payload,
    };
  }

  [ActressAction.REQUEST_ACTRESS_FILTER_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      actressFilter: action.payload,
    };
  }
}
