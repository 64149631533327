import React, { useEffect, useState, useRef } from "react";
import { Tabs, Tab, Box, Grid, Typography, IconButton } from "@mui/material";
import { connect } from "react-redux";
import InterpreterModeIcon from "@mui/icons-material/InterpreterMode";
import LibraryMusicRoundedIcon from "@mui/icons-material/LibraryMusicRounded";
import PublicTwoToneIcon from "@mui/icons-material/PublicTwoTone";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import TrackwiseRevenue from "./TrackwiseRevenue";
import ReleasewiseRevenue from "./ReleasewiseRevenue";
import ArtistwiseRevenue from "./ArtistwiseRevenue";
import StorewiseRevenueReport from "./StorewiseRevenueReport";
import CountrywiseRevenueReport from "./CountrywiseRevenueReport";
import LabelRevenueReport from "./LabelRevenueReport";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import LabelImportantIcon from "@mui/icons-material/LabelImportant";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import UserAction from "../../stores/user/UserAction";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [UserAction.REQUEST_USER_BY_ID]),
  userprofile: state.userprofile.userprofile || [],
});

const subTabsUI = {
  fontWeight: "bold",
  textTransform: "none",
  fontSize: "16px",
  "&.Mui-selected": {
    boxShadow: "0px 0px 20px 4px rgba(0, 0, 0, 0.6)",
  },
  "&:hover": {
    "& .MuiSvgIcon-root": {
      transition: "transform 0.9s",
      transform: "scale(1.3)",
    },
  },
};

function A3Reports(props) {
  const [reportViewType, setReportViewType] = useState("chart");
  const [alertPopUp, setAlertPopUp] = useState(false);
  const [activeTab, setActiveTab] = React.useState(0);
  const boxRef = useRef(null);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  const prevActiveTab = useRef(activeTab);
  useEffect(() => {
    prevActiveTab.current = activeTab;
  }, [activeTab]);

  return (
    <Box
      ref={boxRef}
      style={{
        backgroundColor: theme.palette.background.default,
      }}
    >
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes rotateAnimation {
            0% {
              transform: rotate(0deg); 
            }
            50% {
              transform: rotate(-360deg);
            }
          }
          @keyframes popIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            }
            50% {
              transform: scale(1.1);
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
    `,
        }}
      />
      <>
        <Grid
          item
          container
          alignItems={"center"}
          justifyContent="flex-start"
          style={{
            position: "sticky",
            top: `${boxRef?.current?.offsetTop}px`,
            backgroundColor: theme.palette.background.default,
            zIndex: 10,
          }}
        >
          <Grid
            container
            item
            xs={12}
            px={2}
            py={1}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <Grid item>
              {["chart", "grid"].map((item) => (
                <IconButton
                  sx={{
                    marginX: 1,
                    backgroundColor:
                      item === reportViewType
                        ? theme.palette.background.paper
                        : "none",
                    borderRadius: item === reportViewType ? 2 : 0,
                    padding: item === reportViewType ? 1 : 0,
                    boxShadow: item === reportViewType ? theme.shadows : "none",
                    borderBottom:
                      item === reportViewType
                        ? `2px solid ${theme.palette.primary.main}`
                        : "none",
                  }}
                  onClick={() => {
                    setReportViewType(item);
                  }}
                >
                  {item === "grid" ? (
                    <Typography sx={{ color: theme.palette.primary.main }}>
                      Tabel View
                    </Typography>
                  ) : (
                    <Typography sx={{ color: theme.palette.primary.main }}>
                      Chart View
                    </Typography>
                  )}
                </IconButton>
              ))}
            </Grid>
          </Grid>

          <Grid
            item
            container
            alignItems={"center"}
            justifyContent="flex-start"
            style={{
              position: "sticky",
              top: `${boxRef?.current?.offsetTop}px`,
              backgroundColor: theme.palette.background.default,
              zIndex: 10,
            }}
          >
            <Grid item xs={12}>
              <Tabs
                variant="fullWidth"
                value={activeTab}
                scrollButtons
                allowScrollButtonsMobile
                onChange={handleTabChange}
                centered
                style={{
                  marginRight: "20px",
                  marginTop: "5px",
                  marginBottom: "10px",
                  backgroundColor: theme.palette.background.default,
                  zIndex: 10,
                  "&.Mui-focusVisible": {
                    backgroundColor: "rgba(100, 95, 228, 0.32)",
                  },
                }}
              >
                <Tab
                  label="Top Storewise Revenue"
                  icon={
                    <LocalGroceryStoreIcon
                      style={{
                        fontSize: 30,
                        transition: "transform 0.3s",
                      }}
                    />
                  }
                  sx={subTabsUI}
                />
                <Tab
                  label="Top Countrywise Revenue"
                  icon={
                    <PublicTwoToneIcon
                      style={{
                        fontSize: 30,
                        transition: "transform 0.3s",
                      }}
                    />
                  }
                  sx={subTabsUI}
                />
                <Tab
                  label="Top Labelwise Revenue"
                  icon={
                    <LabelImportantIcon
                      style={{
                        fontSize: 30,
                        transition: "transform 0.3s",
                      }}
                    />
                  }
                  sx={subTabsUI}
                />

                <Tab
                  label="Top Trackwise Revenue"
                  icon={
                    <LibraryMusicRoundedIcon
                      style={{
                        fontSize: 30,
                        transition: "transform 0.3s",
                      }}
                    />
                  }
                  sx={subTabsUI}
                />

                <Tab
                  label="Top Releasewise Revenue"
                  icon={
                    <NewReleasesIcon
                      style={{
                        fontSize: 30,
                        transition: "transform 0.3s",
                      }}
                    />
                  }
                  sx={subTabsUI}
                />

                <Tab
                  label="Top Artistwise Revenue"
                  icon={
                    <InterpreterModeIcon
                      style={{
                        fontSize: 30,
                        transition: "transform 0.3s",
                      }}
                    />
                  }
                  sx={subTabsUI}
                />
              </Tabs>
            </Grid>
          </Grid>
        </Grid>

        <React.Fragment>
          {props.userprofile?._source?.owned_labels?.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                padding: "20px",
              }}
            >
              <h1>Owned Label Missing</h1>
              <h2>
                The Owned Label is not specified. Please contact your client{" "}
                {props.userprofile?._source?.created_by?.user_name} to access
                your revenue data.
              </h2>
            </Box>
          ) : (
            <>
              {activeTab === 0 && (
                <StorewiseRevenueReport reportViewType={reportViewType} />
              )}
              {activeTab === 1 && (
                <CountrywiseRevenueReport reportViewType={reportViewType} />
              )}
              {activeTab === 2 && (
                <LabelRevenueReport reportViewType={reportViewType} />
              )}

              {activeTab === 3 && (
                <TrackwiseRevenue reportViewType={reportViewType} />
              )}
              {activeTab === 4 && (
                <ReleasewiseRevenue reportViewType={reportViewType} />
              )}
              {activeTab === 5 && (
                <ArtistwiseRevenue reportViewType={reportViewType} />
              )}
            </>
          )}
        </React.Fragment>
      </>
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Please apply some filters !"}
      />
    </Box>
  );
}

export default connect(mapStateToProps)(A3Reports);
