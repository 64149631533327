import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Bar, Doughnut, Pie, PolarArea } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, Grid, Typography } from "@mui/material";
import { theme } from "../App";
import NoDataFoundReports from "../reports/NoDataFoundReports";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import YTNetRevenueByMonthAction from "../../stores/monthwiseRevenueYT/YTNetRevenueByMonthAction";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    YTNetRevenueByMonthAction.REQUEST_YT_NET_REVENUE_BY_MONTH_FILTER,
  ]),
  ytNetRevenueByMonthFilter:
    state.ytNetRevenueByMonthFilter.ytNetRevenueByMonthFilter || [],
});

function YTMonthwiseRevenueChartView(props) {
  const { params, isRequesting } = props;

  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = props.ytNetRevenueByMonthFilter;

  let monthList = [];
  let dataSetList = [];

  data &&
    data &&
    data["Net Revenue By Month"] &&
    data["Net Revenue By Month"].length > 0 &&
    data["Net Revenue By Month"].map((item) => {
      const [year, month] = item.key?.split("-");
      const formattedDate = new Date(`${year}-${month}-01`).toLocaleDateString(
        "en-US",
        { year: "numeric", month: "short" }
      );

      monthList.push(formattedDate);

      let tempArray = [];
      item?.summary.forEach((summaryItem) => {
        tempArray.push({
          name:
            typeof summaryItem.key === "string"
              ? summaryItem.key
              : `${summaryItem.key.name}`,
          count: summaryItem.revenue,
        });
      });
      dataSetList.push(tempArray);
    });

  let uniqueNames = [
    ...new Set(
      dataSetList.flatMap((item) => item.map((innerItem) => innerItem.name))
    ),
  ];

  let uniqueNamesWithCounts = [];

  function uniqueNamesWithCount() {
    const resultArray = [];
    uniqueNames.forEach((name) => {
      const filteredData = dataSetList.map((item) =>
        item.filter((innerItem) => innerItem.name === name)
      );

      const countArray = filteredData.map((item) =>
        item.length > 0 ? item[0].count : 0
      );

      const resultObject = {
        name: name,
        count: countArray,
      };

      resultArray.push(resultObject);
    });
    uniqueNamesWithCounts = resultArray;
  }
  uniqueNamesWithCount();

  const colors = [
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
    `${theme.pieChart.color3}`,
    `${theme.pieChart.color4}`,
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color6}`,
  ];

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  let TotalList = [];

  let countData = [];
  let nameLabels = [];

  uniqueNamesWithCounts.map((item, index) => {
    TotalList.push({
      count: item.count,
      name: item.name,
    });
  });

  nameLabels = TotalList.map((item) => {
    return item.name;
  });

  countData = TotalList.map((item) => {
    return Number(item.count).toFixed(2);
  });

  const state = {
    labels: monthList,
    datasets: uniqueNamesWithCounts.map((item, index) => ({
      label: item.name,
      data: item.count,
      backgroundColor: index < colors.length ? colors[index] : getRandomColor(),
      barThickness: 20,
    })),
  };

  const PolarAreaState = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        label: nameLabels,
        data: countData && countData,
        backgroundColor:
          countData &&
          countData.map((item, index) =>
            index < colors.length ? colors[index] : getRandomColor()
          ),
        borderWidth: 3,
      },
    ],
  };

  const donutState = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        type: "doughnut",
        label: nameLabels && nameLabels,
        data: countData && countData,
        borderWidth: 2,
        backgroundColor: colors,
      },
    ],
  };

  const barState = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        label: nameLabels,
        data: countData && countData,
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const territoryState = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        label: nameLabels,
        data: countData && countData,
        backgroundColor: colors,
        borderWidth: 1,
      },
    ],
  };

  const pieState = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        type: "pie", // Change type to 'pie' for a pie chart
        label: nameLabels && nameLabels,
        data: countData && countData,
        borderWidth: 2,
        backgroundColor: colors,
      },
    ],
  };

  const pieOptions = {
    responsive: false,
    animation: true,

    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: true,
        position: "right",
        font: {
          weight: "bold",
        },
        labels: {
          usePointStyle: true,
          padding: 30,
          align: "end",
          generateLabels: function (chart) {
            const { data } = chart;
            if (data?.labels?.length) {
              return data.labels.map((label, i) => {
                return {
                  text: `${label}`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const countLabel = countData[context.dataIndex] || 0;
            return `${label}: ${countLabel}`;
          },
        },
      },
      datalabels: {
        align: "end",
        anchor: "end",
        display: true,
        color: colors,
      },
    },

    label: {
      display: false,
    },
  };

  const territoryOptions = {
    indexAxis: "y", // Set to 'y' for horizontal bar chart
    responsive: true,
    animation: {
      duration: 2000,
      easing: "easeInOutQuart",
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Revenue ($)",
          color: `${theme.palette.primary.main}`,
          font: {
            size: `${theme.typography.fontSize}`,
            // weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Top Countrywise Revenue",
        font: {
          size: 20,
          weight: "bold",
        },
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          color: theme.palette.text.primary,
          font: {
            size: 14,
            weight: "bold",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const countLabel = countData[context.dataIndex] || 0;
            return `Revenue (${label}): ${countLabel}`;
          },
        },
      },
    },
  };

  const barOptions = {
    responsive: true,
    animation: {
      duration: 2000, // Animation duration in milliseconds
      easing: "easeInOutQuart", // Easing function for animation
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
          text: "Revenue ($)",
          color: `${theme.palette.primary.main}`,
          font: {
            size: `${theme.typography.fontSize}`,
            // weight: "bold",
          },
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      title: {
        display: true,
        text: "Top Countrywise Revenue",
        font: {
          size: 20,
          weight: "bold",
        },
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          color: theme.palette.text.primary,
          font: {
            size: 11,
            weight: "bold",
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const countLabel = countData[context.dataIndex] || 0;
            return `Revenue (${label}): ${countLabel}`;
          },
        },
      },
    },
  };

  const options = {
    type: "bar",
    responsive: true,
    indexAxis: "y",
    animations: {
      tension: {
        duration: 900,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    hoverBorderColor: "black",
    hoverBorderWidth: 1,
    borderWidth: 0.5,
    borderColor: "black",
    // barThickness: 20,
    interaction: {
      intersect: false,
    },

    scales: {
      x: {
        stacked: true,
        title: {
          display: true,
          text: "Revenue ($)",
          color: `${theme.palette.primary.main}`,
          font: {
            size: `${theme.typography.fontSize}`,
            // weight: "bold",
          },
        },
      },
      y: {
        stacked: true,
        title: {
          display: true,
          text: "Month",
          color: `${theme.palette.primary.main}`,
          font: {
            size: `${theme.typography.fontSize}`,
            // weight: "bold",
          },
        },
      },
    },
  };

  const polarAreaOptions = {
    responsive: true,
    animations: {
      tension: {
        duration: 900,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },

    // cutout: "60%",
    hoverBorderColor: colors,
    hoverBorderWidth: 2,

    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: true,
        position: "right",
        font: {
          weight: "bold",
        },
        labels: {
          usePointStyle: true,
          padding: 27,
          align: "end",
          generateLabels: function (chart) {
            const { data } = chart;
            if (data?.labels?.length) {
              return data.labels.map((label, i) => {
                return {
                  text: `${label}`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            console.log("context", "2", context);
            const label = context.label || "";
            const countLabel = countData[context.dataIndex] || 0;
            return `${label}: ${countLabel}`;
          },
        },
      },
      datalabels: {
        align: "end",
        anchor: "end",
        display: true,
        color: colors,
      },
    },

    radius: "100%",

    label: {
      display: false,
    },
  };

  const donutOptions = {
    responsive: true,
    animations: {
      tension: {
        duration: 900,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },

    // cutout: "60%",
    hoverBorderColor: "black",
    hoverBorderWidth: 0.5,

    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: true,
        position: "right",
        font: {
          weight: "bold",
        },
        labels: {
          usePointStyle: true,
          padding: 27,
          align: "end",

          generateLabels: function (chart) {
            const { data } = chart;
            if (data?.labels?.length) {
              return data.labels.map((label, i) => {
                return {
                  text: `${label}`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const countLabel = countData[context.dataIndex] || 0;
            return `${label}: ${countLabel}`;
          },
        },
      },
      datalabels: {
        align: "end",
        anchor: "end",
        display: true,
        color: colors,
      },
    },

    radius: "100%",

    label: {
      display: false,
    },
  };

  const finalData = data["Net Revenue By Month"]?.filter((item) =>
    item.summary
      .map((summaryItem) => summaryItem.revenue)
      .some((value) => value !== 0)
  );

  const totalRevenues = data["Net Revenue By Month"]?.map((item) => {
    const date = new Date(item.key);
    const formattedKey = `${date.toLocaleString("default", {
      month: "short",
    })} ${date.getFullYear()}`;
    const formattedRevenue = parseFloat(item.total_revenue);

    return {
      key: formattedKey,
      total_revenue: formattedRevenue,
    };
  });

  console.log("YT_revenue_data", data);

  return (
    <React.Fragment>
      <style
        dangerouslySetInnerHTML={{
          __html: `
          @keyframes popIn {
            0% {
              opacity: 0;
              transform: scale(0.8);
              box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
            }
            50% {
              transform: scale(1.05);
              box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
            }
            100% {
              opacity: 1;
              transform: scale(1);
            }
          }
    `,
        }}
      />
      <Grid
        container
        item
        xs={12}
        xl={12}
        lg={12}
        md={12}
        justifyContent="center"
        alignItems={"center"}
        px={3}
      >
        <LoadingIndicator isActive={isRequesting}>
          {!isRequesting && (
            <>
              {finalData && finalData.length > 0 && (
                <>
                  <Typography
                    sx={{
                      // width: "90vh",
                      paddingTop: "20px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      animation: "popIn 0.4s ease-in-out",
                      border: "2px solid rgba(0, 0, 0, 0.1)",
                      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                    }}
                  >
                    <div
                      style={{
                        margin: "auto",
                        width: "100%",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {props.tab && props.tab.tablabel === "Retailer Based" && (
                        <Box height={400} width={400}>
                          <PolarArea
                            style={{
                              alignItems: "center",
                              height: "400px",
                              width: "400px",
                            }}
                            data={PolarAreaState}
                            options={polarAreaOptions}
                          />
                        </Box>
                      )}

                      {props.tab && props.tab.tablabel === "Label Based" && (
                        <Box height={400} width={400}>
                          <Doughnut
                            style={{
                              alignItems: "center",
                              height: "400px",
                              width: "400px",
                            }}
                            data={donutState}
                            options={donutOptions}
                          />
                        </Box>
                      )}

                      {((props.tab && props.tab.tablabel === "Track Based") ||
                        (props.tab &&
                          props.tab.tablabel === "Artist Based")) && (
                        <Box
                          height={500}
                          style={{
                            margin: "auto",
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Bar
                            style={{
                              alignItems: "center",
                              padding: 10,
                            }}
                            data={barState}
                            options={barOptions}
                          />
                        </Box>
                      )}

                      {props.tab &&
                        props.tab.tablabel === "Territory Based" && (
                          <Box
                            height={450}
                            style={{
                              margin: "auto",
                              width: "100%",
                              textAlign: "center",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Bar
                              style={{
                                alignItems: "center",
                                padding: 10,
                              }}
                              data={territoryState}
                              options={territoryOptions}
                            />
                          </Box>
                        )}

                      {props.tab && props.tab.tablabel === "Release Based" && (
                        <Box
                          height={500}
                          width={800}
                          style={{
                            margin: "auto",
                            width: "100%",
                            textAlign: "center",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                          // className="bg-success"
                        >
                          <Pie
                            height={500}
                            width={800}
                            style={{
                              alignItems: "center",
                              // backgroundColor: "gray",
                            }}
                            data={pieState}
                            options={pieOptions}
                          />
                        </Box>
                      )}

                      {((props.tab && props.tab.tablabel === "UPC Based") ||
                        (props.tab && props.tab.tablabel === "ISRC Based") ||
                        (props.tab &&
                          props.tab.tablabel === "Catalogue No. Based") ||
                        (props.tab &&
                          props.tab.tablabel === "Genre Based")) && (
                        <Bar data={state} options={options} />
                      )}

                      {/* <Bar data={state} options={options} /> */}
                    </div>
                    <Box
                      sx={{
                        paddingX: "12px",
                        paddingY: "5px",
                        backgroundColor: theme.palette.primary.main,
                        color: "white",
                        width: "170px",
                        borderRadius: "2px",
                        // marginLeft: "-13px",
                        textAlign: "center",
                        position: "relative",
                      }}
                    >
                      TOTAL REVENUE
                    </Box>
                    <Box sx={{ marginX: "50px", marginY: "20px" }}>
                      <Grid container spacing={2}>
                        {totalRevenues.map((item) => (
                          <Grid item key={item.key} xs={2}>
                            <Box
                              sx={{
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                                padding: "8px",
                                textAlign: "center",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                                fontWeight: "bold",
                              }}
                            >
                              {item.key}:{` ${item.total_revenue} $`}
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Typography>
                </>
              )}
            </>
          )}
          {!isRequesting && finalData?.length == 0 && <NoDataFoundReports />}
        </LoadingIndicator>
      </Grid>
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(YTMonthwiseRevenueChartView);
