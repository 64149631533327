import { BaseModel } from "sjs-base-model";

export default class A3ReportsReleaseModel extends BaseModel {
  client_wise_delivered_data = [];

  constructor(data1) {
    super();
    this.update(data1);
  }
}
