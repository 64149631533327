import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Doughnut, PolarArea } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { theme } from "../App";
import NoDataFoundReports from "./NoDataFoundReports";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import A3ReportsAction from "../../stores/reportsA3/A3ReportsAction";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import ListIcon from "@mui/icons-material/List";
import MuiSnackbarAlert from "../../components/muiSnackbarAlert/MuiSnackbarAlert";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import NoDataFound from "../../components/nodatafound/NoDataFound";
import { getRandomColor } from "./ReportsReusedFunctions";
import ReactEcharts from "echarts-for-react";
// import DateSelectionPopperReports from "./DateSelectionPopperReports";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    A3ReportsAction.REQUEST_A3REPORTS_STOREWISE,
  ]),
  storewiseRevenueReport:
    state.storewiseRevenueReport.storewiseRevenueReport || [],
});

function StorewiseRevenueReport(props) {
  const { isRequesting, reportViewType } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const [alertPopUp, setAlertPopUp] = useState(false);

  function fetchA3ReportsStorewise(param) {
    props.dispatch(A3ReportsAction.requestStorewiseReports(param));
  }

  useEffect(() => {
    if (selectedDate) {
      dateFilterUpdater();
      return;
    } else if (!isRequesting) {
      let params = {
        youtube: true,
        current_page: "raw_report",
      };

      fetchA3ReportsStorewise(params);
    }
  }, [selectedDate]);

  function dateFilterUpdater() {
    let newFilters = [];
    let param;

    let newSelectedDate = selectedDate.replace(
      /current_date/g,
      "retailer_reporting_period"
    );
    newFilters.push(`${newSelectedDate}`);

    param = {
      current_page: "raw_report",
      filters: `[${newFilters}]`,
      youtube: true,
    };

    fetchA3ReportsStorewise(param);
  }

  ChartJS.register(ArcElement, Tooltip, Legend);

  const data = props.storewiseRevenueReport;

  let TotalList = [];

  data &&
    data.store_wise_revenue &&
    data.store_wise_revenue.length > 0 &&
    data.store_wise_revenue.map((item) => {
      TotalList.push({
        count: item.revenue,
        name: formatStoreName(item.key),
        percentage: item.percentage,
      });
    });
  console.log(TotalList);
  let countData = [];
  let nameLabels = [];

  nameLabels = TotalList.map((item) => {
    return item.name;
  });

  countData = TotalList.map((item) => {
    return Number(item.count).toFixed(2);
  });

  let percentagedata = [];

  percentagedata = TotalList.map((item) => {
    return Number(item.percentage).toFixed(2);
  });

  const colors = [
    `rgba(252, 141, 114, 0.5)`,
    `rgba(34, 188, 186, 0.5)`,
    `rgba(202, 229, 225, 0.5)`,
    `rgba(184, 199, 192, 0.5)`,
    `rgba(65, 128, 142, 0.5)`,
    `rgba(166, 155, 172, 0.5)`,
  ];

  countData.map((item, index) => {
    index < colors.length && colors.push(getRandomColor());
  });

  const state = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        label: "Storewise Revenue",
        data: countData && countData,
        backgroundColor:
          countData &&
          countData.map((item, index) =>
            index < colors.length ? colors[index] : getRandomColor()
          ),
        borderWidth: 3,
      },
    ],
  };

  const getOptions = () => {
    const series = [];
    const percentageLookup = {};
    const formatValue = (value) => {
      if (value >= 1e6) {
        return (value / 1e6).toFixed(2) + "M";
      } else if (value >= 1e3) {
        return (value / 1e3).toFixed(2) + "K";
      }
      return value;
    };
    nameLabels.forEach((key, index) => {
      percentageLookup[key] = percentagedata[index];
      series.push({
        name: key,
        type: "bar",
        coordinateSystem: "polar",
        emphasis: {
          focus: "series",
        },
        data: [countData[index]],
        itemStyle: {
          color: colors[index],
          borderRadius: [1, 1, 1, 1],
        },
      });
    });
    return {
      tooltip: {
        trigger: "item",
        formatter: (params) => {
          let val = params.value;
          let formattedvalue = formatValue(val);
          const percentage = percentageLookup[params.seriesName];
          const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
          return `${colorSpan}${params.seriesName}: ${formattedvalue} (${percentage}%)`;
        },
      },

      angleAxis: {
        type: "category",
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false, // Hide the ticks on the outer circle
        },
        axisLabel: {
          show: false, // Hide the labels on the outer circle
        },
      },
      legend: {
        show: true,
        orient: "vertical",
        icon: "circle", // Set the orientation of the legend=        icon: "circle",
        right: 0,
        top: "10%",
        type: "scroll",
        itemGap: 20,
        itemStyle: {
          borderColor: "#6c757d",
          borderWidth: 1,
        },
        itemHeight: 17,
      },
      radiusAxis: {
        axisLine: {
          show: false, // Hide the radius axis line
        },
        axisTick: {
          show: false, // Hide the ticks on the radius axis
        },
        axisLabel: {
          hideOverlap: true,
        },
      },
      polar: { radius: ["1%", "60%"] },

      series: series,
    };
  };

  const handleDateSelection = (date) => {
    setSelectedDate(date);
  };

  function formatDateRange(dateRangeString) {
    const dateRanges = dateRangeString?.split("],[");

    if (dateRanges?.length >= 2) {
      let [, , , , startDate] = dateRanges[0].split(",");
      let [, , , , endDate] = dateRanges[1].split(",");
      endDate = endDate.replace("]", "");

      const options = { year: "numeric", month: "short", day: "numeric" };
      startDate = new Date(startDate).toLocaleDateString("en-US", options);
      endDate = new Date(endDate).toLocaleDateString("en-US", options);
      return `${endDate} - ${startDate}`;
    }
  }

  const finalData = data?.store_wise_revenue?.filter(
    (item) => item.revenue !== 0
  );

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
      hide: true,
    },

    {
      field: "key",
      headerName: `Retailer Name`,
      flex: 3,
    },
    {
      field: "revenue",
      headerName: "Revenue ($)",
      flex: 3,
    },
    {
      field: "percentage",
      headerName: "Percentage (%)",
      flex: 3,
    },
  ];

  const rows = [];
  let totalRevenue = 0;

  if (props.storewiseRevenueReport?.store_wise_revenue) {
    props.storewiseRevenueReport?.store_wise_revenue?.map((item, index) => {
      totalRevenue += item.revenue;

      return rows.push({
        sl_no: index + 1,
        id: index + 1,
        revenue: item.revenue,
        key: formatStoreName(item.key),
        percentage: item.percentage,
      });
    });
  }

  function formatStoreName(name) {
    const words = name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
    return words.join(" ");
  }

  return (
    <React.Fragment>
      {navigator.onLine ? (
        <Grid
          container
          item
          xs={12}
          xl={12}
          lg={12}
          md={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          px={5}
          gap={3}
        >
          <Grid
            item
            sx={{
              fontSize: "24px",
            }}
          >
            <Grid item>
              <span style={{ fontWeight: "bold" }}>
                {formatName("Storewise Revenue Report")} :{" "}
                {`${totalRevenue?.toFixed(2)} $`}
              </span>
              {/* <span>|</span> */}
              <span
                style={{
                  color: "grey",
                }}
              ></span>
            </Grid>
          </Grid>

          <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />

          <LoadingIndicator isActive={isRequesting}>
            {!isRequesting ? (
              <>
                {reportViewType === "grid" ? (
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={60}
                    height={"74vh"}
                  />
                ) : finalData && finalData.length > 0 ? (
                  <Typography
                    sx={{
                      width: "90vh",
                      marginLeft: "auto",
                      marginRight: "auto",
                      animation: "popIn 0.4s ease-in-out",
                      border: "2px solid rgba(0, 0, 0, 0.1)",
                      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.1)",
                      backgroundColor: "#FFFFFF",
                      borderRadius: "8px",
                    }}
                  >
                    <div style={{ marginright: "15%;" }}>
                      <ReactEcharts
                        style={{
                          height: "61vh",
                          width: "90%",
                        }}
                        option={getOptions()}
                      />
                    </div>
                  </Typography>
                ) : (
                  <NoDataFound />
                )}
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "70vh",
                }}
              >
                <LoadingBgImage />
              </Box>
            )}
          </LoadingIndicator>
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
      <MuiSnackbarAlert
        open={alertPopUp}
        onClose={() => setAlertPopUp(false)}
        message={"Please select a filter first"}
      />
    </React.Fragment>
  );
}
export default connect(mapStateToProps)(StorewiseRevenueReport);
