import React from "react";
//import { DataGrid } from "@mui/x-data-grid-pro";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { Tabs, Tab } from "@mui/material";
import AlbumIcon from "@mui/icons-material/Album";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import FaceIcon from "@mui/icons-material/Face";
import PeopleIcon from "@mui/icons-material/People";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SpeakerPhoneIcon from "@mui/icons-material/SpeakerPhone";
import MusicVideoIcon from "@mui/icons-material/MusicVideo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TopTracks from "./components/TopTracks";
import TopReleases from "./components/TopReleases";
import TopArtists from "./components/TopArtists";
import TopLabels from "./components/TopLabels";
import TopStores from "./components/TopStores";
import TopTerritories from "./components/TopTerritories";
import TopVideos from "./components/TopVideos";
import TopLanguage from "./components/TopLanguage";
import TopComposer from "./components/TopComposer";
import TopAlbum from "./components/TopAlbum";
import TopLyricist from "./components/TopLyricist";

function Analytics() {
  // const columns = [
  //   {
  //     field: "sl_no",
  //     headerName: "SL NO",
  //     width: 150,
  //     flex: 2,
  //     resizable: false,
  //     align: "center",
  //     headerAlign: "center",
  //   },
  //   {
  //     field: "id",
  //     headerName: "ID",
  //     width: 120,
  //     hide: true,
  //   },
  //   {
  //     field: "color",
  //     headerName: "color",
  //     width: 250,
  //     flex: 4,
  //     hide: true,
  //   },

  //   {
  //     field: "created_by",
  //     headerName: "Created By",
  //     width: 250,
  //     flex: 4,
  //     resizable: false,
  //   },
  // ];

  // const getRowId = (row) => {
  //   return row;
  // };

  // const rows = [
  //   {
  //     sl_no: "1",
  //     id: "1234",
  //     color: "Val3",
  //     created_by: "Val4",
  //   },
  // ];

  const [activeTab, setActiveTab] = React.useState(0);

  const handleTabChange = (event, newTab) => {
    setActiveTab(newTab);
  };

  return (
    <div>
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        //aria-label="Analytics tabs"
        //style={{ display: "flex", alignItems: "center" }}
      >
        <Tab
          label="Top tracks"
          icon={<AlbumIcon />}
          //sx={{ display: "flex", alignItems: "center" }}
        />
        <Tab label="Top releases" icon={<WorkOutlineIcon />} />
        <Tab label="Top artists" icon={<FaceIcon />} />
        <Tab label="Top labels" icon={<PeopleIcon />} />
        <Tab label="Top videos" icon={<PlayCircleOutlineIcon />} />
        {/* <Tab label="Top ringtones" icon={<SpeakerPhoneIcon />} /> */}
        <Tab label="Top stores" icon={<MusicVideoIcon />} />
        <Tab label="Top territories" icon={<LocationOnIcon />} />
        <Tab label="Top Language" icon={<PeopleIcon />} />
        <Tab label="Top Composer" icon={<PeopleIcon />} />
        <Tab label="Top Album" icon={<PeopleIcon />} />
        <Tab label="Top Lyricist" icon={<PeopleIcon />} />
      </Tabs>

      {activeTab === 5 && <TopStores />}
      {activeTab === 1 && <TopReleases />}
      {activeTab === 2 && <TopArtists />}
      {activeTab === 3 && <TopLabels />}
      {activeTab === 4 && <TopVideos />}
      {activeTab === 0 && (
        <div style={{ height: 400, width: "100%" }}>
          {/* <DataGridFunction
            columns={columns}
            rows={rows}
            rowHeight={60}
            //rowReordering
            getRowId={getRowId}
          /> */}
          <TopTracks />
        </div>
      )}
      {activeTab === 6 && <TopTerritories />}
      {activeTab === 7 && <TopLanguage />}
      {activeTab === 8 && <TopComposer />}
      {activeTab === 9 && <TopAlbum />}
      {activeTab === 10 && <TopLyricist />}
    </div>
  );
}

export default Analytics;
