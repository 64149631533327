import {
  Box,
  Grid,
  Button,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Avatar,
  IconButton,
  Divider,
} from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import { ErrorMessage, FastField, Field, Form, Formik, getIn } from "formik";
import Territories from "../../components/territories/Territories";
import Plan from "../../components/plan/Plan";
import CheckIcon from "@mui/icons-material/Check";
import TrackAction from "../../stores/track/TrackAction";
import ArtistAction from "../../stores/artist/ArtistAction";
import ClientAction from "../../stores/client/ClientAction";
import AssetsAction from "../../stores/assets/AssetsAction";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import AlbumIcon from "@mui/icons-material/Album";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import MuiButton from "../../components/muiButton/MuiButton";
import environment from "environment";
import { theme } from "../App";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import BreadCrumbs from "./BreadCrumbs";
import { Navigate, useNavigate } from "react-router";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import CircleLoader from "../../components/circularLoder/CircleLoader";
import WarningIcon from "@mui/icons-material/Warning";
import LanguageAction from "../../stores/language/LanguageAction";
import InfoIcon from "@mui/icons-material/Info";
import UploadPopper from "../../components/poppers/UploadPopper";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    ArtistAction.REQUEST_artist_FILTER,
    ClientAction.REQUEST_CLIENT_FILTER,
    AssetsAction.REQUEST_ASSETS,
    AssetsAction.REQUEST_ASSETS_FILTER,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    LanguageAction.REQUEST_LANGUAGE,
  ]),
  track: state.track.track.hits || [],
  artistFilter: state.artistFilter.artistFilter || [],
  clientFilter: state.clientFilter.clientFilter || [],
  assets: state.assets.assets.hits || [],
  language: state.language.language || [],
  assetsFilter: state.assetsFilter.assetsFilter || [],
  releaseById: state.releaseById.releaseById || {},
});

const CommonButton = ({ count, onClick }) => {
  return (
    <Button
      variant="contained"
      sx={{
        textTransform: "none",
        borderRadius: "10px",
        color: "white",
        backgroundColor:
          count === 0
            ? `${theme.palette.background.primary}`
            : `${theme.palette.text.error}`,
      }}
      onClick={onClick}
    >
      {count === 0 ? (
        <>
          <FontAwesomeIcon icon={faCheckDouble} />
          &nbsp;&nbsp;Details
        </>
      ) : (
        <>
          <FontAwesomeIcon icon={faExclamationCircle} />
          &nbsp;
          {`${count} Error(s)`}
        </>
      )}
    </Button>
  );
};

function Submission(props) {
  const navigate = useNavigate();
  const [saveloading, setsaveloading] = useState(false);
  const [submitloading, setsubmitloading] = useState(false);
  const [isWarningVisible, setIsWarningVisible] = useState(false);
  const boxRef = useRef(null);

  // Dispatch

  function fetchResource(resourceId) {
    let params = {
      current_page: "releases",
    };
    props.dispatch(ReleasesAction.requestReleaseById(resourceId, params));
  }

  function fetchTracks(params) {
    props.dispatch(TrackAction.requestTrack(params));
  }
  function fetchArtistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }

  function fetchlanguageFilter(param) {
    props.dispatch(LanguageAction.requestLanguageFilter(param));
  }

  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }
  function fetchAssetsFilter(param) {
    props.dispatch(AssetsAction.requestAssetsFilter(param));
  }
  function fetchAssets(param) {
    props.dispatch(AssetsAction.requestAssets(param));
  }

  const [imgUrl, setImgUrl] = useState("");

  useEffect(() => {
    if (!props.isRequesting) {
      setImgUrl(() => {
        !props.isRequesting &&
          props?.releaseById?._source?.thumbnail_url &&
          RetrieveImageUrl(props?.releaseById?._source?.thumbnail_url);
      });
    }
  }, [!props.isRequesting]);

  function RetrieveImageUrl(thumbnail_url) {
    let access_token = localStorage.getItem("Auth")
      ? JSON.parse(localStorage.getItem("Auth")).access_token
      : "";
    setImageLoader(true);

    let imageUrl = "";
    fetch(
      `${environment.api.retriveAsset}?path=thumbnail/${thumbnail_url}&current_page=submission&release_id=${props?.releaseById?._id}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + access_token,
        },
      }
    )
      .then((res) => res.blob())
      .then((blob) => {
        setImageLoader(false);

        imageUrl = URL.createObjectURL(blob);

        setImgUrl(imageUrl);
      });
  }

  useEffect(() => {
    const params = {
      order_by: `[["created_on","desc"]]`,
      page_limit: "none",
      current_page: "submission",
      filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
        "id"
      )}"]]`,
    };

    const filterparam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "submission",
    };
    let id = localStorage.getItem("id");
    fetchTracks(params);
    // fetchArtistFilter(filterparam);
    fetchClientFilter(filterparam);
    delete params.order_by;
    fetchAssets(params);
    fetchResource(id);
    // fetchArtistFilter(params);
  }, []);

  let assets_list = [];
  if (props.assets && props.assets.hits) {
    props.assets.hits.length !== 0 &&
      props.assets.hits.map((item) => {
        if (item._source?.asset_url) {
          assets_list.push({
            assets_id: item._id,
            assets_name: item._source.assets_name,
            assets_url: item._source.asset_url,
          });
        }
      });
  }

  function UpdateToFinalRelease() {
    setsubmitloading(true);
    let id = localStorage.getItem("id");

    let params = {
      current_page: "submission",
    };

    props
      .dispatch(ReleasesAction.requestPostFinalRelease(null, id, params))
      .then(() => {
        setsubmitloading(false);
        props.dispatch(ReleasesAction.requestReleaseById(id, params));
        navigate("/releases");
      });
  }

  let releaseInfoError = 0;
  let priceError = 0;
  let releaseDateError = 0;
  let territoriesError = 0;
  let promotionError = 0;
  let assetsError = 0;
  let tracksError = 0;
  let assetMapWarning = 0;
  let individualTrackErrorPresent = false;

  const { _source } = props?.releaseById && props.releaseById;

  if (_source) {
    if (_source.tracks.length !== 0) {
      _source.tracks.map((item) => {
        if (item.assets_id === "" || item.assets_id === null) {
          assetsError++;
        }
      });
    } else if (_source.existing_tracks.length == 0) {
      assetsError++;
    }
  }

  function errorValidation() {
    const dateString = _source?.main_release_date;
    const targetDate = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const isReleaseBeforeToday = targetDate < yesterday;

    const preedateString = _source?.pre_order_release_date;
    const preetargetDate = new Date(preedateString);

    const isPreeReleaseBeforeToday = preetargetDate < today;
    function isAlphanumericWithBoth(values) {
      const containsLetters = /[a-zA-Z]/.test(values);
      const containsNumbers = /[0-9]/.test(values);
      const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

      if (containsLetters && containsNumbers && !containsSpecialChars) {
        // The string contains both letters and numbers (alphanumeric) and no special characters.
        return true;
      } else {
        // The string does not meet the criteria.
        return false;
      }
    }

    if (!_source?.title) {
      releaseInfoError++;
    }
    if (!_source?.type_of_release) {
      releaseInfoError++;
    }
    if (!_source?.label) {
      releaseInfoError++;
    }
    if (!_source?.thumbnail_url) {
      releaseInfoError++;
    }

    if (!_source?.format) {
      releaseInfoError++;
    }

    if (!_source?.producer_catalogue_number) {
      releaseInfoError++;
    }

    if (_source?.producer_catalogue_number) {
      if (!isAlphanumericWithBoth(_source.producer_catalogue_number)) {
        releaseInfoError++;
      }
    }

    if (!_source?.genre_type?.id) {
      releaseInfoError++;
    }

    if (!_source?.sub_genre_type?.id) {
      releaseInfoError++;
    }

    if (!_source?.physical_release_date) {
      releaseInfoError++;
    }
    if (!_source?.plan_id) {
      releaseInfoError++;
      priceError++;
    }

    // if (!_source?.copyright_year) {
    //   releaseInfoError++;
    // }
    // if (!_source?.production_year) {
    //   releaseInfoError++;
    // }
    // if (!_source?.phonographic_copyright_year) {
    //   releaseInfoError++;
    // }

    if (_source?.artists.length == 0) {
      releaseInfoError++;
    }

    if (_source?.ask_to_generate_upc === false) {
      !_source?.UPC_EAN && releaseInfoError++;
    }

    if (!_source?.meta_language) {
      releaseInfoError++;
    }

    if (!_source?.tracks.length && !_source?.existing_tracks?.length) {
      tracksError++;
    } else {
      _source.tracks.map((track) => {
        if (track.assets_id !== "") {
          if (track.assets_id?.id) {
            let isIdPresent = assets_list.some(
              (item) => item.assets_id === track.assets_id?.id
            );
            !isIdPresent && tracksError++;
          } else {
            tracksError++;
          }
        } else {
          tracksError++;
        }
      });
    }

    if (!_source?.main_release_date) {
      releaseDateError++;
    }

    if (!_source?.copyrighted_to) {
      releaseInfoError++;
    }

    if (!_source?.published_by) {
      releaseInfoError++;
    }

    // if (
    //   !_source?.pre_order_release_date ||
    //   (_source?.pre_order_release_date && isPreeReleaseBeforeToday) ||
    //   new Date(_source?.pre_order_release_date) >
    //     new Date(_source?.main_release_date)
    // ) {
    //   releaseDateError++;
    // }
  }
  errorValidation();
  const { handleChange } = props;

  const returnAssetsObject = (assets_id) => {
    let filtered_assets = [];

    filtered_assets =
      assets_list &&
      assets_list.length !== 0 &&
      assets_list.filter((item) => {
        return item.assets_id === assets_id.assets_id;
      });

    return filtered_assets.length !== 0
      ? filtered_assets[0]
        ? filtered_assets[0]
        : null
      : null;
  };

  let rows = [];

  let nameslist = [];

  if (
    props.language &&
    typeof props.language.list_of_language_data === "object"
  ) {
    for (let key in props.language.list_of_language_data) {
      const item = props.language.list_of_language_data[key];
      // const name = item.name;
      nameslist.push({ id: key, name: item.name });
    }
  }

  const returnLyricsObj = (id) => {
    return nameslist &&
      nameslist.length !== 0 &&
      nameslist.filter((item) => {
        return item.id === id;
      })[0]
      ? nameslist &&
          nameslist.length !== 0 &&
          nameslist.filter((item) => {
            return item.id === id;
          })[0]
      : null;
  };

  if (props.track.hits) {
    props.track.hits.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: item._id,
        _index: item._index,
        content_type: item._source.content_type,
        primary_artists_string:
          item._source.primary_artists &&
          item._source.primary_artists.length !== 0
            ? item._source.primary_artists
                .map((item) => item?.artist_id?.artist_name)
                .join(", ")
            : null,
        isrc: item._source.isrc,
        errors: fetchTrackErrors(item),

        arranger: item._source.arranger,
        ask_to_generate_an_isrc: item._source.ask_to_generate_an_isrc,
        author: item._source.author,
        composer: item._source.composer,
        created_by:
          item._source.created_by && item._source.created_by.user_name,
        created_on: item._source.created_on,
        featuring: item._source.featuring,
        genre: item._source.genre,
        instrumental: item._source.instrumental,
        lyrics: item._source.lyrics,
        lyrics_language: item._source?.lyrics_language
          ? item._source.lyrics_language
          : null,
        parental_advisory: item._source.parental_advisory,
        plan_id: item._source.plan_id,
        preview_start: item._source.preview_start,
        primary_artists: item._source.primary_artists,
        primary_track_type: item._source.primary_track_type,
        producer: item._source.producer,
        producer_catalouge_number: item._source.producer_catalouge_number,
        production_year: item._source.production_year,
        published_by: item._source.published_by,
        publisher: item._source.publisher,
        remixer: item._source.remixer,
        release_id: item._source.release_id,
        secondary_genre: item._source.secondary_genre,
        secondary_track_type: item._source.secondary_track_type,
        seconday_sub_genre: item._source.seconday_sub_genre,
        stream_id: item._source.stream_id,
        sub_genre_name: item._source.sub_genre_name,
        title: item._source.title,
        track_title_language: item._source?.track_title_language
          ? item._source.track_title_language
          : null,
        version_subtitle: item._source["music/album"],
        views: item._source.views,
        phonographic_copyright_year: item._source.phonographic_copyright_year,
        copyright_year: item._source.copyright_year,
        copyrighted_to: item._source.copyrighted_to,
      });
    });
  }

  const [imageLoader, setImageLoader] = useState(false);

  function fetchTrackErrors(trackData) {
    const { _source } = trackData;

    let tracksErrors = 0;

    if (!_source?.title) {
      tracksErrors++;
    }
    if (_source?.primary_artists.length == 0) {
      tracksErrors++;
    }
    if (_source?.author?.length == 0 && !_source.instrumental) {
      tracksErrors++;
    }
    if (_source?.composer.length == 0) {
      tracksErrors++;
    }
    if (!_source.genre) {
      tracksErrors++;
    }
    if (!_source.sub_genre_name) {
      tracksErrors++;
    }
    if (!_source.plan_id) {
      tracksErrors++;
    }
    if (!_source.instrumental) {
      if (!_source.track_title_language) {
        tracksErrors++;
      }
    }
    if (!_source.instrumental) {
      if (!_source.lyrics_language) {
        tracksErrors++;
      }
    }
    if (!_source.copyright_year) {
      tracksErrors++;
    }
    if (!_source.production_year) {
      tracksErrors++;
    }
    if (!_source.preview_start) {
      tracksErrors++;
    }

    // let publisher_array = _source.publisher?.split("-");

    // if (publisher_array?.length > 0) {
    //   if (!publisher_array[0] || !publisher_array[1]) {
    //     tracksErrors++;
    //   }
    // }

    if (_source?.publisher?.length === 0) {
      tracksErrors++;
    } else {
      _source?.publisher?.some((pub) => {
        let [publisher, type] = pub.split("-");

        if (!publisher || !type) {
          tracksErrors++;
          return true; // Return true to stop further iterations if condition is met
        }
        return false;
      });
    }

    if (!_source.ask_to_generate_an_isrc) {
      if (!_source.isrc) {
        tracksErrors++;
      }
    }

    if (tracksErrors) individualTrackErrorPresent = true;

    if (!_source.copyrighted_to) {
      tracksErrors++;
    }

    if (!_source.published_by) {
      tracksErrors++;
    }

    if (!_source.phonographic_copyright_year) {
      tracksErrors++;
    }

    if (!_source.producer_catalouge_number) {
      tracksErrors++;
    }

    function isAlphanumericWithBoth(values) {
      const containsLetters = /[a-zA-Z]/.test(values);
      const containsNumbers = /[0-9]/.test(values);
      const containsSpecialChars = /[^a-zA-Z0-9]/.test(values);

      if (containsLetters && containsNumbers && !containsSpecialChars) {
        // The string contains both letters and numbers (alphanumeric) and no special characters.
        return true;
      } else {
        // The string does not meet the criteria.
        return false;
      }
    }

    if (_source?.producer_catalouge_number) {
      if (!isAlphanumericWithBoth(_source.producer_catalouge_number)) {
        tracksErrors++;
      }
    }

    if (!_source.content_type) {
      tracksErrors++;
    }
    if (!_source.primary_track_type) {
      tracksErrors++;
    }
    if (!_source.secondary_track_type) {
      tracksErrors++;
    }
    // if (!_source.instrumental) {
    //   tracksErrors++;
    // }

    return tracksErrors;
  }

  const returnAssetsUrl = (assets_id) => {
    return assets_list &&
      assets_list.length !== 0 &&
      assets_list.filter((item) => {
        return item.assets_id === assets_id.id;
      })[0]
      ? assets_list &&
          assets_list.length !== 0 &&
          assets_list.filter((item) => {
            return item.assets_id === assets_id.id;
          })[0].assets_url
      : "";
  };

  if (
    props.releaseById &&
    props.releaseById._source &&
    props.releaseById._source.tracks &&
    props.releaseById._source.tracks.length
  ) {
    let tracks = props.releaseById._source.tracks;
    for (var i = 0; i < tracks.length; i++) {
      for (var j = 0; j < rows.length; j++) {
        if (tracks[i].track_id.id === rows[j].id) {
          rows[j].assets = {
            assets_id:
              tracks[i].assets_id && tracks[i].assets_id.id
                ? tracks[i].assets_id.id
                : "",
            assets_url:
              tracks[i].assets_id && tracks[i].assets_id
                ? returnAssetsUrl(tracks[i].assets_id)
                : "",
          };
          break;
        }
      }
    }
  }
  function extractFileName(inputString) {
    const parts = inputString.split("_");
    const extractedWord = parts[4] && parts[4].substring(6);
    return extractedWord;
  }

  let columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 100,
      flex: 1,
    },
    {
      width: 150,
      flex: 1,
      field: "type_of_release",
      headerName: "Type",
      renderCell: (params) => {
        return params.row.content_type ? params.row.content_type : "";
      },
    },

    {
      field: "title",
      headerName: "Title",
      resizable: true,
      align: "center",
      headerAlign: "center",
      width: 150,
      flex: 2.5,
    },

    {
      width: 150,
      flex: 2,
      field: "file_name_from_assets",
      headerName: "File Name",
      renderCell: (params) => {
        return params.row.assets && params.row.assets.assets_url
          ? extractFileName(params.row.assets.assets_url)
          : "";
      },
    },

    {
      field: "file_name",
      headerName: "Asset(s)",
      width: 150,
      flex: 3,

      renderCell: (params) => {
        if (params?.row?.assets?.assets_id === "" || !params?.row?.assets) {
          ++assetMapWarning;
        }
        return (
          <>
            {(params?.row?.assets?.assets_id === "" || !params?.row?.assets) &&
            isWarningVisible ? (
              <WarningIcon
                color="warning"
                titleAccess="Please map asset to this track"
                className="shake-animation"
              />
            ) : (
              (params?.row?.assets === "" || !params?.row?.assets) && (
                <WarningIcon
                  color="warning"
                  titleAccess="Please map asset to this track"
                />
              )
            )}
            <AutoCompleteComponent
              // value={params.value ? returnAssetsObject(params.row.assets) : ""}
              defaultValue={
                params.row.assets
                  ? returnAssetsObject(params.row.assets && params.row.assets)
                  : null
              }
              disabled={
                _source
                  ? _source.status &&
                    ["1", "4", "10", "9"].includes(_source.status.id)
                    ? false
                    : true
                  : false
              }
              disableClearable={true}
              handleChange={async (e, val) => {
                const targetId = params.row.id;
                const newValue = val.assets_id;
                const targetObject = rows.find((item) => item.id === targetId);
                if (targetObject) {
                  targetObject.assets = newValue;
                }
                localStorage.setItem("submission_data", JSON.stringify(rows));
              }}
              list={assets_list ? assets_list : []}
              isOptionEqualToValue={(option, value) =>
                option.assets_id === value.assets_id
              }
              getOptionLabel={(option) => option.assets_name}
              size="small"
              width="100%"
              textFieldProps={{
                placeholder: "Select Assets",
              }}
            />
            {(params?.row?.assets === "" || !params?.row?.assets) && (
              <UploadPopper
                format={"Choose a track you want to map with the asset type."}
              >
                <InfoIcon sx={{ fontSize: 15 }} />
              </UploadPopper>
            )}
          </>
        );
      },
    },

    {
      field: "primary_artists_string",
      width: 350,
      flex: 3,
      headerName: "Artist(s)",
      align: "center",
      headerAlign: "center",
    },
    {
      field: "isrc",
      width: 250,
      headerName: "ISRC",
      align: "center",
      headerAlign: "center",
      flex: 2,
    },
    {
      field: "errors",
      width: 250,
      headerName: "Errors",
      align: "center",
      headerAlign: "center",
      flex: 2,
      renderCell: (params) => {
        return (
          <CommonButton
            count={params.row.errors}
            onClick={(event) => {
              if (params.row.errors > 0) {
                navigate("/tracks", {
                  state: {
                    trackError: true,
                    trackId: params.id,
                    trackData: params.row,
                  },
                });
              }
            }}
          />
        );
      },
    },
  ];

  function submitClick() {
    let obj = {};

    let parsed_data = localStorage.getItem("submission_data")
      ? JSON.parse(localStorage.getItem("submission_data"))
      : [];

    if (parsed_data.length <= 0) return;
    setsaveloading(true);

    Object.assign(
      obj,
      parsed_data.length && {
        tracks: parsed_data.map((item) => {
          return {
            track_id: item.id,
            assets_id:
              typeof item.assets === `object`
                ? item.assets.assets_id
                : item.assets,
          };
        }),
      }
    );

    let params = {
      current_page: "submission",
    };
    props
      .dispatch(
        ReleasesAction.requestPutRelease(
          obj,
          props.releaseById && props.releaseById._id,
          params
        )
      )
      .then(() => {
        setsaveloading(false);
        props.dispatch(
          ReleasesAction.requestReleaseById(props.releaseById._id, params)
        );
        localStorage.removeItem("submission_data");
      });
  }

  let existingTracksRows = [];
  if (_source && _source.existing_tracks) {
    _source.existing_tracks.map((item, index) => {
      return existingTracksRows.push({
        sl_no: index + 1,
        id: index + 1,
        release_name: item.release_id?.title,
        track_name: item.track_id?.title,
        asset_name: item.assets_id?.assets_name,
      });
    });
  }
  let existingTracksColumns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      // width: 100,
      flex: 0.5,
    },
    {
      field: "track_name",
      headerName: "Existing Track Name",
      resizable: false,
      align: "center",
      headerAlign: "center",
      // width: 150,
      flex: 2.5,
    },
    {
      field: "release_name",
      headerName: "Release Name",
      resizable: false,
      align: "center",
      headerAlign: "center",
      // width: 100,
      flex: 2.5,
    },
    {
      field: "asset_name",
      headerName: "Asset Name",
      resizable: false,
      align: "center",
      headerAlign: "center",
      // width: 150,
      flex: 2.5,
    },
  ];

  return (
    <Box ref={boxRef} paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Grid
        item
        container
        xl={12}
        lg={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent={"space-between"}
        style={{
          position: "sticky",
          top: `${boxRef?.current?.offsetTop}px`,
          backgroundColor: theme.palette.background.default,
          zIndex: 10,
        }}
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={10}
          container
          alignItems={"center"}
          justifyContent="flex-start"
          gap={2}
          paddingTop={1}
        >
          <Grid item>
            <h2>{formatName(`${_source?.title}`)}</h2>
          </Grid>

          <Grid item>
            <BreadCrumbs pathname={window.location.pathname} />
          </Grid>
        </Grid>
      </Grid>
      <LoadingIndicator isActive={props.isRequesting}>
        {!props.isRequesting && (
          <Grid container className="d-flex justify-content-center" marginY={1}>
            <Grid
              container
              lg={10}
              md={10}
              sm={10}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#fff",
              }}
            >
              {(releaseInfoError !== 0 ||
                releaseDateError !== 0 ||
                assetsError !== 0) && (
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{
                    backgroundColor: theme.palette.background.error,
                    padding: 1,
                  }}
                  className="d-flex justify-content-center"
                >
                  <Grid container item lg={11} md={12} sm={12} paddingX={1}>
                    <Typography
                      sx={{
                        color: theme.palette.text.error,
                      }}
                    >
                      Please correct all errors to submit your release
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {_source && _source.status && _source.status.id === "2" && (
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  sx={{
                    backgroundColor: theme.palette.background.error,
                    padding: 1,
                  }}
                  className="d-flex justify-content-center"
                >
                  <Grid container item lg={11} md={12} sm={12} paddingX={1}>
                    <Typography
                      sx={{
                        color: theme.palette.text.error,
                      }}
                    >
                      Please Withdraw from review to edit the release
                    </Typography>
                  </Grid>
                </Grid>
              )}

              <Grid
                container
                item
                lg={11}
                md={12}
                sm={12}
                gap={1}
                sx={{
                  padding: 2,
                }}
              >
                <Grid item lg={6} md={12} sm={12}>
                  <Grid
                    item
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Typography sx={{ fontSize: theme.typography.h2.fontSize }}>
                      {formatName("Release Information")}
                    </Typography>

                    <CommonButton
                      count={releaseInfoError}
                      onClick={(event) => {
                        if (releaseInfoError > 0) {
                          navigate("/releaseInfo", {
                            state: { shouldSubmit: true },
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    gap={1}
                    className="d-flex justify-content-between"
                  >
                    <Grid
                      item
                      lg={8}
                      md={8}
                      sm={8}
                      display={"flex"}
                      flexDirection={"column"}
                      gap={1}
                    >
                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Title</Typography>
                        <Typography>
                          {_source?.title ? _source?.title : ""}
                        </Typography>
                      </Typography>

                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Artist(s)</Typography>
                        <Typography textAlign={"right"}>
                          {_source?.artists && _source?.artists.length !== 0
                            ? _source?.artists.map((artist) => (
                                <>
                                  <>
                                    {artist?.artist_id?.artist_name
                                      ? artist?.artist_id?.artist_name
                                      : ""}
                                  </>
                                  <br />
                                </>
                              ))
                            : ""}
                        </Typography>
                      </Typography>

                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Label </Typography>
                        <Typography>
                          {_source?.label ? _source?.label.label_name : ""}
                        </Typography>
                      </Typography>

                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Genre </Typography>
                        <Typography>
                          {_source?.genre_type
                            ? _source?.genre_type.genre_name
                            : ""}
                        </Typography>
                      </Typography>

                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Subgenre </Typography>
                        <Typography>
                          {_source?.sub_genre_type
                            ? _source?.sub_genre_type?.subgenre_name
                            : ""}
                        </Typography>
                      </Typography>

                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Number of track(s) / asset(s)</Typography>
                        <Typography>
                          {_source?.tracks ? _source?.tracks.length : ""} /{" "}
                          {props && props.assets && props.assets.hits
                            ? props.assets.hits.length
                            : ""}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      sm={3}
                      paddingX={1}
                      paddingY={1}
                      className="d-flex justify-content-end"
                    >
                      <Box
                        style={{
                          width: 80,
                          height: 80,
                          boxShadow: theme.shadows,
                          borderRadius: "5px",
                          backgroundColor: "white",
                          display: "grid",
                          placeItems: "center",
                          overflow: "hidden",
                          borderRadius: 5,
                        }}
                      >
                        {props.releaseById &&
                        props.releaseById._source &&
                        props.releaseById._source.thumbnail_url ? (
                          !imageLoader ? (
                            <img
                              src={imgUrl}
                              alt="Preview"
                              onError={() => {
                                // Error handling code goes here
                              }}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <CircleLoader />
                          )
                        ) : (
                          <Avatar size="small" />
                        )}
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={5} md={12} sm={12}>
                  <Grid
                    item
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Typography sx={{ fontSize: theme.typography.h2.fontSize }}>
                      {formatName("Price")}
                    </Typography>
                    <CommonButton
                      count={priceError}
                      onClick={(event) => {
                        if (priceError > 0) {
                          navigate("/releaseInfo", {
                            state: { shouldSubmit: true, priceError: true },
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12}>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      sm={12}
                      className="d-flex justify-content-between p-1"
                    >
                      <Typography>Plan </Typography>
                      <Typography>
                        {_source?.plan_id && _source.plan_id.plan_name
                          ? _source.plan_id.plan_name
                          : ""}
                      </Typography>
                    </Grid>
                  </Grid>
                  <br />
                  {/* 
            <Grid
              item
              className="d-flex justify-content-between align-items-center"
            >
              <Typography sx={{ fontSize: theme.typography.h2.fontSize }}>
                {formatName("Territories")}
              </Typography>
              <CommonButton
                count={territoriesError}
                onClick={(event) => {
                  handleChange(event, "5");
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12}>
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                className="d-flex justify-content-between p-1"
              >
                <Typography>Your release is authorized in </Typography>
                <Typography>
                  {`${
                    _source?.territory ? _source?.territory.length : "0"
                  } territories`}
                </Typography>
              </Grid>
            </Grid> */}
                </Grid>
              </Grid>

              <Grid item lg={11} md={12} sm={12} padding={1}>
                <Divider sx={{ borderTop: "2px solid #dddddd" }} />
              </Grid>

              <Grid
                container
                item
                lg={11}
                md={12}
                sm={12}
                gap={2}
                sx={{
                  padding: 2,
                }}
              >
                <Grid item lg={6} md={12} sm={12}>
                  <Grid
                    item
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Typography sx={{ fontSize: theme.typography.h2.fontSize }}>
                      {formatName("Release Date")}
                    </Typography>
                    <CommonButton
                      count={releaseDateError}
                      onClick={(event) => {
                        if (releaseDateError > 0) {
                          navigate("/releaseDate", {
                            state: { shouldSubmit: true },
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} gap={1}>
                    <Grid item lg={12} md={12} sm={12}>
                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Main release date</Typography>
                        <Typography>
                          {_source?.main_release_date
                            ? _source?.main_release_date
                            : "(empty)"}
                        </Typography>
                      </Typography>

                      <Typography className="d-flex justify-content-between p-1">
                        <Typography>Pre-order release date</Typography>
                        <Typography>
                          {_source?.pre_order_release_date
                            ? _source?.pre_order_release_date
                            : "(empty)"}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={5} md={12} sm={12}>
                  {/* <Grid
                item
                className="d-flex justify-content-between align-items-center"
              >
                <Typography sx={{ fontSize: theme.typography.h2.fontSize }}>
                  {formatName("Promotions")}
                </Typography>
                <CommonButton
                count={priceError}
                onClick={(event) => {
                  handleChange(event, "4");
                  if (priceError !== 0) {
                    props.setPlantriggerSubmit(true);
                  }
                }}
              />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <Grid item lg={6} md={6} sm={6}>
                  <Typography className="d-flex justify-content-between p-1">
                    <Typography>Plan </Typography>
                    <Typography>
                      {_source?.plan_id && _source.plan_id.plan_name
                        ? _source.plan_id.plan_name
                        : ""}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid> */}
                  <br />
                </Grid>
              </Grid>

              <Grid item lg={11} md={12} sm={12} padding={1}>
                <Divider sx={{ borderTop: "2px solid #dddddd" }} />
              </Grid>

              <Grid
                container
                item
                lg={11}
                md={12}
                sm={12}
                gap={2}
                sx={{
                  padding: 2,
                }}
              >
                <Grid item lg={6} md={12} sm={12}>
                  <Grid
                    item
                    className="d-flex justify-content-between align-items-center"
                  >
                    <Typography sx={{ fontSize: theme.typography.h2.fontSize }}>
                      {formatName("Assets")}
                    </Typography>
                    <CommonButton
                      count={assetsError}
                      onClick={(event) => {
                        if (
                          assetsError > 0 &&
                          _source &&
                          _source.tracks.length === 0
                        ) {
                          navigate("/tracks", {
                            state: { shouldSubmit: true },
                          });
                        } else if (
                          assetsError > 0 &&
                          _source &&
                          _source.tracks.length !== 0
                        ) {
                          if (
                            props.assets &&
                            props.assets.hits &&
                            props.assets.hits.length !== 0
                          ) {
                            setIsWarningVisible(true);
                            setTimeout(() => {
                              setIsWarningVisible(false);
                            }, 1000);
                          } else {
                            navigate("/upload", {
                              state: { shouldSubmit: true },
                            });
                          }
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <br />

              <Grid
                container
                item
                lg={11}
                md={12}
                sm={12}
                gap={2}
                sx={{
                  padding: 2,
                }}
              >
                <Box width="100%">
                  {rows.length}&nbsp;
                  {formatName("Results Found")}
                  <DataGridFunction
                    columns={columns}
                    rows={rows}
                    rowHeight={80}
                    height={rows.length * 60 + 100}
                    headerColor={"white"}
                  />
                </Box>
                {existingTracksRows.length > 0 && (
                  <Box width="100%">
                    {existingTracksRows.length}&nbsp;
                    {formatName("Existing Tracks Found")}
                    <DataGridFunction
                      columns={existingTracksColumns}
                      rows={existingTracksRows}
                      rowHeight={80}
                      height={rows.length * 60 + 100}
                      headerColor={"white"}
                    />
                  </Box>
                )}
              </Grid>

              <Grid
                container
                item
                // lg={11}
                // md={12}
                // sm={12}
                gap={2}
                sx={{
                  padding: 2,
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <MuiButton
                  variant="contained"
                  color="primary"
                  loading={saveloading}
                  name={"Update Asset"}
                  save
                  disabled={
                    _source
                      ? _source.status &&
                        ["1", "4", "10", "9"].includes(_source.status.id)
                        ? false
                        : true
                      : false
                  }
                  onClick={submitClick}
                />

                <MuiButton
                  loading={submitloading}
                  variant="contained"
                  color="primary"
                  name={"Submit for Review"}
                  disabled={
                    _source && _source.status && _source.status.id === "2"
                      ? true
                      : releaseInfoError > 0 ||
                        releaseDateError > 0 ||
                        assetsError > 0 ||
                        tracksError > 0 ||
                        assetMapWarning > 0 ||
                        individualTrackErrorPresent
                  }
                  save
                  onClick={() => {
                    UpdateToFinalRelease();
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </LoadingIndicator>
    </Box>
  );
}
//export default Submission;
export default connect(mapStateToProps)(Submission);
