import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import React from "react";
import { theme } from "../../App";
import Rettrive from "../../releases/Rettrive";
import { useNavigate } from "react-router";

const LatestReleasesBox = ({ latestReleaseList }) => {
  let navigate = useNavigate();
  return (
    <>
      {latestReleaseList &&
        latestReleaseList.map((item) => {
          return (
            <Card
              sx={{
                width: "200px",
                cursor: "pointer",
                height: "220px",
                // border: "1px solid #efefef",
                margin: "10px",
              }}
              onClick={() => {
                navigate("/deliveredRelease", {
                  state: {
                    releaseId: item._id,
                  },
                });
              }}
            >
              <CardContent p={0} className={"p-0 m-0"}>
                <Box
                // height={220}
                // className="bg-warning"
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #efefef",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                    // className="bg-success"
                    height={160}
                  >
                    <Rettrive
                      thumbnail_url={item._source.thumbnail_url}
                      release_id={item._id}
                      current_page={"dashboard"}
                      borderTopLeftRadius={10}
                      borderTopRightRadius={10}
                      params={{
                        id: item._id,
                        row: { client_id: item?._source?.client_id?.id },
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #efefef",
                      justifyContent: "space-around",
                      overflow: "hidden",
                      // padding: 1,
                    }}
                    height={50}
                    // className="bg-info"
                  >
                    <Typography>
                      {item?._source?.title}
                      {/* Streams */}
                    </Typography>
                    {/* <Typography
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <LibraryMusicIcon
                        sx={{
                          color: theme.palette.background.music,
                        }}
                      />
                    </Typography> */}
                  </Box>
                </Box>
              </CardContent>
            </Card>
          );
        })}
    </>
  );
};

export default LatestReleasesBox;
