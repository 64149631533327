import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import NetRevenueByMonthAction from "../../stores/netRevenueReportByMonth/NetRevenueByMonthAction";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import TrackwiseRevenueSummary from "../revenue/TrackwiseRevenueSummary";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    NetRevenueByMonthAction.REQUEST_NET_REVENUE_BY_MONTH_FILTER,
  ]),
  netRevenueByMonthFilter:
    state.netRevenueByMonthFilter.netRevenueByMonthFilter || [],
});

function MonthwiseRevenueGridView(props) {
  const data = props.netRevenueByMonthFilter;

  const [rowData, setRowData] = useState(null);
  const [openTrackRevenueSummary, setTrackRevenueSummary] = useState(false);

  const { isRequesting } = props;

  function keyBeautification(key) {
    const words = key.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }

  function ConvertedName(name) {
    if (name.includes("_")) {
      return name
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }
    return name;
  }

  const rows = [];

  if (data["Net Revenue By Month"]) {
    data["Net Revenue By Month"][0]?.summary?.length &&
      data["Net Revenue By Month"][0]?.summary.map((item, index) => {
        rows.push({
          sl_no: index + 1,
          id: index + 1,
          count: item.revenue,
          key:
            typeof item.key === "string"
              ? ConvertedName(item.key)
              : ConvertedName(item.key?.name),
          track_id: item.key?.id,
        });
      });
  }

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      flex: 0.5,
      resizable: false,
      align: "center",
      headerAlign: "center",
    },

    {
      field: "key",
      headerName: `${keyBeautification(props.report_type)}`,
      flex: 3,
    },
    {
      field: "count",
      headerName: "Revenue ($)",
      flex: 3,
    },
  ];

  return (
    <Box>
      {navigator.onLine ? (
        <Grid
          display="flex"
          justifyContent="center"
          container
          item
          lg={12}
          md={12}
          sm={12}
        >
          <Grid item xs={12} mx={3}>
            <LoadingIndicator isActive={isRequesting}>
              {!isRequesting && data["Net Revenue By Month"] ? (
                <DataGridFunction
                  columns={columns}
                  rows={rows}
                  rowHeight={60}
                  height={"74vh"}
                  curser={props.report_type == "track_id" && "pointer"}
                  onRowClick={(e) => {
                    if (
                      e.row?.key == "others" ||
                      props.report_type != "track_id"
                    )
                      return;
                    setRowData(e.row);
                    setTrackRevenueSummary(true);
                  }}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "70vh",
                  }}
                >
                  <LoadingBgImage />
                </Box>
              )}
            </LoadingIndicator>
          </Grid>
          <TrackwiseRevenueSummary
            open={openTrackRevenueSummary}
            onClose={() => {
              setTrackRevenueSummary(false);
            }}
            trackID={rowData?.track_id}
            trackName={rowData?.key}
          />
        </Grid>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
    </Box>
  );
}

export default connect(mapStateToProps)(MonthwiseRevenueGridView);
