import BaseReducer from "../../utilities/BaseReducer";
import PlanAction from "./PlanAction";

export default class PlanFilterReduser extends BaseReducer {
  //initial state of PlanFilterReduser
  initialState = {
    planFilter: [],
  };
  //PlanFilterReduser request action finish
  [PlanAction.REQUEST_PLAN_FILTER_FINISHED](state, action) {
    return {
      ...state,
      planFilter: action.payload,
    };
  }

  //   [LabelAction.REQUEST_LABELS_FILTER_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       labelFilter: action.payload,
  //     };
  //   }
}
