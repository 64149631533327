import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import UserProfileModel from "./model/UserProfileModel";
import UserProfilePutModel from "./model/UserProfilePutModel";
import UserProfileResetPasswordPutModel from "./model/UserProfileResetPasswordPutModel";
import UserAdminResetPasswordPutModel from "./model/UserAdminResetPasswordPutModel";
import LabelPostModel from "../label/model/LabelPostModel";
import NotificationModel from "./model/NotificationModel";

export default class UserProfileEffect {
  static async requestUserprofile(params) {
    const endpoint = environment.api.userprofile;
    return EffectUtility.getToModel(UserProfileModel, endpoint, params);
  }

  static async requestMailSubsciption(params) {
    const endpoint = environment.api.mailSubscription;
    return EffectUtility.getToModel(UserProfileModel, endpoint, params);
  }

  static async requestPutMailSubsciption(data, params) {
    const endpoint = environment.api.mailSubscription;
    return EffectUtility.putToModel(
      UserProfilePutModel,
      endpoint,
      data,
      params
    );
  }

  static async requestPostMailSubsciption(data, params) {
    const endpoint = environment.api.mailSubscription + "/add";
    return EffectUtility.postToModel(LabelPostModel, endpoint, data, params);
  }

  static async requestNotificationSubsciption(params) {
    const endpoint = environment.api.notificationSubscription;
    return EffectUtility.getToModel(NotificationModel, endpoint, params);
  }

  static async requestPutNotificationSubsciption(data, params) {
    const endpoint = environment.api.notificationSubscription;
    return EffectUtility.putToModel(
      UserProfilePutModel,
      endpoint,
      data,
      params
    );
  }

  static async requestPostNotificationSubsciption(data, params) {
    const endpoint = environment.api.notificationSubscription + "/add";
    return EffectUtility.postToModel(LabelPostModel, endpoint, data, params);
  }

  static async requestPutUserprofile(data, id) {
    const endpoint = environment.api.userprofile + "/" + id;
    return EffectUtility.putToModel(UserProfilePutModel, endpoint, data);
  }
  static async requestPutUserprofilePic(data) {
    const endpoint = environment.api.userprofileupdate;
    return EffectUtility.putToModel(UserProfilePutModel, endpoint, data);
  }

  static async requestUserResetPassword(data) {
    const endpoint = environment.api.userResetPassword;
    return EffectUtility.putToModel(
      UserProfileResetPasswordPutModel,
      endpoint,
      data
    );
  }

  static async requestAdminResetPassword(data) {
    const endpoint = environment.api.adminResetPassword;
    return EffectUtility.putToModel(
      UserAdminResetPasswordPutModel,
      endpoint,
      data
    );
  }

  static async updateBankDetails(data) {
    const endpoint = environment.api.updateBankDetails;
    return EffectUtility.putToModel(
      UserAdminResetPasswordPutModel,
      endpoint,
      data
    );
  }
}
