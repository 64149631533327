import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Typography,
} from "@mui/material";
import { Form, Formik, Field, getIn } from "formik";
import { connect } from "react-redux";
import * as yup from "yup";
import { string } from "yup";
import MuiButton from "../../components/muiButton/MuiButton";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import StatusAction from "../../stores/status/StatusAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { theme } from "../App";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
// mapstateto props function

const mapDispatchToProps = (dispatch) => {
  return {};
};

const validationSchema = yup.object({
  status_name: string()
    .required("Enter Status name")
    .trim()
    .min(1, "Status name should be of minimum 1 characters length")
    .required("Status name is required"),

  // order_number: number().required("Enter Priority Number"),
});

const StatusPost = (props) => {
  let projectProps = props.projectProps;

  // Project post and Put method
  let initial = {};
  if (props.edit) {
    initial = {
      status_name: props.projectEditData.status_name,
    };
  } else {
    initial = {
      status_name: "",
      // order_number: "",
    };
  }
  // Formik

  const [loading, setLoading] = useState(false);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
      <Formik
        initialValues={initial}
        onSubmit={(values, formikHelpers) => {
          setLoading(true);
          // parameter for showing list based on the modified_by
          const params = {
            order_by: `[["order_number","asc"]]`,
            page_limit: 15,
          };
          const param = {
            order_by: `[["status_name.keyword", "asc"]]`,
            page_limit: "none",
          };
          if (props.edit) {
            props
              .dispatch(
                StatusAction.requestPutStatus(
                  JSON.stringify(values),
                  props.projectEditData.id
                )
              )
              .then(() => {
                props.dispatch(StatusAction.requestStatus(params));
                props.dispatch(StatusAction.requestStatusFilter(param));
                props.onClose();
                setLoading(false);
              });
          } else {
            props.dispatch(StatusAction.requestPostStatus(values)).then(() => {
              const params = {
                order_by: `[["order_number","asc"]]`,
                page_limit: 15,
              };
              const param = {
                order_by: `[["status_name.keyword", "asc"]]`,
                page_limit: "none",
              };
              props.dispatch(StatusAction.requestStatus(params));
              props.dispatch(StatusAction.requestStatusFilter(param));
              props.onClose();
              setLoading(false);
            });
          }

          // formikHelpers.resetForm();
        }}
        validationSchema={validationSchema}
      >
        {({
          errors,
          isValid,
          helperText,
          touched,
          dirty,
          handleChange,
          values,
        }) => (
          <Form>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DialogContentText sx={{ width: "100%", marginBottom: 5 }}>
                <Typography
                  component={"div"}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <Typography
                    component={"div"}
                    fontSize={30}
                    sx={{
                      color: theme.palette.primary.main,
                      fontWeight: "bold",
                      justifySelf: "center",
                    }}
                  >
                    {!props.edit
                      ? formatName("Add Status Name")
                      : formatName("Edit Status Name")}
                  </Typography>

                  <Typography
                    component={"span"}
                    sx={{ justifySelf: "end", marginLeft: 3 }}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <HighlightOffIcon sx={{ fontSize: 20 }} />
                  </Typography>
                </Typography>
              </DialogContentText>

              {/* ********************************************* Tag Name */}
              <Grid container>
                <Grid item lg={12} md={12} sm={12} container>
                  <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                    Status Name * :
                  </Grid>
                  <Grid item lg={6} md={6} sm={6}>
                    <Field
                      as={MuiTextField}
                      variant="outlined"
                      placeholder="Status Name"
                      id="status_name"
                      size="small"
                      name="status_name"
                      error={
                        Boolean(errors.status_name) &&
                        Boolean(touched.status_name)
                      }
                      helperText={
                        Boolean(touched.status_name) && errors.status_name
                      }
                      className="col-10"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="m-1 my-4">
              <Grid
                container
                item
                lg={12}
                md={12}
                sm={12}
                display="flex"
                justifyContent="space-evenly"
              >
                <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Add Status"
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      loading={loading}
                      type="submit"
                      name="Submit"
                      width="100%"
                    />
                  )}
                </Grid>
                <Grid item lg={5} md={5} sm={5} minWidth={200}>
                  {!props.edit ? (
                    <MuiButton
                      type="reset"
                      name="Reset"
                      onClick={Formik.resetForm}
                      width="100%"
                    />
                  ) : (
                    <MuiButton
                      name={"Cancel"}
                      width="100%"
                      onClick={() => {
                        props.onClose();
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default connect(mapDispatchToProps)(StatusPost);
