import { BaseModel } from "sjs-base-model";

// TopTrackPutModel
export default class TopTrackPutModel extends BaseModel {
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
