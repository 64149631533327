import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import { connect } from "react-redux";
import { theme } from "../../views/App.jsx";
import ClientAction from "../../stores/client/ClientAction";
import AppBlockingIcon from "@mui/icons-material/AppBlocking";
import ResetAdminPassword from "../../components/resetAdminPassword/ResetAdminPassword";
import LockPersonTwoToneIcon from "@mui/icons-material/LockPersonTwoTone";

function ClientKebabPopper(props) {
  const [openPassword, setOpenPassword] = useState(false);

  const [open, setOpen] = React.useState(false);
  const morePopperRef = React.useRef(null);
  const prevOpen = React.useRef(open);

  const { allowMobileLogin } = props;

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      morePopperRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const handlePopper = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      morePopperRef.current &&
      event &&
      morePopperRef.current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
  };

  function ResetDevice() {
    props.dispatch(ClientAction.requestDeviceReset(props.clientID));
    handleClose();
  }

  return (
    <>
      <Typography
        component={"div"}
        ref={morePopperRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handlePopper}
        style={{
          backgroundColor: "none",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        {props.children}
      </Typography>
      <Popper
        open={open}
        anchorEl={morePopperRef.current}
        role={undefined}
        placement="left"
        transition
        disablePortal
        arrow
        modifiers={[
          {
            arrow: true,
          },
        ]}
        sx={{
          zIndex: 20,
          position: "relative",
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Grow {...TransitionProps} timeout={450}>
              <Box
                elevation={3}
                sx={{
                  padding: "5px",
                  bgcolor: "white",
                  borderRadius: "5px",
                  border: "1px solid rgba(0, 0, 0, 0.2)",
                  boxShadow: "10px 10px 10px rgba(0, 0, 0, 0.2)",
                  margin: "3px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch",
                  transition: "transform 0.3s",
                  transform: openPassword ? "translateX(100vw)" : "none",
                }}
              >
                <Box
                  onClick={() => {
                    setOpenPassword(true);
                  }}
                  sx={{
                    padding: "8px",
                    paddingX: "50px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    transition: openPassword && "transform 0.5s",
                    transform: openPassword && "rotateX(360deg)",
                    "&:hover": {
                      backgroundColor: theme.palette.background.default,
                    },
                  }}
                >
                  Reset Password{" "}
                  <LockPersonTwoToneIcon
                    style={{
                      marginLeft: "20px",
                      marginBottom: "5px",
                      fontSize: "20px",
                    }}
                  />
                </Box>
                {allowMobileLogin != "Disabled" && (
                  <Box
                    onClick={ResetDevice}
                    sx={{
                      padding: "8px",
                      paddingX: "50px",
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: theme.palette.background.default,
                      },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Reset Device
                    <AppBlockingIcon
                      style={{ marginBottom: "4px", fontSize: "20px" }}
                    />
                  </Box>
                )}
              </Box>
            </Grow>
          </ClickAwayListener>
        )}
      </Popper>
      <ResetAdminPassword
        rowData={props.popperRowData}
        open={openPassword}
        onClose={() => {
          setOpenPassword(false);
        }}
      />
    </>
  );
}

export default connect()(ClientKebabPopper);
