import { BaseModel } from "sjs-base-model";

export default class UserProfilePutModel extends BaseModel {
  status = "";
  data = {};

  constructor(data) {
    super();
    this.update(data);
  }
}
