import React from "react";
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";

function GrammarlyCustomisedPlugin({ children }) {
  return (
    <GrammarlyEditorPlugin
      config={{
        userFeedback: "off",
        underlines: "on",
        documentDomain: "general",
        documentDialect: "auto-browser",
        activation: "focus",
      }}
    >
      {children}
    </GrammarlyEditorPlugin>
  );
}

export default GrammarlyCustomisedPlugin;
