import BaseReducer from "../../utilities/BaseReducer";
import ReleasesAction from "./ReleasesAction";

// TopFiveDeliveredReleasesReduser Reducer
export default class TopFiveDeliveredReleasesReduser extends BaseReducer {
  initialState = {
    top5Delivered: [],
  };
  [ReleasesAction.REQUEST_TOP_5_DELIVERED_RELEASES_FINISHED](state, action) {
    return {
      ...state,
      top5Delivered: action.payload,
    };
  }

  //   [ReleasesAction.REQUEST_RELEASE_CLEAR_FINISHED](state, action) {
  //     return {
  //       ...state,
  //       top5Delivered: action.payload,
  //     };
  //   }
}
