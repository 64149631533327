import React, { useEffect, useState } from "react";
import environment from "environment";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { theme } from "../../App";
import { Box, Grid, Typography } from "@mui/material";
import TopStoreAction from "../../../stores/topStoresReport/TopStoreAction";
import { connect } from "react-redux";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import NoDataFound from "../../../components/nodatafound/NoDataFound";
import DateSelectionPopper from "../components/DateSelectionPopper";
import { formatName } from "../../../components/reUsedFuntions/ReUsedFunctions";
import PageNotFound from "../../../components/PageNotFound";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingDashboardBgImage from "../../../components/loadingBgImage/LoadingDashboardBgImage";
import { NivoPieChart } from "../../../components/nivoCharts/NivoPieChart";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TopStoreAction.REQUEST_TOPSTORE]),
  topStore: state.topStore.topStore || [],
});

function TopStoresChart(props) {
  const [data, setData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [createdDatelistSelected, setCreatedDatelistSelected] = useState();
  let [loading, setLoading] = useState(false);

  const handleDateSelection = (date) => {
    setSelectedDate(date);
  };

  ChartJS.register(ArcElement, Tooltip, Legend);
  let access_token = localStorage.getItem("Auth")
    ? JSON.parse(localStorage.getItem("Auth")).access_token
    : "";

  // useEffect(() => {
  //   fetchTopStores();
  // }, []);

  // function fetchTopStores() {
  //   let params = {
  //     current_page: "dashboard",
  //   };
  //   props.dispatch(TopStoreAction.requestTopStore(params));
  // }

  async function fetchData(selectedDate) {
    setLoading(true);
    let filter = [];
    let accessToken = "";

    if (localStorage.getItem("Auth"))
      accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

    props.label &&
      filter.push(`["label.keyword","must","","match","${props.label.id}"]`);
    filter.push(`${selectedDate}`);

    let API = `${environment.api.topStore}?filters=[${filter}]&current_page=dashboard`;

    return fetch(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken,
      },
    })
      .then((res) => {
        return res.json();
      })
      .then((response) => {
        setLoading(false);
        return response;
      });
  }

  useEffect(() => {
    if (selectedDate)
      (async function CallApi() {
        await fetchData(selectedDate).then((e) => {
          setData(e);
        });
      })();
  }, [selectedDate, props.label]);

  // useEffect(() => {
  //   fetch(`${environment.api.baseApi}/top_stores_report`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: "Bearer " + access_token,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       setData(response.data);
  //     });
  // }, []);

  let TotalList = [];
  data &&
    data &&
    data.top_stores_report &&
    data.top_stores_report.length > 0 &&
    data.top_stores_report.map((item) => {
      TotalList.push({
        count: item.count,
        name: item.key,
        percent: item.percentage,
      });
    });
  TotalList.sort((a, b) => a.name.localeCompare(b.name));

  let countData = [];
  let nameLabels = [];
  let percentlables = [];

  nameLabels = TotalList.map((item) => {
    return item.name;
  });

  countData = TotalList.map((item) => {
    return item.count;
  });

  percentlables = TotalList.map((item) => {
    return item.percent;
  });

  const colors = [
    `${theme.pieChart.color1}`,
    `${theme.pieChart.color2}`,
    `${theme.pieChart.color3}`,
    `${theme.pieChart.color4}`,
    `${theme.pieChart.color5}`,
    `${theme.pieChart.color6}`,
  ];

  const state = {
    labels: nameLabels && nameLabels,
    datasets: [
      {
        type: "doughnut",
        label: nameLabels && nameLabels,
        data: countData && countData,
        borderWidth: 2,
        backgroundColor: colors,
      },
    ],
  };

  const options = {
    responsive: true,
    animation: false,

    plugins: {
      title: {
        display: true,
      },
      legend: {
        display: true,
        position: "bottom",
        font: {
          weight: "bold",
        },
        labels: {
          usePointStyle: true,
          padding: 30,
          align: "end",
          generateLabels: function (chart) {
            const { data } = chart;
            if (data.labels.length) {
              return data.labels.map((label, i) => {
                const percent = percentlables[i];
                const formattedPercent = Number(percent).toFixed(2);
                return {
                  text: `${label}: ${formattedPercent}%`,
                  fillStyle: data.datasets[0].backgroundColor[i],
                  index: i,
                };
              });
            }
            return [];
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || "";
            const countLabel = countData[context.dataIndex] || 0;
            return `${label}: ${countLabel}`;
          },
        },
      },
      datalabels: {
        align: "end",
        anchor: "end",
        display: true,
        color: colors,
        // formatter: (value) => {
        //   return value;
        // },
      },
    },

    label: {
      display: false,
    },
  };

  const [dateSelection] = useState([
    // "Last 2 Days",
    // "Today",
    // "Previous Day",
    "Last 7 Days",
    "Last 30 Days",
    "Custom",
  ]);

  function formatDateRange(dateRangeString) {
    const dateRanges = dateRangeString?.split("],[");

    if (dateRanges?.length >= 2) {
      let [, , , , startDate] = dateRanges[0].split(",");
      let [, , , , endDate] = dateRanges[1].split(",");
      endDate = endDate.replace("]", "");

      const options = { year: "numeric", month: "short", day: "numeric" };
      startDate = new Date(startDate).toLocaleDateString("en-US", options);
      endDate = new Date(endDate).toLocaleDateString("en-US", options);
      return `${endDate} - ${startDate}`;
    }
  }

  let chartData = [];
  data &&
    data.top_stores_report &&
    data.top_stores_report.length > 0 &&
    data.top_stores_report.map((item) => {
      chartData.push({
        id: item.key,
        label: `${item.key} (${item?.percentage.toFixed(0)}%)`,
        value: item.count,
      });
    });

  // Calculate the sum of all the values
  const totalValue = chartData.reduce((acc, obj) => acc + obj.value, 0);

  // Sort the data based on value in descending order
  chartData.sort((a, b) => b.value - a.value);

  // Take the first two objects as they are
  const result = chartData.slice(0, 5);

  // Calculate the sum of the values of the remaining items (excluding the top two)
  const othersValue = chartData
    .slice(5)
    .reduce((acc, obj) => acc + obj.value, 0);

  // Calculate the percentage of the sum for the third object
  const othersPercentage = othersValue / totalValue;

  // Create the 'Others' object
  const othersObject = {
    id: "Others",
    label: `Others (${othersPercentage.toFixed(0)}%)`,
    value: othersValue,
  };

  // Push the 'Others' object into the result array
  result.push(othersObject);

  return (
    <Box>
      <Typography
        component={"div"}
        sx={{
          fontSize: "24px",
          display: "flex",
          alignItems: "center",
          marginY: "8px",
        }}
        // className="bg-warning"
      >
        <span
          style={{ fontWeight: "bold" }}
          // className="bg-success"
        >
          {formatName("Top Stores")} | &nbsp;
        </span>

        <span
          style={{ color: "grey" }}
          // className="bg-info"
        >
          <DateSelectionPopper
            onDateSelect={handleDateSelection}
            dateSelection={dateSelection}
            createdDatelistSelected={
              createdDatelistSelected ? createdDatelistSelected : "Last 30 Days"
            }
            setCreatedDatelistSelected={setCreatedDatelistSelected}
          />
        </span>
        <Box
          style={{
            color: "grey",
          }}
          fontSize={{
            lg: 12,
            md: 10,
            xs: 9,
          }}
          // className="bg-light"
        >
          | {formatDateRange(selectedDate)}&nbsp;
        </Box>
        <Box
          sx={{
            marginLeft: "auto",
            fontSize: "12px",
            color: "grey",
          }}
          // className="bg-success"
        >
          * Last 2 days data is not available
        </Box>
      </Typography>
      <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />
      <LoadingIndicator isActive={loading}>
        {!loading ? (
          <>
            {data &&
            data.top_stores_report &&
            data.top_stores_report.length > 0 &&
            !percentlables.every((element) => element === 0) ? (
              <Grid
                xs={12}
                sx={{
                  height: "50vh",
                  xs: {
                    width: "90vw",
                  },
                  md: {
                    width: "50vw",
                  },
                }}
              >
                {/* <Doughnut
                  style={{
                    height: "280px",
                    width: "280px",
                    alignItems: "center",
                  }}
                  data={state}
                  options={options}
                /> */}

                <NivoPieChart
                  chartData={result}
                  enableArcLabels={false}
                  enableArcLinkLabels={false}
                  margin={{ top: 20, right: 60, bottom: 20, left: 0 }}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <PageNotFound page="Reports" />
              </Grid>
            )}
          </>
        ) : (
          <Box>
            <LoadingDashboardBgImage />
          </Box>
        )}
      </LoadingIndicator>
    </Box>
  );
}

export default connect(mapStateToProps)(TopStoresChart);
