import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
} from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { theme } from "../App";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import TopStoreAction from "../../stores/topStoresReport/TopStoreAction";
import TopLabelAction from "../../stores/topLabelReport/TopLabelAction";
import TopTerritoriesAction from "../../stores/TopTerritories/TopTerritoriesAction";
import TopDevicesAction from "../../stores/topDevicesRport/TopDevicesAction";
import TopTrackAction from "../../stores/topTrackReport/TopTrackAction";
import ReportContainer from "./ReportContainer";
import CalendarPopover from "./DashboardFilters";
import LabelReport from "./reports/LabelReport";
import DevicesReport from "./reports/DevicesReport";
import TerritoryReport from "./reports/TerritoryReport";
import AudioStreamsChart from "./reports/AudioStreamsChart";
import RetailerReport from "./reports/RetailerReport";
import TopTrackReport from "./reports/TopTrackReport";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, []),
  topLabel: state.topLabel.topLabel || [],
  topTrack_with_date_filter:
    state.topTrack_with_date_filter.topTrack_with_date_filter || [],
});

export const reports = [
  { report_id: "top_track_report", Name: "Tracks" },
  { report_id: "top_retailers_report", Name: "Retailers" },
  { report_id: "top_label_report", Name: "Labels" },
  { report_id: "top_territory_report", Name: "Territories" },
  { report_id: "top_device_report", Name: "Device Type" },
  { report_id: "top_audiostreams_report", Name: "Audio Streams" },
];
function returnReportname(reportId) {
  if (reportId === "top_audiostreams_report") return "Audio Streams";
  else if (reportId === "top_device_report") return "Device Type";
  else if (reportId === "top_track_report") return "Tracks";
  else if (reportId === "top_retailers_report") return "Retailers";
  else if (reportId === "top_label_report") return "Labels";
  else return "Territory";
}

function DynamicReport(props) {
  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const formattedThirtyDaysAgoDate = thirtyDaysAgo.toISOString().split("T")[0];
  const [StartDate, setStartDate] = useState(formattedThirtyDaysAgoDate);
  const [EndDate, setEndDate] = useState(formattedDate);
  const [trackSelected, setTrackSelected] = useState(null);
  const [labelSelected, setLabelSelected] = useState(null);
  const [retailerSelected, setRetailerSelected] = useState(null);
  const [countrySelected, setCountrySelected] = useState(null);
  const [devicesSelected, setDevicesSelected] = useState(null);
  const [detailReportOpen, setDetailReportOpen] = useState();
  const [detailReportId, setDetailReportId] = useState();
  // function isFilterApplied() {
  //   return trackSelected || labelSelected || retailerSelected ? true : false;
  // }
  useEffect(() => {
    function returnTrackFilter() {
      return `["track_id","must","","match","${trackSelected}"]`;
    }

    function returnRetailerFilter() {
      return `["retailers.${retailerSelected}", "must", "", "exists", ""]`;
    }
    function returnLabelFilter() {
      return `["label", "must", "", "match", "${labelSelected}"]`;
    }

    function returnCountryFilter() {
      return `["territories.${countrySelected}", "must", "", "exists", ""]`;
    }

    function returnDeviceFilter() {
      return `[]`;
    }

    function returnDateFilter() {
      return `["current_date","must","lte","range","${EndDate}"],["current_date","must","gte","range","${StartDate}"]`;
    }

    const topTracksFilters = [];
    const topRetailersFilters = [];
    const topLabelsFilters = [];
    const topTerritoryFilters = [];
    const topDevicesFilters = [];
    const audioStreamsFilters = [];

    if (trackSelected) {
      topLabelsFilters.push(returnTrackFilter());
      topTerritoryFilters.push(returnTrackFilter());
      topRetailersFilters.push(returnTrackFilter());
      topDevicesFilters.push(returnTrackFilter());
      audioStreamsFilters.push(returnTrackFilter());
    }
    if (retailerSelected) {
      topTracksFilters.push(returnRetailerFilter());
      topRetailersFilters.push(returnRetailerFilter());
      topTerritoryFilters.push(returnRetailerFilter());
      topLabelsFilters.push(returnRetailerFilter());
      topDevicesFilters.push(returnRetailerFilter());
      audioStreamsFilters.push(returnRetailerFilter());
    }
    if (labelSelected) {
      topTracksFilters.push(returnLabelFilter());
      topTerritoryFilters.push(returnLabelFilter());
      topRetailersFilters.push(returnLabelFilter());
      topDevicesFilters.push(returnLabelFilter());
      audioStreamsFilters.push(returnLabelFilter());
      topLabelsFilters.push(returnLabelFilter());
    }
    // if (countrySelected) {
    //   topTracksFilters.push(returnCountryFilter());
    //   topRetailersFilters.push(returnCountryFilter());
    //   topLabelsFilters.push(returnCountryFilter());
    //   topDevicesFilters.push(returnCountryFilter());
    //   audioStreamsFilters.push(returnCountryFilter());
    // }
    // if (devicesSelected) {
    //   topTracksFilters.push(returnDeviceFilter());
    //   topRetailersFilters.push(returnDeviceFilter());
    //   topLabelsFilters.push(returnDeviceFilter());
    //   topTerritoryFilters.push(returnDeviceFilter());
    //   audioStreamsFilters.push(returnDeviceFilter());
    // }
    if (StartDate && EndDate) {
      topTracksFilters.push(returnDateFilter());
      topRetailersFilters.push(returnDateFilter());
      topLabelsFilters.push(returnDateFilter());
      topTerritoryFilters.push(returnDateFilter());
      audioStreamsFilters.push(returnDateFilter());
      topDevicesFilters.push(returnDateFilter());
    }

    const topTrackParams = {
      current_page: "dashboard",
      filters: `[${topTracksFilters}]`,
      size: 1000,
    };
    fetchTopTrack(topTrackParams);

    const topRetailerParams = {
      current_page: "dashboard",
      filters: `[${topRetailersFilters}]`,
    };

    fetchTopStores(topRetailerParams);

    const topLabelParams = {
      current_page: "dashboard",
      filters: `[${topLabelsFilters}]`,
    };
    fetchTopLabel(topLabelParams);

    const topTerritoryParams = {
      current_page: "dashboard",
      filters: `[${topTerritoryFilters}]`,
    };
    fetchTopTerritories(topTerritoryParams);

    const topDevicesParams = {
      current_page: "dashboard",
      filters: `[${topDevicesFilters}]`,
      count: 5,
    };
    fetchTopDevices(topDevicesParams);

    const audioStreamparams = {
      current_page: "dashboard",
      filters: `[${topDevicesFilters}]`,
    };
    fetchAudioStreams(audioStreamparams);
  }, [
    trackSelected,
    retailerSelected,
    labelSelected,
    countrySelected,
    devicesSelected,
    StartDate,
    EndDate,
  ]);

  function fetchTopStores(params) {
    props.dispatch(TopStoreAction.requestTopStore(params));
  }
  function fetchTopLabel(params) {
    props.dispatch(TopLabelAction.requestTopLabel(params));
  }

  function fetchTopTerritories(params) {
    props.dispatch(TopTerritoriesAction.requestTopTerritories(params));
  }
  function fetchTopDevices(params) {
    props.dispatch(TopDevicesAction.requestTopDevices(params));
  }

  const fetchAudioStreams = (params) => {
    props.dispatch(TopStoreAction.requestAudioStreams(params));
  };

  function fetchTopTrack(param) {
    props.dispatch(TopTrackAction.requestTopTrackWithDateFilter(param));
  }

  function returnReport(reportId) {
    if (reportId === "top_audiostreams_report")
      return (
        <AudioStreamsChart
          detailReportOpen={detailReportOpen}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      );
    else if (reportId === "top_device_report")
      return <DevicesReport detailReportOpen={detailReportOpen} />;
    else if (reportId === "top_track_report")
      return (
        <TopTrackReport
          trackSelected={trackSelected}
          setTrackSelected={setTrackSelected}
        />
      );
    else if (reportId === "top_retailers_report")
      return (
        <RetailerReport
          setRetailerSelected={setRetailerSelected}
          detailReportOpen={detailReportOpen}
        />
      );
    else if (reportId === "top_label_report")
      return <LabelReport setLabelSelected={setLabelSelected} />;
    else return <TerritoryReport />;
  }

  function returnTrackName(trackId) {
    if (props.topTrack_with_date_filter?.Top_tracks_for_given_dates) {
      return props.topTrack_with_date_filter?.Top_tracks_for_given_dates?.filter(
        (track) => track._id === trackId
      )[0]._source.title;
    }
  }

  function returnLabelName(labelId) {
    if (props.topLabel) {
      return (
        props.topLabel.top_labels &&
        props.topLabel.top_labels.length > 0 &&
        props.topLabel.top_labels.filter(
          (item, index) => item.key === labelId
        )[0].label_name
      );
    }
  }
  return (
    <Box>
      {(trackSelected ||
        retailerSelected ||
        labelSelected ||
        countrySelected ||
        devicesSelected ||
        StartDate ||
        EndDate) && (
        <Box
          sx={{
            backgroundColor: theme.palette.background.light,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            paddingY: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginLeft: "1%",
            }}
          >
            <Typography>Applied Filters:</Typography>

            {trackSelected && (
              <Chip
                label={returnTrackName(trackSelected)}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setTrackSelected(null);
                }}
              />
            )}

            {retailerSelected && (
              <Chip
                label={retailerSelected}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setRetailerSelected(null);
                }}
              />
            )}

            {labelSelected && (
              <Chip
                label={returnLabelName(labelSelected)}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setLabelSelected(null);
                }}
              />
            )}

            {countrySelected && (
              <Chip
                label={countrySelected}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setCountrySelected(null);
                }}
              />
            )}

            {devicesSelected && (
              <Chip
                label={devicesSelected}
                variant="outlined"
                size="small"
                onDelete={() => {
                  // setDevicesSelected(null);
                }}
              />
            )}

            {(StartDate || EndDate) && (
              <Chip
                label={`${StartDate} - ${EndDate}`}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setStartDate(formattedThirtyDaysAgoDate);
                  setEndDate(formattedDate);
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "2%",
            }}
          >
            <CalendarPopover
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setCountrySelected={setCountrySelected}
              setRetailerSelected={setRetailerSelected}
              setLabelSelected={setLabelSelected}
              setTrackSelected={setTrackSelected}
            />
          </Box>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            className="scrollable"
            name={"Tracks"}
            id={"top_track_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
            overflowY={true}
          >
            <TopTrackReport
              trackSelected={trackSelected}
              setTrackSelected={setTrackSelected}
            />
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Retailers"}
            id={"top_retailers_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            <RetailerReport
              setRetailerSelected={setRetailerSelected}
              detailReportOpen={detailReportOpen}
            />
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Labels"}
            id={"top_label_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            <LabelReport setLabelSelected={setLabelSelected} />
          </ReportContainer>
        </Grid>

        {/* Second Row */}
        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Territory"}
            id={"top_territories_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            <TerritoryReport />
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Device Type"}
            id={"top_device_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            <DevicesReport detailReportOpen={detailReportOpen} />
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Audio Streams"}
            id={"top_audiostreams_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            <AudioStreamsChart
              detailReportOpen={detailReportOpen}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </ReportContainer>
        </Grid>
      </Grid>

      <Dialog
        open={detailReportOpen}
        onClose={() => {
          setDetailReportOpen(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" component="h2">
              {returnReportname(detailReportId)}
            </Typography>

            <IconButton
              onClick={() => {
                setDetailReportOpen(false);
              }}
              title="Close"
            >
              <HighlightOffOutlinedIcon sx={{ fontSize: "36px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1/1",
            }}
          >
            {returnReport(detailReportId)}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default connect(mapStateToProps)(DynamicReport);
