import BaseReducer from "../../utilities/BaseReducer";
import NotificationAction from "./NotificationAction";

export default class NotificationFilterReducer extends BaseReducer {
  //initialState of operation filter
  initialState = {
    NotificationsFilter: [],
  };

  //operation Filter
  [NotificationAction.REQUEST_FILTER_NOTIFICATIONS_FINISHED](state, action) {
    return {
      ...state,
      NotificationsFilter: action.payload,
    };
  }
}
