import environment from "environment";
import NetRevenueByMonthGetModel from "./models/NetRevenueReportByMonthGetModel";
import EffectUtility from "../../utilities/EffectUtility";
import TrackwiseRevenueGetModel from "./models/TrackwiseRevenueGetModel";
import ReleasewiseRevenueGetModel from "./models/ReleasewiseRevenueGetModel";
import ArtistwiseRevenueGetModel from "./models/ArtistwiseRevenueGetModel";

// NetRevenueByMonthEffect
export default class NetRevenueByMonthEffect {
  // calling an API according to the SessionLog model
  static async requestNetRevenueByMonth(params) {
    const endpoint = environment.api.netRevenueByMonth;
    return EffectUtility.getToModel(
      NetRevenueByMonthGetModel,
      endpoint,
      params
    );
  }
  static async requestNetRevenueByMonthFilter(params) {
    const endpoint = environment.api.netRevenueByMonth;
    return EffectUtility.getToModel(
      NetRevenueByMonthGetModel,
      endpoint,
      params
    );
  }

  static async requestNetRevenueByMonthFilterClear() {
    return {};
  }

  static async requestTrackwiseRevenue(params) {
    const endpoint = environment.api.trackwiseRevenue;
    return EffectUtility.getToModel(TrackwiseRevenueGetModel, endpoint, params);
  }

  static async requestReleasewiseRevenue(params) {
    const endpoint = environment.api.releasewiseRevenue;
    return EffectUtility.getToModel(
      ReleasewiseRevenueGetModel,
      endpoint,
      params
    );
  }

  static async requestArtistwiseRevenue(params) {
    const endpoint = environment.api.artistwiseRevenue;
    return EffectUtility.getToModel(
      ArtistwiseRevenueGetModel,
      endpoint,
      params
    );
  }
}
