import React, { useEffect, useState } from "react";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import { connect } from "react-redux";
import DataGridFunction from "../../../components/datagridComponent/datagrid";
import { Box, Grid, useTheme } from "@mui/material";
import TopComposerAction from "../../../stores/topComposer/TopComposerAction";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TopComposerAction.REQUEST_TOP_COMPOSER,
  ]),
  top_composers_report: state.top_composers_report.top_composers_report || [],
  pagination: state.top_composers_report.top_composers_report || [],
});

function TopComposer(props) {
  const theme = useTheme();
  const { isRequesting } = props;

  useEffect(() => {
    let params = {
      current_page: "analytics",
    };
    fetchtopcomposer(params);
  }, []);

  function fetchtopcomposer(params) {
    props.dispatch(TopComposerAction.requestTopComposer(params));
  }

  const rows = [];

  props?.top_composers_report?.top_composers?.map((item, index) => {
    rows.push({
      sl_no: ++index,
      id: ++index,

      composer_name: item && Object.keys(item)[0],
      tracks:
        item && Object.values(item)[0] ? Object.values(item)[0].tracks : "",
      views: item && Object.values(item)[0] ? Object.values(item)[0].views : "",
    });
  });
  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      width: 80,
    },

    {
      // width: 140,
      flex: 1,
      field: "composer_name",
      headerName: "Composer name",
    },

    {
      width: 280,
      field: "tracks",
      headerName: "No-Track",
    },

    {
      // width: 140,
      flex: 1,
      field: "views",
      headerName: "No-Views",
    },
  ];
  return (
    <React.Fragment>
      {!isRequesting ? (
        <Grid
          container
          item
          xs={12}
          pr={1}
          xl={12}
          lg={12}
          md={12}
          justifyContent="flex-start"
          alignItems={"flex-start"}
          // className="bg-warning"
        >
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <LoadingIndicator isActive={isRequesting}>
              <DataGridFunction
                columns={columns}
                rows={rows}
                rowHeight={80}
                isRequesting={isRequesting}
              />
            </LoadingIndicator>
          </Grid>
        </Grid>
      ) : (
        <Box>
          <LoadingBgImage />
        </Box>
      )}
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(TopComposer);
