import React, { useEffect, useState } from "react";
import environment from "environment";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import TopStoresChart from "./TopStoresChart";
import { theme } from "../../App";
import { connect } from "react-redux";
import { useNavigate } from "react-router";
// import TopTrackAction from "../../../stores/topTrackReport/TopTrackAction";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TrackAction from "../../../stores/track/TrackAction";

let accessToken = "";
// if (localStorage.getItem("Auth"))
//   accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [TrackAction.REQUEST_TOPTRACK]),
  // topTracks: state.topTrack.topTrack || [],
  track: state.track.track.hits || [],
});

function TopTracks(props) {
  const [page_limit] = useState(15);
  const [data, setData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // if (selectedDate)
    // (async function CallApi() {
    //   setLoading(true);
    //   await fetchData().then((e) => {
    //     setData(e.hits);
    //   });
    //   setLoading(false);
    // })();
    const toptrackparam = {
      page_limit: `${page_limit}`,
      order_by: `[["views","desc"]]`,
      current_page: "releases",
    };
    // fetchTopTrack(toptrackparam);
    fetchtrack(toptrackparam);
  }, []);

  // async function fetchData() {
  //   if (localStorage.getItem("Auth"))
  //     accessToken = JSON.parse(localStorage.getItem("Auth")).access_token;
  //   return fetch(
  //     `${environment.api.topTracks}?order_by=[["Quantity Net","desc"]]`,
  //     {
  //       method: "GET",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + accessToken,
  //       },
  //     }
  //   )
  //     .then((res) => {
  //       return res.json();
  //     })
  //     .then((response) => {
  //       return response;
  //     });
  // }

  // function fetchTopTrack(param) {
  //   props.dispatch(TopTrackAction.requestTopTrack()); //param));
  // }
  function fetchtrack(param) {
    props.dispatch(TrackAction.requestTrack(param));
  }

  return (
    <Box
      sx={{
        border: `1px solid ${theme.reports.gridBorder}`,
        overflow: "hidden",
        padding: 5,
        boxSizing: "border-box",
        height: "auto",
        backgroundColor: "white",
        marginBottom: "50px",
        boxShadow: theme.shadows,
      }}
    >
      {" "}
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item xs={6}>
          <Typography
            component={"div"}
            fontSize={18}
            sx={{
              fontWeight: "bold",
            }}
          >
            Top Tracks
          </Typography>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <span
            style={{
              cursor: "pointer",
              fontSize: "18px",
              textDecoration: "none",
            }}
            onClick={() => {
              navigate(`/analytics`);
            }}
            onMouseEnter={(e) => {
              e.target.style.textDecoration = "underline";
            }}
            onMouseLeave={(e) => {
              e.target.style.textDecoration = "none";
            }}
          >
            See More
          </span>
        </Grid>
      </Grid>
      <Grid item container xs={12} sx={{ marginBottom: 3 }}>
        {data?.hits &&
          data.hits.slice(0, 3).map((item) => {
            //data.hits.map((item) => {
            return (
              <Grid item xs={12} sx={{ margin: 1 }}>
                <Card
                  sx={{
                    cursor: "pointer",
                    backgroundColor: "white",
                    boxShadow: theme.shadows,
                  }}
                  onClick={() => {
                    navigate(`/analytics`);
                  }}
                >
                  <Grid
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px",
                    }}
                  >
                    <Typography
                      component={"div"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <CardMedia
                        sx={{ height: 100, aspectRatio: "1/1" }}
                        image={item._source.thumbnail_url}
                        title={item._source.title}
                      />
                      <Typography
                        component={"div"}
                        sx={{
                          textAlign: "center",
                          fontWeight: "bold",
                          paddingX: "10px",
                        }}
                      >
                        {item._source["Album Title"]}
                      </Typography>
                    </Typography>

                    <Typography
                      component={"div"}
                      sx={{
                        textAlign: "center",
                        fontWeight: "bold",
                        paddingX: "10px",
                      }}
                    >
                      {item._source["Quantity Net"]}
                      <p>streams</p>
                    </Typography>
                  </Grid>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}

export default connect(mapStateToProps)(TopTracks);
