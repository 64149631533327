import React from "react";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Card from "@mui/material/Card";
import { CardActionArea, CardMedia } from "@mui/material";

const RenderMedia = ({ image_url, mediaType, height, width }) => {
  var http = new XMLHttpRequest();

  http.open("HEAD", image_url, true);
  http.send();

  if (http.status === 200 || http.status === 0) {
    return (
      <Card sx={{ maxWidth: 200 }}>
        <CardActionArea>
          <CardMedia
            component={mediaType}
            height={height}
            image={image_url}
            alt={"This media is no longer available"}
            autoPlay="true"
            controls="true"
          />
        </CardActionArea>
      </Card>
    );
  } else {
    return (
      <p style={{ textDecoration: "underline", marginTop: 5 }}>
        <i>
          <ReportGmailerrorredIcon /> this media is no longer available
        </i>
      </p>
    );
  }
};

export default React.memo(RenderMedia);
