import { BaseModel } from "sjs-base-model";
import UserDataModel from "./UserDataModel";

export default class UserModel extends BaseModel {
  //USER model
  data = [UserDataModel];

  constructor(data1) {
    super();

    this.update(data1);
  }
}
