import React from "react";
import { Box, styled } from "@mui/system";
import { Grid, Tooltip, tooltipClasses, Typography } from "@mui/material";
import ErrorBoundary from "../../../components/ErrorBoundary";
import { theme } from "../../App";
import Rettrive from "../../releases/Rettrive";
import LoadingIndicator from "../../../components/loading-indicator/LoadingIndicator";
import LoadingBgImage from "../../../components/loadingBgImage/LoadingBgImage";
import { selectRequesting } from "../../../selectors/requesting/RequestingSelector";
import TopTrackAction from "../../../stores/topTrackReport/TopTrackAction";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  topTracksRequesting: selectRequesting(state, [
    TopTrackAction.REQUEST_TOPTRACK_WITH_DATE_FILTER,
  ]),

  topTrack_with_date_filter:
    state.topTrack_with_date_filter.topTrack_with_date_filter || [],
});
function TopTrackReport(props) {
  const { trackSelected, setTrackSelected, topTracksRequesting } = props;

  const LightTooltip = styled(({ className, ...newprops }) => (
    <Tooltip {...newprops} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 1)",
      boxShadow: theme.shadows[8], // Adjust the shadow to make it more pronounced
      borderRadius: theme.shape.borderRadius, // Rounded corners
      padding: "12px 16px", // Padding for the card
      fontSize: 16,
      border: `1px solid ${theme.palette.divider}`, // Optional: border around the card
      // Additional card-like styles
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  }));
  const toptracksData = [];
  if (props.topTrack_with_date_filter?.Top_tracks_for_given_dates) {
    props.topTrack_with_date_filter?.Top_tracks_for_given_dates?.forEach(
      (track) => {
        toptracksData.push({
          id: track._id,
          name: track._source.title,
          views: track._source.views,
          url: track._source.release_id.thumbnail_url,
          release_id: track._source.release_id.id,
        });
      }
    );
  }
  const TooltipContent = styled("div")(({ theme }) => ({
    padding: "8px 12px",
    fontSize: 16,
    lineHeight: 1.5,
    textAlign: "center",
  }));
  const ImageTextContainer = styled("div")({
    width: "103px", // Fixed width for the container
    textAlign: "center", // Center the text
  });
  const ImageContainer = styled("div")({
    height: "103px",
    width: "103px",
    overflow: "hidden",
    img: {
      width: "100%",
      height: "auto",
    },
  });

  return !topTracksRequesting ? (
    <ErrorBoundary message="Error in loading report">
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        sx={{
          width: "100%",
          height: "100%",
          padding: 1,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        rowGap={1}
        columnGap={1}
      >
        {toptracksData.map((item) => (
          <Grid
            key={item.id}
            item
            sx={{
              border: `1px solid ${theme.palette.info.main}`,
              borderRadius: 1,
              height: "fit-content",
              width: "max-content",
            }}
          >
            <Typography
              component="div"
              sx={{
                cursor: "pointer",
                padding: "5px",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
                backgroundColor:
                  trackSelected === item.id
                    ? theme.palette.primary.contrastText
                    : "none",
              }}
              onClick={() => {
                item.id === trackSelected
                  ? setTrackSelected(null)
                  : setTrackSelected(item.id);
              }}
            >
              {/* <LightTooltip title=> */}
              <LightTooltip
                title={
                  <TooltipContent>
                    <div>
                      <strong>{item.name}</strong>
                    </div>
                    <div>{item.views}</div>
                  </TooltipContent>
                }
              >
                <ImageTextContainer>
                  <ImageContainer>
                    <Rettrive
                      thumbnail_url={item?.url}
                      release_id={item?.release_id}
                      current_page={"dashboard"}
                    />
                  </ImageContainer>
                  <Typography
                    fontWeight={"bold"}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name}
                  </Typography>
                </ImageTextContainer>
              </LightTooltip>
            </Typography>
          </Grid>
        ))}
      </Grid>
    </ErrorBoundary>
  ) : (
    <LoadingIndicator isActive={topTracksRequesting}>
      <Box
        sx={{
          width: "100%",
          height: "40vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LoadingBgImage width={"50%"} />
      </Box>
    </LoadingIndicator>
  );
}

export default connect(mapStateToProps)(TopTrackReport);
