import ActionUtility from "../../utilities/ActionUtility";
import DirectorEffect from "./DirectorEffect";

export default class DirectorAction {
  // DirectorAction action with an function declaration
  static REQUEST_DIRECTOR = "DirectorAction.REQUEST_DIRECTOR";
  static REQUEST_DIRECTOR_FINISHED = "DirectorAction.REQUEST_DIRECTOR_FINISHED";

  static REQUEST_DIRECTOR_FILTER = "DirectorAction.REQUEST_DIRECTOR_FILTER";
  static REQUEST_DIRECTOR_FILTER_FINISHED =
    "DirectorAction.REQUEST_DIRECTOR_FILTER_FINISHED";

  static REQUEST_PUT_DIRECTOR = "DirectorAction.REQUEST_PUT_DIRECTOR";
  static REQUEST_PUT_DIRECTOR_FINISHED =
    "DirectorAction.REQUEST_PUT_DIRECTOR_FINISHED";

  static REQUEST_POST_DIRECTOR = "DirectorAction.REQUEST_POST_DIRECTOR";
  static REQUEST_POST_DIRECTOR_FINISHED =
    "DirectorAction.REQUEST_POST_DIRECTOR_FINISHED";

  static REQUEST_DIRECTOR_CLEAR = "DirectorAction.REQUEST_DIRECTOR_CLEAR";
  static REQUEST_DIRECTOR_CLEAR_FINISHED =
    "DirectorAction.REQUEST_DIRECTOR_CLEAR_FINISHED";

  static REQUEST_DIRECTOR_FILTER_CLEAR =
    "DirectorAction.REQUEST_DIRECTOR_FILTER_CLEAR";
  static REQUEST_DIRECTOR_FILTER_CLEAR_FINISHED =
    "DirectorAction.REQUEST_DIRECTOR_FILTER_CLEAR_FINISHED";

  static REQUEST_DELETE_DIRECTOR = "DirectorAction.REQUEST_DELETE_DIRECTOR";
  static REQUEST_DELETE_DIRECTOR_FINISHED =
    "DirectorAction.REQUEST_DELETE_DIRECTOR_FINISHED";

  // METHODS
  // DIRECTOR GET function
  static requestDirector(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DirectorAction.REQUEST_DIRECTOR,
        DirectorEffect.requestDirector,
        params
      );
    };
  }

  static requestDirectorClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DirectorAction.REQUEST_DIRECTOR_CLEAR,
        DirectorEffect.requestDirectorClear
      );
    };
  }

  static requestDirectorFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DirectorAction.REQUEST_DIRECTOR_FILTER_CLEAR,
        DirectorEffect.requestDirectorFilterClear
      );
    };
  }

  static requestDirectorFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DirectorAction.REQUEST_DIRECTOR_FILTER,
        DirectorEffect.requestDirectorFilter,
        params
      );
    };
  }
  static requestPutDirector(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DirectorAction.REQUEST_PUT_DIRECTOR,
        DirectorEffect.requestPutDirector,
        data,
        id
      );
    };
  }

  static requestPostDirector(data, params, callBack) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DirectorAction.REQUEST_POST_DIRECTOR,
        DirectorEffect.requestPostDirector,
        data,
        params,
        callBack
      );
    };
  }

  static requestDeleteDirector(id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        DirectorAction.REQUEST_DELETE_DIRECTOR,
        DirectorEffect.requestDeleteDirector,
        id
      );
    };
  }
}
