import { BaseModel } from "sjs-base-model";

// TopReleasePutModel
export default class TopReleasePutModel extends BaseModel {
  status = "";

  constructor(data) {
    super();
    this.update(data);
  }
}
