import BaseReducer from "../../utilities/BaseReducer";
import StreamPlatformAction from "./StreamPlatformAction";

// StreamPlatformReduser
export default class StreamPlatformReduser extends BaseReducer {
  initialState = {
    stream_platform: [],
  };
  [StreamPlatformAction.REQUEST_STREAM_PLATFORM_FINISHED](state, action) {
    return {
      ...state,
      stream_platform: action.payload,
    };
  }

  [StreamPlatformAction.REQUEST_STREAM_PLATFORM_CLEAR_FINISHED](state, action) {
    return {
      ...state,
      stream_platform: action.payload,
    };
  }
}
