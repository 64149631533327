import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  createFilterOptions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { faFilter, faTicket, faXmark } from "@fortawesome/free-solid-svg-icons";
import { theme } from "../App";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import { useNavigate } from "react-router";
import { Field, Form, Formik, useFormik, useFormikContext } from "formik";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import MuiTextField from "../../components/muiTextField/MuiTextField";
import MuiButton from "../../components/muiButton/MuiButton";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import TerritoriesAction from "../../stores/territories/TerritoriesAction";
import CancelIcon from "@mui/icons-material/Cancel";

// mapstateto props function
const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TerritoriesAction.REQUEST_TERRITORIES,
  ]),
  territories: state.territories.territories || {},
});

const PromoteTracksDialog = (props) => {
  const navigate = useNavigate();

  useEffect(() => {}, []);

  console.log("releaseData", props.releaseData);

  const handleClose = () => {
    props.onClose();
  };
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
      fullWidth
    >
      <Box>
        <Grid
          item
          container
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          justifyContent="space-between"
          alignItems={"center"}
          //   className="bg-warning"
          px={3}
          py={1}
          sx={{
            borderBottom: `1px solid rgba(0, 0, 0, 0.16)`,
          }}
        >
          <Grid item sx={{ color: theme.palette.primary.main }}>
            <h1
              style={{
                fontSize: theme.typography.h1.fontSize,
              }}
            >
              {formatName("Tracks")}
            </h1>
          </Grid>
          <Grid item sx={{ color: theme.palette.text.gray }}>
            <IconButton onClick={props.onClose}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
        <hr className="p-0 m-0" style={{ color: theme.palette.text.gray }} />
        <Box p={5}></Box>
      </Box>
    </Dialog>
  );
};
export default connect(mapStateToProps)(PromoteTracksDialog);
