import { BaseModel } from "sjs-base-model";

// ReleaseStatusCountPostModel
export default class ReleaseStatusCountPostModel extends BaseModel {
  status = "";

  constructor(data1) {
    super();

    this.update(data1);
  }
}
