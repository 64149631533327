import { BaseModel } from "sjs-base-model";
import ClientDataHitsModel from "./ClientDataHitsModel";
import ClientShardsModel from "./ClientShardsModel";

// StatusModel
export default class ClientModel extends BaseModel {
  // response getting from the api mentioned end point
  _shards = { _shards: ClientShardsModel };
  current_page_no = 0;
  hits = { hits: ClientDataHitsModel };
  total_page_no = 0;
  timed_out = false;
  took = 0;
  constructor(data1) {
    super();

    this.update(data1);
  }
}
