import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
} from "@mui/material";
import { Field, Form, Formik } from "formik";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import UserProfileAction from "../../stores/userprofile/UserProfileAction";
import MuiButton from "../muiButton/MuiButton";
import MuiTextField from "../muiTextField/MuiTextField";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import { Alert } from "@mui/material";
import { theme } from "../../views/App";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const ResetProfilePassword = (props) => {
  let initial = {};

  initial = {
    password: "",
    confirmPassword: "",
  };

  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const passwordButtonRef = React.useRef(null);
  const confirmPasswordButtonRef = React.useRef(null);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    setShowAlert(false);
  }, [props.open]);

  return (
    <>
      <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="sm">
        {/* Scope of Formik starts here */}
        <Formik
          initialValues={initial}
          validate={(values) => {
            var errors = {};
            if (!values.password) {
              errors.password = "Password is Required";
            }
            if (!values.confirmPassword) {
              errors.confirmPassword = "Password is Required";
            }
            return errors;
          }}
          onSubmit={(values, formikHelpers) => {
            setLoading(true);

            if (values.password === values.confirmPassword) {
              let obj = { password: values.confirmPassword };
              props
                .dispatch(
                  UserProfileAction.requestUserResetPassword(
                    JSON.stringify(obj)
                  )
                )
                .then(() => {
                  setLoading(false);
                  props.onClose();
                });
            } else {
              setShowAlert(true);
              setLoading(false);
            }
          }}
          validateOnBlur={true}
          validateOnChange
        >
          {({ values, errors, touched, handleChange, setFieldValue }) => (
            // Form Tag from Formik
            <Form>
              {/* Content of the dialog Box  */}
              <DialogContent
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {/* text Content inside DialogBox  */}
                <DialogContentText sx={{ width: "100%" }}>
                  <div className="text-center pb-2">
                    <div className="row p-3">
                      <div className="col-10 ">
                        <h3
                          className="dailogtext"
                          style={{ color: theme.palette.text.primary }}
                        >
                          <b>
                            <span
                              style={{ color: theme.palette.text.first_letter }}
                            >
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;R
                            </span>
                            eset &nbsp;
                            <span
                              style={{ color: theme.palette.text.first_letter }}
                            >
                              P
                            </span>
                            assword &nbsp;
                          </b>
                        </h3>
                      </div>
                      <div className="col-2 closebtn">
                        <IconButton
                          title="Close"
                          onClick={() => {
                            props.onClose();
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </DialogContentText>
                {/* Form Filed inside Dialog Box  */}
                <Grid container>
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={1}>
                      New Password :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5}>
                      <Field
                        // text feild from material Ui
                        as={MuiTextField}
                        fullWidth
                        variant="outlined"
                        size="small"
                        // type="password"
                        name="password"
                        placeholder="Enter New Password"
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          onKeyPress: (event) => {
                            const { key } = event;
                            if (key === "Enter") {
                              passwordButtonRef.current.click();
                            }
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="small"
                                sx={{ mr: 1 }}
                              >
                                {showPassword ? (
                                  <VisibilityOff transform="scale(0.8)" />
                                ) : (
                                  <Visibility transform="scale(0.8)" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          Boolean(errors.password) && Boolean(touched.password)
                        }
                        helperText={
                          Boolean(touched.password) && errors.password
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container sx={{ mb: 2 }}>
                  <Grid item lg={12} md={12} sm={12} container>
                    <Grid item lg={3} md={3} sm={3} ml={10} mt={3}>
                      Confirm Password :
                    </Grid>
                    <Grid item lg={5} md={5} sm={5} mt={2}>
                      <Field
                        // text feild from material Ui
                        as={MuiTextField}
                        fullWidth
                        variant="outlined"
                        //   id="category_name"
                        size="small"
                        name="confirmPassword"
                        placeholder="Confirm Password"
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                          onKeyPress: (event) => {
                            const { key } = event;
                            if (key === "Enter") {
                              confirmPasswordButtonRef.current.click();
                            }
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                                size="small"
                                sx={{ mr: 1 }}
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff transform="scale(0.8)" />
                                ) : (
                                  <Visibility transform="scale(0.8)" />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        error={
                          Boolean(errors.confirmPassword) &&
                          Boolean(touched.confirmPassword)
                        }
                        helperText={
                          Boolean(touched.confirmPassword) &&
                          errors.confirmPassword
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {showAlert && (
                  <Alert severity="error" onClose={() => setShowAlert(false)}>
                    Password and Confirm Password should be the same.
                  </Alert>
                )}
              </DialogContent>
              {/* Actions performed from form action buttons  */}
              <DialogActions className="m-1 my-4">
                {/* Calling ButtonComponent to perform form actions  */}
                <Grid
                  container
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  display="flex"
                  justifyContent="space-evenly"
                >
                  <Grid item lg={5} md={5} sm={5} mr={2} minWidth={200}>
                    <MuiButton
                      name={"Submit Password"}
                      type="submit"
                      loading={loading}
                      size="large"
                      width="100%"
                      //color="primary"
                      sx={{ backgroundColor: "blue", color: "white" }}
                    />
                  </Grid>
                  <Grid item lg={5} md={5} sm={5} minWidth={200}>
                    <MuiButton
                      name={"Reset"}
                      type="reset"
                      size="large"
                      width="100%"
                      cancel
                      //color="secondary"
                    />
                  </Grid>
                </Grid>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default connect(mapDispatchToProps)(ResetProfilePassword);
