import BaseReducer from "../../utilities/BaseReducer";
import TrackAction from "./TrackAction";

export default class TrackFilterReduser extends BaseReducer {
  //initial state of TrackFilterReduser
  initialState = {
    trackFilter: [],
  };
  //TrackFilterReduser request action finish
  [TrackAction.REQUEST_TRACK_FILTER_FINISHED](state, action) {
    return {
      ...state,
      trackFilter: action.payload,
    };
  }
}
