import environment from "environment";
import EffectUtility from "../../utilities/EffectUtility";
import TrackRevenueGetModel from "./models/TrackRevenueGetModel";
import TrackwiseRevenueSummaryModel from "./models/TrackwiseRevenueSummaryModel";
export default class TrackRevenueEffect {
  static async requestTrackRevenue(params) {
    const endpoint = environment.api.trackRevenue;
    return EffectUtility.getToModel(TrackRevenueGetModel, endpoint, params);
  }

  static async requestTrackRevenueClear() {
    return {};
  }

  static async requestTrackwiseRevenueSummary(params) {
    const endpoint = environment.api.trackwiseRevenueSummary;
    return EffectUtility.getToModel(
      TrackwiseRevenueSummaryModel,
      endpoint,
      params
    );
  }
}
