import ActionUtility from "../../utilities/ActionUtility";
import ClientRevenueSharePolicyEffect from "./ClientRevenueSharePolicyEffect";

export default class ClientRevenueSharePolicyAction {
  static REQUEST_CLIENT_REVENUE_SHARE_POLICY =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY";
  static REQUEST_CLIENT_REVENUE_SHARE_POLICY_FINISHED =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FINISHED";

  static REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER";
  static REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER_FINISHED =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER_FINISHED";

  static REQUEST_PUT_CLIENT_REVENUE_SHARE_POLICY =
    "ClientRevenueSharePolicyAction.REQUEST_PUT_CLIENT_REVENUE_SHARE_POLICY";
  static REQUEST_PUT_CLIENT_REVENUE_SHARE_POLICY_FINISHED =
    "ClientRevenueSharePolicyAction.REQUEST_PUT_CLIENT_REVENUE_SHARE_POLICY_FINISHED";

  static REQUEST_POST_CLIENT_REVENUE_SHARE_POLICY =
    "ClientRevenueSharePolicyAction.REQUEST_POST_CLIENT_REVENUE_SHARE_POLICY";
  static REQUEST_POST_CLIENT_REVENUE_SHARE_POLICY_FINISHED =
    "ClientRevenueSharePolicyAction.REQUEST_POST_CLIENT_REVENUE_SHARE_POLICY_FINISHED";

  static REQUEST_CLIENT_REVENUE_SHARE_POLICY_CLEAR =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_CLEAR";
  static REQUEST_CLIENT_REVENUE_SHARE_POLICY_CLEAR_FINISHED =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_CLEAR_FINISHED";

  static REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER_CLEAR =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER_CLEAR";
  static REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER_CLEAR_FINISHED =
    "ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER_CLEAR_FINISHED";

  static REQUEST_SYNC_CLIENT_REVENUE_SHARE_POLICY =
    "ClientRevenueSharePolicyAction.REQUEST_SYNC_CLIENT_REVENUE_SHARE_POLICY";
  static REQUEST_SYNC_CLIENT_REVENUE_SHARE_POLICY_FINISHED =
    "ClientRevenueSharePolicyAction.REQUEST_SYNC_CLIENT_REVENUE_SHARE_POLICY_FINISHED";

  // METHODS
  static requestClientRevenueSharePolicy(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY,
        ClientRevenueSharePolicyEffect.requestClientRevenueSharePolicy,
        params
      );
    };
  }

  static requestClientRevenueSharePolicyClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_CLEAR,
        ClientRevenueSharePolicyEffect.requestClientRevenueSharePolicyClear
      );
    };
  }

  static requesClientRevenueSharePolicyFilterClear() {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER_CLEAR,
        ClientRevenueSharePolicyEffect.requesClientRevenueSharePolicyFilterClear
      );
    };
  }

  static requestClientRevenueSharePolicyFilter(params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientRevenueSharePolicyAction.REQUEST_CLIENT_REVENUE_SHARE_POLICY_FILTER,
        ClientRevenueSharePolicyEffect.requestClientRevenueSharePolicyFilter,
        params
      );
    };
  }
  static requestPutClientRevenueSharePolicy(data, id) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientRevenueSharePolicyAction.REQUEST_PUT_CLIENT_REVENUE_SHARE_POLICY,
        ClientRevenueSharePolicyEffect.requestPutClientRevenueSharePolicy,
        data,
        id
      );
    };
  }

  static requestPostClientRevenueSharePolicy(data, current_page) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientRevenueSharePolicyAction.REQUEST_POST_CLIENT_REVENUE_SHARE_POLICY,
        ClientRevenueSharePolicyEffect.requestPostClientRevenueSharePolicy,
        data,
        current_page
      );
    };
  }

  static requestSyncClientRevenueSharePolicy(data, clientId, params) {
    return async (dispatch, getState) => {
      await ActionUtility.createThunkEffect(
        dispatch,
        ClientRevenueSharePolicyAction.REQUEST_SYNC_CLIENT_REVENUE_SHARE_POLICY,
        ClientRevenueSharePolicyEffect.requestSyncClientRevenueSharePolicy,
        data,
        clientId,
        params
      );
    };
  }
}
